import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Grid,
  Button,
  Tabs,
  Tab,
  Stack,
  InputLabel,
  InputBase,
  DialogActions,
  Typography,
} from "@mui/material";
import {
  Add,
  BorderColorOutlined,
  Delete,
  ArrowBackIosNew,
  ArrowForwardIos,
  Upload,
  Archive,
  FileUploadOutlined,
  Send,
  Unarchive,
  Publish,
  Email,
  InfoOutlined,
} from "@mui/icons-material";
import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";

import SideNav from "../../components/sidenav/sideNav";
import LoadingControla from "../../components/load/loadingControla";
import DialogConfirmControla from "../../components/dialog/dialogConfirmControla";
import SubtitleText from "../../components/text/subtitleText";
import BannerControla from "../../components/cards/bannerControla";
import ButtonControla from "../../components/buttons/buttonController";
import ButtonIconControla from "../../components/buttons/buttonIconControla";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import DialogTitleControla from "../../components/dialog/dialogTitleControla";
import styled from "styled-components";
import MyRisst from "../../api/MyRisst";

const blue = "#034AFF";
const purple = "#8B8BD8";
const colorTitle = "#9191B0";
const skyblue = "#2D9CDB";
function Index() {
  const params = new URLSearchParams(window.location.search);
  const idTabs = parseInt(
    params.get("idTabs") !== null ? params.get("idTabs") : 0
  );

  const StyledAddButton = styled(Button)({
    textTransform: "none",
    backgroundColor: skyblue,
    borderRadius: 16,
  });

  // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  //fin de alerts

  // Dialogos de confirmacion INICIO

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setIdUpdateRisst(id);
    setIdUpdateRisstTemplate(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    switch (buttonActionType) {
      case "delete":
        handleDeleteRisst();
        break;
      case "public":
        updateStatus(4);
        break;
      case "archived":
        updateStatus(5);
        break;
      case "unarchived":
        updateStatus(4);
        break;
      case "comite":
        updateStatus(3);
        break;
      case "workers":
        updateStatus(7);
        break;
      case "deleteTemplate":
        handleDeleteRisstTemplate();
        break;
      case "publicTemplate":
        updateStatusTemplate(4);
        break;
      case "archivedTemplate":
        updateStatusTemplate(5);
        break;
      case "unarchived":
        updateStatus(4);
        break;
      case "comiteTemplate":
        updateStatusTemplate(3);
        break;
      case "workersTemplate":
        updateStatusTemplate(7);
        break;
    }
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN

  // Abrir diálogo de cargar archivo

  const [develop, setDevelop] = useState(null);
  const [date, setDate] = useState(null);
  const [title, setTitle] = useState(null);
  const [documentUrl, setDocumentUrl] = useState(null);

  const [rowsRisstTemplate, setRowsRisstTemplate] = useState([]);
  const [rowsRisst, setRowsRisst] = useState([]);
  const [rowsRisstArchived, setRowsRisstArchived] = useState([]);
  const [rowsRisstArchivedTemplate, setRowsRisstArchivedTemplate] = useState(
    []
  );

  // mostrar risst docuemntos
  const handleGetRisst = async (inputYear = undefined) => {
    if (inputYear != undefined) {
      handleOpenLoading();
    }
    const response = await MyRisst.getRisst();
    let letrisstarchived = response.filter(
      (status) => status.register_status === 5
    );
    let letrisst = response.filter((status) => status.register_status !== 5);

    setRowsRisst(letrisst);
    setRowsRisstArchived(letrisstarchived);
    return response;
  };

  // Abrir modal subir documento
  const [openModalUploaFile, setOpenModalUploadFile] = useState(false);
  const handleOpenModalUploadFile = () => {
    setDevelop(null);
    setDate(null);
    setTitle(null);
    setDocumentUrl(null);
    setOpenModalUploadFile(true);
  };

  // editar rist document
  const [idRist, setIdRist] = useState();
  const [openModalUploaFileEdit, setOpenModalUploadFileEdit] = useState(false);
  const handleOpenModalUploadFileEdit = (idRist) => {
    setIdRist(idRist);
    handleEditRisst(idRist);
    setOpenModalUploadFileEdit(true);
  };

  const handleEditRisst = async (idRist) => {
    const response = await MyRisst.editRisst({ idRisst: idRist });
    setDevelop(response.develop);
    setTitle(response.title);
    setDate(response.date);
    return response;
  };
  const handleUpdateRisst = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyRisst.UpdateRisst({
      idRisst: idRist,
      develop: develop,
      title: title,
      date: date,
      document_url: documentUrl,
    });
    handleGetRisst();
    setOpenModalUploadFileEdit(false);
    handleOpenAlert("Los datos fueron editados de forma correcta", "success");
    return response;
  };

  // Mostrar plantillas
  const handleGetRisstTemplate = async (inputYear = undefined) => {
    if (inputYear != undefined) {
      handleOpenLoading();
    }
    const response = await MyRisst.getRisstTemplate();

    let letrisstTemplatearchived = response.filter(
      (status) => status.register_status === 5
    );
    let letrisstTemplate = response.filter(
      (status) => status.register_status !== 5
    );
    setRowsRisstTemplate(letrisstTemplate);
    setRowsRisstArchivedTemplate(letrisstTemplatearchived);

    return response;
  };

  //   crear
  const handleCreateRisst = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyRisst.createRisst({
      develop: develop,
      title: title,
      date: date,
      document_url: documentUrl,
    });
    if (response.success !== false) {
      setOpenModalUploadFile(false);
      handleOpenAlert("El documento se ha creado de forma correcta", "success");
      handleGetRisst();
      window.location.href = "/documentation/risst?idTabs=1";
    } else {
      handleOpenAlert("Ocurrió un error al crear el archivo", "error");
    }
    return response;
  };

  // Diálogo para template
  const [openModalTemplate, setOpenModalTemplate] = useState(false);
  const handleOpenModalTemplate = () => {
    setOpenModalTemplate(true);
  };

  // Dialog para ver plantilla
  const [openModalTemplateView, setOpenModalTemplateView] = useState(false);
  const handleOpenModalTemplateView = (event) => {
    event.preventDefault();
    setOpenModalTemplateView(true);
  };

  //usar plantilla

  const handleUseTemplate = async (event) => {
    event.preventDefault();
    window.location.href = "/documentation/risst/template";
  };

  const currentTime = new Date();
  const [year, setYear] = useState(currentTime.getFullYear());

  const [documentType, setDocumentType] = useState(
    idTabs !== undefined && idTabs !== null && idTabs !== "" ? idTabs : 0
  );

  const handleChangeDocumentType = (event, newValue) => {
    setDocumentType(newValue);
  };

  const handleIncreaseYear = (event, newValue) => {
    setYear(year + 1);
    // handleGetPets(year + 1);
  };

  const handleDecreaseYear = (event, newValue) => {
    setYear(year - 1);
    // handleGetPets(year - 1);
  };

  // actualizar estado
  const [idUpdateRisst, setIdUpdateRisst] = useState();
  const updateStatus = async (idStatus) => {
    handleOpenLoading();
    const response = await MyRisst.updatestatusRisst({
      idRisst: idUpdateRisst,
      register_status: idStatus,
    });
    handleGetRisst();
    handleOpenAlert("La operación se realizó con éxito", "success");
    return response;
  };

  // actualizar estado
  const [idUpdateRisstTemplate, setIdUpdateRisstTemplate] = useState();
  const updateStatusTemplate = async (idStatus) => {
    handleOpenLoading();
    const response = await MyRisst.updatestatusRisstTemplate({
      idRisstTemplate: idUpdateRisstTemplate,
      register_status: idStatus,
    });
    handleGetRisstTemplate();
    handleOpenAlert("La operación se realizó con éxito", "success");

    return response;
  };

  // eliminar
  //  const [idRisst, setIdRisst] = React.useState();

  const handleDeleteRisst = async () => {
    handleOpenLoading();

    const response = await MyRisst.deleteRisst({
      idRisst: idUpdateRisst,
    });
    if (response.success === true) {
      handleGetRisst();
      handleOpenAlert(
        "Los datos fueron eliminados de forma correcta",
        "success"
      );
    } else {
      handleOpenAlert(response.msg, "error");
    }
    return response;
  };

  //  eliminar plantilla
  const handleDeleteRisstTemplate = async () => {
    handleOpenLoading();
    const response = await MyRisst.deleteRisstTemplate({
      idRisstTemplate: idUpdateRisstTemplate,
    });
    if (response.success === true) {
      handleGetRisstTemplate();
      handleOpenAlert(
        "Los datos fueron eliminados de forma correcta",
        "success"
      );
    } else {
      handleOpenAlert(response.msg, "error");
    }
    return response;
  };

  //   infromacion
  const StyledModalTitle = styled("h2")({
    color: purple,
  });
  const StyledModalSubtitle = styled("h3")({
    fontWeight: "bold",
  });
  const [openModalInfo, setOpenModalInfo] = React.useState(false);

  const handleOpenModalInfo = () => {
    setOpenModalInfo(true);
  };

  const [totalRisst, setTotalRisst] = useState([]);

  useEffect(() => {
    setTotalRisst([...rowsRisstArchived, ...rowsRisstArchivedTemplate]);
  }, [rowsRisstArchived, rowsRisstArchivedTemplate]);

  useEffect(() => {
    handleGetRisst();
    handleEditRisst();
    handleGetRisstTemplate();
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="/documentation" />
      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />

      <Container>
        <Box>
          <Grid container>
            <Grid item xs={12} md={12}>
              <SubtitleText
                text={"Procedimientos"}
                color={"#9191B0"}
              ></SubtitleText>
            </Grid>

            <Grid
              item
              xd={12}
              md={12}
              style={{ textAlign: "right", marginBottom: "3px" }}
            >
              <StyledAddButton
                variant="text"
                startIcon={<InfoOutlined />}
                disableElevation
                size="large"
                style={{
                  backgroundColor: "#305AD9",
                  borderRadius: "15px",
                  color: "white",
                  padding: "8px 25px",
                }}
                onClick={() => handleOpenModalInfo()}
              >
                ¿Qué debo saber del RISST?{" "}
              </StyledAddButton>
            </Grid>
            <Grid item xs={12} md={12}>
              <BannerControla
                image="/assets/images/banners/documents/bannerrisst.png"
                color2="#2F67BC"
                color1="#8FBCFF"
                text="RISST"
              />
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12} sm={7} md={6} lg={6} xl={6}>
              <Stack direction={"row"} spacing={2}>
                <Box>
                  <ButtonControla
                    roles={[1, 7, 8, 15]}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#1cb591"}
                    backgroundColorHover={"#169073"}
                    textButton={"Agregar usando plantilla"}
                    functionOnClick={handleOpenModalTemplate}
                  />
                </Box>
                <Box>
                  <ButtonControla
                    roles={[1, 7, 8, 15]}
                    iconButton={<Upload sx={{ color: "white" }} />}
                    textButton={"Subir mi documento"}
                    backgroundColor={"#2D9CDB"}
                    backgroundColorHover={"#1976d2"}
                    functionOnClick={handleOpenModalUploadFile}
                  />
                </Box>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={2} lg={3} xl={3} sx={{ mt: 2 }}>
              {" "}
            </Grid>

            {/* filtrar por año */}
            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
              <Grid container spacing={2}>
                {/* <Grid item xs={3} sm={2} md={3} lg={3} xl={3}>
                  <InputLabel
                    sx={{
                      padding: "8px 0px 8px 0px",
                      fontSize: "14px",
                      color: "#305AD9",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    Periodo:
                  </InputLabel>
                </Grid>
                <Grid item xs={3} sm={3} md={4} lg={4} xl={4}>
                  <InputBase
                    type="number"
                    value={year}
                    onChange={"handleGetPets"}
                    readOnly
                    sx={{
                      width: "100%",
                      padding: "4px 10px 4px 10px",
                      backgroundColor: "white",
                      color: "#305AD9",
                      fontSize: "14px",
                      borderRadius: "8px",
                    }}
                  ></InputBase>
                </Grid> */}
                {/* <Grid item xs={2} sm={1} md={2} lg={2} xl={2}>
                  <IconButton
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "15px",
                      color: "#305AD9",
                    }}
                    onClick={handleDecreaseYear}
                  >
                    <ArrowBackIosNew />
                  </IconButton>
                </Grid> */}
                {/* <Grid item xs={2} sm={1} md={2} lg={2} xl={2}>
                  <IconButton
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "15px",
                      color: "#305AD9",
                    }}
                    onClick={handleIncreaseYear}
                  >
                    <ArrowForwardIos />
                  </IconButton>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Paper sx={{ overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 400 }}>
                  <Table
                    stickyHeader
                    sx={{ width: "100%", minWidth: "700px" }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ color: blue }}>
                          Nombre del documento
                        </TableCell>
                        <TableCell
                          sx={{
                            color: blue,
                          }}
                        >
                          Estado
                        </TableCell>

                        <TableCell colSpan={4}>
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            <Tabs
                              value={documentType}
                              onChange={handleChangeDocumentType}
                              aria-label="Tipos de documento"
                            >
                              <Tab label="Modelos" />
                              <Tab label="Activos" />
                              <Tab label="Archivados" />
                            </Tabs>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody
                      sx={{ display: documentType !== 0 ? "none" : "" }}
                    >
                      {rowsRisstTemplate.map((row) => (
                        <TableRow
                          hover
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            sx={{ color: purple }}
                            component="th"
                            scope="row"
                          >
                            {row.title}
                          </TableCell>
                          <TableCell sx={{ color: purple }}>
                            {row.register_status === 1 ? "Creado" : ""}
                            {row.register_status === 7
                              ? "Enviado a trabajadores"
                              : ""}
                            {row.register_status === 3
                              ? "Enviado a comité"
                              : ""}
                            {row.register_status === 4 ? "Publicado" : ""}
                            {row.register_status === 5 ? "Archivado" : ""}{" "}
                          </TableCell>
                          <TableCell sx={{ color: purple }}>
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                            >
                              <ButtonIconControla
                                icon={
                                  <VisibilityOutlined sx={{ color: "white" }} />
                                }
                                backgroundColor={"#305AD9"}
                                backgroundColorHover={"#0E4AFF"}
                                textTooltip={"Ver"}
                                directionUrl={
                                  "http://34.134.148.254:8081/report/" + row.id
                                }
                                directionTarget={"_blank"}
                              />

                              {row.register_status === 1 ? (
                                <ButtonIconControla
                                  roles={[1, 3, 4, 7, 8, 15]}
                                  icon={
                                    <BorderColorOutlined
                                      sx={{ color: "white" }}
                                    />
                                  }
                                  backgroundColor={"#2D9CDB"}
                                  backgroundColorHover={"#33AEF4"}
                                  textTooltip={"Editar"}
                                  // el editar de activos debe enviar a un modal
                                  functionOnClick={() => {
                                    window.location.href =
                                      "/documentation/risst/template?idRisst=" +
                                      row.id;
                                  }}
                                />
                              ) : (
                                ""
                              )}

                              {row.register_status === 1 ? (
                                <ButtonIconControla
                                  roles={[1, 7, 8, 15]}
                                  icon={<Send sx={{ color: "white" }} />}
                                  backgroundColor={"#169073"}
                                  backgroundColorHover={"#1AA483"}
                                  textTooltip={"Enviar a trabajadores"}
                                  functionOnClick={() => {
                                    handleOpenModalConfirm(
                                      row.id,
                                      "¿Esta seguro de enviar a trabajadores?",
                                      'Una vez enviado no podra revertir la operación del documento:  "' +
                                        row.title +
                                        '"',
                                      "Si, Enviar a trabajadores",
                                      "workersTemplate"
                                    );
                                  }}
                                />
                              ) : (
                                ""
                              )}
                              {row.register_status === 7 ? (
                                <ButtonIconControla
                                  roles={[1, 7, 8, 15]}
                                  icon={<Send sx={{ color: "white" }} />}
                                  backgroundColor={"#581845"}
                                  backgroundColorHover={"#6D1F56"}
                                  textTooltip={"Enviar a comité"}
                                  functionOnClick={() => {
                                    handleOpenModalConfirm(
                                      row.id,
                                      "¿Esta seguro de enviar al comité?",
                                      'OJO: Recuerde que para enviar al comité tienes que tener en cuenta las sugerencias de los trabajadores "' +
                                        '"',
                                      "Si, enviar a comité",
                                      "comiteTemplate"
                                    );
                                  }}
                                />
                              ) : (
                                ""
                              )}
                              {row.register_status === 7 ? (
                                <ButtonIconControla
                                  roles={[1, 7, 8, 15]}
                                  icon={<Email sx={{ color: "white" }} />}
                                  backgroundColor={"#FFC300"}
                                  backgroundColorHover={"#FFC300"}
                                  textTooltip={"Buzón de participación"}
                                  // functionOnClick={() => {
                                  // (window.location.href =
                                  //   "/mailbox")}}
                                  directionUrl={"/mailbox"}
                                  directionTarget={"_blank"}
                                />
                              ) : (
                                ""
                              )}

                              {row.register_status === 3 ? (
                                <ButtonIconControla
                                  roles={[1, 7, 8, 15]}
                                  icon={<Publish sx={{ color: "white" }} />}
                                  backgroundColor={"#169073"}
                                  backgroundColorHover={"#1AA483"}
                                  textTooltip={"Publicar"}
                                  functionOnClick={() => {
                                    handleOpenModalConfirm(
                                      row.id,
                                      "¿Esta seguro de publicar?",
                                      'Ingresa en el documento la fecha del acta de comité en el cuál se aprueba el RISST"' +
                                        row.title +
                                        '"',
                                      "Si, publicar",
                                      "publicTemplate"
                                    );
                                  }}
                                />
                              ) : (
                                ""
                              )}

                              {row.register_status === 4 ? (
                                <ButtonIconControla
                                  roles={[1, 7, 8]}
                                  icon={<Archive sx={{ color: "white" }} />}
                                  backgroundColor={"#F2994A"}
                                  backgroundColorHover={"#FF881E"}
                                  textTooltip={"Archivar"}
                                  functionOnClick={() =>
                                    handleOpenModalConfirm(
                                      row.id,
                                      "¿Esta seguro de archivar el documento?",
                                      'Documento a archivar:  "' +
                                        row.title +
                                        '"',
                                      "Si, archivar",
                                      "archivedTemplate"
                                    )
                                  }
                                />
                              ) : (
                                ""
                              )}
                              {row.register_status !== 7 &&
                              row.register_status !== 3 &&
                              row.register_status !== 4 ? (
                                <ButtonIconControla
                                  icon={<Delete sx={{ color: "white" }} />}
                                  backgroundColor={"#EB5757"}
                                  backgroundColorHover={"#FF4040"}
                                  textTooltip={"Eliminar"}
                                  functionOnClick={() => {
                                    handleOpenModalConfirm(
                                      row.id,
                                      "¿Esta seguro de eliminar los datos del RISST?",
                                      "Una vez eliminado no se podra recuperar los datos de " +
                                        row.title,
                                      "Si, Eliminar",
                                      "deleteTemplate"
                                    );
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>

                    <TableBody
                      sx={{ display: documentType !== 1 ? "none" : "" }}
                    >
                      {rowsRisst.map((row) => (
                        <TableRow
                          hover
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell sx={{ color: purple }}>
                            {row.title}
                          </TableCell>
                          <TableCell sx={{ color: purple }}>
                            {row.register_status === 1 ? "Creado" : ""}
                            {row.register_status === 7
                              ? "Enviado a trabajadores"
                              : ""}
                            {row.register_status === 3
                              ? "Enviado a comité"
                              : ""}
                            {row.register_status === 4 ? "Publicado" : ""}
                            {row.register_status === 5 ? "Archivado" : ""}{" "}
                          </TableCell>
                          <TableCell sx={{ color: purple }}>
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                            >
                              <ButtonIconControla
                                icon={
                                  <VisibilityOutlined sx={{ color: "white" }} />
                                }
                                backgroundColor={"#305AD9"}
                                backgroundColorHover={"#0E4AFF"}
                                textTooltip={"Ver"}
                                directionUrl={row.document_url}
                                directionTarget={"_blank"}
                              />

                              {row.register_status === 1 ? (
                                <ButtonIconControla
                                  roles={[1, 3, 4, 7, 8, 15]}
                                  icon={
                                    <BorderColorOutlined
                                      sx={{ color: "white" }}
                                    />
                                  }
                                  backgroundColor={"#2D9CDB"}
                                  backgroundColorHover={"#33AEF4"}
                                  textTooltip={"Editar"}
                                  // el editar de activos debe enviar a un modal
                                  functionOnClick={() => {
                                    handleOpenModalUploadFileEdit(row.id);
                                  }}
                                />
                              ) : (
                                ""
                              )}

                              {row.register_status === 1 ? (
                                <ButtonIconControla
                                  roles={[1, 7, 8, 15]}
                                  icon={<Send sx={{ color: "white" }} />}
                                  backgroundColor={"#169073"}
                                  backgroundColorHover={"#1AA483"}
                                  textTooltip={"Enviar a trabajadores"}
                                  functionOnClick={() => {
                                    handleOpenModalConfirm(
                                      row.id,
                                      "¿Esta seguro de enviar a trabajdores?",
                                      'Una vez enviado no podra revertir la operación del documento:  "' +
                                        row.name +
                                        '"',
                                      "Si, Enviar a trabajadores",
                                      "workers"
                                    );
                                  }}
                                />
                              ) : (
                                ""
                              )}
                              {row.register_status === 7 ? (
                                <ButtonIconControla
                                  roles={[1, 7, 8, 15]}
                                  icon={<Send sx={{ color: "white" }} />}
                                  backgroundColor={"#581845"}
                                  backgroundColorHover={"#6D1F56"}
                                  textTooltip={"Enviar a comité"}
                                  functionOnClick={() => {
                                    handleOpenModalConfirm(
                                      row.id,
                                      "¿Esta seguro de enviar al comité?",
                                      'OJO: Recuerde que para enviar al comité tienes que tener en cuenta las sugerencias de los trabajadores "' +
                                        '"',
                                      "Si, enviar a comité",
                                      "comite"
                                    );
                                  }}
                                />
                              ) : (
                                ""
                              )}
                              {row.register_status === 7 ? (
                                <ButtonIconControla
                                  roles={[1, 7, 8, 15]}
                                  icon={<Email sx={{ color: "white" }} />}
                                  backgroundColor={"#FFC300"}
                                  backgroundColorHover={"#FFC300"}
                                  textTooltip={"Buzón de participación"}
                                  // functionOnClick={() => {
                                  // (window.location.href =
                                  //   "/mailbox")}}
                                  directionUrl={"/mailbox"}
                                  directionTarget={"_blank"}
                                />
                              ) : (
                                ""
                              )}

                              {row.register_status === 3 ? (
                                <ButtonIconControla
                                  roles={[1, 7, 8, 15]}
                                  icon={<Publish sx={{ color: "white" }} />}
                                  backgroundColor={"#169073"}
                                  backgroundColorHover={"#1AA483"}
                                  textTooltip={"Publicar"}
                                  functionOnClick={() => {
                                    handleOpenModalConfirm(
                                      row.id,
                                      "¿Esta seguro de publicar?",
                                      'Ingresa en el documento la fecha del acta de comité en el cuál se aprueba el RISST "' +
                                        row.title +
                                        '"',
                                      "Si, publicar",
                                      "public"
                                    );
                                  }}
                                />
                              ) : (
                                ""
                              )}

                              {row.register_status === 4 ? (
                                <ButtonIconControla
                                  roles={[1, 7, 8]}
                                  icon={<Archive sx={{ color: "white" }} />}
                                  backgroundColor={"#F2994A"}
                                  backgroundColorHover={"#FF881E"}
                                  textTooltip={"Archivar"}
                                  functionOnClick={() =>
                                    handleOpenModalConfirm(
                                      row.id,
                                      "¿Esta seguro de archivar el documento?",
                                      'Documento a archivar:  "' +
                                        row.title +
                                        '"',
                                      "Si, archivar",
                                      "archived"
                                    )
                                  }
                                />
                              ) : (
                                ""
                              )}
                              {row.register_status !== 7 &&
                              row.register_status !== 3 &&
                              row.register_status !== 4 ? (
                                <ButtonIconControla
                                  icon={<Delete sx={{ color: "white" }} />}
                                  backgroundColor={"#EB5757"}
                                  backgroundColorHover={"#FF4040"}
                                  textTooltip={"Eliminar"}
                                  functionOnClick={() => {
                                    handleOpenModalConfirm(
                                      row.id,
                                      "¿Esta seguro de eliminar los datos del RISST?",
                                      "Una vez eliminado no se podra recuperar los datos de " +
                                        row.title,
                                      "Si, Eliminar",
                                      "delete"
                                    );
                                  }}
                                />
                              ) : (
                                ""
                              )}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableBody
                      sx={{ display: documentType !== 2 ? "none" : "" }}
                    >
                      {totalRisst.map((row) => (
                        <TableRow
                          hover
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell sx={{ color: purple }}>
                            {row.title}
                          </TableCell>
                          <TableCell sx={{ color: purple }}>
                            {row.register_status === 1 ? "Creado" : ""}
                            {row.register_status === 7
                              ? "Enviado a trabajadores"
                              : ""}
                            {row.register_status === 3
                              ? "Enviado a comité"
                              : ""}
                            {row.register_status === 4 ? "Publicado" : ""}
                            {row.register_status === 5 ? "Archivado" : ""}
                          </TableCell>
                          <TableCell sx={{ color: purple }}>
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                            >
                              <ButtonIconControla
                                roles={[1, 7, 8]}
                                icon={<Unarchive sx={{ color: "white" }} />}
                                backgroundColor={"#FF5733"}
                                backgroundColorHover={"#FF5733"}
                                textTooltip={"Desarchivar"}
                                functionOnClick={() =>
                                  handleOpenModalConfirm(
                                    row.id,
                                    "¿Esta seguro de desarchivar el documento?",
                                    'Documento a desarchivar:  "' +
                                      row.title +
                                      '"',
                                    "Si, desarchivar",
                                    "unarchived"
                                  )
                                }
                              />
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>

      {/* Dialog subir archivo */}
      <Dialog
        open={openModalUploaFile}
        onClose={() => setOpenModalUploadFile(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            RISST - Reglamento interno de trabajo{" "}
          </Typography>
        </DialogTitle>

        <form onSubmit={handleCreateRisst}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Elaborado por:"}
                  inputType={"text"}
                  inputValue={setDevelop}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de elaboración:"}
                  inputType={"date"}
                  inputValue={setDate}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Título documento"}
                  inputType={"text"}
                  inputValue={setTitle}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Subir Archivo"}
                  inputType={"file"}
                  inputValue={setDocumentUrl}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              // color="success"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalUploadFile(false)}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                // backgroundColor: '#169073',
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog editar  archivo */}
      <Dialog
        open={openModalUploaFileEdit}
        onClose={() => setOpenModalUploadFileEdit(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Edita - RISST - Reglamento interno de trabajo{" "}
          </Typography>
        </DialogTitle>

        <form onSubmit={handleUpdateRisst}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Elaborado por:"}
                  inputType={"text"}
                  inputValue={setDevelop}
                  value={develop}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de elaboración:"}
                  inputType={"date"}
                  inputValue={setDate}
                  modalType={true}
                  value={date}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Título documento"}
                  inputType={"text"}
                  inputValue={setTitle}
                  modalType={true}
                  value={title}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Subir Archivo"}
                  inputType={"file"}
                  inputValue={setDocumentUrl}
                  modalType={true}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              // color="success"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalUploadFileEdit(false)}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                // backgroundColor: '#169073',
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Editar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog plantilla */}
      <Dialog
        open={openModalTemplate}
        onClose={() => setOpenModalTemplate(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Te presentamos un modelo para facilitar la elaboración de tu
            documento
          </Typography>
        </DialogTitle>

        <form onSubmit={handleOpenModalTemplateView}>
          <DialogActions>
            <Button
              variant="contained"
              // color="success"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalTemplate(false)}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                // backgroundColor: '#169073',
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Ver
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Modelo de plantilla */}
      <Dialog
        open={openModalTemplateView}
        onClose={() => setOpenModalTemplateView(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0, marginTop: "15px" }}
      >
        <DialogTitleControla titleText="RISST" />

        <form onSubmit={handleUseTemplate}>
          <DialogContent>
            <Grid container>
              <Grid item md={12}>
                <StyledModalSubtitle>
                  I.RESUMEN EJECUTIVO DE LA ACTIVIDAD DE LA EMPRESA
                </StyledModalSubtitle>
              </Grid>
              <Grid item md={12}>
                <StyledModalSubtitle>
                  II.OBJETIVOS Y ALCANCES
                </StyledModalSubtitle>
              </Grid>
              <Grid item>
                <ul type="1. ">
                  <li>Objetivos</li>
                  <li>Alcances</li>
                </ul>
              </Grid>
              <Grid item md={12}>
                <StyledModalSubtitle>
                  III. LIDERAZGO Y COMPROMISOS, Y POLÍTICA DE SEGURIDAD Y SALUD{" "}
                </StyledModalSubtitle>
              </Grid>
              <Grid item>
                <ul type="1. ">
                  <li>Liderazgo y compromisos</li>
                  <li>Política de seguridad y salud</li>
                </ul>
              </Grid>
              <Grid item md={12}>
                <StyledModalSubtitle>
                  IV. ATRIBUCIONES Y OBLIGACIONES DEL EMPLEADOR, DE LOS
                  SUPERVISORES, DEL COMITÉ DE SEGURIDAD Y SALUD, DE LOS
                  TRABAJADORES Y DE LOS EMPLEADORES QUE LES BRINDAN SERVICIOS SI
                  LOS HUBIERA{" "}
                </StyledModalSubtitle>
              </Grid>
              <Grid item>
                <ul type="1. ">
                  <li>Funciones y responsabilidades</li>
                  <li>Organización interna de seguridad y salud ocupaciona</li>
                  <li>
                    Implementación de registros y documentación del Sistema de
                    Gestión de Seguridad y salud ocupacional
                  </li>
                  <li>
                    Funciones y responsabilidades de las empresas, entidades
                    públicas o privadas que brindan servicios
                  </li>
                </ul>
              </Grid>
              <Grid item md={12}>
                <StyledModalSubtitle>
                  V.ESTÁNDARES DE SEGURIDAD Y SALUD EN LAS OPERACIONES{" "}
                </StyledModalSubtitle>
              </Grid>
              <Grid item md={12}>
                <StyledModalSubtitle>
                  VI. ESTÁNDARES DE SEGURIDAD Y SALUD EN LOS SERVICIOS Y
                  ACTIVIDADES CONEXAS{" "}
                </StyledModalSubtitle>
              </Grid>
              <Grid item md={12}>
                <StyledModalSubtitle>
                  VII. PREPARACIÓN Y RESPUESTA A EMERGENCIAS
                </StyledModalSubtitle>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              // color="success"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalTemplateView(false)}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                // backgroundColor: '#169073',
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Usar plantilla
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* dialogo de información */}
      <Dialog
        open={openModalInfo}
        onClose={() => setOpenModalInfo(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{
          padding: "20px",
        }}
      >
        <DialogTitleControla
          titleText="REGLAMENTO INTERNO DE SEGURIDAD Y SALUD OCUPACIONAL
(RISST)"
        />
        <DialogContent>
          <Grid container>
            <Grid item md={12}>
              <StyledModalTitle>1. Introducción</StyledModalTitle>
            </Grid>

            <Grid item>
              El Reglamento Interno de Seguridad y Salud Ocupacional (RISST) es
              un documento que forma parte del Sistema de Gestión de Seguridad y
              Salud Ocupacional, y que tiene por finalidad promover la
              instauración de una cultura de prevención de riesgos laborales. Su
              elaboración y mejora deben estar siempre documentadas. A
              continuación, te presentamos sus principales características.
            </Grid>
            <Grid item md={12}>
              <StyledModalTitle>
                2. Consideraciones generales:{" "}
              </StyledModalTitle>
            </Grid>
            <StyledModalSubtitle>¿Quién elabora el RISST?</StyledModalSubtitle>
            <Grid item>
              El presente documento es elaborado por el empleador, y debe
              contener la estructura mínima señalada en el artículo 74° del
              Decreto Supremo N° 005-2012-TR, Reglamento de la Ley N° 29783, Ley
              de Seguridad y Salud en el Trabajo que es:
              <ol type="a">
                <li>Objetivos y alcances.</li>
                <li>
                  Liderazgo, compromisos y la política de seguridad y salud.
                </li>
                <li>
                  Atribuciones y obligaciones del empleador, de los
                  supervisores, del comité de seguridad y salud, de los
                  trabajadores y de los empleadores que les brindan servicios si
                  las hubiera.{" "}
                </li>
                <li>Estándares de seguridad y salud en las operaciones.</li>
                <li>
                  Estándares de seguridad y salud en los servicios y actividades
                  conexas.{" "}
                </li>
                <li>Preparación y respuesta a emergencias.</li>
              </ol>
            </Grid>
            <StyledModalSubtitle>
              ¿Qué empresas están obligadas a elaborar un RISST?
            </StyledModalSubtitle>
            <Grid item>
              Para empresas con menos de 20 trabajadores la elaboración del
              RISST es opcional. Sin embargo, para las empresas que cuenten con
              20 o más trabajadores es obligatoria la elaboración del RISST.
            </Grid>
            <StyledModalSubtitle>
              ¿Cada cuánto se debe actualizar el RISST?{" "}
            </StyledModalSubtitle>
            <Grid item>
              Como documento del Sistema de Gestión de Seguridad y Salud
              Ocupacional, el RISST debe ser revisado y actualizado de manera
              periódica, de acuerdo a los cambios que puedan surgir en los
              procesos y operaciones, o en los documentos que contiene el RISST.
              Asimismo, puede ser revisado periódicamente de acuerdo a lo que
              determine el comité.
            </Grid>
            <StyledModalSubtitle>
              ¿Quién vigila el cumplimiento del RISST?
            </StyledModalSubtitle>
            <Grid item>
              Los miembros del Comité de Seguridad y Salud Ocupacional de la
              empresa son los encargados de vigilar su cumplimiento.
            </Grid>
            <StyledModalSubtitle>
              ¿A quiénes se entrega el RISST?
            </StyledModalSubtitle>
            <Grid item>
              El RISST y sus modificaciones y/o actualizaciones se deben
              entregar a:
              <ol>
                <li>
                  Todos los trabajadores, considerando no sólo a los de planilla
                  sino aquellos que en modalidades formativas (practicantes),
                  recibo por honorarios, trabajadores de las empresas
                  contratistas o subcontratistas.
                </li>
              </ol>
            </Grid>
            <StyledModalSubtitle>
              ¿Cómo se entrega el RISST?{" "}
            </StyledModalSubtitle>
            <Grid item>
              El RISST puede ser entregado en formato digital o físico, siempre
              y cuando su entrega sea debidamente documentada o evidenciada.
            </Grid>

            <Grid item md={12}>
              <StyledModalTitle>3. Fiscalización del RISST</StyledModalTitle>
            </Grid>

            <StyledModalSubtitle>
            ¿Qué aspectos principales considerará la autoridad ante una actuación inspectiva?            </StyledModalSubtitle>
            <Grid item>
            <ol>
                <li>
                Que se cumpla con todos los requisitos para su elaboración señalados anteriormente.
                </li>
                <li>
                Que sea revisado y aprobado por el Comité de Seguridad y Salud Ocupacional.
                </li>
                <li>
                Que sea entregado a todos los trabajadores de la empresa, incluidos personas en formación laboral, ya sea de forma digital o física, pero que se cuente con un cargo de entrega. 
                </li>
                <li>
                Que además, sea entregado a los trabajadores de las empresas contratistas y/o subcontratistas, personsa independientes y visitantes en forma digital o física bajo cargo.
                </li>
                <li>
                Que esté actualizado y/o modificado de acuerdo a la actualización o modificación de los procesos operativos por la introducción de nuevas tecnologías, en coherencia con el IPERC.
                </li>
                <li>
                Que contemple todas las actividades de la empresa, ya sea operativas, administrativas, propias y contratadas. 
                </li>
              </ol>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModalInfo(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Index;
