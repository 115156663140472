import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function getRegistersAudit() {
  const url = "register_general_audits";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function editRegisterAudit({ idRegister }) {
  const url = "register_general_audits/" + idRegister;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function updateRegisterAudit({ idRegister, code }) {
  const url = "register_general_audits/" + idRegister;
  const data = {
    code: code,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}
function createRegisterAudit({ code }) {
  const url = "register_general_audits";
  const data = { code: code, status: "Creado" };

  console.log(url);
  console.log(data);
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function getAddAuditioners({ idRegister }) {
  const url = "sec/register_general_audits_add_auditioners/" + idRegister;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

//agrega responsables en la tabla
function addResponsibleAddAudit({ idUser, idRegister }) {
  const url = idRegister + "/responsible";
  const data = {
    id_users: idUser,
  };
  console.log(data);
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function getResponsibleAudit({ idRegister }) {
  const url = idRegister + "/responsible";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

//Elimina responsables de la tabla
function deleteResponsibleAudit({ idRegister, idUser }) {
  const url = "responsible/detail/" + idUser;
  const data = {
    id_users: idUser,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "DELETE",
    url: url,
  });
}

//firma responsables
function signResponsibleAudit({ idUserId, idUser }) {
  const url = "responsible/detail/" + idUser;
  const data = {
    "id_users": idUserId,
    "firm": 1,
    "firm_date": null
  }
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  })
}
///Auditores y registros
function getAuditorsAudit({ idRegister }) {
  const url = "sec/register_general_audits_add_auditioners/" + idRegister;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function addAuditorsAudit({ idRegister, auditName, registerNumber }) {
  const url = "sec/register_general_audits_add_auditioners/" + idRegister;
  const data = {
    name: auditName, 
    register_number: registerNumber
  }
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function deleteAuditorsAudit({ idSubregister }) {
  const url = "sec/register_general_audits_add_auditioners/detail/" + idSubregister;
  console.log(url);
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "DELETE",
    url: url,
  });
}
//Auditores y registros

//Auditores y registros responsables
function getAuditorsResponsibles({ idRegister }) {
  const url =
    "sec/register_general_audits_add_auditioners_responsibles/" + idRegister;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function addAuditorsResponsibles({ idRegister, date, auditProcesses, responsibleName }) {
  const url =
    "sec/register_general_audits_add_auditioners_responsibles/" + idRegister;
  const data = {
    date: date,
    audit_processes: auditProcesses,
    responsible_name: responsibleName
  }
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function deleteAuditorsResponsibles({ idSubregister }) {
  const url =
    "sec/register_general_audits_add_auditioners_responsibles/detail" + idSubregister;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "DELETE",
    url: url,
  });
}

// MEDIDAS CORRECTIVAS

// Crear medidas Correctivas

async function createCorrectiveActions({
  idRegistersAudits,
  idAuditsCorrectiveActionsStatus,
  idUsers,
  nameUserResponsible,
  documentUserResponsible,
  timeStampStart,
  timestampEnd,
  description
}){
  const url = "sec/"
}

//Auditores y registros responsables
const MyRegistersAudits = {
  getRegistersAudit,
  editRegisterAudit,
  updateRegisterAudit,
  createRegisterAudit,
  deleteResponsibleAudit,
  getAddAuditioners,
  addResponsibleAddAudit,
  getResponsibleAudit,
  getAuditorsAudit,
  addAuditorsAudit,
  deleteAuditorsAudit,
  getAuditorsResponsibles,
  addAuditorsResponsibles,
  deleteAuditorsResponsibles,
  signResponsibleAudit,
};

export default MyRegistersAudits;
