// juntas los new work accidents onemptied, two, thre, frour
import { React, useEffect, useState } from "react";

import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import SideNav from "../../../../components/sidenav/sideNav";
import Box from "@mui/material/Box";
import {
  Alert,
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Add,
  ArrowBack,
  Delete,
  EditOutlined,
  HistoryEduOutlined,
  InfoOutlined,
  Print,
  VisibilityOutlined,
} from "@mui/icons-material";
import TitleText from "../../../../components/text/titleText";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import ButtonControla from "../../../../components/buttons/buttonController";
import CompanyLogoControla from "../../../../components/images/companyLogoControla";
import SearchUsersControla from "../../../../components/search/searchUsersControla";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import MyAccidentsOccupationalsIncidents from "../../../../api/MyAccidentsOccupationalsIncidents";
import CloseIcon from "@mui/icons-material/Close";
import SearchButtonControla from "../../../../components/search/searchButtonControla";
import SelectControla from "../../../../components/selects/selectControla";
import MyUsers from "../../../../api/MyUsers";
import MyConfigurationApi from "../../../../api/MyConfigurationApi";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";

const colorTitle = "#9191B0";
const blue = "#034AFF";
const red = "#EB5757";

function Edit() {
 

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="/documentation/registers/mypes/occupationaldiseases" />     
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
              <TitleText
                text={
                  "Registro de enfermedades ocupacionales"
                }
              ></TitleText>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <InputControlaVertical
                text={"Nro registro"}
                inputType={"text"}
                // inputValue={setCode}
                // value={code}
              />
            </Grid>
          </Grid>
        </Box>
        <form onSubmit={'handleUpdateOccupationalsDiseases'}>
          <Box sx={{ flexGrow: 1 }}>
            {/* DATOS DEL TRABAJADOR  */}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Datos del trabajador
                </InputLabel>
                <Typography  variant="caption" display="block" gutterBottom sx={{color: '#F2994A', marginLeft:1}}>
                Este campo es obligatorio
                </Typography>
              </Box>
              <Box>
                {/* {registerStatus === true ? ( */}
                  <ButtonControla
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#2D9CDB"}
                    backgroundColorHover={"#35B4FB"}
                    textButton={"Agregar datos"}
                    functionOnClick={'handleOpenModalUploadAddDates'}
                  />
                {/* ) : (
                  ""
                )} */}
              </Box>
            </Stack>
            {/* tabla para mostrar accidents workers */}
            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nombre y Apellido
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Documento de Identidad
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Área
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Turno
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        N.º de horas trabajadas antes del accidente
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Tiempo de experiencia en el puesto de trabajo
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {/* <TableBody>
                    {accOccIncWorkers.map((row) => (
                      <TableRow
                        hover
                        key={row.id_users}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.first_name + " " + row.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.document}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.area}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.shift}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.work_hours_before}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.work_experience}
                        </TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                            spacing={1}
                          >
                            {/* <ButtonIconControla
                              icon={
                                <VisibilityOutlined sx={{ color: "white" }} />
                              }
                              backgroundColor={"#305AD9"}
                              backgroundColorHover={"#0E4AFF"}
                              textTooltip={"Ver"}
                              directionUrl={
                                "/settings/users/edit?idUsers=" + row.id_users
                              }
                              directionTarget={"_blank"}
                              // functionOnClick={() => ""}
                            /> 
                            {registerStatus === true ? (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id_registers_accidents_occupationals_incidents_workers,
                                    "¿Esta seguro de eliminar los datos del trabajador?",
                                    "Una vez eliminado no se podra recuperar los datos del trabajador",
                                    "Si, Eliminar",
                                    "deleteWorkers"
                                  );
                                }}
                                // functionOnClick={() =>
                                //   handleDeleteAccOccIncsWorkers(
                                //     row.id_registers_accidents_occupationals_incidents_workers
                                //   )
                                // }
                              />
                            ) : (
                              ""
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody> */}
                </Table>
              </TableContainer>
            </Grid>

            {/* Accidente de trabajo indicente peligro incidente */}
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={12} md={12}>
                <SubtitleText
                  text={"Accidente de trabajo/incidente peligroso/incidente"}
                  color={blue}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {/* <SelectControla
                  text="Seleccione si es accidente de trabajo/incidente peligroso/incidente:"
                  inputValue={setTypesRegistersAccOcc}
                  modalType={false}
                  required
                  value={typesRegistersAccOcc}
                  childrenRows={rowsTypesRegisters.map((row) => (
                    <MenuItem
                      key={
                        row.id_registers_accidents_occupationals_incidents_types
                      }
                      value={
                        row.id_registers_accidents_occupationals_incidents_types
                      }
                    >
                      {row.type}
                    </MenuItem>
                  ))}
                /> */}
              </Grid>
              <Typography  variant="caption" display="block" gutterBottom sx={{color: '#F2994A', ml:2 }}>
                Este campo es obligatorio
                </Typography>
            </Grid>

            {/* INVESTIGACIÓN DEL ACCIDENTE DE TRABAJO */}
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={12} md={12}>
                <SubtitleText text={"Investigación"} color={blue} />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                <InputControlaVertical
                  text={"Fecha y hora de ocurrencia del accidente"}
                  inputType={"datetime-local"}
                  // inputValue={setTimestampAccident}
                  // value={timestampAccident}
                  required
                />
                 <Typography  variant="caption" display="block" gutterBottom sx={{color: '#F2994A' }}>
                Este campo es obligatorio
                </Typography>
              </Grid>
              
              <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                <InputControlaVertical
                  text={"Fecha de inicio de la investigación"}
                  inputType={"date"}
                  // inputValue={setTimestampInvestigation}
                  // value={timestampInvestigation}
                  required
                />
                 <Typography  variant="caption" display="block" gutterBottom sx={{color: '#F2994A'}}>
                Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                <InputControlaVertical
                  text={"Lugar exacto donde ocurrió el accidente"}
                  inputType={"text"}
                  // inputValue={setLocation}
                  // value={location}
                  required
                />
                 <Typography  variant="caption" display="block" gutterBottom sx={{color: '#F2994A'}}>
                Este campo es obligatorio
                </Typography>
              </Grid>
            </Grid>

            {/* Investigación del accidente de trabajo */}
            {/* <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={12} md={12}>
                <SubtitleText
                  text={"Investigación"}
                  color={blue}
                />
              </Grid>
            </Grid> */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                {/* <SelectControla
                  text="Gravedad del accidente de trabajo:"
                  inputValue={setSeverityAccidents}
                  modalType={false}
                  required
                  value={severityAccidents}
                  childrenRows={rowsSeverityWorkAccidents.map(
                    (rowsRegistersAccidents) => (
                      <MenuItem
                        key={
                          rowsRegistersAccidents.id_registers_accidents_occupationals_incidents_severities
                        }
                        value={
                          rowsRegistersAccidents.id_registers_accidents_occupationals_incidents_severities
                        }
                      >
                        {
                          rowsRegistersAccidents.register_accident_occupational_incident_severity
                        }
                      </MenuItem>
                    )
                  )}
                /> */}
                 <Typography  variant="caption" display="block" gutterBottom sx={{color: '#F2994A'}}>
                Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <ButtonIconControla
                  icon={<InfoOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#E34034"}
                  backgroundColorHover={"#D43B30"}
                  textTooltip={"Seleccionar solo en caso de accidente"}
                  // functionOnClick={() => console.log("ver ")}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                // sx={{
                //   display: severityAccidents === 2 ? "" : "none",
                // }}
              >
                {/* <SelectControla
                  text="Gravedad del accidente incapacitante:"
                  inputValue={setSeverityIncidents}
                  modalType={false}
                  value={severityIncidents}
                  childrenRows={rowsSeverityIncidents.map(
                    (rowsSeverityIncidents) => (
                      <MenuItem
                        key={
                          rowsSeverityIncidents.id_registers_accidents_severities_incident
                        }
                        value={
                          rowsSeverityIncidents.id_registers_accidents_severities_incident
                        }
                      >
                        {
                          rowsSeverityIncidents.register_accident_severity_incident
                        }
                      </MenuItem>
                    )
                  )}
                /> */}
                <Typography  variant="caption" display="block" gutterBottom sx={{color: '#F2994A'}}>
                Este campo es obligatorio
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"N° días de descanso médico"}
                  inputType={"number"}
                  // inputValue={setMedicalLeaveDays}
                  // value={medicalLeaveDays}
                  required
                />
                <Typography  variant="caption" display="block" gutterBottom sx={{color: '#F2994A'}}>
                Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"N° de trabajadores afectados"}
                  inputType={"number"}
                  // inputValue={setAffectedWorkers}
                  // value={affectedWorkers}
                  required
                />
                <Typography  variant="caption" display="block" gutterBottom sx={{color: '#F2994A'}}>
                Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Describir parte del cuerpo lesionado(de ser el caso)"}
                  inputType={"textArea"}
                  // inputValue={setInjuredBodyPartDescription}
                  // value={injuredBodyPartDescription}
                  // required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Descripción del suceso"}
                  inputType={"textArea"}
                  // inputValue={setDescriptionWork}
                  // value={descriptionWork}
                  // required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={
                    "Descripción de las causas que originaron el accidente de trabajo"
                  }
                  inputType={"textArea"}
                  // inputValue={setcausesDescription}
                  // value={causesDescription}
                  // required
                />
              </Grid>
            </Grid>

            {/* medidas correctivas */}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Medidas correctivas
                </InputLabel>
                <Typography  variant="caption" display="block" gutterBottom sx={{color: '#F2994A', marginLeft:1}}>
                Este campo es obligatorio
                </Typography>
              </Box>
              <Box>
                {/* {registerStatus === true ? ( */}
                  <ButtonControla
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#2D9CDB"}
                    backgroundColorHover={"#35B4FB"}
                    textButton={"Agregar medidas correctivas"}
                    functionOnClick={'handleOpenModalUploadCorrectiveActions'}
                  />
                {/* ) : (
                  ""
                )} */}
              </Box>
            </Stack>

            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Descripción de la medida correctiva
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Responsable
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha de investigación
                      </TableCell>
                      {/* <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha de fin
                      </TableCell> */}
                    </TableRow>
                  </TableHead>

                  {/* <TableBody>
                    {correctivesActions.map((rows) => (
                      <TableRow
                        hover
                        key={rows.id_users}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {rows.description}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rows.first_name + " " + rows.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rows.timestamp_start.substring(0, 10)}
                        </TableCell>
                        {/* <TableCell component="th" scope="row">
                          {rows.timestamp_end}
                        </TableCell> 

                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            <ButtonIconControla
                              icon={
                                <VisibilityOutlined sx={{ color: "white" }} />
                              }
                              backgroundColor={"#305AD9"}
                              backgroundColorHover={"#0E4AFF"}
                              textTooltip={"Ver"}
                              directionUrl={
                                "/settings/users/edit?idUsers=" + rows.id_users
                              }
                              directionTarget={"_blank"}
                              // functionOnClick={() => ""}
                            /> 
                            {registerStatus === true ? (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    rows.id_registers_accidents_occupationals_incidents_measures,
                                    "¿Esta seguro de eliminar los datos de la medida correctiva?",
                                    "Una vez eliminado no se podra recuperar los datos de la medida correctiva",
                                    "Si, Eliminar",
                                    "deleteMeasures"
                                  );
                                }}
                                // functionOnClick={() =>
                                //   handleDeleteCorrectivesActions(
                                //     rows.id_registers_accidents_occupationals_incidents_measures
                                //   )
                                // }
                              />
                            ) : (
                              ""
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody> */}
                </Table>
              </TableContainer>
            </Grid>

            {/* Datos refrentes a la enfermedad ocupacional */}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Enfermedad ocupacional / incidente peligroso / incidente
                </InputLabel>
                <Typography  variant="caption" display="block" gutterBottom sx={{color: '#F2994A', marginLeft:1}}>
                Este campo es obligatorio
                </Typography>
              </Box>
              <Box sx={{ marginRight: 55 }}>
                <ButtonIconControla
                  icon={<InfoOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#FFC300"}
                  backgroundColorHover={"#FACB32"}
                  textTooltip={"Ver enfermedades ocupacionales"}
                  functionOnClick={'handleOpenModalTypeAgentsTable'}
                />
              </Box>
              <Box>
                {/* {registerStatus === true ? ( */}
                  <ButtonControla
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#2D9CDB"}
                    backgroundColorHover={"#35B4FB"}
                    textButton={"Agregar datos"}
                    functionOnClick={'handleOpenModaOccupationalDiseases'}
                  />
                {/* ) : (
                  ""
                )} */}
              </Box>
            </Stack>
            {/* tabla para mostrar accidents workers */}
            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Tipo de agente que originó la enfermedad ocupacional
                      </TableCell>                     
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nombre de la enfermedad ocupacional
                      </TableCell>                    
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Medidas correctivas a implementar
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Responsable{" "}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha de ejecución
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {/* <TableBody>
                    {relsDAgentsTypes.map((row) => (
                      <TableRow
                        hover
                        key={row.id_users}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.agent_type}
                        </TableCell>
                   
                        <TableCell component="th" scope="row">
                          {row.disease_name}
                        </TableCell>                      
                        <TableCell component="th" scope="row">
                          {row.measures}
                        </TableCell>
                       
                        <TableCell component="th" scope="row">
                          {row.first_name + " " + row.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.timestamp_execution.substring(0, 10)}
                        </TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                            spacing={1}
                          >
                            <ButtonIconControla
                              icon={
                                <VisibilityOutlined sx={{ color: "white" }} />
                              }
                              backgroundColor={"#305AD9"}
                              backgroundColorHover={"#0E4AFF"}
                              textTooltip={"Ver mas información"}
                              functionOnClick={handleOpenModalMoreAgents}
                            />
                            {registerStatus === true ? (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id_registers_accidents_occupationals_incidents_rels_agents_types,
                                    "¿Esta seguro de eliminar los datos de la enfermedad ocupacional?",
                                    "Una vez eliminado no se podra recuperar los datos de  la enfermedad ocupacional",
                                    "Si, Eliminar",
                                    "deleteOccupationalDiseases"
                                  );
                                }}
                                // functionOnClick={() =>
                                //   handleDeleteAgentsTypes(
                                //     row.id_registers_accidents_occupationals_incidents_rels_agents_types
                                //   )
                                // }
                              />
                            ) : (
                              ""
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody> */}
                </Table>
              </TableContainer>
            </Grid>

            {/* responsables del registro y de la investigación */}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Responsables del registro y de la investigación
                </InputLabel>
                <Typography  variant="caption" display="block" gutterBottom sx={{color: '#F2994A', marginLeft:1}}>
                Este campo es obligatorio
                </Typography>
              </Box>
             
              <Box>
                {/* {registerStatus === true ? ( */}
                  <ButtonControla
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#2D9CDB"}
                    backgroundColorHover={"#35B4FB"}
                    textButton={"Agregar responsables"}
                    functionOnClick={
                      'handleOpenModalUploadResponsibleRegistration'
                    }
                  />
                {/* ) : (
                  ""
                )} */}
              </Box>
            </Stack>

            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nombre y Apellido
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Documento de identidad
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                       Fecha de firma
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Firma
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {/* <TableBody>
                    {responsibleWorkers.map((row) => (
                      <TableRow
                        hover
                        key={row.id_users}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.first_name + " " + row.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.document}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.firm_date === null ? '' : row.firm_date.substring(0,19)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.firm === 0 ? (
                            "No firmado" && row.id_users === idUserLogged ? (
                              <ButtonIconControla
                                icon={
                                  <HistoryEduOutlined sx={{ color: "white" }} />
                                }
                                backgroundColor={"#F1C40F"}
                                backgroundColorHover={"#F4D03F"}
                                textTooltip={"Firmar"}
                                functionOnClick={() =>
                                  handleOpenModalSignResponsible(
                                    row.id_registers_accidents_occupationals_incidents_responsible
                                  )
                                }
                              />
                            ) : (
                              "No firmado"
                            )
                          ) : (
                            "Firmado"
                          )}
                        </TableCell>

                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >                          
                            {registerStatus === true ? (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id_registers_accidents_occupationals_incidents_responsible,
                                    "¿Esta seguro de eliminar los datos del trabajador responsable?",
                                    "Una vez eliminado no se podra recuperar los datos  del trabajador responsable",
                                    "Si, Eliminar",
                                    "deleteResponsible"
                                  );
                                }}                             
                              />
                            ) : (
                              ""
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody> */}
                </Table>
              </TableContainer>
            </Grid>

            {/* Botones de impresión, guardar y cancelar */}
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                whidth: "100%",
                justifyContent: "space-evenly",
                paddingY: 4,
              }}
            >
         
              <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<ArrowBack sx={{ color: "white" }} />}
                  backgroundColor={"#EB5757"}
                  backgroundColorHover={"#FF4040"}
                  textButton={"Regresar"}
                  functionOnClick={() =>
                    (window.location.href = "/documentation/registers/mypes/accidentsoccupationalsincidents")
                  }
                />
              </Grid>

              <Grid item xs={12} md={2}>
                {/* {registerStatus === true ? ( */}
                  <ButtonControla
                    iconButton={<EditOutlined sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Actualizar registro"}
                    typeButton="submit"
                    // functionOnClick={handleUpdateOccupationalsDiseases}
                  />
                {/* ) : (
                  ""
                )} */}
              </Grid>

              <Grid item xs={12} md={2}>
                {/* {registerStatus === true ? ( */}
                  <ButtonControla
                    iconButton={<CloseIcon sx={{ color: "white" }} />}
                    backgroundColor={"black"}
                    backgroundColorHover={"#C4C4C4"}
                    textButton={"Cerrar registro"}
                    functionOnClick={'handleAccidentsOcupationalEnd'}
                  />
                {/* ) : (
                  ""
                )} */}
              </Grid>
            </Grid>
          </Box>
        </form>
      </Container>     
    </Box>
  );
}

export default Edit;
