import * as React from "react";
import SubtitleText from "../components/text/subtitleText";
import TitleText from "../components/text/titleText";
import Container from "@mui/material/Container";
import SideNav from "../components/sidenav/sideNav";
import Box from "@mui/material/Box";

import { Grid } from "@mui/material";
import "../index.css";
import CardControla from "../components/cards/cardControla";
import BannerButtonControla from "../components/cards/bannerButtonControla";

const colorTitle = "#9191B0";
// Recuerda agendar la reunión mensual de tu Comité

function Documentation() {
  return (
    <Box sx={{ display: "flex" }}>
      <SideNav />

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={6} sm={5} md={6} lg={6} xl={12}>
                              {/* NO ELIMINAR - TEMPORALMENTE COMENTADO */}

              {/* <SubtitleText
                text={"Documentación"}
                color={colorTitle}
              ></SubtitleText> */}
              <TitleText text={"Documentación"} color={colorTitle}></TitleText>
            </Grid>
            <Grid  item xs={12} md={12}>              
              <BannerButtonControla 
                color1={'#090979'}
                color2={'#00d4ff'}
                title={'Recuerda llevar al día tus documentos '}
                textButton={'Comenzar ahora'}
                image={"/assets/images/doccreationimage.png"}            
                />  
              
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/documents"}
                text={"Herramientas de gestión de riesgo"}
                img={"/assets/images/docimagedocuments.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/programs"}
                text={"Programas"}
                img={"/assets/images/docimageprograms.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/registers"}
                text={"Registros"}
                img={"/assets/images/docimageregister.png"}
              />
            </Grid>
            {/* <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/registers/mypes"}
                text={"Registros pequeñas empresas"}
                img={"/assets/images/banners/documents/mypes.png"}
              />
            </Grid> */}
            
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/legalmatrix"}
                text={"Matriz legal"}
                img={"/assets/images/legalmatrix.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/riskmap"}
                text={"Mapa de riesgos"}
                img={"/assets/images/riskmap.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/securitysheet"}
                text={"Hojas de seguridad"}
                img={"/assets/images/securitysheet.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/risst"}
                text={"RISST"}
                img={"/assets/images/risst.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/rit"}
                text={"RIT"}
                img={"/assets/images/bannerRIT.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/annualplan"}
                text={"Plan anual de SSO"}
                img={"/assets/images/plananualsso.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/ssopolitics"}
                text={"Politicas de SSO"}
                img={"/assets/images/politicassso.png"}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default Documentation;
