import React, { useState } from 'react';
import SubtitleText from '../components/text/subtitleText';
import Container from '@mui/material/Container';
import SideNav from "../components/sidenav/sideNav";
import Box from '@mui/material/Box';
import { Grid, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Checkbox, TablePagination, Modal, Typography } from '@mui/material';
import { Add, FeaturedVideoOutlined, TaskAlt, TaskOutlined, BorderColorOutlined, VisibilityOutlined, Archive, MoreVert } from '@mui/icons-material';
import TitleText from '../components/text/titleText';
import BannerControla from '../components/cards/bannerControla';
const colorTitle = "#9191B0";

function createData(name) {
    return { name };
}

const rows = [
    createData('Estandar 1'),
    createData('Estandar 2'),
    createData('Estandar 3'),
    createData('Estandar 4'),
    createData('Estandar 5'),
    createData('Estandar 6'),
];

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};



function Index() {

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(2);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const ModalModels = (
        < div sx={{ width: '800px' }}>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        <TitleText text={"Plantillas para estándares"} />
                    </Typography>
                    <TableContainer sx={{ maxHeight: 200 }}>
                        <Table sx={{ width: '100%' }} aria-label="simple table">
                            <TableBody>
                                {rows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => (
                                        <TableRow hover
                                            key={row.name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >

                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            <TableCell align="center"><IconButton><VisibilityOutlined /></IconButton></TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
            </Modal>
        </div >
    );

    return (

        <Box sx={{ display: "flex", height: '100%' }}>
            <SideNav />

            <Container>

                {/* <Box > */}
                <Grid container>
                    <Grid item xs={12} md={12}>
                        <SubtitleText text={"Documentación"} color={colorTitle}></SubtitleText>
                        <BannerControla image='/assets/images/banners/documents/standarBanner.png' color2='#2F67BC' color1='#8FBCFF' text='Estandares' />
                    </Grid>
                </Grid>
                {/* xs, extra-small: 0px
                    sm, small: 600px
                    md, medium: 900px
                    lg, large: 1200px
                    xl, extra-large: 1536px */}
                <Grid container sx={{ mt: 2 }}>
                    <Grid item xs={12} sm={8} md={6} lg={6} xl={6}>
                        <Grid container spacing={2}>
                            <Grid item xs={6} sm={6} md={5} lg={4} xl={4}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        textTransform: 'none',
                                        backgroundColor: '#169073',
                                        borderRadius: '16px'
                                    }}
                                    href='/documentation/documents/standards/create'
                                    // disableElevation
                                    startIcon={<Add />}
                                >Crear estándar
                                </Button>
                            </Grid>
                            <Grid item xs={6} sm={6} md={5} lg={4} xl={4}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        textTransform: 'none',
                                        backgroundColor: '#2D9CDB',
                                        borderRadius: '16px'
                                    }}
                                    onClick={handleOpen}
                                    startIcon={<FeaturedVideoOutlined />}
                                >Usar plantillas
                                </Button>

                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={1} sm={1} md={3} lg={4} xl={4} sx={{ mt: 2 }}>
                    </Grid>

                    <Grid item xs={12} sm={3} md={3} lg={2} xl={2}>
                        <Grid container spacing={2}>

                            <Grid item xs={2} sm={4} md={4} lg={4} xl={4}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        textTransform: 'none',
                                        backgroundColor: '#599CFF',
                                        borderRadius: '16px'
                                    }}
                                >2023
                                </Button>

                            </Grid>
                            <Grid item xs={4} sm={8} md={7} lg={6} xl={6}>
                                <Button
                                    variant="outlined"
                                    sx={{
                                        textTransform: 'none',
                                        color: '#599CFF',
                                        borderRadius: '16px',
                                        border: 'none'
                                    }}
                                >Anteriores
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container sx={{ mt: 2 }}>
                    <Grid item md={12}>
                        <Paper sx={{ overflow: 'hidden' }}>
                            <TableContainer sx={{ maxHeight: 400, minWidth: '800px' }}>
                                <Table stickyHeader sx={{ width: '100%' }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center"><IconButton><TaskAlt /></IconButton></TableCell>
                                            <TableCell>Nombre del documento</TableCell>
                                            <TableCell align="center"></TableCell>
                                            <TableCell align="center"></TableCell>
                                            <TableCell align="center"></TableCell>
                                            <TableCell align="center"><IconButton><MoreVert /></IconButton></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((row) => (
                                                <TableRow hover
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell align="center"><Checkbox /></TableCell>

                                                    <TableCell component="th" scope="row">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell align="center"><IconButton><TaskOutlined /></IconButton></TableCell>
                                                    <TableCell align="center"><IconButton><BorderColorOutlined /></IconButton></TableCell>
                                                    <TableCell align="center"><IconButton><VisibilityOutlined /></IconButton></TableCell>
                                                    <TableCell align="center"><IconButton><Archive /></IconButton></TableCell>
                                                </TableRow>
                                            ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[2, 3, 4]}
                                component="div"
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </Paper>
                    </Grid>
                </Grid>

                {/* </Box> */}
            </Container>
            {ModalModels}
        </Box >

    );

}

export default Index;

