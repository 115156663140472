import { React, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Button, Card, CardContent, CardMedia, Dialog, DialogActions, DialogContent, Grid, Modal, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { styled } from '@mui/system';
import { Add, DeleteForever, BorderColorOutlined, Search, Print, Edit as EditIcon, HistoryEduOutlined, Delete, DownloadForOffline, PictureAsPdf } from '@mui/icons-material';
import SideNav from '../../../../components/sidenav/sideNav';
import SubtitleText from '../../../../components/text/subtitleText';
import TitleText from '../../../../components/text/titleText';
import MyAts from '../../../../api/MyAts';
import MyUsers from '../../../../api/MyUsers';
import InputControlaVertical from '../../../../components/textfields/inputControlaVertical';
import CompanyLogoControla from '../../../../components/images/companyLogoControla';
import ButtonControla from '../../../../components/buttons/buttonController';
import SearchUsersControla from '../../../../components/search/searchUsersControla';
import DialogTitleControla from '../../../../components/dialog/dialogTitleControla';
import LoadingControla from '../../../../components/load/loadingControla';
import MyConfigurationApi from '../../../../api/MyConfigurationApi';
import ButtonIconControla from '../../../../components/buttons/buttonIconControla';
import SearchButtonControla from '../../../../components/search/searchButtonControla';
import SearchEppsControla from '../../../../components/search/searchEppsControla';
import DialogConfirmControla from '../../../../components/dialog/dialogConfirmControla';

const grey = "#9191B0";
const blue = "#034AFF";
const lilac = "#D1D1E9";
const skyblue = "#2D9CDB";


const StyledTypography = styled(Typography)({
    color: "#305AD9",
    fontSize: 16,
    fontWeight: 600,
    gap: "2%",
    padding: "0.5em"
})

function Edit() {

    // Pantallas de carga y alertas INICIO
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState('');
    const [typeAlert, setTypeAlert] = useState('success');

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert('');
        setTypeAlert('success');
    }

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    }

    const handleCloseAlert = () => {
        setOpenAlert(false);
    }

    // Pantallas de carga y alertas FIN


    // Dialogos de confirmacion INICIO
    const [idAction, setIdAction] = useState('');

    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [titleText, setTitleText] = useState('');
    const [contentText, setContentText] = useState('');
    const [buttonTextConfirm, setButtonTextConfirm] = useState('');
    const [buttonActionType, setButtonActionType] = useState('');


    const handleOpenModalConfirm = (id, titleText, contentText, buttonTextConfirm, buttonActionType) => {
        setIdAction(id);
        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setButtonActionType(buttonActionType);
        setOpenModalConfirm(true);
    }

    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    }

    const handleActionConfirm = () => {
        switch (buttonActionType) {
            case 'deleteResponsible':
                handleDeleteAtsResponsible();
                break;
            case 'deleteTasks':
                handleDeleteAtsTasks();
                break;
            case 'deleteAuthorizations':
                handleDeleteAtsAuthorizations();
                break;
        }
        setOpenModalConfirm(false);
    }

    // Dialogos de confirmacion FIN

    const [idUserLogged, setIdUserLogged] = useState(MyConfigurationApi.userData().id_users);
    const [idUserSelected, setIdUserSelected] = useState(0);
    const [dataUserSelected, setDataUserSelected] = useState('');

    const params = new URLSearchParams(window.location.search);
    const id_ats = params.get('id_ats');

    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [area, setArea] = useState('');
    const [place, setPlace] = useState('');
    const [hourStart, setHourStart] = useState('');
    const [hourFinish, setHourFinish] = useState('');
    const [description, setDescription] = useState('');
    const [tools, setTools] = useState('');
    const [procedure, setProcedure] = useState('');
    const [observation, setObservation] = useState('');
    const [pdfGenerateUrl, setPdfGenerateUrl] = useState('');
    const [readOnlyStatus, setReadOnlyStatus] = useState(false);

    const [idStatuses, setIdStatuses] = useState(false);


    const [rowsResposible, setRowsResposible] = useState([]);
    const [rowsTasks, setRowsTasks] = useState([]);
    const [rowsAuthorizations, setRowsAuthorizations] = useState([]);

    const [rowsUsersNoAuthorizations, setRowsUsersNoAuthorizations] = useState([]);
    const [rowsUsersNoResponsible, setRowsUsersNoResponsible] = useState([]);
    const [rowsUsersNoTasks, setRowsUsersNoTasks] = useState([]);


    const [openModalSelectUser, setOpenModalSelectUser] = useState(false);

    const [openModalCreateResponsible, setOpenModalCreateResponsible] = useState(false);
    const [openModalCreateTasks, setOpenModalCreateTasks] = useState(false);
    const [openModalEditTasks, setOpenModalEditTasks] = useState(false);
    const [openModalCreateAuthorization, setOpenModalCreateAuthorization] = useState(false);


    const [userNameFirstSearch, setUserNameFirstSearch] = useState('');
    const [userLastNameSearch, setUserLastNameSearch] = useState('');
    const [userDocumentSearch, setUserDocumentSearch] = useState('');

    const [idResponsible, setIdResponsible] = useState('');
    const [idAuthorizations, setIdAuthorizations] = useState('');

    const [idTasks, setIdTasks] = useState('');
    const [stepsTasks, setStepsTasks] = useState('');
    const [dangersTasks, setDangersTasks] = useState('');
    const [risksTasks, setRisksTasks] = useState('');
    const [measuresTasks, setMeasuresTasks] = useState('');

    const handleOpenModalSelectUser = () => {
        handleUsersNoTasks();
        setOpenModalSelectUser(true)
    };
    const handleCloseModalSelectUser = () => setOpenModalSelectUser(false);

    const handleOpenModalCreateResponsible = () => {
        handleUsersNoResponsible();
        setOpenModalCreateResponsible(true);
    };
    const handleCloseModalCreateResponsible = () => setOpenModalCreateResponsible(false);

    const handleOpenModalCreateTasks = () => {
        setIdUserSelected(0);
        setDataUserSelected('');
        handleUsersNoTasks();
        setOpenModalCreateTasks(true)
    };
    const handleCloseModalCreateTasks = () => setOpenModalCreateTasks(false);

    const handleOpenModalEditTasks = (idTask, step, danger, risk, measure) => {
        setIdTasks(idTask);
        setStepsTasks(step);
        setDangersTasks(danger);
        setRisksTasks(risk);
        setMeasuresTasks(measure);
        setOpenModalEditTasks(true)
    };
    const handleCloseModalEditTasks = () => setOpenModalEditTasks(false);


    const handleOpenModalCreateAuthorization = () => {
        handleUsersNoAuthorizations();
        setOpenModalCreateAuthorization(true)
    };
    const handleCloseModalCreateAuthorization = () => setOpenModalCreateAuthorization(false);

    const handleSelectUser = async (idUsers) => {
        const response = await MyUsers.editUsers({ idUsers: idUsers });

        if (response.success !== false) {
            setIdUserSelected(idUsers);
            setDataUserSelected(response.document + ' - ' + response.first_name + ' ' + response.last_name)
            handleCloseModalSelectUser();
        } else {
            alert('ERROR');
        }
        return response;
    };

    const handleEditAts = async () => {
        const response = await MyAts.editAts({ id_ats });

        if (response.success === false) {
            alert('Error');
        } else {
            setName(response.name);
            setCode(response.code);
            setArea(response.area);
            setPlace(response.place);
            setHourStart(response.start_time);
            setHourFinish(response.end_time);
            setDescription(response.description);
            setTools(response.tools);
            setProcedure(response.procedure);
            setObservation(response.observation);
            setPdfGenerateUrl(response.pdf_generate_url);
            setIdStatuses(response.id_ats_statuses);
            if (response.id_ats_statuses != 1) {
                setReadOnlyStatus(true);
            }
        }
        return response;
    };

    const handleAtsResponsible = async () => {
        const response = await MyAts.responsibleAtsGet({ id_ats });

        if (response.success === false) {
            alert('Error');
        } else {
            setRowsResposible(response);
        }
        return response;
    };

    const handleAtsTasks = async () => {
        const response = await MyAts.atsTasksGet({ id_ats });

        if (response.success === false) {
            alert('Error');
        } else {
            setRowsTasks(response);
        }
        return response;
    };

    const handleAtsAuthorizations = async () => {
        const response = await MyAts.authorizationsAtsGet({ id_ats });

        if (response.success === false) {
            alert('Error');
        } else {
            setRowsAuthorizations(response);
        }
        return response;
    };

    const handleUsersNoResponsible = async () => {
        const response = await MyAts.responsibleNoAtsGet({ id_ats: id_ats });

        if (response.success !== false) {
            setRowsUsersNoResponsible(response);
        } else {
            alert('Error');

        }
        return response;
    };

    const handleUsersNoAuthorizations = async () => {
        const response = await MyAts.authorizationsNoAtsGet({ id_ats: id_ats });

        if (response.success !== false) {
            setRowsUsersNoAuthorizations(response);
        } else {
            alert('Error');

        }
        return response;
    };

    const handleUsersNoTasks = async () => {
        const response = await MyAts.tasksNoAtsGet({ id_ats: id_ats });

        if (response.success !== false) {
            setRowsUsersNoTasks(response);
        } else {
            alert('Error');

        }
        return response;
    };

    const [rowsEppsAts, setRowsEppsAts] = useState([]);

    const handleAtsEpps = async () => {
        const response = await MyAts.eppsAtsGet({ id_ats });

        if (response.success === false) {
            alert('Error listando los epps');
        } else {
            setRowsEppsAts(response);
        }
        return response;
    };

    const handleAtsNoEpps = async () => {
        const response = await MyAts.eppsNoAtsGet({ id_ats });

        if (response.success === false) {
            alert('Error');
        } else {
            setRowsEpps(response);
        }
        return response;
    };

    useEffect(() => {
        handleEditAts();
        handleAtsResponsible();
        handleAtsTasks();
        handleAtsAuthorizations();
        handleAtsEpps()
    }, []);

    const handlePutUpdateAts = async () => {
        handleOpenLoading();
        const response = await MyAts.updateAts({ id_ats, name, code, area, place, start_time: hourStart, end_time: hourFinish, description: description, tools, procedure, observation: observation });

        if (response.success !== false) {
            handleOpenAlert(response.msg, 'success');
        } else {
            handleOpenAlert('Hubo un error al actualizar', 'success');
        }
        return response;
    };

    const handlePostCreateAtsResponsible = async (id_users) => {
        handleOpenLoading();
        const response = await MyAts.createAtsResponsible({ id_ats, id_users });

        if (response.success === false) {
            handleOpenAlert('Hubo un error', 'error');
        } else {
            handleAtsResponsible();
            handleCloseModalCreateResponsible();
            handleOpenAlert(response.msg, 'success');
        }
        return response;
    };


    const handleFirmStartResponsible = async (idResponsible) => {
        handleOpenLoading();
        const response = await MyAts.responsibleAtsUpdateFirmStart({ id_ats_responsible: idResponsible });

        if (response.success === false) {
            handleOpenAlert('Hubo un error', 'error');
        } else {
            handleAtsResponsible();
            handleOpenAlert(response.msg, 'success');
        }
        return response;
    };

    const handleFirmEndResponsible = async (idResponsible) => {
        handleOpenLoading();
        const response = await MyAts.responsibleAtsUpdateFirmEnd({ id_ats_responsible: idResponsible });

        if (response.success === false) {
            handleOpenAlert('Hubo un error', 'error');
        } else {
            handleAtsResponsible();
            handleOpenAlert(response.msg, 'success');
        }
        return response;
    };

    const handleDeleteAtsResponsible = async () => {
        handleOpenLoading();
        const response = await MyAts.deleteAtsResponsible({ id_ats_responsible: idAction });

        if (response.success === false) {
            handleOpenAlert('Hubo un error', 'error');
        } else {
            handleAtsResponsible();
            handleCloseModalConfirm();
            handleOpenAlert(response.msg, 'success');
        }
        return response;
    };

    const handlePostCreateAtsTasks = async (event) => {
        event.preventDefault();
        handleOpenLoading();
        const response = await MyAts.createAtsTasks({ id_ats, id_users: idUserSelected, steps: stepsTasks, dangers: dangersTasks, risks: risksTasks, measures: measuresTasks });
        if (response.success === false) {
            handleOpenAlert('Hubo un error', 'error');
        } else {
            handleAtsTasks();
            handleCloseModalCreateTasks();
            handleOpenAlert(response.msg, 'success');
        }
        return response;
    };

    const handleUpdateAtsTasks = async (event) => {
        event.preventDefault();
        handleOpenLoading();
        const response = await MyAts.updateAtsTasks({ id_ats_tasks: idTasks, steps: stepsTasks, dangers: dangersTasks, risks: risksTasks, measures: measuresTasks });
        if (response.success === false) {
            handleOpenAlert('Hubo un error', 'error');
        } else {
            handleAtsTasks();
            handleCloseModalEditTasks();
            handleOpenAlert(response.msg, 'success');
        }
        return response;
    };

    const handlePostCreateAtsAuthorizations = async (id_users) => {
        handleOpenLoading();
        const response = await MyAts.authorizationsAtsStore({ id_ats, id_users });

        if (response.success === false) {
            handleOpenAlert('Hubo un error', 'error');
        } else {
            handleAtsAuthorizations();
            handleCloseModalCreateAuthorization();
            handleOpenAlert(response.msg, 'success');
        }
        return response;
    };

    const handleDeleteAtsAuthorizations = async () => {
        handleOpenLoading();
        const response = await MyAts.authorizationsAtsDelete({ id_ats_authorizations: idAction });

        if (response.success === false) {
            handleOpenAlert('Hubo un error', 'error');
        } else {
            handleAtsAuthorizations();
            handleCloseModalConfirm();
            handleOpenAlert(response.msg, 'success');
        }
        return response;
    };


    const handleFirmAuthorizations = async (idAtsAuthorizations) => {
        handleOpenLoading();
        const response = await MyAts.authorizationsAtsUpdateFirm({ id_ats_authorizations: idAtsAuthorizations });

        if (response.success === false) {
            handleOpenAlert('Hubo un error', 'error');
        } else {
            handleAtsAuthorizations();
            handleOpenAlert(response.msg, 'success');
        }
        return response;
    };

    const handleDeleteAtsTasks = async () => {
        handleOpenLoading();
        const response = await MyAts.tasksAtsDelete({ id_ats_tasks: idAction });

        if (response.success === false) {
            handleOpenAlert('Hubo un error', 'error');
        } else {
            handleAtsTasks();
            handleCloseModalConfirm();
            handleOpenAlert(response.msg, 'success');
        }
        return response;
    };

    const [openModalEpps, setOpenModalEpps] = useState(false);
    const [rowsEpps, setRowsEpps] = useState([]);

    const handleOpenModalEpps = () => {
        handleAtsNoEpps();
        setOpenModalEpps(true);
    }

    const handleCloseModalEpps = () => {
        setOpenModalEpps(false);
    }

    const handleSelectEpps = async (idEpps) => {
        handleOpenLoading();
        const response = await MyAts.eppsStore({ id_ats, id_epps: idEpps });

        if (response.success !== false) {
            setRowsEpps([]);
            setOpenModalEpps(false);
            handleAtsNoEpps();
            handleAtsEpps();
            handleOpenAlert(response.msg, 'success');
        } else {
            handleOpenAlert('Hubo un error', 'error');
        }
        return response;
    }

    const handleGeneratePDF = async (event) => {
        handleOpenLoading();
        const response = await MyAts.generatePDF({ id_ats });
        if (response.success === false) {
            handleOpenAlert('Hubo un error', 'error');
        } else {
            handleAtsTasks();
            handleCloseModalCreateTasks();
            handleOpenAlert(response.msg, 'success');
        }
        return response;
    };


    return (
        <>
            <Box sx={{ display: "flex", height: "100%" }}>

                <SideNav returnBack='/ats' />

                <LoadingControla openLoading={openLoading} openAlert={openAlert} handleCloseAlert={handleCloseAlert} messageAlert={messageAlert} typeAlert={typeAlert} />

                <SearchEppsControla openModalEpps={openModalEpps} handleCloseModalEpps={handleCloseModalEpps} handleClickSelectEpps={handleSelectEpps} rowsEpps={rowsEpps} />

                <DialogConfirmControla openModalConfirm={openModalConfirm} handleCloseModalConfirm={handleCloseModalConfirm} titleText={titleText} contentText={contentText} buttonTextConfirm={buttonTextConfirm} handleActionConfirm={handleActionConfirm} />

                <Container sx={{ paddingBottom: 4 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container rowSpacing={2} columnSpacing={2} alignItems="center">
                            <Grid item md={10}>
                                <SubtitleText text={"Procedimientos"} color={grey}></SubtitleText>
                                <TitleText text={"Editar Análisis de Trabajo Seguro"} />
                            </Grid>
                            {/* <Grid item md={2}>

                            </Grid> */}
                            <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
                                <CompanyLogoControla />
                            </Grid>
                        </Grid>
                        <Grid container rowSpacing={2} columnSpacing={2} alignItems="center">
                            <Grid item xs={12} sm={12} md={6} lg={9} xl={9}>
                                <InputControlaVertical text={'Nombre del ATS:'} inputType={'text'} inputValue={setName} modalType={false} value={name} readOnly={readOnlyStatus} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                <InputControlaVertical text={'Código'} inputType={'text'} inputValue={setCode} modalType={false} value={code} readOnly={readOnlyStatus} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                <InputControlaVertical text={'Área:'} inputType={'text'} inputValue={setArea} modalType={false} value={area} readOnly={readOnlyStatus} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                <InputControlaVertical text={'Lugar:'} inputType={'text'} inputValue={setPlace} modalType={false} value={place} readOnly={readOnlyStatus} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                <InputControlaVertical text={'Hora de Inicio:'} inputType={'time'} inputValue={setHourStart} modalType={false} value={hourStart} readOnly={readOnlyStatus} />

                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                <InputControlaVertical text={'Hora de Fin:'} inputType={'time'} inputValue={setHourFinish} modalType={false} value={hourFinish} readOnly={(idStatuses == 2) ? false : readOnlyStatus} />
                            </Grid>
                            {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <InputControlaVertical text={'Descripción del Trabajo:'} inputType={'textArea'} inputValue={setDescription} modalType={false} value={description} />

                            </Grid> */}
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8} >
                                <StyledTypography sx={{
                                    padding: '8px',
                                    fontSize: '20px',
                                    color: '#305AD9',
                                    fontWeight: 'bold'
                                }}>Responsables del trabajo</StyledTypography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                                {(readOnlyStatus == false ?
                                    <ButtonControla
                                        roles={[1, 7, 8, 15]}
                                        iconButton={<Add sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1BAA88"}
                                        textButton={"Agregar responsable"}
                                        functionOnClick={handleOpenModalCreateResponsible}
                                    />
                                    : '')}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Paper sx={{ overflow: 'hidden' }}>
                                    <TableContainer sx={{ minWidth: 650 }}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ color: blue }} align="center">Cargo</TableCell>
                                                    <TableCell sx={{ color: blue }} align="center">Nombres</TableCell>
                                                    <TableCell sx={{ color: blue }} align="center">Firma inicio</TableCell>
                                                    <TableCell sx={{ color: blue }} align="center">Firma Final</TableCell>
                                                    <TableCell sx={{ color: blue }} align="center"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rowsResposible
                                                    .map((rowsResposible) => (
                                                        <TableRow hover key={rowsResposible.id_ats_responsible}>
                                                            <TableCell >
                                                                {rowsResposible.position_name}
                                                            </TableCell>
                                                            <TableCell >
                                                                {rowsResposible.first_name + ' ' + rowsResposible.last_name}
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                <Stack alignItems={'center'}>
                                                                    {rowsResposible.firm_start === 0 ? 'No firmado' && rowsResposible.id_users === idUserLogged && idStatuses == 2 ?
                                                                        <ButtonIconControla
                                                                            icon={
                                                                                <HistoryEduOutlined sx={{ color: "white" }} />
                                                                            }
                                                                            backgroundColor={"#F1C40F"}
                                                                            backgroundColorHover={"#F4D03F"}
                                                                            textTooltip={"Firmar"}
                                                                            functionOnClick={() => { handleFirmStartResponsible(rowsResposible.id_ats_responsible) }}
                                                                        />
                                                                        : 'No firmado' : <img src={rowsResposible.firm_url} alt='Firma no encontrada' style={{ maxWidth: '100px', maxHeight: '100px' }} />}
                                                                </Stack>
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                <Stack alignItems={'center'}>
                                                                    {rowsResposible.firm_end === 0 ? 'No firmado' && rowsResposible.firm_start === 1 && rowsResposible.id_users === idUserLogged && idStatuses == 2 ?
                                                                        <ButtonIconControla
                                                                            icon={
                                                                                <HistoryEduOutlined sx={{ color: "white" }} />
                                                                            }
                                                                            backgroundColor={"#F1C40F"}
                                                                            backgroundColorHover={"#F4D03F"}
                                                                            textTooltip={"Firmar"}
                                                                            functionOnClick={() => { handleFirmEndResponsible(rowsResposible.id_ats_responsible) }}
                                                                        />
                                                                        : 'No firmado' : <img src={rowsResposible.firm_url} alt='Firma no encontrada' style={{ maxWidth: '100px', maxHeight: '100px' }} />}
                                                                </Stack>
                                                            </TableCell>
                                                            <TableCell align="center" >
                                                                {(readOnlyStatus == false ?
                                                                    <Stack alignItems={'center'}>
                                                                        <ButtonIconControla
                                                                            icon={<Delete sx={{ color: 'white' }} />} backgroundColor={"#EB5757"}
                                                                            backgroundColorHover={"#FF4040"} textTooltip={'Eliminar'}
                                                                            functionOnClick={() => { handleOpenModalConfirm(rowsResposible.id_ats_responsible, 'Eliminar responsable', '¿Estas seguro de eliminar el responsable?', 'Si, eliminar', 'deleteResponsible'); }}
                                                                        />
                                                                    </Stack>
                                                                    : '')}
                                                            </TableCell>

                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8} >
                                <StyledTypography sx={{
                                    padding: '8px',
                                    fontSize: '20px',
                                    color: '#305AD9',
                                    fontWeight: 'bold'
                                }}>Tareas</StyledTypography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                                {(idStatuses == 2 || readOnlyStatus == false ?
                                    <ButtonControla
                                        iconButton={<Add sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1BAA88"}
                                        textButton={"Agregar tarea"}
                                        functionOnClick={handleOpenModalCreateTasks}
                                    />
                                    : '')}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Paper sx={{ overflow: 'hidden' }}>
                                    <TableContainer sx={{ minWidth: 650 }}>
                                        <Table aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ color: blue }} align="center">Nombre</TableCell>
                                                    <TableCell sx={{ color: blue }} align="center">Pasos de la tarea</TableCell>
                                                    <TableCell sx={{ color: blue }} align="center">Peligros</TableCell>
                                                    <TableCell sx={{ color: blue }} align="center">Riesgos potenciales</TableCell>
                                                    <TableCell sx={{ color: blue }} align="center">Medidas preventivas</TableCell>
                                                    <TableCell sx={{ color: blue }} align="center"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rowsTasks
                                                    .map((rowsTasks) => (
                                                        <TableRow hover key={rowsTasks.id_ats_tasks} >
                                                            <TableCell >
                                                                {rowsTasks.first_name + ' ' + rowsTasks.last_name}
                                                            </TableCell>
                                                            <TableCell>
                                                                {rowsTasks.steps}
                                                            </TableCell>
                                                            <TableCell>
                                                                {rowsTasks.dangers}
                                                            </TableCell>
                                                            <TableCell>
                                                                {rowsTasks.risks}
                                                            </TableCell>
                                                            <TableCell>
                                                                {rowsTasks.measures}
                                                            </TableCell>
                                                            <TableCell align="center" >
                                                                {(idStatuses == 2 || readOnlyStatus == false ?
                                                                    <Stack alignItems={'center'} direction={'row'} spacing={1}>

                                                                        <ButtonIconControla
                                                                            icon={<BorderColorOutlined sx={{ color: 'white' }} />} backgroundColor={"#2D9CDB"}
                                                                            backgroundColorHover={"#33AEF4"} textTooltip={'Editar'}
                                                                            functionOnClick={() => { handleOpenModalEditTasks(rowsTasks.id_ats_tasks, rowsTasks.steps, rowsTasks.dangers, rowsTasks.risks, rowsTasks.measures) }}
                                                                        />

                                                                        <ButtonIconControla
                                                                            icon={<Delete sx={{ color: 'white' }} />} backgroundColor={"#EB5757"}
                                                                            backgroundColorHover={"#FF4040"} textTooltip={'Eliminar'}
                                                                            functionOnClick={() => handleOpenModalConfirm(rowsTasks.id_ats_tasks, 'Eliminar tarea', '¿Estas seguro de eliminar esta tarea?', 'Si, eliminar', 'deleteTasks')} />
                                                                    </Stack>
                                                                    : '')}
                                                            </TableCell>

                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8} >
                                <StyledTypography sx={{
                                    padding: '8px',
                                    fontSize: '20px',
                                    color: '#305AD9',
                                    fontWeight: 'bold'
                                }}>EPPS</StyledTypography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                                {(readOnlyStatus == false ?
                                    <ButtonControla
                                        iconButton={<Add sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1BAA88"}
                                        textButton={"Agregar EPP"}
                                        functionOnClick={handleOpenModalEpps}
                                    />
                                    : '')}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Paper sx={{ overflow: 'hidden' }}>
                                    <Grid container spacing={2} sx={{ width: '100%', margin: 'auto' }} >
                                        {rowsEppsAts
                                            .map((rowsEpps) => (

                                                <Grid item key={rowsEpps.id_epps} xs={12} sm={6} md={4} lg={3} xl={3} sx={{ p: 2 }}>
                                                    <Card sx={{
                                                        display: 'flex', justifyContent: 'space-between', height: 80,
                                                        /*"&:hover": {
                                                            cursor: 'pointer',
                                                            border: "1px solid #0F1255",
                                                            boxShadow: '0px 4px #0F1255',
                                                        }*/
                                                    }} /*onClick={() => { handleSelectDeleteEpps(rowsEpps.id_ats_epps) }}*/>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                            <CardContent sx={{ flex: '1 0 auto' }}>
                                                                <Typography sx={{ fontSize: '14px' }}>
                                                                    {rowsEpps.epp}
                                                                </Typography>
                                                            </CardContent>
                                                        </Box>
                                                        <CardMedia
                                                            component="img"
                                                            sx={{ maxWidth: 80, marginLeft: 0, maxHeight: 80, objectFit: 'contain' }}
                                                            image={rowsEpps.photo_url}
                                                            alt="Live from space album cover"
                                                        />
                                                    </Card>

                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputControlaVertical text={'Herramientas, equipo y material:'} inputType={'textArea'} inputValue={setTools} modalType={false} value={tools} readOnly={readOnlyStatus} />
                            </Grid>
                            {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputControlaVertical text={'Procedimiento:'} inputType={'textArea'} inputValue={setProcedure} modalType={false} value={procedure} />
                            </Grid> */}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputControlaVertical text={'Observaciones:'} inputType={'textArea'} inputValue={setObservation} modalType={false} value={observation} readOnly={(idStatuses == 2) ? false : readOnlyStatus} />
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8} >
                                <StyledTypography sx={{
                                    padding: '8px',
                                    fontSize: '20px',
                                    color: '#305AD9',
                                    fontWeight: 'bold'
                                }}>Autorización y supervisión</StyledTypography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                                {(readOnlyStatus == false ?
                                    <ButtonControla
                                        roles={[1, 7, 8, 15]}
                                        iconButton={<Add sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1BAA88"}
                                        textButton={"Agregar supervisor"}
                                        functionOnClick={handleOpenModalCreateAuthorization}
                                    />
                                    : '')}
                            </Grid>
                            <Grid item md={12}>
                                <Paper sx={{ overflow: 'hidden' }}>
                                    <TableContainer sx={{ minWidth: 650 }}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ color: blue }} align="center">Cargo</TableCell>
                                                    <TableCell sx={{ color: blue }} align="center">Nombre</TableCell>
                                                    <TableCell sx={{ color: blue }} align="center">Firma</TableCell>
                                                    <TableCell sx={{ color: blue }} align="center">Fecha y hora de firma</TableCell>
                                                    <TableCell sx={{ color: blue }} align="center"></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rowsAuthorizations
                                                    .map((rowsAuthorizations) => (
                                                        <TableRow hover key={rowsAuthorizations.id_ats_authorizations}>

                                                            <TableCell >
                                                                {rowsAuthorizations.position_name}
                                                            </TableCell>
                                                            <TableCell >
                                                                {rowsAuthorizations.first_name + ' ' + rowsAuthorizations.last_name}
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                <Stack alignItems={'center'}>
                                                                    {/* {rowsAuthorizations.firm === 0 ? 'No firmado' && rowsAuthorizations.id_users === idUserLogged ?
                                                                        <ButtonIconControla
                                                                            icon={
                                                                                <HistoryEduOutlined sx={{ color: "white" }} />
                                                                            }
                                                                            backgroundColor={"#F1C40F"}
                                                                            backgroundColorHover={"#F4D03F"}
                                                                            textTooltip={"Firmar"}
                                                                        // functionOnClick={() => console.log("ver ")}
                                                                        />
                                                                        : 'No firmado' : 'Firmado'} */}
                                                                    {rowsAuthorizations.firm === 0 ? 'No firmado' && rowsAuthorizations.id_users === idUserLogged && idStatuses == 2 ?
                                                                        <ButtonIconControla
                                                                            icon={
                                                                                <HistoryEduOutlined sx={{ color: "white" }} />
                                                                            }
                                                                            backgroundColor={"#F1C40F"}
                                                                            backgroundColorHover={"#F4D03F"}
                                                                            textTooltip={"Firmar"}
                                                                            functionOnClick={() => handleFirmAuthorizations(rowsAuthorizations.id_ats_authorizations)}
                                                                        />
                                                                        : 'No firmado' : <img src={rowsAuthorizations.firm_url} alt='Firma no encontrada' style={{ maxWidth: '100px', maxHeight: '100px' }} />}
                                                                </Stack>
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                <Stack alignItems={'center'}>
                                                                    {/* {rowsAuthorizations.firm === 0 ? 'No firmado' && rowsAuthorizations.id_users === idUserLogged ?
                                                                        <ButtonIconControla
                                                                            icon={
                                                                                <HistoryEduOutlined sx={{ color: "white" }} />
                                                                            }
                                                                            backgroundColor={"#F1C40F"}
                                                                            backgroundColorHover={"#F4D03F"}
                                                                            textTooltip={"Firmar"}
                                                                        // functionOnClick={() => console.log("ver ")}
                                                                        />
                                                                        : 'No firmado' : 'Firmado'} */}
                                                                    {rowsAuthorizations.firm === 0 ? '' && rowsAuthorizations.id_users === idUserLogged ? ''
                                                                        : '' : rowsAuthorizations.firm_date.slice(0, 19)}
                                                                </Stack>
                                                            </TableCell>
                                                            <TableCell align="center" >
                                                                {(readOnlyStatus == false ?
                                                                    <Stack alignItems={'center'}>
                                                                        <ButtonIconControla
                                                                            icon={<Delete sx={{ color: 'white' }} />} backgroundColor={"#EB5757"}
                                                                            backgroundColorHover={"#FF4040"} textTooltip={'Eliminar'}
                                                                            functionOnClick={() => handleOpenModalConfirm(rowsAuthorizations.id_ats_authorizations, 'Eliminar supervisor', '¿Estas seguro de eliminar el supervisor?', 'Si, eliminar', 'deleteAuthorizations')} />
                                                                    </Stack>
                                                                    : ''
                                                                )}
                                                            </TableCell>

                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ marginTop: 4 }}>
                                <Stack direction={'row'} spacing={2} justifyContent={'center'}>
                                    <Box>
                                        <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Regresar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} url={'/ats?idTabs=1'} />
                                    </Box>
                                    <Box>
                                        {(idStatuses == 3) ? ((pdfGenerateUrl != null && pdfGenerateUrl != undefined && pdfGenerateUrl != '') ?
                                            <ButtonControla
                                                iconButton={<PictureAsPdf sx={{ color: "white" }} />}
                                                backgroundColor={"#F2994A"}
                                                backgroundColorHover={"#FF881E"}
                                                textButton={"Ver documento generado"}
                                                typeButton={'button'}
                                                url={pdfGenerateUrl}
                                                target='_blank'
                                            />
                                            :
                                            <ButtonControla
                                                iconButton={<PictureAsPdf sx={{ color: "white" }} />}
                                                backgroundColor={"#F2994A"}
                                                backgroundColorHover={"#FF881E"}
                                                textButton={"Generar PDF"}
                                                typeButton={'button'}
                                                functionOnClick={() => handleGeneratePDF}
                                            />) : ''
                                        }

                                    </Box>
                                    <Box>
                                        {(idStatuses == 2 || readOnlyStatus == false ?
                                            <ButtonControla
                                                roles={[1, 7, 8, 9, 15]}
                                                iconButton={<EditIcon sx={{ color: "white" }} />}
                                                backgroundColor={"#169073"}
                                                backgroundColorHover={"#1BAA88"}
                                                textButton={"Actualizar ATS"}
                                                typeButton={'button'}
                                                functionOnClick={handlePutUpdateAts}
                                            />
                                            : '')}
                                    </Box>
                                </Stack>
                            </Grid>

                        </Grid>

                        <Dialog
                            open={openModalCreateResponsible}
                            onClose={handleCloseModalCreateResponsible}
                            maxWidth="sm"
                            fullWidth={true}
                            sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                        >
                            <DialogTitleControla titleText='Agregar Responsable' functionOnClose={handleCloseModalCreateResponsible} />
                            <DialogContent >
                                <SearchUsersControla rowsUser={rowsUsersNoResponsible} handleUsersActionButton={handlePostCreateAtsResponsible} />
                            </DialogContent>
                            <DialogActions>
                                <Stack direction={'row'} spacing={2}>
                                    <Box>
                                        <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cancelar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={handleCloseModalCreateResponsible} />
                                    </Box>
                                </Stack>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={openModalCreateTasks}
                            onClose={handleCloseModalCreateTasks}
                            maxWidth="md"
                            fullWidth={true}
                            sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                        >
                            <DialogTitleControla titleText='Agregar Tarea' functionOnClose={handleCloseModalCreateTasks} />
                            <form onSubmit={handlePostCreateAtsTasks}>
                                <DialogContent >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                            <SearchButtonControla titleLabel={'Usuario:'} inputValue={dataUserSelected} functionClick={handleOpenModalSelectUser} modalType={true} required />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                            <InputControlaVertical text={'Pasos de la tarea:'} inputType={'textArea'} inputValue={setStepsTasks} modalType={true} required />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                            <InputControlaVertical text={'Peligros:'} inputType={'textArea'} inputValue={setDangersTasks} modalType={true} required />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                            <InputControlaVertical text={'Riesgos potenciales:'} inputType={'textArea'} inputValue={setRisksTasks} modalType={true} required />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                            <InputControlaVertical text={'Medidas preventivas:'} inputType={'textArea'} inputValue={setMeasuresTasks} modalType={true} required />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Stack direction={'row'} spacing={2}>
                                        <Box>
                                            <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cancelar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={handleCloseModalCreateTasks} />
                                        </Box>
                                        <Box>
                                            <ButtonControla iconButton={<Add />} backgroundColor={'#169073'} textButton={'Agregar'} backgroundColorHover={'#1BAA88'} typeButton={'submit'} />
                                        </Box>
                                    </Stack>
                                </DialogActions>
                            </form>
                        </Dialog>

                        <Dialog
                            open={openModalEditTasks}
                            onClose={handleCloseModalEditTasks}
                            maxWidth="md"
                            fullWidth={true}
                            sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                        >
                            <DialogTitleControla titleText='Editar Tarea' functionOnClose={handleCloseModalEditTasks} />
                            <form onSubmit={handleUpdateAtsTasks}>
                                <DialogContent >
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                            <InputControlaVertical text={'Pasos de la tarea:'} inputType={'textArea'} inputValue={setStepsTasks} modalType={true} value={stepsTasks} required />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                            <InputControlaVertical text={'Peligros:'} inputType={'textArea'} inputValue={setDangersTasks} modalType={true} value={dangersTasks} required />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                            <InputControlaVertical text={'Riesgos potenciales:'} inputType={'textArea'} inputValue={setRisksTasks} modalType={true} value={risksTasks} required />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                            <InputControlaVertical text={'Medidas preventivas:'} inputType={'textArea'} inputValue={setMeasuresTasks} modalType={true} value={measuresTasks} required />
                                        </Grid>
                                    </Grid>
                                </DialogContent>
                                <DialogActions>
                                    <Stack direction={'row'} spacing={2}>
                                        <Box>
                                            <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cancelar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={handleCloseModalEditTasks} />
                                        </Box>
                                        <Box>
                                            <ButtonControla iconButton={<Add />} backgroundColor={'#169073'} textButton={'Actualizar'} backgroundColorHover={'#1BAA88'} typeButton={'submit'} />
                                        </Box>
                                    </Stack>
                                </DialogActions>
                            </form>
                        </Dialog>

                        <Dialog
                            open={openModalSelectUser}
                            onClose={handleCloseModalSelectUser}
                            maxWidth="sm"
                            fullWidth={true}
                            sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                        >
                            <DialogTitleControla titleText='Buscar Usuario' functionOnClose={handleCloseModalSelectUser} />
                            <DialogContent >
                                <SearchUsersControla rowsUser={rowsUsersNoTasks} handleUsersActionButton={handleSelectUser} />
                            </DialogContent>
                            <DialogActions>
                                <Stack direction={'row'} spacing={2}>
                                    <Box>
                                        <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cancelar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={handleCloseModalSelectUser} />
                                    </Box>
                                </Stack>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={openModalCreateAuthorization}
                            onClose={handleCloseModalCreateAuthorization}
                            maxWidth="sm"
                            fullWidth={true}
                            sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                        >
                            <DialogTitleControla titleText='Agregar Supervisor' functionOnClose={handleCloseModalCreateAuthorization} />
                            <DialogContent >
                                <SearchUsersControla rowsUser={rowsUsersNoAuthorizations} handleUsersActionButton={handlePostCreateAtsAuthorizations} />
                            </DialogContent>
                            <DialogActions>
                                <Stack direction={'row'} spacing={2}>
                                    <Box>
                                        <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cancelar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={handleCloseModalCreateAuthorization} />
                                    </Box>
                                </Stack>
                            </DialogActions>
                        </Dialog>

                    </Box>
                </Container>
            </Box >
        </>
    )
}

export default Edit