import React from 'react'
import { Button, Grid, TextField, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/system';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Add } from '@mui/icons-material';
import TitleText from '../components/text/titleText';

//Styled Components
const teal = "#2D9CDB";
const blue = "#034AFF";
const purple = "#5D5D93";
const lilac = "#D1D1E9";

const AddButton = styled(Button)({
    textTransform: 'none',
    borderRadius: 6,
    backgroundColor: '#169073'
});

const CloseButton = styled(Button)({
    textTransform: 'none',
    backgroundColor: lilac,
    color: purple,
    borderRadius: 6,
});

const StyledTealButton = styled(Button)({
    textTransform: 'none',
    borderRadius: 14,
    backgroundColor: teal,
    color: "white",
    width: "100%"
});

const StyledTypography = styled(Typography)({
    color: "#305AD9",
    fontSize: 16,
    fontWeight: 600,
    gap: "2%",
    padding: "0.5em"
});

const StyledTextField = styled(TextField)({
    //label
    "& label.Mui-focused": {
        color: "#305AD9",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#305AD9",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "white",
            background: "white",
            borderRadius: " 16px"
        },
        "&:hover fieldset": {
            borderColor: "white",
            background: "white"
        },
        "&.Mui-focused fieldset": {
            borderColor: "white",
            background: "white"
        },
        "& .MuiFilledInput-root": {
            background: "white",
        },
    },
    width: "100%",
});
function AddDateDialog() {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <StyledTealButton
                variant='contained'
                onClick={handleClickOpen}
                startIcon={<Add />} disableElevation>
                Agregar datos
            </StyledTealButton>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                PaperProps={{
                    style: {
                        backgroundColor: "#F5F5F5",
                        width: "55%",
                    },

                }}
                fullWidth={true}>

                <DialogTitle> <TitleText text={"Agregar miembro a la junta electoral"}></TitleText><IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton></DialogTitle>
                <DialogContent >

                    <Grid container rowSpacing={3} columnSpacing={6}>
                        <Grid item md={6}>
                            <StyledTypography>
                                Cargo dentro de la Junta
                            </StyledTypography>
                        </Grid>
                        <Grid item md={6}>
                            <StyledTextField />
                        </Grid>
                        <Grid item md={6}>
                            <StyledTypography>
                                Nombres
                            </StyledTypography>
                        </Grid>
                        <Grid item md={6}>
                            <StyledTextField />
                        </Grid>
                        <Grid item md={12}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ color: blue }} align="center">Nombres</TableCell>
                                            <TableCell sx={{ color: blue }} align="center">Documento de Identidad</TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ color: purple }} align="center">
                                                Nelson Heredia
                                            </TableCell>
                                            <TableCell sx={{ color: purple }} align="center">
                                                0028463
                                            </TableCell>
                                            <TableCell>
                                                <StyledTealButton
                                                    variant='contained'
                                                    startIcon={<Add />} disableElevation>
                                                    Agregar datos
                                                </StyledTealButton>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>

                </DialogContent>


                <DialogActions>
                    <CloseButton disableElevation variant="contained" onClick={handleClose}>Salir</CloseButton>
                    <AddButton
                        disableElevation variant="contained">Confirmar datos</AddButton>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default AddDateDialog