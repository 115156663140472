import { React, useState, useEffect } from 'react';

import SubtitleText from '../../components/text/subtitleText';
import Container from '@mui/material/Container';
import SideNav from "../../components/sidenav/sideNav";
import Box from '@mui/material/Box';
import { Grid, Button, FormControl, Typography, Divider, InputLabel, MenuItem, DialogActions, InputBase, Autocomplete, TextField } from '@mui/material';
import { Add, ArrowBackIosNew, ArrowForwardIos, ArrowRight, Delete, DriveFileRenameOutlineOutlined, Highlight, Search, } from '@mui/icons-material';
import TitleText from '../../components/text/titleText';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/system';
import InputControla from '../../components/textfields/inputControla';
import LabelControla from '../../components/textfields/labelControla';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import InputControlaVertical from '../../components/textfields/inputControlaVertical';
import CompanyLogoControla from '../../components/images/companyLogoControla';
import ButtonIconControla from '../../components/buttons/buttonIconControla';
import ButtonControla from '../../components/buttons/buttonController';
import SelectYesNotControla from '../../components/selects/selectYesNotControla';
import MyCompanies from '../../api/MyCompanies';
import SelectControla from '../../components/selects/selectControla';
import SearchUsersControla from '../../components/search/searchUsersControla';
import MyUsers from '../../api/MyUsers';

const colorTitle = "#9191B0";
const blue = "#034AFF";
const green = "#2C8C75";
const lilac = "#D1D1E9";
const skyblue = "#2D9CDB";

function Organization() {
    const [rowsUsers, setRowsUsers] = useState([]);

    const [openModalCreateSector, setOpenModalCreateSector] = useState(false);
    const [sectors, setSectors] = useState(0);

    const handleOpenModalCreateSector = () => {
        setSectors('0');
        setOpenModalCreateSector(true);
    }

    const [openModalSelectSecurityBoss, setOpenModalSelectSecurityBoss] = useState(false);

    const [openModalSelectMedicalBoss, setOpenModalSelectMedicalBoss] = useState(false);


    const [operatingLicense, setOperatingLicense] = useState('');
    const [operatingLicenseDate, setOperatingLicenseDate] = useState('');
    const [itse, setItse] = useState('');
    const [itseEmisionDate, setItseEmisionDate] = useState('');
    const [itseExpirationDate, setItseExpirationDate] = useState('');
    const [hireStaff, setHireStaff] = useState('');
    const [personalHighlights, setPersonalHighlights] = useState('');

    const [securityBoss, setSecurityBoss] = useState(' ');
    const [idSecurityBoss, setIdSecurityBoss] = useState('');

    const [medicalBoss, setMedicalBoss] = useState(' ');
    const [idMedicalBoss, setIdMedicalBoss] = useState('');

    const handleGetCompany = async () => {
        const response = await MyCompanies.company();
        if (response.success !== false) {
            setOperatingLicense(response.operating_license);
            setOperatingLicenseDate(response.operating_license_date);
            setItse(response.itse);
            setItseEmisionDate(response.itse_emision_date);
            setItseExpirationDate(response.itse_expiration_date);
            setHireStaff(response.hire_staff);
            setPersonalHighlights(response.personal_highlights);

            if (response.id_users_security_boss != null) {
                handleSelectUsersSecurityBoss(response.id_users_security_boss);
            }
            if (response.id_users_medical_boss != null) {
                handleSelectUsersMedicalBoss(response.id_users_medical_boss);
            }
        } else {
            alert("Error al listar los datos de empresa!");
        }
    }

    const handleGetUsers = async (event) => {
        const response = await MyUsers.users();
        if (response.success !== false) {
            setRowsUsers(response.users_actives);
        } else {
            alert("Error al listar la Los usuarios!");
        }
    }


    useEffect(() => {
        handleGetCompany();
        handleGetUsers();
    }, []);

    const handleSelectUsersSecurityBoss = async (idUsers) => {
        setIdSecurityBoss(idUsers);
        const response = await MyUsers.editUsers({ idUsers: idUsers });
        if (response.success !== false) {
            setSecurityBoss(response.document + ' - ' + response.first_name + ' ' + response.last_name);
            setOpenModalSelectSecurityBoss(false);
        } else {
            alert("Error al obtener el usuario!");
        }
    }

    const handleSelectUsersMedicalBoss = async (idUsers) => {
        setIdMedicalBoss(idUsers);
        const response = await MyUsers.editUsers({ idUsers: idUsers });
        if (response.success !== false) {
            setMedicalBoss(response.document + ' - ' + response.first_name + ' ' + response.last_name);
            setOpenModalSelectMedicalBoss(false);
        } else {
            alert("Error al obtener el usuario!");
        }
    }

    const handleUpdateOrganizationCompany = async (event) => {
        event.preventDefault();
        const response = await MyCompanies.updateOrganization({ operating_license: operatingLicense, operating_license_date: operatingLicenseDate, itse: itse, itse_emision_date: itseEmisionDate, itse_expiration_date: itseExpirationDate, hire_staff: hireStaff, personal_highlights: personalHighlights, id_users_security_boss: idSecurityBoss, id_users_medical_boss: idMedicalBoss });
        if (response.success !== false) {
            alert("Datos actualizados correctamente!");
        } else {
            alert("Error al obtener el usuario!");
        }
    }

    return (

        <Box sx={{ display: "flex", height: '100%' }}>
            <SideNav returnBack='/settings/company' />

            <Container>

                <Box sx={{ flexGrow: 1, marginBottom: 4 }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={11} sm={11} md={10} lg={10} xl={10}>
                            <SubtitleText text={"Configuración"} color={colorTitle} />
                            <TitleText text={"Organización"} />
                        </Grid>
                        <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
                            <CompanyLogoControla />
                        </Grid>
                    </Grid>
                    <form onSubmit={handleUpdateOrganizationCompany}>

                        <Box sx={{ marginTop: 3 }}>
                            {/* <SubtitleText text={"Organizacion"} color={blue} /> */}
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={7} lg={8} xl={8}>
                                    <SelectYesNotControla text={"Licencia de funcionamiento:"} inputValue={setOperatingLicense} value={operatingLicense} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={5} lg={4} xl={4}>
                                    <InputControlaVertical text={"Fecha de emisión:"} inputType={"date"} inputValue={setOperatingLicenseDate} value={operatingLicenseDate} />

                                </Grid>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <SelectYesNotControla text={"Certificado de Inspección Técnica de Seguridad en la Edificación - ITSE:"} inputValue={setItse} value={itse} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <InputControlaVertical text={"Fecha de emisión:"} inputType={"date"} inputValue={setItseEmisionDate} value={itseEmisionDate} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <InputControlaVertical text={"Fecha de vencimiento:"} inputType={"date"} inputValue={setItseExpirationDate} value={itseExpirationDate} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <SelectYesNotControla text={"¿Su empresa contrata personal destacado?:"} inputValue={setHireStaff} value={hireStaff} />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                    <SelectYesNotControla text={"¿Su empresa destaca personal(contratista)?:"} inputValue={setPersonalHighlights} value={personalHighlights} />
                                </Grid>
                            </Grid>
                        </Box>
                        <Divider sx={{ marginTop: 3 }} />

                        <Box sx={{ marginTop: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <InputLabel
                                        sx={{
                                            padding: '8px',
                                            fontSize: '18px',
                                            color: '#305AD9',
                                            fontWeight: 'bold'
                                        }}> Jefe/ Gerente / Supervisor del Área de Seguridad: </InputLabel>
                                    <Stack direction="row">

                                        <InputBase type='text'
                                            value={securityBoss}
                                            readOnly
                                            sx={{
                                                borderTopLeftRadius: '0',
                                                borderBottomLeftRadius: '0',
                                                fontFamily: 'Roboto',
                                                width: '100%',
                                                padding: '4px 10px',
                                                border: '1px solid transparent',
                                                backgroundColor: '#FFFFFF',
                                                color: '#305AD9',
                                                borderTopLeftRadius: '8px',
                                                borderBottomLeftRadius: '8px',
                                                "&.Mui-focused": {
                                                    border: "1px solid blue",
                                                }
                                            }} />
                                        <Button
                                            variant="contained"
                                            type='button'
                                            sx={{
                                                borderRadius: '0',
                                                borderTopRightRadius: '8px',
                                                borderBottomRightRadius: '8px',
                                                height: '42px',
                                                backgroundColor: '#2D9CDB',
                                                textTransform: "none",
                                                boxShadow: 'none',
                                                "&:hover": {
                                                    backgroundColor: '#33AEF4'
                                                },
                                            }}
                                            onClick={() => { setOpenModalSelectSecurityBoss(true) }}
                                            startIcon={<Search />}
                                        >
                                            Buscar
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                        <Divider sx={{ marginTop: 3 }} />

                        <Box sx={{ marginTop: 3 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <InputLabel
                                        sx={{
                                            padding: '8px',
                                            fontSize: '18px',
                                            color: '#305AD9',
                                            fontWeight: 'bold'
                                        }}> Médico Ocupacional / Responsable de la Salud Ocupacional: </InputLabel>
                                    <Stack direction="row">

                                        <InputBase type='text'
                                            value={medicalBoss}
                                            readOnly
                                            sx={{
                                                borderTopLeftRadius: '0',
                                                borderBottomLeftRadius: '0',
                                                fontFamily: 'Roboto',
                                                width: '100%',
                                                padding: '4px 10px',
                                                border: '1px solid transparent',
                                                backgroundColor: '#FFFFFF',
                                                color: '#305AD9',
                                                borderTopLeftRadius: '8px',
                                                borderBottomLeftRadius: '8px',
                                                "&.Mui-focused": {
                                                    border: "1px solid blue",
                                                }
                                            }} />
                                        <Button
                                            variant="contained"
                                            type='button'
                                            sx={{
                                                borderRadius: '0',
                                                borderTopRightRadius: '8px',
                                                borderBottomRightRadius: '8px',
                                                height: '42px',
                                                backgroundColor: '#2D9CDB',
                                                textTransform: "none",
                                                boxShadow: 'none',
                                                "&:hover": {
                                                    backgroundColor: '#33AEF4'
                                                },
                                            }}
                                            onClick={() => { setOpenModalSelectMedicalBoss(true) }}
                                            startIcon={<Search />}
                                        >
                                            Buscar
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Box>
                        <Divider sx={{ marginTop: 3 }} />


                        <Stack direction={'row'} spacing={2} justifyContent="start" sx={{ marginTop: 3 }}>
                            <Box >
                                <ButtonControla textButton={'Cancelar'} backgroundColor={'#CBCBFF'} backgroundColorHover={'#d6d6ff'} />
                            </Box>
                            <Box >
                                <ButtonControla iconButton={<ArrowRight />} textButton={'Continuar'} backgroundColor={"#169073"}
                                    backgroundColorHover={"#1BAA88"} typeButton={'submit'} />
                            </Box>
                        </Stack>
                    </form>
                </Box>

                <Dialog
                    open={openModalSelectSecurityBoss}
                    onClose={() => { setOpenModalSelectSecurityBoss(false); }}
                    maxWidth="sm"
                    fullWidth={true}
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                >
                    <DialogTitle>
                        <Typography
                            id="modal-modal-title"
                            sx={{
                                color: "#1638F2",
                                fontWeight: "bold",
                                fontSize: "22px",
                            }}
                        >
                            Seleccionar Jefe/ Gerente / Supervisor del Área de Seguridad
                        </Typography>
                    </DialogTitle>
                    <DialogContent sx={{ paddingTop: "0" }}>
                        <SearchUsersControla
                            handleUsersActionButton={handleSelectUsersSecurityBoss}
                            rowsUser={rowsUsers}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => { setOpenModalSelectSecurityBoss(false); }}
                        >
                            Cancelar
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={openModalSelectMedicalBoss}
                    onClose={() => { setOpenModalSelectMedicalBoss(false); }}
                    maxWidth="sm"
                    fullWidth={true}
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                >
                    <DialogTitle>
                        <Typography
                            id="modal-modal-title"
                            sx={{
                                color: "#1638F2",
                                fontWeight: "bold",
                                fontSize: "22px",
                            }}
                        >
                            Seleccionar representante del sindicato
                        </Typography>
                    </DialogTitle>
                    <DialogContent sx={{ paddingTop: "0" }}>
                        <SearchUsersControla
                            handleUsersActionButton={handleSelectUsersMedicalBoss}
                            rowsUser={rowsUsers}

                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => { setOpenModalSelectMedicalBoss(false); }}
                        >
                            Cancelar
                        </Button>
                    </DialogActions>
                </Dialog>

            </Container>

        </Box>

    );

}

export default Organization;

