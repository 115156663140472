import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import { styled } from "@mui/system";
import { Task, TaskAlt } from "@mui/icons-material";
import SideNav from "../components/sidenav/sideNav";
import SubtitleText from "../components/text/subtitleText";
import TitleText from "../components/text/titleText";
import MyBaseline from "../api/MyBaseline";
import ButtonControla from "../components/buttons/buttonController";

const grey = "#9191B0";
const blue = "#034AFF";

const StyledInsufficientCard = styled(Card)({
  backgroundColor: "#EB5757",
  borderRadius: "14px",
});

const StyledCard = styled(Card)({
  borderRadius: "14px",
  color: "white",
  fontSize: 18,
  fontWeight: 600,
});

const StyledImplementButton = styled(Button)({
  backgroundColor: "#169073",
  borderRadius: "14px",
  color: "white",
  textTransform: "none",
  width: "100%",
});

function BaselineResults() {
  const params = new URLSearchParams(window.location.search);
  const id_baseline = params.get("id_baseline");

  const [baselineInfo, setBaselineInfo] = useState({
    percentage: 0,
  });

  const handleGetResult = async () => {
    const response = await MyBaseline.editBaseline({ idBaseline: id_baseline });
    console.log(response);
    setBaselineInfo(response);
  };

  useEffect(() => {
    handleGetResult();
  }, []);

  return (
    <>
      <Box sx={{ display: "flex", height: "100%" }}>
        <SideNav returnBack="/baseline/diagnosis"/>
        <Container>
          <Box sx={{ flexGrow: 1 }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={6}
              justifyContent="center"
              alignContent="center"
              paddingBottom="2em"
            >
              <Grid item md={12}>
                <SubtitleText text={"Línea base"} color={grey}></SubtitleText>
                <TitleText
                  text={"Resultado de la evaluación diagnóstico"}
                ></TitleText>
              </Grid>

              <Grid item md={10}>
                {/* <Typography color={blue} fontSize={18}>Elaborado por Controla - Fecha de resultado de evaluación: 2022-12-21</Typography> */}
                <Typography fontSize={20} color={blue}>
                  El resultado de la evaluación de la Fase I es:
                </Typography>
              </Grid>
              <Grid item md={2}>
                <ButtonControla
                  backgroundColor={"#305AD9"}
                  backgroundColorHover={"#0E4AFF"}
                  textTooltip={"Regresar"}
                  textButton={"Regresar"}
                  url={"/baseline/diagnosis"}
                />
              </Grid>
              <Grid container item md={12}>
                {/* <Grid item md={12} sx={{ display: "flex", justifyContent: "center", alignContent: "center" }} >
                                    <TitleText text={"¡Bienvenido Empresa SAC!"} />
                                </Grid> */}
                {/* <Grid item md={12} sx={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                                    <Typography fontSize={20} color={blue}>El resultado de la evaluación de la Fase I es:</Typography>
                                </Grid> */}
              </Grid>

              <Grid
                container
                item
                md={8}
                direction="row"
                gap={2}
                justifyContent="center"
                alignContent="center"
              >
                <Grid
                  container
                  item
                  md={5}
                  direction="row"
                  justifyContent="center"
                  alignContent="center"
                >
                  <Grid item md={12}>

                    <Card
                      sx={{
                        backgroundColor:
                          baselineInfo.percentage < 39
                            ? "#EB5757"
                            : baselineInfo.percentage >= 39 &&
                              baselineInfo.percentage < 80
                            ? "#F2994A"
                            : baselineInfo.percentage > 80
                            ? "#27AE60"
                            : "",
                        borderRadius: "14px",
                      }}
                    >
                      <CardContent>
                        <Grid
                          container
                          item
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Grid item md={8}>
                            <Typography color={"white"} fontSize={48} >
                              {baselineInfo.percentage + "%"}
                            </Typography>
                          </Grid>
                          <Grid item md={8}>
                            <Typography color={"white"} fontSize={38}>
                              {baselineInfo.percentage < 39
                                ? "Insuficiente"
                                : baselineInfo.percentage >= 39 &&
                                  baselineInfo.percentage < 80
                                ? "Regular"
                                : baselineInfo.percentage > 80
                                ? "Bueno"
                                : ""}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>

                <Grid item md={12}>
                  <StyledCard sx={{ backgroundColor: "#27AE60" }}>
                    <CardContent>
                      <Grid container item>
                        <Grid item md={1.5}>
                          Bueno: 80-100%
                        </Grid>
                        <Grid item md={1.5}></Grid>
                        <Grid item md={9}>
                          Existe un mayor cumplimiento de la Implementación del
                          Sistema de Seguridad y Salud Ocupacional
                        </Grid>
                        <Grid item md={3}></Grid>
                        <Grid item md={9}></Grid>
                      </Grid>
                    </CardContent>
                  </StyledCard>
                </Grid>

                <Grid item md={12}>
                  <StyledCard sx={{ backgroundColor: "#F2994A" }}>
                    <CardContent>
                      <Grid container item>
                        <Grid item md={1.5}>
                          Regular: 40-79%
                        </Grid>
                        <Grid item md={1.5}></Grid>
                        <Grid item md={9}>
                          Existe un cumplimiento moderado de la Implementación
                          del Sistema de Seguridad y Salud Ocupacional
                        </Grid>
                        <Grid item md={3}></Grid>
                        <Grid item md={9}></Grid>
                      </Grid>
                    </CardContent>
                  </StyledCard>
                </Grid>

                <Grid item md={12}>
                  <StyledCard sx={{ backgroundColor: "#EB5757" }}>
                    <CardContent>
                      <Grid container item>
                        <Grid item md={2}>
                          Insuficiente: 0-39%
                        </Grid>
                        <Grid item md={1}></Grid>
                        <Grid item md={9}>
                          Existe carencia y desconocimiento de la Implementación
                          del Sistema de Seguridad y Salud Ocupacional
                        </Grid>
                        <Grid item md={3}></Grid>
                        <Grid item md={9}></Grid>
                      </Grid>
                    </CardContent>
                  </StyledCard>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <Typography
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    color: blue,
                    fontSize: 21,
                  }}
                >
                  Puedes implementar las acciones de mejoras de dos formas
                </Typography>
              </Grid>
              <Grid item md={3.5}>
                <Typography sx={{ color: blue, fontSize: 21 }}>
                  Puedes implementar a través del Plan de acciones de mejora de
                  linea base
                </Typography>
              </Grid>
              <Grid item md={2}>
                <ButtonControla
                  iconButton={<TaskAlt />}
                  backgroundColor={"#169073"}
                  backgroundColorHover={"#1BAA88"}
                  textButton={"Implementar"}
                  tooltipText={"No disponible en esta versión"}
                  readOnly
                ></ButtonControla>
              </Grid>
              <Grid item md={3.5}>
                <Typography sx={{ color: blue, fontSize: 21 }}>
                  Puedes agregar las acciones de mejora al Programa Anual de SSO{" "}
                </Typography>
              </Grid>
              <Grid item md={2}>
                <Link href="/documentation/programs/sso">
                  <ButtonControla
                    iconButton={<TaskAlt />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Implementar"}
                  ></ButtonControla>
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default BaselineResults;
