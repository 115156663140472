import { React, useState, useEffect } from "react";
import SubtitleText from "../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import SideNav from "../../../components/sidenav/sideNav";
import Box from "@mui/material/Box";
import { Grid, Button, Typography, Stack, DialogActions, MenuItem } from "@mui/material";
import { Add, BorderColorOutlined, Delete, Event } from "@mui/icons-material";
import TitleText from "../../../components/text/titleText";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/system";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import InputControlaVertical from "../../../components/textfields/inputControlaVertical";
import MyPrograms from "../../../api/MyPrograms";
import ButtonIconControla from "../../../components/buttons/buttonIconControla";
import CompanyLogoControla from "../../../components/images/companyLogoControla";
import ButtonControla from "../../../components/buttons/buttonController";
import LoadingControla from "../../../components/load/loadingControla";
import SelectControla from "../../../components/selects/selectControla";
import DialogTitleControla from "../../../components/dialog/dialogTitleControla";
import DialogConfirmControla from "../../../components/dialog/dialogConfirmControla";

const colorTitle = "#9191B0";
const green = "#2C8C75";
const lilac = "#D1D1E9";
const skyblue = "#2D9CDB";

const StyledTypography = styled(Typography)({
    color: "#305AD9",
    fontSize: 18,
    fontWeight: 600,
    gap: "2%",
    padding: "0.5em",
});

function ObjectiveGeneral() {
    const params = new URLSearchParams(window.location.search);
    const idprograms = params.get("id_programs");
    const idobjectivegeneral = params.get("idobjectivegeneral");
    const nameobjectivegeneral = params.get("nameobjectivegeneral");


    const weeks = [1, 2, 3, 4];
    const months = [
        {
            id: 1,
            month: 'Enero'
        },
        {
            id: 2,
            month: 'Febrero'
        },
        {
            id: 3,
            month: 'Marzo'
        },
        {
            id: 4,
            month: 'Abril'
        },
        {
            id: 5,
            month: 'Mayo'
        },
        {
            id: 6,
            month: 'Junio'
        },
        {
            id: 7,
            month: 'Julio'
        },
        {
            id: 8,
            month: 'Agosto'
        },
        {
            id: 9,
            month: 'Septiembre'
        },
        {
            id: 10,
            month: 'Octubre'
        },
        {
            id: 11,
            month: 'Noviembre'
        },
        {
            id: 12,
            month: 'Diciembre'
        }
    ]

    // Pantallas de carga y alertas INICIO
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [typeAlert, setTypeAlert] = useState("success");

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert("");
        setTypeAlert("success");
    };

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    // Pantallas de carga y alertas FIN

    useEffect(() => {
        handleSearchPrograms();

    }, []);

    const [data, setData] = useState([]);

    const handleSearchPrograms = async () => {
        handleOpenLoading();
        const response = await MyPrograms.programsExtends({ idprograms });
        if (response.success === false) {

        } else {
            setData(response);
            handleOpenAlert(response.msg, "success");
        }
        return response;
    };


    const handleSearchColumnData = (rowsColumnsData = [], idColumns, idProgramming) => {
        const columnId = "c" + idColumns;
        const programmingId = "p" + idProgramming;

        let data = "";

        if (rowsColumnsData != []) {
            let columnData = [];
            try {
                columnData = rowsColumnsData[`${columnId}`][`${programmingId}`];
                if (columnData) {
                    data = columnData["programs_objectives_programming_columns_data"];
                }
            } catch (error) {
                columnData = [];
            }
        }


        return data;
    };

    const handleSearchProgrammingProgramming = (rowsProgrammingProgramming = [], idProgramming, month, week) => {
        const programmingId = "p" + idProgramming;
        const monthId = "m" + month;
        const weekId = "w" + week;

        let data = "";
        if (rowsProgrammingProgramming != []) {
            let programmingProgramming = [];
            try {
                programmingProgramming = rowsProgrammingProgramming[`${programmingId}`][`${monthId}`][`${weekId}`];
                if (programmingProgramming["id_programs_objectives_programming_rels_programming"] !== undefined) {
                    let backgroundColor = 'green';
                    let programmingType = 'P';
                    let color = 'white';
                    switch (programmingProgramming["id_programs_objectives_programming_rels_programming_types"]) {
                        case 1:
                            backgroundColor = '#169073';
                            programmingType = 'P';
                            color = 'white';
                            break;
                        case 2:
                            backgroundColor = '#F2994A';
                            programmingType = 'R';
                            color = 'white';
                            break;
                        case 3:
                            backgroundColor = '#2D9CDB';
                            programmingType = 'E';
                            color = 'white';
                            break;
                    }
                    data = (<Box>
                        <Box sx={{ textAlign: 'center', minWidth: '70px' }}>
                            <Typography sx={{ backgroundColor: backgroundColor, color: color, padding: '2px 5px', borderRadius: '8px', textAlign: 'center' }}>
                                {programmingProgramming["id_programs_objectives_programming_rels_programming"] + ' - ' + programmingType}
                            </Typography>
                        </Box>
                    </Box>);
                }
            } catch (error) {
                programmingProgramming = [];
            }

        }

        return data;
    };


    function unique(arr) {
        let result = [];

        for (let str of arr) {
            if (!result.includes(str)) {
                result.push(str);
            }
        }

        return result;
    }

    const handleSearchProgrammingTotal = (rowsProgrammingProgramming = [], idProgramming) => {
        let total = [];
        months.forEach(month => {
            weeks.forEach(week => {
                const programmingId = "p" + idProgramming;
                const monthId = "m" + month.id;
                const weekId = "w" + week;
                let programmingProgramming = [];
                try {
                    programmingProgramming = rowsProgrammingProgramming[`${programmingId}`][`${monthId}`][`${weekId}`];
                    if (programmingProgramming["id_programs_objectives_programming_rels_programming"] !== undefined) {
                        total.push(programmingProgramming["id_programs_objectives_programming_rels_programming"]);
                    }
                } catch (error) {
                    programmingProgramming = [];
                }

            });
        });
        const totalCount = unique(total).length;

        return totalCount;
    };

    const handleSearchProgrammingPercentage = (rowsProgrammingProgramming = [], idProgramming) => {
        let total = [];
        let complete = [];
        months.forEach(month => {
            weeks.forEach(week => {
                const programmingId = "p" + idProgramming;
                const monthId = "m" + month.id;
                const weekId = "w" + week;
                let programmingProgramming = [];
                try {
                    programmingProgramming = rowsProgrammingProgramming[`${programmingId}`][`${monthId}`][`${weekId}`];
                    if (programmingProgramming["id_programs_objectives_programming_rels_programming"] !== undefined) {
                        total.push(programmingProgramming["id_programs_objectives_programming_rels_programming"]);
                        if (programmingProgramming["id_programs_objectives_programming_rels_programming_types"] == 3) {
                            complete.push(programmingProgramming["id_programs_objectives_programming_rels_programming"]);
                        }
                    }
                } catch (error) {
                    programmingProgramming = [];
                }

            });
        });

        const totalCount = unique(total).length;
        const completeCount = unique(complete).length;
        const percentage = completeCount * 100 / totalCount;
        return percentage;
    };

    return (
        <Box sx={{ display: "flex", height: "100%" }}>
            <SideNav returnBack={'/documentation/programs/training/editprograms?id_programs=' + idprograms} />
            <LoadingControla
                openLoading={openLoading}
                openAlert={openAlert}
                handleCloseAlert={handleCloseAlert}
                messageAlert={messageAlert}
                typeAlert={typeAlert}
            />

            <Container>

                <Box sx={{ flexGrow: 1 }}>
                    <Grid container rowSpacing={2} columnSpacing={2} alignItems="center">
                        <Grid item xs={11} sm={11} md={10} lg={10} xl={10}>
                            <SubtitleText text={"Programa anual de capacitaciones"} color={'#9191B0'}></SubtitleText>
                            <TitleText text={data.name} />
                        </Grid>
                        <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
                            <CompanyLogoControla />
                        </Grid>
                    </Grid>
                </Box >
                {data.objectives_generals !== undefined ?
                    data.objectives_generals.map((data) => (
                        <Box key={data.id_programs_objectives_general}>
                            <Grid
                                container
                                spacing={2}
                                sx={{ mt: 2 }}
                            >
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <StyledTypography sx={{
                                        padding: '8px',
                                        fontSize: '25px',
                                        color: '#305AD9',
                                        fontWeight: 'bold'
                                    }}>{data.programs_objectives_general}</StyledTypography>
                                </Grid>
                                <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                    <StyledTypography sx={{
                                        padding: '8px',
                                        fontSize: '20px',
                                        color: '#305AD9',
                                        fontWeight: 'bold'
                                    }}>Objetivos especificos</StyledTypography>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    style={{ textAlign: "center" }}
                                >
                                    <TableContainer component={Paper}>
                                        <Table stickyHeader sx={{ width: "100%", minWidth: "100%" }}>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell
                                                        align="center"
                                                        sx={{
                                                            color: "#305ad9",
                                                            fontSize: "16px",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Número de objetivo
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        sx={{
                                                            color: "#305ad9",
                                                            fontSize: "16px",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Objetivo Especifico
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        sx={{
                                                            color: "#305ad9",
                                                            fontSize: "16px",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Meta
                                                    </TableCell>
                                                    <TableCell
                                                        align="center"
                                                        sx={{
                                                            color: "#305ad9",
                                                            fontSize: "16px",
                                                            fontWeight: "bold",
                                                        }}
                                                    >
                                                        Indicador
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {data.objectives_specifics.map((specific) => (
                                                    <TableRow
                                                        hover
                                                        key={
                                                            specific.id_programs_objectives_specific
                                                        }
                                                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                                    >
                                                        <TableCell align="center">
                                                            {specific.order_number}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {specific.programs_objectives_specific}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {specific.goals}
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {specific.indicators}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid >

                            <Box sx={{ flexGrow: 1, mt: 3 }}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ mt: 2 }}
                                    style={{ borderRadius: "15px" }}
                                >
                                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                                        <StyledTypography sx={{
                                            padding: '8px',
                                            fontSize: '20px',
                                            color: '#305AD9',
                                            fontWeight: 'bold'
                                        }}>Actividades</StyledTypography>
                                    </Grid>
                                </Grid >
                            </Box >

                            <Grid item md={12} style={{ textAlign: "center" }}>
                                <TableContainer component={Paper}>
                                    <Table
                                        stickyHeader
                                        sx={{ width: "100%", minWidth: "100%" }}
                                        aria-label="simple table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell
                                                    align="center"
                                                    sx={{
                                                        color: "#305ad9",
                                                        fontSize: "16px",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    N°
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    sx={{
                                                        color: "#305ad9",
                                                        fontSize: "16px",
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    Actividad
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.objectives_activities.map((activities) => (
                                                <TableRow
                                                    hover
                                                    key={activities.id_programs_objectives_activities}
                                                    sx={{
                                                        "&:last-child td, &:last-child th": { border: 0 },
                                                    }}
                                                >
                                                    <TableCell align="center">
                                                        {activities.order_number}
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {activities.programs_objectives_activities}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>

                            <Box sx={{ flexGrow: 1, mt: 3 }}>
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ mt: 3 }}
                                    style={{ borderRadius: "15px" }}
                                >
                                    <Grid item xs={12} sm={12} md={7} lg={6} xl={6}>
                                        <StyledTypography sx={{
                                            padding: '8px',
                                            fontSize: '20px',
                                            color: '#305AD9',
                                            fontWeight: 'bold'
                                        }}>Programación</StyledTypography>
                                    </Grid>

                                    <Grid item md={12} sx={{ mb: 3 }} style={{ textAlign: "center" }}>
                                        <TableContainer component={Paper}>
                                            <Table
                                                stickyHeader
                                                sx={{ width: "100%", minWidth: "100%" }}
                                                aria-label="simple table"
                                            >
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell rowSpan={2}>Nombres</TableCell>
                                                        <TableCell rowSpan={2}>Responsables</TableCell>
                                                        <TableCell rowSpan={2}>Recursos</TableCell>
                                                        <TableCell rowSpan={2}>Presupuesto</TableCell>
                                                        {data.objectives_programming_columns.map((column) => (
                                                            <TableCell
                                                                key={column.id_programs_objectives_programming_columns}
                                                                rowSpan={2}
                                                            >
                                                                {column.programs_objectives_programming_columns}
                                                            </TableCell>
                                                        ))}
                                                        {months.map((month) => (
                                                            <TableCell colSpan={4} align="center" key={month.id}>{month.month}</TableCell>
                                                        ))}
                                                        <TableCell rowSpan={2}>Total</TableCell>
                                                        <TableCell rowSpan={2}>Avance (%)</TableCell>
                                                    </TableRow>
                                                    <TableRow>
                                                        {months.map((month) => (
                                                            weeks.map((week) => (
                                                                <TableCell key={week + '' + month.id} align="center">{week}</TableCell>
                                                            ))
                                                        ))}
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {data.objectives_programming.map((programming) => (
                                                        <TableRow
                                                            hover
                                                            key={programming.id_programs_objectives_programming}
                                                            sx={{
                                                                "&:last-child td, &:last-child th": { border: 0 },
                                                            }}
                                                        >
                                                            <TableCell align="left">
                                                                {programming.programs_objectives_programming}
                                                            </TableCell>
                                                            <TableCell align="left">
                                                                {programming.first_name + " " + programming.last_name}
                                                            </TableCell>
                                                            <TableCell align="left">{programming.resources}</TableCell>
                                                            <TableCell align="left">{programming.budgets}</TableCell>
                                                            {data.objectives_programming_columns.map((column, key) => (
                                                                <TableCell key={key}>
                                                                    {handleSearchColumnData(
                                                                        data.objectives_programming_columns_data,
                                                                        column.id_programs_objectives_programming_columns,
                                                                        programming.id_programs_objectives_programming
                                                                    )}
                                                                </TableCell>
                                                            ))}

                                                            {months.map((month) => (
                                                                weeks.map((week) => (
                                                                    // <TableCell>{month.id + ' - ' + week}</TableCell>
                                                                    <TableCell key={week + '' + month.id}>{handleSearchProgrammingProgramming(data.objectives_programming_programming, programming.id_programs_objectives_programming, month.id, week)}</TableCell>
                                                                ))
                                                            ))}

                                                            <TableCell align="left">{handleSearchProgrammingTotal(data.objectives_programming_programming, programming.id_programs_objectives_programming)}</TableCell>
                                                            <TableCell align="left">{handleSearchProgrammingPercentage(data.objectives_programming_programming, programming.id_programs_objectives_programming)}%</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Grid>
                                </Grid>
                            </Box>

                        </Box >
                    )) : ''}
            </Container >

        </Box>
    );
}

export default ObjectiveGeneral;
