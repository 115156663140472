import { Avatar, Stack, Typography } from "@mui/material";
import React from "react";
import MyConfigurationApi from "../../api/MyConfigurationApi";


function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function UsersPicturesControla({ textAvatar = undefined, textDescription = undefined, backgroundAvatar = undefined }) {
    const userFirstName = (MyConfigurationApi.userData().first_name ? MyConfigurationApi.userData().first_name : '');
    const userLastName = (MyConfigurationApi.userData().last_name ? MyConfigurationApi.userData().last_name : '');
    if (textAvatar === undefined || textAvatar === null || textAvatar === '') {
        textAvatar = userFirstName.charAt(0) + '' + userLastName.charAt(0);
    }

    if (backgroundAvatar === undefined || backgroundAvatar === null || backgroundAvatar === '') {
        backgroundAvatar = stringToColor(userFirstName.charAt(0) + '' + userLastName.charAt(0));
    }

    return (
        <Stack
            direction="row"
            spacing={1}
            justifyContent="space-between"
            alignItems="center"
        >
            <Avatar sx={{ bgcolor: backgroundAvatar, fontSize: '15px', fontWeight: '700' }} >{textAvatar}</Avatar>
            {textDescription === undefined || textDescription === null || textDescription === '' ?
                ''
                :
                <Typography sx={{ fontSize: '18px', color: '#034AFF' }}>
                    {textDescription}
                </Typography>
            }
        </Stack>

    );
}

export default UsersPicturesControla;
