import MyConfigurationApi from "./MyConfigurationApi";

function getAreas() {
  // const url = "areas/get";
  const url = "configuration_areas";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function createAreas({ area }) {
  // const url = "areas/store";
  const url = "configuration_areas";

  const data = {
    area: area,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function updateAreas({ idArea, area }) {
  // const url = "areas/update/" + idArea;
  const url = "configuration_areas/"+idArea;

  const data = {
    area: area,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function deleteAreas({ idArea }) {
  // const url = "areas/delete/" + idArea;
  const url = "configuration_areas/"+idArea;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "DELETE",
    url: url,
  });
}

const MyAreas = {
  getAreas,
  createAreas,
  updateAreas,
  deleteAreas,
};

export default MyAreas;
