import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Grid,
  Button,
  Tabs,
  Tab,
  Stack,
  InputLabel,
  InputBase,
  DialogActions,
  Typography,
} from "@mui/material";
import {
  Add,
  BorderColorOutlined,
  Delete,
  ArrowBackIosNew,
  ArrowForwardIos,
  Upload,
  Archive,
  FileUploadOutlined,
  Send,
  Unarchive,
} from "@mui/icons-material";
import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";

import SideNav from "../../components/sidenav/sideNav";
import LoadingControla from "../../components/load/loadingControla";
import DialogConfirmControla from "../../components/dialog/dialogConfirmControla";
import SubtitleText from "../../components/text/subtitleText";
import BannerControla from "../../components/cards/bannerControla";
import ButtonControla from "../../components/buttons/buttonController";
import ButtonIconControla from "../../components/buttons/buttonIconControla";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import DialogTitleControla from "../../components/dialog/dialogTitleControla";
import styled from "styled-components";
import MyAnnualPlan from "../../api/MyAnnualPlan.jsx";

const blue = "#034AFF";
const purple = "#8B8BD8";

function Index() {
  const params = new URLSearchParams(window.location.search);
  const idTabs = parseInt(
    params.get("idTabs") !== null ? params.get("idTabs") : 0
  );

  // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  //fin de alerts

  // Dialogos de confirmacion INICIO

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    // setIdPETS(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    switch (buttonActionType) {
      case "delete":
        // deletePets();
        break;
      //   case "revision":
      //     updatestatusesPets(2);
      //     break;
      //   case "public":
      //     updatestatusesPets(3);
      //     break;
      //   case "archived":
      //     updatearchivedPets();
      //     break;
      //   case "unarchived":
      //     updateunarchivedPets();
      //     break;
    }
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN

  // Abrir diálogo de cargar archivo

  const [develop, setDevelop] = useState(null);
  const [date, setDate] = useState(null);
  const [title, setTitle] = useState(null);
  const [documentUrl, setDocumentUrl] = useState(null);

  const [rowsAnnualPlanTemplate, setRowsAnnualPlanTemplate] = useState([]);
  const [rowsAnnualPlan, setRowsAnnualPlan] = useState([]);

  // mostrar AnnualPlan docuemntos
  const handleGetAnnualPlan = async (inputYear = undefined) => {
    if (inputYear != undefined) {
      handleOpenLoading();
    }
    const response = await MyAnnualPlan.getAnnualPlan();
    if (response.success === false) {
      handleOpenAlert("Ocurrio un error al listar los planes anuales", "error");
    } else {
      setRowsAnnualPlan(response);
    }
    return response;
  };

  // Abrir modal subir documento
  const [openModalUploaFile, setOpenModalUploadFile] = useState(false);
  const handleOpenModalUploadFile = () => {
    setDevelop(null);
    setDate(null);
    setTitle(null);
    setDocumentUrl(null);
    setOpenModalUploadFile(true);
  };

  // editar rist document
  const [idRist, setIdRist] = useState();
  const [openModalUploaFileEdit, setOpenModalUploadFileEdit] = useState(false);
  const handleOpenModalUploadFileEdit = (idRist) => {
    setIdRist(idRist);
    handleEditAnnualPlan(idRist);
    setOpenModalUploadFileEdit(true);
  };

  const handleEditAnnualPlan = async (idRist) => {
    const response = await MyAnnualPlan.editAnnualPlan({
      idAnnualPlan: idRist,
    });
    setDevelop(response.develop);
    setTitle(response.title);
    setDate(response.date);
    return response;
  };
  const handleUpdateAnnualPlan = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyAnnualPlan.UpdateAnnualPlan({
      idAnnualPlan: idRist,
      develop: develop,
      title: title,
      date: date,
      document_url: documentUrl,
    });
    handleGetAnnualPlan();
    setOpenModalUploadFileEdit(false);
    handleOpenAlert("Los datos fueron editados de forma correcta", "success");
    return response;
  };

  // Mostrar plantillas
  const handleGetAnnualPlanTemplate = async (inputYear = undefined) => {
    if (inputYear != undefined) {
      handleOpenLoading();
    }
    const response = await MyAnnualPlan.getAnnualPlanTemplate();
    if (response.success === false) {
      handleOpenAlert("Ocurrio un error al listar los AnnualPlan", "error");
    } else {
      setRowsAnnualPlanTemplate(response);
    }
    return response;
  };

  //   crear
  const handleCreateAnnualPlan = async (event) => {
    event.preventDefault();

    handleOpenLoading();
    const response = await MyAnnualPlan.createAnnualPlan({
      develop: develop,
      title: title,
      date: date,
      document_url: documentUrl,
    });
    if (response.success !== false) {
      setOpenModalUploadFile(false);
      handleOpenAlert("El documento se ha creado de forma correcta", "success");
      handleGetAnnualPlan();
      window.location.href = "/documentation/annualPlan?idTabs=0";
    } else {
      handleOpenAlert("Ocurrió un error al crear el archivo", "error");
    }
    return response;
  };

  // Diálogo para template
  const [openModalTemplate, setOpenModalTemplate] = useState(false);
  const handleOpenModalTemplate = () => {
    setOpenModalTemplate(true);
  };

  // Dialog para ver plantilla
  const [openModalTemplateView, setOpenModalTemplateView] = useState(false);
  const handleOpenModalTemplateView = (event) => {
    event.preventDefault();
    setOpenModalTemplateView(true);
  };

  //usar plantilla

  const handleUseTemplate = async (event) => {
    event.preventDefault();
    window.location.href = "/documentation/annualPlan/template";
  };

  const StyledModalSubtitle = styled("h5")({
    fontWeight: "bold",
    padding: 0,
    margin: 0,
  });

  const currentTime = new Date();
  const [year, setYear] = useState(currentTime.getFullYear());

  const [documentType, setDocumentType] = useState(
    idTabs !== undefined && idTabs !== null && idTabs !== "" ? idTabs : 0
  );

  const handleChangeDocumentType = (event, newValue) => {
    setDocumentType(newValue);
  };

  const handleIncreaseYear = (event, newValue) => {
    setYear(year + 1);
    // handleGetPets(year + 1);
  };

  const handleDecreaseYear = (event, newValue) => {
    setYear(year - 1);
    // handleGetPets(year - 1);
  };

  useEffect(() => {
    handleGetAnnualPlan();
    handleEditAnnualPlan();

    handleGetAnnualPlanTemplate();
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="/documentation" />
      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />

      <Container>
        <Box>
          <Grid container>
            <Grid item xs={12} md={12}>
              <SubtitleText
                text={"Procedimientos"}
                color={"#9191B0"}
              ></SubtitleText>
              <BannerControla
                image="/assets/images/banners/documents/bannerplananualsso.png"
                color2="#2F67BC"
                color1="#8FBCFF"
                text="Plan Anual de SSO"
              />
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12} sm={7} md={6} lg={6} xl={6}>
              <Stack direction={"row"} spacing={2}>
                <Box>
                  <ButtonControla
                    roles={[1, 7, 8, 15]}
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#1cb591"}
                    backgroundColorHover={"#169073"}
                    textButton={"Agregar usando plantilla"}
                    functionOnClick={handleOpenModalTemplate}
                  />
                </Box>
                <Box>
                  <ButtonControla
                    roles={[1, 7, 8, 15]}
                    iconButton={<Upload sx={{ color: "white" }} />}
                    textButton={"Subir mi documento"}
                    backgroundColor={"#2D9CDB"}
                    backgroundColorHover={"#1976d2"}
                    functionOnClick={handleOpenModalUploadFile}
                  />
                </Box>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={2} lg={3} xl={3} sx={{ mt: 2 }}>
              {" "}
            </Grid>

            {/* filtrar por año */}
            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
              <Grid container spacing={2}>
                {/* <Grid item xs={3} sm={2} md={3} lg={3} xl={3}>
                  <InputLabel
                    sx={{
                      padding: "8px 0px 8px 0px",
                      fontSize: "14px",
                      color: "#305AD9",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    Periodo:
                  </InputLabel>
                </Grid>
                <Grid item xs={3} sm={3} md={4} lg={4} xl={4}>
                  <InputBase
                    type="number"
                    value={year}
                    onChange={"handleGetPets"}
                    readOnly
                    sx={{
                      width: "100%",
                      padding: "4px 10px 4px 10px",
                      backgroundColor: "white",
                      color: "#305AD9",
                      fontSize: "14px",
                      borderRadius: "8px",
                    }}
                  ></InputBase>
                </Grid> */}
                {/* <Grid item xs={2} sm={1} md={2} lg={2} xl={2}>
                  <IconButton
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "15px",
                      color: "#305AD9",
                    }}
                    onClick={handleDecreaseYear}
                  >
                    <ArrowBackIosNew />
                  </IconButton>
                </Grid> */}
                {/* <Grid item xs={2} sm={1} md={2} lg={2} xl={2}>
                  <IconButton
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "15px",
                      color: "#305AD9",
                    }}
                    onClick={handleIncreaseYear}
                  >
                    <ArrowForwardIos />
                  </IconButton>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Paper sx={{ overflow: "hidden" }}>
                <TableContainer sx={{ maxHeight: 400 }}>
                  <Table
                    stickyHeader
                    sx={{ width: "100%", minWidth: "700px" }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ color: blue }}>
                          Nombre del documento
                        </TableCell>
                        <TableCell
                          sx={{
                            color: blue,
                          }}
                        >
                          Estado
                        </TableCell>

                        <TableCell colSpan={4}>
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            <Tabs
                              value={documentType}
                              onChange={handleChangeDocumentType}
                              aria-label="Tipos de documento"
                            >
                              <Tab label="Plan Anual" />
                              <Tab label="Plantillas" />
                              <Tab label="Archivados" />
                            </Tabs>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody
                      sx={{ display: documentType !== 0 ? "none" : "" }}
                    >
                      {rowsAnnualPlan.map((row) => (
                        <TableRow
                          hover
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell
                            sx={{ color: purple }}
                            component="th"
                            scope="row"
                          >
                            {row.title}
                          </TableCell>
                          <TableCell sx={{ color: purple }}>
                            {row.register_status === 1 ? "Creado" : "Cerrado"}
                          </TableCell>
                          <TableCell sx={{ color: purple }}>
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                            >
                              {row.register_status === 1 ? (
                                <ButtonIconControla
                                  roles={[1, 3, 4, 7, 8, 15]}
                                  icon={
                                    <BorderColorOutlined
                                      sx={{ color: "white" }}
                                    />
                                  }
                                  backgroundColor={"#2D9CDB"}
                                  backgroundColorHover={"#33AEF4"}
                                  textTooltip={"Editar"}
                                  // el editar de activos debe enviar a un modal
                                  functionOnClick={() => {
                                    handleOpenModalUploadFileEdit(row.id);
                                  }}
                                />
                              ) : (
                                ""
                              )}

                              {row.register_status === 1 ? (
                                <ButtonIconControla
                                  roles={[1, 7, 8, 15]}
                                  icon={<Send sx={{ color: "white" }} />}
                                  backgroundColor={"#169073"}
                                  backgroundColorHover={"#1AA483"}
                                  textTooltip={"Enviar a comité"}
                                  // functionOnClick={() => {
                                  //   handleOpenModalConfirm(
                                  //     row.id_pets,
                                  //     "¿Esta seguro de enviar a revisión?",
                                  //     'Una vez enviado no podra editar nuevamente el PETS:  "' +
                                  //     rowsActives.name +
                                  //     '"',
                                  //     "Si, enviar",
                                  //     "revision"
                                  //   );
                                  // }}
                                />
                              ) : row.register_status === 2 ? (
                                <ButtonIconControla
                                  roles={[1, 7, 8]}
                                  icon={
                                    <FileUploadOutlined
                                      sx={{ color: "white" }}
                                    />
                                  }
                                  backgroundColor={"#169073"}
                                  backgroundColorHover={"#1AA483"}
                                  textTooltip={"Publicar"}
                                  // functionOnClick={() => {
                                  //   handleOpenModalConfirm(
                                  //     rowsActives.id_pets,
                                  //     "¿Esta seguro de publicar?",
                                  //     'Una vez publicado se cerrara el PETS:  "' +
                                  //     rowsActives.name +
                                  //     '"',
                                  //     "Si, publicar",
                                  //     "public"
                                  //   );
                                  // }}
                                />
                              ) : (
                                <></>
                              )}

                              <ButtonIconControla
                                ////todos los roles
                                icon={
                                  <VisibilityOutlined sx={{ color: "white" }} />
                                }
                                backgroundColor={"#305AD9"}
                                backgroundColorHover={"#0E4AFF"}
                                textTooltip={"Ver"}
                                directionUrl={row.document_url}
                                directionTarget={"_blank"}
                              />

                              {row.register_status === 3 ? (
                                <ButtonIconControla
                                  roles={[1, 7, 8]}
                                  icon={<Archive sx={{ color: "white" }} />}
                                  backgroundColor={"#F2994A"}
                                  backgroundColorHover={"#FF881E"}
                                  textTooltip={"Archivar"}
                                  // functionOnClick={() =>
                                  //   handleOpenModalConfirm(
                                  //     rowsActives.id_pets,
                                  //     "¿Esta seguro de archivar el PETS?",
                                  //     'PETS a archivar:  "' +
                                  //     rowsActives.name +
                                  //     '"',
                                  //     "Si, archivar",
                                  //     "archived"
                                  //   )
                                  // }
                                />
                              ) : (
                                ""
                              )}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>

                    <TableBody
                      sx={{ display: documentType !== 1 ? "none" : "" }}
                    >
                      {rowsAnnualPlanTemplate.map((row) => (
                        <TableRow
                          hover
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell sx={{ color: purple }}>
                            {row.title}
                          </TableCell>
                          <TableCell sx={{ color: purple }}>
                            {row.register_status === 1 ? "Creado" : "Cerrado"}
                          </TableCell>
                          <TableCell sx={{ color: purple }}>
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                            >
                              {row.register_status === 1 ? (
                                <ButtonIconControla
                                  roles={[1, 3, 4, 7, 8, 15]}
                                  icon={
                                    <BorderColorOutlined
                                      sx={{ color: "white" }}
                                    />
                                  }
                                  backgroundColor={"#2D9CDB"}
                                  backgroundColorHover={"#33AEF4"}
                                  textTooltip={"Editar"}
                                  // el editar de activos debe enviar a un modal
                                  functionOnClick={() => {
                                    window.location.href =
                                      "/documentation/annualPlan/template?idAnnualPlan=" +
                                      row.id;
                                  }}
                                />
                              ) : (
                                ""
                              )}

                              {row.register_status === 1 ? (
                                <ButtonIconControla
                                  roles={[1, 7, 8, 15]}
                                  icon={<Send sx={{ color: "white" }} />}
                                  backgroundColor={"#169073"}
                                  backgroundColorHover={"#1AA483"}
                                  textTooltip={"Publicar"}
                                  // functionOnClick={() => {
                                  //   handleOpenModalConfirm(
                                  //     rowsActives.id_pets,
                                  //     "¿Esta seguro de enviar a revisión?",
                                  //     'Una vez enviado no podra editar nuevamente el PETS:  "' +
                                  //     rowsActives.name +
                                  //     '"',
                                  //     "Si, enviar",
                                  //     "revision"
                                  //   );
                                  // }}
                                />
                              ) : (
                                <></>
                              )}

                              {row.document_url !== null ? (
                                <ButtonIconControla
                                  ////todos los roles
                                  icon={
                                    <VisibilityOutlined
                                      sx={{ color: "white" }}
                                    />
                                  }
                                  backgroundColor={"#305AD9"}
                                  backgroundColorHover={"#0E4AFF"}
                                  textTooltip={"Ver"}
                                  // directionUrl={rowsActives.document_url}
                                  // directionTarget={"_blank"}
                                />
                              ) : row.register_status !== 1 ? (
                                <ButtonIconControla
                                  ////todos los roles
                                  icon={
                                    <VisibilityOutlined
                                      sx={{ color: "white" }}
                                    />
                                  }
                                  backgroundColor={"#305AD9"}
                                  backgroundColorHover={"#0E4AFF"}
                                  textTooltip={"Ver"}
                                  // directionUrl={
                                  //   "/documentation/documents/procedures/pets/edit?idPets=" +
                                  //   rowsActives.id_pets
                                  // }
                                />
                              ) : (
                                ""
                              )}

                              {row.register_status === 3 ? (
                                <ButtonIconControla
                                  roles={[1, 7, 8]}
                                  icon={<Archive sx={{ color: "white" }} />}
                                  backgroundColor={"#F2994A"}
                                  backgroundColorHover={"#FF881E"}
                                  textTooltip={"Archivar"}
                                  // functionOnClick={() =>
                                  //   handleOpenModalConfirm(
                                  //     rowsActives.id_pets,
                                  //     "¿Esta seguro de archivar el PETS?",
                                  //     'PETS a archivar:  "' +
                                  //     rowsActives.name +
                                  //     '"',
                                  //     "Si, archivar",
                                  //     "archived"
                                  //   )
                                  // }
                                />
                              ) : (
                                ""
                              )}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    {/*
                    <TableBody
                      sx={{ display: documentType !== 2 ? "none" : "" }}
                    >
                      {rowsArchived.map((rowsArchives) => (
                        <TableRow
                          hover
                          key={rowsArchives.id_pets}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell sx={{ color: purple }}>
                            {rowsArchives.name}
                          </TableCell>
                          <TableCell sx={{ color: purple }}>
                            {rowsArchives.document_url != null &&
                              rowsArchives.document_url != undefined
                              ? "Archivo"
                              : "Sistema"}
                          </TableCell>
                          <TableCell sx={{ color: purple }}>
                            {rowsArchives.status}
                          </TableCell>
                          <TableCell align="right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                            >
                              {rowsArchives.document_url !== null ? (
                                <ButtonIconControla
                                  //todos pueden ver
                                  icon={
                                    <VisibilityOutlined
                                      sx={{ color: "white" }}
                                    />
                                  }
                                  backgroundColor={"#305AD9"}
                                  backgroundColorHover={"#0E4AFF"}
                                  textTooltip={"Ver"}
                                  directionUrl={rowsArchives.document_url}
                                  directionTarget={"_blank"}
                                // functionOnClick={() => ""}
                                />
                              ) : rowsArchives.id_pets_statuses !== 1 ? (
                                <ButtonIconControla
                                  //todos pueden ver
                                  icon={
                                    <VisibilityOutlined
                                      sx={{ color: "white" }}
                                    />
                                  }
                                  backgroundColor={"#305AD9"}
                                  backgroundColorHover={"#0E4AFF"}
                                  textTooltip={"Ver"}
                                  directionUrl={
                                    "/documentation/documents/procedures/pets/edit?idPets=" +
                                    rowsArchives.id_pets
                                  }
                                />
                              ) : (
                                ""
                              )}
                              <ButtonIconControla
                                roles={[1, 7, 8]}
                                icon={<Unarchive sx={{ color: "white" }} />}
                                backgroundColor={"#F2994A"}
                                backgroundColorHover={"#FF881E"}
                                textTooltip={"Desarchivar"}
                                functionOnClick={() =>
                                  handleOpenModalConfirm(
                                    rowsArchives.id_pets,
                                    "¿Esta seguro de desarchivar el PETS?",
                                    'PETS a desarchivar:  "' +
                                    rowsArchives.name +
                                    '"',
                                    "Si, desarchivar",
                                    "unarchived"
                                  )
                                }
                              // functionOnClick={() =>
                              //   updateunarchivedPets(rowsArchives.id_pets)
                              // }
                              />
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody> */}
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>

      {/* Dialog subir archivo */}
      <Dialog
        open={openModalUploaFile}
        onClose={() => setOpenModalUploadFile(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Plan Anual{" "}
          </Typography>
        </DialogTitle>

        <form onSubmit={handleCreateAnnualPlan}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Elaborado por:"}
                  inputType={"text"}
                  inputValue={setDevelop}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de elaboración:"}
                  inputType={"date"}
                  inputValue={setDate}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Título documento"}
                  inputType={"text"}
                  inputValue={setTitle}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Subir Archivo"}
                  inputType={"file"}
                  inputValue={setDocumentUrl}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              // color="success"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalUploadFile(false)}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                // backgroundColor: '#169073',
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog editar  archivo */}
      <Dialog
        open={openModalUploaFileEdit}
        onClose={() => setOpenModalUploadFileEdit(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Edita - AnnualPlan - Reglamento interno de trabajo{" "}
          </Typography>
        </DialogTitle>

        <form onSubmit={handleUpdateAnnualPlan}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Elaborado por:"}
                  inputType={"text"}
                  inputValue={setDevelop}
                  value={develop}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de elaboración:"}
                  inputType={"date"}
                  inputValue={setDate}
                  modalType={true}
                  value={date}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Título documento"}
                  inputType={"text"}
                  inputValue={setTitle}
                  modalType={true}
                  value={title}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Subir Archivo"}
                  inputType={"file"}
                  inputValue={setDocumentUrl}
                  modalType={true}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              // color="success"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalUploadFileEdit(false)}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                // backgroundColor: '#169073',
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog plantilla */}
      <Dialog
        open={openModalTemplate}
        onClose={() => setOpenModalTemplate(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Te presentamos unas plantillas para facilitar la elaboración de tu
            documento
          </Typography>
        </DialogTitle>

        <form onSubmit={handleOpenModalTemplateView}>
          <DialogActions>
            <Button
              variant="contained"
              // color="success"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalTemplate(false)}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                // backgroundColor: '#169073',
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Ver
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Modelo de plantilla */}
      <Dialog
        open={openModalTemplateView}
        onClose={() => setOpenModalTemplateView(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0, marginTop: "15px" }}
      >
        <DialogTitleControla titleText="AnnualPlan" />

        <form onSubmit={handleUseTemplate}>
          <DialogContent>
            <Grid container>
              <Grid item md={12}>
                <StyledModalSubtitle>I.Introducción</StyledModalSubtitle>
              </Grid>
              <Grid item md={12}>
                <Typography sx={{
                    fontSize: 14,
                }}>
                  NOMBRE_EMPRESA es una empresa dedicada a la _________________
                  y en cumplimiento del artículo 38° de la Ley N° 29783, Ley de
                  Seguridad y Salud en el Trabajo, su reglamento y normas
                  sectoriales elabora el presente Plan Anual de Seguridad y
                  Salud Ocupacional del año _____, en base a los resultados
                  obtenidos de la evaluación inicial o estudio de línea base del
                  Sistema de Gestión de Seguridad y Salud Ocupacional y su
                  mejora continua. Considerando que en NOMBRE_EMPRESA el
                  trabajador es el elemento más importante dentro de la
                  organización, los objetivos del presente plan están orientados
                  a alcanzar la protección de la vida y la salud de todos los
                  trabajadores de la organización, así como el compromiso de la
                  misma de generar y mantener ambientes de trabajo seguros y
                  saludables de manera permanente.
                </Typography>
              </Grid>
              <Grid item md={12}>
                <StyledModalSubtitle>II.Alcance</StyledModalSubtitle>
              </Grid>
              <Grid item md={12}>
              <Typography sx={{
                    fontSize: 14,
                }}>
                  El presente Plan Anual de Seguridad y Salud Ocupacional es de
                  aplicación para todos los trabajadores de NOMBRE_EMPRESA, así
                  como a todos sus contratistas y visitantes. 3. Requisitos
                  legales y otros A fin de elaborar el presente plan, la empresa
                  ha realizado la identificación del grado de cumplimiento de la
                  normativa legal general y sectorial que tiene que cumplir así
                  como otros requisitos que la organización elige cumplir, los
                  cuales deben ser actualizados y difundidos oportunamente para
                  su cumplimiento. Cabe señalar que la empresa debe mantener y
                  conservar información documentada sobre los requisitos legales
                  y otros requisitos en materia de seguridad y salud
                  ocupacional, como parte de la planificación de este sistema y
                  el compromiso del empleador.
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              // color="success"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalTemplateView(false)}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                // backgroundColor: '#169073',
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Usar plantilla
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}

export default Index;
