import { Grid, InputBase, InputLabel } from "@mui/material";
import React from "react";

function InputControla({ text, inputType }) {
  return (
    <Grid container>

      {text !== undefined ?

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <InputLabel
          sx={{
            padding: '8px',
            fontSize: '20px',
            color: '#305AD9',
            fontWeight: 'bold'
          }}> {text} </InputLabel>
      </Grid>
      : ''
      }
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <InputBase type={inputType}
          sx={{
            width: '100%',
            padding: '8px 20px 8px 20px',
            backgroundColor: 'white',
            color: '#305AD9',
            borderRadius: '8px'
          }}></InputBase>
      </Grid>
    </Grid >
  );
}

export default InputControla;
