import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function petar({ year }) {
    const url = 'petar/get?year=' + year;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

async function createPetar({ name, code, area, place, start_time, end_time, model, document_url }) {

    if (document_url !== null && document_url !== undefined){
        document_url = await uploadFileCompanies(document_url, 'documentation/documents/procedures/petars/');
    }
    const url = 'petar/store';
    const data = {
        'name': name,
        'code': code,
        'area': area,
        'place': place,
        'start_time': start_time,
        'end_time': end_time,
        'model': model,
        'document_url': document_url
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'POST', url: url });
}

function deletePetar({ idPetar}){
    const url = "petar/delete/" + idPetar;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'DELETE', url: url});
}

function editPetar({ id_petar }) {
    const url = 'petar/edit/' + id_petar;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

async function updatePetar({ id_petar, name, code, area, place, start_time, end_time, description, observation, tools, procedure, document_url }) {
    if (document_url !== null && document_url !== undefined) {
        document_url = await uploadFileCompanies(document_url);
    }
    const url = 'petar/update/' + id_petar;
    const data = {
        'name': name,
        'code': code,
        'area': area,
        'place': place,
        'start_time': start_time,
        'end_time': end_time,
        'description': description,
        'tools': tools,
        'procedure': procedure,
        'observation': observation,
        'document_url': document_url
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

function petarResponsibleGet({ id_petar }) {
    const url = 'petar/responsible/get/' + id_petar;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function petarNoResponsibleGet({ id_petar }) {
    const url = 'petar/responsible/get/users/' + id_petar;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function deletePetarResponsible({ id_petar_responsible }) {
    const url = 'petar/responsible/delete/' + id_petar_responsible;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'DELETE', url: url });
}

function sendToWorkers({ id_petar }) {
    const url = 'petar/update/statuses/' + id_petar;
    const data = {
        'id_petar_statuses' : 2,
    }
    console.log(url);
    console.log(data);
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

function publishPetar({ id_petar }) {
    const url = 'petar/update/statuses/' + id_petar;
    const data = { 
        'id_petar_statuses' : 3,
    }
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

function archivePetar({ id_petar }){
    const url = 'petar/update/archived/' + id_petar;
    const data = {
        'archived' : 1
    }
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

function unarchivePetar({ id_petar }){
    const url = 'petar/update/archived/' + id_petar;
    const data = {
        'archived' : 0
    }

    console.log(url);
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

function cancelledPetar( { id_petar }) {
    const url = 'petar/update/statuses' + id_petar;
    const data = {
         'id_petar_statuses' : 4,
    }
}

function createPetarResponsible({ id_petar, id_users }) {
    const url = 'petar/responsible/store/' + id_petar;
    const data = {
        'id_users': id_users
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'POST', url: url });
}

function petarTasksGet({ id_petar }) {
    const url = 'petar/tasks/get/' + id_petar;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function storePetarModel({ id_petar }) {
    const url = 'petar/store/petar/model/' + id_petar;
    return MyConfigurationApi.connectApi({dataJson: null, method: 'POST', url: url});
}

//////////////EPPS

function eppPetarGet({ id_petar }) {
    const url = 'petar/epps/get/' + id_petar;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function eppsNoPetarGet({ id_petar }) {
    const url = 'petar/epps/get/epps/' + id_petar;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function eppsStore({ id_petar, id_epps }) {
    const url = 'petar/epps/store/' + id_petar;
    const data = {
        'id_epps' : id_epps,
    }
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'POST', url: url});
}

function eppsDelete({ id_petar_epps }) {
    const url = 'petar/epps/delete/' + id_petar_epps;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'DELETE', url: url});
}

function petarEppsAuthorizations({ id_petar }) {
    const url = 'petar/authorizations/get/' + id_petar;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

//////////////EPPS

// function petarEppsGet({ id_petar }) {
//     const url = 'petar/epps/get/' + id_petar;
//     return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
// }
/////////////////////////////////////////////////////////////////////////////////////////////////////

function getNoAuthorizationUsers({ idPetar }) {
    const url = 'petar/authorizations/get/users/' + idPetar;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url});
}

function getAuthorizations({ idPetar }) {
    const url = 'petar/authorizations/get/' + idPetar;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function storeAuthorizations({ idPetar, idUsers }) {
    const url = 'petar/authorizations/store/' + idPetar;
    const data = {
        'id_users' : idUsers
    }
    return MyConfigurationApi.connectApi({ dataJson: data, method:
    'POST', url: url});
}

function updateAutorizations({ idPetarAuthorizations }) {
    const url = 'petar/authorizations/update/firm/'+ idPetarAuthorizations;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'PUT', url: url });
}

function deleteAuthorizations({ idPetarAuthorizations }) {
    const url = 'petar/authorizations/delete/'+ idPetarAuthorizations;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'DELETE', url: url });
}

function signResponsible({ idPetarResponsible }) {
    const url = 'petar/responsible/update/firm/' + idPetarResponsible;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'PUT', url: url });
}

function signAuthorization({ idPetarAuthorizations }) {
    const url = 'petar/authorizations/update/firm/' + idPetarAuthorizations;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'PUT', url: url });
}

function signResponsibleStart({ idPetarResponsible }) {
    const url = 'petar/responsible/update/firm/start/' + idPetarResponsible;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'PUT', url: url });
}

function signResponsibleEnd({ idPetarResponsible }) {
    const url = 'petar/responsible/update/firm/end/' + idPetarResponsible;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'PUT', url: url });
}

function generatePDF({ idPetar }) {
    const url = 'petar/update/generate/pdf/' + idPetar;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'PUT', url: url });
}

const MyPetar = {
    petar, createPetar, createPetarResponsible, editPetar, updatePetar, petarResponsibleGet, petarTasksGet, petarEppsAuthorizations, deletePetar,
    eppPetarGet, eppsNoPetarGet, eppsStore, eppsDelete, storePetarModel, petarNoResponsibleGet, deletePetarResponsible, getNoAuthorizationUsers, getAuthorizations, storeAuthorizations,
    updateAutorizations, deleteAuthorizations, signResponsible, signAuthorization, sendToWorkers, publishPetar, archivePetar, unarchivePetar, signResponsibleStart, signResponsibleEnd, generatePDF
}

export default MyPetar;
