import { Grid, IconButton, Tooltip } from "@mui/material";
import { React } from "react";
import MyConfigurationApi from "../../api/MyConfigurationApi";

function ButtonIconControla({
  icon,
  backgroundColor,
  textTooltip,
  functionOnClick,
  backgroundColorHover = undefined,
  directionUrl = "",
  directionTarget = "",
  roles = [1],
  textIcon=''
}) {
  const userData = MyConfigurationApi.userData();
  let usersRoles = userData.roles;
  let showButton = false;
  roles.map((rol) => {
    if (usersRoles.includes(rol)) {
      showButton = true;
    }
  });
  if (showButton === true) {
    return (
      <Grid
        container
        sx={{
          maxWidth: "65px",
        }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Tooltip title={textTooltip} placement="top">
            <IconButton
              sx={{
                backgroundColor: { backgroundColor },
                "&:hover": {
                  backgroundColor:
                    backgroundColorHover === undefined
                      ? backgroundColor
                      : backgroundColorHover,
                  boxShadow:
                    "rgba(50, 50, 93, 0.25) 0px 6px 12px 1px, rgba(0, 0, 0, 0.3) 0px 3px 7px 1px",
                },
              }}
              onClick={functionOnClick}
              href={directionUrl}
              target={directionTarget}
              textIcon={textIcon}
            >
              {icon}
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  } else {
    return "";
  }
}

export default ButtonIconControla;
