import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React from "react";
import ButtonControla from "../buttons/buttonController";

function BannerButtonControla({
  color1,
  color2,
  image,
  title,
  functionButton,
  textButton,
  backgrounColorButton,
  hoverButton,
  subtitle,
  url
}) {
  return (
    <Card
      sx={{
        width: "100%",
        height: "154px",
        borderRadius: "16px",
        background: `linear-gradient(90deg, ${color2}, ${color1})`,
        backgroundSize: "100%",
        opacity: "0.75",
        color: "white",
        padding: "20px",
        position: "relative",
        fontSize: "90%",
      }}
    >
      <CardActionArea
       href={url}
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px 0",
        }}
      >
        <CardContent
          sx={{
            display: "flex",
            width: "70%",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Typography
            gutterBottom
            variant="h5"
            component="span"
            sx={{
              fontWeight: "bold",
              position: "absolute",
              top: "0",
              width: "60%",
            }}
          >
            {title}
          </Typography>
          <Typography
            gutterBottom
            variant="h6"
            component="span"
            sx={{ position: "absolute", top: "2", width: "60%" }}
          >
            {subtitle}
          </Typography>
         
        </CardContent>
        <CardMedia
          component="img"
          sx={{ width: "140px", display: "flex", justifyContent: "flex-end" }}
          image={image}
          alt="card"
        />
         {/* 
          <ButtonControla
            sx={{}}
            backgroundColor={backgrounColorButton}
            backgroundColorHover={hoverButton}
            textButton={textButton}
            functionOnClick={functionButton}
          /> */}
      </CardActionArea>
    </Card>
  );
}

export default BannerButtonControla;
