import React from 'react'
import { Button, Grid, TextField, Typography} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/system';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Add } from '@mui/icons-material';
import TitleText from '../components/text/titleText';

//Styled Components
const teal = "#2D9CDB";
const purple = "#5D5D93";
const lilac = "#D1D1E9";

const AddButton = styled(Button)({
    textTransform: 'none',
    borderRadius: 6,
    backgroundColor: '#169073'
});

const CloseButton = styled(Button)({
    textTransform: 'none',
    backgroundColor: lilac,
    color: purple,
    borderRadius: 6,
});

const StyledTealButton = styled(Button)({
    textTransform: 'none',
    borderRadius: 14,
    backgroundColor: teal,
    color: "white",
    width: "100%"
});



const StyledTextField = styled(TextField)({
    //label
    "& label.Mui-focused": {
        color: "#305AD9",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#305AD9",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "white",
            background: "white",
            borderRadius: " 16px"
        },
        "&:hover fieldset": {
            borderColor: "white",
            background: "white"
        },
        "&.Mui-focused fieldset": {
            borderColor: "white",
            background: "white"
        },
        "& .MuiFilledInput-root": {
            background: "white",
        },
    },
    width: "100%",
});

const StyledTypography = styled(Typography)({
    color: "#305AD9",
    fontSize: 16,
    fontWeight: 600,
    gap: "2%",
    padding: "0.5em"
});
//Styled Components

function AddMemberDialog() {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
        }}>
            <StyledTealButton variant='contained'
                startIcon={<Add />}
                disableElevation
                onClick={handleClickOpen}
            >Crear acta</StyledTealButton>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                PaperProps={{
                    style: {
                        backgroundColor: "#F5F5F5",
                        width: "55%",
                    },

                }}
                fullWidth={true}>

                <DialogTitle> <TitleText text={"Programación de Fechas"}></TitleText><IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton></DialogTitle>
                <DialogContent >

                    <Grid container rowSpacing={3} columnSpacing={6}>
                        <Grid item md={6}>
                            <StyledTypography>
                                Cargo dentro de la Junta
                            </StyledTypography>
                        </Grid>
                        <Grid item md={6}>
                            <StyledTextField />
                        </Grid>
                    </Grid>
                </DialogContent>


                <DialogActions>
                    <CloseButton disableElevation variant="contained" onClick={handleClose}>Salir</CloseButton>
                    <AddButton
                        disableElevation variant="contained">Crear acta</AddButton>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default AddMemberDialog