import React, { Component, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


export const EditorControla = ({ dataEdit, handleDataEditor = alert }) => {
  
  const template = `
  <h2>RISST - Reglamento interno de trabajo</h2>
  <h3> I.RESUMEN EJECUTIVO DE LA ACTIVIDAD DE LA EMPRESA</h3>
  <h3> II.OBJETIVOS Y ALCANCES</h3>
  <h3> III. LIDERAZGO Y COMPROMISOS, Y POLÍTICA DE SEGURIDAD Y SALUDA</h3>
    <ul>
      <li>Liderazgo y compromisos</li>
      <li>Política de seguridad y salud</li>
  </ul>
  <h3> IV. ATRIBUCIONES Y OBLIGACIONES DEL EMPLEADOR, DE LOS
  SUPERVISORES, DEL COMITÉ DE SEGURIDAD Y SALUD, DE LOS
  TRABAJADORES Y DE LOS EMPLEADORES QUE LES BRINDAN SERVICIOS SI
  LOS HUBIERA</h3>
      <ul>
          <li>Funciones y responsabilidades</li>
          <li>Organización interna de seguridad y salud ocupaciona</li>
          <li>
            Implementación de registros y documentación del Sistema de
            Gestión de Seguridad y salud ocupacional
          </li>
          <li>
            Funciones y responsabilidades de las empresas, entidades
            públicas o privadas que brindan servicios
          </li>
      </ul>
  <h3> V.ESTÁNDARES DE SEGURIDAD Y SALUD EN LAS OPERACIONES</h3>
  <h3> VI. ESTÁNDARES DE SEGURIDAD Y SALUD EN LOS SERVICIOS Y
  ACTIVIDADES CONEXAS</h3>
  <h3> VII. PREPARACIÓN Y RESPUESTA A EMERGENCIAS</h3>        
  `;
  return (
    <div className="App">
      <CKEditor
        editor={ClassicEditor}
        data={dataEdit === null ? template : dataEdit}
        onReady={(editor) => {
          const data = editor.getData();
          handleDataEditor(data);
          // You can store the "editor" and use when it is needed.
          // console.log( 'Editor is ready to use!', editor );
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          handleDataEditor(data);
          // console.log({ data });
        }}
        // onChange={(event, editor) => dataEditor(editor.getData())}

        onBlur={(event, editor) => {
          const data = editor.getData();
          handleDataEditor(data);
        }}
        onFocus={(event, editor) => {
          const data = editor.getData();
          handleDataEditor(data);
        }}
      />
    </div>
  );
};
