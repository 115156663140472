import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

// PROGRAMAS
// mostrar programas por año
function programs({ year, idType = 1 }) {
    const url = 'programs/get?year=' + year + '&idType=' + idType;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

// crear programa
async function createPrograms({ code, version, titulo, document_url, date_created, year, idType = 1 }) {
    if (document_url !== null && document_url !== undefined) {
        const current = new Date();
        const year = current.getFullYear();
        const month = current.getMonth() + 1;
        const day = current.getDate();
        document_url = await uploadFileCompanies(document_url, 'documentation/programs/sso/' + year + '/' + month + '/' + day + '/');

    }
    const url = 'programs/store';
    /*const archived = 0;*/
    const data = {
        'code': code,
        'version': version,
        'document_url': document_url,
        'name': titulo,
        'id_programs_types': idType,
        'date_created': date_created,
        'year': year
        /*'archived': archived*/
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'POST', url: url });
}

// actualizar programa
async function updatePrograms({ idprograms, code, version, document_url, name, date_created, year }) {
    console.log(date_created);
    if (document_url !== null && document_url !== undefined) {
        const current = new Date();
        const year = current.getFullYear();
        const month = current.getMonth() + 1;
        const day = current.getDate();
        document_url = await uploadFileCompanies(document_url, 'documentation/programs/sso/' + year + '/' + month + '/' + day + '/');

    }
    const url = 'programs/update/' + idprograms;
    const data = {
        'code': code,
        'version': version,
        'name': name,
        'document_url': document_url,
        'date_created': date_created,
        'year': year

    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });

}

// jalar datos de programa
function editPrograms({ idprograms }) {
    const url = 'programs/edit/' + idprograms;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

// eliminar programa
function deletePrograms({ idprograms }) {
    const url = 'programs/delete/' + idprograms;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'DELETE', url: url });
}

// creado 1 - enviado a trabajadores 2 - enviado a comité 3   -   aprobado por comité 4  -  desaprobado 5 - publicado 6
function updateStatusesPrograms({ id_programs, id_programs_statuses }) {
    const url = 'programs/update/statuses/' + id_programs;
    const data = {
        'id_programs_statuses': id_programs_statuses
    };

    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}
function updatearchivedPrograms({ id_programs }) {
    const url = 'programs/update/archived/' + id_programs;
    const data = {
        'archived': 1
    };

    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}


function updateUnarchivedPrograms({ id_programs }) {
    const url = 'programs/update/archived/' + id_programs;
    const data = {
        'archived': 0
    };

    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

// OBJETIVO GENERAL
// mostrar obj general
function getObjetiveGeneral({ idprograms }) {
    const url = 'programs/objectives/general/get/' + idprograms;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}
// crear objetivo general
function createObjetiveGeneral({ idprograms, objectiveGeneral, numObjectiveGeneral, currency_symbol }) {
    const url = 'programs/objectives/general/store/' + idprograms;
    const data = {
        'programs_objectives_general': objectiveGeneral,
        'currency_symbol': currency_symbol,
        'order_number': numObjectiveGeneral
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'POST', url: url });
}

function editObjetiveGeneral({ idobjectivegeneral }) {
    const url = 'programs/objectives/general/edit/' + idobjectivegeneral;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function updateObjetiveGeneral({ idObjectiveGeneral, objectiveGeneral, numObjectiveGeneral, moneda }) {
    console.log(idObjectiveGeneral, objectiveGeneral, numObjectiveGeneral);
    const url = 'programs/objectives/general/update/' + idObjectiveGeneral;
    const data = {
        'programs_objectives_general': objectiveGeneral,
        'order_number': numObjectiveGeneral,
        'currency_symbol': moneda
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

// eliminar objetivo general
function deleteObjetiveGeneral({ id_programs_objectives_general }) {
    const url = 'programs/objectives/general/delete/' + id_programs_objectives_general;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'DELETE', url: url });
}

// OBJETIVOS ESPECIFICOS

function getObjetiveSpecific({ idobjectivegeneral }) {
    const url = 'programs/objectives/specific/get/' + idobjectivegeneral;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function createObjetiveSpecific({ id_programs_objectives_general, programs_objectives_specific, goals, indicators, order_number }) {
    const url = 'programs/objectives/specific/store/' + id_programs_objectives_general;
    const data = {
        'programs_objectives_specific': programs_objectives_specific,
        'goals': goals,
        'indicators': indicators,
        'order_number': order_number
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'POST', url: url });
}

function updateObjetiveSpecific({ id_programs_objectives_specific, programs_objectives_specific, goals, indicators, order_number }) {
    const url = 'programs/objectives/specific/update/' + id_programs_objectives_specific;
    const data = {
        'programs_objectives_specific': programs_objectives_specific,
        'goals': goals,
        'indicators': indicators,
        'order_number': order_number
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}
// eliminar objetivo especifico
function deleteObjetiveSpecific({ id_programs_objectives_specific }) {
    const url = 'programs/objectives/specific/delete/' + id_programs_objectives_specific;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'DELETE', url: url });
}

// ACTIVIDADES
function getActivities({ idobjectivegeneral }) {
    const url = 'programs/objectives/activities/get/' + idobjectivegeneral;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function createActivities({ id_programs_objectives_general, programs_objectives_activities, order_number }) {
    const url = 'programs/objectives/activities/store/' + id_programs_objectives_general;
    const data = {
        'programs_objectives_activities': programs_objectives_activities,
        'order_number': order_number
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'POST', url: url });
}

function updateActivities({ id_programs_objectives_activities, programs_objectives_activities, order_number }) {
    const url = 'programs/objectives/activities/update/' + id_programs_objectives_activities;
    const data = {
        'programs_objectives_activities': programs_objectives_activities,
        'order_number': order_number
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

// eliminar objetivo ACTIVIDADES
function deleteObjetivesActivity({ id_programs_objectives_activity }) {
    const url = 'programs/objectives/activities/delete/' + id_programs_objectives_activity;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'DELETE', url: url });
}

// PROGRAMANDO
function getProgramming({ idobjectivegeneral }) {
    const url = 'programs/programming/get/' + idobjectivegeneral;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function createProgramming({ idobjectivegeneral, idSubject = null, idResponsible, resources, programming, budgets, columnsData }) {
    const url = 'programs/programming/store/' + idobjectivegeneral;
    const data = {
        'id_trainings_subjects': idSubject,
        'id_users_responsible': idResponsible,
        'resources': resources,
        'programs_objectives_programming': programming,
        'budgets': budgets,
        'columnsData': columnsData
    }
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'POST', url: url });
}

function updateProgramming({ programmingId, idResponsible, resources, programming, budgets, columnsData }) {
    const url = 'programs/programming/update/' + programmingId;
    const data = {
        'id_users_responsible': idResponsible,
        'resources': resources,
        'programs_objectives_programming': programming,
        'budgets': budgets,
        'columnsData': columnsData
    }
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}



function getProgrammingColumns({ idobjectivegeneral }) {
    const url = 'programs/programming/columns/get/' + idobjectivegeneral;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function createProgrammingColumns({ idobjectivegeneral, programmingColumn }) {
    const url = 'programs/programming/columns/store/' + idobjectivegeneral;
    const data = {
        'programs_objectives_programming_columns': programmingColumn
    }
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'POST', url: url });
}

function updateProgrammingColumns({ idProgrammingColumn, programmingColumn }) {
    const url = 'programs/programming/columns/update/' + idProgrammingColumn;
    const data = {
        'programs_objectives_programming_columns': programmingColumn
    }
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

function deleteProgrammingColumns({ idProgrammingColumn }) {
    const url = 'programs/programming/columns/delete/' + idProgrammingColumn;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'DELETE', url: url });
}



function getProgrammingColumnsData({ idobjectivegeneral }) {
    const url = 'programs/programming/columns/data/get/' + idobjectivegeneral;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}


function getProgrammingProgramming({ idobjectivegeneral }) {
    const url = 'programs/programming/programming/get/' + idobjectivegeneral;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function createProgrammingProgram({ idProgramsProgramming, month, week }) {
    const url = 'programs/programming/programming/program/' + idProgramsProgramming;
    const data = {
        'month': month,
        'week': week
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'POST', url: url });
}

function updateProgrammingReprogram({ idProgramsProgram, month, week }) {
    const url = 'programs/programming/programming/reprogram/' + idProgramsProgram;
    const data = {
        'month': month,
        'week': week
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

function updateProgrammingExecute({ idProgramsProgramChangeLog }) {
    const url = 'programs/programming/programming/execute/' + idProgramsProgramChangeLog;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'PUT', url: url });
}

function programsExtends({ idprograms }) {
    const url = 'programs/extends/get/' + idprograms;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

const MyPrograms = {
    // programas
    programs,
    createPrograms,
    updatePrograms,
    editPrograms,
    deletePrograms,
    updateStatusesPrograms,
    updateUnarchivedPrograms,
    updatearchivedPrograms,


    // objetivos egenerales
    getObjetiveGeneral,
    createObjetiveGeneral,
    editObjetiveGeneral,
    updateObjetiveGeneral,
    deleteObjetiveGeneral,

    // objetivos especificos
    getObjetiveSpecific,
    createObjetiveSpecific,
    updateObjetiveSpecific,
    deleteObjetiveSpecific,

    //  actividades
    getActivities,
    createActivities,
    updateActivities,
    deleteObjetivesActivity,

    //  programando
    getProgramming,
    createProgramming,
    updateProgramming,

    getProgrammingColumns,
    getProgrammingColumnsData,
    createProgrammingColumns,
    updateProgrammingColumns,
    deleteProgrammingColumns,
    // programacion
    getProgrammingProgramming,
    createProgrammingProgram,
    updateProgrammingReprogram,
    updateProgrammingExecute,

    programsExtends
}



export default MyPrograms;