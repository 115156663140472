import { React, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Button, Card, CardContent, CardMedia, Dialog, DialogActions, DialogContent, Grid, InputLabel, MenuItem, Modal, Paper, Rating, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { styled } from '@mui/system';
import { Add, DeleteForever, BorderColorOutlined, Search, Print, Edit as EditIcon, HistoryEduOutlined, Delete, DownloadForOffline, PictureAsPdf } from '@mui/icons-material';
import SideNav from '../../components/sidenav/sideNav';
import SubtitleText from '../../components/text/subtitleText';
import TitleText from '../../components/text/titleText';
import MyAts from '../../api/MyAts';
import MyUsers from '../../api/MyUsers';
import InputControlaVertical from '../../components/textfields/inputControlaVertical';
import CompanyLogoControla from '../../components/images/companyLogoControla';
import ButtonControla from '../../components/buttons/buttonController';
import SearchUsersControla from '../../components/search/searchUsersControla';
import DialogTitleControla from '../../components/dialog/dialogTitleControla';
import LoadingControla from '../../components/load/loadingControla';
import MyConfigurationApi from '../../api/MyConfigurationApi';
import ButtonIconControla from '../../components/buttons/buttonIconControla';
import SearchButtonControla from '../../components/search/searchButtonControla';
import SearchEppsControla from '../../components/search/searchEppsControla';
import DialogConfirmControla from '../../components/dialog/dialogConfirmControla';
import MyTrainings from '../../api/MyTrainings';
import MyCampus from '../../api/MyCampus';
import SelectControla from '../../components/selects/selectControla';

const grey = "#9191B0";
const blue = "#034AFF";
const lilac = "#D1D1E9";
const skyblue = "#2D9CDB";


const StyledTypography = styled(Typography)({
    color: "#305AD9",
    fontSize: 16,
    fontWeight: 600,
    gap: "2%",
    padding: "0.5em"
})

function Workers() {

    // Pantallas de carga y alertas INICIO
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState('');
    const [typeAlert, setTypeAlert] = useState('success');

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert('');
        setTypeAlert('success');
    }

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    }

    const handleCloseAlert = () => {
        setOpenAlert(false);
    }

    // Pantallas de carga y alertas FIN


    // Dialogos de confirmacion INICIO
    const [idAction, setIdAction] = useState('');

    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [titleText, setTitleText] = useState('');
    const [contentText, setContentText] = useState('');
    const [buttonTextConfirm, setButtonTextConfirm] = useState('');
    const [buttonActionType, setButtonActionType] = useState('');


    const handleOpenModalConfirm = (id, titleText, contentText, buttonTextConfirm, buttonActionType) => {
        setIdAction(id);
        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setButtonActionType(buttonActionType);
        setOpenModalConfirm(true);
    }

    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    }

    const handleActionConfirm = () => {
        switch (buttonActionType) {
            // case 'deleteWorkers':
            //     handleDeleteWorkers()
            //     break;
        }
        setOpenModalConfirm(false);
    }

    // Dialogos de confirmacion FIN

    const params = new URLSearchParams(window.location.search);
    const id_trainings = params.get('id_trainings');
    const [idUserLogged, setIdUserLogged] = useState(MyConfigurationApi.userData().id_users);
    //Modals
    const [openModalFirm, setOpenModalFirm] = useState(false);
    const [idWorker, setIdWorker] = useState("");

    const handleOpenModalFirm = (idWorker) => {
        setOpenModalFirm(true);
        setIdWorker(idWorker);
    };
    const handleCloseModalFirm = () => {
        setOpenModalFirm(false);
    };

    const [rowWorkers, setRowWorkers] = useState([]);
    const handleGetWorkers = async () => {
        const response = await MyTrainings.getWorkers({ id_trainings: id_trainings });
        setRowWorkers(response);
    }

    const [idStatus, setIdStatus] = useState("");
    const handleGetTraining = async () => {
        const response = await MyTrainings.getTrainings({ id_trainings: id_trainings });
        setIdStatus(response[0].id_trainings_statuses);
    }

    const [rating, setRating] = useState(null);

    useEffect(() => {
        handleGetTraining();
        handleGetWorkers();
    }, []);

    const handleFirm = async (event) => {
        event.preventDefault();
        if (rating > 0 && rating < 6) {
            const response = await MyTrainings.updateWorkersFirm({ id_trainings_workers: idWorker, qualification: rating });
            if (response.success === false) {
                handleOpenAlert('Hubo un error', 'error');
            } else {
                handleGetWorkers();
                handleCloseModalFirm();
                handleOpenAlert(response.msg, 'success');
            }

        } else {
            handleOpenAlert('Califica la capacitacion para poder firmar.', 'error');
        }


    }


    return (
        <>
            <Box sx={{ display: "flex", height: "100%" }}>

                <SideNav returnBack='/trainings/personal' />

                <LoadingControla openLoading={openLoading} openAlert={openAlert} handleCloseAlert={handleCloseAlert} messageAlert={messageAlert} typeAlert={typeAlert} />

                <DialogConfirmControla openModalConfirm={openModalConfirm} handleCloseModalConfirm={handleCloseModalConfirm} titleText={titleText} contentText={contentText} buttonTextConfirm={buttonTextConfirm} handleActionConfirm={handleActionConfirm} />

                <Container sx={{ paddingBottom: 4 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container rowSpacing={2} columnSpacing={2} alignItems="center">
                            <Grid item md={10}>
                                <SubtitleText text={"Capacitaciones"} color={grey}></SubtitleText>
                                <TitleText text={"Editar capacitación"} />
                            </Grid>
                            {/* <Grid item md={2}>

                            </Grid> */}
                            <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
                                <CompanyLogoControla />
                            </Grid>
                        </Grid>
                        <Stack
                            direction="row"
                            justifyContent={"space-between"}
                            alignItems="center"
                            sx={{ mt: .5, mb: 2 }}
                        >
                            <Tabs value={1} aria-label="Tipos" >
                                <Tab label="Detalle" href={"/trainings/personal/edit?id_trainings=" + id_trainings} />
                                {/* <Tab label="Trabajadores" href={"/trainings/personal/workers?id_trainings=" + id_trainings} /> */}
                                <Tab label="Evidencias" href={"/trainings/personal/evidences?id_trainings=" + id_trainings} />
                                <Tab label="Materiales" href={"/trainings/personal/materials?id_trainings=" + id_trainings} />
                                <Tab label="Examenes" href={"/trainings/personal/exams?id_trainings=" + id_trainings} />

                            </Tabs>
                        </Stack>
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Paper sx={{ overflow: "hidden" }}>
                                    <TableContainer>
                                        <Table
                                            stickyHeader
                                            sx={{ width: "100%", minWidth: "700px" }}
                                            aria-label="simple table"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    {/* <TableCell align="center"><IconButton><TaskAlt /></IconButton></TableCell> */}
                                                    <TableCell align="center" sx={{ color: blue }}>
                                                        Nombre y apellido
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ color: blue }}>
                                                        Documento de identidad
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ color: blue }}>
                                                        Asistencia
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ color: blue }}>
                                                        Firma
                                                    </TableCell>
                                                    {/* <TableCell align="center" sx={{ color: blue }}>
                                                        Nota
                                                    </TableCell> */}
                                                    <TableCell align="center" sx={{ color: blue }}>
                                                        Acciones
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rowWorkers.map((row, key) => (
                                                    <TableRow
                                                        hover
                                                        sx={{
                                                            "&:last-child td, &:last-child th": { border: 0 },
                                                        }}
                                                        key={key}
                                                    >
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            align="center"
                                                        >
                                                            {row.first_name + ' ' + row.last_name}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            align="center"
                                                        >
                                                            {row.document}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            align="center"
                                                        >
                                                            {(row.assistances === 1 ? 'Asistio' : 'No asistio')}
                                                        </TableCell>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            align="center"
                                                        >
                                                            {(row.firm === 1 ? 'Firmo' : 'No Firmo')}
                                                        </TableCell>
                                                        {/* <TableCell
                                                            component="th"
                                                            scope="row"
                                                            align="center"
                                                        >
                                                            {(row.qualification == null ? 'No dio examen' : row.qualification)}
                                                        </TableCell> */}
                                                        <TableCell align="right">
                                                            <Stack
                                                                direction="row"
                                                                justifyContent="end"
                                                                alignItems="center"
                                                            >
                                                                {idStatus == 2 && idUserLogged == row.id_users && row.firm !== 1 ?
                                                                    <ButtonIconControla
                                                                        icon={
                                                                            <HistoryEduOutlined sx={{ color: "white" }} />
                                                                        }
                                                                        backgroundColor={"#F1C40F"}
                                                                        backgroundColorHover={"#F4D03F"}
                                                                        textTooltip={"Firmar"}
                                                                        functionOnClick={() => { handleOpenModalFirm(row.id_trainings_workers); }}
                                                                    />
                                                                    : ''}
                                                            </Stack>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>

                <Dialog
                    open={openModalFirm}
                    onClose={handleCloseModalFirm}
                    maxWidth="md"
                    fullWidth={true}
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                >
                    <DialogTitleControla titleText='Firmar capacitación' functionOnClose={handleCloseModalFirm} />
                    <form onSubmit={handleFirm}>
                        <DialogContent >
                            <Typography color={blue} fontSize={17}
                                sx={{
                                    userSelect: 'none'
                                }}>
                                Recuerda que con tu firma estas marcando tu asistencia a esta capacitación, tambien recuerda que para firmar tienes que haber completado los examenes.
                            </Typography>
                            <InputLabel
                                sx={{
                                    paddingTop: '8px',
                                    fontSize: '18px',
                                    color: '#305AD9',
                                    fontWeight: 'bold'
                                }}> Califica esta capacitación (donde 1 es muy mala y 5 es muy buena) </InputLabel>
                            <Rating value={rating} size="large"
                                onChange={(event, newValue) => {
                                    setRating(newValue);
                                }}
                                required
                            />

                        </DialogContent>
                        <DialogActions>
                            <Stack direction={'row'} spacing={2}>
                                <Box>
                                    <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cancelar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={handleCloseModalFirm} />
                                </Box>
                                <Box>
                                    <ButtonControla iconButton={<HistoryEduOutlined />} backgroundColor={'#169073'} textButton={'Firmar y marcar asistencia'} backgroundColorHover={'#1BAA88'} typeButton={'submit'} />
                                </Box>
                            </Stack>
                        </DialogActions>
                    </form>
                </Dialog>

            </Box >
        </>
    )
}

export default Workers