import {
    Backdrop,
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    MenuItem,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { React, useState, useEffect } from "react";
import SideNav from "../../components/sidenav/sideNav";
import BannerControla from "../../components/cards/bannerControla";
import { Add, BorderColorOutlined, Delete, FilterAlt } from "@mui/icons-material";
import ButtonControla from "../../components/buttons/buttonController";
import ButtonIconControla from "../../components/buttons/buttonIconControla";
import DialogTitleControla from "../../components/dialog/dialogTitleControla";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import MyAreas from "../../api/MyAreas";
import DialogConfirmControla from "../../components/dialog/dialogConfirmControla";
import MyTrainings from "../../api/MyTrainings";
import SelectControla from "../../components/selects/selectControla";
import MyUsers from "../../api/MyUsers";
import MyCampus from "../../api/MyCampus";

//colors
const purple = "#8B8BD8";
const skyblue = "#2D9CDB";
const blue = "#034AFF";

function Index() {
    const params = new URLSearchParams(window.location.search);

    //BackDrop
    const [openBackdrop, setOpenBackdrop] = useState(false);

    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
    };

    const [idSubject, setIdSubject] = useState("");
    const [name, setName] = useState("");


    //Modals
    const [openModalCreate, setOpenModalCreate] = useState(false);

    const handleOpenModalCreate = () => {
        setOpenModalCreate(true);
    };
    const handleCloseModalCreate = () => {
        setOpenModalCreate(false);
    };

    //Filters
    const [openModalFilters, setOpenModalFilters] = useState(false);

    const handleOpenModalFilters = () => {
        setOpenModalFilters(true);
    };
    const handleCloseModalFilters = () => {
        setOpenModalFilters(false);
    };

    // alerts
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [typeAlert, setTypeAlert] = useState("success");

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert("");
        setTypeAlert("success");
    };

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };
    // Dialogos de confirmacion INICIO

    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [titleText, setTitleText] = useState("");
    const [contentText, setContentText] = useState("");
    const [buttonTextConfirm, setButtonTextConfirm] = useState("");
    const [buttonActionType, setButtonActionType] = useState("");

    const handleOpenModalConfirm = (
        id,
        titleText,
        contentText,
        buttonTextConfirm,
        buttonActionType
    ) => {
        setIdSubject(id);
        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setButtonActionType(buttonActionType);
        setOpenModalConfirm(true);
    };

    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    };

    const handleActionConfirm = () => {
        switch (buttonActionType) {
            default:
                break;
        }
        setOpenModalConfirm(false);
    };

    const [subjectFilter, setSubjectFilter] = useState(params.get("id_trainings_subjects"));
    const [typeFilter, setTypeFilter] = useState(params.get("id_trainings_types"));
    const [modalityFilter, setModalityFilter] = useState(params.get("id_trainings_modalities"));
    const [statusFilter, setStatusFilter] = useState(params.get("id_trainings_statuses"));
    const [yearFilter, setYearFilter] = useState(params.get("year"));
    // const [campusFilter, setCampusFilter] = useState(params.get("id_campus"));
    const [responsibleFilter, setResponsibleFilter] = useState(params.get("id_users_responsible"));
    // const [startFilter, setStartFilter] = useState(params.get("timestamp_start"));
    // const [endFilter, setEndFilter] = useState(params.get("timestamp_end"));


    const [rowsTypes, setRowsTypes] = useState([]);
    const handleGetTypes = async () => {
        const response = await MyTrainings.getTypes();
        setRowsTypes(response);
    };

    const [rowSubjects, setRowSubjects] = useState([]);
    const handleGetSubjects = async () => {
        const response = await MyTrainings.getSubjects();
        setRowSubjects(response);
    };

    const [rowStatuses, setRowStatuses] = useState([]);
    const handleGetStatuses = async () => {
        const response = await MyTrainings.getStatuses();
        setRowStatuses(response);
    };

    const [rowModalities, setRowModalities] = useState([]);
    const handleGetModalities = async () => {
        const response = await MyTrainings.getModalities();
        setRowModalities(response);
    };

    const [rowResponsible, setRowResponsible] = useState([]);
    const handleGetResponsible = async () => {
        const response = await MyUsers.usersTrust();
        setRowResponsible(response);
    };

    const [rowCampus, setRowCampus] = useState([]);
    const handleGetCampus = async () => {
        const response = await MyCampus.campus();
        setRowCampus(response);
    };

    const [rowYears, setRowYears] = useState([]);
    const handleGetYears = async () => {
        const today = new Date();
        const year = today.getFullYear();
        const margin = year - 2021;
        let years = [];
        for (let i = 0; i < margin; i++) {
            years.push(year - i);
        }
        years.push(year + 1);
        const response = years.sort();
        setRowYears(response);
    };

    //CRUD functions
    const [rowTrainings, setRowTrainings] = useState([]);

    const handleGetTrainings = async () => {
        const response = await MyTrainings.getTrainings({ id_trainings_subjects: subjectFilter, id_trainings_types: typeFilter, id_trainings_statuses: statusFilter, id_trainings_modalities: modalityFilter, id_users_responsible: responsibleFilter, id_campus: '', timestamp_start: '', timestamp_end: '', year: yearFilter });
        setRowTrainings(response);
    };

    const handleCreateTrainings = async (event) => {
        event.preventDefault();
        handleOpenLoading();
        const response = await MyTrainings.createTrainings({ id_trainings_subjects: idSubject, name: name });
        if (response.success === true) {
            handleCloseModalCreate();
            handleGetTrainings();
            handleOpenAlert(response.msg, 'success');
            window.location.href = '/trainings/all/edit?id_trainings=' + response.id_trainings;
        } else {
            alert('Ocurrio un error');
            handleOpenAlert(response.msg, 'error');
        }

    };

    //UseEffect
    useEffect(() => {
        handleGetSubjects();
        handleGetTypes();
        handleGetStatuses();
        handleGetModalities();
        handleGetResponsible();
        handleGetCampus();
        handleGetYears();
        handleGetTrainings();
    }, []);

    return (
        <Box sx={{ display: "flex", height: "100%" }}>
            <SideNav returnBack="/trainings" />
            <DialogConfirmControla
                openModalConfirm={openModalConfirm}
                handleCloseModalConfirm={handleCloseModalConfirm}
                titleText={titleText}
                contentText={contentText}
                buttonTextConfirm={buttonTextConfirm}
                handleActionConfirm={handleActionConfirm}
            />
            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container>
                        <Grid item xs={6} md={6}>
                            {/* <SubtitleText
                              text={"Areas"}
                              color={colorTitle}
                              ></SubtitleText> */}
                        </Grid>
                        <Grid item xd={6} md={6} style={{ textAlign: "right" }}></Grid>
                        <Grid item xs={12} md={12} sx={{ mt: 2 }}>
                            <BannerControla
                                image="/assets/images/Trainings.png"
                                color2="#2F67BC"
                                color1="#8FBCFF"
                                text="Todas las capacitaciones"
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        sx={{ mt: .5 }}
                        direction="row"
                        alignItems="center"
                    >
                        <Grid item>
                            <ButtonControla
                                roles={[1, 7, 8]}
                                iconButton={<Add sx={{ color: "white" }} />}
                                backgroundColor={"#169073"}
                                backgroundColorHover={"#1BAA88"}
                                textButton={"Crear capacitación"}
                                functionOnClick={() => handleOpenModalCreate()}
                            />
                        </Grid>

                        <Grid item>
                            <ButtonControla
                                roles={[1, 7, 8]}
                                iconButton={<FilterAlt sx={{ color: "white" }} />}
                                backgroundColor={"#169073"}
                                backgroundColorHover={"#1BAA88"}
                                textButton={"Buscar capacitación"}
                                functionOnClick={() => handleOpenModalFilters()}
                            />
                        </Grid>

                    </Grid>
                    <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Paper sx={{ overflow: "hidden" }}>
                                <TableContainer>
                                    <Table
                                        stickyHeader
                                        sx={{ width: "100%", minWidth: "700px" }}
                                        aria-label="simple table"
                                    >
                                        <TableHead>
                                            <TableRow sx={{ display: rowTrainings.length == 0 ? 'none' : '' }}>
                                                {/* <TableCell align="center"><IconButton><TaskAlt /></IconButton></TableCell> */}
                                                <TableCell align="center" sx={{ color: blue }}>
                                                    Tema
                                                </TableCell>
                                                <TableCell align="center" sx={{ color: blue }}>
                                                    Nombre
                                                </TableCell>
                                                <TableCell align="center" sx={{ color: blue }}>
                                                    Estado
                                                </TableCell>
                                                <TableCell align="center" sx={{ color: blue }}>
                                                    Fecha de inicio
                                                </TableCell>
                                                <TableCell align="center" sx={{ color: blue }}>
                                                    Fecha de fin
                                                </TableCell>
                                                <TableCell align="center" sx={{ color: blue }}>
                                                    Acciones
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rowTrainings.map((row, key) => (
                                                <TableRow
                                                    hover
                                                    sx={{
                                                        "&:last-child td, &:last-child th": { border: 0 },
                                                    }}
                                                    key={key}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {row.subject}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {row.status}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {(row.timestamp_start != null) ? row.timestamp_start.substr(0, 19) : ''}
                                                    </TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                    >
                                                        {(row.timestamp_end != null) ? row.timestamp_end.substr(0, 19) : ''}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="end"
                                                            alignItems="center"
                                                        >
                                                            <ButtonIconControla
                                                                roles={[1, 7, 8]}
                                                                icon={
                                                                    <BorderColorOutlined
                                                                        sx={{ color: "white" }}
                                                                    />
                                                                }
                                                                backgroundColor={"#2D9CDB"}
                                                                backgroundColorHover={"#33AEF4"}
                                                                textTooltip={"Editar"}
                                                                directionUrl={"/trainings/all/edit?id_trainings=" + row.id_trainings}
                                                            />
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </Container>

            <Dialog
                open={openModalCreate}
                onClose={() => handleCloseModalCreate()}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText="Agregar nueva Capacitación"
                    functionOnClose={() => handleCloseModalCreate()}
                />
                <form onSubmit={handleCreateTrainings}>
                    <DialogContent sx={{ paddingTop: "0" }}>

                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <SelectControla text={'Tema de capacitación:'}
                                    inputValue={setIdSubject} modalType={true} childrenRows={
                                        rowSubjects.map((row) => (
                                            <MenuItem key={row.id_trainings_subjects}
                                                value={row.id_trainings_subjects}
                                            >
                                                {row.subject}
                                            </MenuItem>
                                        ))
                                    } required
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputControlaVertical
                                    text={"Nombre"}
                                    inputType={"text"}
                                    inputValue={setName}
                                    modalType={true}
                                    required
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            // color="success"
                            sx={{
                                textTransform: "none",
                                color: "#6969D6",
                                backgroundColor: "#CBCBFF",
                                borderRadius: "16px",
                                marginRight: "10px",
                            }}
                            onClick={() => handleCloseModalCreate()}
                        >
                            Cancelar
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="success"
                            sx={{
                                textTransform: "none",
                                // backgroundColor: '#169073',
                                borderRadius: "16px",
                                marginRight: "10px",
                            }}
                        >
                            Crear
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog
                open={openModalFilters}
                onClose={() => handleCloseModalFilters()}
                maxWidth="md"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText="Filtros de búsqueda"
                    functionOnClose={() => handleCloseModalFilters()}
                />
                <form method="GET">
                    <DialogContent sx={{ paddingTop: "0" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <SelectControla text={'Tema de capacitación:'} name="id_trainings_subjects"
                                    inputValue={setSubjectFilter} modalType={true} childrenRows={
                                        rowSubjects.map((row) => (
                                            <MenuItem key={row.id_trainings_subjects}
                                                value={row.id_trainings_subjects}
                                            >
                                                {row.subject}
                                            </MenuItem>
                                        ))
                                    } value={subjectFilter}
                                    optionNull={1}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <SelectControla text={'Tipo de capacitación:'} name="id_trainings_types"
                                    inputValue={setTypeFilter} modalType={true} childrenRows={
                                        rowsTypes.map((row) => (
                                            <MenuItem key={row.id_trainings_types}
                                                value={row.id_trainings_types}
                                            >
                                                {row.type}
                                            </MenuItem>
                                        ))
                                    } value={typeFilter}
                                    optionNull={1}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <SelectControla text={'Estado de capacitación:'} name="id_trainings_statuses"
                                    inputValue={setStatusFilter} modalType={true} childrenRows={
                                        rowStatuses.map((row) => (
                                            <MenuItem key={row.id_trainings_statuses}
                                                value={row.id_trainings_statuses}
                                            >
                                                {row.status}
                                            </MenuItem>
                                        ))
                                    } value={statusFilter}
                                    optionNull={1}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <SelectControla text={'Modalidad de capacitación:'} name="id_trainings_modalities"
                                    inputValue={setModalityFilter} modalType={true} childrenRows={
                                        rowModalities.map((row) => (
                                            <MenuItem key={row.id_trainings_modalities}
                                                value={row.id_trainings_modalities}
                                            >
                                                {row.modality}
                                            </MenuItem>
                                        ))
                                    } value={modalityFilter}
                                    optionNull={1}
                                />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <SelectControla text={'Responsable de capacitación:'} name="id_users_responsible"
                                    inputValue={setResponsibleFilter} modalType={true} childrenRows={
                                        rowResponsible.map((row) => (
                                            <MenuItem key={row.id_users}
                                                value={row.id_users}
                                            >
                                                {row.first_name + ' ' + row.last_name}
                                            </MenuItem>
                                        ))
                                    } value={responsibleFilter}
                                    optionNull={1}
                                />
                            </Grid>

                            {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <SelectControla text={'Sede de capacitación:'} name="id_campus"
                                    inputValue={setCampusFilter} modalType={true} childrenRows={
                                        rowCampus.map((row) => (
                                            <MenuItem key={row.id_campus}
                                                value={row.id_campus}
                                            >
                                                {row.name}
                                            </MenuItem>
                                        ))
                                    } value={campusFilter}
                                />
                            </Grid> */}

                            {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <InputControlaVertical text={'Fecha y hora inicio de capacitación:'} inputType={'datetime-local'} inputValue={setStartFilter} value={startFilter} modalType={true} name="timestamp_start" />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <InputControlaVertical text={'Fecha y hora fin de capacitación:'} inputType={'datetime-local'} inputValue={setEndFilter} value={endFilter} modalType={true} name="timestamp_end" />
                            </Grid> */}

                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <SelectControla text={'Año de capacitación:'} name="year"
                                    inputValue={setYearFilter} modalType={true} childrenRows={
                                        rowYears.map((row) => (
                                            <MenuItem key={row}
                                                value={row}
                                            >
                                                {row}
                                            </MenuItem>
                                        ))
                                    } value={yearFilter}
                                    optionNull={1}
                                />
                            </Grid>

                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="contained"
                            // color="success"
                            sx={{
                                textTransform: "none",
                                color: "#6969D6",
                                backgroundColor: "#CBCBFF",
                                borderRadius: "16px",
                                marginRight: "10px",
                            }}
                            onClick={() => handleCloseModalFilters()}
                        >
                            Cancelar
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            color="success"
                            sx={{
                                textTransform: "none",
                                // backgroundColor: '#169073',
                                borderRadius: "16px",
                                marginRight: "10px",
                            }}
                        >
                            Buscar
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>


            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openBackdrop}
            >
                {/* <CircularProgress color="inherit" /> */}
                <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
            </Backdrop>
        </Box>
    );
}

export default Index;
