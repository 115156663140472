import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function getRegisterInspections() {
  const url = "inspections";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function createRegisterInspections({ code }) {
  const url = "inspections";
  const data = { code: code, status: "Creado" };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}
async function updateRegisterInspection({
  idRegister,
  code,
  area,
  areaResponsible,
  conclusionsRecomendations,
  date,
  description,
  inspectionResponsible,
  objective,
  results,
  type,
  document1,
  typeDetail,
  register_status,
}) {
  const current = new Date();
  const year = current.getFullYear();
  const month = current.getMonth() + 1;
  const day = current.getDate();

  if (document1 !== null && document1 !== undefined) {
    document1 = await uploadFileCompanies(
      document1,
      "documentation/registers/generals/inspections/" +
        year +
        "/" +
        month +
        "/" +
        day +
        "/"
    );
  }

  const url = "inspection/" + idRegister;

  const data = {
    code: code,
    area: area,
    area_responsible: areaResponsible,
    conclusions_recomendations: conclusionsRecomendations,
    date: date,
    description: description,
    inspection_responsible: inspectionResponsible,
    objective: objective,
    results: results,
    type: type,
    type_detail: typeDetail,
    document_1: document1,
    register_status: register_status,
  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function editRegisterInspection({ idRegister }) {
  const url = "inspection/" + idRegister;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

// RESPONSABLES

//obtiene responsables
function getResponsible({ idRegister }) {
  const url = idRegister + "/responsible";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}
//agrega responsables
function addResponsible({ idUser, idRegister }) {
  const url = idRegister + "/responsible";
  const data = {
    id_users: idUser,
  };
  console.log(data);
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

//elimina responsables
function deleteResponsible({ idUser }) {
  const url = "responsible/detail/" + idUser;
  const data = {
    id_users: idUser,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "DELETE",
    url: url,
  });
}

//firma responsables
function signResponsible({ idUserId, idUser }) {
  console.log(idUserId);
  const url = "responsible/detail/" + idUser;
  const data = {
    id_users: idUserId,
    firm: 1,
    firm_date: null,
  };
  console.log(idUserId);
  console.log(data);
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}
// RESPONSABLES

function closeRegisterInspections({ idRegister }) {
  const url = idRegister + "/status/end";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "PUT",
    url: url,
  });
}

///RESPONSIBLE AREAS

function getResponsibleAreas({ idRegister }) {
  const url = "sec/responsible_areas/" + idRegister ;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function addResponsibleAreas({ idUser, idRegister }) {
  const url = "sec/responsible_areas/" + idRegister;
  const data = {
    id_users: idUser,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

//elimina responsables
function deleteResponsibleAreas({ idUser }) {
  console.log(idUser);
  const url = "sec/responsible_areas/detail/" + idUser;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "DELETE",
    url: url,
  });
}

const MyRegistersInspections = {
  getRegisterInspections,
  createRegisterInspections,
  editRegisterInspection,
  updateRegisterInspection,
  getResponsible,
  addResponsible,
  deleteResponsible,
  signResponsible,
  closeRegisterInspections,
  addResponsibleAreas,
  deleteResponsibleAreas,
  getResponsibleAreas
};

export default MyRegistersInspections;
