import React from "react";

function DocCard({ size, color1, color2, text, img }) {
  
  const small = {
    display: 'flex',
    flexDirection: 'column',
    height: '265px',
    width: '100%',
  };

  const medium = {
    display: 'flex',
    flexDirection: 'column',
    height: '265px',
    width: '100%',
  };

  const normal = {
    display: 'flex',
    flexDirection: 'column',
    height: '265px',
    width: '100%',
  };

  const colorBanner = {
    //backgroundColor : 'blue',
    width: '100%',
    height: '100%',
    borderTopLeftRadius : '16px',
    borderTopRightRadius : '16px',
    background: `linear-gradient(45deg, ${color1}, ${color2})`,
  };

  const imageBanner ={
    width: '100%',
    height: small?'80%': '100%',
    borderTopLeftRadius : '16px',
    borderTopRightRadius : '16px',
    backgroundImage: '/assets/images/docimagebanner.png',
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat',
  }
  
  const cardText = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FCFCFC',
    height: '70%',
    borderBottomLeftRadius: '16px',    
    borderBottomRightRadius: '16px',
    color: '#395AC8',
    fontSize: 20
  };

  switch (size){
    case 'small':
      size = small;
      break;
    case 'medium':
      size = medium;
      break;
    case 'normal':
      size = normal;
      break;
    default:
      console.log('Se rompió este componente')
  }
  return (
    <div style={size}>
      {img
        ? <div
          //style={colorBanner}
        >
          <img src={img} style={{ width: "100%"}} alt="banner"/>
        </div>
        : <div
          style={img? imageBanner : colorBanner}
        >
          <img src={img} style={{ width: "100%"}} alt="banner" />
        </div>
      }

      <div style={cardText}>
        {text}
      </div>
    </div>
  );
}

export default DocCard;
