import {
  Backdrop,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { React, useState, useEffect } from "react";
import SideNav from "../components/sidenav/sideNav";
import BannerControla from "../components/cards/bannerControla";
import { Add, BorderColorOutlined, Delete } from "@mui/icons-material";
import ButtonControla from "../components/buttons/buttonController";
import ButtonIconControla from "../components/buttons/buttonIconControla";
import DialogTitleControla from "../components/dialog/dialogTitleControla";
import InputControlaVertical from "../components/textfields/inputControlaVertical";
import MyAreas from "../api/MyAreas";
import MyOccupationalPositions from "../api/MyOccupationalPositions";
import SelectControla from "../components/selects/selectControla";
import DialogConfirmControla from "../components/dialog/dialogConfirmControla";

//colors
const purple = "#8B8BD8";
const skyblue = "#2D9CDB";
const blue = "#034AFF";

function Index() {
  //BackDrop
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };
  //Variables
  const [rowPositions, setRowPositions] = useState([]);

  const [idPosition, setIdPosition] = useState("");
  const [positionName, setPositionName] = useState("");

  const [areas, setAreas] = useState([]);
  const [positionArea, setPositionArea] = useState(0);
  //Modals
  const [openModalCreate, setOpenModalCreate] = useState(false);

  const handleOpenModalCreate = () => {
    setOpenModalCreate(true);
    setPositionName("");
    setPositionArea(0);
    handleGetAreas();
  };
  const handleCloseModalCreate = () => {
    setOpenModalCreate(false);
  };

  const [openModalEdit, setOpenModalEdit] = useState(false);

  const handleOpenModalEdit = (idPosition, positionName, positionArea) => {
    setIdPosition(idPosition);
    setPositionName(positionName);
    setPositionArea(positionArea);
    setOpenModalEdit(true);
    handleGetAreas();
  };
  const handleCloseModalEdit = () => {
    setOpenModalEdit(false);
  };
  // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  // Dialogos de confirmacion INICIO

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setIdPosition(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    switch (buttonActionType) {
      case "delete":
        handleDeletePositions(idPosition);
        break;
    }
    setOpenModalConfirm(false);
  };

  //CRUD functions
  const handleGetPositions = async () => {
    const response = await MyOccupationalPositions.getPositions();
    setRowPositions(response);
    return response;
  };

  const handleCreatePositions = async () => {
    setOpenBackdrop(!openBackdrop);
    const response = await MyOccupationalPositions.createPositions({
      position: positionName,
      idArea: positionArea,
    });
    handleCloseBackdrop();
    handleCloseModalCreate();
    handleGetPositions();
    return response;
  };

  const handleUpdatePositions = async () => {
    setOpenBackdrop(!openBackdrop);
    console.log(positionArea, positionName);
    const response = await MyOccupationalPositions.updatePositions({
      idPosition: idPosition,
      name: positionName,
      idArea: positionArea,
    });
    handleCloseBackdrop();
    handleCloseModalEdit();
    handleGetPositions();
    return response;
  };

  const handleDeletePositions = async (idPosition) => {
    setOpenBackdrop(!openBackdrop);
    const response = await MyOccupationalPositions.deletePositions({
      idPosition: idPosition,
    });
    handleCloseBackdrop();
    handleGetPositions();
    return response;
  };

  //Get Areas for dropdown
  const handleGetAreas = async () => {
    const response = await MyAreas.getAreas();
    setAreas(response);
    return response;
  };

  //UseEffect
  useEffect(() => {
    handleGetPositions();
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav />
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={6} md={6}>
              {/* <SubtitleText
                              text={"Areas"}
                              color={colorTitle}
                              ></SubtitleText> */}
            </Grid>
            <Grid item xd={6} md={6} style={{ textAlign: "right" }}></Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>
              <BannerControla
                image=""
                color2="#2F67BC"
                color1="#8FBCFF"
                text="Cargos Ocupacionales"
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{ mt: 2 }}
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Grid item>
              <ButtonControla
                roles={[1, 7, 8]}
                iconButton={<Add sx={{ color: "white" }} />}
                backgroundColor={"#169073"}
                backgroundColorHover={"#1BAA88"}
                textButton={"Agregar Cargo"}
                functionOnClick={() => handleOpenModalCreate()}
              />
            </Grid>
          </Grid>
          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Paper sx={{ overflow: "hidden" }}>
                <TableContainer>
                  <Table
                    stickyHeader
                    sx={{ width: "100%", minWidth: "700px" }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        {/* <TableCell align="center"><IconButton><TaskAlt /></IconButton></TableCell> */}
                        <TableCell align="center" sx={{ color: blue }}>
                          Nombre
                        </TableCell>
                        <TableCell align="center" sx={{ color: blue }}>
                          Areas
                        </TableCell>
                        <TableCell align="center" sx={{ color: blue }}>
                          Acciones
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowPositions.map((positions, key) => (
                        <TableRow
                          hover
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          key={key}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{ color: purple }}
                            align="center"
                          >
                            {positions.name}
                          </TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{ color: purple }}
                            align="center"
                          >
                            {positions.area}
                          </TableCell>

                          <TableCell align="right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                            >
                              <ButtonIconControla
                                roles={[1, 7, 8]}
                                icon={
                                  <BorderColorOutlined
                                    sx={{ color: "white" }}
                                  />
                                }
                                backgroundColor={"#2D9CDB"}
                                backgroundColorHover={"#33AEF4"}
                                textTooltip={"Editar"}
                                functionOnClick={() =>
                                  handleOpenModalEdit(
                                    positions.id_positions,
                                    positions.name,
                                    positions.id_areas
                                  )
                                }
                              />
                              <ButtonIconControla
                                roles={[1, 7, 8]}
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    positions.id_positions,
                                    "¿Esta seguro de eliminar esta área?",
                                    'Una vez eliminada no se podrá recuperar. Se eliminará:  "' +
                                      positions.name +
                                      '"',
                                    "Si, Eliminar",
                                    "delete"
                                  );
                                }}
                              />
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Dialog
        open={openModalCreate}
        onClose={() => handleCloseModalCreate()}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitleControla
          titleText="Agregar nuevo Cargo"
          functionOnClose={() => handleCloseModalCreate()}
        />
        {/* <form onSubmit={() => handleCreateAreas()}> */}
        <DialogContent sx={{ paddingTop: "0" }}>
          <InputControlaVertical
            text={"Nombre"}
            inputType={"text"}
            inputValue={setPositionName}
            value={positionName}
            modalType={true}
            required
          />
          <SelectControla
            text="Areas:"
            inputValue={setPositionArea}
            modalType={false}
            childrenRows={areas.map((area) => (
              <MenuItem key={area.id_areas} value={area.id_areas}>
                {area.area}
              </MenuItem>
            ))}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            // color="success"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => handleCloseModalCreate()}
          >
            Cancelar
          </Button>
          <Button
            //   type="submit"
            variant="contained"
            color="success"
            sx={{
              textTransform: "none",
              // backgroundColor: '#169073',
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => handleCreatePositions()}
          >
            Crear
          </Button>
        </DialogActions>
        {/* </form> */}
      </Dialog>

      <Dialog
        open={openModalEdit}
        onClose={() => handleCloseModalEdit()}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitleControla
          titleText="Editar Área"
          functionOnClose={() => handleCloseModalEdit()}
        />
        <DialogContent sx={{ paddingTop: "0" }}>
          <InputControlaVertical
            text={"Nombre"}
            inputType={"text"}
            inputValue={setPositionName}
            value={positionName}
            modalType={true}
            required
          />
          <SelectControla
            text="Areas:"
            inputValue={setPositionArea}
            modalType={true}
            value={positionArea}
            childrenRows={areas.map((area) => (
              <MenuItem key={area.id_areas} value={area.id_areas}>
                {area.area}
              </MenuItem>
            ))}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            // color="success"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => handleCloseModalEdit()}
          >
            Cancelar
          </Button>
          <Button
            //   type="submit"
            variant="contained"
            color="success"
            sx={{
              textTransform: "none",
              // backgroundColor: '#169073',
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => handleUpdatePositions()}
          >
            Editar
          </Button>
        </DialogActions>
        {/* </form> */}
      </Dialog>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        {/* <CircularProgress color="inherit" /> */}
        <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
      </Backdrop>
    </Box>
  );
}

export default Index;
