import React from 'react';
import Box from '@mui/material/Box';
import { Grid, Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import SubtitleText from '../../../components/text/subtitleText';
import Container from '@mui/material/Container';
import SideNav from "../../../components/sidenav/sideNav";
import DocBanner from '../../../components/cards/docBanner';
import TitleText from '../../../components/text/titleText';
import InputControla from '../../../components/textfields/inputControla';
import LabelControla from '../../../components/textfields/labelControla';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';

const colorTitle = "#9191B0";
const green = "#2C8C75";
const lilac = "#D1D1E9";

function createData(order, name) {
    return { order, name };
}

const rows = [
    createData('1','Lorem ipsum'),
    createData('2','Lorem ipsum')
];



function Sso() {

    const StyledSaveButton = styled(Button)({
        textTransform: 'none',
        borderRadius: 14,
        backgroundColor: green,
        color: 'white',
        width: "100%"
    });

    const StyledCancelButton = styled(Button)({
        textTransform: 'none',
        borderRadius: 14,
        backgroundColor: lilac,
        color: 'black',
        width: "100%"
    });

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (

        <Box sx={{ display: "flex", height: '100%' }}>
            <SideNav />

            <Container>

                <Box sx={{ flexGrow: 1 }}>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <SubtitleText text={"Documentación"} color={colorTitle} ></SubtitleText>
                            <DocBanner color2='#0F1255' color1='#2A30B9' text='Objetivos generales' image={""} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ mt: 2 }} justify="flex-end">
                        <Grid item xs={4} md={4}>
                            <Button
                                variant="contained"
                                sx={{
                                    textTransform: 'none',
                                    backgroundColor: '#169073',
                                    borderRadius: '16px'
                                }}
                                onClick={handleClickOpen}
                                startIcon={<Add />}
                            >Crear objetivo general
                            </Button>
                        </Grid>
                        <Grid item xs={4} md={4}>
                        </Grid>
                        <Grid item xs={4} md={4}>
                            <Grid container spacing={2}>
                                <Grid item xs>
                                    <LabelControla text="Periodo"></LabelControla>
                                </Grid>
                                <Grid item xs>
                                    <InputControla inputType={"date"} />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ mt: 2 }}>
                        <Grid item xs={12} md={12}>
                            <Grid item md={12}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Orden</TableCell>
                                                <TableCell>Objetivos generales</TableCell>
                                                <TableCell align="right">*</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rows.map((row) => (
                                                <TableRow
                                                    key={row.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {row.order}
                                                    </TableCell>
                                                    <TableCell component="th" scope="row">
                                                        {row.name}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button href={"/documentation/programs/sso/newgoals"}
                                                            variant="contained"
                                                            sx={{
                                                                textTransform: 'none',
                                                                backgroundColor: 'transparent',
                                                                borderRadius: '16px',
                                                                color: '#305AD9',
                                                                mr: 2
                                                            }}
                                                            startIcon={<Add />}
                                                        >Editar
                                                        </Button>
                                                        <Button href={"/documentation/programs/sso/newgoals"}
                                                            variant="contained"
                                                            sx={{
                                                                textTransform: 'none',
                                                                backgroundColor: '#2D9CDB',
                                                                borderRadius: '16px'
                                                            }}
                                                            startIcon={<Add />}
                                                        >Entrar
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Container>

            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth={true}
                PaperProps={{
                    style: {
                        backgroundColor: "#F5F5F5",
                        width: "55%",
                    },
                }}
            >
                <DialogTitle> <TitleText text={"Agregar nuevo objetivo general"}></TitleText><IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton></DialogTitle>
                <DialogContent>
                    
                    <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                        <Grid item xs={3} md={3}>
                            <LabelControla text="Objetivo"></LabelControla>
                        </Grid>
                        <Grid item xs={9} md={9}>
                            <InputControla inputType={"text"} />
                        </Grid>

                        <Grid item xs={3} md={3}>
                            <LabelControla text="Número de objetivo"></LabelControla>
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <InputControla inputType={"text"} />
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <LabelControla text="Moneda"></LabelControla>
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <InputControla inputType={"text"} />
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="center" sx={{ mt: 3 }}>
                        <Grid item md={2} sx={{ mr: 3 }}>
                            <StyledCancelButton>Cancelar</StyledCancelButton>
                        </Grid>
                        <Grid item md={2} >
                            <StyledSaveButton>Agregar</StyledSaveButton>
                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog>


        </Box>




    );

}

export default Sso;

