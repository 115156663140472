import { Close } from "@mui/icons-material";
import { DialogTitle, IconButton, Typography } from "@mui/material";
import { } from "@mui/system";
import { React } from "react";

function DialogTitleControla({ titleText = '', functionOnClose = '' }) {

    return (
        <DialogTitle sx={{ paddingBottom: '0' }}>
            <Typography
                sx={{
                    fontSize: '24px',
                    color: "#1638F2",
                    fontWeight: "bold",
                }}
            >
                {titleText}
            </Typography>
            <IconButton
                aria-label="close"
                onClick={functionOnClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <Close />
            </IconButton>
        </DialogTitle>
    );
}

export default DialogTitleControla;