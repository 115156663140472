import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function getAnnualPlan() {
  const url = "annual_plan";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function createAnnualPlan({ develop, title, date, document_url }) {
  const current = new Date();
  const year = current.getFullYear();
  const month = current.getMonth() + 1;
  const day = current.getDate();

  if (document_url !== null && document_url !== undefined) {
    document_url = await uploadFileCompanies(
      document_url,
      "documentation/annualplan/" + year + "/" + month + "/" + day + "/"
    );
  }
  const url = "annual_plan";
  const data = {
    develop: develop,
    title: title,
    date: date,
    document_url: document_url,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function editAnnualPlan({ idAnnualPlan }) {
  const url = "annual_plan/" + idAnnualPlan;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function UpdateAnnualPlan({
  develop,
  title,
  date,
  document_url,
  idAnnualPlan,
}) {
  const current = new Date();
  const year = current.getFullYear();
  const month = current.getMonth() + 1;
  const day = current.getDate();

  if (document_url !== null && document_url !== undefined) {
    document_url = await uploadFileCompanies(
      document_url,
      "documentation/annualplan/" + year + "/" + month + "/" + day + "/"
    );
  }
  const url = "annual_plan" + idAnnualPlan;
  const data = {
    develop: develop,
    title: title,
    date: date,
    document_url: document_url,
  };
  console.log(data);
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

// TEMPLATE

async function createAnnualPlanTemplate({
  code,
  develop,
  revised,
  approved,
  version,
  title,
  document_text,
  idUsersDevelop,
  idUsersRevised,
  idUsersApproved,
  dateDevelop,
  dateRevised,
  dateApproved,
}) {
  const url = "annual_plan_template";
  const data = {
    code: code,
    develop: develop,
    revised: revised,
    approved: approved,
    version: version,
    title: title,
    document_text: document_text,
    id_users_develop: idUsersDevelop,
    id_users_revised: idUsersRevised,
    id_users_approved: idUsersApproved,
    date_develop: dateDevelop,
    date_revised: dateRevised,
    date_approved: dateApproved,
  };
  console.log(data);
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}
function getAnnualPlanTemplate() {
  const url = "annual_plan_template";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function editAnnualPlanTemplate({idAnnualPlanTemplate}) {
  const url = "annual_plan_template/" + idAnnualPlanTemplate;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function updateAnnualPlanTemplate({
  idAnnualPlanTemplate,
  code,
  develop,
  revised,
  approved,
  version,
  title,
  document_text,
  idUsersDevelop,
  idUsersRevised,
  idUsersApproved,
  dateDevelop,
  dateRevised,
  dateApproved,
}) {
  const url = "annual_plan_template/" + idAnnualPlanTemplate;
  const data = {
    code: code,
    develop: develop,
    revised: revised,
    approved: approved,
    version: version,
    title: title,
    document_text: document_text,
    id_users_develop: idUsersDevelop,
    id_users_revised: idUsersRevised,
    id_users_approved: idUsersApproved,
    date_develop: dateDevelop,
    date_revised: dateRevised,
    date_approved: dateApproved,
  };
  console.log(data);
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}
const MyAnnualPlan = {
  getAnnualPlan,
  createAnnualPlan,
  editAnnualPlan,
  UpdateAnnualPlan,

  //template
  createAnnualPlanTemplate,
  getAnnualPlanTemplate,
  editAnnualPlanTemplate,
  updateAnnualPlanTemplate,
};

export default MyAnnualPlan
