import MyConfigurationApi from "./MyConfigurationApi";

function getPositions() {
  const url = "positions/get";
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function createPositions({ position, idArea }) {
  console.log(position, idArea);
  const url = "positions/store";
  const data = {
    name: position,
    id_areas: idArea,
  };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function updatePositions({ idPosition, name, idArea }) {
  const url = "positions/update/" + idPosition;
  const data = {
    name: name,
    id_areas: idArea,
  };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function deletePositions({ idPosition }) {
  const url = "positions/delete/" + idPosition;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "DELETE",
    url: url,
  });
}

const MyOccupationalPositions = {
  getPositions,
  createPositions,
  updatePositions,
  deletePositions,
};

export default MyOccupationalPositions;
