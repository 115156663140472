import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function getSsoPolitics() {
  const url = "sso_politics";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function createSsoPolitics({ develop, title, date, document_url }) {
  const current = new Date();
  const year = current.getFullYear();
  const month = current.getMonth() + 1;
  const day = current.getDate();

  if (document_url !== null && document_url !== undefined) {
    document_url = await uploadFileCompanies(
      document_url,
      "documentation/ssopolitics/" + year + "/" + month + "/" + day + "/"
    );
  }
  const url = "sso_politics";
  const data = {
    develop: develop,
    title: title,
    date: date,
    document_url: document_url,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function editSsoPolitics({ idSsoPolitics }) {
  const url = "sso_politics/" + idSsoPolitics;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function UpdateSsoPolitics({
  develop,
  title,
  date,
  document_url,
  idSsoPolitics,
}) {
  const current = new Date();
  const year = current.getFullYear();
  const month = current.getMonth() + 1;
  const day = current.getDate();

  if (document_url !== null && document_url !== undefined) {
    document_url = await uploadFileCompanies(
      document_url,
      "documentation/ssopolitics/" + year + "/" + month + "/" + day + "/"
    );
  }
  const url = "sso_politics/" + idSsoPolitics;
  const data = {
    develop: develop,
    title: title,
    date: date,
    document_url: document_url,
  };
  console.log(data);
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

// TEMPLATE

async function createSsoPoliticsTemplate({
  code,
  develop,
  revised,
  approved,
  version,
  title,
  document_text,
  idUsersDevelop,
  idUsersRevised,
  idUsersApproved,
  dateDevelop,
  dateRevised,
  dateApproved,
}) {
  const url = "sso_politics_template";
  const data = {
    code: code,
    develop: develop,
    revised: revised,
    approved: approved,
    version: version,
    title: title,
    document_text: document_text,
    id_users_develop: idUsersDevelop,
    id_users_revised: idUsersRevised,
    id_users_approved: idUsersApproved,
    date_develop: dateDevelop,
    date_revised: dateRevised,
    date_approved: dateApproved,
  };
  console.log(data);
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}
function getSsoPoliticsTemplate() {
  const url = "sso_politics_template";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function editSsoPoliticsTemplate({ idSsoPoliticsTemplate }) {
  const url = "sso_politics_template/" + idSsoPoliticsTemplate;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function updateSsoPoliticsTemplate({
  idSsoPoliticsTemplate,
  code,
  develop,
  revised,
  approved,
  version,
  title,
  document_text,
  idUsersDevelop,
  idUsersRevised,
  idUsersApproved,
  dateDevelop,
  dateRevised,
  dateApproved,
}) {
  const url = "sso_politics_template/" + idSsoPoliticsTemplate;
  const data = {
    code: code,
    develop: develop,
    revised: revised,
    approved: approved,
    version: version,
    title: title,
    document_text: document_text,
    id_users_develop: idUsersDevelop,
    id_users_revised: idUsersRevised,
    id_users_approved: idUsersApproved,
    date_develop: dateDevelop,
    date_revised: dateRevised,
    date_approved: dateApproved,
  };
  console.log(data);
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}
const MySsoPolitics = {
  getSsoPolitics,
  createSsoPolitics,
  editSsoPolitics,
  UpdateSsoPolitics,

  //template
  createSsoPoliticsTemplate,
  getSsoPoliticsTemplate,
  editSsoPoliticsTemplate,
  updateSsoPoliticsTemplate,
};

export default MySsoPolitics;
