import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from "@mui/material";
import React from "react";
function BannerVerticalControla({
  color1,
  color2,
  image,
  title,
  functionButton,
  textButton,
  backgrounColorButton,
  hoverButton,
  url,
  functionClick
}) {
  return (
    <Card
      sx={{
        maxWidth: 450,
        height: "320px",
        borderRadius: "16px",
        background: `linear-gradient(90deg, ${color2}, ${color1})`,
        backgroundSize: "100%",
        color: "white",
        padding: "15px",
        marginBottom: "20px",
      }}
    >
      {" "}
      <CardActionArea
        sx={{
          width: "100%",
          height: "100%",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px  10px 0 10px !important",
        }}
        href={url}
        onClick={functionClick}
      >
        <CardContent
          sx={{
            width: "100%",
            height: "100%",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px  10px 0 10px !important",
          }}
        >
          <Typography gutterBottom variant="h5">
            {title}
          </Typography>
          <CardMedia
            sx={{ width: "100%", height: "70%", objectFit: "contain" }}
            image={image}
            title="green iguana"
          />
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default BannerVerticalControla;
