// colocar lo de Workaccidents.jsx
import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import {
  Add,
  Archive,
  Delete,
  FileUploadOutlined,
  InfoOutlined,
  Publish,
  Send,
  Unarchive,
  VisibilityOutlined,
} from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SideNav from "../../../components/sidenav/sideNav";
import SubtitleText from "../../../components/text/subtitleText";
import BannerControla from "../../../components/cards/bannerControla";
import ButtonControla from "../../../components/buttons/buttonController";
import InputControlaVertical from "../../../components/textfields/inputControlaVertical";
import ButtonIconControla from "../../../components/buttons/buttonIconControla";
import LoadingControla from "../../../components/load/loadingControla";
import DialogConfirmControla from "../../../components/dialog/dialogConfirmControla";
import styled from "styled-components";
import DialogTitleControla from "../../../components/dialog/dialogTitleControla";
import MySecuritySheetRadioactiveMaterials from "../../../api/MySecuritySheetRadioactiveMaterials";

const colorTitle = "#9191B0";
const skyblue = "#2D9CDB";
const purple = "#8B8BD8";

function Index() {
  const StyledAddButton = styled(Button)({
    textTransform: "none",
    backgroundColor: skyblue,
    borderRadius: 16,
  });
  const params = new URLSearchParams(window.location.search);
  const idTabs = parseInt(
    params.get("idTabs") !== null ? params.get("idTabs") : 0
  );

  // alerts
  const [openAlert, setOpenAlert] = React.useState(false);
  const [openLoading, setOpenLoading] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState("");
  const [typeAlert, setTypeAlert] = React.useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  //fin de alerts
  // Dialogos de confirmacion INICIO
  const [openModalConfirm, setOpenModalConfirm] = React.useState(false);
  const [titleText, setTitleText] = React.useState("");
  const [contentText, setContentText] = React.useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = React.useState("");
  const [buttonActionType, setButtonActionType] = React.useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setIdSecuritySheet(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    // eslint-disable-next-line default-case
    switch (buttonActionType) {
      case "delete":
        handleDeleteSecuritySheet();
        break;
        case "public":
          updateStatus(4);
          break;
        case "archived":
          updateStatus(5);
          break;
        case "unarchived":
          updateStatus(4);
          break;
    }
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN

  const [openModalAddSecuritySheet, setOpenModalAddSecuritySheet] =
    React.useState(false);
  const handleOpenModalAddSecuritySheet = () => {
    setName("");
    setOpenModalAddSecuritySheet(true);
  };

  const [securitySheet, setSecuritySheet] = React.useState([]);
  const [name, setName] = React.useState("");
  const [file, setFile] = React.useState("");

  const handleAddSecuritySheet = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response =
      await MySecuritySheetRadioactiveMaterials.createSecuritySheet({
        name,
        file,
      });
    handleGetSecuritySheet();
    setOpenModalAddSecuritySheet(false);
    handleOpenAlert("Se crearon los datos de forma correcta", "success");
    return response;
  };

  const handleGetSecuritySheet = async () => {
    const response =
      await MySecuritySheetRadioactiveMaterials.getSecuritySheet();
    setSecuritySheet(response);

    return response;
  };

  //   // eliminar
  const [idSecuritySheet, setIdSecuritySheet] = React.useState();

  const handleDeleteSecuritySheet = async () => {
    handleOpenLoading();

    const response =
      await MySecuritySheetRadioactiveMaterials.deleteSecuritySheet({
        idSecuritySheet: idSecuritySheet,
      });
    if (response.success === true) {
      handleGetSecuritySheet();
      handleOpenAlert(
        "Los datos fueron eliminados de forma correcta",
        "success"
      );
    } else {
      handleOpenAlert(response.msg, "error");
    }
    return response;
  };

  //   infromacion del mapa de riesgos
  const StyledModalTitle = styled("h2")({
    color: purple,
  });
  const StyledModalSubtitle = styled("h3")({
    fontWeight: "bold",
  });
  const [openModalInfo, setOpenModalInfo] = React.useState(false);

  const handleOpenModalInfo = () => {
    setOpenModalInfo(true);
  };

  // document type
  const [documentType, setDocumentType] = React.useState(
    idTabs !== undefined && idTabs !== null && idTabs !== "" ? idTabs : 0
  );

  const handleChangeDocumentType = (event, newValue) => {
    setDocumentType(newValue);
  };
// actualizar estado
const updateStatus = async (idStatus) => {
  handleOpenLoading();
  const response = await MySecuritySheetRadioactiveMaterials.updatestatus({
    idSecuritySheet: idSecuritySheet,
    register_status: idStatus,
  });
  if (response.success === false) {
    handleOpenAlert(response.msg, "error");
  } else {
    handleGetSecuritySheet();
    handleOpenAlert("La operación se realizó con éxito", "success");
  }
  return response;
};
  React.useEffect(() => {
    handleGetSecuritySheet();
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="/documentation/securitysheet" />
      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={6} md={6}>
              <SubtitleText
                text={"Hojas de seguridad"}
                color={colorTitle}
              ></SubtitleText>
            </Grid>
            <Grid item xd={6} md={6} style={{ textAlign: "right" }}>
              <StyledAddButton
                variant="text"
                startIcon={<InfoOutlined />}
                disableElevation
                size="large"
                style={{
                  backgroundColor: "#305AD9",
                  borderRadius: "15px",
                  color: "white",
                  padding: "8px 25px",
                }}
                onClick={() => handleOpenModalInfo()}
              >
                ¿Qué sustancias comprenden?{" "}
              </StyledAddButton>
            </Grid>
            <Grid item xs={12} md={12}>
              <BannerControla
                image="/assets/images/banners/documents/bannerradioactivematerials.png"
                color2="#2F67BC"
                color1="#8FBCFF"
                text="MSDS - Clase 7 - Materiales Radioactivos"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={3}>
              <ButtonControla
                iconButton={<Add sx={{ color: "white" }} />}
                backgroundColor={"#169073"}
                backgroundColorHover={"#1BAA88"}
                textButton={"Agregar nuevo MSDS"}
                functionOnClick={handleOpenModalAddSecuritySheet}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid item md={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          // align="center"
                          sx={{
                            color: "#1638F2",
                            fontSize: "18px",
                            width: "50%",
                          }}
                        >
                          Nombre del documento
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#1638F2",
                            fontSize: "18px",
                            width: "30%",
                          }}
                        >
                          Estado
                        </TableCell>
                        <TableCell colSpan={4}>
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            <Tabs
                              value={documentType}
                              onChange={handleChangeDocumentType}
                              aria-label="Tipos de documento"
                            >
                              {/* <Tab label="Modelos" /> */}
                              <Tab label="Activos" />
                              {/* <Tab label="Archivados" /> */}
                            </Tabs>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {securitySheet.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            padding: "4px",
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.register_status === 1
                              ? "Creado"
                              : row.register_status === 4 ?
                              "Publicado" : ''
                            }
                          </TableCell>

                          <TableCell align="right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                            >
                              <ButtonIconControla
                                ////todos los roles
                                icon={
                                  <VisibilityOutlined sx={{ color: "white" }} />
                                }
                                backgroundColor={"#305AD9"}
                                backgroundColorHover={"#0E4AFF"}
                                textTooltip={"Ver"}
                                directionUrl={row.file}
                                directionTarget={"_blank"}
                              />
                               {row.register_status === 1 ? (
                                <ButtonIconControla
                                  roles={[1, 7, 8, 15]}
                                  icon={<Publish sx={{ color: "white" }} />}
                                  backgroundColor={"#169073"}
                                  backgroundColorHover={"#1AA483"}
                                  textTooltip={"Publicar"}
                                  functionOnClick={() => {
                                    handleOpenModalConfirm(
                                      row.id,
                                      "¿Esta seguro de publicar?",
                                      'Una vez publicado no podrá revertir la acción del documento:  "' +
                                        row.name +
                                        '"',
                                      "Si, publicar",
                                      "public"
                                    );
                                  }}
                                />
                              ) : (
                                <></>
                              )}
                              {/* {row.register_status === 4 ? (
                                <ButtonIconControla
                                  roles={[1, 7, 8]}
                                  icon={<Archive sx={{ color: "white" }} />}
                                  backgroundColor={"#F2994A"}
                                  backgroundColorHover={"#FF881E"}
                                  textTooltip={"Archivar"}
                                  functionOnClick={() =>
                                    handleOpenModalConfirm(
                                      row.id,
                                      "¿Esta seguro de archivar el documento?",
                                      'Documento a archivar:  "' +
                                        row.name +
                                        '"',
                                      "Si, archivar",
                                      "archived"
                                    )
                                  }
                                />
                              ) : (
                                ""
                              )}
                              {row.register_status === 5 ? (
                                <ButtonIconControla
                                  roles={[1, 7, 8]}
                                  icon={<Unarchive sx={{ color: "white" }} />}
                                  backgroundColor={"#FF5733"}
                                  backgroundColorHover={"#FF5733"}
                                  textTooltip={"Desarchivar"}
                                  functionOnClick={() =>
                                    handleOpenModalConfirm(
                                      row.id,
                                      "¿Esta seguro de desarchivar el documento?",
                                      'Documento a desarchivar:  "' +
                                        row.name +
                                        '"',
                                      "Si, desarchivar",
                                      "unarchived"
                                    )
                                  }
                                />
                              ) : (
                                ""
                              )} */}
                              {row.register_status !== 5 ? (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id,
                                    "¿Esta seguro de eliminar los datos del documento?",
                                    "Una vez eliminado no se podra recuperar los datos de " +
                                      row.name,
                                    "Si, Eliminar",
                                    "delete"
                                  );
                                }}
                              />
                              ) : (
                                ""
                              )}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Dialog
        open={openModalAddSecuritySheet}
        onClose={() => setOpenModalAddSecuritySheet(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Nuevo MSDS - Materiales radioativos{" "}
          </Typography>
        </DialogTitle>
        <form onSubmit={handleAddSecuritySheet}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Nombre:"}
                  inputType={"text"}
                  inputValue={setName}
                  value={name}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Archivo"}
                  inputType={"file"}
                  inputValue={setFile}
                  value={file}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalAddSecuritySheet(false)}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Crear
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* dialogo de información */}
      <Dialog
        open={openModalInfo}
        onClose={() => setOpenModalInfo(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{
          padding: "20px",
        }}
      >
        <DialogTitleControla titleText="MSDS - Clase 7 - Materiales radiactivos" />
        <DialogContent>
          <Grid container>
            <Grid item md={12}>
              <StyledModalTitle>
                Clase 7 - Materiales radiactivos{" "}
              </StyledModalTitle>
            </Grid>

            <Grid item>
              Se entiende por material radiactivo a todos aquellos que poseen
              una actividad mayor a 70 kBq/Kg (kilobequerelios por kilogramo) o
              su equivalente de 2 nCi/g (nanocurios por gramo).
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle>
                Simbología
              </StyledModalSubtitle>
              <img src="/assets/images/info/Clase 7.1..jpeg" alt="simbologia"/>
              <img src="/assets/images/info/Clase 7.2..jpeg" alt="simbologia"/>
              <img src="/assets/images/info/Clase 7.3..jpeg" alt="simbologia"/>
              <img src="/assets/images/info/Clase 7.4..jpeg" alt="simbologia"/>


            </Grid>
            <Grid item>
              Fuente: Libro Naranja de las Naciones Unidas; ICS (International Classification System).
              </Grid> 
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModalInfo(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Index;
