import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Grid,
  Button,
  Tabs,
  Tab,
  Stack,
  Typography,
  DialogActions,
  InputBase,
  InputLabel,
} from "@mui/material";
import {
  Add,
  Archive,
  ArrowBackIosNew,
  ArrowForwardIos,
  BorderColorOutlined,
  Delete,
  FileUploadOutlined,
  ForumOutlined,
  Send,
  ThumbDownAlt,
  ThumbUpAlt,
  Unarchive,
  Upload,
  VisibilityOutlined,
} from "@mui/icons-material";
import SubtitleText from "../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import SideNav from "../../../components/sidenav/sideNav";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import InputControlaVertical from "../../../components/textfields/inputControlaVertical";

import CloseIcon from "@mui/icons-material/Close";
import MyPrograms from "../../../api/MyPrograms";

import ButtonIconControla from "../../../components/buttons/buttonIconControla";
import BannerControla from "../../../components/cards/bannerControla";
import LoadingControla from "../../../components/load/loadingControla";
import DialogConfirmControla from "../../../components/dialog/dialogConfirmControla";
import ButtonControla from "../../../components/buttons/buttonController";
import MyConfigurationApi from "../../../api/MyConfigurationApi";
import DialogTitleControla from "../../../components/dialog/dialogTitleControla";

const colorTitle = "#9191B0";
const blue = "#034AFF";
const purple = "#8B8BD8";

function Index() {
  // const params = new URLSearchParams(window.location.search);
  // const idprograms = params.get("id_programs");

  // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  //fin de alerts

  // Dialogos de confirmacion INICIO

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setIdPrograms(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    switch (buttonActionType) {
      case "delete":
        deletePrograms();
        break;
      case "trabajador":
        updatestatusesPrograms(2);
        break;
      case "comite":
        updatestatusesPrograms(3);
        break;
      case "aprobar":
        updatestatusesPrograms(4);
        break;
      case "desaprobar":
        updatestatusesPrograms(5);
        break;
      case "public":
        updatestatusesPrograms(6);
        break;
      case "archived":
        updateArchivedPrograms();
        break;
      case "unarchived":
        updateUnarchivedPrograms();
        break;
    }
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN


  const handleGetPrograms = async (inputYear = undefined) => {
    if (inputYear != undefined) {
      handleOpenLoading();
    }
    const response = await MyPrograms.programs({ year: (inputYear == undefined ? year : inputYear) });
    if (response.success === false) {
      handleOpenAlert('Ocurrio un error al listar los Programas', 'error');
    } else {
      const programs_archived = response.programs_archived;
      const programs_not_archived = response.programs_not_archived;

      setRowsArchived(programs_archived);
      setRowsNotArchived(programs_not_archived);
      if (inputYear != undefined) {
        setDocumentType(0);
        handleOpenAlert('Listando Programas vigentes del año ' + inputYear, 'success');
      }
    }
    return response;
  };

  const [rowsArchived, setRowsArchived] = useState([]);
  const [rowsNotArchived, setRowsNotArchived] = useState([]);

  const [documentType, setDocumentType] = useState(0);

  const handleChangeDocumentType = (event, newValue) => {
    setDocumentType(newValue);
  };

  // modal para crear un nuevo programa
  const [openModalCreate, setOpen] = React.useState(false);
  const handleCloseModalCreate = () => setOpen(false);

  const handleClickOpen = () => {
    setCode("");
    setDateCreated("");
    setTitulo("");
    setVersion("");
    setYear("");
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // MODAL CON IPUT FILE
  const [openModalCreateFile, setOpenModalFile] = React.useState(false);

  const handleClickOPenFile = () => {
    setCode("");
    setDateCreated(undefined);
    setTitulo("");
    setOpenModalFile(true);
    setVersion("");
    setYear("");
  };

  const handleCloseCModalCreateFile = () => {
    setOpenModalFile(false);
  };
  // usuario logeado
  const handleUserActive = async () => {
    const response = await MyConfigurationApi.userData();
    setPreparedBy(response.first_name + " " + response.last_name);
  };

  // crear nuevo programa
  const [idPrograms, setIdPrograms] = useState(0);
  const [code, setCode] = useState(null);
  const [version, setVersion] = useState(null);
  const [titulo, setTitulo] = useState(null);
  const [preparedBy, setPreparedBy] = useState("");
  const [dateCreated, setDateCreated] = useState("");
  const [year, setYear] = useState(new Date().getFullYear());
  const [progrmapType, setProgramType] = useState("General");

  const handlePostCreatePrograms = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyPrograms.createPrograms({
      code,
      version,
      titulo,
      document_url: null,
      date_created: dateCreated,
      year: year,
    });
    if (response.success !== false) {
      handleOpenAlert(response.msg, "success");
      handleGetPrograms();
      handleCloseModalCreate();
    }
    return response;
  };

  // crear programa file
  const [documentUrlFile, setDocumentUrlFile] = useState(null);

  const handlePostCreateProgramsFile = async (event) => {
    event.preventDefault();
    handleOpenLoading();

    const response = await MyPrograms.createPrograms({
      code: code,
      version: version,
      titulo: titulo,
      document_url: documentUrlFile,
      year: year,
      date_created: dateCreated,
    });
    if (response.success !== false) {
      handleOpenAlert(response.msg, "success");
      handleGetPrograms();
      handleCloseCModalCreateFile();
    }
    return response;
  };

  // editar programa con file
  const [idProgramsFile, setIdProgramsFile] = useState();

  const [openModalEditFile, setOpenModalEditFile] = useState(false);
  const handleOpenModalEditFile = (
    idPrograms,
    code,
    version,
    titulo,
    dateCreated,
    year
  ) => {
    setIdProgramsFile(idPrograms);
    setCode(code);
    setVersion(version);
    setTitulo(titulo);
    setDateCreated(dateCreated);
    setYear(year);
    setOpenModalEditFile(true);
  };

  const handleCloseModalEditFile = () => setOpenModalEditFile(false);

  const handlePostUpdatePrograms = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyPrograms.updatePrograms({
      idprograms: idProgramsFile,
      code,
      version,
      document_url: documentUrlFile,
      name: titulo,
      date_created: dateCreated,
      year: year,
    });
    if (response.success !== false) {
      handleGetPrograms();
      handleCloseModalEditFile();
      handleOpenAlert(response.msg, "success");
    } else {
      handleOpenAlert(response.msg, "error");
    }
    return response;
  };

  // eliminar programa
  const deletePrograms = async () => {
    console.log("eliminar");
    handleOpenLoading();
    const response = await MyPrograms.deletePrograms({
      idprograms: idPrograms,
    });
    if (response.success !== false) {
      handleGetPrograms();
      handleOpenAlert(response.msg, "success");
    } else {
      handleOpenAlert("Ocurrió un error al eliminar el archivo", "error");
    }
    return response;
  };

  // Actualziar estados
  const updatestatusesPrograms = async (idPetsStatuses) => {
    console.log(idPetsStatuses, idPrograms);
    handleOpenLoading();
    const response = await MyPrograms.updateStatusesPrograms({
      id_programs: idPrograms,
      id_programs_statuses: idPetsStatuses,
    });
    if (response.success === false) {
      handleOpenAlert(response.msg, "error");
    } else {
      // handleGetPets();
      handleGetPrograms();
      handleOpenAlert("Documento publicado", "success");
    }
    return response;
  };

  const updateArchivedPrograms = async () => {
    handleOpenLoading();
    const response = await MyPrograms.updatearchivedPrograms({ id_programs: idPrograms });
    if (response.success === false) {
      handleOpenAlert(response.msg, "error");
    } else {
      handleOpenAlert("Documento archivado", "success");
      handleGetPrograms();
    }
    return response;
  };

  const updateUnarchivedPrograms = async () => {
    handleOpenLoading();
    const response = await MyPrograms.updateUnarchivedPrograms({ id_programs: idPrograms });
    if (response.success === false) {
      handleOpenAlert(response.msg, "error");
    } else {
      handleOpenAlert("Documento desarchivado", "success");
      handleGetPrograms();
    }
    return response;
  };

  useEffect(() => {
    handleGetPrograms();
    handleUserActive();
  }, []);

  const handleIncreaseYear = (event, newValue) => {
    setYear(year + 1);
    handleGetPrograms(year + 1);
  };

  const handleDecreaseYear = (event, newValue) => {
    setYear(year - 1);
    handleGetPrograms(year - 1);
  };

  console.log(rowsNotArchived);
  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="/documentation/programs" />
      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <SubtitleText
                text={"Documentación"}
                color={colorTitle}
              ></SubtitleText>
              <BannerControla
                image="/assets/images/programs_general.png"
                color2="#2F67BC"
                color1="#8FBCFF"
                text="Programa anual de SSO y otros"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
              <ButtonControla
                iconButton={<Add sx={{ color: "white" }} />}
                textButton={"Agregar programa"}
                backgroundColor={"#2D9CDB"}
                backgroundColorHover={"#1976d2"}
                functionOnClick={handleClickOpen}
              />
            </Grid>

            <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
              <ButtonControla
                iconButton={<Upload sx={{ color: "white" }} />}
                backgroundColor={"#1cb591"}
                backgroundColorHover={"#169073"}
                textButton={"Subir programa"}
                functionOnClick={handleClickOPenFile}
              />
            </Grid>

            {/* filtrar por año */}
            <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
              <Grid container spacing={2}>
                <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
                  <InputLabel
                    sx={{
                      padding: "8px 0px 8px 0px",
                      fontSize: "14px",
                      color: "#305AD9",
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    Periodo:
                  </InputLabel>
                </Grid>
                <Grid item xs={3} sm={3} md={4} lg={4} xl={4}>
                  <InputBase
                    type="number"
                    value={year}
                    // onChange={handleGetPets}
                    readOnly
                    sx={{
                      width: "100%",
                      padding: "4px 10px 4px 10px",
                      backgroundColor: "white",
                      color: "#305AD9",
                      fontSize: "14px",
                      borderRadius: "8px",
                    }}
                  ></InputBase>
                </Grid>
                <Grid item xs={2} sm={1} md={2} lg={2} xl={2}>
                  <IconButton
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "15px",
                      color: "#305AD9",
                    }}
                    onClick={handleDecreaseYear}
                  >
                    <ArrowBackIosNew />
                  </IconButton>
                </Grid>
                <Grid item xs={2} sm={1} md={2} lg={2} xl={2}>
                  <IconButton
                    sx={{
                      backgroundColor: "white",
                      borderRadius: "15px",
                      color: "#305AD9",
                    }}
                    onClick={handleIncreaseYear}
                  >
                    <ArrowForwardIos />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2, mb: 6 }}>
            <Grid container item md={12}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nombre del documento
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Tipo
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Estado
                      </TableCell>
                      <TableCell
                        colSpan={4}
                        sx={{ color: blue }}
                        align="center"
                      >
                        <Stack
                          direction="row"
                          justifyContent="end"
                          alignItems="center"
                        >
                          <Tabs
                            value={documentType}
                            onChange={handleChangeDocumentType}
                            aria-label="Tipos de documento"
                            centered
                          >
                            {/* <Tab label="Modelos" /> */}
                            <Tab label="VIGENTES" />
                            <Tab label="ARCHIVADOS" />
                          </Tabs>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody sx={{ display: documentType !== 0 ? "none" : "" }}>
                    {rowsNotArchived.map((rowsNotArchived) => (
                      <TableRow
                        hover
                        key={rowsNotArchived.id_programs}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">
                          {rowsNotArchived.name}
                        </TableCell>
                        <TableCell sx={{ color: purple }} align="center">
                          {rowsNotArchived.document_url != null &&
                            rowsNotArchived.document_url != undefined
                            ? "Archivo"
                            : "Sistema"}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {rowsNotArchived.status}
                        </TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            {rowsNotArchived.id_programs_statuses === 1 &&
                              rowsNotArchived.document_url !== null ? (
                              <ButtonIconControla
                                icon={
                                  <BorderColorOutlined
                                    sx={{ color: "white" }}
                                  />
                                }
                                backgroundColor={"#2D9CDB"}
                                backgroundColorHover={"#33AEF4"}
                                textTooltip={"Editar"}
                                functionOnClick={() =>
                                  handleOpenModalEditFile(
                                    rowsNotArchived.id_programs,
                                    rowsNotArchived.code,
                                    rowsNotArchived.version,
                                    rowsNotArchived.name,
                                    rowsNotArchived.date_created,
                                    rowsNotArchived.year
                                  )
                                }
                              />
                            ) : (rowsNotArchived.id_programs_statuses === 1 || rowsNotArchived.id_programs_statuses === 2 || rowsNotArchived.id_programs_statuses === 3) &&
                              rowsNotArchived.document_url === null ? (
                              <ButtonIconControla
                                icon={
                                  <BorderColorOutlined
                                    sx={{ color: "white" }}
                                  />
                                }
                                backgroundColor={"#2D9CDB"}
                                backgroundColorHover={"#33AEF4"}
                                textTooltip={"Editar"}
                                functionOnClick={() =>
                                (window.location.href =
                                  "/documentation/programs/sso/editprograms?id_programs=" +
                                  rowsNotArchived.id_programs)
                                }
                              />
                            ) : ''
                            }

                            {rowsNotArchived.document_url === null ?
                              rowsNotArchived.id_programs_statuses === 1 ? (
                                <ButtonIconControla
                                  icon={<Send sx={{ color: "white" }} />}
                                  backgroundColor={"#169073"}
                                  backgroundColorHover={"#1AA483"}
                                  textTooltip={"Enviar a trabajadores"}
                                  functionOnClick={() => {
                                    handleOpenModalConfirm(
                                      rowsNotArchived.id_programs,
                                      "¿Estás seguro de enviar a trabajadores?",
                                      'Una vez enviado no podra revertir la operación del documento:  "' +
                                      rowsNotArchived.name +
                                      '"',
                                      "Si, Enviar a trabajadores",
                                      "trabajador"
                                    );
                                  }}
                                />
                              ) :
                                rowsNotArchived.id_programs_statuses === 2 ? (
                                  <>
                                    <ButtonIconControla
                                      icon={
                                        <ForumOutlined sx={{ color: "white" }} />
                                      }
                                      backgroundColor={"#CA2323"}
                                      backgroundColorHover={"#DF2F24"}
                                      textTooltip={"Buzon de participación"}
                                      directionUrl={"/mailbox/consults"}
                                    />
                                    <ButtonIconControla
                                      icon={<Send sx={{ color: "white" }} />}
                                      backgroundColor={"#804000"}
                                      backgroundColorHover={"#A45200"}
                                      textTooltip={"Enviar a comité"}
                                      functionOnClick={() => {
                                        handleOpenModalConfirm(
                                          rowsNotArchived.id_programs,
                                          "¿Estás seguro de enviar a comité?",
                                          'Recuerda que para enviar al comite tienes que tener en cuenta las sugerencias de los trabajadores:  "' +
                                          rowsNotArchived.name +
                                          '"',
                                          "Si, enviar a comité",
                                          "comite"
                                        );
                                      }}
                                    />
                                  </>

                                ) : rowsNotArchived.id_programs_statuses === 3 ? (
                                  <>
                                    <ButtonIconControla
                                      icon={<ThumbUpAlt sx={{ color: "white" }} />}
                                      backgroundColor={"#169073"}
                                      backgroundColorHover={"#1AA483"}
                                      textTooltip={"Aprobar"}
                                      functionOnClick={() => {
                                        handleOpenModalConfirm(
                                          rowsNotArchived.id_programs,
                                          "¿Estás seguro de aprobar el programa?",
                                          'Para marcar la opción aprobado, debes verificar que el comité lo haya aprobado y conste en la respectiva acta. Una vez aprobado no podrá deshacer la operación:  "' +
                                          rowsNotArchived.name +
                                          '"',
                                          "Aprobado",
                                          "aprobar"
                                        );
                                      }}
                                    />
                                    <ButtonIconControla
                                      icon={<ThumbDownAlt sx={{ color: "white" }} />}
                                      backgroundColor={"#CA2323"}
                                      backgroundColorHover={"#DF2F24"}
                                      textTooltip={"Desaprobar"}
                                      functionOnClick={() => {
                                        handleOpenModalConfirm(
                                          rowsNotArchived.id_programs,
                                          "¿Estás seguro de desaprobar el programa?",
                                          'Para marcar la opción desaprobado, debes verificar que el comité lo haya desaprobado y conste en la respectiva acta. Una vez desaprobado no podrá deshacer la operación:  "' +
                                          rowsNotArchived.name +
                                          '"',
                                          "Desaprobado",
                                          "desaprobar"
                                        );
                                      }}
                                    />
                                  </>

                                ) : rowsNotArchived.id_programs_statuses === 4 ? (
                                  <ButtonIconControla
                                    icon={<FileUploadOutlined sx={{ color: "white" }} />}
                                    backgroundColor={"#169073"}
                                    backgroundColorHover={"#1AA483"}
                                    textTooltip={"Publicar"}
                                    functionOnClick={() => {
                                      handleOpenModalConfirm(
                                        rowsNotArchived.id_programs,
                                        "¿Estás seguro de publicar el programa?",
                                        'Una vez publicado  se cerrará el programa:  "' +
                                        rowsNotArchived.name +
                                        '"',
                                        "Si, publicar",
                                        "public"
                                      );
                                    }}
                                  />
                                ) : rowsNotArchived.id_programs_statuses === 4 || rowsNotArchived.id_programs_statuses === 5 || rowsNotArchived.id_programs_statuses === 6 ? (
                                  <ButtonIconControla
                                    icon={<Archive sx={{ color: "white" }} />}
                                    backgroundColor={"#F2994A"}
                                    backgroundColorHover={"#FF881E"}
                                    textTooltip={"Archivar"}
                                    functionOnClick={() => {
                                      handleOpenModalConfirm(
                                        rowsNotArchived.id_programs,
                                        "¿Estás seguro de archivar el programa?",
                                        'Programa a archivar  "' +
                                        rowsNotArchived.name +
                                        '"',
                                        "Si, archivar",
                                        "archived"
                                      );
                                    }}
                                  />
                                ) : (
                                  ""
                                ) :
                              rowsNotArchived.id_programs_statuses === 1 ?
                                <ButtonIconControla
                                  icon={<FileUploadOutlined sx={{ color: "white" }} />}
                                  backgroundColor={"#169073"}
                                  backgroundColorHover={"#1AA483"}
                                  textTooltip={"Publicar"}
                                  functionOnClick={() => {
                                    handleOpenModalConfirm(
                                      rowsNotArchived.id_programs,
                                      "¿Estás seguro de publicar el programa?",
                                      'Para publicar debes asegurarte que este Programa ha sido aprobado por el Comité. Una vez publicado  se cerrará el programa:  "' +
                                      rowsNotArchived.name +
                                      '"',
                                      "Si, publicar",
                                      "public"
                                    );
                                  }}
                                /> : ""}

                            {rowsNotArchived.document_url !== null ? (
                              rowsNotArchived.id_programs_statuses === 6 ? (
                                <>
                                  <ButtonIconControla
                                    icon={<Archive sx={{ color: "white" }} />}
                                    backgroundColor={"#F2994A"}
                                    backgroundColorHover={"#FF881E"}
                                    textTooltip={"Archivar"}
                                    functionOnClick={() => {
                                      handleOpenModalConfirm(
                                        rowsNotArchived.id_programs,
                                        "¿Estás seguro de archivar el programa?",
                                        'Programa a archivar  "' +
                                        rowsNotArchived.name +
                                        '"',
                                        "Si, archivar",
                                        "archived"
                                      );
                                    }}
                                  />

                                  <ButtonIconControla
                                    icon={
                                      <VisibilityOutlined sx={{ color: "white" }} />
                                    }
                                    backgroundColor={"#305AD9"}
                                    backgroundColorHover={"#0E4AFF"}
                                    textTooltip={"Ver"}
                                    directionUrl={rowsNotArchived.document_url}
                                    directionTarget={"_blank"}
                                  />
                                </>
                              ) :
                                <ButtonIconControla
                                  icon={
                                    <VisibilityOutlined sx={{ color: "white" }} />
                                  }
                                  backgroundColor={"#305AD9"}
                                  backgroundColorHover={"#0E4AFF"}
                                  textTooltip={"Ver"}
                                  directionUrl={rowsNotArchived.document_url}
                                  directionTarget={"_blank"}
                                />
                            ) : rowsNotArchived.id_programs_statuses === 4 || rowsNotArchived.id_programs_statuses === 5 || rowsNotArchived.id_programs_statuses === 6 ? (
                              <ButtonIconControla
                                icon={
                                  <VisibilityOutlined sx={{ color: "white" }} />
                                }
                                backgroundColor={"#305AD9"}
                                backgroundColorHover={"#0E4AFF"}
                                textTooltip={"Ve xxr"}
                                directionUrl={
                                  "/documentation/programs/sso/editprograms?id_programs=" + rowsNotArchived.id_programs

                                }
                              />
                            ) : ""}
                            {rowsNotArchived.id_programs_statuses === 1 ?
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    rowsNotArchived.id_programs,
                                    "¿Estás seguro de eliminar el programa?",
                                    'Una vez eliminado no se podra recuperar todo lo incluido en este Programa:  "' +
                                    rowsNotArchived.name +
                                    '"',
                                    "Si, Eliminar",
                                    "delete"
                                  );
                                }}
                              /> : ''}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableBody sx={{ display: documentType !== 1 ? "none" : "" }}>
                    {rowsArchived.map((rowsArchived) => (
                      <TableRow
                        hover
                        key={rowsArchived.id_programs}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="left">{rowsArchived.name}</TableCell>
                        <TableCell sx={{ color: purple }} align="center">
                          {rowsArchived.document_url != null &&
                            rowsArchived.document_url != undefined
                            ? "Archivo"
                            : "Sistema"}
                        </TableCell>
                        <TableCell align="center" component="th" scope="row">
                          {rowsArchived.status}
                        </TableCell>

                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >

                            {rowsArchived.document_url !== null ? (
                              <ButtonIconControla
                                icon={
                                  <VisibilityOutlined sx={{ color: "white" }} />
                                }
                                backgroundColor={"#305AD9"}
                                backgroundColorHover={"#0E4AFF"}
                                textTooltip={"Ver"}
                                directionUrl={rowsArchived.document_url}
                                directionTarget={"_blank"}
                              />
                            ) : rowsArchived.id_programs_statuses !== 1 ? (
                              <ButtonIconControla
                                icon={
                                  <VisibilityOutlined sx={{ color: "white" }} />
                                }
                                backgroundColor={"#305AD9"}
                                backgroundColorHover={"#0E4AFF"}
                                textTooltip={"Ver"}
                                directionUrl={
                                  "/documentation/programs/sso/editprograms?id_programs=" + rowsArchived.id_programs

                                }
                              />
                            ) : (
                              ""
                            )}
                            <ButtonIconControla
                              icon={<Unarchive sx={{ color: "white" }} />}
                              backgroundColor={"#F2994A"}
                              backgroundColorHover={"#FF881E"}
                              textTooltip={"Desarchivar"}
                              functionOnClick={() =>
                                handleOpenModalConfirm(rowsArchived.id_programs,
                                  '¿Estás seguro de desarchivar el programa?',
                                  'Programa a desarchivar:  "' + rowsArchived.name + '"',
                                  'Si, desarchivar',
                                  'unarchived')}
                            />
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Dialog
        open={openModalCreate}
        onClose={handleCloseModalCreate}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Programa anual de SSO y otros
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseModalCreate}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={handlePostCreatePrograms}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <InputControlaVertical
                  text={"Código:"}
                  inputType={"text"}
                  inputValue={setCode}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputControlaVertical
                  text={"Versión:"}
                  inputType={"text"}
                  inputValue={setVersion}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputControlaVertical
                  text={"Elaborado por:"}
                  inputType={"text"}
                  inputValue={setPreparedBy}
                  value={preparedBy}
                  readOnly
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputControlaVertical
                  text={"Fecha:"}
                  inputType={"date"}
                  inputValue={setDateCreated}
                  value={dateCreated}
                  modalType={true}
                  required
                // required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputControlaVertical
                  text={"Tipo de programa:"}
                  inputType={"text"}
                  inputValue={setProgramType}
                  value={progrmapType}
                  readOnly
                  modalType={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputControlaVertical
                  text={"Periodo(año):"}
                  inputType={"number"}
                  inputValue={setYear}
                  value={year}
                  modalType={true}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <InputControlaVertical
                  text={"Título documento:"}
                  inputType={"text"}
                  inputValue={setTitulo}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalCreate}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Crear
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog crear program con file */}
      <Dialog
        open={openModalCreateFile}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Subir programa
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <form onSubmit={handlePostCreateProgramsFile}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <InputControlaVertical
                  text={"Código"}
                  inputType={"text"}
                  inputValue={setCode}
                  value={code}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputControlaVertical
                  text={"Versión"}
                  inputType={"text"}
                  inputValue={setVersion}
                  value={version}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputControlaVertical
                  text={"Elaborado por:"}
                  inputType={"text"}
                  inputValue={setPreparedBy}
                  value={preparedBy}
                  readOnly
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputControlaVertical
                  text={"Fecha:"}
                  inputType={"date"}
                  inputValue={setDateCreated}
                  value={dateCreated}
                  modalType={true}
                  required
                // required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputControlaVertical
                  text={"Tipo de programa:"}
                  inputType={"text"}
                  inputValue={setProgramType}
                  value={progrmapType}
                  readOnly
                  modalType={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputControlaVertical
                  text={"Periodo(año):"}
                  inputType={"text"}
                  inputValue={setYear}
                  value={year}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <InputControlaVertical
                  text={"Título documento"}
                  inputType={"text"}
                  inputValue={setTitulo}
                  value={titulo}
                  modalType={true}
                  required
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Subir Archivo"}
                  inputType={"file"}
                  inputValue={setDocumentUrlFile}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseCModalCreateFile}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Crear
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog para editar archivo programa*/}
      <Dialog
        open={openModalEditFile}
        onClose={handleCloseModalEditFile}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitleControla
          titleText="Actualizar programa cargado"
          functionOnClose={handleCloseModalEditFile}
        />
        <form onSubmit={handlePostUpdatePrograms}>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <InputControlaVertical
                  text={"Código"}
                  inputType={"text"}
                  inputValue={setCode}
                  value={code}
                  modalType={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputControlaVertical
                  text={"Versión"}
                  inputType={"text"}
                  inputValue={setVersion}
                  value={version}
                  modalType={true}
                />
              </Grid>
              {/* <Grid item xs={12} md={6}>
                <InputControlaVertical
                  text={"Elaborado por:"}
                  inputType={"text"}
                  inputValue={setPreparedBy}
                  value={preparedBy}
                  readOnly
                  modalType={true}
                  required
                />
              </Grid> */}
              <Grid item xs={12} md={6}>
                <InputControlaVertical
                  text={"Fecha:"}
                  inputType={"date"}
                  inputValue={setDateCreated}
                  value={dateCreated}
                  modalType={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputControlaVertical
                  text={"Tipo de programa:"}
                  inputType={"text"}
                  inputValue={setProgramType}
                  value={progrmapType}
                  readOnly
                  modalType={true}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputControlaVertical
                  text={"Periodo(año):"}
                  inputType={"text"}
                  inputValue={setYear}
                  value={year}
                  modalType={true}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <InputControlaVertical
                  text={"Título documento"}
                  inputType={"text"}
                  inputValue={setTitulo}
                  value={titulo}
                  modalType={true}
                />
              </Grid>

              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Subir Archivo"}
                  inputType={"file"}
                  inputValue={setDocumentUrlFile}
                  modalType={true}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Stack direction={"row"} spacing={2}>
              <Box>
                <ButtonControla
                  backgroundColor={"#CBCBFF"}
                  textButton={"Cancelar"}
                  backgroundColorHover={"#CBCBFF"}
                  typeButton={"button"}
                  functionOnClick={handleCloseModalEditFile}
                />
              </Box>
              <Box>
                <ButtonControla
                  iconButton={<Add />}
                  backgroundColor={"#169073"}
                  textButton={"Actualizar programa"}
                  backgroundColorHover={"#1BAA88"}
                  typeButton={"submit"}
                />
              </Box>
            </Stack>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}

export default Index;
