import { React } from "react";
import SubtitleText from "../components/text/subtitleText";
import TitleText from "../components/text/titleText";
import Container from "@mui/material/Container";
import DocCard from "../components/cards/docCard";
import Link from "@mui/material/Link";
import SideNav from "../components/sidenav/sideNav";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import MyBaseline from "../api/MyBaseline";
import CardControla from "../components/cards/cardControla";
import BannerButtonControla from "../components/cards/bannerButtonControla";

const colorTitle = "#9191B0";
const colorOneCardOne = "#e7e7e7";

const handleGetBaselineDiagnosis = async () => {
  const response = await MyBaseline.baselineDiagnosis();

  if (response.success === false) {
    //console.log("error no se puedo traer lois diagnósticos")
  } else {
    const archivedBaselines = response.baseLine_archived;
    const activeBaselines = response.baseLine_current;
    const inProgressBaselines = response.baseLine_in_progress;

    if (
      JSON.stringify(archivedBaselines) === "[]" &&
      JSON.stringify(activeBaselines) === "[]" &&
      JSON.stringify(inProgressBaselines) === "[]"
    ) {
      window.location.href = "/baseline/startprocess";
    } else {
      console.log("hay algo lleno");
      window.location.href = "/baseline/diagnosis";
    }
  }
  return response;
};

function Mailbox() {
  return (
    <Box sx={{ display: "flex", backgroundColor: "#F5F4F6" }}>
      <SideNav />
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <SubtitleText
                text={"Buzón de participación"}
                color={colorTitle}
              ></SubtitleText>
            </Grid>
            <Grid item md={12}>
              <TitleText text={"Buzón"} color={colorTitle}></TitleText>
            </Grid>
            <Grid item xs={12} md={12}>
              <BannerButtonControla
                color1={"#ABD82B"}
                color2={"#309B0B"}
                title={"Revisa en pocos minutos la participación de tu equipo "}
                textButton={"Comenzar ahora"}
                image={"/assets/images/doccreationimage.png"}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>        
            <Grid
              item
              xs={12}
              md={3.5}
              sx={{ mt: 3 }}
              style={{ cursor: "pointer" }}
            >
              <Link
                onClick={() => (window.location.href = "/mailbox/congratulations")}
                underline="none"
              >
                <CardControla
                  size={"medium"}
                  color1={colorOneCardOne}
                  color2={colorOneCardOne}
                  text={"Felicitaciones"}
                  img={"/assets/images/banners/mailbox/bannerRecibidos.png"}
                  alt="banner"
                ></CardControla>
              </Link>
            </Grid>
            <Grid
              item
              xs={12}
              md={3.5}
              sx={{ mt: 3 }}
              style={{ cursor: "pointer" }}
            >
              <Link href={"/mailbox/reports"} underline="none">
                <CardControla
                  size={"medium"}
                  color1={colorOneCardOne}
                  color2={colorOneCardOne}
                  text={"Reportes "}
                  img={"/assets/images/banners/mailbox/bannerEnviados.png"}
                  alt="banner"
                ></CardControla>
              </Link>
            </Grid>
            <Grid
              item
              xs={12}
              md={3.5}
              sx={{ mt: 3 }}
              style={{ cursor: "pointer" }}
            >
              <Link href={"/mailbox/consults"} underline="none">
                <CardControla
                  size={"medium"}
                  color1={colorOneCardOne}
                  color2={colorOneCardOne}
                  text={"Consultas y requerimientos "}
                  img={"/assets/images/banners/mailbox/bannerRecibidos.png"}
                  alt="banner"
                ></CardControla>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default Mailbox;
