import MyConfigurationApi from "./MyConfigurationApi";

function getRegistersInductionTranning() {
  const url = "registers_general_induction_tranning";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function createRegistersInductionTranning({ code }) {
  const url = "registers_general_induction_tranning";
  const data = {
    code: code,
    status: "Creado",
    id_registers_induction_tranning_type:'',
    id_registers_induction_tranning_status:'',
    ruc:"",
    date:"",
    number_hour:"",
    name_trainer:""

  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

// // // typos de entrenamiento
function getTypeTranning() {
  const url = "induction_tranning_type";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

// // // actualizar registros de incidentes peligrosos

async function updateRegistersInductionTranning({
  idRegistersInductionTranning,
  code,
  id_registers_induction_tranning_type,ruc,date,number_hour,name_trainer,register_status
}) {
  const url = "registers_general_induction_tranning/" + idRegistersInductionTranning;
  const data = {
    "code": code,
    "id_registers_induction_tranning_type":id_registers_induction_tranning_type,
    "ruc":ruc,
    "date":date,
    "number_hour":number_hour,
    "name_trainer":name_trainer,
    register_status:register_status

    
  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function editRegistersInductionTranning({ idRegistersInductionTranning }) {
  const url = "registers_general_induction_tranning/" + idRegistersInductionTranning;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

// // TRABAJADOR INVOLUCRADO
// // registro de trabajadores involucrados
async function createWorkersInvolved({ idRegistersInductionTranning, id_users, area, observation, nameResponsibleInput,responsibleDocumentInput}) {
  const url = "sec/induction_tranning_workers_involved/" + idRegistersInductionTranning;
  const data = {   
    "area": area,
    "observation": observation,   
    "id_users": id_users,
    "name_user_responsible":nameResponsibleInput,
    "document_user_responsible":responsibleDocumentInput,
    "firm":0,
    "firm_date":''    
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

// // jalar registros de trabajadores involucrados
function getWorkersInvolved({ idRegistersInductionTranning }) {
    const url = "sec/induction_tranning_workers_involved/" + idRegistersInductionTranning;
    return MyConfigurationApi.connectFormApi({
      dataJson: null,
      method: "GET",
      url: url,
    });
  }

  // // // mandar para la firma
async function updateSignWorkerInvolved({idResponsible, id_users}) {  
  const url = "sec/induction_tranning_workers_involved/detail/" + idResponsible;

  const data = {  
    "id_users": id_users,
    "firm": 1,
    "firm_date": new Date()
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}
//   // eliminar trabajador involucrado
  async function deleteWorkerInvolved({ idWorkerInvolved }) {
    const url ="sec/induction_tranning_workers_involved/detail/"+ idWorkerInvolved;
    return MyConfigurationApi.connectFormApi({
      dataJson: null,
      method: "DELETE",
      url: url,
    });
  }

// TRABAJADORES RESPONSABLES
//get de trabajadores responsables
function getResponsibleWorkers({ idRegistersInductionTranning }) {
  const url = idRegistersInductionTranning +"/responsible";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

// // crear trabajadores responsables
async function createResponsibleWorkers({
  idRegistersInductionTranning,
  id_users
}) {
  const url = idRegistersInductionTranning +"/responsible";
  const data = {
    id_users: id_users,
    firm: 0,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}
  
// // // mandar para la firma
async function updateResponsibleWorkers({idResponsible, id_users}) {  
console.log(idResponsible);
  const url = "responsible/detail/" + idResponsible;

  const data = {  
    "id_users": id_users,
    "firm": 1,
    "firm_date": null
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

// //   // eliminar  
  async function deleteResponsibleWorker({ idResponsible }) {
    const url ="responsible/detail/"+ idResponsible;
    return MyConfigurationApi.connectFormApi({
      dataJson: null,
      method: "DELETE",
      url: url,
    });
  }
  // cierre de registro
  async function updateRegistersInductionTranningStatus({idRegistersInductionTranning}) {  
      const url = idRegistersInductionTranning+"/status/end";   
      return MyConfigurationApi.connectFormApi({
        dataJson: null,
        method: "PUT",
        url: url,
      });
    }
const MyInductionTranning = {
    getRegistersInductionTranning,
    createRegistersInductionTranning,
    getTypeTranning,
    updateRegistersInductionTranning,
    editRegistersInductionTranning,
  

    // // trabajador involucrado
    createWorkersInvolved,
    getWorkersInvolved,
    deleteWorkerInvolved, 
    updateSignWorkerInvolved,

    // responsable
    getResponsibleWorkers,
    createResponsibleWorkers,
    deleteResponsibleWorker,
    updateResponsibleWorkers,

    // cierre de registro
    updateRegistersInductionTranningStatus
};
export default MyInductionTranning;
