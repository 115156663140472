import { React, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Grid, Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { BorderColorOutlined, Delete } from '@mui/icons-material';
import SubtitleText from '../../components/text/subtitleText';
import Container from '@mui/material/Container';
import SideNav from "../../components/sidenav/sideNav";
import DocBanner from '../../components/cards/docBanner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import InputControlaVertical from '../../components/textfields/inputControlaVertical';
import ButtonIconControla from '../../components/buttons/buttonIconControla';
import MyEpps from '../../api/MyEpps';

const colorTitle = "#9191B0";

function Categories() {

    const [rowsCategories, setRowsCategories] = useState([]);

    const handleGetEppsCategories = async () => {
        const response = await MyEpps.categories();

        if (response.success === false) {
        } else {
            setRowsCategories(response);
        }
        return response;
    };

    useEffect(() => {
        handleGetEppsCategories();
    }, []);

    const [idCategories, setIdCategories] = useState(null);

    const [name, setName] = useState(null);

    const [openCreateCategories, setOpenCreateCategories] = useState(false);

    const handleClickOpenCreateCategories = () => {
        setOpenCreateCategories(true);
    };

    const handleCloseCreateCategories = () => {
        setOpenCreateCategories(false);
    };

    const [openEditCategories, setOpenEditCategories] = useState(false);

    const handleClickOpenEditCategories = (idCategories, name) => {
        setIdCategories(idCategories);
        setName(name);
        setOpenEditCategories(true);
    };

    const handleCloseEditCategories = () => {
        setOpenEditCategories(false);
    };

    const [openDeleteCategories, setOpenDeleteCategories] = useState(false);

    const handleClickOpenDeleteCategories = (idCategories) => {
        setIdCategories(idCategories);
        setOpenDeleteCategories(true);
    };

    const handleCloseDeleteCategories = () => {
        setOpenDeleteCategories(false);
    };

    const handleStoreCategories = async () => {
        const response = await MyEpps.createEppsCategories({ name: name });

        if (response.success === false) {
        } else {
            handleGetEppsCategories();
            handleCloseCreateCategories();
        }
        return response;
    };

    const handleUpdateCategories = async () => {
        const response = await MyEpps.updateEppsCategories({ idEppsCategories: idCategories, name: name });

        if (response.success === false) {
        } else {
            handleGetEppsCategories();
            handleCloseEditCategories();
        }
        return response;
    };

    const handleDeleteCategories = async () => {
        const response = await MyEpps.deleteEppsCategories({ idEppsCategories: idCategories });

        if (response.success === false) {
        } else {
            handleGetEppsCategories();
            handleCloseDeleteCategories();
        }
        return response;
    };




    return (

        <Box sx={{ display: "flex", height: '100%' }}>
            <SideNav />

            <Container>

                <Box sx={{ flexGrow: 1 }}>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <SubtitleText text={"Administrador"} color={colorTitle} ></SubtitleText>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <DocBanner color2='#9d00ff' color1='#001156' text='Categorias de los Epps' image={""} />
                        </Grid>
                    </Grid>

                    <Stack direction="row" spacing={1} sx={{ mt: 2 }} >
                        <Button
                            variant="contained"
                            onClick={() => handleClickOpenCreateCategories()}
                        >Agregar Categoria
                        </Button>
                        <Button
                            href='/admin/epps'
                            variant="contained"
                        >Epps
                        </Button>
                    </Stack>


                    <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Paper sx={{ overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: 400 }}>
                                    <Table stickyHeader sx={{ width: '100%', minWidth: '700px' }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Categorias</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody   >
                                            {rowsCategories
                                                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((rowsCategories) => (
                                                    <TableRow hover
                                                        key={rowsCategories.id_epps_categories}
                                                    >
                                                        {/* <TableCell align="center"><Checkbox /></TableCell> */}

                                                        <TableCell>
                                                            {rowsCategories.category}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Stack
                                                                direction="row"
                                                                justifyContent="end"
                                                                alignItems="center"
                                                                spacing={1}
                                                            >
                                                                <ButtonIconControla icon={<BorderColorOutlined sx={{ color: 'white' }} />} backgroundColor={'#169073'} textTooltip='Editar' functionOnClick={() => handleClickOpenEditCategories(rowsCategories.id_epps_categories, rowsCategories.category)} />
                                                                <ButtonIconControla icon={<Delete sx={{ color: 'white' }} />} backgroundColor={'#EB5757'} textTooltip='Eliminar' functionOnClick={() => handleClickOpenDeleteCategories(rowsCategories.id_epps_categories)} />
                                                            </Stack>
                                                        </TableCell>

                                                    </TableRow>
                                                ))}
                                        </TableBody>

                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Grid>
                    </Grid>
                    <Dialog open={openCreateCategories} onClose={handleCloseCreateCategories} maxWidth='sm' fullWidth={true}>
                        <DialogTitle sx={{
                            color: '#1638F2', fontWeight: 'bold',
                        }}>Crear categoria de epps</DialogTitle>
                        <DialogContent>
                            <InputControlaVertical text={'Nombre:'} inputType={'text'} inputValue={setName} modalType={true} />

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseCreateCategories}>Cancel</Button>
                            <Button onClick={handleStoreCategories}>Crear</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={openEditCategories} onClose={handleCloseEditCategories} maxWidth='sm' fullWidth={true}>
                        <DialogTitle sx={{
                            color: '#1638F2', fontWeight: 'bold',
                        }}>Crear categoria de epps</DialogTitle>
                        <DialogContent>
                            <InputControlaVertical text={'Nombre:'} inputType={'text'} value={name} inputValue={setName} modalType={true} />

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseEditCategories}>Cancel</Button>
                            <Button onClick={handleUpdateCategories}>Actualizar</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={openDeleteCategories} onClose={handleCloseDeleteCategories} maxWidth='sm' fullWidth={true}>
                        <DialogTitle sx={{
                            color: '#1638F2', fontWeight: 'bold',
                        }}>Eliminar categoría de epps</DialogTitle>
                        <DialogContent>
                            <Typography >
                                Si eliminar esta categoría tambien eliminaras todos los epps que le pertenezcan.
                            </Typography>
                            <Typography >
                                ¿Deseas continuar?.
                            </Typography>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDeleteCategories}>Cancelar</Button>
                            <Button onClick={handleDeleteCategories}>Si, eliminar</Button>
                        </DialogActions>
                    </Dialog>

                </Box>
            </Container >


        </Box >




    );

}

export default Categories;

