import * as React from 'react';

import SubtitleText from '../../../components/text/subtitleText';
import Container from '@mui/material/Container';
import SideNav from "../../../components/sidenav/sideNav";
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import '../../../index.css';
import BannerControla from "../../../components/cards/bannerControla";
import CardControla from "../../../components/cards/cardControla";


const colorTitle = "#9191B0";


function Registers() {
  return (

    <Box sx={{ display: "flex" }}>
      <SideNav returnBack="/documentation/registers" />

      <Container>

        <Box sx={{ flexGrow: 1 }}>

          <Grid container>
            {/* <Grid item xs={12} md={12}>
              <SubtitleText text={"Documentación"} color={colorTitle}></SubtitleText>
            </Grid> */}
            <Grid item xs={12} md={12}>
              <BannerControla image='/assets/images/banners/documents/bannermypes.png' color2='#2F67BC' color1='#8FBCFF' text='Registros para pequeñas empresas' />

            </Grid>
          </Grid>

          <Grid container spacing={2}>
           
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/registers/mypes/accidentsoccupationalsincidents"}
                text={"Registro para pequeñas Empresas General"}
                img={"/assets/images/banners/documents/mypesgeneral.png"}
              />
            </Grid> 
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/registers/mypes/occupationaldiseases"}
                text={"Enfermedades ocupacionales"}
                img={"/assets/images/banners/documents/occupationaldiseases.png"}
              />
            </Grid>                     
          </Grid>
        </Box>
      </Container>

    </Box>

  );

}

export default Registers;

