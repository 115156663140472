
function getSession(key) {
    const value = window.sessionStorage.getItem(key);
    if (!value) {
        return null;
    }
    return JSON.parse(value);
}

function setSession(key, value) {
    window.sessionStorage.setItem(key, JSON.stringify(value))
}

function removeSession(key) {
    sessionStorage.removeItem(key);
}

function clearSession() {
    window.sessionStorage.clear();
}

const MySessionStorage = {
    getSession, setSession, removeSession, clearSession
};

export default MySessionStorage;