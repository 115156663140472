import { React, useState } from 'react'
import Box from '@mui/material/Box';
import { Dialog, DialogActions, IconButton, DialogContent, DialogTitle, Button, Grid, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { styled } from '@mui/system';
import {Close} from '@mui/icons-material';
import SideNav from '../components/sidenav/sideNav';
import SubtitleText from '../components/text/subtitleText';
import TitleText from '../components/text/titleText';
import InputControlaVertical from '../components/textfields/inputControlaVertical';
import MyBaseline from '../api/MyBaseline';

const grey = "#9191B0";
const green = "#2C8C75";
const lilac = "#D1D1E9";
const purple = "#8B8BD8";

const StyledDataButton = styled(Button)({
    textTransform: 'none',
    borderRadius: 14,
    backgroundColor: green,
    color: "white",
    width: "100%"
});

const StyledCancelButton = styled(Button)({
    textTransform: 'none',
    borderRadius: 14,
    backgroundColor: lilac,
    color: purple,
    width: "100%"
});

const AddButton = styled(Button)({
    textTransform: 'none',
    borderRadius: 6,
    backgroundColor: "#ffc107",
    color: 'black'
});

function BaselineStartProcess() {

    const [name, setName] = useState(null);
    const [document_url, setDocument_url] = useState(null);

    const [openHaveDiagnosticModal, setOpenHaveDiagnosticModal] = useState(false);
    const handleCloseHaveDiagnosticModal = () => setOpenHaveDiagnosticModal(false);
    const handleOpenHaveDiagnosticModal = () => setOpenHaveDiagnosticModal(true);

    const [openStartBaselineProcess, setOpenStartBaselineProcess] = useState(false);
    const handleCloseStartBaselineProcess = () => setOpenStartBaselineProcess(false);
    const handelOpenStartBaselineProcess = () => setOpenStartBaselineProcess(true);

    const handlePostCreateBaseline = async () => {
        const response = await MyBaseline.storeBaseline({ name, document_url });

        if (response.success === true) {
            console.log("Linea Base creada correctamente");
            console.log(response)
        } else {
            console.log("Error, línea base no se ha creado");
            console.log(response);
        }
        window.location.href = 'diagnosis';
    }

    const handlePostStartBaselineProcess = async () => {
        const response = await MyBaseline.storeBaseline ({name});
        console.log("Proceso iniciado");
        console.log(response);
        window.location.href = 'answers?id_baseline='+response.id_baseline;
    }

    return (
        <>
            <Box sx={{ display: "flex", height: '100%' }}>
                <SideNav />
                <Container>
                    <Box sx={{ flexGrow: 1 }}>
                            <Grid container rowSpacing={3} columnSpacing={6} justifyContent="center" alignItems="center" paddingBottom="6em">
                                <Grid item md={12}>
                                    <SubtitleText
                                        text={"Línea Base"}
                                        color={grey}>
                                    </SubtitleText>
                                    <TitleText
                                        text={"Línea Base"}>
                                    </TitleText>
                                </Grid>
                                <Grid item>
                                    <TitleText text={"¡Bienvenido Empresa SAC!"} />
                                </Grid>
                                <Grid item>
                                    <Typography>
                                        A continuación comenzaremos con el proceso de la línea base. Recuerda que este es eld ocumento por el cual podrás contar con el estado inicial de tu sistema de gestión en seguridad y salud ocupacional.
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <img src='\assets\images\comitteeProcess.png' alt='Proceso de comité'></img>
                                </Grid>
                            </Grid>
                            <Grid container item justifyContent="right" gap={2} paddingBottom="4em">
                                <Grid item md={3}>
                                    <StyledCancelButton variant='contained'
                                        disableElevation
                                        onClick={handleOpenHaveDiagnosticModal}
                                    >Ya tengo diagnóstico</StyledCancelButton>
                                </Grid>
                                <Grid item md={3}>
                                    <StyledDataButton
                                        disabledElevation
                                        onClick={handelOpenStartBaselineProcess}
                                    >Iniciar proceso de linea base</StyledDataButton>
                                </Grid>
                            </Grid>
                        <Dialog
                            open={openHaveDiagnosticModal}
                            onClose={handleCloseHaveDiagnosticModal}
                            maxWidth="md"
                            PaperProps={{
                                style: {
                                    backgroundColor: "#F5F5F5",
                                    width: "40%",
                                    padding: "10px",
                                    borderRadius: "16px"
                                },
                            }}
                            fullWidth={true}
                        >
                            <DialogTitle >
                                <TitleText text={"Cargar Línea Base"} />
                                <IconButton
                                    aria-label="close"
                                    onClick={handleCloseHaveDiagnosticModal}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey
                                        [500],
                                    }}
                                ><Close /></IconButton>
                            </DialogTitle>
                            <DialogContent>
                                <Grid container rowGap={2} >
                                    <Grid item md="12">
                                        <InputControlaVertical
                                            text="Nombre de línea base"
                                            inputType={"text"}
                                            inputValue={setName}
                                            modalType={false}
                                        />
                                    </Grid>
                                    <Grid item md="12">
                                        <InputControlaVertical
                                            text={"Archivo"}
                                            inputType={"file"}
                                            inputValue={setDocument_url}
                                            modalType={false}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>

                                <AddButton
                                    onClick={handlePostCreateBaseline}
                                >
                                    Cargar
                                </AddButton>

                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={openStartBaselineProcess}
                            onClose={handleCloseStartBaselineProcess}
                            maxWidth="md"
                            PaperProps={{
                                style: {
                                    backgroundColor: "#F5F5F5",
                                    width: "40%",
                                    padding: "10px",
                                    borderRadius: "16px"
                                },
                            }}
                            fullWidth={true}
                        >
                            <DialogTitle>
                                <TitleText text={"Iniciar Proceso Línea Base"} />
                                <IconButton
                                    aria-label="close"
                                    onClick={handleCloseStartBaselineProcess}
                                    sx={{
                                        position: 'absolute',
                                        right: 8,
                                        top: 8,
                                        color: (theme) => theme.palette.grey
                                        [500],
                                    }}
                                ><Close /></IconButton>
                            </DialogTitle>
                            <DialogContent>
                                <Grid container >
                                    <Grid item md="12">
                                        <InputControlaVertical
                                            text="Nombre de línea base"
                                            inputType={"text"}
                                            inputValue={setName}
                                            modalType={false}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <AddButton
                                    onClick={handlePostStartBaselineProcess}
                                >
                                    Iniciar Proceso
                                </AddButton>
                            </DialogActions>
                        </Dialog>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default BaselineStartProcess