// colocar lo de Workaccidents.jsx
import * as React from "react";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Add, Delete, Publish, Send, VisibilityOutlined } from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SideNav from "../../components/sidenav/sideNav";
import SubtitleText from "../../components/text/subtitleText";
import BannerControla from "../../components/cards/bannerControla";
import ButtonControla from "../../components/buttons/buttonController";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import MyRit from "../../api/MyRit";
import ButtonIconControla from "../../components/buttons/buttonIconControla";
import LoadingControla from "../../components/load/loadingControla";
import DialogConfirmControla from "../../components/dialog/dialogConfirmControla";
import ArchiveIcon from "@mui/icons-material/Archive";
const colorTitle = "#9191B0";

function Index() {
  // alerts
  const [openAlert, setOpenAlert] = React.useState(false);
  const [openLoading, setOpenLoading] = React.useState(false);
  const [messageAlert, setMessageAlert] = React.useState("");
  const [typeAlert, setTypeAlert] = React.useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  //fin de alerts
  // Dialogos de confirmacion INICIO
  const [openModalConfirm, setOpenModalConfirm] = React.useState(false);
  const [titleText, setTitleText] = React.useState("");
  const [contentText, setContentText] = React.useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = React.useState("");
  const [buttonActionType, setButtonActionType] = React.useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setIdRit(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    // eslint-disable-next-line default-case
    switch (buttonActionType) {
      case "deleteRit":
        handleDeleteRit();
        break;
      case "archiveRit":
        handleArchiveRit();
        break;
      case "dearchiveRit":
        handleDeArchiveRit();
        break;
      case "publishRit":
        handlePublishRit();
        break;
    }
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN

  const [openModalAddMatrix, setOpenModalAddMatrix] = React.useState(false);
  const handleOpenModalAddMatrix = () => {
    setName("");
    setOpenModalAddMatrix(true);
  };

  const [rit, setRit] = React.useState([]);
  const [name, setName] = React.useState("");
  const [file, setFile] = React.useState("");

  const handleAddMatrix = async (event) => {
    event.preventDefault();
    handleOpenLoading();

    const response = await MyRit.createRit({ name, file });
    handleGetRit();
    setOpenModalAddMatrix(false);
    handleOpenAlert("Se crearon los datos de forma correcta", "success");
    return response;
  };

  const handleGetRit = async () => {
    handleOpenLoading();
    const response = await MyRit.getRit();
    setRit(response);
    console.log(response);
    setOpenLoading(false);
    return response;
  };

  // eliminar
  const [idRit, setIdRit] = React.useState();

  const handleDeleteRit = async () => {
    handleOpenLoading();
    const response = await MyRit.deleteRit({
      idRit: idRit,
    });
    handleGetRit();
    handleOpenAlert("Los datos fueron eliminados de forma correcta", "success");
    return response;
  };

  //
  const params = new URLSearchParams(window.location.search);
  const idTabs = parseInt(
    params.get("idTabs") !== null ? params.get("idTabs") : 0
  );
  const [documentType, setDocumentType] = React.useState(
    idTabs !== undefined && idTabs !== null && idTabs !== "" ? idTabs : 0
  );

  const handleChangeDocumentType = (event, newValue) => {
    setDocumentType(newValue);
  };

  const handleArchiveRit = async () => {
    handleOpenLoading();
    const response = await MyRit.changeRitStatus({
      idRit: idRit,
      registerStatus: 5,
    });
    handleGetRit();
  };

  const handleDeArchiveRit = async () => {
    handleOpenLoading();
    const response = await MyRit.changeRitStatus({
      idRit: idRit,
      registerStatus: 6,
    });
    handleGetRit();
  };

  const handlePublishRit = async () => {
    const response = await MyRit.changeRitStatus({
      idRit: idRit,
      registerStatus: 4,
    });
    handleGetRit();
  };

  React.useEffect(() => {
    handleGetRit();
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="/documentation" />
      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
              <BannerControla
                image="/assets/images/banners/documents/bannerritmain.png"
                color2="#2F67BC"
                color1="#8FBCFF"
                text="RIT- Reglamento Interno de Trabajo"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={3}>
              <ButtonControla
                iconButton={<Add sx={{ color: "white" }} />}
                backgroundColor={"#169073"}
                backgroundColorHover={"#1BAA88"}
                textButton={"Agregar nuevo RIT"}
                functionOnClick={handleOpenModalAddMatrix}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Grid item md={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          // align="center"
                          sx={{
                            color: "#1638F2",
                            fontSize: "18px",
                            width: "50%",
                          }}
                        >
                          Nombre del documento
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#1638F2",
                            fontSize: "18px",
                            width: "30%",
                          }}
                        >
                          Estado
                        </TableCell>
                        <TableCell colSpan={4}>
                          <Stack
                            direction={"row"}
                            justifyContent={"end"}
                            alignItems={"center"}
                          >
                            <Tabs
                              value={documentType}
                              onChange={handleChangeDocumentType}
                              aria-label="Tipos de documento"
                            >
                              {/* <Tab label={"Activos"} />
                              <Tab label={"Archivados"} /> */}
                            </Tabs>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rit.map((row) => (
                        <TableRow
                          key={row.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            padding: "4px",
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {row.register_status === 1
                              ? "Creado"
                              : row.register_status === 4 ? "Publicado" : ""}
                          </TableCell>

                          <TableCell align="right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                            >
                              <ButtonIconControla
                                ////todos los roles
                                icon={
                                  <VisibilityOutlined sx={{ color: "white" }} />
                                }
                                backgroundColor={"#305AD9"}
                                backgroundColorHover={"#0E4AFF"}
                                textTooltip={"Ver"}
                                directionUrl={row.file}
                                directionTarget={"_blank"}
                              />
                              {/* <ButtonIconControla
                                icon={<ArchiveIcon sx={{ color: "white" }} />}
                                backgroundColor={"#F7C80B"}
                                backgroundColorHover={"#F7C80B"}
                                textTooltip={"Archivar"}
                                // directionUrl={row.file}
                                // directionTarget={"_blank"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id,
                                    "¿Está seguro de archivar este RIT?",
                                    "Puede recuperar el documento en la sección de Archivados",
                                    "Sí, archivar",
                                    "archiveRit"
                                  );
                                }}
                              /> */}
                              {row.register_status === 1 ? (
                                <>
                                  <ButtonIconControla
                                    roles={[1, 7, 8, 15]}
                                    icon={<Publish sx={{ color: "white" }} />}
                                    backgroundColor={"#169073"}
                                    backgroundColorHover={"#1AA483"}
                                    textTooltip={"Publicar"}
                                    functionOnClick={() => {
                                      handleOpenModalConfirm(
                                        row.id,
                                        "¿Esta seguro de publicar?",
                                        'Una vez publicado no podra revertir la operación del documento:  "' +
                                        row.name +
                                        '"',
                                        "Si, publicar",
                                        "publishRit"
                                      );
                                    }}
                                  /> <ButtonIconControla
                                    icon={<Delete sx={{ color: "white" }} />}
                                    backgroundColor={"#EB5757"}
                                    backgroundColorHover={"#FF4040"}
                                    textTooltip={"Eliminar"}
                                    functionOnClick={() => {
                                      handleOpenModalConfirm(
                                        row.id,
                                        "¿Está seguro de eliminar los datos del RIT?",
                                        "Una vez eliminado no se podra recuperar los datos del RIT" +
                                        row.name,
                                        "Si, eliminar",
                                        "deleteRit"
                                      );
                                    }}
                                  /> </>
                              ) : (
                                <></>
                              )}


                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>

                    {/* <TableBody
                      sx={{ display: documentType !== 0 ? "none" : "" }}
                    >
                      {rit.map((row) =>
                        row.register_status == 1 ? (
                          <TableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              padding: "4px",
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.register_status === 1
                                ? "Creado"
                                : "Finalizado"}
                            </TableCell>

                            <TableCell align="right">
                              <Stack
                                direction="row"
                                justifyContent="end"
                                alignItems="center"
                              >
                                <ButtonIconControla
                                  ////todos los roles
                                  icon={
                                    <VisibilityOutlined
                                      sx={{ color: "white" }}
                                    />
                                  }
                                  backgroundColor={"#305AD9"}
                                  backgroundColorHover={"#0E4AFF"}
                                  textTooltip={"Ver"}
                                  directionUrl={row.file}
                                  directionTarget={"_blank"}
                                />
                                <ButtonIconControla
                                  icon={<ArchiveIcon sx={{ color: "white" }} />}
                                  backgroundColor={"#F7C80B"}
                                  backgroundColorHover={"#F7C80B"}
                                  textTooltip={"Archivar"}
                                  // directionUrl={row.file}
                                  // directionTarget={"_blank"}
                                  functionOnClick={() => {
                                    handleOpenModalConfirm(
                                      row.id,
                                      "¿Está seguro de archivar este RIT?",
                                      "Puede recuperar el documento en la sección de Archivados",
                                      "Sí, archivar",
                                      "archiveRit"
                                    );
                                  }}
                                />
                                <ButtonIconControla
                                  icon={<Delete sx={{ color: "white" }} />}
                                  backgroundColor={"#EB5757"}
                                  backgroundColorHover={"#FF4040"}
                                  textTooltip={"Eliminar"}
                                  functionOnClick={() => {
                                    handleOpenModalConfirm(
                                      row.id,
                                      "¿Está seguro de eliminar los datos del RIT?",
                                      "Una vez eliminado no se podra recuperar los datos del RIT" +
                                        row.name,
                                      "Si, eliminar",
                                      "deleteRit"
                                    );
                                  }}
                                />
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )
                      )}
                    </TableBody> */}

                    {/* <TableBody
                      sx={{ display: documentType !== 1 ? "none" : "" }}
                    >
                      {rit.map((row) =>
                        row.register_status == 5 ? (
                          <TableRow
                            key={row.id}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                              padding: "4px",
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.register_status === 1
                                ? "Creado"
                                : "Finalizado"}
                            </TableCell>

                            <TableCell align="right">
                              <Stack
                                direction="row"
                                justifyContent="end"
                                alignItems="center"
                              >
                                <ButtonIconControla
                                  ////todos los roles
                                  icon={
                                    <VisibilityOutlined
                                      sx={{ color: "white" }}
                                    />
                                  }
                                  backgroundColor={"#305AD9"}
                                  backgroundColorHover={"#0E4AFF"}
                                  textTooltip={"Ver"}
                                  directionUrl={row.file}
                                  directionTarget={"_blank"}
                                />
                                <ButtonIconControla
                                icon={<ArchiveIcon sx={{ color: "white" }} />}
                                backgroundColor={"#F7C80B"}
                                backgroundColorHover={"#F7C80B"}
                                textTooltip={"Archivar"}
                                // directionUrl={row.file}
                                // directionTarget={"_blank"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id,
                                    "¿Está seguro de desarchivar este RIT?",
                                    "El documento volverá a la sección de Activos",
                                    "Sí, desarchivar",
                                    "deArchiveRit"
                                  );
                                }}
                              />
                                <ButtonIconControla
                                  icon={<Delete sx={{ color: "white" }} />}
                                  backgroundColor={"#EB5757"}
                                  backgroundColorHover={"#FF4040"}
                                  textTooltip={"Eliminar"}
                                  functionOnClick={() => {
                                    handleOpenModalConfirm(
                                      row.id,
                                      "¿Está seguro de eliminar los datos del RIT",
                                      "Una vez eliminado no se podra recuperar los datos del RIT " +
                                        row.name,
                                      "Si, eliminar",
                                      "deleteRit"
                                    );
                                  }}
                                />
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ) : (
                          ""
                        )
                      )}
                    </TableBody> */}
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Dialog
        open={openModalAddMatrix}
        onClose={() => setOpenModalAddMatrix(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Crear nuevo RIT
          </Typography>
        </DialogTitle>
        <form onSubmit={handleAddMatrix}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Nombre:"}
                  inputType={"text"}
                  inputValue={setName}
                  value={name}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Archivo"}
                  inputType={"file"}
                  inputValue={setFile}
                  value={file}
                  modalType={true}
                  required
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalAddMatrix(false)}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Crear
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}

export default Index;
