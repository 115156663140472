import {
  Box,
  Container,
  Grid,
  InputLabel,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { React, useEffect, useState } from "react";
import SideNav from "../../../../components/sidenav/sideNav";
import TitleText from "../../../../components/text/titleText";
import SubtitleText from "../../../../components/text/subtitleText";
import CompanyLogoControla from "../../../../components/images/companyLogoControla";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import ButtonControla from "../../../../components/buttons/buttonController";
import {
  Add,
  ArrowBack,
  Delete,
  VisibilityOutlined,
} from "@mui/icons-material";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import InputControlaVerticalIcon from "../../../../components/textfields/inputControlaVerticalIcon";

//colors
const colorTitle = "#9191B0";
const blue = "#034AFF";

function Edit() {
  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="/documentation/registers/generals/accidents" />
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
              <TitleText text={"Registro de Auditorías"}></TitleText>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <form
          // onSubmit={handleUpdateRegistersAccidents}
          >
            {/* tabla para mostrar accidents workers */}
            <Grid container sx={{ marginTop: "4px" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Nro registro"}
                  inputType={"text"}
                // inputValue={setCode}
                // value={code}
                />
              </Grid>
              <Grid item xs={8} sm={8} md={8} lg={8} xl={8} mt={2}>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Auditores y registros
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                textAlign="right"
                mt={2}
              >
                {/* {registerStatus === true ? (
                <ButtonControla
                  iconButton={<Add sx={{ color: "white" }} />}
                  backgroundColor={"#169073"}
                  backgroundColorHover={"#1BAA88"}
                  textButton={"Agregar auditores"}
                  // functionOnClick={handleOpenModalAddAuditor}
                />
              ) : (
                ""
              )} */}
              </Grid>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nombre y Apellido
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        N° de registro
                      </TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody
                  // sx={{ display: documentType !== 1 ? "none" : "" }}
                  >
                    {/* {accidentsWorkers.map((rowsAccidentsWorkers, key) => ( */}
                    <TableRow
                      // key={key}
                      hover
                      // key={rowsAccidentsWorkers.id_users}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {/* {rowsAccidentsWorkers.name} */}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {/* {rowsAccidentsWorkers.register_number} */}
                      </TableCell>

                      <TableCell align="right">
                        <Stack
                          direction="row"
                          justifyContent="end"
                          alignItems="center"
                          spacing={1}
                        >
                          {/* {registerStatus === true ? (
                            <ButtonIconControla
                              icon={<Delete sx={{ color: "white" }} />}
                              backgroundColor={"#EB5757"}
                              backgroundColorHover={"#FF4040"}
                              textTooltip={"Eliminar"}
                              // functionOnClick={() => {
                              //   handleOpenModalConfirm(
                              //     rowsAccidentsWorkers.id,
                              //     "¿Esta seguro de eliminar los datos del trabajador?",
                              //     "Una vez eliminado no se podra recuperar los datos del trabajador",
                              //     "Si, Eliminar",
                              //     "deleteAuditors"
                              //   );
                              // }}
                            />
                          ) : (
                            ""
                          )} */}
                        </Stack>
                      </TableCell>
                    </TableRow>
                    {/* ))} */}
                  </TableBody>
                </Table>
              </TableContainer>

              <Grid item xs={8} sm={8} md={8} lg={8} xl={8} mt={2}>
                {/* <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Auditores y registros
                </InputLabel> */}
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                textAlign="right"
                mt={2}
              >
                {/* {registerStatus === true ? (
                <ButtonControla
                  iconButton={<Add sx={{ color: "white" }} />}
                  backgroundColor={"#169073"}
                  backgroundColorHover={"#1BAA88"}
                  textButton={"Agregar datos"}
                  // functionOnClick={handleOpenModalAddAudit}
                />
              ) : (
                ""
              )} */}
              </Grid>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha de auditoria
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Procesos auditados
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nombre de los responsables de los procesos auditados
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody
                  // sx={{ display: documentType !== 1 ? "none" : "" }}
                  >
                    {/* {responsibleAuditors.map((rowsAccidentsWorkers) => ( */}
                    <TableRow
                      hover
                      // key={rowsAccidentsWorkers.id_users}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {/* {rowsAccidentsWorkers.date} */}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {/* {rowsAccidentsWorkers.responsible_name} */}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {/* {rowsAccidentsWorkers.audit_processes} */}
                      </TableCell>

                      <TableCell align="right">
                        <Stack
                          direction="row"
                          justifyContent="end"
                          alignItems="center"
                          spacing={1}
                        >
                          <ButtonIconControla
                            icon={<Delete sx={{ color: "white" }} />}
                            backgroundColor={"#EB5757"}
                            backgroundColorHover={"#FF4040"}
                            textTooltip={"Eliminar"}
                          // functionOnClick={() => {
                          //   handleOpenModalConfirm(
                          //     rowsAccidentsWorkers.id,
                          //     "¿Esta seguro de eliminar los datos del trabajador?",
                          //     "Una vez eliminado no se podra recuperar los datos del trabajador",
                          //     "Si, Eliminar",
                          //     "deleteWorkers"
                          //   );
                          // }}
                          />
                        </Stack>
                      </TableCell>
                    </TableRow>
                    {/* ))} */}
                  </TableBody>
                </Table>
              </TableContainer>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={6} md={3} lg={3} xl={3} mt={3}>
                  <InputControlaVertical
                    text={"Nro. De no conformidades"}
                    inputType={"number"}
                  // inputValue={setCode}
                  // value={code}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SubtitleText text={"Información a adjuntar"} color={blue} />
                </Grid>

                <Grid item xs={11} md={11}>
                  <InputControlaVerticalIcon
                    text={"a) Informe de auditoría"}
                    inputType={"file"}
                    // inputValue={setDocumentUrl1}
                    textTooltip={
                      "Indicar los hallazgos encontrados, así como no conformidades, observaciones, entre otros, con la respectiva firma del auditor o auditores."
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ButtonIconControla
                    icon={<VisibilityOutlined sx={{ color: "white" }} />}
                    backgroundColor={"#305AD9"}
                    backgroundColorHover={"#0E4AFF"}
                    textTooltip={"Ver documento"}
                    // directionUrl={documentUrl1}
                    directionTarget={"_blank"}
                  // functionOnClick={() => ""}
                  />
                </Grid>
                <Grid item xs={11} md={11}>
                  <InputControlaVerticalIcon
                    text={
                      "b) Plan de acción para cierre de no conformidades (posterior a la auditoría)."
                    }
                    inputType={"file"}
                    // inputValue={setDocumentUrl1}
                    textTooltip={
                      "El plan de acción contiene la descripción de las causas que originaron cada no conformidad, propuesta de las medidas correctivas para cada no conformidad, responsable de implementación, fecha de ejecución, estado de la acción correctiva."
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs={1}
                  md={1}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <ButtonIconControla
                    icon={<VisibilityOutlined sx={{ color: "white" }} />}
                    backgroundColor={"#305AD9"}
                    backgroundColorHover={"#0E4AFF"}
                    textTooltip={"Ver documento"}
                    // directionUrl={documentUrl1}
                    directionTarget={"_blank"}
                  // functionOnClick={() => ""}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <SubtitleText
                    text={
                      "Modelo de encabezados para el plan de acción de cierre de no conformidades"
                    }
                    color={blue}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <InputControlaVertical
                    text={"Descripción de la no conformidad"}
                    inputType={"textArea"}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <InputControlaVertical
                    text={"Causas de la no conformidad"}
                    inputType={"textArea"}
                  />
                </Grid>
              </Grid>

              {/********************************  medidas correctivas  ********************************************** */}
              <Grid container>
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  sx={{ marginTop: 3, marginBottom: 1 }}
                >
                  <Box>
                    <InputLabel
                      sx={{
                        padding: "8px",
                        fontSize: "18px",
                        color: "#305AD9",
                        fontWeight: "bold",
                      }}
                    >
                      Medidas correctivas
                    </InputLabel>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      sx={{ color: "#F2994A", ml: 1 }}
                    >
                      Este campo es obligatorio
                    </Typography>
                  </Box>
                  <Box>
                    {/* {registerStatus === true ? (
                    <ButtonControla
                      iconButton={<Add sx={{ color: "white" }} />}
                      backgroundColor={"#169073"}
                      backgroundColorHover={"#1BAA88"}
                      textButton={"Agregar medidas correctivas"}
                      // functionOnClick={handleOpenModalUploadCorrectiveActions}
                    />
                  ) : (
                    ""
                  )} */}
                  </Box>
                </Stack>
              </Grid>

              <Grid container sx={{ marginTop: "4px" }}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Descripción de la medida correctivaDescripción de la
                          medida correctiva a implementarse para eliminar la
                          causa y prevenir la consecuencia
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Responsable
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Fecha de inicio
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Fecha de fin
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Completar en la fecha de ejecución propuesta, el
                          ESTADO de la implementación de la medida correctiva
                          (realizada, pendiente, en ejecución)
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {/* {correctivesActions.map((rows) => ( */}
                      <TableRow
                        hover
                        // key={rows.id_users}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {/* {rows.description} */}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {/* {rows.first_name + " " + rows.last_name} */}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {/* {rows.timestamp_start.substring(0, 10)} */}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {/* {rows.timestamp_end === null
                            ? ""
                            : rows.timestamp_end.substring(0, 10)} */}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {/* {rows.status} */}
                        </TableCell>

                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            {/* <ButtonIconControla
                              icon={
                                <VisibilityOutlined sx={{ color: "white" }} />
                              }
                              backgroundColor={"#305AD9"}
                              backgroundColorHover={"#0E4AFF"}
                              textTooltip={"Ver"}
                              directionUrl={
                                "/settings/users/edit?idUsers=" + rows.id_users
                              }
                              directionTarget={"_blank"}
                              // functionOnClick={() => ""}
                            /> */}
                            {/* {registerStatus === true ? ( */}
                            <ButtonIconControla
                              icon={<Delete sx={{ color: "white" }} />}
                              backgroundColor={"#EB5757"}
                              backgroundColorHover={"#FF4040"}
                              textTooltip={"Eliminar"}
                            // functionOnClick={() => {
                            //   handleOpenModalConfirm(
                            //     rows.id_registers_accidents_measures,
                            //     "¿Esta seguro de eliminar los datos de la medida correctiva?",
                            //     "Una vez eliminado no se podra recuperar los datos de la medida correctiva",
                            //     "Si, Eliminar",
                            //     "deleteMeasures"
                            //   );
                            // }}
                            // functionOnClick={() =>
                            //   handleDeleteCorrectivesActions(
                            //     rows.id_registers_accidents_measures
                            //   )
                            // }
                            />
                            {/* ) : (
                              ""
                            )} */}
                          </Stack>
                        </TableCell>
                      </TableRow>
                      {/* ))} */}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              {/* responsables del registro y de la investigación */}
              <Grid container>
                <Stack
                  direction="row"
                  justifyContent={"space-between"}
                  sx={{ marginTop: 3, marginBottom: 1 }}
                >
                  <Box>
                    <InputLabel
                      sx={{
                        padding: "8px",
                        fontSize: "18px",
                        color: "#305AD9",
                        fontWeight: "bold",
                      }}
                    >
                      Responsables del registro y de la investigación
                    </InputLabel>
                    <Typography
                      variant="caption"
                      display="block"
                      gutterBottom
                      sx={{ color: "#F2994A", ml: 1 }}
                    >
                      Este campo es obligatorio
                    </Typography>
                  </Box>
                  <Box>
                    {/* {registerStatus === true ? (
                    <ButtonControla
                      iconButton={<Add sx={{ color: "white" }} />}
                      backgroundColor={"#169073"}
                      backgroundColorHover={"#1BAA88"}
                      textButton={"Agregar responsables"}
                      // functionOnClick={
                      //   handleOpenModalUploadResponsibleRegistration
                      // }
                    />
                  ) : (
                    ""
                  )} */}
                  </Box>
                </Stack>
              </Grid>

              <Grid container sx={{ marginTop: "4px" }}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Nombre y Apellido
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Cargo
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Fecha de firma
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Firma
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {/* {responsibleWorkers.map((row) => ( */}
                      <TableRow
                        hover
                        // key={row.id_users}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {/* {row.first_name + " " + row.last_name} */}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {/* {row.document} */}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {/* {row.firm_date === null || row.firm_date === undefined
                            ? ""
                            : row.firm_date.substring(0, 19)} */}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {/* {row.firm === 0 ? (
                            "No firmado" && row.id_users === idUserLogged ? (
                              <ButtonIconControla
                                icon={
                                  <HistoryEduOutlined sx={{ color: "white" }} />
                                }
                                backgroundColor={"#F1C40F"}
                                backgroundColorHover={"#F4D03F"}
                                textTooltip={"Firmar"}
                                functionOnClick={() =>
                                  handleOpenModalSignResponsible(
                                    row.id,
                                    row.id_users
                                  )
                                }
                              />
                            ) : (
                              "No firmado"
                            )
                          ) : (
                            "Firmado"
                          )} */}
                        </TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            {/* {registerStatus === true ? (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id,
                                    "¿Esta seguro de eliminar los datos del trabajador responsable?",
                                    "Una vez eliminado no se podra recuperar los datos del trabajador responsable",
                                    "Si, Eliminar",
                                    "deleteResponsible"
                                  );
                                }}
                                functionOnClick={() =>
                                  handleDeleteResponsibleWorkers(
                                    row.id_registers_accidents_responsible
                                  )
                                }
                              />
                            ) : (
                              ""
                            )} */}
                          </Stack>
                        </TableCell>
                      </TableRow>
                      {/* ))} */}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>

              {/* Botones de impresión, guardar y cancelar */}
              <Grid
                container
                spacing={2}
                sx={{
                  display: "flex",
                  whidth: "100%",
                  justifyContent: "space-evenly",
                  paddingY: 4,
                }}
              >
                {/* cerrado temporalmente */}
                {/* <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<Print sx={{ color: "white" }} />}
                  backgroundColor={"#2D9CDB"}
                  backgroundColorHover={"#33AEF4"}
                  textButton={"Imprimir"}
                />
              </Grid> */}
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    iconButton={<ArrowBack sx={{ color: "white" }} />}
                    backgroundColor={"#EB5757"}
                    backgroundColorHover={"#FF4040"}
                    textButton={"Regresar"}
                  // functionOnClick={() =>
                  //   (window.location.href =
                  //     "/documentation/registers/generals/accidents")
                  // }
                  />
                </Grid>
                {/* {registerStatus === true ? (
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    iconButton={<EditOutlined sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Actualizar registro"}
                    typeButton="submit"
                    functionOnClick={handleUpdateRegistersAudit}
                  />
                </Grid>
              ) : (
                ""
              )}
              {registerStatus === true ? (
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    iconButton={<CloseIcon fontSize="inherit" />}
                    backgroundColor={"black"}
                    backgroundColorHover={"#2C2B29"}
                    textButton={"Cerrar registro"}
                    functionOnClick={handleGetRegisterStatus}
                  />
                </Grid>
              ) : (
                ""
              )} */}
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </Box>
  );
}

export default Edit;
