import * as React from "react";

import SubtitleText from "../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CardControla from "../../../components/cards/cardControla";
import SideNav from "../../../components/sidenav/sideNav";
import Box from "@mui/material/Box";
import BannerControla from "../../../components/cards/bannerControla";

const colorTitle = "#9191B0";


function Procedures() {
  return (
    <Box sx={{ display: "flex", backgroundColor: "#F5F4F6" }}>
      <SideNav returnBack="/documentation/documents" />

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            {/* NO ELIMINAR - TEMPORALMENTE COMENTADO */}

            {/* <Grid item xs={12} md={12}>
              <SubtitleText
                text={"Procedimientos"}
                color={colorTitle}
              ></SubtitleText>
            </Grid> */}
            <Grid item xs={12} md={12}>
              <BannerControla image='/assets/images/banners/documents/proceduresbanner.png' text='Procedimientos' />

            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/documents/procedures/iperc"}
                text={"IPERC"}
                img={"/assets/images/banners/documents/iperc.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/documents/procedures/pets"}
                text={"PETS"}
                img={"/assets/images/banners/documents/pets.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/documents/procedures/petar"}
                text={"PETAR"}
                img={"/assets/images/banners/documents/petar.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/ats"}
                text={"ATS"}
                img={"/assets/images/banners/documents/ats.png"}
              />
            </Grid>

          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default Procedures;
