import React from "react";
import ReactDOM from "react-dom/client";
import Login from "../src/login/main";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import DocSelect from "./components/selects/docSelect";
import HomeView from "./components/home/homeView";
import Documentation from "./documentation/documentation.jsx";
import Documents from "./documentation/documents/documents.jsx";
import Procedures from "./documentation/documents/procedures/procedures";

import Pets from "./documentation/documents/procedures/pets";
import PetsEdit from "./documentation/documents/procedures/pets/edit.jsx";
import PetsModels from "./documentation/documents/procedures/pets/model.jsx";

import Iperc from "./documentation/documents/procedures/iperc/iperc.jsx";
import Iperctemplate from "./documentation/documents/procedures/iperc/iperctemplate.jsx";
import Standards from "./standards";
import StandardsCreate from "./standards/create";
import Programs from "./documentation/programs/programs.jsx";
import Sso from "./documentation/programs/sso/index.jsx";
import Goals from "./documentation/programs/sso/goals.jsx";
import Newgoals from "./documentation/programs/sso/newgoals.jsx";
import EditPrograms from "./documentation/programs/sso/editprograms.jsx";
import ObjectiveGeneral from "./documentation/programs/sso/objectivegeneral.jsx";
import ProgramsExtends from "./documentation/programs/sso/extends";

import ProgramsTraining from "./documentation/programs/training/index.jsx";
import TrainingGoals from "./documentation/programs/training/goals.jsx";
import TrainingNewgoals from "./documentation/programs/training/newgoals.jsx";
import TrainingEditPrograms from "./documentation/programs/training/editprograms.jsx";
import TrainingObjectiveGeneral from "./documentation/programs/training/objectivegeneral.jsx";
import TrainingProgramsExtends from "./documentation/programs/training/extends";

// documentos
import LegalMatrix from "./documentation/legalmatrix/index.jsx";
import RiskMap from "./documentation/riskmap/index.jsx";
import Rit from "./documentation/rit/index.jsx";

// hojas de seguridad
import SecuritySheet from "./documentation/securitysheet/index.jsx";
import SecuritySheetExplosives from "./documentation/securitysheet/explosives/index.jsx";
import SecuritySheetGases from "./documentation/securitysheet/gases/index.jsx";
import SecuritySheetFlammableLiquid from "./documentation/securitysheet/flammableliquid/index.jsx";
import SecuritySheetFlammableSolids from "./documentation/securitysheet/flammablesolids/index.jsx";
import SecuritySheetOxidizingSubstances from "./documentation/securitysheet/oxidizingsubstances/index.jsx";
import SecuritySheetPoisonousSubstances from "./documentation/securitysheet/poisonoussubstances/index.jsx";
import SecuritySheetRadioactiveMaterials from "./documentation/securitysheet/radioactivematerials/index.jsx";
import SecuritySheetCorrosiveSubstances from "./documentation/securitysheet/corrosivesubstances/index.jsx";
import SecuritySheetMiscelaneous from "./documentation/securitysheet/miscelaneous/index.jsx";

import Risst from "./documentation/risst/index.jsx";
import RisstTemplate from "./documentation/risst/template.jsx";

import SsoPolitics from "./documentation/ssopolitics/index.jsx";
import SsoPoliticsTemplate from "./documentation/ssopolitics/template.jsx";

import AnnualPlan from "./documentation/annualplan/index.jsx";
import AnnualPlanTemplate from "./documentation/annualplan/template.jsx";
// import SecuritySheetMiscelaneous from "./documentation/securitysheet/miscelaneous/index.jsx";

// import Risst from "./documentation/risst/index.jsx";


// registers
import Registers from "./documentation/registers";
import RegistersAccidents from "./documentation/registers/generals/accidents";
import RegistersAccidentsEdit from "./documentation/registers/generals/accidents/edit.jsx";
import RegistersOccupationalDiseases from "./documentation/registers/generals/occupationaldiseases";
import RegistersOccupationalDiseasesEdit from "./documentation/registers/generals/occupationaldiseases/edit.jsx";
import RegistersIncidentsDangerousIncidents from "./documentation/registers/generals/incidentsdangerousincidents";
import RegistersIncidentsDangerousIncidentsEdit from "./documentation/registers/generals/incidentsdangerousincidents/edit.jsx";
import RegistersMonitoring from "./documentation/registers/generals/monitoring/index";
import RegistersMonitoringEdit from "./documentation/registers/generals/monitoring/edit";
import RegistersSecurityEmergencyEquipment from "./documentation/registers/generals/securityemergencyequipment/index";
import RegistersSecurityEmergencyEquipmentEdit from "./documentation/registers/generals/securityemergencyequipment/edit";
import RegistersInductionTrainingCoaching from "./documentation/registers/generals/inductiontrainingcoaching/index";
import RegistersInductionTrainingCoachingEdit from "./documentation/registers/generals/inductiontrainingcoaching/edit";
import RegistersAudits from "./documentation/registers/generals/audits/index";
import RegistersAuditsEdit from "./documentation/registers/generals/audits/edit";
import RegistersInspections from "./documentation/registers/generals/inspections/index";
import RegistersInspectionsEdit from "./documentation/registers/generals/inspections/edit";
import RegistersStatisticalData from "./documentation/registers/generals/statisticaldata/index";
import RegistersStatisticalDataEdit from "./documentation/registers/generals/statisticaldata/edit";
import RegistersCompetencyCertificate from "./documentation/registers/generals/competencycertificate/index";
import RegistersCompetencyCertificateEdit from "./documentation/registers/generals/competencycertificate/edit";

// mypes
import Mypes from "./documentation/registers/mypes";
import RegistersMyAccidentsOccupationalsIncidents from "./documentation/registers/mypes/accidentsoccupationalsincidents";
import RegistersMyAccidentsOccupationalsIncidentsEdit from "./documentation/registers//mypes/accidentsoccupationalsincidents/edit.jsx";
import RegistersMypeOccupationalDiseases from "./documentation/registers/mypes/occupationaldiseases";
import RegistersMypeOccupationalDiseasesEdit from "./documentation/registers/mypes/occupationaldiseases/edit.jsx";

// inductiontrainingcoaching
import Committee from "./committee/index.jsx";
import Baseline from "./baseline/baseline.jsx";
import MainPetar from "./documentation/documents/procedures/petar";
import EditPetar from "./documentation/documents/procedures/petar/editPetar";
import IndexAts from "./documentation/documents/procedures/ats";
import EditAts from "./documentation/documents/procedures/ats/edit";
import ModelEditAts from "./documentation/documents/procedures/ats/model";

// import MainAts from './documentation/ats';
// import EditAts from './documentation/ats/editAts';
import ElectionProcess from "./committee/electionProcess/index.jsx";
import ElectionProcessProcess from "./committee/electionProcess/process";
import Process from "./committee/process/index.jsx";
import ProcessStatus from "./committee/processStatus";

// mailbox
import Mailbox from "./mailbox";
import MailboxConsults from "./mailbox/consults/";
import MailboxReports from "./mailbox/reports/";
import MailboxCongratulations from "./mailbox/congratulations/";

import ScheduleCalendar from "./committee/scheduleCalendar";
import ProcessStatusTwo from "./committee/processStatusTwo";
import ScheduleCalendarTwo from "./committee/scheduleCalendarTwo";
import ScheduleCalendarThree from "./committee/ScheduleCalendarThree";
import BaselineProcess from "./baseline/baselineProcess";
import BaselineStartProcess from "./baseline/baselineStartProcess";
import BaselineAnswers from "./baseline/baselineAnswers";
import BaselineAnswersTwo from "./baseline/baselineAnswersTwo";
import BaselineResults from "./baseline/baselineResults";
import Company from "./company/index.jsx";
import Headoffices from "./company/headoffices.jsx";
import Organizationchart from "./company/organizationchart.jsx";
import IndexAdmin from "./administrator";
import IndexEpps from "./administrator/epps";
import IndexEppsCategories from "./administrator/epps/categories";
import IndexBaseLineIndicators from "./administrator/baseline/index";
import IndexBaseLineLineaments from "./administrator/baseline/lineaments";
import ErrorViews from "./components/errorsViews";
import BaselineTools from "./baseline/baselineTools";
import BaselineDiagnosis from "./baseline/baselineDiagnosis";
import Settings from "./settings";
import SettingsUsers from "./settings/users";
import SettingsUsersCreate from "./settings/users/create";
import SettingsUsersEdit from "./settings/users/edit";
import SettingsCompany from "./settings/company";
import SettingsCompanyGeneralInformation from "./settings/company/generalInformation";
import SettingsCompanyOrganization from "./settings/company/organization";
import SettingsCompanyCertifications from "./settings/company/certifications";
import SettingsCampus from "./settings/campus";
import EditModel from "./documentation/documents/procedures/petar/editModel";
import BaselineShowResults from "./baseline/baselineShowResults";
import AreasIndex from "./areas/index";
import OccupationalPositionsIndex from "./occupationalpositions/index";
import BaselineImprovementActions from "./baseline/baselineImprovementActions";

import TrainingsIndex from "./trainings";
import TrainingsSubjectIndex from "./trainings/subjects";
import TrainingsAllIndex from "./trainings/all/index";
import TrainingsAllEdit from "./trainings/all/edit";
import TrainingsAllWorkers from "./trainings/all/workers";
import TrainingsAllEvidences from "./trainings/all/evidences";
import TrainingsAllMaterials from "./trainings/all/materials";
import TrainingsAllExams from "./trainings/all/exams/index";
import TrainingsAllExamsQuestions from "./trainings/all/exams/edit";

import TrainingsPersonalIndex from "./trainings/personal/index";
import TrainingsPersonalEdit from "./trainings/personal/edit";
import TrainingsPersonalWorkers from "./trainings/personal/123workers";
import TrainingsPersonalEvidences from "./trainings/personal/evidences";
import TrainingsPersonalMaterials from "./trainings/personal/materials";
import TrainingsPersonalExams from "./trainings/personal/exams/index";
import TrainingsPersonalExamsQuestions from "./trainings/personal/exams/edit";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeView />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/documentation",
    element: <Documentation />,
  },
  {
    path: "/documentation/legalmatrix/",
    element: <LegalMatrix />,
  },
  {
    path: "/documentation/riskmap/",
    element: <RiskMap />,
  },
  {
    path: "/documentation/rit",
    element: <Rit />
  },
  {
    path: "/documentation/securitysheet/",
    element: <SecuritySheet />,
  },
  {
    path: "/documentation/securitysheet/explosives",
    element: <SecuritySheetExplosives />,
  },
  {
    path: "/documentation/securitysheet/gases",
    element: <SecuritySheetGases />,
  },
  {
    path: "/documentation/securitysheet/flammableliquid",
    element: <SecuritySheetFlammableLiquid />,
  },
  {
    path: "/documentation/securitysheet/flammablesolids",
    element: <SecuritySheetFlammableSolids />,
  },
  {
    path: "/documentation/securitysheet/oxidizingsubstances",
    element: <SecuritySheetOxidizingSubstances />,
  },
  {
    path: "/documentation/securitysheet/poisonoussubstances",
    element: <SecuritySheetPoisonousSubstances />,
  },
  {
    path: "/documentation/securitysheet/radioactivematerials",
    element: <SecuritySheetRadioactiveMaterials />,
  },
  {
    path: "/documentation/securitysheet/corrosivesubstances",
    element: <SecuritySheetCorrosiveSubstances />,
  },
  {
    path: "/documentation/securitysheet/miscelaneous",
    element: <SecuritySheetMiscelaneous />,
  },
  {
    path: "/documentation/risst",
    element: <Risst />,
  },
  {
    path: "/documentation/risst/template",
    element: <RisstTemplate />,
  },
  {
    path: "/documentation/ssopolitics",
    element: <SsoPolitics />,
  },
  {
    path: "/documentation/ssopolitics/template",
    element: <SsoPoliticsTemplate />
  },
  {
    path: "/documentation/annualplan",
    element: <AnnualPlan />,
  },
  {
    path: "/documentation/annualplan/template",
    element: <AnnualPlanTemplate />
  },
  {
    path: "documentation/documents/standards",
    element: <Standards />,
  },
  {
    path: "documentation/documents/standards/create",
    element: <StandardsCreate />,
  },
  {
    path: "/documentation/documents",
    element: <Documents />,
  },
  {
    path: "/documentation/documents/procedures",
    element: <Procedures />,
  },
  {
    path: "/documentation/documents/procedures/pets/",
    element: <Pets />,
  },
  {
    path: "/documentation/documents/procedures/pets/edit",
    element: <PetsEdit />,
  },
  {
    path: "/documentation/documents/procedures/pets/model",
    element: <PetsModels />,
  },
  {
    path: "/documentation/documents/procedures/iperc",
    element: <Iperc />,
  },
  {
    path: "/documentation/documents/procedures/Iperctemplate",
    element: <Iperctemplate />,
  },
  {
    path: "documentation/programs",
    element: <Programs />,
  },
  {
    path: "documentation/programs/sso",
    element: <Sso />,
  },
  {
    path: "documentation/programs/sso/goals",
    element: <Goals />,
  },
  {
    path: "documentation/programs/sso/newgoals",
    element: <Newgoals />,
  },
  {
    path: "documentation/programs/sso/editprograms",
    element: <EditPrograms />,
  },
  {
    path: "documentation/programs/sso/objectivegeneral",
    element: <ObjectiveGeneral />,
  },
  {
    path: "documentation/programs/sso/extends",
    element: <ProgramsExtends />,
  },


  {
    path: "documentation/programs/training",
    element: <ProgramsTraining />,
  },
  {
    path: "documentation/programs/training/goals",
    element: <TrainingGoals />,
  },
  {
    path: "documentation/programs/training/newgoals",
    element: <TrainingNewgoals />,
  },
  {
    path: "documentation/programs/training/editprograms",
    element: <TrainingEditPrograms />,
  },
  {
    path: "documentation/programs/training/objectivegeneral",
    element: <TrainingObjectiveGeneral />,
  },
  {
    path: "documentation/programs/training/extends",
    element: <TrainingProgramsExtends />,
  },

  {
    path: "documentation/registers",
    element: <Registers />,
  },
  {
    path: "documentation/registers/generals/accidents",
    element: <RegistersAccidents />,
  },
  {
    path: "documentation/registers/generals/accidents/edit",
    element: <RegistersAccidentsEdit />,
  },
  {
    path: "documentation/registers/mypes",
    element: <Mypes />,
  },
  {
    path: "documentation/registers/mypes/accidentsoccupationalsincidents",
    element: <RegistersMyAccidentsOccupationalsIncidents />,
  },
  {
    path: "documentation/registers/mypes/accidentsoccupationalsincidents/edit",
    element: <RegistersMyAccidentsOccupationalsIncidentsEdit />,
  },
  {
    path: "documentation/registers/mypes/occupationaldiseases",
    element: <RegistersMypeOccupationalDiseases />,
  },
  {
    path: "documentation/registers/mypes/occupationaldiseases/edit",
    element: <RegistersMypeOccupationalDiseasesEdit />,
  },
  {
    path: "documentation/registers/generals/incidentsdangerousincidents",
    element: <RegistersIncidentsDangerousIncidents />,
  },
  {
    path: "documentation/registers/generals/incidentsdangerousincidents/edit",
    element: <RegistersIncidentsDangerousIncidentsEdit />,
  },
  {
    path: "documentation/registers/generals/monitoring",
    element: <RegistersMonitoring />,
  },
  {
    path: "documentation/registers/generals/monitoring/edit",
    element: <RegistersMonitoringEdit />,
  },
  // {
  //   path: "documentation/registers/generals/inspections",
  //   element: <RegistersInspections/>,
  // },
  // {
  //   path: "documentation/registers/generals/inspections/edit",
  //   element: <RegistersInspectionsEdit/>
  // },
  // {
  //   path: "documentation/registers/generals/statistics",
  //   element: <RegistersStatistics/>
  // },
  // {
  //   path: "documentations/registers/generals/statistics/edit",
  //   element: <RegistersStatisticsEdit/>
  // },
  {
    path: "documentation/registers/generals/securityemergencyequipment",
    element: <RegistersSecurityEmergencyEquipment />,
  },
  {
    path: "documentation/registers/generals/securityemergencyequipment/edit",
    element: <RegistersSecurityEmergencyEquipmentEdit />,
  },
  {
    path: "documentation/registers/generals/inductiontrainingcoaching",
    element: <RegistersInductionTrainingCoaching />,
  },
  {
    path: "documentation/registers/generals/inductiontrainingcoaching/edit",
    element: <RegistersInductionTrainingCoachingEdit />,
  },
  {
    path: "documentation/registers/generals/audits",
    element: <RegistersAudits />,
  },
  {
    path: "documentation/registers/generals/audits/edit",
    element: <RegistersAuditsEdit />,
  },

  {
    path: "documentation/registers/generals/inspections",
    element: <RegistersInspections />,
  },
  {
    path: "documentation/registers/generals/inspections/edit",
    element: <RegistersInspectionsEdit />,
  },
  {
    path: "documentation/registers/generals/competencycertificate",
    element: <RegistersCompetencyCertificate />,
  },
  {
    path: "documentation/registers/generals/competencycertificate/edit",
    element: <RegistersCompetencyCertificateEdit />,
  },
  {
    path: "documentation/registers/generals/statisticaldata",
    element: <RegistersStatisticalData />,
  },
  {
    path: "documentation/registers/generals/statisticaldata/edit",
    element: <RegistersStatisticalDataEdit />,
  },
  {
    path: "/committee",
    element: <Committee />,
  },
  {
    path: "/committee/electionprocess",
    element: <ElectionProcess />,
  },
  {
    path: "/committee/electionprocess/process",
    element: <ElectionProcessProcess />,
  },
  {
    path: "/committee/process",
    element: <Process />,
  },

  {
    path: "/committee/processstatus",
    element: <ProcessStatus />,
  },
  {
    path: "/committee/processstatustwo",
    element: <ProcessStatusTwo />,
  },
  {
    path: "/committee/schedulecalendar",
    element: <ScheduleCalendar />,
  },
  {
    path: "/committee/schedulecalendartwo",
    element: <ScheduleCalendarTwo />,
  },
  {
    path: "/committee/schedulecalendarthree",
    element: <ScheduleCalendarThree />,
  },
  {
    path: "/baseline",
    element: <Baseline />,
  },
  {
    path: "/baseline/startprocess",
    element: <BaselineStartProcess />,
  },
  {
    path: "/baseline/diagnosis",
    element: <BaselineDiagnosis />,
  },
  {
    path: "baseline/process",
    element: <BaselineProcess />,
  },
  {
    path: "baseline/answers",
    element: <BaselineAnswers />,
  },
  {
    path: "baseline/answerstwo",
    element: <BaselineAnswersTwo />,
  },
  {
    path: "baseline/results",
    element: <BaselineResults />,
  },
  {
    path: "baseline/baselineTools",
    element: <BaselineTools />,
  },
  {
    path: "baseline/show/results",
    element: <BaselineShowResults />,
  },
  {
    path: "baseline/show/improvementactions",
    element: <BaselineImprovementActions />,
  },
  {
    path: "/404",
    element: <h1>No se encontró</h1>,
  },
  {
    path: "/500",
    element: <h1>Error interno del servidor</h1>,
  },
  {
    path: "/403",
    element: <h1>Prohibidos</h1>,
  },
  {
    path: "/dashboard",
    element: <HomeView />,
  },
  {
    path: "/ats",
    element: <IndexAts />,
  },
  {
    path: "/ats/edit",
    element: <EditAts />,
  },
  {
    path: "/ats/model/edit",
    element: <ModelEditAts />,
  },

  {
    path: "/editAts",
    element: <EditAts />,
  },
  {
    path: "documentation/registers/generals/occupationaldiseases",
    element: <RegistersOccupationalDiseases />,
  },
  {
    path: "documentation/registers/generals/occupationaldiseases/edit",
    element: <RegistersOccupationalDiseasesEdit />,
  },

  {
    path: "/documentation/documents/procedures/petar",
    element: <MainPetar />,
  },
  {
    path: "editPetar",
    element: <EditPetar />,
  },
  {
    path: "/documentation/documents/procedures/petar/editModel",
    element: <EditModel />,
  },
  {
    path: "mailbox",
    element: <Mailbox />,
  },

  {
    path: "mailbox/consults",
    element: <MailboxConsults />,
  },
  {
    path: "mailbox/reports",
    element: <MailboxReports />,
  },
  {
    path: "mailbox/congratulations",
    element: <MailboxCongratulations />,
  },

  {
    path: "settings",
    element: <Settings />,
  },
  {
    path: "settings/users",
    element: <SettingsUsers />,
  },
  {
    path: "settings/users/create",
    element: <SettingsUsersCreate />,
  },
  {
    path: "settings/users/edit",
    element: <SettingsUsersEdit />,
  },
  {
    path: "settings/company",
    element: <SettingsCompany />,
  },
  {
    path: "settings/company/generalinformation",
    element: <SettingsCompanyGeneralInformation />,
  },
  {
    path: "settings/company/organization",
    element: <SettingsCompanyOrganization />,
  },
  {
    path: "settings/company/certifications",
    element: <SettingsCompanyCertifications />,
  },
  {
    path: "settings/campus",
    element: <SettingsCampus />,
  },
  {
    path: "company",
    element: <Company />,
  },
  {
    path: "company/headoffices",
    element: <Headoffices />,
  },
  {
    path: "company/organizationchart",
    element: <Organizationchart />,
  },

  // Administrador
  {
    path: "admin",
    element: <IndexAdmin />,
  },
  {
    path: "admin/epps",
    element: <IndexEpps />,
  },
  {
    path: "admin/epps/categories",
    element: <IndexEppsCategories />,
  },
  {
    path: "admin/baseline/indicators",
    element: <IndexBaseLineIndicators />,
  },
  {
    path: "admin/baseline/lineaments",
    element: <IndexBaseLineLineaments />,
  },
  //Areas
  {
    path: "settings/areas",
    element: <AreasIndex />,
  },
  //Positions
  {
    path: "settings/positions",
    element: <OccupationalPositionsIndex />,
  },

  {
    path: "trainings",
    element: <TrainingsIndex />,
  },
  {
    path: "trainings/subjects",
    element: <TrainingsSubjectIndex />,
  },
  {
    path: "trainings/all",
    element: <TrainingsAllIndex />,
  },
  {
    path: "trainings/all/edit",
    element: <TrainingsAllEdit />,
  },
  {
    path: "trainings/all/workers",
    element: <TrainingsAllWorkers />,
  },
  {
    path: "trainings/all/evidences",
    element: <TrainingsAllEvidences />,
  },
  {
    path: "trainings/all/materials",
    element: <TrainingsAllMaterials />,
  },
  {
    path: "trainings/all/exams",
    element: <TrainingsAllExams />,
  },
  {
    path: "trainings/all/exams/questions",
    element: <TrainingsAllExamsQuestions />,
  },
  {
    path: "trainings/personal",
    element: <TrainingsPersonalIndex />,
  },
  {
    path: "trainings/personal/edit",
    element: <TrainingsPersonalEdit />,
  },
  {
    path: "trainings/personal/workers",
    element: <TrainingsPersonalWorkers />,
  },
  {
    path: "trainings/personal/evidences",
    element: <TrainingsPersonalEvidences />,
  },
  {
    path: "trainings/personal/materials",
    element: <TrainingsPersonalMaterials />,
  },
  {
    path: "trainings/personal/exams",
    element: <TrainingsPersonalExams />,
  },
  {
    path: "trainings/personal/exams/questions",
    element: <TrainingsPersonalExamsQuestions />,
  },


  //errors
  {
    path: "*",
    element: (
      <ErrorViews
        urlImg={"/assets/images/errors/imgNotFound404.png"}
        textError={"ERROR 404"}
        msgError={"Pagina no encontrada"}
      />
    ),
  },
  {
    path: "errorPermissions",
    element: (
      <ErrorViews
        urlImg={"/assets/images/errors/imgNotFound403.png"}
        textError={"ERROR 403"}
        msgError={"Error de roles, de permisos o falta de login."}
      />
    ),
  },
  {
    path: "internalError",
    element: (
      <ErrorViews
        urlImg={"/assets/images/errors/imgNotFound503.png"}
        textError={"ERROR 503"}
        msgError={"Error interno del sistema ya sea servidor o usuario."}
      />
    ),
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <RouterProvider router={router} />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
