import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function getSecuritySheet() {
  const url = "security_sheet_radioactive_materials";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function createSecuritySheet({name, file}) {
  const current = new Date();
  const year = current.getFullYear();
  const month = current.getMonth() + 1;
  const day = current.getDate();

  if (file !== null && file !== undefined) {
    file = await uploadFileCompanies(
      file,
      "documentation/securitysheet/radioactivematerials/" +
        year +
        "/" +
        month +
        "/" +
        day +
        "/"
    );
  }

  const url = "security_sheet_radioactive_materials";
  const data = {
    name: name,
    file: file
  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

// eliminar
async function deleteSecuritySheet({ idSecuritySheet }) {
  const url ="security_sheet_radioactive_materials/"+ idSecuritySheet;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "DELETE",
    url: url,
  });
}

async function updatestatus({
  idSecuritySheet,  
  register_status
}) {
  const url = "security_sheet_radioactive_materials/" + idSecuritySheet;
  const data = {   
    register_status:register_status   
  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}


const MySecuritySheetRadioactiveMaterials = {
    getSecuritySheet,
    createSecuritySheet,
    deleteSecuritySheet,
    updatestatus

};
export default MySecuritySheetRadioactiveMaterials;