import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function getReportSubjects() {
  const url = "mailbox/reports/themes/get";
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function getSentReports() {
  const url = "mailbox/reports/sent/get";
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function getReceiveReports() {
  const url = "mailbox/reports/receive/get";
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function postReports({ idUsersReceiving, report, idPrioritiesSender, idReportsTheme, document_url }) {
  if (document_url !== null && document_url !== undefined) {
    const current = new Date();
    const year = current.getFullYear();
    const month = current.getMonth() + 1;
    const day = current.getDate();
    document_url = await uploadFileCompanies(document_url, 'mailbox/reports/' + year + '/' + month + '/' + day + '/');

}
  const url = "mailbox/reports/store";
  const data = {
    'id_users_receiving': idUsersReceiving,
    'report': report,
    'id_priorities_sender': idPrioritiesSender,
    'id_reports_theme': idReportsTheme,
    'document_url':document_url
  }
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

async function updateReports({ idPrioritiesReceiving, answer, idReports, document_url }) {
  if (document_url !== null && document_url !== undefined) {
    const current = new Date();
    const year = current.getFullYear();
    const month = current.getMonth() + 1;
    const day = current.getDate();
    document_url = await uploadFileCompanies(document_url, 'mailbox/reports/' + year + '/' + month + '/' + day + '/');

}
  const url = "mailbox/reports/update/" + idReports;
  const data = {
    'id_priorities_receiving': idPrioritiesReceiving,
     'answer': answer,
     'document_url':document_url

  }
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function updateReportStatuses({ idReportsStatuses, idReports }) {
  const url = "mailbox/reports/update/statuses/" + idReports;
  const data = {
    'id_reports_statuses': idReportsStatuses
  }
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

// jalando todos los reportes
function getReports() {
  const url = "mailbox/reports/get";
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

const  MyReports = {
  getReportSubjects, getReceiveReports, getSentReports, postReports, updateReports, updateReportStatuses, getReports
}

export default MyReports;
