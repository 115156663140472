import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function getRiskMap() {
  const url = "document_risk_map";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function createRiskMapx({name, file}) {
  const current = new Date();
  const year = current.getFullYear();
  const month = current.getMonth() + 1;
  const day = current.getDate();

  if (file !== null && file !== undefined) {
    file = await uploadFileCompanies(
      file,
      "documentation/riskmap/" +
        year +
        "/" +
        month +
        "/" +
        day +
        "/"
    );
  }

  const url = "document_risk_map";
  const data = {
    name: name,
    file: file
  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}


// editar
function editRiskMap({ idRiskMap }) {
  const url = "document_risk_map/" + idRiskMap;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}


async function updateRiskMap({name, file, idRiskMap}) {
  const current = new Date();
  const year = current.getFullYear();
  const month = current.getMonth() + 1;
  const day = current.getDate();

  if (file !== null && file !== undefined) {
    file = await uploadFileCompanies(
      file,
      "documentation/riskmap/" +
        year +
        "/" +
        month +
        "/" +
        day +
        "/"
    );
  }

  const url = "document_risk_map/"+idRiskMap;
  const data = {
    name: name,
    file: file
  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

// eliminar
async function deleteRiskMap({ idRiskMap }) {
  const url ="document_risk_map/"+ idRiskMap;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "DELETE",
    url: url,
  });
}

async function updatestatus({
  idRiskMap,  
  register_status
}) {
  const url = "document_risk_map/" + idRiskMap;
  const data = {   
    register_status:register_status   
  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

const MyRiskMap = {
    getRiskMap,
    createRiskMapx,
    deleteRiskMap,
    updatestatus,
    editRiskMap,
    updateRiskMap

};
export default MyRiskMap;