import { React, useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import { Button, Card, CardActions, CardContent, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, FormLabel, Grid, IconButton, InputLabel, LinearProgress, Paper, Radio, RadioGroup, Rating, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { styled } from '@mui/system';
import SideNav from '../../../components/sidenav/sideNav';
import SubtitleText from '../../../components/text/subtitleText';
import TitleText from '../../../components/text/titleText';
import MyBaseline from '../../../api/MyBaseline';
import InputControlaVertical from '../../../components/textfields/inputControlaVertical';
import { Add, ArrowBackIosNew, ArrowBackOutlined, ArrowForwardIos, BorderColorOutlined, Delete, FormatListNumbered, HistoryEduOutlined } from '@mui/icons-material';
import ButtonControla from '../../../components/buttons/buttonController';
import MyTrainings from '../../../api/MyTrainings';
import DialogTitleControla from '../../../components/dialog/dialogTitleControla';
import LoadingControla from '../../../components/load/loadingControla';
import DialogConfirmControla from '../../../components/dialog/dialogConfirmControla';
import ButtonIconControla from '../../../components/buttons/buttonIconControla';
import SelectYesNotControla from '../../../components/selects/selectYesNotControla';
import MyConfigurationApi from '../../../api/MyConfigurationApi';

const grey = "#9191B0";
const blue = "#034AFF";

let rowQuestionsAnswers = [];

function BaselineAnswersTwo() {

    const params = new URLSearchParams(window.location.search);
    const id_trainings_exams = params.get('id_trainings_exams');
    const id_trainings = params.get('id_trainings');


    // Pantallas de carga y alertas INICIO
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState('');
    const [typeAlert, setTypeAlert] = useState('success');

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert('');
        setTypeAlert('success');
    }

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    }

    const handleCloseAlert = () => {
        setOpenAlert(false);
    }

    // Pantallas de carga y alertas FIN


    // Dialogos de confirmacion INICIO
    const [idAction, setIdAction] = useState('');

    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [titleText, setTitleText] = useState('');
    const [contentText, setContentText] = useState('');
    const [buttonTextConfirm, setButtonTextConfirm] = useState('');
    const [buttonActionType, setButtonActionType] = useState('');


    const handleOpenModalConfirm = (id, titleText, contentText, buttonTextConfirm, buttonActionType) => {
        setIdAction(id);
        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setButtonActionType(buttonActionType);
        setOpenModalConfirm(true);
    }

    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    }

    const handleActionConfirm = () => {
        switch (buttonActionType) {
            case 'storeQuestionsAnswers':
                handleStoreQuestionsAnswers()
                break;
        }
        setOpenModalConfirm(false);
    }

    const [openModalFirm, setOpenModalFirm] = useState(false);
    const [idUserLogged, setIdUserLogged] = useState(MyConfigurationApi.userData().id_users);
    const handleOpenModalFirm = () => {
        setOpenModalFirm(true);
    };
    const handleCloseModalFirm = () => {
        setOpenModalFirm(false);
    };

    const [rowQuestions, setRowQuestions] = useState([]);
    const [realizedQuestions, setRealizedQuestions] = useState(0);
    const [qualification, setQualification] = useState(0);
    const handleGetQuestions = async () => {
        const response = await MyTrainings.getQuestionsAnswers({ id_trainings_exams: id_trainings_exams });
        setRowQuestions(response);

        response.forEach((question) => {
            if (question.id_trainings_exams_questions_answers) {
                let quali = 0;
                response.forEach((question) => {
                    const answers = question.answers;
                    answers.forEach((answer) => {
                        if (question.id_trainings_exams_questions_answers == answer.id_trainings_exams_questions_answers && answer.correct == 1) {
                            quali = quali + question.value;
                        }

                    });
                });
                setQualification(quali);
                setRealizedQuestions(1);
                return;
            } else {

            }
        });
    };
    const [idStatus, setIdStatus] = useState("");
    const [name, setName] = useState("");
    const handleGetTraining = async () => {
        const response = await MyTrainings.getTrainings({ id_trainings: id_trainings });
        setIdStatus(response[0].id_trainings_statuses);
        setName(response[0].name);
    }
    useEffect(() => {
        handleGetTraining();
        handleGetQuestions();
    }, []);


    const handleQuestionAnswer = async (idQuestion, idAnswer) => {
        const existingQuestionIndex = rowQuestionsAnswers.findIndex(
            (question) => question.id_trainings_exams_questions === idQuestion
        );
        if (existingQuestionIndex !== -1) {
            rowQuestionsAnswers[existingQuestionIndex].id_trainings_exams_questions_answers = idAnswer;
        } else {
            rowQuestionsAnswers.push({
                id_trainings_exams_questions: idQuestion,
                id_trainings_exams_questions_answers: idAnswer,
            });
        }
    };


    const handleStoreQuestionsAnswers = async () => {
        handleOpenLoading();
        const response = await MyTrainings.storeQuestionsAnswers({ array_questions_answers: rowQuestionsAnswers });

        if (response.success === false) {
            handleOpenAlert('Hubo un error', 'error');
        } else {
            handleGetQuestions();
            handleOpenAlert(response.msg, 'success');
        }
    };
    const [rating, setRating] = useState(null);
    const handleFirm = async (event) => {
        handleOpenLoading();
        event.preventDefault();
        if (rating > 0 && rating < 6) {
            const response = await MyTrainings.updateWorkersFirm({ id_users: idUserLogged, qualification: rating, id_trainings: id_trainings });
            if (response.success === false) {
                handleOpenAlert('Hubo un error', 'error');
            } else {
                handleCloseModalFirm();
                handleOpenAlert(response.msg, 'success');
                handleStoreQuestionsAnswers();
            }

        } else {
            handleOpenAlert('Califica la capacitacion para poder firmar.', 'error');
        }


    }

    return (
        <>
            <Box sx={{ display: "flex", height: '100%' }}>
                <SideNav returnBack={'/trainings/personal/exams?id_trainings=' + id_trainings} />
                <LoadingControla openLoading={openLoading} openAlert={openAlert} handleCloseAlert={handleCloseAlert} messageAlert={messageAlert} typeAlert={typeAlert} />

                <DialogConfirmControla openModalConfirm={openModalConfirm} handleCloseModalConfirm={handleCloseModalConfirm} titleText={titleText} contentText={contentText} buttonTextConfirm={buttonTextConfirm} handleActionConfirm={handleActionConfirm} />
                <Container>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container rowSpacing={3} columnSpacing={3}>
                            <Grid item md={12}>
                                <TitleText
                                    text={name}>
                                </TitleText>
                                {realizedQuestions === 1 ?
                                    <>
                                        <SubtitleText
                                            text={'Muchas gracias, has completado la evaluación.'}
                                            color={grey}>
                                        </SubtitleText>
                                        <SubtitleText
                                            text={'Tu puntaje es: ' + qualification}
                                            color={grey}>
                                        </SubtitleText>
                                        <SubtitleText
                                            text={'Aquí puedes revisar las respuestas correctas (marcadas en color verde)'}
                                            color={grey}>
                                        </SubtitleText>
                                    </> : ''
                                }


                            </Grid>

                            <Grid container item spacing={2}>
                                {rowQuestions.map((row) => (
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} key={row.id_trainings_exams_questions} >

                                        <Card sx={{
                                            maxWidth: "100%",
                                            borderRadius: "14px",
                                            height: 'auto',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between'
                                        }}
                                        >
                                            <CardContent
                                                sx={{
                                                    width: '100%'
                                                }}>
                                                <Grid container >
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ height: "auto", mb: 1 }}>
                                                        <Typography color={blue} fontSize={17} fontWeight={600}
                                                            sx={{
                                                                userSelect: 'none'
                                                            }}>
                                                            {row.question + ' (' + row.value + ' puntos)'}
                                                        </Typography>

                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{ height: "auto" }}>
                                                        <Typography color={blue} fontSize={16}
                                                            sx={{
                                                                userSelect: 'none', pb: .5
                                                            }}>
                                                            Grupo de respuestas:
                                                        </Typography>
                                                        <FormControl sx={{
                                                            width: '100%'
                                                        }}
                                                            readOnly={realizedQuestions === 1}>
                                                            <RadioGroup
                                                                name="radio-buttons-group"
                                                                sx={{
                                                                    width: '100%'
                                                                }}
                                                                onChange={(event) => {
                                                                    handleQuestionAnswer(row.id_trainings_exams_questions, event.target.value);
                                                                }}

                                                                defaultValue={realizedQuestions === 1
                                                                    ? row.id_trainings_exams_questions_answers
                                                                    : false}

                                                                readOnly={realizedQuestions === 1}

                                                            >
                                                                {row.answers.map((answer) => (
                                                                    <FormControlLabel
                                                                        key={answer.id_trainings_exams_questions_answers}
                                                                        value={answer.id_trainings_exams_questions_answers}
                                                                        control={<Radio />}
                                                                        label={answer.answer}
                                                                        // checked={
                                                                        //     realizedQuestions === 1
                                                                        //         ? row.id_trainings_exams_questions_answers === answer.id_trainings_exams_questions_answers
                                                                        //         : false
                                                                        // }
                                                                        sx={{
                                                                            width: '100%',
                                                                            borderRadius: '16px',
                                                                            backgroundColor: realizedQuestions === 1
                                                                                ? answer.id_trainings_exams_questions_answers === row.id_trainings_exams_questions_answers
                                                                                    ? answer.correct === 1
                                                                                        ? '#a7f8a6'
                                                                                        : '#ffb1b1'
                                                                                    : answer.correct === 1
                                                                                        ? '#a7f8a6'
                                                                                        : ''
                                                                                : '',
                                                                        }}
                                                                    />

                                                                ))}
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}

                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    sx={{ paddingTop: 8 }}
                                >
                                    <Stack direction={"row"} spacing={2} justifyContent={"right"}>
                                        <Box>
                                            <ButtonControla
                                                iconButton={<ArrowBackOutlined sx={{ color: "white" }} />}
                                                backgroundColor={"#CBCBFF"}
                                                textButton={"Cerrar"}
                                                backgroundColorHover={"#CBCBFF"}
                                                typeButton={"button"}
                                                url={"/trainings/personal/exams?id_trainings=" + id_trainings}
                                            />
                                        </Box>
                                        <Box>
                                            {realizedQuestions === 1 || idStatus !== 2 ?
                                                '' :
                                                <ButtonControla
                                                    roles={[1, 7, 8]}
                                                    backgroundColor={"#169073"}
                                                    backgroundColorHover={"#1BAA88"}
                                                    textButton={"Finalizar evaluación"}
                                                    typeButton={"button"}
                                                    functionOnClick={() => { handleOpenModalFirm(); }}
                                                />
                                            }
                                        </Box>
                                    </Stack>
                                </Grid>



                            </Grid>
                        </Grid>
                    </Box>
                </Container>

                <Dialog
                    open={openModalFirm}
                    onClose={handleCloseModalFirm}
                    maxWidth="md"
                    fullWidth={true}
                    sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                >
                    <DialogTitleControla titleText='Firmar y finalizar examen' functionOnClose={handleCloseModalFirm} />
                    <form onSubmit={handleFirm}>
                        <DialogContent >
                            <Typography color={blue} fontSize={17}
                                sx={{
                                    userSelect: 'none'
                                }}>
                                Recuerda que con tu firma estas marcando tu asistencia a esta capacitación.
                            </Typography>
                            <InputLabel
                                sx={{
                                    paddingTop: '8px',
                                    fontSize: '18px',
                                    color: '#305AD9',
                                    fontWeight: 'bold'
                                }}> Califica esta capacitación (donde 1 es muy mala y 5 es muy buena) </InputLabel>
                            <Rating value={rating} size="large"
                                onChange={(event, newValue) => {
                                    setRating(newValue);
                                }}
                                required
                            />

                        </DialogContent>
                        <DialogActions>
                            <Stack direction={'row'} spacing={2}>
                                <Box>
                                    <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cancelar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={handleCloseModalFirm} />
                                </Box>
                                <Box>
                                    <ButtonControla iconButton={<HistoryEduOutlined />} backgroundColor={'#169073'} textButton={'Firmar y marcar asistencia'} backgroundColorHover={'#1BAA88'} typeButton={'submit'} />
                                </Box>
                            </Stack>
                        </DialogActions>
                    </form>
                </Dialog>


            </Box >
        </>
    )
}

export default BaselineAnswersTwo