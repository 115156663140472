import React from 'react'
import Box from '@mui/material/Box';

function BadgeRound({initial,text,color}) {
  return (

    <div style={{ width: '100%' }}>
        <Box component="div" sx={{ display: 'inline' }}
        style={{
            color:'white',
            fontSize: '15px',
            backgroundColor: `${color}`,
            textAlign: 'center',
            width: '36px',
            borderRadius: '20px',
            padding: '6px 10px 5px 10px',
            position: 'relative',
            marginRight: '7px',
        }}
        
        >
            {initial}
        </Box>
        <Box component="div" sx={{ display: 'inline' }}

        style={{
            color:'#034AFF',
            fontSize: '18px',
        }}

        >
            {text}
        </Box>
    </div>
  )
}

export default BadgeRound