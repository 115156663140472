import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function getMode() {
    const url = 'committee/electionProcess/mode/get';
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function getType() {
    const url = 'committee/electionProcess/type/get';
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function getElectionType() {
    const url = 'committee/electionProcess/electionType/get';
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function getStatus() {
    const url = 'committee/electionProcess/status/get';
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function getProcessActives() {
    const url = 'committee/electionProcess/process/active/get';
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function storeProcessActives() {
    const url = 'committee/electionProcess/process/active/store';
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'POST', url: url });
}

function getProcessSettings(id_committee_process) {
    const url = 'committee/electionProcess/process/settings/get/' + id_committee_process;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function updateProcessSettings({ id_committee_process, date_start, date_end, id_committee_process_types, id_committee_process_mode, id_committee_process_election_type, id_users_observed, total_members, total_members_titular_workers, total_members_alternate_workers, total_members_titular_companies, total_members_alternate_companies, total_ombudsman_candidate }) {
    const url = 'committee/electionProcess/process/settings/update/' + id_committee_process;
    const data = {
        'date_start': date_start,
        'date_end': date_end,
        'id_committee_process_types': id_committee_process_types,
        'id_committee_process_mode': id_committee_process_mode,
        'id_committee_process_election_type': id_committee_process_election_type,
        'id_users_observed': id_users_observed,
        'total_members': total_members,
        'total_members_titular_workers': total_members_titular_workers,
        'total_members_alternate_workers': total_members_alternate_workers,
        'total_members_titular_companies': total_members_titular_companies,
        'total_members_alternate_companies': total_members_alternate_companies,
        'total_ombudsman_candidate': total_ombudsman_candidate
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

function getProcessSettingsObservations({ id_committee_process, id_committee_process_timeline = null }) {
    const url = 'committee/electionProcess/process/settings/observations/get/' + id_committee_process + (id_committee_process_timeline !== null ? '?id_committee_process_timeline=' + id_committee_process_timeline : '');
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function storeProcessSettingsObservations({ id_committee_process, id_committee_process_timeline, setting_observation }) {
    const url = 'committee/electionProcess/process/settings/observations/store/' + id_committee_process;
    const data = {
        'setting_observation': setting_observation,
        'id_committee_process_timeline': id_committee_process_timeline
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'POST', url: url });
}

function updateProcessStatus({ id_committee_process, id_committee_process_statuses, data_extra_1 = null, data_extra_2 = null }) {
    const url = 'committee/electionProcess/status/update/' + id_committee_process;
    const data = {
        'id_committee_process_statuses': id_committee_process_statuses,
        'data_extra_1': data_extra_1,
        'data_extra_2': data_extra_2
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

function getElectoralComissionPosition() {
    const url = 'committee/electionProcess/electoralComission/position/get';
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function getElectoralComissionStatus() {
    const url = 'committee/electionProcess/electoralComission/status/get';
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function getElectoralComission({ id_committee_process }) {
    const url = 'committee/electionProcess/electoralComission/get/' + id_committee_process;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function storeElectoralComission({ id_committee_process, id_users, id_committee_process_electoral_commission_position }) {
    const url = 'committee/electionProcess/electoralComission/store/' + id_committee_process;
    const data = {
        'id_users': id_users,
        'id_committee_process_electoral_commission_position': id_committee_process_electoral_commission_position
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'POST', url: url });
}

function updateElectoralCommissionStatus({ id_committee_process, id_users, id_committee_process_electoral_commission_status }) {
    const url = 'committee/electionProcess/electoralComission/update/status/' + id_committee_process;
    const data = {
        'id_users': id_users,
        'id_committee_process_electoral_commission_status': id_committee_process_electoral_commission_status
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

function deleteElectoralCommission({ id_committee_process, id_users }) {
    const url = 'committee/electionProcess/electoralComission/delete/' + id_committee_process;
    const data = {
        'id_users': id_users
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'DELETE', url: url });
}

function getElectoralComissionUser({ id_committee_process }) {
    const url = 'committee/electionProcess/electoralComission/user/get/' + id_committee_process;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function getTimeline({ id_committee_process }) {
    const url = 'committee/electionProcess/timeline/get/' + id_committee_process;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function getTimelineStatuses({ id_committee_process, id_committee_process_statuses }) {
    const url = 'committee/electionProcess/timeline/get/status/' + id_committee_process + '?id_committee_process_statuses=' + id_committee_process_statuses;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}


function updateTimeline({ id_committee_process_timeline, timestamp_start, timestamp_end, timestamp_expiration }) {
    const url = 'committee/electionProcess/timeline/update/' + id_committee_process_timeline;
    const data = {
        'timestamp_start': timestamp_start,
        'timestamp_end': timestamp_end,
        'timestamp_expiration': timestamp_expiration
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

function getProcessCandidates({ id_committee_process }) {
    const url = 'committee/electionProcess/candidates/get/' + id_committee_process;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}


async function storeProcessCandidates({ id_committee_process, id_users, document_1, document_2, document_3 }) {
    if (document_1 !== null && document_1 !== undefined) {
        document_1 = await uploadFileCompanies(document_1, 'committee/' + id_committee_process + '/processElection/candidates/' + id_users + '/');
    }
    if (document_2 !== null && document_2 !== undefined) {
        document_2 = await uploadFileCompanies(document_2, 'committee/' + id_committee_process + '/processElection/candidates/' + id_users + '/');
    }
    if (document_3 !== null && document_3 !== undefined) {
        document_3 = await uploadFileCompanies(document_3, 'committee/' + id_committee_process + '/processElection/candidates/' + id_users + '/');
    }
    const url = 'committee/electionProcess/candidates/store/' + id_committee_process;
    const data = {
        'id_users': id_users,
        'document_1': document_1,
        'document_2': document_2,
        'document_3': document_3
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'POST', url: url });
}

function updateProcessCandidatesStatus({ id_committee_process, id_users, id_committee_process_candidates_statuses }) {
    const url = 'committee/electionProcess/candidates/update/status/' + id_committee_process;
    const data = {
        'id_users': id_users,
        'id_committee_process_candidates_statuses': id_committee_process_candidates_statuses
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}




const MyCommitteeElectionProcess = {
    getMode, getElectionType, getProcessActives, getStatus, getType, storeProcessActives, getProcessSettings, updateProcessSettings, getProcessSettingsObservations, storeProcessSettingsObservations, updateProcessStatus, getElectoralComissionPosition, getElectoralComissionStatus, getElectoralComission, storeElectoralComission, updateElectoralCommissionStatus, deleteElectoralCommission, getElectoralComissionUser, getTimeline, getTimelineStatuses, updateTimeline, getProcessCandidates, storeProcessCandidates, updateProcessCandidatesStatus
}

export default MyCommitteeElectionProcess;
