import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import React from 'react'

function DocSelect({labelText}) {
    const [example, setExample] = React.useState('');

    const handleChange = (event) => {
        setExample(event.target.value);
    }

  return (
    <Box sx={{
        minWidth:120
    }}>
        <FormControl fullwidth>
            <InputLabel>
            {labelText}
            </InputLabel>
            <Select
            value={example}
            label={labelText}
            onChange={handleChange}
            
            >
                <MenuItem value={example}>Valor</MenuItem>
            </Select>
        </FormControl>
    </Box>
  )
}

export default DocSelect