import { React, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import {
  Grid,
  Button,
  Tabs,
  Tab,
  Typography,
  Backdrop,
  Snackbar,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputLabel,
  InputBase,
  Input,
  Link,
  MenuItem,
  TextField,
} from "@mui/material";
import {
  Add,
  InfoOutlined,
  ArrowBackIosNew,
  ArrowForwardIos,
  SearchOutlined,
  BorderColorOutlined,
  Archive,
  Delete,
  Send,
  Unarchive,
} from "@mui/icons-material";
import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import SideNav from "../../../../components/sidenav/sideNav";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import VisibilityOutlined from "@mui/icons-material/VisibilityOutlined";
import InputControla from "../../../../components/textfields/inputControla";
import LabelControla from "../../../../components/textfields/labelControla";
import Stack from "@mui/material/Stack";
import MyIperc from "../../../../api/MyIperc";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import CloseIcon from "@mui/icons-material/Close";
import ButtonControla from "../../../../components/buttons/buttonController";
import BannerControla from "../../../../components/cards/bannerControla";
import SearchIcon from '@mui/icons-material/Search';
import useAutocomplete from '@mui/material/useAutocomplete';
import { Search } from "@mui/icons-material";
import DialogTitleControla from "../../../../components/dialog/dialogTitleControla";
import LoadingControla from "../../../../components/load/loadingControla";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";

const colorTitle = "#9191B0";
const skyblue = "#2D9CDB";
const blue = "#034AFF";
const purple = "#8B8BD8";
const SearchTextField = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: "white",
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
}));

function Iperc() {
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };
  ////////STYLED COMPONENTS/////////////////
  // input buscar Input inicio



  const StyledModalTitle = styled('h2')({
    color: purple
  });
  const StyledModalSubtitle = styled('h3')({
    fontWeight: 'bold'
  });
  // input buscar fin

  ////////////////////STYLED COMPONENTS

  /**** Message Alert */
  const [stateAlert, setStateAlert] = useState({
    openAlert: false,
    vertical: "top",
    horizontal: "center",
    severityAlert: "success",
    messageAlert: "",
  });

  const { severityAlert, messageAlert, vertical, horizontal, openAlert } =
    stateAlert;

  const handleClickAlert = (severityAlert, messageAlert) => {
    setStateAlert({
      openAlert: true,
      vertical: "top",
      horizontal: "right",
      severityAlert: severityAlert,
      messageAlert: messageAlert,
    });
  };

  const handleCloseAlert = () => {
    setStateAlert({ ...stateAlert, openAlert: false });
  };
  /*********** */

  const [documentType, setDocumentType] = useState(0);

  const handleChangeDocumentType = (event, newValue) => {
    setDocumentType(newValue);
  };
  const currentTime = new Date();
  const [year, setYear] = useState(currentTime.getFullYear());

  const [rowsActives, setRowsActives] = useState([]);
  const [rowsArchives, setRowsArchives] = useState([]);

  const [idIpercEdit, setIdIpercEdit] = useState("");
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [area, setArea] = useState("");
  const [documentUrl, setDocumentUrl] = useState("");
  const [timestampApproval, setTimestampApproval] = useState("");
  const [timestampRevision, setTimestampRevision] = useState("");

  const [openModalUpload, setOpenModalUpload] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalInfo, setOpenModalInfo] = useState(false);


  // DELETE IPERC
  const [openDelete, setOpenDelete] = useState(false);

  const handleClickOpenDelete = (idiperc) => {
    setOpenDelete(true);
    setIdIpercEdit(idiperc);
  };
  const handleCloseDelete = () => {
    setOpenDelete(false);
  };
  //
  const handleOpenModalUpload = () => {
    setName(null);
    setCode(null);
    setArea(null);
    setDocumentUrl(null);
    setTimestampApproval(null);
    setTimestampRevision(null);
    setOpenModalUpload(true);
  };

  const handleCloseModalUpload = () => {
    setOpenModalUpload(false);
  };

  const handleOpenModalEdit = () => {
    setOpenModalEdit(true);
  };

  const handleCloseModalEdit = () => {
    setOpenModalEdit(false);
  };

  const handleOpenModalInfo = () => {
    setOpenModalInfo(true);
  }

  const handleCloseModalInfo = () => {
    setOpenModalInfo(false);
  }

  const handleGetIperc = async (inputYear = undefined) => {
    const response = await MyIperc.iperc({ year: (inputYear == undefined ? year : inputYear) });
    if (inputYear != undefined) {
    }
    if (response.success === false) {
      // setAlertMessage(response.message);
      // setOpenLoadView(false);
    } else {
      const iperc_no_archived = response.iperc_no_archived;
      const iperc_archived = response.iperc_archived;
      setRowsActives(iperc_no_archived);
      setRowsArchives(iperc_archived);
      if (inputYear != undefined) {
        setDocumentType(0);
      }
      setRowsIpercSearch(iperc_no_archived);
      setRowsIpercSearchArchived(iperc_archived);

    }
    return response;
  };

  const [searchName, setSearchName] = useState('prueba');
  const [searchArea, setSearchArea] = useState('');
  const searchIperc = () => {
    console.log(searchName)
    const searchName_tmp = searchName.toLowerCase();
    const result_tmp = [];
    rowsActives.map((row) => (
      row.name.toLowerCase() === searchName_tmp ? result_tmp.push(row) : console.log('nope')
    ));
    console.log(result_tmp)
  }

  const handlePostCreateIperc = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    const response = await MyIperc.createIperc({
      name,
      code,
      area,
      timestamp_approval: timestampApproval,
      timestamp_revision: timestampRevision,
      document_url: documentUrl,
    });
    if (response.success === true) {
      handleGetIperc();
      handleCloseBackdrop();
      handleCloseModalUpload();
      handleClickAlert("success", "Se guardo correctamente!");
      // window.location.href = '/editAts?id_ats=' + response.id_ats;
    } else {
      handleClickAlert("error", "Error al guardar el documento!");
    }

    return response;
  };

  const StyledAddButton = styled(Button)({
    textTransform: "none",
    backgroundColor: skyblue,
    borderRadius: 16,
  });

  const handleShowDocumentUrl = (documentUrlRow) => {
    if (
      documentUrlRow === null ||
      documentUrlRow === undefined ||
      documentUrlRow === ""
    ) {
      alert("No se encontro el documento");
    } else {
      window.open(documentUrlRow, "_blank");
    }
  };

  const handleModalEdit = async (idIperc) => {
    const response = await MyIperc.editIperc({ id_iperc: idIperc });
    if (response.success === false) {
    } else {
      setIdIpercEdit(response.id_iperc);
      setName(response.name);
      setArea(response.area);
      if (response.timestamp_approval == null) {
        const dateApproval = '';
        setTimestampApproval(dateApproval);
      } else {
        const dateApproval = response.timestamp_approval.split(" ");
        setTimestampApproval(dateApproval[0]);
      }
      if (response.timestamp_revision !== null) {
        const dateRevision = response.timestamp_revision.split(" ");
        setTimestampRevision(dateRevision[0]);
      }
      handleOpenModalEdit();
    }
    return response;
  };

  const handlePostUpdateIperc = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);

    const response = await MyIperc.updateIperc({
      id_iperc: idIpercEdit,
      name,
      area,
      timestamp_approval: timestampApproval,
      timestamp_revision: timestampRevision,
      document_url: documentUrl,
    });
    if (response.success === true) {
      handleCloseModalEdit();
      handleCloseBackdrop();

      handleClickAlert("success", "Se actualizó el ESTADO del IPERC");
      setIdIpercEdit(null);
      handleGetIperc();
    } else {
      handleClickAlert("error", "Ha ocurrido un error");
    }
    return response;
  };

  const handlePostUpdateIpercStatuses = async (
    idIpercEditStatuses,
    idIpercStatuses
  ) => {
    setOpenBackdrop(!openBackdrop);

    const response = await MyIperc.updateIpercStatuses({
      id_iperc: idIpercEditStatuses,
      id_iperc_statuses: idIpercStatuses,
    });
    if (response.success === true) {
      // alert("Se actualizado correctamente el IPERC");
      handleGetIperc();
      handleCloseBackdrop();
      handleClickAlert("success", "Se ha actualizado el estado del IPERC");
    } else {
      handleClickAlert("error", "Ha ocurrido un error");
      // alert("Ha ocurrido un error");
    }
    return response;
  };

  const handleUpdateIpercArchived = async (idIpercEditArchived, archived) => {
    setOpenBackdrop(!openBackdrop);

    const response = await MyIperc.updateIpercArchived({
      id_iperc: idIpercEditArchived,
      archived: archived,
    });
    if (response.success === true) {
      // alert("Se actualizado correctamente el IPERC");
      handleCloseBackdrop();
      handleGetIperc();
      handleClickAlert("success", "IPERC actualizó su estado de Archivos!");
    } else {
      handleClickAlert("error", "Ha ocurrido un error");
      // alert("Ha ocurrido un error");
    }
    return response;
  };

  const handleDeleteIperc = async () => {
    setOpenBackdrop(!openBackdrop);

    const response = await MyIperc.deleteIperc({ id_iperc: idIpercEdit });
    if (response.success === true) {
      // alert("Se elimino correctamente el IPERC");
      handleGetIperc();
      handleCloseDelete();
      handleCloseBackdrop();
      handleClickAlert("success", "Se elimino correctamente el IPERC");
    } else {
      handleClickAlert("error", "Ha ocurrido un error");
      // alert("Ha ocurrido un error");
    }
    return response;
  };

  const handleIncreaseYear = (event, newValue) => {
    setYear(year + 1);
    handleGetIperc(year + 1);
  };

  const handleDecreaseYear = (event, newValue) => {
    setYear(year - 1);
    handleGetIperc(year - 1);
  };

  const Listbox = styled('ul')(({ theme }) => ({
    width: '100%',
    margin: 0,
    padding: 0,
    zIndex: 1,
    position: 'absolute',
    listStyle: 'none',
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#000',
    overflow: 'auto',
    maxHeight: 300,
    border: '1px solid rgba(0,0,0,.25)',
    '& li.Mui-focused': {
      backgroundColor: '#4a8df6',
      color: 'white',
      cursor: 'pointer',
    },
    '& li:active': {
      backgroundColor: '#2977f5',
      color: 'white',
    },
  }));

  // buscar mensaje
  const [rowsIpercSearch, setRowsIpercSearch] = useState([]);
  const [rowsIpercSearchArchived, setRowsIpercSearchArchived] = useState([]);
  const [textSearch, setTextSearch] = useState('');

  const handleSearchMessage = (searchMessage) => {
    // setTextSearch(searchMessage);
    if (documentType !== 1) {
      console.log('Buscando en Activos')
      setRowsIpercSearch(rowsActives);
      if (searchMessage !== "") {
        let expresion = new RegExp(`${searchMessage}.*`, "i");
        let ipercSearch = rowsActives.filter(
          x =>
            expresion.test(x.area)
        );
        console.log(ipercSearch);
        console.log(rowsActives)
        setRowsIpercSearch(ipercSearch);
      } else {
        setRowsIpercSearch(rowsActives);
      }
    } else {
      console.log('Buscando en Archivos')
      setRowsIpercSearchArchived(rowsArchives);
      if (searchMessage !== "") {
        let expresion = new RegExp(`${searchMessage}.*`, "i");
        let ipercSearch = rowsArchives.filter(
          x =>
            expresion.test(x.area)
        );
        console.log(ipercSearch);
        console.log(rowsArchives)
        setRowsIpercSearchArchived(ipercSearch);
      } else {
        setRowsIpercSearchArchived(rowsArchives);
      }
    }

    // return true
    // const handleSearchEpp = (searchEpp) => {
    //   setRowsEpps(rowsEppsOriginal);
    //   if (searchEpp !== '') {
    //       let expresion = new RegExp(`${searchEpp}.*`, "i");
    //       let eppsSearch = rowsEpps.filter(x => expresion.test(x.epp));
    //       setRowsEpps(eppsSearch);
    //   }
    // };
  };

  /////////////////////// ALERT
  const [idIperc, setIdIperc] = useState(null);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState('');
  const [contentText, setContentText] = useState('');
  const [buttonTextConfirm, setButtonTextConfirm] = useState('');
  const [buttonActionType, setButtonActionType] = useState('');
  const [openLoading, setOpenLoading] = useState(false);
  const [typeAlert, setTypeAlert] = useState('success');


  const handleOpenModalConfirm = (id, titleText, contentText, buttonTextConfirm, buttonActionType) => {
    setIdIperc(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  }

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  }

  const handleActionConfirm = () => {
    switch (buttonActionType) {
      case 'delete':
        handleDeleteIperc();
        break;
      // case 'sendWorkers':
      //   sendToWorkers(idPetar);
      //   break;
      // case 'public':
      //   publishPetar(idIperc);
      //   break;
      case 'archive':
        handleUpdateIpercArchived(idIperc, 1);
        break;
      case 'unarchive':
        handleUpdateIpercArchived(idIperc, 0);
        break;
    }
    setOpenModalConfirm(false);
  }
  ///////////////////// ALERT

  useEffect(() => {
    handleGetIperc();
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav />
      <LoadingControla openLoading={openLoading} openAlert={openAlert} handleCloseAlert={handleCloseAlert} messageAlert={messageAlert} typeAlert={typeAlert} />
      <DialogConfirmControla openModalConfirm={openModalConfirm} handleCloseModalConfirm={handleCloseModalConfirm} titleText={titleText} contentText={contentText} buttonTextConfirm={buttonTextConfirm} handleActionConfirm={handleActionConfirm} />

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleCloseAlert}
        key={vertical + horizontal}
        autoHideDuration={3000}
      >
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                handleCloseAlert();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity={severityAlert}
          size="small"
        >
          {messageAlert}
        </Alert>
      </Snackbar>

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={6} md={6}>

              {/* <SubtitleText
                text={"Procedimientos"}
                color={colorTitle}
              ></SubtitleText> */}
            </Grid>
            <Grid item xd={6} md={6} style={{ textAlign: "right" }}>
              <StyledAddButton
                variant="text"
                startIcon={<InfoOutlined />}
                disableElevation
                size="large"
                style={{
                  backgroundColor: "#305AD9",
                  borderRadius: "15px",
                  color: "white",
                  padding: "8px 25px",
                }}
                onClick={() => (handleOpenModalInfo())}
              >
                ¿Qué debo saber sobre la matriz del IPERC?
              </StyledAddButton>
            </Grid>
            <Grid item xs={12} md={12} sx={{ mt: 2 }}>

              <BannerControla image='/assets/images/banners/documents/ipercBanner.png' color2='#2F67BC' color1='#8FBCFF' text='IPERC' />

            </Grid>
          </Grid>
          <Grid
            container
            spacing={2}
            sx={{ mt: 2 }}
            justifyContent="space-between"
            direction="row"
            alignItems="center"
          >
            <Grid item>
              <ButtonControla
                roles={[1, 7, 8]}
                iconButton={<Add sx={{ color: "white" }} />}
                backgroundColor={"#169073"}
                backgroundColorHover={"#1BAA88"}
                textButton={"Agregar IPERC"}
                functionOnClick={handleOpenModalUpload}
              />
            </Grid>
            <Grid>
              <SearchTextField
                onChange={({ target }) => { handleSearchMessage(target.value) }}
              >
                <SearchIconWrapper>
                  <Search />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder="Buscar ..."
                  defaultValue={textSearch}
                  inputProps={{ "aria-label": "search" }}
                />
              </SearchTextField>
            </Grid>
            <Grid container spacing={2} item xs={12} sm={12} md={4} lg={3} xl={3} alignItems="center" justifyContent={"right"}>
              <Grid item xs={3} sm={2} md={4} lg={3} xl={3}>
                <InputLabel
                  sx={{
                    padding: "8px 0px 8px 0px",
                    fontSize: "14px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  {" "}
                  Periodo:
                </InputLabel>
              </Grid>
              <Grid item xs={3} sm={3} md={5} lg={4} xl={4}>
                <InputBase
                  type="number"
                  value={year}
                  onChange={handleGetIperc}
                  readOnly
                  sx={{
                    width: "100%",
                    padding: "4px 10px 4px 10px",
                    backgroundColor: "white",
                    color: "#305AD9",
                    fontSize: "14px",
                    borderRadius: "8px",
                  }}
                ></InputBase>
              </Grid>
              <Grid item xs={2} sm={1} md={2} lg={2} xl={2}>
                <IconButton
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "15px",
                    color: "#305AD9",
                  }}
                  onClick={handleDecreaseYear}
                >
                  <ArrowBackIosNew />
                </IconButton>
              </Grid>
              <Grid item xs={2} sm={1} md={2} lg={2} xl={2}>
                <IconButton
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "15px",
                    color: "#305AD9",
                  }}
                  onClick={handleIncreaseYear}
                >
                  <ArrowForwardIos />
                </IconButton>
              </Grid>
            </Grid>
            {/* <Grid item justifyContent="flex-end">
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                background="#000"
                sx={{ mr: 2 }}
                href={"/mailbox/mailboxtwo"}
                style={{
                  backgroundColor: "white",
                  borderRadius: "15px",
                  color: "#305AD9",
                }}
              >
                <ArrowBackIosNew />
              </IconButton>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="open drawer"
                background="#000"
                href={"/mailbox/mailboxone"}
                style={{
                  backgroundColor: "white",
                  borderRadius: "15px",
                  color: "#305AD9",
                }}
              >
                <ArrowForwardIos />
              </IconButton>
            </Grid> */}
          </Grid>

          <Grid container sx={{ mt: 2 }}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Paper sx={{ overflow: "hidden" }}>
                <TableContainer>
                  <Table
                    stickyHeader
                    sx={{ width: "100%", minWidth: "700px" }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        {/* <TableCell align="center"><IconButton><TaskAlt /></IconButton></TableCell> */}
                        <TableCell align="center" sx={{ color: blue }}>Nombre del documento</TableCell>
                        <TableCell align="center" sx={{ color: blue }}>Área</TableCell>
                        <TableCell align="center" sx={{ color: blue }}>Estado</TableCell>
                        <TableCell>
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            <Tabs
                              value={documentType}
                              onChange={handleChangeDocumentType}
                              aria-label="Tipos de documento"
                            >
                              <Tab label="Activados" />
                              <Tab label="Archivados" />
                            </Tabs>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{ display: documentType !== 0 ? "none" : "" }}
                    >
                      {rowsIpercSearch
                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((rowsActives) => (
                          <TableRow
                            hover
                            key={rowsActives.id_iperc}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            {/* <TableCell align="center"><Checkbox /></TableCell> */}

                            <TableCell component="th" scope="row" sx={{ color: purple }} align="center">
                              {rowsActives.name}
                            </TableCell>
                            <TableCell component="th" scope="row" sx={{ color: purple }} align="center">
                              {rowsActives.area === null ? 'Sin Área' : rowsActives.area}
                            </TableCell>
                            <TableCell component="th" scope="row" sx={{ color: purple }} align="center">
                              {rowsActives.status}
                            </TableCell>
                            <TableCell align="right">
                              <Stack
                                direction="row"
                                justifyContent="end"
                                alignItems="center"
                              >
                                {rowsActives.id_iperc_statuses === 1 ? (
                                  <>
                                    <ButtonIconControla
                                      roles={[1, 7, 8]}
                                      icon={<Send sx={{ color: "white" }} />}
                                      backgroundColor={"#169073"}
                                      backgroundColorHover={"#1AA483"}
                                      textTooltip={"Enviar a trabajadores"}
                                      functionOnClick={() =>
                                        handlePostUpdateIpercStatuses(
                                          rowsActives.id_iperc,
                                          2
                                        )
                                      }
                                    />
                                    <ButtonIconControla
                                    roles={[1,7,8]}
                                      icon={
                                        <BorderColorOutlined
                                          sx={{ color: "white" }}
                                        />
                                      }
                                      backgroundColor={"#2D9CDB"}
                                      backgroundColorHover={"#33AEF4"}
                                      textTooltip={"Editar"}
                                      functionOnClick={() =>
                                        handleModalEdit(rowsActives.id_iperc)
                                      }
                                    />
                                  </>
                                ) : rowsActives.id_iperc_statuses === 2 ? (
                                  <>
                                    <ButtonIconControla
                                      roles={[1, 7, 8]}
                                      icon={<Send sx={{ color: "white" }} />}
                                      backgroundColor={"#169073"}
                                      backgroundColorHover={"#1AA483"}
                                      textTooltip={"Enviar a comite"}
                                      functionOnClick={() =>
                                        handlePostUpdateIpercStatuses(
                                          rowsActives.id_iperc,
                                          3
                                        )
                                      }
                                    />
                                    <ButtonIconControla
                                      roles={[1, 7, 8]}
                                      icon={
                                        <BorderColorOutlined
                                          sx={{ color: "white" }}
                                        />
                                      }
                                      backgroundColor={"#2D9CDB"}
                                      backgroundColorHover={"#33AEF4"}
                                      textTooltip={"Editar"}
                                      functionOnClick={() =>
                                        handleModalEdit(rowsActives.id_iperc)
                                      }
                                    />
                                  </>
                                ) : rowsActives.id_iperc_statuses === 3 ? (
                                  <>
                                    <ButtonIconControla
                                      roles={[1, 7, 8]}
                                      icon={
                                        <FileUploadOutlinedIcon
                                          sx={{ color: "white" }}
                                        />
                                      }
                                      backgroundColor={"#169073"}
                                      backgroundColorHover={"#1AA483"}
                                      textTooltip={"Publicar"}
                                      functionOnClick={() =>
                                        handlePostUpdateIpercStatuses(
                                          rowsActives.id_iperc,
                                          4
                                        )
                                      }
                                    />
                                    <ButtonIconControla
                                      roles={[1, 7, 8]}
                                      icon={
                                        <BorderColorOutlined
                                          sx={{ color: "white" }}
                                        />
                                      }
                                      backgroundColor={"#2D9CDB"}
                                      backgroundColorHover={"#33AEF4"}
                                      textTooltip={"Editar"}
                                      functionOnClick={() =>
                                        handleModalEdit(rowsActives.id_iperc)
                                      }
                                    />
                                  </>
                                ) : (
                                  <></>
                                )}

                                <ButtonIconControla
                                  ///pueden ver todos
                                  icon={
                                    <VisibilityOutlined
                                      sx={{ color: "white" }}
                                    />
                                  }
                                  backgroundColor={"#305AD9"}
                                  backgroundColorHover={"#0E4AFF"}
                                  textTooltip={"Ver"}
                                  directionUrl={rowsActives.document_url} directionTarget={'_blank'}
                                />
                                <ButtonIconControla
                                  roles={[1, 7, 8]}
                                  icon={<Archive sx={{ color: "white" }} />}
                                  backgroundColor={"#F2994A"}
                                  backgroundColorHover={"#FF881E"}
                                  textTooltip={"Archivar"}
                                  // functionOnClick={() =>
                                  //   handleUpdateIpercArchived(
                                  //     rowsActives.id_iperc,
                                  //     1
                                  //   )
                                  // }
                                  functionOnClick={() => handleOpenModalConfirm(rowsActives.id_iperc, '¿Esta seguro de Archivar este Iperc?', 'Una vez archivado no podrá editar nuevamente el Iperc:  \"' + rowsActives.name + '\"', 'Si, archivar', 'archive')}
                                />
                                {rowsActives.id_iperc_statuses === 1 ?
                                  <ButtonIconControla
                                    roles={[1, 7, 8]}
                                    icon={<Delete sx={{ color: "white" }} />}
                                    backgroundColor={"#EB5757"}
                                    backgroundColorHover={"#FF4040"}
                                    textTooltip={"Eliminar"}
                                    functionOnClick={() =>
                                      handleClickOpenDelete(rowsActives.id_iperc)
                                    }
                                  /> : <></>
                                }

                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                    <TableBody
                      sx={{ display: documentType !== 1 ? "none" : "" }}
                    >
                      {rowsIpercSearchArchived
                        // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((rowsArchives) => (
                          <TableRow
                            hover
                            key={rowsArchives.id_iperc}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            {/* <TableCell align="center"><Checkbox /></TableCell> */}

                            <TableCell component="th" scope="row" sx={{ color: purple }} align="center">
                              {rowsArchives.name}
                            </TableCell>
                            <TableCell sx={{ color: purple }} align="center">
                              {rowsArchives.area === null ? 'Sin Área' : rowsArchives.area}
                            </TableCell>
                            <TableCell component="th" scope="row" sx={{ color: purple }} align="center">
                              {rowsArchives.status}
                            </TableCell>
                            <TableCell align="right">
                              <Stack
                                direction="row"
                                justifyContent="end"
                                alignItems="center"
                              >
                                <ButtonIconControla
                                ///Pueden ver todos
                                  icon={
                                    <VisibilityOutlined
                                      sx={{ color: "white" }}
                                    />
                                  }
                                  backgroundColor={"#305AD9"}
                                  backgroundColorHover={"#0E4AFF"}
                                  textTooltip={"Ver"}
                                  directionUrl={rowsArchives.document_url} directionTarget={'_blank'}
                                />
                                <ButtonIconControla
                                  roles={[1, 7, 8]}
                                  icon={<Unarchive sx={{ color: "white" }} />}
                                  backgroundColor={"#F2994A"}
                                  backgroundColorHover={"#FF881E"}
                                  textTooltip={"Desarchivar"}
                                  // functionOnClick={() =>
                                  //   handleUpdateIpercArchived(
                                  //     rowsArchives.id_iperc,
                                  //     0
                                  //   )
                                  // }
                                  functionOnClick={() => handleOpenModalConfirm(rowsArchives.id_iperc, '¿Esta seguro de Desarchivar este Iperc?', 'Una vez deasarchivado podrá editar nuevamente el Iperc:  \"' + rowsArchives.name + '\"', 'Si, desarchivar', 'unarchive')}
                                />
                                {/* <ButtonIconControla
                                  icon={<Delete sx={{ color: "white" }} />}
                                  backgroundColor={"#EB5757"}
                                  backgroundColorHover={"#FF4040"}
                                  textTooltip={"Eliminar"}
                                  functionOnClick={
                                    () =>
                                      handleClickOpenDelete(
                                        rowsArchives.id_iperc
                                      )
                                    // handleDeleteIperc(rowsArchives.id_iperc)
                                  }
                                /> */}
                              </Stack>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </Grid>
          </Grid>
        </Box>

        <Dialog
          open={openModalUpload}
          onClose={handleCloseModalUpload}
          maxWidth="sm"
          fullWidth={true}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        >
          <DialogTitle>
            <Typography
              id="modal-modal-title"
              sx={{
                color: "#1638F2",
                fontWeight: "bold",
                fontSize: "22px",
              }}
            >
              Agregar nuevo IPERC
            </Typography>
          </DialogTitle>

          <form onSubmit={handlePostCreateIperc}>
            <DialogContent sx={{ paddingTop: "0" }}>
              <InputControlaVertical
                text={"Nombre"}
                inputType={"text"}
                inputValue={setName}
                modalType={true}
                required
              />
              <InputControlaVertical
                text={"Área"}
                inputType={"text"}
                inputValue={setArea}
                modalType={true}
                required
              />
              <InputControlaVertical
                text={"Fecha de aprobación"}
                inputType={"date"}
                inputValue={setTimestampApproval}
                modalType={true}
              />
              <InputControlaVertical
                text={"Fecha de elaboración/revisión"}
                inputType={"date"}
                inputValue={setTimestampRevision}
                modalType={true}
              >
              </InputControlaVertical>
              <InputControlaVertical
                text={"Subir documento"}
                inputType={"file"}
                inputValue={setDocumentUrl}
                modalType={true}
                required
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                // color="success"
                sx={{
                  textTransform: "none",
                  color: "#6969D6",
                  backgroundColor: "#CBCBFF",
                  borderRadius: "16px",
                  marginRight: "10px",
                }}
                onClick={handleCloseModalUpload}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="success"
                sx={{
                  textTransform: "none",
                  // backgroundColor: '#169073',
                  borderRadius: "16px",
                  marginRight: "10px",
                }}
              >
                Crear
              </Button>
            </DialogActions>
          </form>
        </Dialog>

        <Dialog
          open={openModalEdit}
          onClose={handleCloseModalEdit}
          maxWidth="sm"
          fullWidth={true}
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        >
          <DialogTitle>
            <Typography
              id="modal-modal-title"
              sx={{
                color: "#1638F2",
                fontWeight: "bold",
              }}
            >
              Editar IPERC
            </Typography>
          </DialogTitle>
          <form onSubmit={handlePostUpdateIperc}>
            <DialogContent>
              <InputControlaVertical
                text={"Nombre"}
                inputType={"text"}
                inputValue={setName}
                modalType={true}
                value={name}
                required
              />
              <InputControlaVertical
                text={"Área"}
                inputType={"text"}
                inputValue={setArea}
                modalType={true}
                value={area}
                required
              />
              <InputControlaVertical
                text={"Fecha de aprobación"}
                inputType={"date"}
                inputValue={setTimestampApproval}
                modalType={true}
                value={timestampApproval}
                required
              />
              <InputControlaVertical
                text={"Fecha de elaboración/revisión"}
                inputType={"date"}
                inputValue={setTimestampRevision}
                modalType={true}
                value={timestampRevision}
                required
              >
              </InputControlaVertical>
              <InputControlaVertical
                text={"Subir documento"}
                inputType={"file"}
                inputValue={setDocumentUrl}
                modalType={true}
              />
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                // color="success"
                sx={{
                  textTransform: "none",
                  color: "#6969D6",
                  backgroundColor: "#CBCBFF",
                  borderRadius: "16px",
                  marginRight: "10px",
                }}
                onClick={handleCloseModalEdit}
              >
                Cancelar
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="success"
                sx={{
                  textTransform: "none",
                  // backgroundColor: '#169073',
                  borderRadius: "16px",
                  marginRight: "10px",
                }}
              >
                Actualizar
              </Button>
            </DialogActions>
          </form>
        </Dialog>

        <Dialog
          sx={{
            severity: severityAlert,
            zIndex: (theme) => theme.zIndex.drawer + 0,
          }}
          open={openDelete}
          onClose={handleCloseDelete}
          maxWidth="sm"
          fullWidth={true}
        >
          <DialogTitle
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
            }}
          >
            Eliminar IPERC
          </DialogTitle>
          <DialogContent>
            <Typography>El IPERC se eliminará de forma permanente</Typography>
            <Typography>¿Deseas continuar?.</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseDelete}>Cancelar</Button>
            <Button onClick={handleDeleteIperc}>Si, eliminar</Button>
          </DialogActions>
        </Dialog>
      </Container>
      <Dialog
        open={openModalInfo}
        onClose={handleCloseModalInfo}
        maxWidth="lg"
        fullWidth={true}
        sx={{
          padding: '20px'
        }} >
        <DialogTitleControla
          titleText="IDENTIFICACIÓN DE PELIGROS Y EVALUACIÓN DE RIESGOS Y MEDIDAS DE CONTROL - IPERC"
        />
        <DialogContent>
          <Grid container
          >
            <Grid item md={12}>
              <StyledModalTitle >
                1. Introducción:
              </StyledModalTitle>
            </Grid>
            <Grid item>
              Esta sección tiene como objetivo evaluar los riesgos vinculados a los peligros derivados de la actividad económica de EMPRESA E.I.R.L.
              La elaboración y mejora de la matriz IPERC forma parte de la documentación del Sistema de Gestión en Seguridad y Salud Ocupacional, por lo que debe estar siempre documentada.
            </Grid>
            <Grid item md={12}>
              <StyledModalTitle>
                2. Consideraciones generales:
              </StyledModalTitle>
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle >
                ¿Qué es una matriz IPERC?
              </StyledModalSubtitle>
            </Grid>
            <Grid item>
              Documento que contiene los procesos sistemáticos utilizados para identificar los peligros y definir sus características, así como evaluar los riesgos y sus impactos e implementar controles adecuados, con la finalidad de reducir o disminuir los riesgos a niveles establecidos en las normas legales vigentes.
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle>
                ¿Cada cuánto se debe actualizar la matriz IPERC?
              </StyledModalSubtitle>
            </Grid>
            <Grid item>
              De manera general, la matriz IPERC debe ser actualizada una (01) vez al año. Sin embargo, existen tres condiciones en que la matriz debe ser actualizada:
            </Grid>
            <Grid item>
              <ol type="a">
                <li>
                  En caso de ocurrencia de accidentes o incidentes peligrosos que hayan producido daño a la salud y seguridad de los trabajadores, solo si la causa del accidente o incidente está asociado a un peligros no identificado.
                </li>
                <li>
                  En caso de cambios de las condiciones de trabajo (que puede ser cambio en los procesos, introducción de nuevos procesos, cambio de maquinaria, cambios de turnos, etc).
                </li>
                <li>
                  En caso de una enfermedad ocupacional asociada a un peligro no identificado.
                </li>
                <li>
                  En caso de una evaluación que evidencie no conformidades en el Sistema de Gestión de Seguridad y Salud Ocupacional (supervisión, inspecciones, auditorías).
                </li>
              </ol>
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle>
                ¿Qué factores de riesgo y otras consideraciones deben tenerse en cuenta para la elaboración de una matriz IPERC?
              </StyledModalSubtitle>
            </Grid>
            <Grid item>
              Para la elaboración de una matriz IPERC se debe tener en cuenta lo siguiente:
            </Grid>
            <Grid item>
              <ol type="a">
                <li>
                  Condiciones de trabajo rutinarias y no rutinarias.
                </li>
                <li>
                  Situaciones de emergencia.
                </li>
                <li>
                  Condiciones de trabajo existentes o previstas.
                </li>
                <li>
                  Condiciones del medio ambiente de trabajo y la organización del trabajo.
                </li>
                <li>
                  Situación de discapacidad del trabajador.
                </li>
                <li>
                  Evaluación de los factores de riesgo para la procreación.
                </li>
                <li>
                  Enfoque de género.
                </li>
                <li>
                  Protección a las trabajadoras en período de embarazo o lactancia.
                </li>
                <li>
                  Resultados de las evaluaciones de los factores de riesgo físico, químicos, biológicos, ergonómicos y psicosociales.
                </li>
                <li>
                  Resultados de las investigaciones de los accidentes de trabajo y enfermedades ocupacionales.
                </li>
                <li>
                  Datos estadísticos recopilados producto de la vigilancia de la salud colectiva de los trabajadores.
                </li>
                <li>
                  Protección de los adolescentes.
                </li>
              </ol>
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle>
                ¿Qué fuentes de información debo considerar para la elaboración y actualización de la matriz IPERC?
              </StyledModalSubtitle>
            </Grid>
            <Grid item>
              Se deben tener en cuenta las siguientes fuentes:
              <ol type="a">
                <li>
                  Resultados de la línea base.
                </li>
                <li>
                  Resultados de las auditorías.
                </li>
                <li>
                  Resultados de las investigaciones de accidentes e incidentes peligrosos.
                </li>
                <li>
                  Registro de enfermedades ocupacionales de ser el caso.
                </li>
                <li>
                  Riesgos propios de la actividad de la empresa.
                </li>
              </ol>
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle>
                ¿Cómo se identifican los peligros dentro de las actividades de la empresa?
              </StyledModalSubtitle>
            </Grid>
            <Grid item>
              Existen diferentes metodologías para poder realizar el estudio, análisis y evaluación de los riesgos de la empresa, como los métodos cualitativos, cuantitativos, comparativos y generalizados.
              Recomendamos que, para la elaboración de la matriz IPERC, el empleador pueda usar una metodología práctica y sencilla que se adecúe a las actividades de la empresa.
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle>
                ¿Cuál es la jerarquía de controles a aplicar en la matriz IPERC?
              </StyledModalSubtitle>
            </Grid>
            <Grid item>
              Para la aplicación de las medidas de control, se debe tener en cuenta la siguiente jerarquía o priorización:
            </Grid>
            <Grid item>
              <ol type="a">
                <li>
                  Eliminar el peligro.
                </li>
                <li>
                  Sustituir con procesos, operaciones, materiales o equipos menos peligrosos.
                </li>
                <li>
                  Utilizar controles de ingeniería y reorganización del trabajo.
                </li>
                <li>
                  Utilizar controles administrativos, incluyendo la formación o capacitación de los trabajadores de acuerdo al puesto de trabajo a realizar.
                </li>
                <li>
                  Utilizar los equipos de protección personal (EPP) adecuados.
                </li>
              </ol>
              <Grid item>
                <StyledModalTitle md={12}>
                  3. Categorización de los peligros
                </StyledModalTitle>
              </Grid>
              <Grid>
                <a href="/assets/images/info/categorizacion_peligros.png" target="_blank">
                  <ButtonControla
                    textButton={"Ver Categorización"}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                  />
                </a>
              </Grid>
              <Grid item >
                <StyledModalTitle md={12}>
                  4. Fiscalización del IPERC
                </StyledModalTitle>
              </Grid>
            </Grid>
            <Grid item>
              <StyledModalSubtitle md={12}>
                ¿Qué aspectos principales considerará la autoridad ante una actuación inspectiva?
              </StyledModalSubtitle>
            </Grid>
            <Grid item>
              <ul type="none">
                <li>
                  Que todos los peligros por puesto de trabajo, estén identificados en la matriz IPERC. Tener en consideración que hay peligros que por norma deben estar incluidos, como el hostigamiento sexual (peligro psicosocial), biológicos (Covid-19), evaluar los peligros considerando el género, en el caso de personas con discapacidad, mujeres gestantes o en período de lactancia y adolescentes.
                </li>
                <li>
                  Que la evaluación de los riesgos sea coherente con la realidad. Recordar que la autoridad se guía por el Principio de Primacía de la Realidad, señalado en la Ley N° 29783, es decir ante una discrepancia con el documento y la realidad, la autoridad preferirá guiarse por la realidad.
                </li>
              </ul>

            </Grid>
            <Grid item>
              La actualización de la matriz IPERC una vez al año y cuando se den las siguientes condiciones:
            </Grid>
            <Grid item>
              <ol>
                <li>
                  En caso de ocurrencia de accidentes o incidentes peligrosos que hayan producido daño a la salud y seguridad de los trabajadores, solo si la causa del accidente o incidente está asociado a un peligros no identificado.
                </li>
                <li>
                  En caso de cambios de las condiciones de trabajo (que puede ser cambio en los procesos, introducción de nuevos procesos, cambio de maquinaria, cambios de turnos, etc).
                </li>
                <li>
                  En caso de una enfermedad ocupacional asociada a un peligro no identificado.
                </li>
                <li>
                  En caso de una evaluación que evidencie no conformidades en el Sistema de Gestión de Seguridad y Salud Ocupacional (supervisión, inspecciones, auditorías). "
                </li>
              </ol>
            </Grid>
            <Grid item>
              <ul type="none">
                Que la participación de los trabajadores en la elaboración y/o en la actualización de la matriz IPERC esté debidamente documentada y registrada.

                <li>
                  Que la participación (revisión) del Comité de Seguridad y Salud Ocupacional en la elaboración y/o actualización de la matriz IPERC esté debidamente documentada y registrada.
                </li>
                <li>
                  Que las medidas de control hayan sido aplicadas según la jerarquía o priorización.
                </li>
                <li>
                  Que las medidas de control determinadas en la evaluación de riesgos esté implementadas.
                </li>
                <li>
                  Que los trabajadores hayan sido capacitados en prevención de riesgos ocupacionales, en la interpretación y puesta en práctica de la matriz IPERC por puesto de trabajo.
                  Recomendamos contar con la documentación necesaria que pruebe la capacitación (registros, programa anual de seguridad y salud ocupacional).
                </li>
                <li>
                  Que la matriz IPERC esté exhibida en cada área del centro de labores.
                </li>
              </ul>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModalInfo}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        {/* <CircularProgress color="inherit" /> */}
        <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
      </Backdrop>
    </Box>
  );
}

export default Iperc;
