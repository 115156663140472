



import { Add, Close } from "@mui/icons-material";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material";
import { } from "@mui/system";
import { React } from "react";
import ButtonControla from "../buttons/buttonController";

function DialogConfirmControla({ openModalConfirm = false, handleCloseModalConfirm, titleText = '', contentText = '', buttonTextConfirm = '', handleActionConfirm }) {

    return (
        <Dialog
            open={openModalConfirm}
            onClose={handleCloseModalConfirm}
            maxWidth="sm"
            fullWidth={true}
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        >
            <DialogTitle sx={{ paddingBottom: '0' }}>
                <Typography
                    sx={{
                        fontSize: '24px',
                        color: "#1638F2",
                        fontWeight: "bold",
                    }}
                >
                    {titleText}
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseModalConfirm}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent >
                <Typography
                    sx={{
                        fontSize: '18px',
                        color: "#1638F2",
                    }}
                >
                    {contentText}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Stack direction={'row'} spacing={2}>
                    <Box>
                        <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cancelar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={handleCloseModalConfirm} />
                    </Box>
                    <Box>
                        <ButtonControla backgroundColor={'#169073'} textButton={buttonTextConfirm} backgroundColorHover={'#1BAA88'} functionOnClick={handleActionConfirm} />
                    </Box>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}

export default DialogConfirmControla;