import * as React from 'react';

import SubtitleText from '../../components/text/subtitleText';
import Container from '@mui/material/Container';
import SideNav from "../../components/sidenav/sideNav";
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import '../../index.css';
import BannerControla from "../../components/cards/bannerControla";
import CardControla from "../../components/cards/cardControla";


const colorTitle = "#9191B0";


function Registers() {
  return (

    <Box sx={{ display: "flex" }}>
      <SideNav returnBack="/documentation" />

      <Container>

        <Box sx={{ flexGrow: 1 }}>

          <Grid container>
            {/* <Grid item xs={12} md={12}>
              <SubtitleText text={"Documentación"} color={colorTitle}></SubtitleText>
            </Grid> */}
            <Grid item xs={12} md={12}>
              <BannerControla image='/assets/images/banners/documents/bannerrecord.png' color2='#2F67BC' color1='#8FBCFF' text='Registros' />

            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>

              <CardControla
                url={"/documentation/registers/generals/accidents"}
                text={"Accidentes de trabajo"}
                img={"/assets/images/banners/documents/accidents.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/registers/generals/occupationaldiseases"}
                text={"Enfermedades ocupacionales"}
                img={"/assets/images/banners/documents/ocupationalDiseases.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>

              <CardControla
                url={"/documentation/registers/mypes"}
                text={"Registro para pequeñas Empresas"}
                img={"/assets/images/banners/documents/mypes.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>

              <CardControla
                url={"/documentation/registers/generals/incidentsdangerousincidents"}
                text={"Incidentes peligrosos e incidentes"}
                img={"/assets/images/banners/documents/incidentsDangerosincidents.png"}
              />
            </Grid>

            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/registers/generals/monitoring"}
                text={"Monitoreo de agentes"}
                img={"/assets/images/banners/documents/monitoring.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/registers/generals/inspections"}
                text={"Inspecciones"}
                img={"/assets/images/banners/documents/inspections.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>

              <CardControla
                url={"/documentation/registers/generals/statisticaldata"}
                text={"Datos estadísticos"}
                img={"/assets/images/banners/documents/statistics.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/registers/generals/securityemergencyequipment"}
                text={"Equipos de seguridad o emergencia"}
                img={"/assets/images/banners/documents/security or emergency equipment.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/registers/generals/inductiontrainingcoaching"}
                text={"Inducción, capacitación, entrenamiento y simulacros"}
                img={"/assets/images/banners/documents/inductiontrainingcoaching.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/registers/generals/audits"}
                text={"Registro de auditorias"}
                img={"/assets/images/banners/documents/audits.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/registers/generals/competencycertificate"}
                text={"Certificado de aptitud de exámenes"}
                img={"/assets/images/banners/documents/competencecertificate.png"}
              />
            </Grid>            
          </Grid>
        </Box>
      </Container>

    </Box>

  );

}

export default Registers;

