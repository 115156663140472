import { React, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Button, Card, CardContent, CardMedia, Dialog, DialogActions, DialogContent, Grid, MenuItem, Modal, Paper, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { styled } from '@mui/system';
import { Add, DeleteForever, BorderColorOutlined, Search, Print, Edit as EditIcon, HistoryEduOutlined, Delete, DownloadForOffline, PictureAsPdf, VisibilityOutlined } from '@mui/icons-material';
import SideNav from '../../components/sidenav/sideNav';
import SubtitleText from '../../components/text/subtitleText';
import TitleText from '../../components/text/titleText';
import MyAts from '../../api/MyAts';
import MyUsers from '../../api/MyUsers';
import InputControlaVertical from '../../components/textfields/inputControlaVertical';
import CompanyLogoControla from '../../components/images/companyLogoControla';
import ButtonControla from '../../components/buttons/buttonController';
import SearchUsersControla from '../../components/search/searchUsersControla';
import DialogTitleControla from '../../components/dialog/dialogTitleControla';
import LoadingControla from '../../components/load/loadingControla';
import MyConfigurationApi from '../../api/MyConfigurationApi';
import ButtonIconControla from '../../components/buttons/buttonIconControla';
import SearchButtonControla from '../../components/search/searchButtonControla';
import SearchEppsControla from '../../components/search/searchEppsControla';
import DialogConfirmControla from '../../components/dialog/dialogConfirmControla';
import MyTrainings from '../../api/MyTrainings';
import MyCampus from '../../api/MyCampus';
import SelectControla from '../../components/selects/selectControla';

const grey = "#9191B0";
const blue = "#034AFF";
const lilac = "#D1D1E9";
const skyblue = "#2D9CDB";


const StyledTypography = styled(Typography)({
    color: "#305AD9",
    fontSize: 16,
    fontWeight: 600,
    gap: "2%",
    padding: "0.5em"
})

function Evidences() {

    // Dialogos de confirmacion FIN

    const params = new URLSearchParams(window.location.search);
    const id_trainings = params.get('id_trainings');

    const [rowEvidences, setRowEvidences] = useState([]);
    const handleGetEvidences = async () => {
        const response = await MyTrainings.getEvidences({ id_trainings: id_trainings });
        setRowEvidences(response);
    }

    useEffect(() => {
        handleGetEvidences();
    }, []);


    return (
        <>
            <Box sx={{ display: "flex", height: "100%" }}>

                <SideNav returnBack='/trainings/personal' />

                <Container sx={{ paddingBottom: 4 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container rowSpacing={2} columnSpacing={2} alignItems="center">
                            <Grid item md={10}>
                                <SubtitleText text={"Capacitaciones"} color={grey}></SubtitleText>
                                <TitleText text={"Evidencias"} />
                            </Grid>
                            <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
                                <CompanyLogoControla />
                            </Grid>
                        </Grid>
                        <Stack
                            direction="row"
                            justifyContent={"space-between"}
                            alignItems="center"
                            sx={{ mt: .5, mb: 2 }}
                        >
                            <Tabs value={1} aria-label="Tipos" >
                                <Tab label="Detalles" href={"/trainings/personal/edit?id_trainings=" + id_trainings} />
                                {/* <Tab label="Trabajadores" href={"/trainings/personal/workers?id_trainings=" + id_trainings} /> */}
                                <Tab label="Evidencias" href={"/trainings/personal/evidences?id_trainings=" + id_trainings} />
                                <Tab label="Materiales" href={"/trainings/personal/materials?id_trainings=" + id_trainings} />
                                <Tab label="Examenes" href={"/trainings/personal/exams?id_trainings=" + id_trainings} />

                            </Tabs>
                        </Stack>
                        <Grid container spacing={2} >
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Paper sx={{ overflow: "hidden" }}>
                                    <TableContainer>
                                        <Table
                                            stickyHeader
                                            sx={{ width: "100%", minWidth: "700px" }}
                                            aria-label="simple table"
                                        >
                                            <TableHead>
                                                <TableRow>
                                                    {/* <TableCell align="center"><IconButton><TaskAlt /></IconButton></TableCell> */}
                                                    <TableCell align="center" sx={{ color: blue }}>
                                                        Nombre
                                                    </TableCell>
                                                    <TableCell align="center" sx={{ color: blue }}>
                                                        Acciones
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rowEvidences.map((row, key) => (
                                                    <TableRow
                                                        hover
                                                        sx={{
                                                            "&:last-child td, &:last-child th": { border: 0 },
                                                        }}
                                                        key={key}
                                                    >
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            align="center"
                                                        >
                                                            {row.evidence}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Stack
                                                                direction="row"
                                                                justifyContent="end"
                                                                alignItems="center"
                                                            >
                                                                <ButtonIconControla
                                                                    roles={[1, 7, 8]}
                                                                    icon={
                                                                        <VisibilityOutlined sx={{ color: "white" }} />
                                                                    }
                                                                    backgroundColor={"#305AD9"}
                                                                    backgroundColorHover={"#0E4AFF"}
                                                                    textTooltip={"Ver"}
                                                                    directionUrl={row.evidence_url}
                                                                    directionTarget={'blank'}
                                                                />
                                                            </Stack>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>

            </Box >
        </>
    )
}

export default Evidences