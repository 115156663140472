import { React, useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import { Grid, Button, Dialog, DialogTitle, DialogContent, DialogActions, InputLabel, Select, MenuItem } from '@mui/material';
import { BorderColorOutlined, Delete } from '@mui/icons-material';
import SubtitleText from '../../components/text/subtitleText';
import Container from '@mui/material/Container';
import SideNav from "../../components/sidenav/sideNav";
import DocBanner from '../../components/cards/docBanner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import InputControlaVertical from '../../components/textfields/inputControlaVertical';
import ButtonIconControla from '../../components/buttons/buttonIconControla';
import MyBaseline from '../../api/MyBaseline';

const colorTitle = "#9191B0";
function Index() {

    const [rowsIndicators, setRowsIndicators] = useState([]);
    const [rowsLineaments, setRowsLineaments] = useState([]);

    const handleGetIndicators = async () => {
        const response = await MyBaseline.indicators();

        if (response.success === false) {
            // setAlertMessage(response.message);
            // setOpenLoadView(false);
        } else {
            setRowsIndicators(response);
        }
        return response;
    };

    const handleGetLineamnts = async () => {
        const response = await MyBaseline.lineaments();

        if (response.success === false) {
            // setAlertMessage(response.message);
            // setOpenLoadView(false);
        } else {
            setRowsLineaments(response);
        }
        return response;
    };

    useEffect(() => {
        handleGetIndicators();
        handleGetLineamnts();
    }, []);

    const [idIndicators, setIdIndicators] = useState(null);

    const [idLineamentsView, setIdLineamentsView] = useState(null);
    const [idLineaments, setIdLineaments] = useState(null);

    const handleChangeLineaments = (event) => {
        setIdLineaments(event.target.value);
    };

    const [questions, setQuestions] = useState(null);
    const [actions, setActions] = useState(null);


    const [openCreateIndicators, setOpenCreateIndicators] = useState(false);

    const handleClickOpenCreateIndicators = () => {
        setOpenCreateIndicators(true);
    };

    const handleCloseCreateIndicators = () => {
        setOpenCreateIndicators(false);
    };

    const [openEditIndicators, setOpenEditIndicators] = useState(false);

    const handleClickOpenEditIndicators = (idIndicators, idLineaments, questions, actions) => {
        setIdIndicators(idIndicators);
        setIdLineaments(idLineaments);
        setIdLineamentsView(idLineaments);
        setQuestions(questions);
        setActions(actions);
        setOpenEditIndicators(true);
    };

    const handleCloseEditIndicators = () => {
        setOpenEditIndicators(false);
    };

    const handleStoreIndicators = async () => {
        const response = await MyBaseline.storeIndicators({ idLineaments: idLineaments, questions: questions, actions: actions });

        if (response.success === false) {
        } else {
            handleGetIndicators();
            handleCloseCreateIndicators();
        }
        return response;
    };

    const handleUpdateIndicators = async () => {
        const response = await MyBaseline.updateIndicators({ idIndicators: idIndicators, idLineaments: idLineaments, questions: questions, actions: actions });

        if (response.success === false) {
        } else {
            handleGetIndicators();
            handleCloseEditIndicators();
        }
        return response;
    };

    const handleDeleteIndicators = async (idIndicators) => {
        const response = await MyBaseline.deleteIndicators({ idIndicators: idIndicators });

        if (response.success === false) {
        } else {
            handleGetIndicators();
        }
        return response;
    };



    return (

        <Box sx={{ display: "flex", height: '100%' }}>
            <SideNav />

            <Container>

                <Box sx={{ flexGrow: 1 }}>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <SubtitleText text={"Procedimientos"} color={colorTitle} ></SubtitleText>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <DocBanner color2='#BC6FEC' color1='#E6BEFF' text='Linea Base Indicadores' image={""} />
                        </Grid>
                    </Grid>

                    <Stack direction="row" spacing={1} sx={{ mt: 2 }} >
                        <Button
                            variant="contained"
                            onClick={() => handleClickOpenCreateIndicators()}
                        >Agregar Indicador
                        </Button>
                        <Button
                            variant="contained"
                            href='/admin/baseline/lineaments'
                        >Lineamientos
                        </Button>
                    </Stack>


                    <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Paper sx={{ overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: 400 }}>
                                    <Table stickyHeader sx={{ width: '100%', minWidth: '700px' }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Lineamientos</TableCell>
                                                <TableCell>Indicadores</TableCell>
                                                <TableCell>Acciones</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody   >
                                            {rowsIndicators
                                                // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                .map((rowsIndicators) => (
                                                    <TableRow hover
                                                        key={rowsIndicators.id_baseline_indicators}
                                                    >
                                                        {/* <TableCell align="center"><Checkbox /></TableCell> */}

                                                        <TableCell>
                                                            {rowsIndicators.baseline_lineament}
                                                        </TableCell>
                                                        <TableCell>
                                                            {rowsIndicators.questions}
                                                        </TableCell>
                                                        <TableCell>
                                                            {rowsIndicators.actions}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Stack
                                                                direction="row"
                                                                justifyContent="end"
                                                                alignItems="center"
                                                                spacing={1}
                                                            >
                                                                <ButtonIconControla icon={<BorderColorOutlined sx={{ color: 'white' }} />} backgroundColor={'#169073'} textTooltip='Editar' functionOnClick={() => handleClickOpenEditIndicators(rowsIndicators.id_baseline_indicators, rowsIndicators.id_baseline_lineaments, rowsIndicators.questions, rowsIndicators.actions)} />
                                                                <ButtonIconControla icon={<Delete sx={{ color: 'white' }} />} backgroundColor={'#EB5757'} textTooltip='Eliminar' functionOnClick={() => handleDeleteIndicators(rowsIndicators.id_baseline_indicators)} />
                                                            </Stack>
                                                        </TableCell>

                                                    </TableRow>
                                                ))}
                                        </TableBody>

                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Dialog open={openCreateIndicators} onClose={handleCloseCreateIndicators} maxWidth='sm' fullWidth={true}>
                        <DialogTitle sx={{
                            color: '#1638F2', fontWeight: 'bold',
                        }}>Crear indicador</DialogTitle>
                        <DialogContent>
                            <InputLabel
                                sx={{
                                    padding: '8px',
                                    fontSize: '20px',
                                    color: '#305AD9',
                                    fontWeight: 'bold'
                                }}> Lineamiento: </InputLabel>
                            <Select
                                defaultValue={0}
                                displayEmpty
                                onChange={handleChangeLineaments}
                                sx={{ width: '100%', backgroundColor: '#F5F5F5', border: 'none' }}
                            >
                                <MenuItem key={0} value={0}>Seleccione</MenuItem>
                                {rowsLineaments
                                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((rowsLineaments) => (
                                        <MenuItem key={rowsLineaments.id_baseline_lineaments} value={rowsLineaments.id_baseline_lineaments}>{rowsLineaments.baseline_lineament}</MenuItem>

                                    ))
                                }
                            </Select>
                            <InputControlaVertical text={'Indicador (Pregunta):'} inputType={'textArea'} inputValue={setQuestions} modalType={true} />
                            <InputControlaVertical text={'Accion:'} inputType={'textArea'} inputValue={setActions} modalType={true} />

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseCreateIndicators}>Cancel</Button>
                            <Button onClick={handleStoreIndicators}>Crear</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={openEditIndicators} onClose={handleCloseEditIndicators} maxWidth='sm' fullWidth={true}>
                        <DialogTitle sx={{
                            color: '#1638F2', fontWeight: 'bold',
                        }}>Editar indicador</DialogTitle>
                        <DialogContent>
                            <InputLabel
                                sx={{
                                    padding: '8px',
                                    fontSize: '20px',
                                    color: '#305AD9',
                                    fontWeight: 'bold'
                                }}> Lineamiento: </InputLabel>
                            <Select
                                displayEmpty
                                defaultValue={idLineamentsView}
                                onChange={handleChangeLineaments}
                                sx={{ width: '100%', backgroundColor: '#F5F5F5', border: 'none' }}
                            >
                                {rowsLineaments
                                    // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((rowsLineaments) => (
                                        <MenuItem key={rowsLineaments.id_baseline_lineaments} value={rowsLineaments.id_baseline_lineaments}>{rowsLineaments.baseline_lineament}</MenuItem>

                                    ))
                                }
                            </Select>
                            <InputControlaVertical text={'Indicador (Pregunta):'} inputType={'textArea'} value={questions} inputValue={setQuestions} modalType={true} />
                            <InputControlaVertical text={'Accion:'} inputType={'textArea'} value={actions} inputValue={setActions} modalType={true} />

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseEditIndicators}>Cancel</Button>
                            <Button onClick={handleUpdateIndicators}>Actualizar</Button>
                        </DialogActions>
                    </Dialog>

                </Box>
            </Container >


        </Box >




    );

}

export default Index;

