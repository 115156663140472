// juntas los new work accidents onemptied, two, thre, frour
import { React, useEffect, useState } from "react";

import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import SideNav from "../../../../components/sidenav/sideNav";
import Box from "@mui/material/Box";
import {
  Alert,
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Add,
  ArrowBack,
  Delete,
  EditOutlined,
  HistoryEduOutlined,
  InfoOutlined,
  Print,
  VisibilityOutlined,
} from "@mui/icons-material";
import TitleText from "../../../../components/text/titleText";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import ButtonControla from "../../../../components/buttons/buttonController";
import CompanyLogoControla from "../../../../components/images/companyLogoControla";
import SearchUsersControla from "../../../../components/search/searchUsersControla";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import MyAccidentsOccupationalsIncidents from "../../../../api/MyAccidentsOccupationalsIncidents";
import CloseIcon from "@mui/icons-material/Close";
import SearchButtonControla from "../../../../components/search/searchButtonControla";
import SelectControla from "../../../../components/selects/selectControla";
import MyUsers from "../../../../api/MyUsers";
import MyConfigurationApi from "../../../../api/MyConfigurationApi";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";

const colorTitle = "#9191B0";
const blue = "#034AFF";
const red = "#EB5757";

function Edit() {
  const params = new URLSearchParams(window.location.search);
  const idRegistersAccOccInc = params.get(
    "idRegistersAccidentsOccupationalsIncidents"
  );

  // Backdrop
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  /**** Message Alert */
  const [stateAlert, setStateAlert] = useState({
    openAlert: false,
    vertical: "top",
    horizontal: "center",
    severityAlert: "success",
    messageAlert: "",
  });
  const { severityAlert, messageAlert, vertical, horizontal, openAlert } =
    stateAlert;
  const handleClickAlert = (severityAlert, messageAlert) => {
    setStateAlert({
      openAlert: true,
      vertical: "top",
      horizontal: "right",
      severityAlert: severityAlert,
      messageAlert: messageAlert,
    });
  };

  const handleCloseAlert = () => {
    setStateAlert({ ...stateAlert, openAlert: false });
  };

  // Dialogos de confirmacion INICIO

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    console.log(id);
    setIdAgentType(id);
    setIdRegistersWorkers(id);
    setIdMeasures(id);
    setIdResponsibleWorker(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    // eslint-disable-next-line default-case
    switch (buttonActionType) {
      case "deleteWorkers":
        handleDeleteAccOccIncsWorkers();
        break;
      case "deleteOccupationalDiseases":
        handleDeleteAgentsTypes();
        break;
      case "deleteMeasures":
        handleDeleteCorrectivesActions();
        break;
      case "deleteResponsible":
        handleDeleteResponsibleWorkers();
        // updatestatusesPets();
        break;
    }
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN


  // DIALOGOS
  // agregar datos del trabajador DIALOG
  const [openModalUploadAddDates, setOpenModalUploadAddDates] = useState(false);

  const handleOpenModalUploadAddDates = () => {
    setWorkExperience("");
    setArea("");
    setShift("");
    setWorkHoursBefore("");
    setResponsibleCorrectiveActions("");
    handleGetSearchsAccOccIncWorkersUsers();
    setOpenModalUploadAddDates(true);
  };

  const handleCloseModalUploadAddDates = () => {
    setOpenModalUploadAddDates(false);
  };

  // agregar enfermedades ocupacionales
  const [openModalOccupationalDiseases, setOpenModalOccupationalDiseases] =
    useState(false);

  const handleOpenModaOccupationalDiseases = () => {
    setFirstSemesterCount("");
    setSecondSemesterCount("");
    setDiseaseName("");
    setArea("");
    setCauses("");
    setMeasures("");
    setResponsibleCorrectiveActions("");
    setTimestampExecution("");
    setStatusSelectAgentsTypes("");
    handleGetSearchsResponsiblesWorkersAgentsTypes();
    setOpenModalOccupationalDiseases(true);
  };

  const handleCloseModalOccupationalDiseases = () => {
    setOpenModalOccupationalDiseases(false);
  };

  // agregar medidas correctivas DIALOG
  const [
    openModalUploadCorrectiveActions,
    setOpenModalUploadCorrectiveActions,
  ] = useState(false);

  const handleOpenModalUploadCorrectiveActions = () => {
    setTimestampStartMeasures(null);
    setTimestampEndMeasures(null);
    setDescriptionMeasures(null);
    // setStatusCorrectivesActions(null);
    setDescriptionMeasures("");
    setResponsibleCorrectiveActions("");
    setOpenModalUploadCorrectiveActions(true);
  };

  const handleCloseModalUploadCorrectiveActions = () => {
    setOpenModalUploadCorrectiveActions(false);
  };

  // agregar RESPONSABLES  DIALOG
  const [
    openModalUploadResponsibleRegistration,
    setOpenModalUploadCResponsibleRegistration,
  ] = useState(false);

  const handleOpenModalUploadResponsibleRegistration = () => {
    handleGetSearchsResponsiblesWorkers();
    setOpenModalUploadCResponsibleRegistration(true);
  };

  const handleCloseModalUploadResponsibleRegistration = () => {
    setOpenModalUploadCResponsibleRegistration(false);
  };
  /**************************************************************************************+ */
  // usuario logeado
  const [idUserLogged, setIdUserLogged] = useState();

  const handleUserActive = async () => {
    const responsable = await MyConfigurationApi.userData();
    setIdUserLogged(responsable.id_users);
  };

  // listar tipo de rgistro
  // severidad del accidente
  const [typesRegistersAccOcc, setTypesRegistersAccOcc] = useState(0);
  const [rowsTypesRegisters, setRowsTypesRegisters] = useState([]);

  const handleGetTypeRegisters = async () => {
    const response =
      await MyAccidentsOccupationalsIncidents.typeRegistersAccOccInc();
    if (response.success === false) {
    } else {
      setRowsTypesRegisters(response);
    }
    return response;
  };

  // LISTAR  SEVERIDAD
  // Gravedad del accidente de trabajo
  const [severityAccidents, setSeverityAccidents] = useState(0);
  const [rowsSeverityWorkAccidents, setRowsSeverityWorkAccidents] = useState(
    []
  );

  const handleGetSeverityAccidents = async () => {
    const response =
      await MyAccidentsOccupationalsIncidents.severityWorkAccident();

    if (response.success === false) {
    } else {
      setRowsSeverityWorkAccidents(response);
    }
    return response;
  };

  // severidad del accidente
  const [severityIncidents, setSeverityIncidents] = useState(0);
  const [rowsSeverityIncidents, setRowsSeverityIncidents] = useState([]);

  const handleGetSeverityInccidents = async () => {
    const response =
      await MyAccidentsOccupationalsIncidents.severityIncidents();
    if (response.success === false) {
    } else {
      setRowsSeverityIncidents(response);
    }
    return response;
  };

  // edit accidentes ocupaccionales mypes
  const [code, setCode] = useState("");
  const [affectedWorkers, setAffectedWorkers] = useState(0);
  const [causesDescription, setcausesDescription] = useState("");
  const [injuredBodyPartDescription, setInjuredBodyPartDescription] =
    useState("");
  const [location, setLocation] = useState("");
  const [medicalLeaveDays, setMedicalLeaveDays] = useState(0);
  const [timestampAccident, setTimestampAccident] = useState("");
  const [timestampInvestigation, setTimestampInvestigation] = useState("");
  const [descriptionWork, setDescriptionWork] = useState("");

  const handleEditAccidentsOccupational = async () => {
    const response =
      await MyAccidentsOccupationalsIncidents.editAccidentsOccupational({
        idRegistersAccOccInc,
      });
    if (response.success === false) {
    } else {
      setRegisterStatus(
        response.id_registers_accidents_occupationals_incidents_statuses === 1
          ? true
          : false
      );
      setAffectedWorkers(response.affected_workers);
      setcausesDescription(response.causes_description);
      setCode(response.code);
      setDescriptionWork(response.description);
      setInjuredBodyPartDescription(response.injured_body_part_desciption);
      setLocation(response.location);
      setMedicalLeaveDays(response.medical_leave_days);
      setTimestampAccident(
        response.timestamp_accident === null &&  response.timestamp_accident === undefined
          ? ""
          : response.timestamp_accident.substring(0, 19)
      );
      setTimestampInvestigation(
        response.timestamp_investigation === null &&  response.timestamp_investigation === undefined
          ? ""
          : response.timestamp_investigation.substring(0, 10)
      );
      setTypesRegistersAccOcc(
        response.id_registers_accidents_occupationals_incidents_types
      );
      setSeverityAccidents(
        response.id_registers_accidents_occupationals_incidents_severities
      );
      setSeverityIncidents(
        response.id_registers_accidents_occupationals_incidents_incident
      );
    }
    return response;
  };

  //actualizar accidentes ocupacionales mypes
  const handleUpdateOccupationalsDiseases = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);

    const response =
      await MyAccidentsOccupationalsIncidents.updateAccidentsOccupational({
        idRegistersAccOccInc,
        code: code,
        id_registers_accidents_occupationals_incidents_types:
          typesRegistersAccOcc,
        timestamp_accident: timestampAccident,
        timestamp_investigation: timestampInvestigation,
        location: location,
        id_registers_accidents_occupationals_incidents_severities:
          severityAccidents,
        id_registers_accidents_occupationals_incidents_incident:
          severityIncidents,
        medical_leave_days: medicalLeaveDays,
        affected_workers: affectedWorkers,
        injured_body_part_desciption: injuredBodyPartDescription,
        description: descriptionWork,
        causes_description: causesDescription,
      });

    if (response.success === false) {
      console.log("error al actualizar");
    } else {
      handleCloseBackdrop();
      handleClickAlert("success", "Se actualizaron los datos");
    }
    return response;
  };

  // cerrar el estado de accidentes ocupacionales mypes!!!!!!
  const [registerStatus, setRegisterStatus] = useState(true);
  const handleAccidentsOcupationalEnd = async () => {
    const response =
      await MyAccidentsOccupationalsIncidents.accidentsOcupationalEnd({
        idRegistersAccOccInc,
      });
    if (response.success === false) {
      alert("Error");
    } else {
      handleEditAccidentsOccupational();
    }
    return response;
  };

  // TRABAJADOR INVOLUCRADO
  const [
    openModalUploadSearchInvolvedWorkers,
    setOpenModalUploadSearchInvolvedWorkers,
  ] = useState(false);
  const handleOpenModalResponsibleInvolvedWorkers = () => {
    setResponsibleCorrectiveActions("");
    setOpenModalUploadSearchInvolvedWorkers(true);
  };
  // listar trabajador involucrado para la búsqueda
  const [rowsSearchsAccOccIncWorkersUsers, setSearchsAccOccIncWorkersUsers] =
    useState([]);

  const handleGetSearchsAccOccIncWorkersUsers = async () => {
    const response =
      await MyAccidentsOccupationalsIncidents.getSearchsAccOccIncWorkersUsers({
        idRegistersAccOccInc,
      });
    setSearchsAccOccIncWorkersUsers(response);
  };
  //get  trabajador involucrado
  const [accOccIncWorkers, setAccOccIncWorkers] = useState([]);
  const handleGetsetAccOccIncWorkers = async () => {
    const response =
      await MyAccidentsOccupationalsIncidents.getAccOccIncWorkers({
        idRegistersAccOccInc,
      });
    if (response.success === false) {
      alert("Error");
    } else {
      setAccOccIncWorkers(response);
    }
  };

  // crear trabajador involucrado
  const [areaWork, setAreaWork] = useState("");
  const [shift, setShift] = useState("");
  const [workExperience, setWorkExperience] = useState("");
  const [workHoursBefore, setWorkHoursBefore] = useState("");

  const handleChangeShift = (event) => {
    setShift(event.target.value);
  };

  const handleStoreAccidentsWorkers = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    const response =
      await MyAccidentsOccupationalsIncidents.creatAccOccIncsWorkers({
        idRegistersAccOccInc: idRegistersAccOccInc,
        id_users: idResponsible,
        area: areaWork,
        shift: shift,
        work_experience: workExperience,
        work_hours_before: workHoursBefore,
      });
    if (response.success === false) {
      console.log("error", response);
    } else {
      handleCloseModalUploadAddDates();
      handleGetsetAccOccIncWorkers();
      handleCloseBackdrop();
      handleClickAlert(
        "success",
        "Se agregó correctamente los datos del trabajador"
      );
    }
    return response;
  };

  // eliminar
  const [idRegistersWorkers, setIdRegistersWorkers] = useState();
  const handleDeleteAccOccIncsWorkers = async () => {
    setOpenBackdrop(!openBackdrop);

    const response =
      await MyAccidentsOccupationalsIncidents.deleteAccOccIncsWorkers({
        id_registers_workers: idRegistersWorkers,
      });
    if (response.success === true) {
      handleGetsetAccOccIncWorkers();
      handleCloseBackdrop();
      handleClickAlert("success", "Se eliminó correctamente");
    } else {
      alert("error", "Ha ocurrido un error");
    }
    return response;
  };

  //MEDIDAS CORRECTIVAS
  // buscar usuario por ID
  const [responsibleCorrectiveActions, setResponsibleCorrectiveActions] =
    useState(null);
  const [idResponsible, setIdResponsible] = useState("");

  const handleSelectUsersResponsible = async (idUsers) => {
    setIdResponsible(idUsers);
    const response = await MyUsers.editUsers({ idUsers: idUsers });
    if (response.success != false) {
      setResponsibleCorrectiveActions(
        response.first_name + " " + response.last_name
      );
      setOpenModalUploadSearchUsersCorrectiveActions(false);
      setOpenModalUploadSearchInvolvedWorkers(false);
      setOpenModalUploadAgentsResponsible(false);
    } else {
      alert("Error al obtener el usuario!");
    }
  };

  // abrir modal de busqueda de usuarios de medidas correctivas
  const [
    openModalUploadSearchUsersCorrectiveActions,
    setOpenModalUploadSearchUsersCorrectiveActions,
  ] = useState(false);
  const handleOpenModalResponsibleSearchUsersCorrectiveActions = () => {
    setResponsibleCorrectiveActions("");
    setOpenModalUploadSearchUsersCorrectiveActions(true);
  };

  // listar estado de la medida correctiva
  // const [statusCorrectivesActions, setStatusCorrectivesActions] = useState(1);

  // const [rowsStatusCorrectivesActions, setRowsStatusCorrectivesActions] =
  //   useState([]);
  // const handleGetMeasuresStatuses = async () => {
  //   const response =
  //     await MyAccidentsOccupationalsIncidents.getMeasuresStatuses();
  //   if (response.success === false) {
  //   } else {
  //     setRowsStatusCorrectivesActions(response);
  //   }
  //   return response;
  // };

  //get de usuarios para buscar en medidas correctivas
  const [
    rowsSearchsCorrectivesActionWorkers,
    setSearchsCorrectivesActionWorkers,
  ] = useState([]);
  const handleGetSearchsMeasuresUsers = async () => {
    const response =
      await MyAccidentsOccupationalsIncidents.getAccOccuInciMeasuresUsers({
        idRegistersAccOccInc,
      });
    setSearchsCorrectivesActionWorkers(response);
  };

  //get de medidas correctivas, tabla
  const [correctivesActions, setCorrectivesActions] = useState([]);

  const handleGetCorrectivesActions = async () => {
    const response = await MyAccidentsOccupationalsIncidents.getMeasures({
      idRegistersAccOccInc,
    });
    if (response.success === false) {
      alert("Error");
    } else {
      setCorrectivesActions(response);
    }
  };

  // crear medidas correctivas
  const [timestampStartMeasures, setTimestampStartMeasures] = useState("");
  const [timestampEndMeasures, setTimestampEndMeasures] = useState("");
  const [descriptionMeasures, setDescriptionMeasures] = useState("");

  const handleStoreCorrectivesActions = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    const response =
      await MyAccidentsOccupationalsIncidents.createCorrectivesActions({
        idRegistersAccOccInc: idRegistersAccOccInc,
        id_registers_accidents_occupationals_incidents_measures_statuses: 0,
        id_users: idResponsible,
        timestamp_start: timestampStartMeasures,
        timestamp_end: timestampEndMeasures,
        description: descriptionMeasures,
      });

    if (response.success === false) {
    } else {
      console.log("se creo");
      handleGetCorrectivesActions();
      handleCloseModalUploadCorrectiveActions();
      handleCloseBackdrop();
      handleClickAlert(
        "success",
        "Se agregó correctamente la medida correctiva"
      );
    }
    return response;
  };

  // eliminar medidas correctives
  const [idMeasures,setIdMeasures] = useState();
  const handleDeleteCorrectivesActions = async ( ) => {
    setOpenBackdrop(!openBackdrop);

    const response =
      await MyAccidentsOccupationalsIncidents.deleteCorrectivesActions({
        id_measures: idMeasures,
      });
    if (response.success === true) {
      handleGetCorrectivesActions();
      handleCloseBackdrop();
      handleClickAlert(
        "success",
        "Se eliminó correctamente la medida correctiva"
      );
    } else {
      alert("error", "Ha ocurrido un error");
    }
    return response;
  };

  // RESPONSABLES
  // Buscar trabajadores en medidas correctivas
  const [rowsSearchsResponsiblesWorkers, setSearchsResponsiblesWorkers] =
    useState([]);

  const handleGetSearchsResponsiblesWorkers = async () => {
    const response =
      await MyAccidentsOccupationalsIncidents.getSearchsResponsiblesWorkers({
        idRegistersAccOccInc,
      });
    setSearchsResponsiblesWorkers(response);
  };

  // //listas trabajadores responsables
  const [responsibleWorkers, setResponsibleActions] = useState([]);
  const handleGetResponsibleWorkers = async () => {
    const response =
      await MyAccidentsOccupationalsIncidents.getResponsibleWorkers({
        idRegistersAccOccInc,
      });
    if (response.success === false) {
      alert("Error");
    } else {
      setResponsibleActions(response);
      // setFirm(response.firm);
    }
  };

  // firmar responsable
  const [openModalSignResponsible, setOpenModalSignResponsible] =
    useState(false);
  const [idResponsibleSign, setIdResponsibleSign] = useState(0);

  const handleOpenModalSignResponsible = (idUserResposible) => {
    setIdResponsibleSign(idUserResposible);
    setOpenModalSignResponsible(true);
  };

  const handleSignResponsible = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    const response = await MyAccidentsOccupationalsIncidents.signResponsible({
      idResponsibleSign,
    });
    if (response.success !== false) {
      handleCloseBackdrop();
      setOpenModalSignResponsible(false);
      handleGetResponsibleWorkers();
      handleClickAlert("success", "Se registro la firma");
    } else {
      alert("Error al obtener el usuario!");
    }
  };
  // //crear trabajadores responsables
  // const [firm, setFirm] = useState();
  const handleStoreResponsibleWorkers = async (idUsers) => {
    setOpenBackdrop(!openBackdrop);
    const response =
      await MyAccidentsOccupationalsIncidents.createResponsibleWorkers({
        idRegistersAccOccInc: idRegistersAccOccInc,
        id_users: idUsers,
        // firm: firm,
      });

    if (response.success === false) {
      console.log("error", response);
    } else {
      handleGetResponsibleWorkers();
      handleCloseModalUploadResponsibleRegistration();
      handleCloseBackdrop();
      handleClickAlert(
        "success",
        "Se agregó correctamente la medida correctiva"
      );
    }
    return response;
  };

  // // eliminar trabajador responsable
  const [idResponsibleWorker, setIdResponsibleWorker] = useState();
  const handleDeleteResponsibleWorkers = async () => {
    setOpenBackdrop(!openBackdrop);
    const response =
      await MyAccidentsOccupationalsIncidents.deleteResponsibleWorkers({
        id_registers_responsible: idResponsibleWorker,
      });
    if (response.success === true) {
      handleGetResponsibleWorkers();
      handleCloseBackdrop();
      handleClickAlert("success", "Se eliminó correctamente");
    } else {
      alert("error", "Ha ocurrido un error");
    }
    return response;
  };

  // // AGENTES
  const [
    openModalUploadAgentsResponsible,
    setOpenModalUploadAgentsResponsible,
  ] = useState(false);
  const handleOpenModalAgentsResponsible = () => {
    setResponsibleCorrectiveActions("");
    setOpenModalUploadAgentsResponsible(true);
  };
  // // seleccionar sustancias select
  const [statusSelectAgentsTypes, setStatusSelectAgentsTypes] = useState(null);

  // // listar tipos de agentes
  const [agentsTypes, setAgentsTypes] = useState([]);
  const handleGetAgentsTypess = async () => {
    const response = await MyAccidentsOccupationalsIncidents.getAgentsTypes();
    setAgentsTypes(response);
  };

  // //listas agentes
  const [openModalMoreAgents, setOpenModalMoreAgents] = useState(false);

  const handleOpenModalMoreAgents = () => {
    setOpenModalMoreAgents(true);
  };

  const [relsDAgentsTypes, setRelsAgentsTypes] = useState([]);
  const handleGetRelsDAgentsTypes = async () => {
    const response =
      await MyAccidentsOccupationalsIncidents.getRelsDAgentsTypes({
        idRegistersAccOccInc,
      });
    if (response.success === false) {
      alert("Error");
    } else {
      setRelsAgentsTypes(response);
    }
  };

  // Buscar trabajadores en medidas correctivas
  const [
    rowsSearchsResponsiblesWorkersAgentesType,
    setSearchsResponsiblesWorkersAgentesType,
  ] = useState([]);

  const handleGetSearchsResponsiblesWorkersAgentsTypes = async () => {
    const response =
      await MyAccidentsOccupationalsIncidents.getSearchsResponsiblesWorkersAgentesType(
        { idRegistersAccOccInc }
      );
    setSearchsResponsiblesWorkersAgentesType(response);
  };

  // // crear enfermedades ocupacionales
  const [firstSemesterCount, setFirstSemesterCount] = useState("");
  const [secondSemesterCount, setSecondSemesterCount] = useState("");
  const [diseaseName, setDiseaseName] = useState("");
  const [area, setArea] = useState("");
  const [causes, setCauses] = useState("");
  const [measures, setMeasures] = useState("");

  const [timestampExecution, setTimestampExecution] = useState("");

  const handleStoreAccOccIncAgents = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    const response = await MyAccidentsOccupationalsIncidents.createAgentsTypes({
      idRegistersAccOccInc: idRegistersAccOccInc,
      id_registers_accidents_occupationals_incidents_agents:
        statusSelectAgentsTypes,
      first_semester_count: firstSemesterCount,
      second_semester_count: secondSemesterCount,
      disease_name: diseaseName,
      area: area,
      causes: causes,
      measures: measures,
      id_users: idResponsible,
      timestamp_execution: timestampExecution,
    });

    if (response.success === false) {
      console.log("error", response);
    } else {
      handleGetRelsDAgentsTypes();
      handleCloseModalOccupationalDiseases();
      handleCloseBackdrop();
      handleClickAlert("success", "Se creó correctamente");
    }
    return response;
  };
  // // eliminar agentes de enfermedades ocupacionales
  const [idAgentType, setIdAgentType] = useState();
  const handleDeleteAgentsTypes = async () => {
    setOpenBackdrop(!openBackdrop);
    const response =
      await MyAccidentsOccupationalsIncidents.deleteAccOccIncAgentsTypes({
        id_agent_type: idAgentType,
      });
    if (response.success === true) {
      handleGetRelsDAgentsTypes();
      handleCloseBackdrop();
      handleClickAlert("success", "Se eliminó correctamente");
    } else {
      alert("error", "Ha ocurrido un error");
    }
    return response;
  };

  // listar tipos de agentes tabla completa
  const [openModalTypeAgentsTable, setOpenModalTypeAgentsTable] =
    useState(false);

  const handleOpenModalTypeAgentsTable = () => {
    setOpenModalTypeAgentsTable(true);
  };

  const [agentsTable, setAgentsTable] = useState([]);
  // const [bodyAgents, setBodyAgents] = useState([]);

  const handleGetAgentsTypesTable = async () => {
    const response =
      await MyAccidentsOccupationalsIncidents.getAgentsTypesTable();

    if (response.success === false) {
      handleClickAlert("error", "Error al guardar el documento!");
    } else {
      console.log(response);
      // let arrTitleAgents = [];
      // let arrBodyAgents = [];
      // response.forEach((element) => {
      //   arrTitleAgents.push(element.category);
      //   arrBodyAgents.push(element.agents);
      // });
      setAgentsTable(response);
      // setBodyAgents(arrBodyAgents);
    }
    return response;
  };

  // console.log('titleAgents', titleAgents);
  console.log("agentsTable", agentsTable);

  useEffect(() => {
    handleUserActive();

    handleEditAccidentsOccupational();
    handleGetTypeRegisters();
    handleGetSeverityAccidents();
    handleGetSeverityInccidents();

    // trabajadores relacionados
    handleGetsetAccOccIncWorkers();

    // medidas correctivas
    // handleGetMeasuresStatuses();
    handleGetSearchsMeasuresUsers();
    handleGetCorrectivesActions();

    // // agentes
    handleGetAgentsTypess();
    handleGetRelsDAgentsTypes();

    // // responsables
    handleGetResponsibleWorkers();

    // tipos de agentes tabla completa
    handleGetAgentsTypesTable();
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="/documentation/registers/mypes/accidentsoccupationalsincidents" />
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleCloseAlert}
        key={vertical + horizontal}
        autoHideDuration={3000}
      >
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                handleCloseAlert();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity={severityAlert}
          size="small"
        >
          {messageAlert}
        </Alert>
      </Snackbar>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
              <TitleText
                text={
                  "Registro de accidentes de trabajo, enfermedades ocupacionales, incidentes peligrosos y otros indicentes"
                }
              ></TitleText>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <InputControlaVertical
                text={"Nro registro"}
                inputType={"text"}
                inputValue={setCode}
                value={code}
              />
            </Grid>
          </Grid>
        </Box>
        <form onSubmit={handleUpdateOccupationalsDiseases}>
          <Box sx={{ flexGrow: 1 }}>
            {/* DATOS DEL TRABAJADOR  */}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Datos del trabajador
                </InputLabel>
                <Typography  variant="caption" display="block" gutterBottom sx={{color: '#F2994A', marginLeft:1}}>
                Este campo es obligatorio
                </Typography>
              </Box>
              <Box>
                {registerStatus === true ? (
                  <ButtonControla
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#2D9CDB"}
                    backgroundColorHover={"#35B4FB"}
                    textButton={"Agregar datos"}
                    functionOnClick={handleOpenModalUploadAddDates}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Stack>
            {/* tabla para mostrar accidents workers */}
            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nombre y Apellido
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Documento de Identidad
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Área
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Turno
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        N.º de horas trabajadas antes del accidente
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Tiempo de experiencia en el puesto de trabajo
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {accOccIncWorkers.map((row) => (
                      <TableRow
                        hover
                        key={row.id_users}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.first_name + " " + row.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.document}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.area}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.shift}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.work_hours_before}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.work_experience}
                        </TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                            spacing={1}
                          >
                            {/* <ButtonIconControla
                              icon={
                                <VisibilityOutlined sx={{ color: "white" }} />
                              }
                              backgroundColor={"#305AD9"}
                              backgroundColorHover={"#0E4AFF"}
                              textTooltip={"Ver"}
                              directionUrl={
                                "/settings/users/edit?idUsers=" + row.id_users
                              }
                              directionTarget={"_blank"}
                              // functionOnClick={() => ""}
                            /> */}
                            {registerStatus === true ? (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id_registers_accidents_occupationals_incidents_workers,
                                    "¿Esta seguro de eliminar los datos del trabajador?",
                                    "Una vez eliminado no se podra recuperar los datos del trabajador",
                                    "Si, Eliminar",
                                    "deleteWorkers"
                                  );
                                }}
                                // functionOnClick={() =>
                                //   handleDeleteAccOccIncsWorkers(
                                //     row.id_registers_accidents_occupationals_incidents_workers
                                //   )
                                // }
                              />
                            ) : (
                              ""
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* Accidente de trabajo indicente peligro incidente */}
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={12} md={12}>
                <SubtitleText
                  text={"Accidente de trabajo/incidente peligroso/incidente"}
                  color={blue}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SelectControla
                  text="Seleccione si es accidente de trabajo/incidente peligroso/incidente:"
                  inputValue={setTypesRegistersAccOcc}
                  modalType={false}
                  required
                  value={typesRegistersAccOcc}
                  childrenRows={rowsTypesRegisters.map((row) => (
                    <MenuItem
                      key={
                        row.id_registers_accidents_occupationals_incidents_types
                      }
                      value={
                        row.id_registers_accidents_occupationals_incidents_types
                      }
                    >
                      {row.type}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Typography  variant="caption" display="block" gutterBottom sx={{color: '#F2994A', ml:2 }}>
                Este campo es obligatorio
                </Typography>
            </Grid>

            {/* INVESTIGACIÓN DEL ACCIDENTE DE TRABAJO */}
            <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={12} md={12}>
                <SubtitleText text={"Investigación"} color={blue} />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                <InputControlaVertical
                  text={"Fecha y hora de ocurrencia del accidente"}
                  inputType={"datetime-local"}
                  inputValue={setTimestampAccident}
                  value={timestampAccident}
                  required
                />
                 <Typography  variant="caption" display="block" gutterBottom sx={{color: '#F2994A' }}>
                Este campo es obligatorio
                </Typography>
              </Grid>
              
              <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                <InputControlaVertical
                  text={"Fecha de inicio de la investigación"}
                  inputType={"date"}
                  inputValue={setTimestampInvestigation}
                  value={timestampInvestigation}
                  required
                />
                 <Typography  variant="caption" display="block" gutterBottom sx={{color: '#F2994A'}}>
                Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                <InputControlaVertical
                  text={"Lugar exacto donde ocurrió el accidente"}
                  inputType={"text"}
                  inputValue={setLocation}
                  value={location}
                  required
                />
                 <Typography  variant="caption" display="block" gutterBottom sx={{color: '#F2994A'}}>
                Este campo es obligatorio
                </Typography>
              </Grid>
            </Grid>

            {/* Investigación del accidente de trabajo */}
            {/* <Grid container spacing={2} sx={{ marginTop: 1 }}>
              <Grid item xs={12} md={12}>
                <SubtitleText
                  text={"Investigación"}
                  color={blue}
                />
              </Grid>
            </Grid> */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <SelectControla
                  text="Gravedad del accidente de trabajo:"
                  inputValue={setSeverityAccidents}
                  modalType={false}
                  required
                  value={severityAccidents}
                  childrenRows={rowsSeverityWorkAccidents.map(
                    (rowsRegistersAccidents) => (
                      <MenuItem
                        key={
                          rowsRegistersAccidents.id_registers_accidents_occupationals_incidents_severities
                        }
                        value={
                          rowsRegistersAccidents.id_registers_accidents_occupationals_incidents_severities
                        }
                      >
                        {
                          rowsRegistersAccidents.register_accident_occupational_incident_severity
                        }
                      </MenuItem>
                    )
                  )}
                />
                 <Typography  variant="caption" display="block" gutterBottom sx={{color: '#F2994A'}}>
                Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid item xs={1} sm={1} md={1} lg={1} xl={1}>
                <ButtonIconControla
                  icon={<InfoOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#E34034"}
                  backgroundColorHover={"#D43B30"}
                  textTooltip={"Seleccionar solo en caso de accidente"}
                  // functionOnClick={() => console.log("ver ")}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                sx={{
                  display: severityAccidents === 2 ? "" : "none",
                }}
              >
                <SelectControla
                  text="Gravedad del accidente incapacitante:"
                  inputValue={setSeverityIncidents}
                  modalType={false}
                  value={severityIncidents}
                  childrenRows={rowsSeverityIncidents.map(
                    (rowsSeverityIncidents) => (
                      <MenuItem
                        key={
                          rowsSeverityIncidents.id_registers_accidents_severities_incident
                        }
                        value={
                          rowsSeverityIncidents.id_registers_accidents_severities_incident
                        }
                      >
                        {
                          rowsSeverityIncidents.register_accident_severity_incident
                        }
                      </MenuItem>
                    )
                  )}
                />
                <Typography  variant="caption" display="block" gutterBottom sx={{color: '#F2994A'}}>
                Este campo es obligatorio
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"N° días de descanso médico"}
                  inputType={"number"}
                  inputValue={setMedicalLeaveDays}
                  value={medicalLeaveDays}
                  required
                />
                <Typography  variant="caption" display="block" gutterBottom sx={{color: '#F2994A'}}>
                Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"N° de trabajadores afectados"}
                  inputType={"number"}
                  inputValue={setAffectedWorkers}
                  value={affectedWorkers}
                  required
                />
                <Typography  variant="caption" display="block" gutterBottom sx={{color: '#F2994A'}}>
                Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Describir parte del cuerpo lesionado(de ser el caso)"}
                  inputType={"textArea"}
                  inputValue={setInjuredBodyPartDescription}
                  value={injuredBodyPartDescription}
                  // required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Descripción del suceso"}
                  inputType={"textArea"}
                  inputValue={setDescriptionWork}
                  value={descriptionWork}
                  // required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={
                    "Descripción de las causas que originaron el accidente de trabajo"
                  }
                  inputType={"textArea"}
                  inputValue={setcausesDescription}
                  value={causesDescription}
                  // required
                />
              </Grid>
            </Grid>

            {/* medidas correctivas */}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Medidas correctivas
                </InputLabel>
                <Typography  variant="caption" display="block" gutterBottom sx={{color: '#F2994A', marginLeft:1}}>
                Este campo es obligatorio
                </Typography>
              </Box>
              <Box>
                {registerStatus === true ? (
                  <ButtonControla
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#2D9CDB"}
                    backgroundColorHover={"#35B4FB"}
                    textButton={"Agregar medidas correctivas"}
                    functionOnClick={handleOpenModalUploadCorrectiveActions}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Stack>

            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Descripción de la medida correctiva
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Responsable
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha de investigación
                      </TableCell>
                      {/* <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha de fin
                      </TableCell> */}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {correctivesActions.map((rows) => (
                      <TableRow
                        hover
                        key={rows.id_users}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {rows.description}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rows.first_name + " " + rows.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {rows.timestamp_start.substring(0, 10)}
                        </TableCell>
                        {/* <TableCell component="th" scope="row">
                          {rows.timestamp_end}
                        </TableCell> */}

                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            {/* <ButtonIconControla
                              icon={
                                <VisibilityOutlined sx={{ color: "white" }} />
                              }
                              backgroundColor={"#305AD9"}
                              backgroundColorHover={"#0E4AFF"}
                              textTooltip={"Ver"}
                              directionUrl={
                                "/settings/users/edit?idUsers=" + rows.id_users
                              }
                              directionTarget={"_blank"}
                              // functionOnClick={() => ""}
                            /> */}
                            {registerStatus === true ? (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    rows.id_registers_accidents_occupationals_incidents_measures,
                                    "¿Esta seguro de eliminar los datos de la medida correctiva?",
                                    "Una vez eliminado no se podra recuperar los datos de la medida correctiva",
                                    "Si, Eliminar",
                                    "deleteMeasures"
                                  );
                                }}
                                // functionOnClick={() =>
                                //   handleDeleteCorrectivesActions(
                                //     rows.id_registers_accidents_occupationals_incidents_measures
                                //   )
                                // }
                              />
                            ) : (
                              ""
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* Datos refrentes a la enfermedad ocupacional */}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Enfermedad ocupacional / incidente peligroso / incidente
                </InputLabel>
                <Typography  variant="caption" display="block" gutterBottom sx={{color: '#F2994A', marginLeft:1}}>
                Este campo es obligatorio
                </Typography>
              </Box>
              <Box sx={{ marginRight: 55 }}>
                <ButtonIconControla
                  icon={<InfoOutlined sx={{ color: "white" }} />}
                  backgroundColor={"#FFC300"}
                  backgroundColorHover={"#FACB32"}
                  textTooltip={"Ver enfermedades ocupacionales"}
                  functionOnClick={handleOpenModalTypeAgentsTable}
                />
              </Box>
              <Box>
                {registerStatus === true ? (
                  <ButtonControla
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#2D9CDB"}
                    backgroundColorHover={"#35B4FB"}
                    textButton={"Agregar datos"}
                    functionOnClick={handleOpenModaOccupationalDiseases}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Stack>
            {/* tabla para mostrar accidents workers */}
            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Tipo de agente que originó la enfermedad ocupacional
                      </TableCell>

                      {/* <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nro de trabajadores que adquirieron las enfermedades
                        ocupacionales PRIMER SEMESTRE
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nro de trabajadores que adquirieron las enfermedades
                        ocupacionales SEGUNDO SEMESTRE
                      </TableCell> */}
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nombre de la enfermedad ocupacional
                      </TableCell>
                      {/* <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Áreas donde se presentó la enfermedad ocupacional
                      </TableCell> */}
                      {/* <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Causas que originaron la enfermedad ocupacional
                      </TableCell> */}
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Medidas correctivas a implementar
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Responsable{" "}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha de ejecución
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {relsDAgentsTypes.map((row) => (
                      <TableRow
                        hover
                        key={row.id_users}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.agent_type}
                        </TableCell>
                        {/* <TableCell component="th" scope="row">
                          {row.first_semester_count}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.second_semester_count}
                        </TableCell> */}
                        <TableCell component="th" scope="row">
                          {row.disease_name}
                        </TableCell>
                        {/* <TableCell component="th" scope="row">
                          {row.area}
                        </TableCell> */}
                        <TableCell component="th" scope="row">
                          {row.measures}
                        </TableCell>
                        {/* <TableCell component="th" scope="row">
                          {row.disease_name}
                        </TableCell> */}
                        <TableCell component="th" scope="row">
                          {row.first_name + " " + row.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.timestamp_execution.substring(0, 10)}
                        </TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                            spacing={1}
                          >
                            <ButtonIconControla
                              icon={
                                <VisibilityOutlined sx={{ color: "white" }} />
                              }
                              backgroundColor={"#305AD9"}
                              backgroundColorHover={"#0E4AFF"}
                              textTooltip={"Ver mas información"}
                              functionOnClick={handleOpenModalMoreAgents}
                            />
                            {registerStatus === true ? (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id_registers_accidents_occupationals_incidents_rels_agents_types,
                                    "¿Esta seguro de eliminar los datos de la enfermedad ocupacional?",
                                    "Una vez eliminado no se podra recuperar los datos de  la enfermedad ocupacional",
                                    "Si, Eliminar",
                                    "deleteOccupationalDiseases"
                                  );
                                }}
                                // functionOnClick={() =>
                                //   handleDeleteAgentsTypes(
                                //     row.id_registers_accidents_occupationals_incidents_rels_agents_types
                                //   )
                                // }
                              />
                            ) : (
                              ""
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* responsables del registro y de la investigación */}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Responsables del registro y de la investigación
                </InputLabel>
                <Typography  variant="caption" display="block" gutterBottom sx={{color: '#F2994A', marginLeft:1}}>
                Este campo es obligatorio
                </Typography>
              </Box>
             
              <Box>
                {registerStatus === true ? (
                  <ButtonControla
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#2D9CDB"}
                    backgroundColorHover={"#35B4FB"}
                    textButton={"Agregar responsables"}
                    functionOnClick={
                      handleOpenModalUploadResponsibleRegistration
                    }
                  />
                ) : (
                  ""
                )}
              </Box>
            </Stack>

            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nombre y Apellido
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Documento de identidad
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                       Fecha de firma
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Firma
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {responsibleWorkers.map((row) => (
                      <TableRow
                        hover
                        key={row.id_users}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.first_name + " " + row.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.document}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.firm_date === null ? '' : row.firm_date.substring(0,19)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.firm === 0 ? (
                            "No firmado" && row.id_users === idUserLogged ? (
                              <ButtonIconControla
                                icon={
                                  <HistoryEduOutlined sx={{ color: "white" }} />
                                }
                                backgroundColor={"#F1C40F"}
                                backgroundColorHover={"#F4D03F"}
                                textTooltip={"Firmar"}
                                functionOnClick={() =>
                                  handleOpenModalSignResponsible(
                                    row.id_registers_accidents_occupationals_incidents_responsible
                                  )
                                }
                              />
                            ) : (
                              "No firmado"
                            )
                          ) : (
                            "Firmado"
                          )}
                        </TableCell>

                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            {/* <ButtonIconControla
                              icon={
                                <VisibilityOutlined sx={{ color: "white" }} />
                              }
                              backgroundColor={"#305AD9"}
                              backgroundColorHover={"#0E4AFF"}
                              textTooltip={"Ver"}
                              directionUrl={
                                "/settings/users/edit?idUsers=" + row.id_users
                              }
                              directionTarget={"_blank"}
                              // functionOnClick={() => ""}
                            /> */}
                            {registerStatus === true ? (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id_registers_accidents_occupationals_incidents_responsible,
                                    "¿Esta seguro de eliminar los datos del trabajador responsable?",
                                    "Una vez eliminado no se podra recuperar los datos  del trabajador responsable",
                                    "Si, Eliminar",
                                    "deleteResponsible"
                                  );
                                }}
                                // functionOnClick={() =>
                                //   handleDeleteResponsibleWorkers(
                                //     row.id_registers_accidents_occupationals_incidents_responsible
                                //   )
                                // }
                              />
                            ) : (
                              ""
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* Botones de impresión, guardar y cancelar */}
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                whidth: "100%",
                justifyContent: "space-evenly",
                paddingY: 4,
              }}
            >
              {/* <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<Print sx={{ color: "white" }} />}
                  backgroundColor={"#2D9CDB"}
                  backgroundColorHover={"#33AEF4"}
                  textButton={"Imprimir"}
                  // functionOnClick={""}
                />
              </Grid> */}
              <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<ArrowBack sx={{ color: "white" }} />}
                  backgroundColor={"#EB5757"}
                  backgroundColorHover={"#FF4040"}
                  textButton={"Regresar"}
                  functionOnClick={() =>
                    (window.location.href = "/documentation/registers/mypes/accidentsoccupationalsincidents")
                  }
                />
              </Grid>

              <Grid item xs={12} md={2}>
                {registerStatus === true ? (
                  <ButtonControla
                    iconButton={<EditOutlined sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Actualizar registro"}
                    typeButton="submit"
                    // functionOnClick={handleUpdateOccupationalsDiseases}
                  />
                ) : (
                  ""
                )}
              </Grid>

              <Grid item xs={12} md={2}>
                {registerStatus === true ? (
                  <ButtonControla
                    iconButton={<CloseIcon sx={{ color: "white" }} />}
                    backgroundColor={"black"}
                    backgroundColorHover={"#C4C4C4"}
                    textButton={"Cerrar registro"}
                    functionOnClick={handleAccidentsOcupationalEnd}
                  />
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Box>
        </form>
      </Container>

      {/* Dialog agregar trabajador involucrado */}
      <Dialog
        open={openModalUploadAddDates}
        onClose={handleCloseModalUploadAddDates}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar trabajador involucrado
          </Typography>
        </DialogTitle>
        <form onSubmit={handleStoreAccidentsWorkers}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Tiempo de experiencia en el puesto de trabajo"}
                  inputType={"text"}
                  inputValue={setWorkExperience}
                  value={workExperience}
                  modalType={true}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Área"}
                  inputType={"text"}
                  inputValue={setAreaWork}
                  value={areaWork}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                {/* <InputControlaVertical
                  text={"Turno"}
                  inputType={"text"}
                  inputValue={setShift}
                  value={shift}
                  modalType={true}
                  required
                /> */}
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Turno
                </InputLabel>
                <Select
                  required
                  sx={{
                    fontFamily: "Roboto",
                    width: "100%",
                    backgroundColor: "#F5F5F5",
                    height: "42px",
                    color: "#305AD9",
                  }}
                  value={shift}
                  onChange={handleChangeShift}
                >
                  <MenuItem value={"Dia"}>Dia</MenuItem>
                  <MenuItem value={"Tarde"}>Tarde</MenuItem>
                  <MenuItem value={"Noche"}>Noche</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"N.º de horas trabajadas antes del accidente"}
                  inputType={"text"}
                  inputValue={setWorkHoursBefore}
                  value={workHoursBefore}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SearchButtonControla
                  titleLabel="Trabajador involucrado: "
                  inputValue={responsibleCorrectiveActions}
                  modalType={true}
                  functionClick={handleOpenModalResponsibleInvolvedWorkers}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalUploadAddDates}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog agregar medidas correctivas */}
      <Dialog
        open={openModalUploadCorrectiveActions}
        onClose={handleCloseModalUploadCorrectiveActions}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar medidas correctivas
          </Typography>
        </DialogTitle>
        <form onSubmit={handleStoreCorrectivesActions}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de ejecución"}
                  inputType={"date"}
                  inputValue={setTimestampStartMeasures}
                  value={timestampStartMeasures}
                  modalType={true}
                  required
                />
              </Grid>
              {/* <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Fecha de fin"}
                  inputType={"date"}
                  inputValue={setTimestampEndMeasures}
                  value={timestampEndMeasures}
                  modalType={true}
                  required
                />
              </Grid> */}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Descripción"}
                  inputType={"textArea"}
                  inputValue={setDescriptionMeasures}
                  value={descriptionMeasures}
                  modalType={true}
                  required
                />
              </Grid>
              {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SelectControla
                  text="Estado de medida correctiva:"
                  inputValue={setStatusCorrectivesActions}
                  modalType={true}
                  required
                  value={statusCorrectivesActions}
                  childrenRows={rowsStatusCorrectivesActions.map((rows) => (
                    <MenuItem
                      key={
                        rows.id_registers_accidents_occupationals_incidents_measures_statuses
                      }
                      value={
                        rows.id_registers_accidents_occupationals_incidents_measures_statuses
                      }
                    >
                      {rows.statuses}
                    </MenuItem>
                  ))}
                />
              </Grid> */}

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SearchButtonControla
                  titleLabel="Responsable: "
                  modalType={true}
                  inputValue={responsibleCorrectiveActions}
                  functionClick={
                    handleOpenModalResponsibleSearchUsersCorrectiveActions
                  }
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalUploadCorrectiveActions}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog de  agentes enfermedades ocupacionales */}
      <Dialog
        open={openModalOccupationalDiseases}
        onClose={handleCloseModalOccupationalDiseases}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar datos referentes a las enfermedades ocupacionales
          </Typography>
        </DialogTitle>
        <form onSubmit={handleStoreAccOccIncAgents}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SelectControla
                  text="Tipo de agente:"
                  inputValue={setStatusSelectAgentsTypes}
                  modalType={true}
                  required
                  value={statusSelectAgentsTypes}
                  childrenRows={agentsTypes.map((row) => (
                    <MenuItem
                      key={
                        row.id_registers_accidents_occupationals_incidents_agents
                      }
                      value={
                        row.id_registers_accidents_occupationals_incidents_agents
                      }
                    >
                      {row.agent_type}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "16px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  N° de trabajadores que adquirieron enfermedades ocupacionales
                </InputLabel>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Primer Semestre"}
                  inputType={"number"}
                  inputValue={setFirstSemesterCount}
                  value={firstSemesterCount}
                  modalType={true}
                  // required
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                <InputControlaVertical
                  text={"Segundo Semestre"}
                  inputType={"number"}
                  inputValue={setSecondSemesterCount}
                  value={secondSemesterCount}
                  modalType={true}
                  // required
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Nombre de la Enfermedad Ocupacional"}
                  inputType={"text"}
                  inputValue={setDiseaseName}
                  value={diseaseName}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Áreas donde se presentó la enfermedad ocupacional"}
                  inputType={"text"}
                  inputValue={setArea}
                  value={area}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Causas que generaron la enfermedad ocupacional"}
                  inputType={"text"}
                  inputValue={setCauses}
                  value={causes}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Medidas Correctivas a implementar"}
                  inputType={"text"}
                  inputValue={setMeasures}
                  value={measures}
                  modalType={true}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
                <InputControlaVertical
                  text={"Fecha de Ejecucion"}
                  inputType={"date"}
                  inputValue={setTimestampExecution}
                  value={timestampExecution}
                  modalType={true}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SearchButtonControla
                  titleLabel="Responsable: "
                  modalType={true}
                  inputValue={responsibleCorrectiveActions}
                  functionClick={handleOpenModalAgentsResponsible}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalOccupationalDiseases}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog de agregar repsonsables  del registro  y de la invesatigación */}
      <Dialog
        open={openModalUploadResponsibleRegistration}
        onClose={handleCloseModalUploadResponsibleRegistration}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsables del Registro y de la Investigación
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={rowsSearchsResponsiblesWorkers}
              handleUsersActionButton={handleStoreResponsibleWorkers}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            // color="success"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={handleCloseModalUploadResponsibleRegistration}
          >
            Cancelar
          </Button>
        </DialogActions>
        {/* </form> */}
      </Dialog>

      {/* /* ************************************ Dialogs search************************************************** */}

      {/* Dialogo de buscar trabajadores involucrados */}
      <Dialog
        open={openModalUploadSearchInvolvedWorkers}
        onClose={() => {
          setOpenModalUploadSearchInvolvedWorkers(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Seleccionar Responsable
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "0" }}>
          <SearchUsersControla
            rowsUser={rowsSearchsAccOccIncWorkersUsers}
            handleUsersActionButton={handleSelectUsersResponsible}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModalUploadSearchInvolvedWorkers(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo de buscar usuarios de medidas correctivas */}
      <Dialog
        open={openModalUploadSearchUsersCorrectiveActions}
        onClose={() => {
          setOpenModalUploadSearchUsersCorrectiveActions(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Seleccionar Responsable
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "0" }}>
          <SearchUsersControla
            rowsUser={rowsSearchsCorrectivesActionWorkers}
            handleUsersActionButton={handleSelectUsersResponsible}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModalUploadSearchUsersCorrectiveActions(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo de buscar trabajadores Agregar datos referentes a las enfermedades ocupacionales */}
      <Dialog
        open={openModalUploadAgentsResponsible}
        onClose={() => {
          setOpenModalUploadAgentsResponsible(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Seleccionar Responsable
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "0" }}>
          <SearchUsersControla
            rowsUser={rowsSearchsResponsiblesWorkersAgentesType}
            handleUsersActionButton={handleSelectUsersResponsible}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModalUploadAgentsResponsible(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo para firmar responsable */}
      <Dialog
        open={openModalSignResponsible}
        onClose={() => {
          setOpenModalSignResponsible(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            Sus datos quedarán registrados de forma permanente ¿Desea firmar?
          </Typography>
        </DialogTitle>
        <form onSubmit={handleSignResponsible}>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => {
                setOpenModalSignResponsible(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Firmar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* ver mas datos de enfermedades ocupacionales */}
      <Dialog
        open={openModalMoreAgents}
        onClose={() => {
          setOpenModalMoreAgents(false);
        }}
        maxWidth="md"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Enfermedad ocupacional / incidente peligroso / incidente
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid container sx={{ marginTop: "4px" }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        color: "#305ad9",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Nro de trabajadores que adquirieron las enfermedades
                      ocupacionales PRIMER SEMESTRE
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#305ad9",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Nro de trabajadores que adquirieron las enfermedades
                      ocupacionales SEGUNDO SEMESTRE
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#305ad9",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Áreas donde se presentó la enfermedad ocupacional
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#305ad9",
                        fontSize: "16px",
                        fontWeight: "bold",
                      }}
                    >
                      Causas que originaron la enfermedad ocupacional
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {relsDAgentsTypes.map((row) => (
                    <TableRow
                      hover
                      key={row.id_users}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row.first_semester_count}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.second_semester_count}
                      </TableCell>

                      <TableCell component="th" scope="row">
                        {row.area}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {row.causes}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            // color="success"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalMoreAgents(false);
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
        {/* </form> */}
      </Dialog>

      {/* ver typos de agentes y enfermedades tabla completa */}
      <Dialog
        open={openModalTypeAgentsTable}
        onClose={() => {
          setOpenModalTypeAgentsTable(false);
        }}
        maxWidth="md"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Tipos de Agentes
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid container sx={{ marginTop: "4px" }}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} >
                <TableHead>
                  <TableRow>
                    {agentsTable.map((column) => (
                      <TableCell
                        key={column.id_categories}
                        style={{ minWidth: column.minWidth }}
                      >
                        {column.category}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {agentsTable.map((row) => {
                      return (
                        <TableCell                         
                          key={row.id_categories}
                        >
                          {row.agents.map((column) => {
                            return (
                              <TableRow key={column.id_registers_occupational_diseases_agents_types}>
                                 {/* <TableCell > */}
                                {column.agent_type}
                                {/* </TableCell> */}
                              </TableRow>
                            );
                          })}
                        </TableCell>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            // color="success"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalTypeAgentsTable(false);
            }}
          >
            Cerrar
          </Button>
        </DialogActions>
        {/* </form> */}
      </Dialog>

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        {/* <CircularProgress color="inherit" /> */}
        <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
      </Backdrop>
    </Box>
  );
}

export default Edit;
