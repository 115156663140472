import React from 'react'
import Box from '@mui/material/Box';
import { Button, Grid, LinearProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import Container from '@mui/material/Container';
import { styled } from '@mui/system';
import SideNav from '../components/sidenav/sideNav';
import SubtitleText from '../components/text/subtitleText';
import DocBanner from '../components/cards/docBanner';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import DownloadIcon from '@mui/icons-material/Download';


const grey = "#9191B0";
const blue = "#034AFF";
//const drawerBlue = "#0F1255";
//const uDrawerText = "#65A3FF";
const green = "#2C8C75";
const lilac = "#D1D1E9";
const purple = "#5D5D93";
//const simpleBlue = "#599CFF";
const accordionBlue = "#305AD9";
const backgroundGrey = "#F5F5F5";

const StyledDataButton = styled(Button)({
    textTransform: 'none',
    borderRadius: 14,
    backgroundColor: green,
    color: "white",
    width: "100%"
});

const StyledCancelButton = styled(Button)({
    textTransform: 'none',
    borderRadius: 14,
    backgroundColor: lilac,
    color: purple,
    width: "100%"
});

const StyledAccordion = styled(Accordion)({
    backgroundColor: backgroundGrey,
    borderRadius: '0.9rem',
    boxShadow: 'none',
    color: purple
});

const StyledGreyButton = styled(Button)({
    textTransform: 'none',
    borderRadius: 14,
    backgroundColor: backgroundGrey,
    color: accordionBlue,
    width: "100%"
});

const BorderLinearProgress = styled(LinearProgress)({
    height: 10,
    borderRadius: 5,

});

function ProcessStatusTwo() {
    return (
        <>
            <Box sx={{ display: "flex", height: '100%' }}>
                <SideNav />
                <Container>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container rowSpacing={3} columnSpacing={6}>
                            <Grid item md={12}>
                                <SubtitleText
                                    text={"Proceso de las elecciones"}
                                    color={grey}>
                                </SubtitleText>

                            </Grid>
                            <Grid item md={12}>
                                <DocBanner color1={"#8B8BD8"} color2={"#2B2BA5"} text={"Estado del proceso"}></DocBanner>
                            </Grid>
                            <Grid
                                container direction="row"
                                alignItems="center"
                                justifyContent="left"
                                gap={2}
                                style={
                                    { paddingLeft: "48px", paddingTop: "24px" }
                                }>
                                <Grid container direction="row" alignItems="center" justifyContent="left" columnSpacing={2.5} >
                                    <Grid item md={8}>
                                        <Box sx={{ width: '100%' }}>
                                            <BorderLinearProgress variant="determinate" color={'success'} value={9} />
                                        </Box>
                                    </Grid>
                                    <Grid item md={2}>
                                        <StyledDataButton>Proponer datos</StyledDataButton>
                                    </Grid>
                                    <Grid item md={2}>
                                        <StyledCancelButton>Anular proceso</StyledCancelButton>
                                    </Grid>
                                </Grid>
                                <Grid item md={12}>
                                    <StyledAccordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            sx={{
                                                backgroundColor: lilac,
                                            }}
                                        >
                                            <Typography>Comentarios de los Trabajadores</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                            <Grid container direction={"row"} paddingTop="1em">
                                                    <Grid item md={12} paddingBottom="2em">
                                                        <TableContainer component={Paper}>
                                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                                
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell sx={{ color: blue }} align="center">Trabajador que propone</TableCell>
                                                                        <TableCell sx={{ color: blue }} align="center">Documento de Identidad</TableCell>
                                                                        <TableCell sx={{ color: blue }} align="center">Propuesta</TableCell>
                                                                    
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell sx={{ color: purple }} align="center">
                                                                            Nelson Heredia
                                                                        </TableCell>
                                                                        <TableCell sx={{ color: purple }} align="center">
                                                                            0028463
                                                                        </TableCell>
                                                                        <TableCell sx={{ color: purple }} align="center">
                                                                            Propuesta de ejemplo
                                                                        </TableCell>
                                                                  
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Grid>
                                                </Grid>
                                            </Typography>
                                        </AccordionDetails>
                                    </StyledAccordion>
                                </Grid>
                                <Grid item md={12}>
                                    <StyledAccordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            sx={{
                                                backgroundColor: lilac,
                                            }}
                                        >
                                            <Typography>Impugnaciones por parte de los trabajadores</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Typography>
                                                <Grid container direction={"row"} paddingTop="1em">
                                                    <Grid item md={12} paddingBottom="2em">
                                                        <TableContainer component={Paper}>
                                                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                                
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell sx={{ color: blue }} align="center">Trabajador que propone</TableCell>
                                                                        <TableCell sx={{ color: blue }} align="center">Documento de Identidad</TableCell>
                                                                        <TableCell sx={{ color: blue }} align="center">Impugnación</TableCell>
                                                                    
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell sx={{ color: purple }} align="center">
                                                                            Nelson Heredia
                                                                        </TableCell>
                                                                        <TableCell sx={{ color: purple }} align="center">
                                                                            0028463
                                                                        </TableCell>
                                                                        <TableCell sx={{ color: purple }} align="center">
                                                                            Impugnación de ejemplo
                                                                        </TableCell>
                                                                  
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Grid>
                                                </Grid>
                                            </Typography>
                                        </AccordionDetails>
                                    </StyledAccordion>
                                </Grid>
                                <Grid container direction="row" justifyContent="center" paddingBottom="2em" paddingTop="2em">
                                    <Grid item md={4}>
                                        <StyledGreyButton startIcon={<DownloadIcon />}>
                                            Exportar todo
                                        </StyledGreyButton>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Stack spacing={2}>
                                            <Pagination count={2} />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box >
        </>
    )
}

export default ProcessStatusTwo