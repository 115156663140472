import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Close, Add, ArrowBack } from "@mui/icons-material";
import Container from "@mui/material/Container";

import SideNav from "../../components/sidenav/sideNav";
import LoadingControla from "../../components/load/loadingControla";
import DialogConfirmControla from "../../components/dialog/dialogConfirmControla";
import SubtitleText from "../../components/text/subtitleText";
import ButtonControla from "../../components/buttons/buttonController";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import { EditorControla } from "../../components/editor/editorControla";
import CompanyLogoControla from "../../components/images/companyLogoControla";
import TitleText from "../../components/text/titleText";
import MyAnnualPlan from "../../api/MyAnnualPlan";
import SearchButtonControla from "../../components/search/searchButtonControla";
import MyUsers from "../../api/MyUsers";
import SearchUsersControla from "../../components/search/searchUsersControla";
import { EditorAnnualPlanControla } from "../../components/editor/editorAnnualPlanControla";

const colorTitle = "#9191B0";

function Template() {
  const params = new URLSearchParams(window.location.search);
  const idAnnualPlan = params.get("idAnnualPlan");
  // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  //fin de alerts

  // Dialogos de confirmacion INICIO

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    // setIdPETS(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    switch (buttonActionType) {
      case "delete":
        // deletePets();
        break;
    }
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN
  const [usersActives, setUsersActives] = useState([]);
  const handleGetUsersActives = async () => {
    const response = await MyUsers.users();
    if (response.success === false) {
      alert("Error");
    } else {
      setUsersActives(response.users_actives);
    }
  };

  // Abrir diálogo de cargar archivo
  const [openModalUploaFile, setOpenModalUploadFile] = useState(false);
  const handleOpenModalUploadFile = () => {
    setCode(null);
    setDevelop(null);
    setRevised(null);
    setApproved(null);
    setVersion(null);
    setTitle(null);
    setOpenModalUploadFile(true);
  };

  const [version, setVersion] = useState(null);
  const [title, setTitle] = useState(null);
  const [documentText, setDocumentText] = useState(null);
  const [code, setCode] = useState(null);

  const [develop, setDevelop] = useState("");
  const [dateDevelop, setDateDevelop] = useState("");
  const [revised, setRevised] = useState(null);
  const [dateRevised, setDateRevised] = useState("");
  const [approved, setApproved] = useState(null);
  const [dateApproved, setDateApproved] = useState("");

  const templateText = (textdata) => {
    setDocumentText(textdata);
    console.log(textdata);
  };
  // jalar y mostrar datos del usuario preparado
  const [idUsersDevelop, setIdUsersDevelop] = useState("");
  const [openModalDevelop, setOpenModalDevelop] = useState(false);
  const handleOpenModalDevelop = () => {
    setOpenModalDevelop(true);
  };

  const handleGetUsersDevelop = async (idUser) => {
    handleOpenLoading();
    const response = await MyUsers.editUsers({ idUsers: idUser });
    if (response.success === false) {
      alert("Error");
    } else {
      setDevelop(response.first_name + " " + response.last_name);
      setIdUsersDevelop(response.id_users);
      setOpenModalDevelop(false);
      handleOpenAlert("", "success");

    }
  };

  // jalar y mostrar datos del usuario revisado
  const [idUsersRevised, setIdUsersRevised] = useState("");
  const [openModalRevised, setOpenModalRevised] = useState(false);
  const handleOpenModalRevised = () => {
    setOpenModalRevised(true);
  };

  const handleGetUsersRevised = async (idUser) => {
    handleOpenLoading();

    const response = await MyUsers.editUsers({ idUsers: idUser });
    if (response.success === false) {
      alert("Error");
    } else {
      setRevised(response.first_name + " " + response.last_name);
      setIdUsersRevised(response.id_users);
      setOpenModalRevised(false);
      handleOpenAlert("", "success");

    }
  };

  // jalar y mostrar datos del usuario revisado
  const [idUsersApproved, setIdUsersApproved] = useState("");
  const [openModalApproved, setOpenModalApproved] = useState(false);
  const handleOpenModalApproved = () => {
    setOpenModalApproved(true);
  };

  const handleGetUsersApproved = async (idUser) => {
    handleOpenLoading();

    const response = await MyUsers.editUsers({ idUsers: idUser });
    if (response.success === false) {
      alert("Error");
    } else {
      setApproved(response.first_name + " " + response.last_name);
      setIdUsersApproved(response.id_users);
      setOpenModalApproved(false);
      handleOpenAlert("", "success");

    }
  };

  //   crear
  const handleCreateAnnualPlanTemplate = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyAnnualPlan.createAnnualPlanTemplate({
      code: code,
      develop: develop,
      revised: revised,
      approved: approved,
      version: version,
      title: title,
      document_text: documentText,
      idUsersDevelop,
      idUsersRevised,
      idUsersApproved,
      dateDevelop,
      dateRevised,
      dateApproved,
    });
    if (response.success !== false) {
      handleOpenAlert("El documento se ha creado con éxito", "success");  
      window.location.href = "/documentation/AnnualPlan?idTabs=1";
    } else {
      handleOpenAlert("Ocurrió un error al crear el archivo", "error");
    }
    return response;
  };

  // Editar temlplate
  // editar annual plan document

  const handleEditAnnualPlanTemplate = async () => {
    console.log(idAnnualPlan)
    const response = await MyAnnualPlan.editAnnualPlanTemplate({
      idAnnualPlanTemplate: idAnnualPlan,
    });
    setDevelop(response.develop);
    setCode(response.code);
    setApproved(response.approved);
    setDevelop(response.develop);
    setRevised(response.revised);
    setVersion(response.version);
    setTitle(response.title);
    setDocumentText(response.document_text);
    setDateApproved(response.date_approved);
    setDateRevised(response.date_revised);
    setDateDevelop(response.date_develop);

    return response;
  };
  const handleUpdateAnnualPlanTemplate = async () => {
    handleOpenLoading();
    const response = await MyAnnualPlan.updateAnnualPlanTemplate({
      idAnnualPlanTemplate: idAnnualPlan,
      code: code,
      develop: develop,
      revised: revised,
      approved: approved,
      version: version,
      title: title,
      document_text: documentText,
    });
    handleOpenAlert("Los datos fueron editados de forma correcta", "success");
    return response;
  };

  useEffect(() => {
    handleEditAnnualPlanTemplate();
    handleGetUsersActives();
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="/documentation/AnnualPlan" />
      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />

      <Container>
        <form onSubmit={handleCreateAnnualPlanTemplate}>
          <Grid container justifyContent="space-between">
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <SubtitleText text={"Plan anual"} color={colorTitle}></SubtitleText>
              <TitleText text={"Plantilla Plan Anual"}></TitleText>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={46}>
              <InputControlaVertical
                text={"Código:"}
                inputType={"text"}
                inputValue={setCode}
                value={code}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <InputControlaVertical
                text={"Versión:"}
                inputType={"text"}
                inputValue={setVersion}
                value={version}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <InputControlaVertical
                text={"Título documento:"}
                inputType={"text"}
                inputValue={setTitle}
                value={title}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              {/* <InputControlaVertical
                text={"Elaborado:"}
                inputType={"text"}
                inputValue={setDevelop}
                value={develop}
                required
              /> */}
              <SearchButtonControla
                titleLabel="Elaborado por: "
                inputValue={develop}
                functionClick={handleOpenModalDevelop}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <InputControlaVertical
                text={"Fecha de Elaboración:"}
                inputType={"date"}
                inputValue={setDateDevelop}
                value={dateDevelop}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              {/* <InputControlaVertical
                text={"Revisado:"}
                inputType={"text"}
                inputValue={setRevised}
                value={revised}
                required
              /> */}
              <SearchButtonControla
                titleLabel="Revisado por: "
                inputValue={revised}
                functionClick={handleOpenModalRevised}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <InputControlaVertical
                text={"Fecha de Revisión:"}
                inputType={"date"}
                inputValue={setDateRevised}
                value={dateRevised}
                modalType={false}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              {/* <InputControlaVertical
                text={"Aprobado:"}
                inputType={"text"}
                inputValue={setApproved}
                value={approved}
                required
              /> */}
              <SearchButtonControla
                titleLabel="Aprobado por: "
                inputValue={approved}
                functionClick={handleOpenModalApproved}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <InputControlaVertical
                text={"Fecha de Aprobación:"}
                inputType={"date"}
                inputValue={setDateApproved}
                value={dateApproved}
                modalType={false}
                required
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <EditorAnnualPlanControla
                dataEdit={documentText}
                handleDataEditor={templateText}
              />
            </Grid>
          </Grid>
          <Stack
            direction={"row"}
            spacing={2}
            justifyContent={"center"}
            mt={2}
            mb={2}
          >
            <Box>
              <ButtonControla
                  iconButton={<ArrowBack sx={{ color: "white" }} />}
                  backgroundColor={"#C61C39"}
                backgroundColorHover={"#D61F3E"}
                textButton={"Regresar"}
                typeButton={"button"}
                functionOnClick={() =>
                  (window.location.href = "/documentation/AnnualPlan")
                }
              />
            </Box>{" "}
            {idAnnualPlan === null || idAnnualPlan === undefined ? (
              <Box>
                <ButtonControla
                  iconButton={<Add sx={{ color: "white" }} />}
                  backgroundColor={"#169073"}
                  backgroundColorHover={"#1BAA88"}
                  textButton={"Guardar"}
                  typeButton={"submit"}
                />
              </Box>
            ) : (
              <Box>
                <ButtonControla
                  // iconButton={<Add sx={{ color: "white" }} />}
                  backgroundColor={"#169073"}
                  backgroundColorHover={"#1BAA88"}
                  textButton={"Actualizar"}
                  functionOnClick={() => handleUpdateAnnualPlanTemplate()}
                />
              </Box>
            )}
          </Stack>
        </form>
      </Container>

      {/* Buscar responsables */}
      {/* Elaborado por */}
      <Dialog
        open={openModalDevelop}
        onClose={() => {
          setOpenModalDevelop(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsable de elaborar la plantilla Plan Anual
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={usersActives}
              handleUsersActionButton={handleGetUsersDevelop}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalDevelop(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Revisado por */}
      <Dialog
        open={openModalRevised}
        onClose={() => {
          setOpenModalRevised(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsable de Revisar la plantilla Plan Anual
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={usersActives}
              handleUsersActionButton={handleGetUsersRevised}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalRevised(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Revisado por */}
      <Dialog
        open={openModalApproved}
        onClose={() => {
          setOpenModalApproved(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsable de Aprobar la plantilla Plan Anual
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={usersActives}
              handleUsersActionButton={handleGetUsersApproved}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => {
              setOpenModalApproved(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Template;
