import { React, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
} from "@mui/material";
import Container from "@mui/material/Container";
import { styled } from "@mui/system";
import {
  Add,
  Archive,
  BorderColorOutlined,
  Close,
  Delete,
  Download,
  FileDownloadOutlined,
  Print,
  UploadFile,
  Visibility,
  VisibilityOutlined,
} from "@mui/icons-material";
import SideNav from "../components/sidenav/sideNav";
import SubtitleText from "../components/text/subtitleText";
import DocBanner from "../components/cards/docBanner";
import MyBaseline from "../api/MyBaseline";
import ButtonIconControla from "../components/buttons/buttonIconControla";
import TitleText from "../components/text/titleText";
import InputControlaVertical from "../components/textfields/inputControlaVertical";
import ButtonControla from "../components/buttons/buttonController";
import LoadingControla from "../components/load/loadingControla";
import DialogConfirmControla from "../components/dialog/dialogConfirmControla";
import { CSVLink } from "react-csv";

const grey = "#9191B0";
const blue = "#034AFF";

const StyledSimpleButton = styled(Button)({
  textTransform: "none",
  borderRadius: 14,
  backgroundColor: "#169073",
  color: "white",
  width: "100%",
});

const AddButton = styled(Button)({
  textTransform: "none",
  borderRadius: 6,
  backgroundColor: "#ffc107",
  color: "black",
});

function BaselineDiagnosis() {
  // Pantallas de carga y alertas INICIO
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  // Pantallas de carga y alertas FIN

  const [baselineActiveRows, setBaselineActiveRows] = useState([]);
  const [baselineArchivedRows, setBaselineArchivedRows] = useState([]);
  const [baselineInProgressRows, setBaselineProgressRows] = useState([]);
  const [documentType, setDocumentType] = useState(0);

  const handleChangeDocumentType = (event, newValue) => {
    setDocumentType(newValue);
  };

  const handleGetBaselineDiagnosis = async () => {
    setOpenLoading(true);
    const response = await MyBaseline.baselineDiagnosis();
    console.log(response);
    if (response.success === false) {
      handleOpenAlert(response.msg, "error");
      //console.log("error no se puedo traer lois diagnósticos")
    } else {
      // console.log(window.localStorage.getItem('userToken'));
      const archivedBaselines = response.baseLine_archived;
      const inProgressBaselines = response.baseLine_in_progress;

      const tempActiveBaselines = response.baseLine_current[0];
      if (tempActiveBaselines === null || tempActiveBaselines === undefined) {
      } else {
        const activeBaselines = [];
        activeBaselines.push(tempActiveBaselines);
        handleDownloadImprovementActions(activeBaselines[0].id_baseline);

        setBaselineActiveRows(activeBaselines);
      }
      setOpenLoading(false);
      setBaselineArchivedRows(archivedBaselines);
      setBaselineProgressRows(inProgressBaselines);
    }
    return response;
  };

  const [name, setName] = useState(null);
  const [document_url, setDocument_url] = useState(null);
  const [delete_id, setDelete_id] = useState(null);

  const [openHaveDiagnosticModal, setOpenHaveDiagnosticModal] = useState(false);
  const handleCloseHaveDiagnosticModal = () =>
    setOpenHaveDiagnosticModal(false);
  const handleOpenHaveDiagnosticModal = () => setOpenHaveDiagnosticModal(true);

  const [openStartBaselineProcess, setOpenStartBaselineProcess] =
    useState(false);
  const handleCloseStartBaselineProcess = () =>
    setOpenStartBaselineProcess(false);
  const handelOpenStartBaselineProcess = () =>
    setOpenStartBaselineProcess(true);

  const [openEditDiagnosticModal, setOpenEditDiagnosticModal] = useState(false);
  const handleCloseEditDiagnosticModal = () =>
    setOpenEditDiagnosticModal(false);
  const handleOpenEditDiagnosticModal = () => setOpenEditDiagnosticModal(true);

  const [openAlertModal, setOpenAlertModal] = useState(false);
  const handleCloseAlertModal = () => setOpenAlertModal(false);
  const handleOpenAlertModal = (id) => {
    setDelete_id(id);
    setOpenAlertModal(true);
  };
  const handlePostCreateBaseline = async () => {
    handleOpenLoading();
    const response = await MyBaseline.storeBaseline({ name, document_url });

    if (response.success === true) {
      console.log("Linea Base creada correctamente");
    } else {
      console.log("Error, línea base no se ha creado");
    }
    window.location.href = "/baseline/diagnosis";
  };

  const handlePostStartBaselineProcess = async (event) => {
    event.preventDefault();
    handleOpenLoading();

    const response = await MyBaseline.storeBaseline({ name });
    console.log("Proceso iniciado");
    window.location.href = "answers?id_baseline=" + response.id_baseline;
  };

  const handleDeleteBaseline = async (idBaseline) => {
    handleOpenLoading();
    const response = await MyBaseline.deleteBaseline({ idBaseline });
    if (response.success === false) {
      handleOpenAlert(response.msg, "error");
    } else {
      handleOpenAlert(response.msg, "success");
    }
    window.location.href = "diagnosis";
  };

  const handleArchiveBaseline = async (idBaseline) => {
    handleOpenLoading();
    const response = await MyBaseline.archiveBaselineDiagnosis({
      idBaseline: idBaseline,
    });
    if (response.success === false) {
      handleOpenAlert(response.msg, "error");
    } else {
      handleOpenAlert(response.msg, "success");
    }
    handleGetBaselineDiagnosis();
  };

  // const handleUpdateDiagnosisArchived = (idBaseline) => {
  //   handleOpenLoading();

  // }

  /////////////////////// ALERT

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    // setIdPetar(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    switch (buttonActionType) {
      // case 'delete':
      //   handleDeletePetar();
      //   break;
      // case 'sendWorkers':
      //   sendToWorkers(idPetar);
      //   break;
      // case 'public':
      //   publishPetar(idPetar);
      //   break;
      // case 'archived':
      //   toArchive(idPetar);
      //   break;
      // case 'unarchived':
      //   toUnarchive(idPetar);
      //   break;
      case "upload":
        handlePostCreateBaseline();
    }
    setOpenModalConfirm(false);
  };
  /////////////////////// ALERT
  /////descargar acciones de mejora

  const [csvData, setCsvData] = useState([]);
  const handleDownloadImprovementActions = async (idBaseline) => {
    const response = await MyBaseline.downloadImprovements({
      idBaseline: idBaseline,
    });
    setCsvData(response);
    return response;
  };
  useEffect(() => {
    handleGetBaselineDiagnosis();
  }, []);

  return (
    <>
      <Box sx={{ display: "flex", height: "100%" }}>
        <SideNav returnBack="/baseline" />
        <LoadingControla
          openLoading={openLoading}
          openAlert={openAlert}
          handleCloseAlert={handleCloseAlert}
          messageAlert={messageAlert}
          typeAlert={typeAlert}
        />
        <DialogConfirmControla
          openModalConfirm={openModalConfirm}
          handleCloseModalConfirm={handleCloseModalConfirm}
          titleText={titleText}
          contentText={contentText}
          buttonTextConfirm={buttonTextConfirm}
          handleActionConfirm={handleActionConfirm}
        />
        <Container>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container rowSpacing={3} columnSpacing={6}>
              <Grid item md={12}>
                <SubtitleText text={"Línea Base"} color={grey}></SubtitleText>
                <DocBanner
                  color1={"#8FBCFF"}
                  color2={"#2F67BC"}
                  text={"Mis diagnósticos"}
                ></DocBanner>
              </Grid>
              <Grid
                container
                direction={"row"}
                alignItems="center"
                justifyContent="left"
                gap={2}
                style={{ paddingLeft: "48px", paddingTop: "24px" }}
              >
                <Grid item md={2} alignItems="center" justifyContent={"center"}>
                  <ButtonControla
                    iconButton={<Add sx={{ color: "white " }} />}
                    backgroundColor={"#2D9CDB"}
                    backgroundColorHover={"#33AEF4"}
                    textButton={"Iniciar diagnóstico"}
                    functionOnClick={handelOpenStartBaselineProcess}
                  ></ButtonControla>
                </Grid>
                <Grid item md={2} alignItems="center" justifyContent={"center"}>
                  <ButtonControla
                    iconButton={<UploadFile sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Subir diagnóstico"}
                    functionOnClick={handleOpenHaveDiagnosticModal}
                  >
                    Subir diagnóstico
                  </ButtonControla>
                </Grid>
                <Grid item md={3}></Grid>
              </Grid>
              <Grid item md={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead></TableHead>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          sx={{
                            color: blue,
                          }}
                        >
                          Nombre del documento
                        </TableCell>
                        <TableCell
                          sx={{
                            color: blue,
                            display: documentType !== 0 ? "none" : "",
                          }}
                          align="left"
                        >
                          {" "}
                          Resultado
                        </TableCell>
                        <TableCell
                          sx={{
                            color: blue,
                            display: "",
                          }}
                          align="center"
                        >
                          {" "}
                          Tipo
                        </TableCell>
                        <TableCell colSpan={4}>
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            <Tabs
                              value={documentType}
                              onChange={handleChangeDocumentType}
                              aria-label="Tipos de documento"
                            >
                              <Tab label="Vigente" />
                              <Tab label="En Proceso" />
                              <Tab label="Archivados" />
                            </Tabs>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{ display: documentType !== 1 ? "none" : "" }}
                    >
                      {baselineInProgressRows.map((rowProgress) => (
                        <TableRow
                          hover
                          key={rowProgress.id_baseline}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {rowProgress.name === null ||
                            rowProgress.name === undefined
                              ? ""
                              : rowProgress.name}
                          </TableCell>
                          <TableCell align="center">
                            {rowProgress.document_url !== null
                              ? "ARCHIVO"
                              : "SISTEMA"}
                          </TableCell>
                          <TableCell align="right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                            >
                              {rowProgress.document_url === null ? (
                                <ButtonIconControla
                                  icon={
                                    <BorderColorOutlined
                                      sx={{ color: "white" }}
                                    />
                                  }
                                  backgroundColor={"#2D9CDB"}
                                  textTooltip={"Editar"}
                                  functionOnClick={() =>
                                    (window.location.href =
                                      "answers?id_baseline=" +
                                      rowProgress.id_baseline)
                                  }
                                />
                              ) : (
                                <ButtonIconControla
                                  icon={
                                    <BorderColorOutlined
                                      sx={{ color: "white" }}
                                    />
                                  }
                                  backgroundColor={"#2D9CDB"}
                                  textTooltip={"Editar"}
                                  functionOnClick={
                                    handleOpenEditDiagnosticModal
                                  }
                                />
                              )}

                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                textTooltip={"Eliminar"}
                                functionOnClick={
                                  () =>
                                    handleOpenAlertModal(
                                      rowProgress.id_baseline
                                    )
                                  // handleDeleteBaseline(rowProgress.id_baseline)
                                }
                              />
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableBody
                      sx={{ display: documentType !== 0 ? "none" : "" }}
                    >
                      {baselineActiveRows.map((rowActive, key) => (
                        <TableRow
                          hover
                          key={key}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {rowActive.name === null ||
                            rowActive.name === undefined
                              ? ""
                              : rowActive.name}
                          </TableCell>
                          <TableCell>
                            {rowActive.document_url !== null ? (
                              "Sin Resultado"
                            ) : (
                              <Chip
                                sx={{
                                  backgroundColor:
                                    rowActive.percentage < 40
                                      ? "#d32f2f"
                                      : rowActive.percentage >= 40 &&
                                        rowActive.percentage < 80
                                      ? "#ff9800"
                                      : "#2e7d32",
                                  "&:hover": {
                                    backgroundColor:
                                      rowActive.percentage < 40
                                        ? "#d32f2f"
                                        : rowActive.percentage >= 40 &&
                                          rowActive.percentage < 80
                                        ? "#ff9800"
                                        : "#2e7d32",
                                    color: "#fff",
                                  },
                                  color: "white",
                                }}
                                label={rowActive.percentage + "%"}
                                onClick={() =>
                                  (window.location.href =
                                    "results?id_baseline=" +
                                    rowActive.id_baseline)
                                }
                                // color={rowActive.percentage < 40 ? 'error' : rowActive.percentage >= 40 && rowActive.percentage < 80 ? 'warning':'success'}
                              />
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {rowActive.document_url !== null
                              ? "ARCHIVO"
                              : "SISTEMA"}
                          </TableCell>

                          <TableCell align="right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                            >
                              {/* <ButtonIconControla
                                icon={
                                  <BorderColorOutlined
                                    sx={{ color: "white" }}
                                  />
                                }
                                backgroundColor={"#2D9CDB"}
                                textTooltip={"Editar"}
                                functionOnClick={() =>
                                  (window.location.href =
                                    "" + rowActive.id_baseline)
                                }
                              /> */}
                              {rowActive.document_url !== null ? (
                                <ButtonIconControla
                                  icon={
                                    <VisibilityOutlined
                                      sx={{ color: "white" }}
                                    />
                                  }
                                  backgroundColor={"#305AD9"}
                                  backgroundColorHover={"#0E4AFF"}
                                  textTooltip={"Ver"}
                                  directionUrl={rowActive.document_url}
                                  directionTarget={"_blank"}
                                />
                              ) : (
                                <>
                                  <ButtonIconControla
                                    icon={
                                      <Visibility sx={{ color: "white" }} />
                                    }
                                    backgroundColor={"#ffc107"}
                                    textTooltip={"Ver acciones de mejora"}
                                    functionOnClick={() =>
                                      (window.location.href =
                                        "show/improvementactions?id_baseline=" +
                                        rowActive.id_baseline)
                                    }
                                  />
                                  <ButtonIconControla
                                    icon={
                                      <Visibility sx={{ color: "white" }} />
                                    }
                                    backgroundColor={"#305AD9"}
                                    textTooltip={"Ver resultados"}
                                    functionOnClick={() =>
                                      (window.location.href =
                                        "show/results?id_baseline=" +
                                        rowActive.id_baseline)
                                    }
                                  />
                                </>
                              )}
                              <ButtonIconControla
                                icon={<Archive sx={{ color: "white" }} />}
                                backgroundColor={"#F2994A"}
                                backgroundColorHover={"#FF881E"}
                                textTooltip={"Archivar"}
                                functionOnClick={() =>
                                  handleArchiveBaseline(rowActive.id_baseline)
                                }
                              />

                              {/* <ButtonIconControla
                                icon={<Download sx={{ color: "white" }} />}
                                backgroundColor={"#169073"}
                                textTooltip={"Descargar"}
                                functionOnClick={() =>
                                (window.location.href =
                                  "" + rowActive.id_baseline)
                                }
                              /> */}

                              {/* <ButtonIconControla
                                icon={<Print sx={{ color: "white" }} />}
                                backgroundColor={"#F2994A"}
                                textTooltip={"Imprimir"}
                                functionOnClick={() =>
                                  (window.location.href =
                                    "" + rowActive.id_baseline)
                                }
                              /> */}

                              {/* <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() =>
                                  (window.location.href =
                                    "" + rowActive.id_baseline)
                                }
                              /> */}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableBody
                      sx={{ display: documentType !== 2 ? "none" : "" }}
                    >
                      {baselineArchivedRows.map((rowArchived) => (
                        <TableRow
                          hover
                          key={rowArchived.id_baseline}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {rowArchived.name}
                          </TableCell>
                          <TableCell align="center">
                            {rowArchived.document_url !== null
                              ? "ARCHIVO"
                              : "SISTEMA"}
                          </TableCell>
                          <TableCell align="right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                            >
                              {/* <ButtonIconControla
                                icon={
                                  <BorderColorOutlined
                                    sx={{ color: "white" }}
                                  />
                                }
                                backgroundColor={"#2D9CDB"}
                                textTooltip={"Editar"}
                                functionOnClick={() =>
                                  (window.location.href =
                                    "" + rowArchived.id_baseline)
                                }
                              /> */}

                              <ButtonIconControla
                                icon={<Visibility sx={{ color: "white" }} />}
                                backgroundColor={"#305AD9"}
                                textTooltip={"Ver"}
                                functionOnClick={() =>
                                  (window.location.href =
                                    "show/results?id_baseline=" +
                                    rowArchived.id_baseline)
                                }
                              />

                              {/* <ButtonIconControla
                                icon={<Download sx={{ color: "white" }} />}
                                backgroundColor={"#169073"}
                                textTooltip={"Descargar"}
                                functionOnClick={() =>
                                (window.location.href =
                                  "" + rowArchived.id_baseline)
                                }
                              /> */}
                              {/* 
                              <ButtonIconControla
                                icon={<Print sx={{ color: "white" }} />}
                                backgroundColor={"#F2994A"}
                                textTooltip={"Imprimir"}
                                functionOnClick={() =>
                                  (window.location.href =
                                    "" + rowArchived.id_baseline)
                                }
                              /> */}

                              {/* <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() =>
                                  (window.location.href =
                                    "" + rowArchived.id_baseline)
                                }
                              /> */}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item md={12}></Grid>
            </Grid>

            <Dialog
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
              open={openHaveDiagnosticModal}
              onClose={handleCloseHaveDiagnosticModal}
              maxWidth="md"
              PaperProps={{
                style: {
                  backgroundColor: "#F5F5F5",
                  width: "40%",
                  padding: "10px",
                  borderRadius: "16px",
                },
              }}
              fullWidth={true}
            >
              <DialogTitle>
                <TitleText text={"Cargar Línea Base"} />
                <IconButton
                  aria-label="close"
                  onClick={handleCloseHaveDiagnosticModal}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <Close />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Grid container rowGap={2}>
                  <Grid item md={12}>
                    <InputControlaVertical
                      text="Nombre de línea base"
                      inputType={"text"}
                      inputValue={setName}
                      modalType={false}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <InputControlaVertical
                      text={"Archivo"}
                      inputType={"file"}
                      inputValue={setDocument_url}
                      modalType={false}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <ButtonControla
                  textButton={"Subir diagnóstico"}
                  backgroundColor={"#ffc107"}
                  functionOnClick={() =>
                    handleOpenModalConfirm(
                      1,
                      "Alerta",
                      " Recuerda que al subir este archivo al sistema el diagnóstico línea vigente actual se enviará a la sección archivado y este nuevo archivo será el vigente. El diagnóstico línea archivado ya no podrá regresar a vigente.",
                      "Sí, subir",
                      "upload"
                    )
                  }
                />
              </DialogActions>
            </Dialog>

            <Dialog
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
              open={openStartBaselineProcess}
              onClose={handleCloseStartBaselineProcess}
              maxWidth="md"
              PaperProps={{
                style: {
                  backgroundColor: "#F5F5F5",
                  width: "40%",
                  padding: "10px",
                  borderRadius: "16px",
                },
              }}
              fullWidth={true}
            >
              <form onSubmit={handlePostStartBaselineProcess}>
                <DialogTitle>
                  <TitleText text={"Iniciar Proceso Línea Base"} />
                  <IconButton
                    aria-label="close"
                    onClick={handleCloseStartBaselineProcess}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <Close />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <Grid container>
                    <Grid item md={12}>
                      <InputControlaVertical
                        text="Nombre de línea base"
                        inputType={"text"}
                        inputValue={setName}
                        modalType={false}
                        required
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <ButtonControla
                    textButton={"Iniciar Proceso"}
                    backgroundColor={"#ffc107"}
                    typeButton="submit"
                  />
                </DialogActions>
              </form>
            </Dialog>

            <Dialog
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
              open={openEditDiagnosticModal}
              onClose={handleCloseEditDiagnosticModal}
              maxWidth="md"
              PaperProps={{
                style: {
                  backgroundColor: "#F5F5F5",
                  width: "40%",
                  padding: "10px",
                  borderRadius: "16px",
                },
              }}
              fullWidth={true}
            >
              <DialogTitle>
                <TitleText text={"Editar Línea Base"} />
                <IconButton
                  aria-label="close"
                  onClick={handleCloseEditDiagnosticModal}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <Close />
                </IconButton>
              </DialogTitle>
              <DialogContent>
                <Grid container rowGap={2}>
                  <Grid item md={12}>
                    <InputControlaVertical
                      text="Nombre de línea base"
                      inputType={"text"}
                      inputValue={setName}
                      modalType={false}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <InputControlaVertical
                      text={"Archivo"}
                      inputType={"file"}
                      inputValue={setDocument_url}
                      modalType={false}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <AddButton
                //onClick={handlePostCreateBaseline}
                >
                  Cargar
                </AddButton>
              </DialogActions>
            </Dialog>
            <Dialog
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
              open={openAlertModal}
              onClose={handleCloseAlertModal}
              maxWidth="md"
              PaperProps={{
                style: {
                  backgroundColor: "#F5F5F5",
                  width: "40%",
                  padding: "10px",
                  borderRadius: "16px",
                },
              }}
              fullWidth={true}
            >
              <DialogTitle>¿Está seguro de eliminar?</DialogTitle>
              <DialogContent>
                Se eliminarán los lineamientos, todas las respuestas a los
                lineamientos y los resultados de línea base, una vez hecho esto
                no se podrá recuperar dicha información.
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseAlertModal}>Cancelar</Button>
                <Button
                  onClick={() => {
                    handleDeleteBaseline(delete_id);
                  }}
                >
                  Eliminar
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default BaselineDiagnosis;
