import React from 'react'
import Box from '@mui/material/Box';
import { Button, Card, CardContent, FormControl, FormControlLabel, Grid, LinearProgress, Pagination, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { styled } from '@mui/system';
import SideNav from '../components/sidenav/sideNav';
import SubtitleText from '../components/text/subtitleText';
import TitleText from '../components/text/titleText';
import InputControlaVertical from '../components/textfields/inputControlaVertical';

const grey = "#9191B0";
const blue = "#034AFF";
const green = "#2C8C75";
const lilac = "#D1D1E9";
const purple = "#8B8BD8";

const StyledDataButton = styled(Button)({
    textTransform: 'none',
    borderRadius: 14,
    backgroundColor: green,
    color: "white",
    width: "100%"
});

const StyledCancelButton = styled(Button)({
    textTransform: 'none',
    borderRadius: 14,
    backgroundColor: lilac,
    color: purple,
    width: "100%"
});

const BorderLinearProgress = styled(LinearProgress)({
    height: 10,
    borderRadius: 5,

});

const StyledPercentageText = styled(Typography)({
    color: "9191B0",
    fontSize: 12
});

function BaselineProcess() {
    return (
        <>
            <Box sx={{ display: "flex", height: '100%' }}>
                <SideNav />
                <Container>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container rowSpacing={3} columnSpacing={6}>
                            <Grid item md={12}>
                                <SubtitleText
                                    text={"Línea base"}
                                    color={grey}>
                                </SubtitleText>
                                <TitleText
                                    text={"Proceso de línea base"}>
                                </TitleText>
                            </Grid>
                            <Grid item md={7}>
                                <Box sx={{ width: '100%' }}>
                                    <BorderLinearProgress variant="determinate" color={'success'} value={9} />
                                </Box>
                            </Grid>
                            <Grid item md={1}>
                                <StyledPercentageText>9%</StyledPercentageText>
                            </Grid>
                            <Grid item md={4}>

                            </Grid>
                            <Grid item md={8}>
                                <TitleText text={"Liderazgo y compromiso"} />
                            </Grid>
                            <Grid item md={4}>
                                <Stack spacing={2}>
                                    <Pagination count={2} />
                                </Stack>
                            </Grid>
                            <Grid item md={6}>
                                <Card sx={{
                                    minWidth: 275,
                                    padding: "1em",
                                    borderRadius: "14px"
                                }}
                                >
                                    <CardContent>
                                        <Grid container gap={2}>
                                            <Typography sx={{ fontSize: 14 }} color={blue} gutterBottom>
                                                ¿La empresa cuenta con una estructura de organización relacionada a las eguridad y salud en el trabajo?
                                            </Typography>
                                            <Grid item md={8} >
                                                <FormControl>
                                                    <RadioGroup row >
                                                        <FormControlLabel value="si" control={<Radio />} label="Si" />
                                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                                        <FormControlLabel value="otro" control={<Radio />} label="No aplica (NA)" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={10}>
                                                <InputControlaVertical
                                                    inputType={'textArea'} modalType={true} />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item md={6}>
                                <Card sx={{
                                    minWidth: 275,
                                    padding: "1em",
                                    borderRadius: "14px"
                                }}
                                >
                                    <CardContent>
                                        <Grid container gap={2}>
                                            <Typography sx={{ fontSize: 14 }} color={blue} gutterBottom>
                                                ¿La empresa cuenta con una estructura de organización relacionada a las eguridad y salud en el trabajo?
                                            </Typography>
                                            <Grid item md={8} >
                                                <FormControl>
                                                    <RadioGroup row >
                                                        <FormControlLabel value="si" control={<Radio />} label="Si" />
                                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                                        <FormControlLabel value="otro" control={<Radio />} label="No aplica (NA)" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={10}>
                                                <InputControlaVertical modalType={true} inputType={'textArea'} />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item md={6}>
                                <Card sx={{
                                    minWidth: 275,
                                    padding: "1em",
                                    borderRadius: "14px"
                                }}
                                >
                                    <CardContent>
                                        <Grid container gap={2}>
                                            <Typography sx={{ fontSize: 14 }} color={blue} gutterBottom>
                                                ¿La empresa cuenta con una estructura de organización relacionada a las eguridad y salud en el trabajo?
                                            </Typography>
                                            <Grid item md={8} >
                                                <FormControl>
                                                    <RadioGroup row >
                                                        <FormControlLabel value="si" control={<Radio />} label="Si" />
                                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                                        <FormControlLabel value="otro" control={<Radio />} label="No aplica (NA)" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={10}>
                                                <InputControlaVertical modalType={true} inputType={'textArea'} />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item md={6}>
                                <Card sx={{
                                    minWidth: 275,
                                    padding: "1em",
                                    borderRadius: "14px"
                                }}
                                >
                                    <CardContent>
                                        <Grid container gap={2}>
                                            <Typography sx={{ fontSize: 14 }} color={blue} gutterBottom>
                                                ¿La empresa cuenta con una estructura de organización relacionada a las eguridad y salud en el trabajo?
                                            </Typography>
                                            <Grid item md={8} >
                                                <FormControl>
                                                    <RadioGroup row >
                                                        <FormControlLabel value="si" control={<Radio />} label="Si" />
                                                        <FormControlLabel value="no" control={<Radio />} label="No" />
                                                        <FormControlLabel value="otro" control={<Radio />} label="No aplica (NA)" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Grid>
                                            <Grid item md={10}>
                                                <InputControlaVertical modalType={true} inputType={'textArea'} /> 
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid container item justifyContent="right" gap={2} paddingBottom="20px">
                                <Grid item md={2}>
                                    <StyledDataButton>Guardar y continuar</StyledDataButton>
                                </Grid>
                                <Grid item md={2}>
                                    <StyledCancelButton>Continuar después</StyledCancelButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default BaselineProcess