import React from 'react';

import SubtitleText from '../../../components/text/subtitleText';
import Container from '@mui/material/Container';
import SideNav from "../../../components/sidenav/sideNav";
import Box from '@mui/material/Box';
import { Grid, Button, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import TitleText from '../../../components/text/titleText';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/system';
import InputControla from '../../../components/textfields/inputControla';
import LabelControla from '../../../components/textfields/labelControla';
import BadgeRound from '../../../components/text/badgeRound';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';

const colorTitle = "#9191B0";
const blue = "#034AFF";
const green = "#2C8C75";
const lilac = "#D1D1E9";
const skyblue = "#2D9CDB";

const StyledSaveButton = styled(Button)({
    textTransform: 'none',
    borderRadius: 14,
    backgroundColor: green,
    color: 'white',
    width: "100%"
});

const StyledCancelButton = styled(Button)({
    textTransform: 'none',
    borderRadius: 14,
    backgroundColor: lilac,
    color: 'black',
    width: "100%"
});

const StyledTypography = styled(Typography)({
    color: "#305AD9",
    fontSize: 18,
    fontWeight: 600,
    gap: "2%",
    padding: "0.5em"
})

const StyledAddButton = styled(Button)({
    textTransform: 'none',
    backgroundColor: skyblue,
    borderRadius: 16
});


function Newtemplate() {


    const [openModal, setOpen] = React.useState(false);
    const [openModal2, setOpen2] = React.useState(false);
    const [openModal3, setOpen3] = React.useState(false);
    const [openModal4, setOpen4] = React.useState(false);

    const openAgregarObjetivo = () => {
        setOpen(true);
    };

    const closeAgregarObjetivo = () => {
        setOpen(false);
    };


    const openAgregarActividad = () => {
        setOpen2(true);
    };

    const closeAgregarActividad = () => {
        setOpen2(false);
    };

    const openAgregarColumna = () => {
        setOpen3(true);
    };

    const closeAgregarColumna = () => {
        setOpen3(false);
    };

    const openAgregarProgramacion = () => {
        setOpen4(true);
    };

    const closeAgregarProgramacion = () => {
        setOpen4(false);
    };



    return (

        <Box sx={{ display: "flex", height: '100%' }}>
            <SideNav />

            <Container>

                <Box sx={{ flexGrow: 1 }}>
                    <Grid container justifyContent="flex-end">
                        <Grid item xs={12} md={5}>
                            <SubtitleText text={"Crear objetivos para programa anual"} color={colorTitle} ></SubtitleText>
                            <TitleText text={"Primer objetivo general"}></TitleText>
                        </Grid>
                        <Grid item xs={12} md={5}>
                            <SubtitleText text={"Leyenda"} color={blue} style={{ fontWeight: "bold" }}></SubtitleText>
                            <Grid container >
                                <Grid item xs={12} md={4}>
                                    <BadgeRound initial={"P"} text={"Programado"} color={"#27AE60"}></BadgeRound>
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <BadgeRound initial={"R"} text={"Reprogramado"} color={"#F2994A"}></BadgeRound>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <BadgeRound initial={"E"} text={"Ejecutado"} color={"#2F80ED"}></BadgeRound>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={2} justifyContent="flex-end" style={{ textAlign: 'center' }}>
                            <img src={"/assets/images/logoempresaejemplo.png"} style={{ width: "150px", textAlign: "left" }} alt='logo' />
                        </Grid>
                    </Grid>
                </Box>

                <Box sx={{ flexGrow: 1, mt: 3 }}>

                    <Grid container spacing={2} sx={{ mt: 3 }} style={{ backgroundColor: 'white', borderRadius: '15px' }}>
                        <Grid item md={6}>
                            <StyledTypography>Objetivo general</StyledTypography>
                        </Grid>
                        {/* <Grid item md={3}>
                            <StyledAddButton variant='contained'
                                startIcon={<Add />} disableElevation>
                                Agregar objetivo específico
                            </StyledAddButton>
                        </Grid> */}
                        <Grid item md={3}>
                            <StyledAddButton variant='contained' onClick={openAgregarObjetivo}
                                startIcon={<Add />} disableElevation>
                                Agregar objetivo específico
                            </StyledAddButton>
                        </Grid>
                        <Grid item md={3}>
                            <StyledAddButton variant='contained' onClick={openAgregarActividad}
                                startIcon={<Add />} disableElevation>
                                Agregar actividad
                            </StyledAddButton>
                        </Grid>

                        <Grid item md={12} sx={{ mt: 3, mb: 3 }} style={{ textAlign: 'center' }}>
                            <SubtitleText text={"No cuentas con objetivos específicos ni actividades"} color={colorTitle} ></SubtitleText>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ mt: 3 }} style={{ backgroundColor: 'white', borderRadius: '15px' }}>
                        <Grid item md={6}>
                            <StyledTypography>Presupuesto</StyledTypography>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ mt: 3 }} style={{ backgroundColor: 'white', borderRadius: '15px' }}>
                        <Grid item md={6}>
                            <StyledTypography>Programación</StyledTypography>
                        </Grid>
                        <Grid item md={3}>
                            <StyledAddButton variant='contained' onClick={openAgregarColumna}
                                startIcon={<Add />} disableElevation>
                                Agregar columna
                            </StyledAddButton>
                        </Grid>
                        <Grid item md={3}>
                            <StyledAddButton variant='contained' onClick={openAgregarProgramacion}
                                startIcon={<Add />} disableElevation>
                                Agregar programación
                            </StyledAddButton>
                        </Grid>

                        <Grid item md={12}>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ color: blue }} align="center">Tema de capacitacion</TableCell>
                                            <TableCell sx={{ color: blue }} align="center">Responsable</TableCell>
                                            <TableCell sx={{ color: blue }} align="center">Recursos</TableCell>
                                            <TableCell sx={{ color: blue }} align="center">Presupuesto</TableCell>
                                            <TableCell sx={{ color: blue }} align="center">
                                                <StyledSaveButton>
                                                    <Add />
                                                </StyledSaveButton>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>

                        <Grid item md={12} sx={{ mt: 3, mb: 3 }} style={{ textAlign: 'center' }}>
                            <SubtitleText text={"No cuentas con objetivos específicos ni actividades"} color={colorTitle} ></SubtitleText>
                        </Grid>

                    </Grid>

                    <Grid container justifyContent="center" gap="2em" paddingBottom={10} sx={{ mt: 3 }}>

                        <Grid item md={1.3}>
                            <StyledSaveButton>Guardar</StyledSaveButton>
                        </Grid>
                        <Grid item md={1.3}>
                            <StyledCancelButton>Cancelar</StyledCancelButton>
                        </Grid>
                    </Grid>

                </Box>

            </Container>



            <Dialog open={openModal} onClose={closeAgregarObjetivo} maxWidth="md" fullWidth={true}
                PaperProps={{
                    style: {
                        backgroundColor: "#F5F5F5",
                        width: "55%",
                    },
                }}>

                <DialogTitle> <TitleText text={"Agregar nuevo objetivo específico"}></TitleText><IconButton
                    aria-label="close"
                    onClick={closeAgregarObjetivo}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton></DialogTitle>
                <DialogContent>

                    <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                        <Grid item xs={3} md={4}>
                            <LabelControla text="Objetivo específico"></LabelControla>
                        </Grid>
                        <Grid item xs={9} md={8}>
                            <InputControla inputType={"text"} />
                        </Grid>

                        <Grid item xs={3} md={4}>
                            <LabelControla text="Número de objetivo"></LabelControla>
                        </Grid>
                        <Grid item xs={3} md={2}>
                            <InputControla inputType={"text"} />
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <LabelControla text="Meta"></LabelControla>
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <InputControla inputType={"text"} />
                        </Grid>

                        <Grid item xs={3} md={4}>
                            <LabelControla text="Indicador"></LabelControla>
                        </Grid>
                        <Grid item xs={9} md={8}>
                            <InputControla inputType={"text"} />
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="center" sx={{ mt: 3 }}>
                        <Grid item md={2} sx={{ mr: 3 }}>
                            <StyledCancelButton>Cancelar</StyledCancelButton>
                        </Grid>
                        <Grid item md={2} >
                            <StyledSaveButton>Agregar</StyledSaveButton>
                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog>




            <Dialog open={openModal2} onClose={closeAgregarActividad} maxWidth="md" fullWidth={true}
                PaperProps={{
                    style: {
                        backgroundColor: "#F5F5F5",
                        width: "55%",
                    },
                }}>
                <DialogTitle> <TitleText text={"Agregar actividad"}></TitleText><IconButton
                    aria-label="close"
                    onClick={closeAgregarActividad}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton></DialogTitle>
                <DialogContent>

                    <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                        <Grid item xs={3} md={4}>
                            <LabelControla text="Actividad"></LabelControla>
                        </Grid>
                        <Grid item xs={9} md={8}>
                            <InputControla inputType={"text"} />
                        </Grid>

                        <Grid item xs={3} md={4}>
                            <LabelControla text="Número de actividad"></LabelControla>
                        </Grid>
                        <Grid item xs={3} md={8}>
                            <InputControla inputType={"text"} />
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="center" sx={{ mt: 3 }}>
                        <Grid item md={2} sx={{ mr: 3 }}>
                            <StyledCancelButton>Cancelar</StyledCancelButton>
                        </Grid>
                        <Grid item md={2} >
                            <StyledSaveButton>Agregar</StyledSaveButton>
                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog>


            <Dialog open={openModal3} onClose={closeAgregarColumna} maxWidth="md" fullWidth={true}
                PaperProps={{
                    style: {
                        backgroundColor: "#F5F5F5",
                        width: "55%",
                    },
                }}>
                <DialogTitle> <TitleText text={"Agregar columna"}></TitleText><IconButton
                    aria-label="close"
                    onClick={closeAgregarColumna}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton></DialogTitle>
                <DialogContent>

                    <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                        <Grid item xs={3} md={4}>
                            <LabelControla text="Nombre de columna"></LabelControla>
                        </Grid>
                        <Grid item xs={9} md={8}>
                            <InputControla inputType={"text"} />
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="center" sx={{ mt: 3 }}>
                        <Grid item md={2} sx={{ mr: 3 }}>
                            <StyledCancelButton>Cancelar</StyledCancelButton>
                        </Grid>
                        <Grid item md={2} >
                            <StyledSaveButton>Agregar</StyledSaveButton>
                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog>


            <Dialog open={openModal4} onClose={closeAgregarProgramacion} maxWidth="md" fullWidth={true}
                PaperProps={{
                    style: {
                        backgroundColor: "#F5F5F5",
                        width: "55%",
                    },
                }}>
                <DialogTitle> <TitleText text={"Agregar programación"}></TitleText><IconButton
                    aria-label="close"
                    onClick={closeAgregarProgramacion}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton></DialogTitle>
                <DialogContent>

                    <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
                        <Grid item xs={3} md={4}>
                            <LabelControla text="Nombre de actividad"></LabelControla>
                        </Grid>
                        <Grid item xs={9} md={8}>
                            <InputControla inputType={"text"} />
                        </Grid>

                        <Grid item xs={3} md={4}>
                            <LabelControla text="Responsable"></LabelControla>
                        </Grid>
                        <Grid item xs={3} md={2}>
                            <InputControla inputType={"text"} />
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <LabelControla text="Recursos"></LabelControla>
                        </Grid>
                        <Grid item xs={3} md={3}>
                            <InputControla inputType={"text"} />
                        </Grid>

                        <Grid item xs={3} md={4}>
                            <LabelControla text="Presupuesto"></LabelControla>
                        </Grid>
                        <Grid item xs={9} md={8}>
                            <InputControla inputType={"text"} />
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="center" sx={{ mt: 3 }}>
                        <Grid item md={2} sx={{ mr: 3 }}>
                            <StyledCancelButton>Cancelar</StyledCancelButton>
                        </Grid>
                        <Grid item md={2} >
                            <StyledSaveButton>Agregar</StyledSaveButton>
                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog>



        </Box>

    );

}

export default Newtemplate;

