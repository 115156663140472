import * as React from "react";
import SubtitleText from "../components/text/subtitleText";
import TitleText from "../components/text/titleText";
import Container from "@mui/material/Container";
import SideNav from "../components/sidenav/sideNav";
import Box from "@mui/material/Box";

import { Grid } from "@mui/material";
import "../index.css";
import CardControla from "../components/cards/cardControla";
import BannerButtonControla from "../components/cards/bannerButtonControla";

const colorTitle = "#9191B0";
// Recuerda agendar la reunión mensual de tu Comité

function Index() {
  return (
    <Box sx={{ display: "flex" }}>
      <SideNav />

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={6} sm={5} md={6} lg={6} xl={12}>
              <TitleText text={"Capacitaciones"} color={colorTitle}></TitleText>
            </Grid>
            <Grid item xs={12} md={12}>
              <BannerButtonControla
                color1={'#090979'}
                color2={'#00d4ff'}
                title={'Recuerda llevar al día tus capacitaciones '}
                textButton={'Comenzar ahora'}
                image={"/assets/images/RightBannerTrainings.png"}
              />

            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/trainings/subjects"}
                text={"Temas"}
                img={"/assets/images/Subjects.png"}
                roles={[1, 3, 5, 7, 15]}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/trainings/all"}
                text={"Todas las Capacitaciones"}
                img={"/assets/images/Trainings.png"}
                roles={[1, 3, 5, 7, 15]}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/trainings/personal"}
                text={"Mis Capacitaciones"}
                img={"/assets/images/MyTrainings.png"}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default Index;
