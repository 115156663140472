import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function getSubjects() {
  const url = "trainings/subject/get";
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function createSubjects({ subject }) {
  const url = "trainings/subject/store";
  const data = {
    subject: subject,
  };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function updateSubjects({ id_trainings_subjects, subject }) {
  const url = "trainings/subject/update/" + id_trainings_subjects;
  const data = {
    subject: subject,
  };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function deleteSubjects({ id_trainings_subjects }) {
  const url = "trainings/subject/delete/" + id_trainings_subjects;
  return MyConfigurationApi.connectApi({ dataJson: null, method: "DELETE", url: url });
}

function getModalities() {
  const url = "trainings/modalities/get";
  return MyConfigurationApi.connectApi({ dataJson: null, method: "GET", url: url });
}

function getTypes() {
  const url = "trainings/types/get";
  return MyConfigurationApi.connectApi({ dataJson: null, method: "GET", url: url });
}

function getStatuses() {
  const url = "trainings/statuses/get";
  return MyConfigurationApi.connectApi({ dataJson: null, method: "GET", url: url });
}

function getTrainings({ id_trainings = '', id_trainings_subjects = '', id_trainings_types = '', id_trainings_statuses = '', id_trainings_modalities = '', id_users_responsible = '', id_campus = '', timestamp_start = '', timestamp_end = '', year = '' }) {
  const url = "trainings/all/get?id_trainings=" + id_trainings + "&id_trainings_subjects=" + id_trainings_subjects + "&id_trainings_types=" + id_trainings_types + "&id_trainings_statuses=" + id_trainings_statuses + "&id_trainings_modalities=" + id_trainings_modalities + "&id_users_responsible=" + id_users_responsible + "&id_campus=" + id_campus + "&timestamp_start=" + timestamp_start + "&timestamp_end=" + timestamp_end + "&year=" + year;
  console.log(url);
  return MyConfigurationApi.connectApi({ dataJson: null, method: "GET", url: url });
}

function createTrainings({ id_trainings_subjects, name }) {
  const url = "trainings/store";
  const data = {
    name: name,
    id_trainings_subjects: id_trainings_subjects,
  };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function updateTrainings({ id_trainings, id_trainings_subjects, name, manager, hours, minutes, observations, id_trainings_types, id_trainings_statuses, id_trainings_modalities, id_users_responsible, timestamp_start, timestamp_end, year, month }) {
  const url = "trainings/update/" + id_trainings;
  const data = {
    id_trainings: id_trainings,
    id_trainings_subjects: id_trainings_subjects,
    name: name,
    manager: manager,
    hours: hours,
    minutes: minutes,
    observations: observations,
    id_trainings_types: id_trainings_types,
    id_trainings_statuses: id_trainings_statuses,
    id_trainings_modalities: id_trainings_modalities,
    id_users_responsible: id_users_responsible,
    timestamp_start: timestamp_start,
    timestamp_end: timestamp_end,
    year: year,
    month: month
  };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function updateTrainingsStatuses({ id_trainings, id_trainings_statuses }) {
  const url = "trainings/update/statuses/" + id_trainings;
  const data = {
    id_trainings_statuses: id_trainings_statuses
  };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}


function getRating({ id_trainings }) {
  const url = "trainings/rating/get/" + id_trainings;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}


function getNotWorkers({ id_trainings }) {
  const url = "trainings/not/workers/get/" + id_trainings;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function getWorkers({ id_trainings }) {
  const url = "trainings/workers/get/" + id_trainings;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function storeWorkers({ id_trainings, id_users }) {
  const url = "trainings/workers/store/" + id_trainings;
  const data = {
    id_users: id_users,
  };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function deleteWorkers({ id_trainings_workers }) {
  const url = "trainings/workers/delete/" + id_trainings_workers;
  return MyConfigurationApi.connectApi({ dataJson: null, method: "DELETE", url: url });
}

function updateWorkersFirm({ id_users, qualification, id_trainings }) {
  const url = "trainings/workers/update/firm/" + id_trainings;

  const data = {
    id_users: id_users,
    qualification: qualification
  };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function getEvidences({ id_trainings }) {
  const url = "trainings/evidences/get/" + id_trainings;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function storeEvidences({ id_trainings, evidence, evidence_url }) {
  if (evidence_url !== null && evidence_url !== undefined) {
    const current = new Date();
    const year = current.getFullYear();
    const month = current.getMonth() + 1;
    const day = current.getDate();
    evidence_url = await uploadFileCompanies(evidence_url, 'trainings/' + year + '/' + month + '/' + day + '/' + id_trainings + '/evidences/');
  }
  const url = "trainings/evidences/store/" + id_trainings;
  const data = {
    evidence: evidence,
    evidence_url: evidence_url
  };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

async function updateEvidences({ id_trainings_evidences, evidence, evidence_url, id_trainings }) {
  if (evidence_url !== null && evidence_url !== undefined) {
    const current = new Date();
    const year = current.getFullYear();
    const month = current.getMonth() + 1;
    const day = current.getDate();
    evidence_url = await uploadFileCompanies(evidence_url, 'trainings/' + year + '/' + month + '/' + day + '/' + id_trainings + '/evidences/');
  }
  const url = "trainings/evidences/update/" + id_trainings_evidences;
  const data = {
    evidence: evidence,
    evidence_url: evidence_url
  };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function deleteEvidences({ id_trainings_evidences }) {
  const url = "trainings/evidences/delete/" + id_trainings_evidences;
  return MyConfigurationApi.connectApi({ dataJson: null, method: "DELETE", url: url });
}

function getMaterials({ id_trainings }) {
  const url = "trainings/materials/get/" + id_trainings;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function storeMaterials({ id_trainings, material, material_url }) {
  if (material_url !== null && material_url !== undefined) {
    const current = new Date();
    const year = current.getFullYear();
    const month = current.getMonth() + 1;
    const day = current.getDate();
    material_url = await uploadFileCompanies(material_url, 'trainings/' + year + '/' + month + '/' + day + '/' + id_trainings + '/materials/');
  }
  const url = "trainings/materials/store/" + id_trainings;
  const data = {
    material: material,
    material_url: material_url
  };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

async function updateMaterials({ id_trainings_materials, material, material_url, id_trainings }) {
  if (material_url !== null && material_url !== undefined) {
    const current = new Date();
    const year = current.getFullYear();
    const month = current.getMonth() + 1;
    const day = current.getDate();
    material_url = await uploadFileCompanies(material_url, 'trainings/' + year + '/' + month + '/' + day + '/' + id_trainings + '/materials/');
  }
  const url = "trainings/materials/update/" + id_trainings_materials;
  const data = {
    material: material,
    material_url: material_url
  };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function deleteMaterials({ id_trainings_materials }) {
  const url = "trainings/materials/delete/" + id_trainings_materials;
  return MyConfigurationApi.connectApi({ dataJson: null, method: "DELETE", url: url });
}

function getExams({ id_trainings }) {
  const url = "trainings/exams/get/" + id_trainings;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function storeExams({ id_trainings, name }) {
  const url = "trainings/exams/store/" + id_trainings;
  const data = {
    name: name
  };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function updateExams({ id_trainings_exams, name }) {
  const url = "trainings/exams/update/" + id_trainings_exams;
  const data = {
    name: name
  };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function deleteExams({ id_trainings_exams }) {
  const url = "trainings/exams/delete/" + id_trainings_exams;
  return MyConfigurationApi.connectApi({ dataJson: null, method: "DELETE", url: url });
}

function getQuestions({ id_trainings_exams }) {
  const url = "trainings/exams/questions/get/" + id_trainings_exams;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function storeQuestions({ id_trainings_exams, question, value }) {
  const url = "trainings/exams/questions/store/" + id_trainings_exams;
  const data = {
    question: question,
    value: value
  };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function updateQuestions({ id_trainings_exams_questions, question, value }) {
  const url = "trainings/exams/questions/update/" + id_trainings_exams_questions;
  const data = {
    question: question,
    value: value
  };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function deleteQuestions({ id_trainings_exams_questions }) {
  const url = "trainings/exams/questions/delete/" + id_trainings_exams_questions;
  return MyConfigurationApi.connectApi({ dataJson: null, method: "DELETE", url: url });
}

function getAnswers({ id_trainings_exams_questions }) {
  const url = "trainings/exams/questions/answers/get/" + id_trainings_exams_questions;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function storeAnswers({ id_trainings_exams_questions, answer, correct }) {
  const url = "trainings/exams/questions/answers/store/" + id_trainings_exams_questions;
  const data = {
    answer: answer,
    correct: correct
  };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function updateAnswers({ id_trainings_exams_questions_answers, answer, correct }) {
  const url = "trainings/exams/questions/answers/update/" + id_trainings_exams_questions_answers;
  const data = {
    answer: answer,
    correct: correct
  };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function deleteAnswers({ id_trainings_exams_questions_answers }) {
  const url = "trainings/exams/questions/answers/delete/" + id_trainings_exams_questions_answers;
  return MyConfigurationApi.connectApi({ dataJson: null, method: "DELETE", url: url });
}

function getPersonalTrainings({ id_trainings = '', id_trainings_subjects = '', id_trainings_types = '', id_trainings_statuses = '', id_trainings_modalities = '', id_users_responsible = '', id_campus = '', timestamp_start = '', timestamp_end = '', year = '' }) {
  const url = "trainings/personal/get?id_trainings=" + id_trainings + "&id_trainings_subjects=" + id_trainings_subjects + "&id_trainings_types=" + id_trainings_types + "&id_trainings_statuses=" + id_trainings_statuses + "&id_trainings_modalities=" + id_trainings_modalities + "&id_users_responsible=" + id_users_responsible + "&id_campus=" + id_campus + "&timestamp_start=" + timestamp_start + "&timestamp_end=" + timestamp_end + "&year=" + year;
  console.log(url);
  return MyConfigurationApi.connectApi({ dataJson: null, method: "GET", url: url });
}

function getQuestionsAnswers({ id_trainings_exams }) {
  const url = "trainings/exams/workers/questions/answers/get/" + id_trainings_exams;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}


function storeQuestionsAnswers({ array_questions_answers }) {
  const url = "trainings/exams/workers/questions/answers/store";
  const data = {
    array_questions_answers: array_questions_answers
  };
  console.log('GAAAAA');
  console.log(array_questions_answers);
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function getExamsQualification({ id_trainings }) {
  const url = "trainings/exams/workers/questions/qualification/get/" + id_trainings;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}


const MyTrainings = {
  getSubjects,
  createSubjects,
  updateSubjects,
  deleteSubjects,
  getModalities,
  getTypes,
  getStatuses,
  getTrainings,
  createTrainings,
  updateTrainings,
  updateTrainingsStatuses,
  getRating,
  getNotWorkers,
  getWorkers,
  storeWorkers,
  deleteWorkers,
  updateWorkersFirm,
  getEvidences,
  storeEvidences,
  updateEvidences,
  deleteEvidences,
  getMaterials,
  storeMaterials,
  updateMaterials,
  deleteMaterials,
  getExams,
  storeExams,
  updateExams,
  deleteExams,
  getQuestions,
  storeQuestions,
  updateQuestions,
  deleteQuestions,
  getAnswers,
  storeAnswers,
  updateAnswers,
  deleteAnswers,
  getPersonalTrainings,
  getQuestionsAnswers,
  storeQuestionsAnswers,
  getExamsQualification
};

export default MyTrainings;
