import { React, useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import { InputLabel, InputBase, Grid, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Modal, Typography, Tabs, Tab, Stack, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import Container from '@mui/material/Container';
// import { Download, Edit, Print, Visibility, Add, FeaturedVideoOutlined, DriveFolderUpload } from '@mui/icons-material';
import { Add, BorderColorOutlined, VisibilityOutlined, Archive, DriveFolderUpload, ArrowBackIosNew, ArrowForwardIos, Send, FileUploadOutlined, Delete, Unarchive } from '@mui/icons-material';
import SideNav from '../../../../components/sidenav/sideNav';
import SubtitleText from '../../../../components/text/subtitleText';
import InputControlaVertical from '../../../../components/textfields/inputControlaVertical';
import ButtonIconControla from '../../../../components/buttons/buttonIconControla';
import BannerControla from '../../../../components/cards/bannerControla';
import MyAts from '../../../../api/MyAts';
import ButtonControla from '../../../../components/buttons/buttonController';
import DialogTitleControla from '../../../../components/dialog/dialogTitleControla';
import LoadingControla from '../../../../components/load/loadingControla';
import DialogConfirmControla from '../../../../components/dialog/dialogConfirmControla';

const grey = "#9191B0";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    maxWidth: 800,
    overflow: 'visible',
    border: '1px solid #000',
    backgroundColor: 'background.paper',
    borderRadius: '16px',
    boxShadow: 24,
    p: 4,
};

function Index() {
    const params = new URLSearchParams(window.location.search);
    const idTabs = parseInt(
        params.get("idTabs") !== null ? params.get("idTabs") : 0
    );

    // Pantallas de carga y alertas INICIO
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState('');
    const [typeAlert, setTypeAlert] = useState('success');

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert('');
        setTypeAlert('success');
    }

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    }

    const handleCloseAlert = () => {
        setOpenAlert(false);
    }

    // Pantallas de carga y alertas FIN


    // Dialogos de confirmacion INICIO

    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [titleText, setTitleText] = useState('');
    const [contentText, setContentText] = useState('');
    const [buttonTextConfirm, setButtonTextConfirm] = useState('');
    const [buttonActionType, setButtonActionType] = useState('');


    const handleOpenModalConfirm = (id, titleText, contentText, buttonTextConfirm, buttonActionType) => {
        setIdAts(id);
        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setButtonActionType(buttonActionType);
        setOpenModalConfirm(true);
    }

    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    }

    const handleActionConfirm = () => {
        switch (buttonActionType) {
            case 'delete':
                handleDeleteAts();
                break;
            case 'sendWorkers':
                handleUpdateStatusesAts(2);
                break;
            case 'public':
                handleUpdateStatusesAts(3);
                break;
            case 'archived':
                handleUpdateArchiveAts(1);
                break;
            case 'unarchived':
                handleUpdateArchiveAts(0);
                break;
        }
        setOpenModalConfirm(false);
    }

    // Dialogos de confirmacion FIN

    const handleGetAts = async (inputYear = undefined) => {
        if (inputYear != undefined) {
            handleOpenLoading();
        }
        const response = await MyAts.ats({ year: (inputYear == undefined ? year : inputYear) });

        if (response.success === false) {
            handleOpenAlert('Ocurrio un error al listar los ATS', 'error');
        } else {
            const ats_model = response.ats_model
            const ats_no_archived = response.ats_no_archived
            const ats_archived = response.ats_archived
            setRowsModels(ats_model);
            setRowsActives(ats_no_archived);
            setRowsArchives(ats_archived);
            if (inputYear != undefined) {
                setDocumentType(1);
                handleOpenAlert('Listando ATS activos del año ' + inputYear, 'success');
            }
        }
        return response;
    };

    const handlePostCreateAts = async (event) => {
        event.preventDefault();
        handleOpenLoading();
        const response = await MyAts.createAts({ name, code, model: 0, document_url: documentUrl });
        if (response.success !== false) {
            handleGetAts();
            handleCloseModalUpload();
            handleOpenAlert(response.msg, 'success');
        } else {
            handleOpenAlert('Ocurrio un error al crear el ATS', 'error');
        }
        return response;
    };

    const handlePostCreateModelAts = async (event) => {
        event.preventDefault();
        handleOpenLoading();
        const response = await MyAts.createAts({ name, code, model: 1 });
        if (response.success !== false) {
            window.location.href = '/ats/model/edit?id_ats=' + response.id_ats;
        } else {
            handleOpenAlert('Ocurrio un error al crear el modelo ATS', 'error');
        }
        return response;
    };

    const handleDeleteAts = async () => {
        handleOpenLoading();
        const response = await MyAts.deleteAts({ id_ats: idAts });
        if (response.success !== false) {
            handleGetAts();
            handleOpenAlert(response.msg, 'success');
        } else {
            handleOpenAlert('Ocurrio un error al crear el ATS', 'error');
        }
        return response;
    };


    useEffect(() => {
        handleGetAts();
    }, []);

    const [rowsModels, setRowsModels] = useState([]);
    const [rowsActives, setRowsActives] = useState([]);
    const [rowsArchives, setRowsArchives] = useState([]);
    const currentTime = new Date();
    const [year, setYear] = useState(currentTime.getFullYear());

    const [documentType, setDocumentType] = useState(
        idTabs !== undefined && idTabs !== null && idTabs !== "" ? parseInt(idTabs) : 0
    );
    const [idAts, setIdAts] = useState(null);

    const handleChangeDocumentType = (event, newValue) => {
        setDocumentType(newValue);
    };

    const handleIncreaseYear = (event, newValue) => {
        setYear(year + 1);
        handleGetAts(year + 1);
    };

    const handleDecreaseYear = (event, newValue) => {
        setYear(year - 1);
        handleGetAts(year - 1);
    };


    const [name, setName] = useState(null);
    const [code, setCode] = useState(null);
    const [area, setArea] = useState(null);
    const [place, setPlace] = useState(null);
    const [hourStart, setHourStart] = useState(null);
    const [hourFinish, setHourFinish] = useState(null);
    const [documentUrl, setDocumentUrl] = useState(null);

    const [openModalCreate, setOpenModalCreate] = useState(false);
    const handleOpenModalCreate = () => setOpenModalCreate(true);
    const handleCloseModalCreate = () => setOpenModalCreate(false);

    const [openModalUpload, setOpenModalUpload] = useState(false);
    const handleOpenModalUpload = () => {
        setName(null);
        setCode(null);
        setArea(null);
        setPlace(null);
        setHourStart(null);
        setHourFinish(null);
        setDocumentUrl(null);
        setOpenModalUpload(true);
    }

    const handleCloseModalUpload = () => setOpenModalUpload(false);

    const [openModalEditUpload, setOpenModalEditUpload] = useState(false);
    const handleOpenModalEditUpload = (idAts, name, code) => {
        setIdAts(idAts)
        setName(name);
        setCode(code);
        setOpenModalEditUpload(true);
    }

    const handleCloseModalEditUpload = () => setOpenModalEditUpload(false);

    const handleUpdateAts = async (event) => {
        event.preventDefault();
        handleOpenLoading();
        const response = await MyAts.updateAts({ id_ats: idAts, name, code, document_url: documentUrl });

        if (response.success !== false) {
            handleGetAts();
            handleCloseModalEditUpload();
            handleOpenAlert(response.msg, 'success');
            setDocumentType(1);
        } else {
            handleOpenAlert('Hubo un error al actualizar', 'success');
        }
        return response;
    };

    const handleUpdateStatusesAts = async (idAtsStatuses) => {
        handleOpenLoading();
        const response = await MyAts.updateStatusesAts({ id_ats: idAts, id_ats_statuses: idAtsStatuses });

        if (response.success !== false) {
            handleGetAts();
            handleCloseModalEditUpload();
            handleOpenAlert(response.msg, 'success');
        } else {
            handleOpenAlert(response.msg, 'error');
        }
        return response;
    };

    const handleUpdateArchiveAts = async (archived) => {
        handleOpenLoading();
        const response = await MyAts.updateArchiveAts({ id_ats: idAts, archived: archived });

        if (response.success !== false) {
            handleGetAts();
            handleCloseModalEditUpload();
            handleOpenAlert(response.msg, 'success');
        } else {
            handleOpenAlert(response.msg, 'error');
        }
        return response;
    };


    return (
        <>
            <Box sx={{ display: "flex", height: '100%' }}>
                <SideNav returnBack='/documentation/documents/procedures' />

                <LoadingControla openLoading={openLoading} openAlert={openAlert} handleCloseAlert={handleCloseAlert} messageAlert={messageAlert} typeAlert={typeAlert} />

                <DialogConfirmControla openModalConfirm={openModalConfirm} handleCloseModalConfirm={handleCloseModalConfirm} titleText={titleText} contentText={contentText} buttonTextConfirm={buttonTextConfirm} handleActionConfirm={handleActionConfirm} />

                <Container>
                    <Box>
                        <Grid container >
                            <Grid item xs={12} md={12}>
                                <SubtitleText
                                    text={"Procedimientos"}
                                    color={grey}>
                                </SubtitleText>
                                <BannerControla image='/assets/images/banners/documents/atsBanner.png' color2='#2F67BC' color1='#8FBCFF' text='ATS' />
                            </Grid>
                        </Grid>
                        <Grid container sx={{ mt: 2 }}>
                            <Grid item xs={12} sm={7} md={6} lg={6} xl={6}>
                                <Stack direction={'row'} spacing={2}>
                                    <Box>
                                        <ButtonControla
                                            roles={[1, 7, 8, 15]}
                                            iconButton={<Add sx={{ color: "white" }} />}
                                            backgroundColor={"#2D9CDB"}
                                            backgroundColorHover={"#33AEF4"}
                                            textButton={"Agregar modelo ATS"}
                                            functionOnClick={handleOpenModalCreate}
                                        />
                                    </Box>
                                    <Box>
                                        <ButtonControla
                                            roles={[1, 7, 8, 15]}
                                            iconButton={<DriveFolderUpload sx={{ color: "white" }} />}
                                            backgroundColor={"#169073"}
                                            backgroundColorHover={"#1BAA88"}
                                            textButton={"Subir archivo ATS"}
                                            functionOnClick={handleOpenModalUpload}
                                        />
                                    </Box>
                                </Stack>
                            </Grid>

                            <Grid item xs={12} sm={12} md={2} lg={3} xl={3} sx={{ mt: 2 }}>
                            </Grid>

                            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={3} sm={2} md={3} lg={3} xl={3}>
                                        <InputLabel
                                            sx={{
                                                padding: '8px 0px 8px 0px',
                                                fontSize: '14px',
                                                color: '#305AD9',
                                                fontWeight: 'bold'
                                            }}> Periodo:
                                        </InputLabel>
                                    </Grid>
                                    <Grid item xs={3} sm={3} md={4} lg={4} xl={4}>
                                        <InputBase type='number'
                                            value={year}
                                            onChange={handleGetAts}
                                            readOnly
                                            sx={{
                                                width: '100%',
                                                padding: '4px 10px 4px 10px',
                                                backgroundColor: 'white',
                                                color: '#305AD9',
                                                fontSize: '14px',
                                                borderRadius: '8px',
                                            }}></InputBase>

                                    </Grid>
                                    <Grid item xs={2} sm={1} md={2} lg={2} xl={2}>
                                        <IconButton
                                            sx={{ backgroundColor: 'white', borderRadius: '15px', color: '#305AD9' }}
                                            onClick={handleDecreaseYear}
                                        >
                                            <ArrowBackIosNew />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={2} sm={1} md={2} lg={2} xl={2}>
                                        <IconButton
                                            sx={{ backgroundColor: 'white', borderRadius: '15px', color: '#305AD9' }}
                                            onClick={handleIncreaseYear}
                                        >
                                            <ArrowForwardIos />
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid container sx={{ mt: 2 }}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Paper sx={{ overflow: 'hidden' }}>
                                    <TableContainer sx={{ maxHeight: 400 }}>
                                        <Table stickyHeader sx={{ width: '100%', minWidth: '700px' }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Nombre del documento</TableCell>
                                                    <TableCell sx={{ display: (documentType === 0 ? 'none' : '') }}>Tipo</TableCell>
                                                    <TableCell sx={{ display: (documentType === 0 ? 'none' : '') }}>Estado</TableCell>
                                                    <TableCell colSpan={4} >
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="end"
                                                            alignItems="center"
                                                        >
                                                            <Tabs value={documentType} onChange={handleChangeDocumentType} aria-label="Tipos de documento" >
                                                                <Tab label="Modelos" />
                                                                <Tab label="Activados" />
                                                                <Tab label="Archivados" />
                                                            </Tabs>
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody sx={{ display: (documentType !== 0 ? 'none' : '') }}  >
                                                {rowsModels
                                                    .map((rowsModels) => (
                                                        <TableRow hover
                                                            key={rowsModels.id_ats}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >

                                                            <TableCell component="th" scope="row">
                                                                {rowsModels.name}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <Stack
                                                                    direction="row"
                                                                    justifyContent="end"
                                                                    alignItems="center"
                                                                >
                                                                    <ButtonIconControla
                                                                        roles={[1, 7, 8, 15]}
                                                                        icon={<BorderColorOutlined sx={{ color: 'white' }} />} backgroundColor={"#2D9CDB"}
                                                                        backgroundColorHover={"#33AEF4"} textTooltip={'Editar'}
                                                                        directionUrl={'/ats/model/edit?id_ats=' + rowsModels.id_ats} />

                                                                    <ButtonIconControla
                                                                        roles={[1, 7, 8, 15]}
                                                                        icon={<Delete sx={{ color: 'white' }} />} backgroundColor={"#EB5757"}
                                                                        backgroundColorHover={"#FF4040"} textTooltip={'Eliminar'} functionOnClick={() => {
                                                                            handleOpenModalConfirm(
                                                                                rowsModels.id_ats,
                                                                                '¿Esta seguro de eliminar el modelo?',
                                                                                'Una vez eliminado no se podra recuperar todo lo incluido en este modelo ATS:  \"' + rowsModels.name + '\"',
                                                                                'Si, Eliminar',
                                                                                'delete')
                                                                        }} />
                                                                </Stack>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                            <TableBody sx={{ display: (documentType !== 1 ? 'none' : '') }}  >
                                                {rowsActives
                                                    .map((rowsActives) => (
                                                        <TableRow hover
                                                            key={rowsActives.id_ats}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >

                                                            <TableCell component="th" scope="row">
                                                                {rowsActives.name}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {(rowsActives.document_url != null && rowsActives.document_url != undefined ? 'Archivo' : 'Sistema')}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {rowsActives.status}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <Stack
                                                                    direction="row"
                                                                    justifyContent="end"
                                                                    alignItems="center"
                                                                >


                                                                    {(rowsActives.id_ats_statuses === 1 && rowsActives.document_url === null) ?
                                                                        <ButtonIconControla
                                                                            roles={[1, 7, 8, 9, 15]}
                                                                            icon={<BorderColorOutlined sx={{ color: 'white' }} />} backgroundColor={"#2D9CDB"}
                                                                            backgroundColorHover={"#33AEF4"} textTooltip={'Editar'}
                                                                            directionUrl={'/ats/edit?id_ats=' + rowsActives.id_ats} />
                                                                        :
                                                                        (rowsActives.id_ats_statuses === 1 && rowsActives.document_url !== null) ?
                                                                            <ButtonIconControla
                                                                                roles={[1, 7, 8, 9, 15]}
                                                                                icon={<BorderColorOutlined sx={{ color: 'white' }} />} backgroundColor={"#2D9CDB"}
                                                                                backgroundColorHover={"#33AEF4"} textTooltip={'Editar'}
                                                                                functionOnClick={() => { handleOpenModalEditUpload(rowsActives.id_ats, rowsActives.name, rowsActives.code) }}
                                                                            />
                                                                            :
                                                                            ''
                                                                    }


                                                                    {rowsActives.id_ats_statuses === 1 ?
                                                                        <ButtonIconControla
                                                                            roles={[1, 7, 8, 15]}
                                                                            icon={<Send sx={{ color: 'white' }} />} backgroundColor={"#169073"}
                                                                            backgroundColorHover={"#1AA483"} textTooltip={'Enviar a trabajadores'}
                                                                            functionOnClick={() => { handleOpenModalConfirm(rowsActives.id_ats, '¿Esta seguro de enviar a los trabajadores?', 'Una vez enviado no podra editar nuevamente el ATS:  \"' + rowsActives.name + '\"', 'Si, enviar', 'sendWorkers') }} />
                                                                        :
                                                                        rowsActives.id_ats_statuses === 2 ?
                                                                            <ButtonIconControla
                                                                                roles={[1, 7, 8, 15]}
                                                                                icon={<FileUploadOutlined sx={{ color: 'white' }} />} backgroundColor={"#169073"}
                                                                                backgroundColorHover={"#1AA483"} textTooltip={'Publicar'} functionOnClick={() => { handleOpenModalConfirm(rowsActives.id_ats, '¿Esta seguro de publicar?', 'Una vez publicado se cerrara el ATS:  \"' + rowsActives.name + '\"', 'Si, publicar', 'public') }} />
                                                                            :
                                                                            <></>
                                                                    }

                                                                    {(rowsActives.document_url !== null) ?
                                                                        <ButtonIconControla
                                                                            //lo pueden ver todes
                                                                            icon={<VisibilityOutlined sx={{ color: 'white' }} />} backgroundColor={"#305AD9"}
                                                                            backgroundColorHover={"#0E4AFF"} textTooltip={'Ver'}
                                                                            directionUrl={rowsActives.document_url} directionTarget={'_blank'} />
                                                                        :
                                                                        (rowsActives.id_ats_statuses !== 1) ?
                                                                            //lo pueden ver todos
                                                                            <ButtonIconControla
                                                                                icon={<VisibilityOutlined sx={{ color: 'white' }} />} backgroundColor={"#305AD9"}
                                                                                backgroundColorHover={"#0E4AFF"} textTooltip={'Ver'}
                                                                                directionUrl={'/ats/edit?id_ats=' + rowsActives.id_ats} />
                                                                            :
                                                                            ''
                                                                    }

                                                                    {(rowsActives.id_ats_statuses === 3) ?
                                                                        <ButtonIconControla
                                                                            roles={[1, 7, 8]}
                                                                            icon={<Archive sx={{ color: 'white' }} />} backgroundColor={"#F2994A"}
                                                                            backgroundColorHover={"#FF881E"} textTooltip={'Archivar'} functionOnClick={() => handleOpenModalConfirm(rowsActives.id_ats, '¿Esta seguro de archivar el ATS?', 'ATS a archivar:  \"' + rowsActives.name + '\"', 'Si, archivar', 'archived')} />
                                                                        :
                                                                        ''
                                                                    }

                                                                    {rowsActives.id_ats_statuses === 1 ?
                                                                        <ButtonIconControla
                                                                            roles={[1, 7, 8, 15]}
                                                                            icon={<Delete sx={{ color: 'white' }} />} backgroundColor={"#EB5757"}
                                                                            backgroundColorHover={"#FF4040"} textTooltip={'Eliminar'}
                                                                            functionOnClick={() => handleOpenModalConfirm(rowsActives.id_ats, '¿Esta seguro de eliminar el ATS?', 'Una vez eliminado no se podra recuperar todo lo incluido en el ATS:  \"' + rowsActives.name + '\"', 'Si, eliminar', 'delete')} />
                                                                        :
                                                                        ''
                                                                    }

                                                                </Stack>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                            <TableBody sx={{ display: (documentType !== 2 ? 'none' : '') }}  >
                                                {rowsArchives
                                                    .map((rowsArchives) => (
                                                        <TableRow hover
                                                            key={rowsArchives.id_ats}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >

                                                            <TableCell component="th" scope="row">
                                                                {rowsArchives.name}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {(rowsArchives.document_url != null && rowsArchives.document_url != undefined ? 'Archivo' : 'Sistema')}
                                                            </TableCell>
                                                            <TableCell component="th" scope="row">
                                                                {rowsArchives.status}
                                                            </TableCell>
                                                            <TableCell align="right">
                                                                <Stack
                                                                    direction="row"
                                                                    justifyContent="end"
                                                                    alignItems="center"
                                                                >
                                                                    <ButtonIconControla
                                                                        icon={<VisibilityOutlined sx={{ color: 'white' }} />} backgroundColor={"#305AD9"}
                                                                        backgroundColorHover={"#0E4AFF"} textTooltip={'Ver'}
                                                                        directionUrl={'/ats/edit?id_ats=' + rowsArchives.id_ats} />
                                                                    <ButtonIconControla
                                                                        roles={[1, 7, 8]}
                                                                        icon={<Unarchive sx={{ color: 'white' }} />} backgroundColor={"#F2994A"}
                                                                        backgroundColorHover={"#FF881E"} textTooltip={'Desarchivar'} functionOnClick={() => handleOpenModalConfirm(rowsArchives.id_ats, '¿Esta seguro de desarchivar el ATS?', 'ATS a desarchivar:  \"' + rowsArchives.name + '\"', 'Si, desarchivar', 'unarchived')} />

                                                                </Stack>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                            </TableBody>

                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>
                        </Grid>

                        <Dialog
                            open={openModalCreate}
                            onClose={handleCloseModalCreate}
                            maxWidth="sm"
                            fullWidth={true}
                            sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                        >
                            <DialogTitleControla titleText='Crear nuevo modelo ATS' functionOnClose={handleCloseModalCreate} />
                            <form onSubmit={handlePostCreateModelAts}>
                                <DialogContent >
                                    <InputControlaVertical text={'Código:'} inputType={'text'} inputValue={setCode} modalType={true} required />
                                    <InputControlaVertical text={'Nombre del modelo ATS:'} inputType={'text'} inputValue={setName} modalType={true} required />
                                </DialogContent>
                                <DialogActions>
                                    <Stack direction={'row'} spacing={2}>
                                        <Box>
                                            <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cancelar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={handleCloseModalCreate} />
                                        </Box>
                                        <Box>
                                            <ButtonControla iconButton={<Add />} backgroundColor={'#169073'} textButton={'Crear modelo de ATS'} backgroundColorHover={'#1BAA88'} typeButton={'submit'} />
                                        </Box>
                                    </Stack>
                                </DialogActions>
                            </form>
                        </Dialog>

                        <Dialog
                            open={openModalUpload}
                            onClose={handleCloseModalUpload}
                            maxWidth="sm"
                            fullWidth={true}
                            sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                        >
                            <DialogTitleControla titleText='Cargar documento ATS' functionOnClose={handleCloseModalUpload} />
                            <form onSubmit={handlePostCreateAts}>
                                <DialogContent >
                                    <InputControlaVertical text={'Código:'} inputType={'text'} inputValue={setCode} modalType={true} required />
                                    <InputControlaVertical text={'Nombre del ATS:'} inputType={'text'} inputValue={setName} modalType={true} required />
                                    <InputControlaVertical text={'Subir Archivo'} inputType={'file'} inputValue={setDocumentUrl} modalType={true} required />

                                </DialogContent>
                                <DialogActions>
                                    <Stack direction={'row'} spacing={2}>
                                        <Box>
                                            <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cancelar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={handleCloseModalUpload} />
                                        </Box>
                                        <Box>
                                            <ButtonControla iconButton={<Add />} backgroundColor={'#169073'} textButton={'Cargar ATS'} backgroundColorHover={'#1BAA88'} typeButton={'submit'} />
                                        </Box>
                                    </Stack>
                                </DialogActions>
                            </form>
                        </Dialog>

                        <Dialog
                            open={openModalEditUpload}
                            onClose={handleCloseModalEditUpload}
                            maxWidth="sm"
                            fullWidth={true}
                            sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                        >
                            <DialogTitleControla titleText='Actuaizar documentol ATS' functionOnClose={handleCloseModalEditUpload} />
                            <form onSubmit={handleUpdateAts}>
                                <DialogContent >
                                    <InputControlaVertical text={'Código:'} inputType={'text'} value={code} inputValue={setCode} modalType={true} required />
                                    <InputControlaVertical text={'Nombre del ATS:'} inputType={'text'} value={name} inputValue={setName} modalType={true} required />
                                    <InputControlaVertical text={'Subir Archivo'} inputType={'file'} inputValue={setDocumentUrl} modalType={true} />

                                </DialogContent>
                                <DialogActions>
                                    <Stack direction={'row'} spacing={2}>
                                        <Box>
                                            <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cancelar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={handleCloseModalEditUpload} />
                                        </Box>
                                        <Box>
                                            <ButtonControla iconButton={<Add />} backgroundColor={'#169073'} textButton={'Actualizar ATS'} backgroundColorHover={'#1BAA88'} typeButton={'submit'} />
                                        </Box>
                                    </Stack>
                                </DialogActions>
                            </form>
                        </Dialog>

                    </Box>
                </Container>
            </Box >
        </>
    )
}

export default Index