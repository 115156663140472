import { React, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { styled, alpha } from '@mui/material/styles';
import { Grid, Typography, Card, CardContent, CardMedia, InputBase, Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import { Close, Search } from '@mui/icons-material';

const SearchTextField = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.black, 0.05),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.black, 0.15),
    },
    // marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        // marginRight: '1rem',
        width: 'auto'
        // display: 'block'
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    width: '100%',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '100%',
        },
    },
}));

function SearchEppsControla({ openModalEpps = true, handleCloseModalEpps, handleClickSelectEpps, rowsEpps = [] }) {

    const [rowsEppsSearch, setRowsEppsSearch] = useState(rowsEpps);

    const handleSearchEpp = (searchEpp) => {
        setRowsEppsSearch(rowsEpps);
        if (searchEpp !== '') {
            let expresion = new RegExp(`${searchEpp}.*`, "i");
            let eppsSearch = rowsEpps.filter(x => expresion.test(x.epp));
            setRowsEppsSearch(eppsSearch);
        }
    };

    useEffect(() => {
        setRowsEppsSearch(rowsEpps);
    }, []);


    return (
        <Dialog
            open={openModalEpps}
            onClose={handleCloseModalEpps}
            maxWidth="lg"
            fullWidth={true}
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
        >
            <DialogTitle sx={{ paddingBottom: '0' }}>
                <Typography
                    sx={{
                        fontSize: '24px',
                        color: "#1638F2",
                        fontWeight: "bold",
                    }}
                >
                    Buscar Epps
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseModalEpps}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ height: 400 }}>
                <Grid container spacing={2} sx={{ width: '100%', margin: 'auto' }} >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container spacing={2} sx={{ width: '100%' }} >
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                {/* <Stack direction='row' justifyContent="space-between" alignItems="center"> */}
                                <SearchTextField onChange={({ target }) => handleSearchEpp(target.value)}
                                >
                                    <SearchIconWrapper>
                                        <Search />
                                    </SearchIconWrapper>
                                    <StyledInputBase
                                        placeholder="Buscar ..."
                                        inputProps={{ 'aria-label': 'search' }}
                                    />
                                </SearchTextField>
                            </Grid>
                        </Grid>
                    </Grid>
                    {(rowsEppsSearch.length == 0 ? rowsEpps : rowsEppsSearch)
                        .map((rowsEpps) => (

                            <Grid item key={rowsEpps.id_epps} xs={12} sm={6} md={4} lg={3} xl={3} sx={{ p: 2 }}>
                                <Card sx={{
                                    display: 'flex', justifyContent: 'space-between', height: 120,
                                    "&:hover": {
                                        cursor: 'pointer',
                                        border: "1px solid #0F1255",
                                        boxShadow: '0px 4px #0F1255',
                                    },
                                }} onClick={() => handleClickSelectEpps(rowsEpps.id_epps)}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <CardContent sx={{ flex: '1 0 auto' }}>
                                            <Typography sx={{ fontSize: '14px' }}>
                                                {rowsEpps.epp}
                                            </Typography>
                                        </CardContent>
                                    </Box>
                                    <CardMedia
                                        component="img"
                                        sx={{ maxWidth: 120, marginLeft: 0, maxHeight: 120, objectFit: 'contain' }}
                                        image={rowsEpps.photo_url}
                                        alt="Live from space album cover"
                                    />
                                </Card>

                            </Grid>
                        ))
                    }
                </Grid>
            </DialogContent >
        </Dialog>
    );

}

export default SearchEppsControla;

