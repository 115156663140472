import React, { Component, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


export const EditorAnnualPlanControla = ({ dataEdit, handleDataEditor = alert }) => {
  
  const template = `
  <h2>Plan Anual de SSO</h2>
  <h3> I.Introducción</h3>
  <p>NOMBRE_EMPRESA es una empresa dedicada a la _________________ y en cumplimiento del artículo 38° de la Ley N° 29783, Ley de Seguridad y Salud en el Trabajo, su reglamento y normas sectoriales elabora el presente Plan Anual de Seguridad y Salud Ocupacional del año _____, en base a los resultados obtenidos de la evaluación inicial o estudio de línea base del Sistema de Gestión de Seguridad y Salud Ocupacional y su mejora continua.
  Considerando que en NOMBRE_EMPRESA el trabajador es el elemento más importante dentro de la organización, los objetivos del presente plan están orientados a alcanzar la protección de la vida y la salud de todos los trabajadores de la organización, así como el compromiso de la misma de generar y mantener ambientes de trabajo seguros y saludables de manera permanente.
  </p>
  <h3> II.Alcance</h3>
  <p>El presente Plan Anual de Seguridad y Salud Ocupacional es de aplicación para todos los trabajadores de NOMBRE_EMPRESA, así como a todos sus contratistas y visitantes.
  3. Requisitos legales y otros
  
  A fin de elaborar el presente plan, la empresa ha realizado la identificación del grado de cumplimiento de la normativa legal general y sectorial que tiene que cumplir así como otros requisitos que la organización elige cumplir, los cuales deben ser actualizados y difundidos oportunamente para su cumplimiento.
  
  Cabe señalar que la empresa debe mantener y conservar información documentada sobre los requisitos legales y otros requisitos en materia de seguridad y salud ocupacional, como parte de la planificación de este sistema y el compromiso del empleador.</p>   
  `;
  return (
    <div className="App">
      <CKEditor
        editor={ClassicEditor}
        data={dataEdit === null ? template : dataEdit}
        onReady={(editor) => {
          const data = editor.getData();
          handleDataEditor(data);
          // You can store the "editor" and use when it is needed.
          // console.log( 'Editor is ready to use!', editor );
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          handleDataEditor(data);
          // console.log({ data });
        }}
        // onChange={(event, editor) => dataEditor(editor.getData())}

        onBlur={(event, editor) => {
          const data = editor.getData();
          handleDataEditor(data);
        }}
        onFocus={(event, editor) => {
          const data = editor.getData();
          handleDataEditor(data);
        }}
      />
    </div>
  );
};
