import { React, useEffect, useState } from "react";
import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import SideNav from "../../../../components/sidenav/sideNav";
import Box from "@mui/material/Box";
import {
  Alert,
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Icon,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Add,
  ArrowBack,
  Close,
  Delete,
  EditOutlined,
  HistoryEduOutlined,
  PictureAsPdf,
  Print,
  VisibilityOutlined,
} from "@mui/icons-material";
import TitleText from "../../../../components/text/titleText";
import IconButton from "@mui/material/IconButton";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import ButtonControla from "../../../../components/buttons/buttonController";
import CompanyLogoControla from "../../../../components/images/companyLogoControla";
import MyRegistersAccidents from "../../../../api/MyRegistersAccidents";
import SearchUsersControla from "../../../../components/search/searchUsersControla";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import CloseIcon from "@mui/icons-material/Close";
import SearchButtonControla from "../../../../components/search/searchButtonControla";
import MyUsers from "../../../../api/MyUsers";
import SelectControla from "../../../../components/selects/selectControla";
import MyConfigurationApi from "../../../../api/MyConfigurationApi";
import HistoryEduOutlinedIcon from "@mui/icons-material/HistoryEduOutlined";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";
import CardUserControla from "../../../../components/cards/cardUserControla";
import styled from "styled-components";
import LoadingControla from "../../../../components/load/loadingControla";
import MyInductionTranning from "../../../../api/MyRegisterInductionTranning";
import MyAreas from "../../../../api/MyAreas";

const colorTitle = "#9191B0";
const blue = "#034AFF";
const red = "#EB5757";
const StyledTypography = styled(Typography)({
  color: "#305AD9",
  fontSize: 16,
  fontWeight: 600,
  gap: "2%",
  padding: "0.5em",
});

function Edit() {
  const params = new URLSearchParams(window.location.search);
  const idRegistersInductionTranning = params.get(
    "idRegistersInductionTranning"
  );

  // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  //fin de alerts
  // Dialogos de confirmacion INICIO
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setIdWorkerInvolved(id);
    setIdResponsibleWorker(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    // eslint-disable-next-line default-case
    switch (buttonActionType) {
      case "deleteWorkers":
        handleDeleteWorkerInvolved();
        break;
      case "deleteResponsible":
        handleDeleteResponsibleWorker();
        break;
    }
    setOpenModalConfirm(false);
  };
  // Dialogos de confirmacion FIN

  // REGISTRO DE ENTRENAMIENTO
  const [code, setCode] = useState("");
  const [TypeTranning, setTypeTranning] = useState("");
  const [ruc, setRuc] = useState("");
  const [date, setDate] = useState("");
  const [numberHour, setNumberHour] = useState("");
  const [nameTrainer, setNameTrainerr] = useState("");

  // select typo de equipo
  const [rowsTypeTranning, setRowsTypeTranning] = useState([]);
  const handleTypeTranning = async () => {
    const response = await MyInductionTranning.getTypeTranning();
    if (response.success === false) {
    } else {
      setRowsTypeTranning(response);
    }
    return response;
  };

  // update register
  const handleUpdateRegistersInductionTranning = async () => {
    handleOpenLoading();
    const response = await MyInductionTranning.updateRegistersInductionTranning(
      {
        idRegistersInductionTranning,
        code,
        id_registers_induction_tranning_type: TypeTranning,
        ruc: ruc,
        date: date,
        number_hour: numberHour,
        name_trainer: nameTrainer,
        register_status:1
      }
    );
    if (response.success === false) {
      handleOpenAlert("Ocurrió un error al actualizar los datos", "error");
    } else {
      handleOpenAlert("Se actualizaron los datos", "success");
    }
    return response;
  };

  // edit register
  const handleEditRegistersInductionTranning = async () => {
    const response = await MyInductionTranning.editRegistersInductionTranning({
      idRegistersInductionTranning,
    });
    if (response.success === false) {
    } else {
      setRegisterStatus(response.register_status === 1 ? true : false);
      setCode(response.code);
      setTypeTranning(response.id_registers_induction_tranning_type);
      setRuc(response.ruc);
      setDate(response.date);
      setNumberHour(response.number_hour);
      setNameTrainerr(response.name_trainer);
    }
    return response;
  };

  // USUARIOS ACTIVOS
  const [rowsUsersSearchs, setRowsUsersSearchs] = useState([]);
  const handleGetUsersSearchsAddsWorkers = async () => {
    const response = await MyUsers.users();
    setRowsUsersSearchs(response.users_actives);
  };

  // buscar usuario por ID y setear sus datos
  const [nameResponsibleInput, setResponsibleInput] = useState(null);
  const [responsibleDocumentInput, setResponsibleDocumentInput] =
    useState(null);
  const handleSelectUsersResponsible = async (idUsers) => {
    setIdResponsible(idUsers);
    const response = await MyUsers.editUsers({ idUsers: idUsers });
    if (response.success !== false) {
      setResponsibleInput(response.first_name + " " + response.last_name);
      setResponsibleDocumentInput(response.document);
      // setOpenModalUpload(false);
      setOpenModalInvolvedWorkersResponsible(false);
    } else {
    }
  };

  // identificar usuario logeado
  const [idUserLogged, setIdUserLogged] = useState();
  const handleUserActive = async () => {
    const responsable = await MyConfigurationApi.userData();
    setIdUserLogged(responsable.id_users);
  };

  //*************************  TRABAJADOR INVOLUCRADO *******************************************
  const [workersInvolved, setWorkersInvolved] = useState([]);

  const [areaWorkerInvolved, setAreaWorkerInvolved] = useState("");
  const [rowsAreaWorkerInvolved, setRowsAreaWorkerInvolved] = useState([]);

  const [observation, setObservation] = useState("");
  const [idResponsible, setIdResponsible] = useState(" ");

  //mostrar las áreas desde configuración
  const handleGetAreas = async () => {
    const response = await MyAreas.getAreas();
    setRowsAreaWorkerInvolved(response);
    return response;
  };

  // agregar datos del trabajador DIALOG
  const [openModalWorkerInvolved, setOpenModalWorkerInvolved] = useState(false);
  const handleOpenModalWorkerInvolved = () => {
    setAreaWorkerInvolved(null);
    setResponsibleInput("");
    setObservation("");
    handleGetUsersSearchsAddsWorkers();
    setOpenModalWorkerInvolved(true);
  };

  // dialog para buscar el responsable de trabajador involucrado
  const [
    openModalInvolvedWorkersResponsible,
    setOpenModalInvolvedWorkersResponsible,
  ] = useState(false);
  const handleOpenModalResponsibleInvolvedWorkers = () => {
    setResponsibleInput("");
    setOpenModalInvolvedWorkersResponsible(true);
  };

  // crear trabajador involucrado
  const handleCreateWorkersInvolved = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyInductionTranning.createWorkersInvolved({
      idRegistersInductionTranning,
      area: areaWorkerInvolved,
      observation: observation,
      id_users: idResponsible,
      nameResponsibleInput: nameResponsibleInput,
      responsibleDocumentInput: responsibleDocumentInput,
    });

    if (response.success === false) {
      handleOpenAlert(response.msg, "error");
    } else {
      // handleGetWorkersInvolved();
      setOpenModalWorkerInvolved(false);
      handleGetWorkersInvolved();
      handleOpenAlert("Creado de forma correcta", "success");
    }
    return response;
  };

  // // mostrar trabajadores involucrado
  const handleGetWorkersInvolved = async () => {
    const response = await MyInductionTranning.getWorkersInvolved({
      idRegistersInductionTranning: idRegistersInductionTranning,
    });
    if (response.success === false) {
    } else {
      setWorkersInvolved(response);
    }
  };

  // firmar trabajador involucrado
  const [openModalSignWorkerInvolved, setOpenModalSignWorkerInvolved] =
    useState(false);
  const [idWorkerInvolvedSign, setIdWorkerInvolvedeSign] = useState(0);
  const [idWorkerInvolvedSignUser, setIdWorkerInvolvedeSignUser] = useState(0);

  const handleOpenModalSignWorkerInvolved = (idResponsible, id_users) => {
    setIdWorkerInvolvedeSign(idResponsible);
    setIdWorkerInvolvedeSignUser(id_users);
    setOpenModalSignWorkerInvolved(true);
  };

  const handleSignWorkerInvolved = async (event) => {
    event.preventDefault();
    const response = await MyInductionTranning.updateSignWorkerInvolved({
      idResponsible: idWorkerInvolvedSign,
      id_users: idWorkerInvolvedSignUser,
    });
    setOpenModalSignWorkerInvolved(false);
    handleGetWorkersInvolved();
  };

  // // eliminar trabajadores involucrado
  const [idWorkerInvolved, setIdWorkerInvolved] = useState();

  const handleDeleteWorkerInvolved = async () => {
    handleOpenLoading();
    const response = await MyInductionTranning.deleteWorkerInvolved({
      idWorkerInvolved: idWorkerInvolved,
    });
    if (response.success === true) {
      handleGetWorkersInvolved();
      handleOpenAlert("Eliminado de forma correcta", "success");
    } else {
      handleOpenAlert(response.msg, "error");
    }
    return response;
  };

  //*************************  RESPONSABLES  *******************************************
  const [openModalResponsibleWorker, setOpenModalResponsibleWorker] =
    useState(false);

  const handleOpenModalResponsibleWorker = () => {
    setOpenModalResponsibleWorker(true);
  };

  //listas trabajadores responsables
  const [responsibleWorkers, setResponsibleActions] = useState([]);
  const handleGetResponsibleWorkers = async () => {
    const response = await MyInductionTranning.getResponsibleWorkers({
      idRegistersInductionTranning: idRegistersInductionTranning,
    });
    if (response.success === false) {
    } else {
      setResponsibleActions(response);
    }
  };

  //crear trabajadores responsables
  const handleStoreResponsibleWorkers = async (idUsers) => {
    handleOpenLoading();

    const response = await MyInductionTranning.createResponsibleWorkers({
      idRegistersInductionTranning: idRegistersInductionTranning,
      id_users: idUsers,
    });

    if (response.success === false) {
    } else {
      handleGetResponsibleWorkers();
      setOpenModalResponsibleWorker(false);
      handleOpenAlert("Se agregó de forma correcta los datos", "success");
    }
    return response;
  };

  // eliminar trabajador responsable
  const [idResponsibleWorker, setIdResponsibleWorker] = useState(0);
  const handleDeleteResponsibleWorker = async () => {
    const response = await MyInductionTranning.deleteResponsibleWorker({
      idResponsible: idResponsibleWorker,
    });
    handleGetResponsibleWorkers();
    return response;
  };

  // firmar responsable
  const [openModalSignResponsibleWorker, setOpenModalSignResponsibleWorker] =
    useState(false);
  const [idResponsibleSign, setIdResponsibleSign] = useState(0);
  const [idResponsibleSignUsers, setIdResponsibleSignUsers] = useState(0);
  const handleOpenModalSignResponsibleWorker = (idResponsible, id_users) => {
    setIdResponsibleSign(idResponsible);
    setIdResponsibleSignUsers(id_users);
    setOpenModalSignResponsibleWorker(true);
  };

  const handleSignResponsibleWorker = async (event) => {
    event.preventDefault();
    handleOpenLoading();

    const response = await MyInductionTranning.updateResponsibleWorkers({
      idResponsible: idResponsibleSign,
      id_users: idResponsibleSignUsers,
    });
    setOpenModalSignResponsibleWorker(false);
    handleGetResponsibleWorkers();
    handleOpenAlert("Se registró la firma con éxito", "success");
  };

  // CERRAR ESTADO DEL REGISTRO
  const [registerStatus, setRegisterStatus] = useState(1);
  const handleGetRegisterStatus = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    if (
      responsibleWorkers.length >= 1 &&
      workersInvolved.length >= 1
    ) {
      const finded = responsibleWorkers.find((item) => item.firm === 0);
      const findedWorkers = workersInvolved.find((item) => item.firm === 0);

      if (finded === undefined && findedWorkers === undefined) {
        handleOpenLoading();
        const response = await MyInductionTranning.updateRegistersInductionTranning(
          {
            idRegistersInductionTranning,
            code,
            id_registers_induction_tranning_type: TypeTranning,
            ruc: ruc,
            date: date,
            number_hour: numberHour,
            name_trainer: nameTrainer,
            register_status:2
          }
        );
        handleOpenAlert("El registro se cerró de forma correcta", "success");
        handleEditRegistersInductionTranning();
        return response;
      } else {
        handleOpenAlert("Firmas pendientes", "error");
      }
    } else {
      handleOpenAlert(
        "Complete las secciones obligatorias(Trabajador involucrado y trabajador responsable)",
        "error"
      );
    }
  };

  useEffect(() => {
    handleTypeTranning();
    handleEditRegistersInductionTranning();
    handleGetWorkersInvolved();

    // RESPONSABLES
    handleGetResponsibleWorkers();

    handleGetUsersSearchsAddsWorkers();
    handleUserActive();
    handleGetAreas();
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="/documentation/registers/generals/inductiontrainingcoaching" />
      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
              <TitleText
                text={
                  "Registro de inducción, capacitación, entrenamiento y simulacros de emergencia"
                }
              ></TitleText>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <form onSubmit={handleGetRegisterStatus}>
            {/* tabla para mostrar accidents workers */}
            <Grid container spacing={2} sx={{ marginTop: "4px" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Nro registro"}
                  inputType={"text"}
                  inputValue={setCode}
                  value={code}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SelectControla
                  text="Seleccione:"
                  inputValue={setTypeTranning}
                  modalType={false}
                  required
                  value={TypeTranning}
                  childrenRows={rowsTypeTranning.map((row) => (
                    <MenuItem
                      key={row.id}
                      value={row.id_registers_induction_tranning_type}
                    >
                      {row.registers_induction_tranning_type}
                    </MenuItem>
                  ))}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <InputControlaVertical
                  text={"Tema:"}
                  inputType={"text"}
                  inputValue={setRuc}
                  value={ruc}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <InputControlaVertical
                  text={"Fecha:"}
                  inputType={"date"}
                  inputValue={setDate}
                  value={date}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <InputControlaVertical
                  text={"N° de horas:"}
                  inputType={"number"}
                  inputValue={setNumberHour}
                  value={numberHour}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Nombre del capacitador o entrenador:"}
                  inputType={"text"}
                  inputValue={setNameTrainerr}
                  value={nameTrainer}
                />
              </Grid>

              <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Datos de los trabajadores
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Grid>
              <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                {registerStatus === true ? (
                  <ButtonControla
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar datos"}
                    functionOnClick={handleOpenModalWorkerInvolved}
                  />
                ) : (
                  ""
                )}
              </Grid>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nombre y Apellido
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Documento de Identidad
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Área
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Firma
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha de Firma
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Observación
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody
                  // sx={{ display: documentType !== 1 ? "none" : "" }}
                  >
                    {workersInvolved.map((row) => (
                      <TableRow
                        hover
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.name_user_responsible}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.document_user_responsible}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.area}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.firm === 0 ? (
                            "No firmado" && row.id_users === idUserLogged ? (
                              <ButtonIconControla
                                icon={
                                  <HistoryEduOutlined sx={{ color: "white" }} />
                                }
                                backgroundColor={"#F1C40F"}
                                backgroundColorHover={"#F4D03F"}
                                textTooltip={"Firmar"}
                                functionOnClick={() =>
                                  handleOpenModalSignWorkerInvolved(
                                    row.id,
                                    row.id_users
                                  )
                                }
                              />
                            ) : (
                              "No firmado"
                            )
                          ) : (
                            "Firmado"
                          )}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.firm_date === null || row.firm_date === undefined
                            ? ""
                            : row.firm_date.substring(0, 19)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.observation}
                        </TableCell>

                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                            spacing={1}
                          >
                            {registerStatus === true ? (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id,
                                    "¿Esta seguro de eliminar los datos del trabajador?",
                                    "Una vez eliminado no se podra recuperar los datos del trabajador " +
                                      row.name_user_responsible,
                                    "Si, Eliminar",
                                    "deleteWorkers"
                                  );
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* responsables del registro y de la investigación */}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Responsables del registro
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Box>
              <Box>
                {registerStatus === true ? (
                  <ButtonControla
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar responsables"}
                    functionOnClick={handleOpenModalResponsibleWorker}
                  />
                ) : (
                  ""
                )}
              </Box>
            </Stack>

            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nombre y Apellido
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Cargo
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha de firma
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Firma
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {responsibleWorkers.map((row) => (
                      <TableRow
                        hover
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.first_name + " " + row.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.position_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.firm_date === null
                            ? ""
                            : row.firm_date.substring(0, 19)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.firm === 0 ? (
                            "No firmado" && row.id_users === idUserLogged ? (
                              <ButtonIconControla
                                icon={
                                  <HistoryEduOutlined sx={{ color: "white" }} />
                                }
                                backgroundColor={"#F1C40F"}
                                backgroundColorHover={"#F4D03F"}
                                textTooltip={"Firmar"}
                                functionOnClick={() =>
                                  handleOpenModalSignResponsibleWorker(
                                    row.id,
                                    row.id_users
                                  )
                                }
                              />
                            ) : (
                              "No firmado"
                            )
                          ) : (
                            "Firmado"
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            {registerStatus === true ? (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id,
                                    "¿Esta seguro de eliminar los datos del trabajador responsable?",
                                    "Una vez eliminado no se podra recuperar los datos del trabajador responsable " +
                                      row.first_name +
                                      " " +
                                      row.last_name,
                                    "Si, Eliminar",
                                    "deleteResponsible"
                                  );
                                }}
                              />
                            ) : (
                              ""
                            )}
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* Botones de impresión, guardar y cancelar */}
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                whidth: "100%",
                justifyContent: "space-evenly",
                paddingY: 4,
              }}
            >
              {/* cerrado temporalmente */}
              {/* <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<Print sx={{ color: "white" }} />}
                  backgroundColor={"#2D9CDB"}
                  backgroundColorHover={"#33AEF4"}
                  textButton={"Imprimir"}
                />
              </Grid> */}
              <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<ArrowBack sx={{ color: "white" }} />}
                  backgroundColor={"#EB5757"}
                  backgroundColorHover={"#FF4040"}
                  textButton={"Regresar"}
                  functionOnClick={() =>
                    (window.location.href =
                      "/documentation/registers/generals/inductiontrainingcoaching")
                  }
                />
              </Grid>
              {registerStatus === true ? (
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    iconButton={<EditOutlined sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Actualizar registro"}
                    // typeButton="submit"
                    functionOnClick={handleUpdateRegistersInductionTranning}
                  />
                </Grid>
              ) : (
                ""
              )}
              {registerStatus === true ? (
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    iconButton={<CloseIcon fontSize="inherit" />}
                    backgroundColor={"black"}
                    backgroundColorHover={"#2C2B29"}
                    textButton={"Cerrar registro"}
                    typeButton="submit"

                    // functionOnClick={handleGetRegisterStatus}
                  />
                </Grid>
              ) : (
                ""
              )}
                {registerStatus === false ? (
              <Grid item xs={12} md={3}>
                <ButtonControla
                  //todos
                  // roles={[1, 5, 6, 11]}
                  iconButton={<PictureAsPdf sx={{ color: "white" }} />}
                  backgroundColor={"#F2994A"}
                  backgroundColorHover={"#FF881E"}
                  textButton={"Ver documento PDF"}
                  typeButton={"button"}
                  url={'http://34.134.148.254:8081/report/'+idRegistersInductionTranning}
                  target="_blank"
                  functionOnClick={() => console.log(idRegistersInductionTranning)}
                />              
              </Grid>
                 ) : (
                  ""
                )}
            </Grid>
          </form>
        </Box>
      </Container>

      {/* Dialog agregar trabajador involucrado */}
      <Dialog
        open={openModalWorkerInvolved}
        onClose={() => setOpenModalWorkerInvolved(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar trabajador involucrado
          </Typography>
        </DialogTitle>
        <form onSubmit={handleCreateWorkersInvolved}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {/* <InputControlaVertical
                  text={"Área"}
                  inputType={"text"}
                  inputValue={setAreaWorkerInvolved}
                  value={areaWorkerInvolved}
                  modalType={true}
                  required
                /> */}
                <SelectControla
                  text="Área:"
                  inputValue={setAreaWorkerInvolved}
                  modalType={true}
                  required
                  value={areaWorkerInvolved}
                  childrenRows={rowsAreaWorkerInvolved.map((rows) => (
                    <MenuItem key={rows.id} value={rows.area}>
                      {rows.area}
                    </MenuItem>
                  ))}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Observación: "}
                  inputType={"textArea"}
                  inputValue={setObservation}
                  value={observation}
                  modalType={true}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <SearchButtonControla
                  titleLabel="Trabajador involucrado: "
                  inputValue={nameResponsibleInput}
                  functionClick={handleOpenModalResponsibleInvolvedWorkers}
                  modalType={true}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => setOpenModalWorkerInvolved(false)}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Agregar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialogo de buscar trabajadores responsables DEL TRABAJADOR INVOLUCRADO */}
      <Dialog
        open={openModalInvolvedWorkersResponsible}
        onClose={() => {
          setOpenModalInvolvedWorkersResponsible(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Seleccionar Responsable
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ paddingTop: "0" }}>
          <SearchUsersControla
            rowsUser={rowsUsersSearchs}
            handleUsersActionButton={handleSelectUsersResponsible}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenModalInvolvedWorkersResponsible(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo para firmar trabajador involucrado */}
      <Dialog
        open={openModalSignWorkerInvolved}
        onClose={() => {
          setOpenModalSignWorkerInvolved(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            Sus datos quedarán registrados de forma permanente ¿Desea firmar?
          </Typography>
        </DialogTitle>
        <form onSubmit={handleSignWorkerInvolved}>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => {
                setOpenModalSignWorkerInvolved(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Firmar
            </Button>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog de agregar repsonsables  del registro  y de la invesatigación */}
      <Dialog
        open={openModalResponsibleWorker}
        onClose={() => setOpenModalResponsibleWorker(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsables del Registro
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={rowsUsersSearchs}
              handleUsersActionButton={handleStoreResponsibleWorkers}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => setOpenModalResponsibleWorker(false)}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo para firmar responsable */}
      <Dialog
        open={openModalSignResponsibleWorker}
        onClose={() => {
          setOpenModalSignResponsibleWorker(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            Sus datos quedarán registrados de forma permanente ¿Desea firmar?
          </Typography>
        </DialogTitle>
        <form onSubmit={handleSignResponsibleWorker}>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => {
                setOpenModalSignResponsibleWorker(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Firmar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}

export default Edit;
