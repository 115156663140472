import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Grid, Typography, TextField, } from '@mui/material';
import { Info } from '@mui/icons-material';
import { styled } from '@mui/system';
import SideNav from '../components/sidenav/sideNav';
import SubtitleText from '../components/text/subtitleText';
import TitleText from '../components/text/titleText';

const colorTitle = "#9191B0";

const StyledTypography = styled(Typography)({
    color: "#305AD9",
    fontSize: 16,
    fontWeight: 600,
    gap: "2%",
    padding: "0.5em"
});

const StyledTextField = styled(TextField)({
    //label
    "& label.Mui-focused": {
        color: "#305AD9",
    },
    "& .MuiInput-underline:after": {
        borderBottomColor: "#305AD9",
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: "white",
            background: "white",
            borderRadius: " 16px"
        },
        "&:hover fieldset": {
            borderColor: "white",
            background: "white"
        },
        "&.Mui-focused fieldset": {
            borderColor: "white",
            background: "white"
        },
        "& .MuiFilledInput-root": {
            background: "white",
        },
    },
    width: "100%",
});

function ScheduleCalendarTwo() {
    return (

        <Box sx={{ display: "flex", height: '100%' }}>
            <SideNav />

            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container justifyContent="flex-end">
                        <Grid item xs={12} md={8}>
                            <SubtitleText text={"Cronogramas"} color={colorTitle} ></SubtitleText>
                            <TitleText text={"Calendario de programación"}></TitleText>
                            {/* <DocBanner color2='#2F67BC' color1='#8FBCFF' text='Accidentes de trabajo' image={imageBanner} /> */}
                        </Grid>
                        <Grid item xs={12} md={4} justifyContent="flex-end">
                            <img src={"/assets/images/logoempresaejemplo.png"} style={{ width: "150px", textAlign: "left" }} alt='logo' />
                        </Grid>
                    </Grid>
                </Box>
                <Box sx={{ flexGrow: 1, mt: 3 }}>

                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item md={3}>
                            <StyledTypography>Número de representantes titulares y suplentes a ser elegidos/as</StyledTypography>
                        </Grid>
                        <Grid md={1}>
                            <Info />
                        </Grid>
                        <Grid container item md={8} gap={2}>
                            <Grid item md={2}>
                                <StyledTypography>Suplentes</StyledTypography>
                            </Grid>
                            <Grid item md={3}>
                                <StyledTextField />
                            </Grid>
                            <Grid item md={3}>
                                <StyledTypography>Plazo del Mandato</StyledTypography>
                            </Grid>
                            <Grid item md={1}>
                                <Info />
                            </Grid>
                            <Grid item md={2}>
                                <StyledTypography>Titulares</StyledTypography>
                            </Grid>
                            <Grid item md={3}>
                                <StyledTextField />
                            </Grid>
                            <Grid item md={3}>
                                <StyledTextField />
                            </Grid>
                        </Grid>
                        <Grid item md={4}>
                            <StyledTypography>
                                Requisitos para postular y ser elegidos/as como representantes de los/las trabajadores/as ante el Comité de Seguridad y Salud en el Trabajo
                            </StyledTypography>
                        </Grid>
                        <Grid item md={8}>
                            <StyledTextField/>
                        </Grid>
                        <Grid item md={4}>
                            <StyledTypography>
                               Modalidad en la que se llevará a cabo el proceso de elecciones
                            </StyledTypography>
                        </Grid>
                        <Grid item md={8}>
                            <StyledTextField/>

                        </Grid>
                        <Grid item md={4}>
                            <StyledTypography>
                                Periodo de inscripción de candidatos/as
                            </StyledTypography>
                        </Grid>
                        <Grid item md={8}>
                            <StyledTextField/>

                        </Grid>
                        <Grid item md={4}>
                            <StyledTypography>
                                Publicación del listado de candidatos/as inscritos/as
                            </StyledTypography>
                        </Grid>
                        <Grid item md={8}>
                            <StyledTextField/>
                        </Grid>
                    </Grid>
                </Box>

            </Container>

        </Box>

    );

}

export default ScheduleCalendarTwo;

