import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import SubtitleText from "../../components/text/subtitleText";
import Container from "@mui/material/Container";
import SideNav from "../../components/sidenav/sideNav";
import Box from "@mui/material/Box";
import {
  Grid,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputBase,
  Avatar,
} from "@mui/material";
import TitleText from "../../components/text/titleText";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import AvatarText from "../../components/avatar/avatarText";
import MyCongratulations from "../../api/MyCongratulations";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import SearchUsersControla from "../../components/search/searchUsersControla";
import MyUsers from "../../api/MyUsers";
import CompanyLogoControla from "../../components/images/companyLogoControla";
import SearchButtonControla from "../../components/search/searchButtonControla";
import LoadingControla from "../../components/load/loadingControla";
import ButtonControla from "../../components/buttons/buttonController";
import { Close, FileOpenOutlined, Search, Send } from "@mui/icons-material";
import ButtonIconControla from "../../components/buttons/buttonIconControla";

const colorTitle = "#9191B0";
const skyblue = "#2D9CDB";

// input buscar
const SearchTextField = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: "white",
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
}));
// fin input buscar

const StyledAddButton = styled(Button)({
  textTransform: "none",
  backgroundColor: skyblue,
  borderRadius: 16,
});

function Index() {
  // Pantallas de carga y alertas INICIO
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  // Pantallas de carga y alertas FIN

  // modal para crear mensaje
  const [openCreateMailModal, setOpenCreateMailModal] = useState(false);
  const [message, setMessage] = useState("");
  const [addressee, setaddresse] = useState();

  const handleOpenCreateModal = () => {
    setaddresse(null);
    setMessage(null);
    setDocumentUrl(null);
    setUserSendMessage(null);
    setOpenCreateMailModal(true);
  };

  const handleCloseCreateModal = () => {
    setOpenCreateMailModal(false);
  };

  // enviar mensaje
  const sendMessageToUser = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyCongratulations.storeCongratulations({
      idUsersReceiving: addressee,
      congratulation: message,
      document_url: documentUrl,
    });
    if (response !== false) {
      getSentCongratulations();
      handleOpenAlert("Mensaje enviado", "success");
      handleCloseCreateModal();
    } else {
      handleOpenAlert(response.msg, "error");
      handleCloseCreateModal();
    }
  };

  // mostrar mensajes
  const [sentCongratulations, setSentCongratulations] = useState([]);
  const [congratulationId, setCongratulationId] = useState([]);
  const [documentUrl, setDocumentUrl] = useState("");
  const [flag, setFlag] = useState(false);

  const getSentCongratulations = async () => {
    const response = await MyCongratulations.getCongratulations();
    console.log(response);
    setSentCongratulations(response);
    setRowsMailSendSearch(response);
    if (congratulationId !== "") {
      response.map((congratulation) => {
        if (congratulation.id_congratulations === congratulationId) {
          setCongratulationsDetail(congratulation);
        }
      });
    }
  };

  // usuarios para mostrar activos para enviar sms
  const [users, setUsers] = useState([]);
  const handleGetAllUsers = async () => {
    const response = await MyUsers.users();
    setUsers(response.users_actives);
  };

  // buscar usuario por id para enviar sms
  const [userSendMessage, setUserSendMessage] = useState("");
  const handleCatchAdressee = async (idUser) => {
    setaddresse(idUser);
    handleOpenLoading();
    const response = await MyUsers.editUsers({ idUsers: idUser });
    if (response.success !== false) {
      setUserSendMessage(response.first_name + " " + response.last_name);
      handleOpenAlert(response.msg, "success");
      handleCloseSearchUser();
    } else {
      handleOpenAlert(response.msg, "error");
    }
  };

  // detaller derecha del sms
  const [congratulationsDetail, setCongratulationsDetail] = useState([]);

  const handleShowSent = (mail) => {
    setCongratulationsDetail(mail.congratulation);
    setFlag(true);
    setCongratulationId(mail.congratulation.id_congratulations);
  };

  ////// Modal para buscar usuario
  const [openSearchUser, setOpenSearchUser] = useState(false);
  const handleOpenSearchUser = () => {
    setOpenSearchUser(true);
  };
  const handleCloseSearchUser = () => {
    setOpenSearchUser(false);
  };

  // buscar mensaje
  const [rowsMailSendSearch, setRowsMailSendSearch] = useState([]);
  const handleSearchMessage = (searchMessage) => {
    setRowsMailSendSearch(sentCongratulations);
    if (searchMessage !== "") {
      let expresion = new RegExp(`${searchMessage}.*`, "i");
      let sendMailSearch = sentCongratulations.filter(
        (x) =>
          expresion.test(x.congratulation) ||
          expresion.test(x.first_name) ||
          expresion.test(x.last_name)
      );

      setRowsMailSendSearch(sendMailSearch);
    } else {
      setRowsMailSendSearch(sentCongratulations);
    }
  };

  useEffect(() => {
    getSentCongratulations();
    handleGetAllUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="/mailbox/" />
      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container justifyContent="flex-end">
            <Grid item xs={12} md={5}>
              <SubtitleText
                text={"Buzón de participación"}
                color={colorTitle}
              ></SubtitleText>
              <TitleText text={"Felicitaciones enviadas"}></TitleText>
            </Grid>
            <Grid item xs={12} md={5}></Grid>
            <Grid
              item
              xs={12}
              md={2}
              justifyContent="flex-end"
              style={{ textAlign: "center" }}
            >
              <CompanyLogoControla />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, mt: 2 }}>
          <Grid container spacing={2}>
            <Grid
              item
              md={6}
              // style={{
              //   display: "flex",
              //   justifyContent: "flex-end",
              // }}
            >
              <Stack
                direction="row"
                spacing={2}
                // justifyContent="center"
                // alignItems="left"
              >
                <StyledAddButton
                  variant="text"
                  disableElevation
                  size="large"
                  style={{
                    backgroundColor: "rgb(89, 156, 255)",
                    borderRadius: "15px",
                    color: "white",
                    padding: "6px 18px",
                  }}
                  onClick={handleOpenCreateModal}
                >
                  Crear nueva felicitación
                </StyledAddButton>

                <SearchTextField
                  onChange={({ target }) => handleSearchMessage(target.value)}
                >
                  <SearchIconWrapper>
                    <Search />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Buscar ..."
                    inputProps={{ "aria-label": "search" }}
                  />
                </SearchTextField>
              </Stack>
            </Grid>

            <Grid item md={6} style={{ textAlign: "center" }}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  size="large"
                  variant="text"
                  style={{ textTransform: "none", borderRadius: "15px" }}
                  // href={"/mailbox/sent"}
                >
                  Consultas y comentarios
                </Button>
                <Button
                  size="large"
                  variant="text"
                  style={{ textTransform: "none", borderRadius: "15px" }}
                  // href={"/mailbox/reports/sentreport"}
                >
                  Reportes
                </Button>
                <Button
                  size="large"
                  variant="text"
                  style={{
                    backgroundColor: "#599CFF",
                    borderRadius: "15px",
                    color: "white",
                    padding: "8px 25px",
                    textTransform: "none",
                  }}
                  // href={"/mailbox/mailboxcongrats"}
                >
                  Felicitaciones
                </Button>
              </Stack>
            </Grid>
            <Grid item md={3} style={{ textAlign: "right" }}></Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, mt: 2, mb: 4 }}>
          <Grid container spacing={2}>
            {rowsMailSendSearch !== null ? (
              <Grid
                item
                md={6}
                style={{ textAlign: "center" }}
                sx={{
                  textAlign: "left",
                  overflowY: "scroll",
                  maxHeight: "400px",
                  paddingTop: "0 !important",

                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                  },
                  "&::-webkit-scrollbar-button": {
                    height: "80px",
                  },
                  "&::-webkit-scrollbar-track": {
                    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#222ADA",
                  },
                }}
              >
                {rowsMailSendSearch.map((congratulation, key) => (
                  <Card
                    onClick={() => {
                      handleShowSent({
                        congratulation,
                      });
                    }}
                    sx={{ mt: 2 }}
                    style={{
                      backgroundColor: "white",
                      borderRadius: "16px",
                      padding: "17px",
                      border:
                        congratulationId === congratulation.id_congratulations
                          ? "2px solid #1538f5"
                          : "",
                    }}
                    key={key}
                  >
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item md={8} style={{ textAlign: "left" }}>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "#034AFF",
                              fontWeight: 600,
                            }}
                          >
                            De:
                            {" " +
                              congratulation.first_name_sender +
                              " " +
                              congratulation.last_name_sender}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "#034AFF",
                              fontWeight: 600,
                            }}
                          >
                            Para:
                            {" " +
                              congratulation.first_name_receiving +
                              " " +
                              congratulation.last_name_receiving}
                          </Typography>
                        </Grid>
                        <Grid item md={4} style={{ textAlign: "right" }}>
                          <Typography
                            sx={{ fontSize: "12px", color: "#9191B0" }}
                          >
                            {congratulation.timestamp_created.substring(0, 16) +
                              " hrs"}
                          </Typography>
                        </Grid>
                        <Grid item md={11} style={{ textAlign: "left" }}>
                          <Typography
                            sx={{
                              fontSize: "18px",
                              color: "#091641",
                              fontWeight: "400",
                            }}
                          >
                            {congratulation.congratulation}
                          </Typography>
                        </Grid>
                        {congratulation.document_url !== null ? (
                          <Grid item md={1} style={{ textAlign: "right" }}>
                            <ButtonIconControla
                              icon={
                                <FileOpenOutlined sx={{ color: "white" }} />
                              }
                              backgroundColor={"#305AD9"}
                              backgroundColorHover={"#0E4AFF"}
                              textTooltip={"Ver archivo"}
                              directionUrl={congratulation.document_url}
                              directionTarget={"_blank"}
                            />
                          </Grid>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </CardContent>
                  </Card>
                ))}
              </Grid>
            ) : (
              <Grid
                item
                md={6}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    color: "#2F80ED",
                    fontWeight: "600",
                  }}
                >
                  Bandeja vacía
                </Typography>
                <img
                  src="/assets/images/banners/mailbox/bannerRecibidos.png"
                  alt="gif"
                />
              </Grid>
            )}
            {/* img={"/assets/images/banners/mailbox/bannerEnviados.png"} */}
            <Grid
              item
              md={6}
              sx={{
                textAlign: "center",
                overflowY: "scroll",
                maxHeight: "523px",
                paddingTop: "0 !important",
              }}
            >
              <img
                src="/assets/images/banners/mailbox/felicitaciones.png"
                alt="gif"
              />
            </Grid>

            {/* {flag === true ? (
              <Grid
                item
                md={6}
                sx={{
                  textAlign: "left",
                  overflowY: "scroll",
                  maxHeight: "523px",
                  paddingTop: "0 !important",

                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                  },
                  "&::-webkit-scrollbar-button": {
                    height: "80px",
                  },
                  "&::-webkit-scrollbar-track": {
                    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#222ADA",
                  },
                }}
              >
                <Card
                  sx={{ mb: 2 }}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "16px",
                    padding: "10px",
                    cursor: "pointer",
                  }}
                >
                  <CardContent>
                    <Grid container spacing={2}>
                    <Grid item md={8} style={{ textAlign: "left" }}>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "#034AFF",
                              fontWeight: 600,
                            }}
                          >
                            De:
                            {" " +
                              congratulationsDetail.first_name_sender +
                              " " +
                              congratulationsDetail.last_name_sender}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "#034AFF",
                              fontWeight: 600,
                            }}
                          >
                            Para:
                            {" " +
                              congratulationsDetail.first_name_receiving +
                              " " +
                              congratulationsDetail.last_name_receiving}
                          </Typography>
                        </Grid>
                        <Grid item md={4} style={{ textAlign: "right" }}>
                          <Typography
                            sx={{ fontSize: "12px", color: "#9191B0" }}
                          >
                            {congratulationsDetail.timestamp_created.substring(0, 16) +
                              " hrs"}
                          </Typography>
                        </Grid>

                      <Grid item md={11} style={{ textAlign: "left" }}>
                        <Typography
                          sx={{
                            fontSize: "17px",
                            color: "#323232",
                          }}
                        >
                          {congratulationsDetail.congratulation}
                        </Typography>
                      </Grid>
                      {congratulationsDetail.document_url !== null ? (
                        <Grid item md={1} style={{ textAlign: "right" }}>
                          <ButtonIconControla
                            icon={<FileOpenOutlined sx={{ color: "white" }} />}
                            backgroundColor={"#305AD9"}
                            backgroundColorHover={"#0E4AFF"}
                            textTooltip={"Ver archivo"}
                            directionUrl={congratulationsDetail.document_url}
                            directionTarget={"_blank"}
                          />
                        </Grid>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </CardContent>
                 
                </Card>
              </Grid>
            ) : (
              <Grid
                item
                md={6}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid item md={5}>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      color: "#2F80ED",
                      fontWeight: "600",
                    }}
                  >
                    Bandeja vacía
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      color: "#2F80ED",
                      fontWeight: "600",
                    }}
                  >
                    Seleccione un mensaje
                  </Typography>
                  <img
                    src="/assets/images/rowLeft.png"
                    alt="row left"
                    width={"100px"}
                  />
                </Grid>
              </Grid>
            )} */}
          </Grid>
          <Dialog
            open={openCreateMailModal}
            onClose={handleCloseCreateModal}
            maxWidth="xs"
            fullWidth
            sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
          >
            <DialogTitle>
              <Typography
                sx={{
                  color: "#305AD9",
                  fontSize: 24,
                  fontWeight: "bold",
                }}
              >
                Crear nuevo Mensaje
              </Typography>
            </DialogTitle>
            <form onSubmit={sendMessageToUser}>
              <DialogContent>
                <InputControlaVertical
                  inputValue={setMessage}
                  value={message}
                  inputType={"textArea"}
                  modalType={true}
                  text="Mensaje"
                  required
                ></InputControlaVertical>
                <SearchButtonControla
                  titleLabel={"Buscar Usuario"}
                  modalType={true}
                  functionClick={handleOpenSearchUser}
                  inputValue={userSendMessage}
                  required
                ></SearchButtonControla>
                <InputControlaVertical
                  inputValue={setDocumentUrl}
                  value={documentUrl}
                  inputType={"file"}
                  modalType={true}
                ></InputControlaVertical>
              </DialogContent>
              <DialogActions>
                <Stack direction={"row"} spacing={2} justifyContent={"center"}>
                  <Box>
                    <ButtonControla
                      iconButton={<Close sx={{ color: "white" }} />}
                      backgroundColor={"#C61C39"}
                      backgroundColorHover={"#D61F3E"}
                      textButton={"Cancelar"}
                      typeButton={"button"}
                      functionOnClick={handleCloseCreateModal}
                    />
                  </Box>{" "}
                  <Box>
                    <ButtonControla
                      iconButton={<Send sx={{ color: "white" }} />}
                      backgroundColor={"#169073"}
                      backgroundColorHover={"#1BAA88"}
                      textButton={"Enviar mensaje"}
                      typeButton={"submit"}
                    />
                  </Box>
                </Stack>
              </DialogActions>
            </form>
          </Dialog>
        </Box>
      </Container>

      {/* Dialog para buscar usuario */}
      <Dialog
        open={openSearchUser}
        onClose={handleCloseSearchUser}
        maxWidth="xs"
        fullWidth
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <SearchUsersControla
          rowsUser={users}
          handleUsersActionButton={handleCatchAdressee}
        ></SearchUsersControla>
      </Dialog>
    </Box>
  );
}

export default Index;
