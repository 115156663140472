import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function registersAccidents() {
  const url = "registers/general/accidents/get";
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function severityWorkAccident() {
  const url = "registers/general/accidents/severities/get";
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function severityIncidents() {
  const url = "registers/general/accidents/severities/incidents/get";
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

// workers accidents
function getAccidentsWorkers({ idRegistersAccidents }) {
  const url = "registers/general/accidents/workers/get/" + idRegistersAccidents;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function deleteAccidentsWorkers({ id_registers_accidents_workers }) {
  const url =
    "registers/general/accidents/workers/delete/" +
    id_registers_accidents_workers;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "DELETE",
    url: url,
  });
}

async function creatAccidentsWorkers({
  idRegistersAccidents,
  id_users,
  area,
  shift,
  work_experience,
  work_hours_before,
}) {
  const url =
    "registers/general/accidents/workers/store/" + idRegistersAccidents;
  const data = {
    id_users: id_users,
    area: area,
    shift: shift,
    work_experience: work_experience,
    work_hours_before: work_hours_before,
  };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

// Usuarios a buscar al agregar trabajadores
function getUserSearchsAddsWorkers({ idRegistersAccidents }) {
  const url =
    "registers/general/accidents/workers/users/get/" + idRegistersAccidents;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

//registros de accidents
async function createRegistersAccidents({ code }) {
  const url = "registers/general/accidents/store";
  const data = { code: code };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function editRegistersAccidents({ idRegistersAccidents }) {
  const url = "registers/general/accidents/edit/" + idRegistersAccidents;
  console.log(url);
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function updateRegistersAccidents({
  idRegistersAccidents,
  code,
  timestamp_accident,
  timestamp_investigation,
  location,
  id_registers_accidents_severities,
  id_registers_accidents_severities_incident,
  medical_leave_days,
  affected_workers,
  injured_body_part_desciption,
  description,
  document_url_1,
  document_url_2,
  document_url_3,
  causes_description,
}) {
  const current = new Date();
  const year = current.getFullYear();
  const month = current.getMonth() + 1;
  const day = current.getDate();

  if (document_url_1 !== null && document_url_1 !== undefined) {
    document_url_1 = await uploadFileCompanies(
      document_url_1,
      "documentation/registers/generals/accidents/" +
        year +
        "/" +
        month +
        "/" +
        day +
        "/"
    );
  }

  if (document_url_2 !== null && document_url_2 !== undefined) {
    document_url_2 = await uploadFileCompanies(
      document_url_2,
      "documentation/registers/generals/accidents/" +
        year +
        "/" +
        month +
        "/" +
        day +
        "/"
    );
  }
  if (document_url_3 !== null && document_url_3 !== undefined) {
    document_url_3 = await uploadFileCompanies(
      document_url_3,
      "documentation/registers/generals/accidents/" +
        year +
        "/" +
        month +
        "/" +
        day +
        "/"
    );
  }

  const url = "registers/general/accidents/update/" + idRegistersAccidents;

  const data = {
    code: code,
    timestamp_accident: timestamp_accident,
    timestamp_investigation: timestamp_investigation,
    location: location,
    id_registers_accidents_severities: id_registers_accidents_severities,
    id_registers_accidents_severities_incident:
      id_registers_accidents_severities_incident,
    medical_leave_days: medical_leave_days,
    affected_workers: affected_workers,
    injured_body_part_desciption: injured_body_part_desciption,
    description: description,
    document_url_1: document_url_1,
    document_url_2: document_url_2,
    document_url_3: document_url_3,
    causes_description: causes_description,
  };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

// MEDIDAS CORRECTIVAS

//get de estatus de medidas correctivas
function getStatusCorrectivesActions() {
  const url = "registers/general/accidents/measures/statuses/get";
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

//eliminar medidas correctivas
async function deleteCorrectivesActions({ id_registers_accidents_measures }) {
  const url =
    "registers/general/accidents/measures/delete/" +
    id_registers_accidents_measures;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "DELETE",
    url: url,
  });
}
// Buscar trabajadores en medidas correctivas
function getSearchsCorrectivesActionWorkers({ idRegistersAccidents }) {
  const url =
    "registers/general/accidents/measures/users/get/" + idRegistersAccidents;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function getCorrectivesActionWorkers({ idRegistersAccidents }) {
  const url =
    "registers/general/accidents/measures/get/" + idRegistersAccidents;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function createCorrectivesActions({
  idRegistersAccidents,
  id_registers_accidents_measures_statuses,
  id_users,
  timestamp_start,
  timestamp_end,
  description,
}) {
  const url =
    "registers/general/accidents/measures/store/" + idRegistersAccidents;
  const data = {
    id_registers_accidents_measures_statuses:
      id_registers_accidents_measures_statuses,
    id_users: id_users,
    timestamp_start: timestamp_start,
    timestamp_end: timestamp_end,
    description: description,
  };

  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

// TRABAJADORES RESPONSABLES
//get de trabajadores responsables
function getResponsibleWorkers({ idRegistersAccidents }) {
  const url =
    "registers/general/accidents/responsible/get/" + idRegistersAccidents;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

// crear trabajadores responsables
async function createResponsibleWorkers({
  idRegistersAccidents,
  id_users,
  firm,
}) {
  const url =
    "registers/general/accidents/responsible/store/" + idRegistersAccidents;
  const data = {
    id_users: id_users,
    firm: firm,
  };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

// eliminar trabajadores responsable
async function deleteResponsibleWorkers({
  id_registers_accidents_responsible,
}) {
  const url =
    "registers/general/accidents/responsible/delete/" +
    id_registers_accidents_responsible;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "DELETE",
    url: url,
  });
}

// Buscar trabajadores en responsables:
function getSearchsResponsiblesWorkers({ idRegistersAccidents }) {
  const url =
    "registers/general/accidents/responsible/users/get/" + idRegistersAccidents;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

//cerrar registro
function getRegisterStatus({ idRegistersAccidents }) {
  const url =
    "registers/general/accidents/update/statuses/end/" + idRegistersAccidents;
  return MyConfigurationApi.connectApi({
    dataJson: null,
    method: "PUT",
    url: url,
  });
}

// firmar responsable por
function signResponsible({ id_accidents_responsible }) {
  const url =
    "registers/general/accidents/responsible/update/firm/" +
    id_accidents_responsible;
  const data = {};
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

const MyRegistersAccidents = {
  registersAccidents,
  createRegistersAccidents,
  editRegistersAccidents,
  updateRegistersAccidents,
  severityWorkAccident,
  severityIncidents,
  getAccidentsWorkers,
  deleteAccidentsWorkers,
  creatAccidentsWorkers,
  getUserSearchsAddsWorkers,
  getSearchsCorrectivesActionWorkers,
  getSearchsResponsiblesWorkers,
  getCorrectivesActionWorkers,
  createCorrectivesActions,
  getStatusCorrectivesActions,
  deleteCorrectivesActions,
  getResponsibleWorkers,
  createResponsibleWorkers,
  deleteResponsibleWorkers,
  getRegisterStatus,
  // firmar
  signResponsible,
};
export default MyRegistersAccidents;
