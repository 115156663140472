import { Grid } from "@mui/material";
import React from "react";
import MyConfigurationApi from "../../api/MyConfigurationApi";

function CompanyLogoControla() {
    const companyLogo = MyConfigurationApi.companyData().logo_url;
    const companyName = MyConfigurationApi.companyData().name;
    return (
        <Grid container>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <img src={companyLogo} alt={'Logo ' + companyName} style={{ width: '100%' }} />
            </Grid>

        </Grid >
    );
}

export default CompanyLogoControla;
