import { Button, Grid, Tooltip } from "@mui/material";
import { React } from "react";
import MyConfigurationApi from "../../api/MyConfigurationApi";

function ButtonControla({
  iconButton,
  backgroundColor,
  textButton,
  functionOnClick,
  backgroundColorHover = undefined,
  url,
  target = "",
  typeButton = "button",
  roles = [],
  tooltipText,
}) {
  const userData = MyConfigurationApi.userData();
  let usersRoles = userData.roles;
  let showButton = false;
  roles =
    roles.length === 0 ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13] : roles;
  roles.map((rol) => {
    if (usersRoles.includes(rol)) {
      showButton = true;
    }
  });
  if (showButton === true) {
    return (
      <Grid
        container
        //   sx={{
        //     maxWidth: '160px',
        //     background: 'red',
        //     borderRadius:'16px',
        // }}
      >
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Tooltip title={tooltipText}>
            <Button
              variant="contained"
              type={typeButton}
              sx={{
                backgroundColor: { backgroundColor },
                textTransform: "none",
                boxShadow: "none",
                borderRadius: "16px",
                "&:hover": {
                  backgroundColor:
                    backgroundColorHover === undefined
                      ? backgroundColor
                      : backgroundColorHover,
                },
              }}
              onClick={functionOnClick}
              startIcon={iconButton}
              href={url}
              target={target}
            >
              {textButton}
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    );
  } else {
    return "";
  }
}

export default ButtonControla;
