import { React, useEffect, useState } from "react";
import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import SideNav from "../../../../components/sidenav/sideNav";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputLabel,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Add,
  ArrowBack,
  Delete,
  EditOutlined,
  HistoryEduOutlined,
} from "@mui/icons-material";
import TitleText from "../../../../components/text/titleText";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import ButtonControla from "../../../../components/buttons/buttonController";
import CompanyLogoControla from "../../../../components/images/companyLogoControla";
import SearchUsersControla from "../../../../components/search/searchUsersControla";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import CloseIcon from "@mui/icons-material/Close";
import MyUsers from "../../../../api/MyUsers";
import MyConfigurationApi from "../../../../api/MyConfigurationApi";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";
import LoadingControla from "../../../../components/load/loadingControla";
import MyStatisticalData from "../../../../api/MyRegisterStatisticalData";

const colorTitle = "#9191B0";


function Edit() {
  const params = new URLSearchParams(window.location.search);
  const idRegistersStatisticalData = params.get(
    "idRegistersStatisticalData"
  );


  // alerts
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  //fin de alerts
  // Dialogos de confirmacion INICIO
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    setIdResponsibleWorker(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    // eslint-disable-next-line default-case
    switch (buttonActionType) { 
      case "deleteResponsible":
        handleDeleteResponsibleWorker();
        break;
    }
    setOpenModalConfirm(false);
  };
  // Dialogos de confirmacion FIN

  // REGISTRO DE ENTRENAMIENTO
  const [code, setCode] = useState(""); 


  // update register
  const handleUpdateRegistersStatisticalData = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyStatisticalData.updateRegistersStatisticalData(
      {
        idRegistersStatisticalData,
        code,   
      }
    );
    handleOpenAlert("Se actualizaron los datos", "success");

    return response;
  };

  // edit register
  const handleEditRegistersStatisticalData = async () => {
    const response = await MyStatisticalData.editRegistersStatisticalData({
      idRegistersStatisticalData,
    });
      setRegisterStatus(response.register_status === 1 ? true : false);
      setCode(response.code);  

    return response;
  };

  // USUARIOS ACTIVOS
  const [rowsUsersSearchs, setRowsUsersSearchs] = useState([]);
  const handleGetUsersSearchsAddsWorkers = async () => {
    const response = await MyUsers.users();
    setRowsUsersSearchs(response.users_actives);
  };



  // identificar usuario logeado
  const [idUserLogged, setIdUserLogged] = useState();
  const handleUserActive = async () => {
    const responsable = await MyConfigurationApi.userData();
    setIdUserLogged(responsable.id_users);
  };


  //*************************  RESPONSABLES  *******************************************
  const [openModalResponsibleWorker,  setOpenModalResponsibleWorker] = useState(false);

  const handleOpenModalResponsibleWorker = () => {
    setOpenModalResponsibleWorker(true);
  };


  //listas trabajadores responsables
  const [responsibleWorkers, setResponsibleActions] = useState([]);
  const handleGetResponsibleWorkers = async () => {
    const response = await MyStatisticalData.getResponsibleWorkers({
      idRegistersStatisticalData:idRegistersStatisticalData,
    });
    if (response.success === false) {
    } else {     
      setResponsibleActions(response);
    }
  };

//crear trabajadores responsables
const handleStoreResponsibleWorkers = async (idUsers) => {
  handleOpenLoading();
  const response = await MyStatisticalData.createResponsibleWorkers({
    idRegistersStatisticalData: idRegistersStatisticalData,
    id_users: idUsers
  });

  if (response.success === false) {
  } else {
    handleGetResponsibleWorkers();
    setOpenModalResponsibleWorker(false);
    handleOpenAlert("Se agregó de forma correcta los datos", "success");
  }
  return response;
};


  // eliminar trabajador responsable
  const [idResponsibleWorker, setIdResponsibleWorker] = useState(0);
  const handleDeleteResponsibleWorker = async () => {
    const response = await MyStatisticalData.deleteResponsibleWorker({
      idResponsible: idResponsibleWorker,
    });
    handleGetResponsibleWorkers();
    return response;
  };

  // // firmar responsable
  const [openModalSignResponsibleWorker, setOpenModalSignResponsibleWorker] =  useState(false);
  const [idResponsibleSign, setIdResponsibleSign] = useState(0);
  const [idResponsibleSignUsers, setIdResponsibleSignUsers] = useState(0);
  const handleOpenModalSignResponsibleWorker = (idResponsible, id_users) => {
    setIdResponsibleSign(idResponsible);
    setIdResponsibleSignUsers(id_users);
    setOpenModalSignResponsibleWorker(true);
  };

  const handleSignResponsibleWorker = async (event) => {
    event.preventDefault();
    handleOpenLoading();

    const response = await MyStatisticalData.updateResponsibleWorkers({
      idResponsible: idResponsibleSign,
      id_users:idResponsibleSignUsers
    });
    setOpenModalSignResponsibleWorker(false);
    handleGetResponsibleWorkers();
    handleOpenAlert("Se registró la firma con éxito", "success");

  };

  // // CERRAR ESTADO DEL REGISTRO
  const [registerStatus, setRegisterStatus] = useState(1);
  const handleGetRegisterStatus = async () => {
    handleOpenLoading();
   const response = await MyStatisticalData.updateRegistersStatusClose({
    idRegistersStatisticalData: idRegistersStatisticalData,
   });
   handleOpenAlert("El registro se cerró de forma correcta", "success");
   handleEditRegistersStatisticalData();
   return response;
  };

  useEffect(() => {
    handleEditRegistersStatisticalData();

    // RESPONSABLES
    handleGetResponsibleWorkers();

    handleGetUsersSearchsAddsWorkers();
    handleUserActive();
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="/documentation/registers/generals/statisticaldata" />
      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />
      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container justifyContent="space-between">
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
              <TitleText
                text={
                  "Registros de estadísticas de seguridad y salud en el trabajo"
                }
              ></TitleText>
            </Grid>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
              <CompanyLogoControla />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <form onSubmit={handleUpdateRegistersStatisticalData}>
            {/* tabla para mostrar accidents workers */}
            <Grid container spacing={2} sx={{ marginTop: "4px" }}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputControlaVertical
                  text={"Código:"}
                  inputType={"text"}
                  inputValue={setCode}
                  value={code}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <InputControlaVertical
                  text={"Razón social o denominación social:"}
                  inputType={"date"}
                  // inputValue={setRuc}
                  // value={ruc}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <InputControlaVertical
                  text={"Mes inicial:"}
                  inputType={"date"}
                  // inputValue={setRuc}
                  // value={ruc}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                <InputControlaVertical
                  text={"Mes final:"}
                  inputType={"date"}
                  // inputValue={setNumberHour}
                  // value={numberHour}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Mes
                        </TableCell>

                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          N° de accidentes mortales
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Áreas
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          N° de accidentes leves
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Áreas
                        </TableCell>
                        <TableCell
                          sx={{
                            color: "#305ad9",
                            fontSize: "16px",
                            fontWeight: "bold",
                          }}
                        >
                          Áreas
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody
                    // sx={{ display: documentType !== 1 ? "none" : "" }}
                    >
                      {/* {responsibleWorkers.map((row) => (
                      <TableRow
                        hover
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.name_user_responsible}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.document_user_responsible}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.area}
                        </TableCell>
                        <TableCell component="th" scope="row">
                        {row.firm === 0 ? (
                            "No firmado" && row.id_users === idUserLogged ? (
                              <ButtonIconControla
                                icon={
                                  <HistoryEduOutlined sx={{ color: "white" }} />
                                }
                                backgroundColor={"#F1C40F"}
                                backgroundColorHover={"#F4D03F"}
                                textTooltip={"Firmar"}
                                functionOnClick={() =>
                                  handleOpenModalSignWorkerInvolved(row.id, row.id_users)
                                }
                              />
                            ) : (
                              "No firmado"
                            )
                          ) : (
                            "Firmado"
                          )}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.firm_date === null || row.firm_date === undefined ? '' : row.firm_date.substring(0,19)} 
                        </TableCell>                  
                        <TableCell component="th" scope="row">
                          {row.observation}
                        </TableCell>

                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                            spacing={1}
                          >                          
                            {registerStatus === true ? (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id,
                                    "¿Esta seguro de eliminar los datos del trabajador?",
                                    "Una vez eliminado no se podra recuperar los datos del trabajador " + row.name_user_responsible ,
                                    "Si, Eliminar",
                                    "deleteWorkers"
                                  );
                                }}
                              />
                           ) : (
                              ""
                            )} 
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))} */}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

            {/* responsables del registro y de la investigación */}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              sx={{ marginTop: 3, marginBottom: 1 }}
            >
              <Box>
                <InputLabel
                  sx={{
                    padding: "8px",
                    fontSize: "18px",
                    color: "#305AD9",
                    fontWeight: "bold",
                  }}
                >
                  Responsables del registro y de la investigación
                </InputLabel>
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  sx={{ color: "#F2994A", ml: 1 }}
                >
                  Este campo es obligatorio
                </Typography>
              </Box>
              <Box>
                {registerStatus === true ? (
                  <ButtonControla
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar responsables"}
                    functionOnClick={handleOpenModalResponsibleWorker}
                  />
                ) : (
                  ""
                )} 
              </Box>
            </Stack>

            <Grid container sx={{ marginTop: "4px" }}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Nombre y Apellido
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Cargo
                      </TableCell>
                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Fecha de firma
                      </TableCell>

                      <TableCell
                        sx={{
                          color: "#305ad9",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                      >
                        Firma
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {responsibleWorkers.map((row) => (
                      <TableRow
                        hover
                        key={row.id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {row.first_name + " " + row.last_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.position_name}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.firm_date === null
                            ? ""
                            : row.firm_date.substring(0, 19)}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {row.firm === 0 ? (
                            "No firmado" && row.id_users === idUserLogged ? (
                              <ButtonIconControla
                                icon={
                                  <HistoryEduOutlined sx={{ color: "white" }} />
                                }
                                backgroundColor={"#F1C40F"}
                                backgroundColorHover={"#F4D03F"}
                                textTooltip={"Firmar"}
                                functionOnClick={() =>
                                  handleOpenModalSignResponsibleWorker(
                                    row.id,
                                    row.id_users
                                  )
                                }
                              />
                            ) : (
                              "No firmado"
                            )
                          ) : (
                            "Firmado"
                          )}
                        </TableCell>
                        <TableCell align="right">
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            {registerStatus === true ? (
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  handleOpenModalConfirm(
                                    row.id,
                                    "¿Esta seguro de eliminar los datos del trabajador responsable?",
                                    "Una vez eliminado no se podra recuperar los datos del trabajador responsable " +
                                      row.first_name +
                                      " " +
                                      row.last_name,
                                    "Si, Eliminar",
                                    "deleteResponsible"
                                  );
                                }}
                              />
                            ) : (
                              ""
                            )} 
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            {/* Botones de impresión, guardar y cancelar */}
            <Grid
              container
              spacing={2}
              sx={{
                display: "flex",
                whidth: "100%",
                justifyContent: "space-evenly",
                paddingY: 4,
              }}
            >
              {/* cerrado temporalmente */}
              {/* <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<Print sx={{ color: "white" }} />}
                  backgroundColor={"#2D9CDB"}
                  backgroundColorHover={"#33AEF4"}
                  textButton={"Imprimir"}
                />
              </Grid> */}
              <Grid item xs={12} md={2}>
                <ButtonControla
                  iconButton={<ArrowBack sx={{ color: "white" }} />}
                  backgroundColor={"#EB5757"}
                  backgroundColorHover={"#FF4040"}
                  textButton={"Regresar"}
                  functionOnClick={() =>
                    (window.location.href =
                      "/documentation/registers/generals/statisticaldata")
                  }
                />
              </Grid>
              {registerStatus === true ? (
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    iconButton={<EditOutlined sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Actualizar registro"}
                    typeButton="submit"
                  />
                </Grid>
             ) : (
                ""
              )} 
              {registerStatus === true ? (
                <Grid item xs={12} md={2}>
                  <ButtonControla
                    iconButton={<CloseIcon fontSize="inherit" />}
                    backgroundColor={"black"}
                    backgroundColorHover={"#2C2B29"}
                    textButton={"Cerrar registro"}
                    functionOnClick={handleGetRegisterStatus}
                  />
                </Grid>
             ) : (
                ""
              )} 
            </Grid>
          </form>
        </Box>
      </Container>
     

      {/* Dialog de agregar repsonsables  del registro  y de la invesatigación */}
      <Dialog
        open={openModalResponsibleWorker}
        onClose={() => setOpenModalResponsibleWorker(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar Responsables del Registro y de la Investigación
          </Typography>
        </DialogTitle>

        <DialogContent sx={{ paddingTop: "0" }}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <SearchUsersControla
              rowsUser={rowsUsersSearchs}
              handleUsersActionButton={handleStoreResponsibleWorkers}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#6969D6",
              backgroundColor: "#CBCBFF",
              borderRadius: "16px",
              marginRight: "10px",
            }}
            onClick={() => setOpenModalResponsibleWorker(false)}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialogo para firmar responsable */}
      <Dialog
        open={openModalSignResponsibleWorker}
        onClose={() => {
          setOpenModalSignResponsibleWorker(false);
        }}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            id="modal-modal-title"
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "20px",
            }}
          >
            Sus datos quedarán registrados de forma permanente ¿Desea firmar?
          </Typography>
        </DialogTitle>
        <form onSubmit={handleSignResponsibleWorker}>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={() => {
                setOpenModalSignResponsibleWorker(false);
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "none",
                color: "white",
                backgroundColor: "#169073",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Firmar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}

export default Edit;
