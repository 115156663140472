import { Grid, InputBase, InputLabel, TextareaAutosize } from "@mui/material";
import { React, useState } from "react";
import ButtonIconControla from "../buttons/buttonIconControla";
import { InfoOutlined } from "@mui/icons-material";


function InputControlaVerticalIcon(props) {

    const { text, inputType, inputValue, modalType = false, value = undefined, required = false, textTooltip, ...other } = props;
    // const textAreaBorder = '1px solid transparent';

    const [textAreaBorder, setTextAreaBorder] = useState('1px solid transparent')


    return (
        <Grid container>
            <Grid item>
                <InputLabel
                    sx={{
                        padding: '8px',
                        fontSize: '18px',
                        color: '#305AD9',
                        fontWeight: 'bold'
                    }}> {text} </InputLabel>

            </Grid>
            <Grid item>
                <ButtonIconControla
                    icon={<InfoOutlined sx={{ color: "white" }} />}
                    backgroundColor={"#FFC300"}
                    backgroundColorHover={"#FACB32"}
                    textTooltip={textTooltip}
                // functionOnClick={handleOpenModalTypeAgentsTable}
                />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                {inputType === 'textArea' ?
                    <TextareaAutosize
                        onFocus={() => { setTextAreaBorder('1px solid blue') }}
                        onBlur={() => { setTextAreaBorder('1px solid transparent') }}
                        className='inputControlaVertical'
                        onChange={({ target }) => inputValue(target.value)}
                        minRows={3}
                        required={required}
                        style={{
                            fontSize: '1rem',
                            fontFamily: 'Roboto',
                            width: '100%',
                            padding: '4px 10px',
                            border: textAreaBorder,
                            outline: '0px transparent',
                            backgroundColor: (modalType === true ? '#F5F5F5' : '#FFFFFF'),
                            color: '#305AD9',
                            borderRadius: '8px',

                        }}
                        value={(value === undefined || value === null ? undefined : value)}
                        {...other}
                    />
                    :
                    inputType === 'file' ?
                        <InputBase type={inputType}
                            className='inputControlaVertical'
                            onChange={({ target }) => inputValue(target.files[0])}
                            required={required}
                            inputProps={
                                { ...other }
                            }
                            sx={{
                                fontFamily: 'Roboto',
                                width: '100%',
                                padding: '4px 10px',
                                border: '1px solid transparent',
                                backgroundColor: (modalType === true ? '#F5F5F5' : '#FFFFFF'),
                                color: '#305AD9',
                                borderRadius: '8px',
                                "&.Mui-focused": {
                                    border: "1px solid blue",
                                }
                            }} />
                        :
                        <InputBase type={inputType}
                            className='inputControlaVertical'
                            value={(value === undefined || value === null ? undefined : value)}
                            onChange={({ target }) => inputValue(target.value)}
                            required={required}
                            {...other}
                            sx={{
                                fontFamily: 'Roboto',
                                width: '100%',
                                padding: '4px 10px',
                                border: '1px solid transparent',
                                backgroundColor: (modalType === true ? '#F5F5F5' : '#FFFFFF'),
                                color: '#305AD9',
                                borderRadius: '8px',
                                "&.Mui-focused": {
                                    border: "1px solid blue",
                                }
                            }} />
                }

            </Grid>
        </Grid >
    );
}

export default InputControlaVerticalIcon;
