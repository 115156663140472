import React from 'react';
import SubtitleText from '../components/text/subtitleText';
import Container from '@mui/material/Container';
import SideNav from "../components/sidenav/sideNav";
import Box from '@mui/material/Box';
import { Grid, Button } from '@mui/material';
import TitleText from '../components/text/titleText';
import InputControla from '../components/textfields/inputControla';
const colorTitle = "#9191B0";


function Create() {

    return (

        <Box sx={{ display: "flex", height: '100%' }}>
            <SideNav />

            <Container>
                <Box >
                    <Grid container>
                        <Grid item xs={11} sm={8} md={9} lg={10} xl={10}>
                            <SubtitleText text={"Herramientas de gestión de riesgos"} color={colorTitle}></SubtitleText>
                            <TitleText text={"Crear estándar"} />
                            {/* <DocBanner color2='#2F67BC' color1='#8FBCFF' text='Estandares' /> */}
                        </Grid>
                        <Grid item xs={1} sm={4} md={3} lg={2} xl={2} sx={{ textAlign: 'right' }}>
                            <img src={"/assets/images/logoempresaejemplo.png"} style={{ width: "auto", maxHeight: '100px' }} alt='logo'/>
                        </Grid>
                    </Grid>
                    {/* xs, extra-small: 0px
                    sm, small: 600px
                    md, medium: 900px
                    lg, large: 1200px
                    xl, extra-large: 1536px */}
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={6} sm={5} md={4} lg={3} xl={3}>
                            <InputControla text={"Fecha de creación"} inputType={"date"} />
                        </Grid>

                        <Grid item xs={6} sm={5} md={4} lg={3} xl={3}>
                            <InputControla text={"Elaborado por"} inputType={"text"} />
                        </Grid>

                        <Grid item xs={6} sm={5} md={4} lg={3} xl={3}>
                            <InputControla text={"Cargo"} inputType={"text"} />
                        </Grid>

                        <Grid item xs={6} sm={5} md={4} lg={3} xl={3}>
                            <InputControla text={"Firma"} inputType={"text"} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={6} sm={5} md={4} lg={3} xl={3}>
                            <InputControla text={"Fecha de Revision"} inputType={"date"} />
                        </Grid>

                        <Grid item xs={6} sm={5} md={4} lg={3} xl={3}>
                            <InputControla text={"Revisado"} inputType={"text"} />
                        </Grid>

                        <Grid item xs={6} sm={5} md={4} lg={3} xl={3}>
                            <InputControla text={"Cargo"} inputType={"text"} />
                        </Grid>

                        <Grid item xs={6} sm={5} md={4} lg={3} xl={3}>
                            <InputControla text={"Firma"} inputType={"text"} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={6} sm={5} md={4} lg={3} xl={3}>
                            <InputControla text={"Codigo"} inputType={"text"} />
                        </Grid>

                        <Grid item xs={6} sm={5} md={4} lg={3} xl={3}>
                            <InputControla text={"Version"} inputType={"text"} />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <InputControla text={"Nombre del documento"} inputType={"text"} />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <InputControla text={"Archivo"} inputType={"file"} />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} sx={{ mt: 3, mb: 4 }}>
                        <Grid item md={6} sx={{ textAlign: 'right' }} >
                            <Button
                                href='/documentation/documents/standards'
                                sx={{
                                    width: '121px',
                                    height: '44px',
                                    fontSize: '16px',
                                    borderRadius: '16px',
                                    backgroundColor: '#D1D1E9',
                                    color: '#5D5D93'
                                }} >Cancelar</Button>
                        </Grid>
                        <Grid item md={6} sx={{ textAlign: 'left' }}>
                            <Button sx={{
                                width: '121px',
                                height: '44px',
                                fontSize: '16px',
                                borderRadius: '16px',
                                backgroundColor: '#169073',
                                color: '#F0F0F0'
                            }}>Guardar</Button>
                        </Grid>
                    </Grid>

                </Box>
            </Container >

        </Box >

    );

}

export default Create;

