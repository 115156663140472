import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileGeneral } from "./MyFirebase";

function categories() {
    const url = 'admin/epps/categories/get'
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function epps({ idEppsCategories }) {
    const url = 'admin/epps/categories/get/' + idEppsCategories;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

async function createEpps({ idEppsCategories, name, description, photoUrl }) {
    if (photoUrl !== null && photoUrl !== undefined) {
        photoUrl = await uploadFileGeneral(photoUrl, 'epps/');
    }
    const data = {
        'id_epps_categories': idEppsCategories,
        'epp': name,
        'description': description,
        'photo_url': photoUrl
    };
    const url = 'admin/epps/store';
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'POST', url: url });
}

async function updateEpps({ idEpps, idEppsCategories, name, description, photoUrl }) {
    if (photoUrl !== null && photoUrl !== undefined) {
        photoUrl = await uploadFileGeneral(photoUrl, 'epps/');
    }
    const data = {
        'id_epps_categories': idEppsCategories,
        'epp': name,
        'description': description,
        'photo_url': photoUrl
    };
    const url = 'admin/epps/update/' + idEpps;
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

async function deleteEpps({ idEpps }) {
    const url = 'admin/epps/delete/' + idEpps;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'DELETE', url: url });
}

async function createEppsCategories({ name }) {
    const data = {
        'category': name,
    };
    const url = 'admin/epps/categories/store';
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'POST', url: url });
}

async function updateEppsCategories({ idEppsCategories, name }) {
    const data = {
        'category': name,
    };
    const url = 'admin/epps/categories/update/' + idEppsCategories;
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

async function deleteEppsCategories({ idEppsCategories }) {
    const url = 'admin/epps/categories/delete/' + idEppsCategories;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'DELETE', url: url });
}


const MyEpps = {
    categories, epps, createEpps, createEppsCategories, updateEpps, deleteEpps, updateEppsCategories, deleteEppsCategories
}

export default MyEpps;