import * as React from 'react';
import SubtitleText from '../components/text/subtitleText';
import TitleText from '../components/text/titleText';
import Container from '@mui/material/Container';
import DocCard from "../components/cards/docCard";
import Link from '@mui/material/Link';
import SideNav from "../components/sidenav/sideNav";
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import BannerButtonControla from '../components/cards/bannerButtonControla';
import CardControla from '../components/cards/cardControla';
import BannerControla from '../components/cards/bannerControla';

const colorTitle = "#9191B0";

function Index() {
    return (
        <Box sx={{ display: "flex" }}>
            <SideNav />

            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container>
                        <Grid item xs={6} sm={5} md={6} lg={6} xl={12}>
                            <TitleText text={"Comité"} color={colorTitle}></TitleText>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <BannerControla
                                text={'Comité vista general'}
                                image={"/assets/images/committee/bannerGeneral.png"}
                            />

                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={3} sx={{ mt: 3 }}>
                            <CardControla
                                url={"/committee/process"}
                                text={"Proceso de elecciones (Trabajadores)"}
                                img={"/assets/images/committee/bannerVotaciones.png"}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sx={{ mt: 3 }}>
                            <CardControla
                                url={"/committee/electionProcess"}
                                text={"Proceso de elecciones (Configuración)"}
                                img={"/assets/images/committee/bannerVotaciones.png"}
                                roles={[1, 3, 5, 7, 15]}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sx={{ mt: 3 }}>
                            <CardControla
                                url={"/committee/current"}
                                text={"Comité actual"}
                                img={"/assets/images/committee/bannerActual.png"}
                                roles={[1, 3, 5, 7, 15]}
                            />
                        </Grid>
                        <Grid item xs={12} md={3} sx={{ mt: 3 }}>
                            <CardControla
                                url={"/committee/history"}
                                text={"Histórico"}
                                img={"/assets/images/committee/bannerHistorico.png"}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
}

export default Index;
