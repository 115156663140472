import {
  Box,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { React, useState } from "react";
import ButtonControla from "../buttons/buttonController";
import { Add } from "@mui/icons-material";

function SearchUsersControla({ rowsUser = [], handleUsersActionButton = alert }) {


  const [rowsUserSearch, setRowsUserSearch] = useState([]);

  const onSearchValueChange = (event) => {
    if (event.target.value !== "") {
      let expresion = new RegExp(`${event.target.value}.*`, "i");
      let usersSearch = rowsUser.filter((x) => expresion.test(x.first_name) || expresion.test(x.document) || expresion.test(x.last_name));
      setRowsUserSearch(usersSearch);
    } else {
      setRowsUserSearch([]);
    }
  };

  return (
    <Box>
      <InputLabel
        sx={{
          padding: "8px",
          fontSize: "18px",
          color: "#305AD9",
          fontWeight: "bold",
        }}
      >
        {" "}
        Buscar usuario:{" "}
      </InputLabel>
      <Stack direction="row">
        {/* <Select
          size="small"
          defaultValue={1}
          onChange={handleChangesSelect}
          sx={{
            width: "150px",
            height: "42px",
            borderTopRightRadius: "0",
            borderBottomRightRadius: "0",
          }}
          displayEmpty
        >
          <MenuItem value={1}>Documento de identidad</MenuItem>
          <MenuItem value={2}>Nombres</MenuItem>
          <MenuItem value={3}>Apellidos</MenuItem>
        </Select> */}

        <InputBase
          type="text"
          placeholder="Buscar por nombre, apellido o documento"
          onChange={onSearchValueChange}
          sx={{
            fontFamily: "Roboto",
            width: "100%",
            padding: "4px 10px",
            border: "1px solid transparent",
            backgroundColor: "#F5F5F5",
            color: "#305AD9",
            borderRadius: "8px",
            "&.Mui-focused": {
              border: "1px solid blue",
            },
          }}
        />
      </Stack>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                sx={{
                  color: "#305ad9",
                  fontSize: "16px",
                  fontWeight: 'bold'
                }}
              >
                Nombre y Apellido
              </TableCell>
              <TableCell
                sx={{
                  color: "#305ad9",
                  fontSize: "16px",
                  fontWeight: 'bold'
                }}
              >
                Documento de identidad
              </TableCell>
              <TableCell
              ></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {rowsUserSearch.map((row) => (
              <TableRow
                key={row.id_users}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                  padding: "4px",
                }}
              >
                <TableCell>{row.first_name + " " + row.last_name}</TableCell>
                <TableCell>{row.document}</TableCell>
                <TableCell>
                  <ButtonControla
                    iconButton={<Add sx={{ color: "white" }} />}
                    backgroundColor={"#169073"}
                    backgroundColorHover={"#1BAA88"}
                    textButton={"Agregar"}
                    functionOnClick={() => {
                      handleUsersActionButton(row.id_users);
                    }}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default SearchUsersControla;
