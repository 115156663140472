import { React, useState, useEffect, useRef } from 'react';

import Box from '@mui/material/Box';
import { Grid, Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Add, BorderColorOutlined, Delete } from '@mui/icons-material';
import SubtitleText from '../../components/text/subtitleText';
import Container from '@mui/material/Container';
import SideNav from "../../components/sidenav/sideNav";
import DocBanner from '../../components/cards/docBanner';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import InputControlaVertical from '../../components/textfields/inputControlaVertical';
import ButtonIconControla from '../../components/buttons/buttonIconControla';
import MyBaseline from '../../api/MyBaseline';
import MyCampus from '../../api/MyCampus';
import ButtonControla from '../../components/buttons/buttonController';
import LoadingControla from '../../components/load/loadingControla';

const colorTitle = "#9191B0";

function Index() {

    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState('');
    const [typeAlert, setTypeAlert] = useState('success');

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert('');
        setTypeAlert('success');
    }

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    }

    const handleCloseAlert = () => {
        setOpenAlert(false);
    }

    const [rowsCampus, setRowsCampus] = useState([]);

    const handleGetCampus = async () => {
        const response = await MyCampus.campus();

        if (response.success === false) {
            // setAlertMessage(response.message);
            // setOpenLoadView(false);
        } else {
            setRowsCampus(response);
        }
        return response;
    };

    useEffect(() => {
        handleGetCampus();
    }, []);

    const [idCampus, setIdCampus] = useState(null);

    const [name, setName] = useState(null);
    const [address, setAddress] = useState(null);

    const [openCreateCampus, setOpenCreateCampus] = useState(false);

    const handleClickOpenCreateCampus = () => {
        setOpenCreateCampus(true);
    };

    const handleCloseCreateCampus = () => {
        setOpenCreateCampus(false);
    };

    const [openEditCampus, setOpenEditCampus] = useState(false);

    const handleClickOpenEditCampus = (idCampus, name, address) => {
        setIdCampus(idCampus);
        setName(name);
        setAddress(address);
        setOpenEditCampus(true);
    };

    const handleCloseEditCampus = () => {
        setOpenEditCampus(false);
    };

    // const [openDeleteCampus, setOpenDeleteCampus] = useState(false);

    // const handleClickOpenDeleteCampus = (idCampus) => {
    //     setIdCampus(idCampus);
    //     setOpenDeleteCampus(true);
    // };

    // const handleCloseDeleteCampus = () => {
    //     setOpenDeleteCampus(false);
    // };


    const handleStoreCampus = async () => {
        handleOpenLoading();
        const response = await MyCampus.storeCampus({ name: name, address: address });

        if (response.success === false) {
            handleOpenAlert('error', 'Error');
        } else {
            handleGetCampus();
            handleCloseCreateCampus();
            handleOpenAlert(response.msg, 'success');
        }
        return response;
    };

    const handleUpdateCampus = async () => {
        handleOpenLoading();
        const response = await MyCampus.updateCampus({ idCampus: idCampus, name: name, address: address });

        if (response.success === false) {
            handleOpenAlert('error', 'Error');
        } else {
            handleGetCampus();
            handleCloseEditCampus();
            handleOpenAlert(response.msg, 'success');
        }
        return response;
    };

    // const handleDeleteCampus = async () => {
    //     const response = await MyBaseline.deleteCampus({ idCampus: idCampus });

    //     if (response.success === false) {
    //     } else {
    //         handleGetCampus();
    //         handleCloseDeleteCampus();

    //     }
    //     return response;
    // };



    return (

        <Box sx={{ display: "flex", height: '100%' }}>
            <SideNav />
            <LoadingControla openLoading={openLoading} openAlert={openAlert} handleCloseAlert={handleCloseAlert} messageAlert={messageAlert} typeAlert={typeAlert} />
            <Container>

                <Box sx={{ flexGrow: 1 }}>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <SubtitleText text={"Configuracion"} color={colorTitle} ></SubtitleText>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <DocBanner color2='#BC6FEC' color1='#E6BEFF' text='Sedes' image={""} />
                        </Grid>
                    </Grid>

                    <Stack direction="row" spacing={1} sx={{ mt: 2 }} >
                        <ButtonControla
                            iconButton={<Add sx={{ color: "white" }} />}
                            backgroundColor={"#169073"}
                            backgroundColorHover={"#1BAA88"}
                            textButton={"Agregar sedes"}
                            functionOnClick={handleClickOpenCreateCampus}
                        />
                        {/* <Button
                            variant="contained"
                            onClick={() => handleClickOpenCreateCampus()}
                        >Agregar sedes
                        </Button> */}
                    </Stack>


                    <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Paper sx={{ overflow: 'hidden' }}>
                                <TableContainer sx={{ maxHeight: 400 }}>
                                    <Table stickyHeader sx={{ width: '100%', minWidth: '700px' }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                {/* <TableCell>Tipos de lineamientos</TableCell> */}
                                                <TableCell>Sede</TableCell>
                                                <TableCell>Dirección</TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody   >
                                            {rowsCampus
                                                .map((rowsCampus) => (
                                                    <TableRow hover
                                                        key={rowsCampus.id_campus}
                                                    >
                                                        <TableCell>
                                                            {rowsCampus.name}
                                                        </TableCell>
                                                        <TableCell>
                                                            {rowsCampus.address}
                                                        </TableCell>
                                                        <TableCell align="right">
                                                            <Stack
                                                                direction="row"
                                                                justifyContent="end"
                                                                alignItems="center"
                                                                spacing={1}
                                                            >
                                                                <ButtonIconControla
                                                                    icon={
                                                                        <BorderColorOutlined
                                                                            sx={{ color: "white" }}
                                                                        />
                                                                    }
                                                                    backgroundColor={"#2D9CDB"}
                                                                    backgroundColorHover={"#33AEF4"}
                                                                    textTooltip={"Editar"}
                                                                    functionOnClick={() =>
                                                                        handleClickOpenEditCampus(rowsCampus.id_campus, rowsCampus.name, rowsCampus.address)
                                                                    }
                                                                />
                                                                {/* <ButtonIconControla icon={<BorderColorOutlined sx={{ color: 'white' }} />} backgroundColor={'#169073'} textTooltip='Editar' functionOnClick={() => handleClickOpenEditCampus(rowsCampus.id_campus, rowsCampus.name, rowsCampus.address)} /> */}
                                                                {/* <ButtonIconControla icon={<Delete sx={{ color: 'white' }} />} backgroundColor={'#EB5757'} textTooltip='Eliminar' functionOnClick={() => handleClickOpenDeleteCampus(rowsCampus.id_campus)} /> */}
                                                            </Stack>
                                                        </TableCell>

                                                    </TableRow>
                                                ))}
                                        </TableBody>

                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Grid>
                    </Grid>

                    <Dialog open={openCreateCampus} onClose={handleCloseCreateCampus} maxWidth='sm' fullWidth={true} sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}>
                        <DialogTitle sx={{
                            color: '#1638F2', fontWeight: 'bold',
                        }}>Crear sede</DialogTitle>
                        <DialogContent>
                            <InputControlaVertical text={'Nombre:'} inputType={'text'} inputValue={setName} modalType={true} />
                            <InputControlaVertical text={'Dirección:'} inputType={'text'} inputValue={setAddress} modalType={true} />

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseCreateCampus}>Cancelar</Button>
                            <Button onClick={handleStoreCampus}>Crear</Button>
                        </DialogActions>
                    </Dialog>

                    <Dialog open={openEditCampus} onClose={handleCloseEditCampus} maxWidth='sm' fullWidth={true} sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}>
                        <DialogTitle sx={{
                            color: '#1638F2', fontWeight: 'bold',
                        }}>Editar sede</DialogTitle>
                        <DialogContent>
                            <InputControlaVertical text={'Nombre:'} inputType={'text'} value={name} inputValue={setName} modalType={true} />
                            <InputControlaVertical text={'Dirección:'} inputType={'text'} value={address} inputValue={setAddress} modalType={true} />


                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseEditCampus}>Cancelar</Button>
                            <Button onClick={handleUpdateCampus}>Actualizar</Button>
                        </DialogActions>
                    </Dialog>

                    {/* <Dialog open={openDeleteCampus} onClose={handleCloseDeleteCampus} maxWidth='sm' fullWidth={true}>
                        <DialogTitle sx={{
                            color: '#1638F2', fontWeight: 'bold',
                        }}>Eliminar lineamiento</DialogTitle>
                        <DialogContent>
                            <Typography >
                                Si eliminas esta sede.
                            </Typography>
                            <Typography >
                                ¿Deseas continuar?.
                            </Typography>

                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseDeleteCampus}>Cancelar</Button>
                            <Button onClick={handleDeleteCampus}>Si, eliminar</Button>
                        </DialogActions>
                    </Dialog> */}

                </Box>
            </Container >


        </Box >




    );

}

export default Index;

