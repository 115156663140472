import { Card, Typography } from "@mui/material";
import React from "react";

function DocBanner({ color1, color2, image, text }) {
  return (
    <Card sx={{
      width: '100%',
      height: '154px',
      borderRadius: '16px',
      background: ((image !== undefined && image !== '') ? 'linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(' + image + ') no-repeat center' : `linear-gradient(to top, ${color1}, ${color2})`),
      backgroundSize: '100%',
      opacity: '0.75',
      color: 'white',
      padding: '20px',
      position: 'relative',
      fontSize: '90%'
    }}>
      <Typography gutterBottom variant="h4" component="span" sx={{ fontWeight: 'bold', position: 'absolute', bottom: '0' }}>
        {text}
      </Typography>

    </Card >
  );
}

export default DocBanner;