import MyConfigurationApi from "./MyConfigurationApi";
import MyLocalStorage from "./MyLocalStorage";

function notificationsUsers({ view }) {
    const url = 'notifications/users/get?view=' + view;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

const MyNotifications = {
    notificationsUsers
}

export default MyNotifications;
