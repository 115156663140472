import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function ats({ year }) {
    const url = 'ats/get?year=' + year;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

async function createAts({ name, code, model, document_url }) {
    if (document_url !== null && document_url !== undefined) {
        const current = new Date();
        const year = current.getFullYear();
        const month = current.getMonth() + 1;
        const day = current.getDate();
        document_url = await uploadFileCompanies(document_url, 'documentation/documents/procedures/ats/' + year + '/' + month + '/' + day + '/');
    }
    const url = 'ats/store';
    const data = {
        'name': name,
        'code': code,
        'model': model,
        'document_url': document_url
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'POST', url: url });
}

function editAts({ id_ats }) {
    const url = 'ats/edit/' + id_ats;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

async function updateAts({ id_ats, name, code, area, place, start_time, end_time, description, tools, procedure, observation, document_url }) {
    if (document_url !== null && document_url !== undefined) {
        const current = new Date();
        const year = current.getFullYear();
        const month = current.getMonth() + 1;
        const day = current.getDate();
        document_url = await uploadFileCompanies(document_url, 'documentation/documents/procedures/ats/' + year + '/' + month + '/' + day + '/');
    }
    const url = 'ats/update/' + id_ats;
    const data = {
        'name': name,
        'code': code,
        'area': area,
        'place': place,
        'start_time': start_time,
        'end_time': end_time,
        'description': description,
        'tools': tools,
        'procedure': procedure,
        'observation': observation,
        'document_url': document_url
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

function deleteAts({ id_ats }) {
    const url = 'ats/delete/' + id_ats;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'DELETE', url: url });
}

async function storeAtsModel({ id_ats }) {
    const url = 'ats/store/ats/model/' + id_ats;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'POST', url: url });
}

async function updateStatusesAts({ id_ats, id_ats_statuses }) {
    const url = 'ats/update/statuses/' + id_ats;
    const data = {
        'id_ats_statuses': id_ats_statuses
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

async function updateArchiveAts({ id_ats, archived }) {
    const url = 'ats/update/archived/' + id_ats;
    const data = {
        'archived': archived
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}


function eppsAtsGet({ id_ats }) {
    const url = 'ats/epps/get/' + id_ats;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function eppsNoAtsGet({ id_ats }) {
    const url = 'ats/epps/get/epps/' + id_ats;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function eppsStore({ id_ats, id_epps }) {
    const url = 'ats/epps/store/' + id_ats;
    const data = {
        'id_epps': id_epps,
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'POST', url: url });
}

function eppsDelete({ id_ats_epps }) {
    const url = 'ats/epps/delete/' + id_ats_epps;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'DELETE', url: url });
}

function responsibleNoAtsGet({ id_ats }) {
    const url = 'ats/responsible/get/users/' + id_ats;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function responsibleAtsGet({ id_ats }) {
    const url = 'ats/responsible/get/' + id_ats;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function createAtsResponsible({ id_ats, id_users }) {
    const url = 'ats/responsible/store/' + id_ats;
    const data = {
        'id_users': id_users
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'POST', url: url });
}

function tasksNoAtsGet({ id_ats }) {
    const url = 'ats/tasks/get/users/' + id_ats;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}


function atsTasksGet({ id_ats }) {
    const url = 'ats/tasks/get/' + id_ats;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function atsEppsGet({ id_ats }) {
    const url = 'ats/epps/get/' + id_ats;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function deleteAtsResponsible({ id_ats_responsible }) {
    const url = 'ats/responsible/delete/' + id_ats_responsible;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'DELETE', url: url });
}

function responsibleAtsUpdateFirmStart({ id_ats_responsible }) {
    const url = 'ats/responsible/update/firm/start/' + id_ats_responsible;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'PUT', url: url });
}

function responsibleAtsUpdateFirmEnd({ id_ats_responsible }) {
    const url = 'ats/responsible/update/firm/end/' + id_ats_responsible;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'PUT', url: url });
}

function createAtsTasks({ id_ats, id_users, steps, dangers, risks, measures }) {
    const url = 'ats/tasks/store/' + id_ats;
    const data = {
        'id_users': id_users,
        'steps': steps,
        'dangers': dangers,
        'risks': risks,
        'measures': measures
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'POST', url: url });
}

function updateAtsTasks({ id_ats_tasks, steps, dangers, risks, measures }) {
    const url = 'ats/tasks/update/' + id_ats_tasks;
    const data = {
        'steps': steps,
        'dangers': dangers,
        'risks': risks,
        'measures': measures
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

function tasksAtsDelete({ id_ats_tasks }) {
    const url = 'ats/tasks/delete/' + id_ats_tasks;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'DELETE', url: url });
}

function authorizationsNoAtsGet({ id_ats }) {
    const url = 'ats/authorizations/get/users/' + id_ats;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function authorizationsAtsGet({ id_ats }) {
    const url = 'ats/authorizations/get/' + id_ats;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function authorizationsAtsStore({ id_ats, id_users }) {
    const url = 'ats/authorizations/store/' + id_ats;
    const data = {
        'id_users': id_users
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'POST', url: url });
}

function authorizationsAtsDelete({ id_ats_authorizations }) {
    const url = 'ats/authorizations/delete/' + id_ats_authorizations;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'DELETE', url: url });
}

function authorizationsAtsUpdateFirm({ id_ats_authorizations }) {
    const url = 'ats/responsible/update/firm/' + id_ats_authorizations;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'PUT', url: url });
}


function generatePDF({ id_ats }) {
    const url = 'ats/update/generate/pdf/' + id_ats;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'PUT', url: url });
}


const MyAts = {
    ats, createAts, editAts, updateAts, storeAtsModel, deleteAts, updateStatusesAts, updateArchiveAts, eppsAtsGet, eppsNoAtsGet, responsibleNoAtsGet, responsibleAtsGet, atsTasksGet, atsEppsGet, eppsStore, eppsDelete, createAtsResponsible, deleteAtsResponsible, tasksNoAtsGet, createAtsTasks, updateAtsTasks, authorizationsNoAtsGet, authorizationsAtsGet, authorizationsAtsStore, authorizationsAtsDelete, tasksAtsDelete, responsibleAtsUpdateFirmStart, responsibleAtsUpdateFirmEnd, authorizationsAtsUpdateFirm, generatePDF
}

export default MyAts;
