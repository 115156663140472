import MyConfigurationApi from "./MyConfigurationApi";

function getRegistersStatisticalData() {
  const url = "registers_general_statistical_data";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function createRegistersStatisticalData({ code }) {
    const url = "registers_general_statistical_data";
    const data = {
      code: code
    };
    return MyConfigurationApi.connectFormApi({
      dataJson: data,
      method: "POST",
      url: url,
    });
  }

  function editRegistersStatisticalData({ idRegistersStatisticalData }) {
    const url = "registers_general_statistical_data/" + idRegistersStatisticalData;
    return MyConfigurationApi.connectFormApi({
      dataJson: null,
      method: "GET",
      url: url,
    });
  }

  async function updateRegistersStatisticalData({idRegistersStatisticalData, code }) {
    const url = "registers_general_induction_tranning/" + idRegistersStatisticalData;
    const data = {
      "code": code      
    };
    return MyConfigurationApi.connectFormApi({
      dataJson: data,
      method: "PUT",
      url: url,
    });
  }

  
// TRABAJADORES RESPONSABLES
//get de trabajadores responsables
function getResponsibleWorkers({ idRegistersStatisticalData }) {
  const url = idRegistersStatisticalData +"/responsible";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

// // crear trabajadores responsables
async function createResponsibleWorkers({
  idRegistersStatisticalData,
  id_users
}) {
  const url = idRegistersStatisticalData +"/responsible";
  const data = {
    id_users: id_users,
    firm: 0,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}
  
// // // // mandar para la firma
async function updateResponsibleWorkers({idResponsible, id_users}) {  
  const url = "responsible/detail/" + idResponsible;

  const data = {  
    "id_users": id_users,
    "firm": 1,
    "firm_date": null
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

// // //   // eliminar  
  async function deleteResponsibleWorker({ idResponsible }) {
    const url ="responsible/detail/"+ idResponsible;
    return MyConfigurationApi.connectFormApi({
      dataJson: null,
      method: "DELETE",
      url: url,
    });
  }


//   // cierre de registro
  async function updateRegistersStatusClose({idRegistersStatisticalData}) {  
      const url = idRegistersStatisticalData+"/status/end";   
      return MyConfigurationApi.connectFormApi({
        dataJson: null,
        method: "PUT",
        url: url,
      });
    }


const MyStatisticalData = {
    getRegistersStatisticalData,
    createRegistersStatisticalData,
    editRegistersStatisticalData,
    updateRegistersStatisticalData,

    getResponsibleWorkers,
    createResponsibleWorkers,
    deleteResponsibleWorker,
    updateResponsibleWorkers,

    updateRegistersStatusClose

};
export default MyStatisticalData;
