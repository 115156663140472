import { Button } from '@mui/material'
import React from 'react'

function BlueButton({text, onclick}) {
  return (
    <div>
      <Button 
      variant= "contained"
      sx={{ 
        textTransform: 'none',
        backgroundColor: '#599CFF',
        borderRadius: 3
      }}
      disableElevation
      onClick={onclick}
      >
        {text}
      </Button>
    </div>
  )
}

export default BlueButton