import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function getLegalMatrix() {
  const url = "document_legal_matrix";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function createLegalMatrix({name, file}) {
  const current = new Date();
  const year = current.getFullYear();
  const month = current.getMonth() + 1;
  const day = current.getDate();

  if (file !== null && file !== undefined) {
    file = await uploadFileCompanies(
      file,
      "documentation/legalmatrix/" +
        year +
        "/" +
        month +
        "/" +
        day +
        "/"
    );
  }

  const url = "document_legal_matrix";
  const data = {
    name: name,
    file: file
  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

// eliminar
async function deleteLegalMatrix({ idLegalMatrix }) {
  const url ="document_legal_matrix/"+ idLegalMatrix;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "DELETE",
    url: url,
  });
}
const MyLegalMatrix = {
    getLegalMatrix,
    createLegalMatrix,
    deleteLegalMatrix

};
export default MyLegalMatrix;