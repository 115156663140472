import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function genders() {
    const url = 'users/genders/get';
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function documentsTypes() {
    const url = 'users/documents/types/get';
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function positions() {
    const url = 'users/positions/get';
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function jobTypes() {
    const url = 'users/job/types/get';
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function users() {
    const url = 'users/get';
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

async function storeUsers({ first_name, last_name, id_genders, phone, /*address, department,*/ id_documents_types, document, birthday, email, /*id_educations_levels */id_positions, /*code,*/ disability, sctr, unionized, /*id_countries, id_occupations, id_labor_regimes, id_contracts_types,*/ id_job_types, /*id_occupational_categories, id_regime_categories, id_regime_types,*/ start_date, end_date, id_health_entity, /*active,*/ firm_url
}) {
    if (firm_url !== null && firm_url !== undefined) {
        firm_url = await uploadFileCompanies(firm_url, 'users/firms/');
    }
    const data = {
        'first_name': first_name,
        'last_name': last_name,
        'id_users_genders': id_genders,
        'phone': phone,
        // 'address': address,
        // 'department': department,
        'id_users_documents_types': id_documents_types,
        'document': document,
        'birthday': birthday,
        'email': email,
        // 'id_educations_levels': id_educations_levels,
        'id_positions': id_positions,
        // 'code': code,
        'disability': disability,
        'sctr': sctr,
        'unionized': unionized,
        // 'id_countries': id_countries,
        // 'id_occupations': id_occupations,
        // 'id_labor_regimes': id_labor_regimes,
        // 'id_contracts_types': id_contracts_types,
        'id_job_types': id_job_types,
        // 'id_occupational_categories': id_occupational_categories,
        // 'id_regime_categories': id_regime_categories,
        // 'id_regime_types': id_regime_types,
        'start_date': start_date,
        'end_date': end_date,
        'id_health_entity': id_health_entity,
        // 'active': active,
        'firm_url': firm_url
    };
    const url = 'users/store';
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'POST', url: url });
}

function editUsers({ idUsers }) {
    const url = 'users/edit/' + idUsers;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}


async function updateUsers({ idUsers, first_name, last_name, id_genders, phone, /*address, department,*/ id_documents_types, document, birthday, email, /*id_educations_levels */id_positions, /*code,*/ disability, sctr, unionized, id_campus, /*id_countries, id_occupations, id_labor_regimes, id_contracts_types,*/ id_job_types, /*id_occupational_categories, id_regime_categories, id_regime_types,*/ start_date, end_date, id_health_entity, /*active,*/ firm_url
}) {
    if (firm_url !== null && firm_url !== undefined) {
        firm_url = await uploadFileCompanies(firm_url, 'users/firms/');
    }
    const data = {
        'first_name': first_name,
        'last_name': last_name,
        'id_users_genders': id_genders,
        'phone': phone,
        // 'address': address,
        // 'department': department,
        'id_users_documents_types': id_documents_types,
        'document': document,
        'birthday': birthday,
        'email': email,
        // 'id_educations_levels': id_educations_levels,
        'id_positions': id_positions,
        // 'code': code,
        'disability': disability,
        'sctr': sctr,
        'unionized': unionized,
        'id_campus': id_campus,
        // 'id_countries': id_countries,
        // 'id_occupations': id_occupations,
        // 'id_labor_regimes': id_labor_regimes,
        // 'id_contracts_types': id_contracts_types,
        'id_job_types': id_job_types,
        // 'id_occupational_categories': id_occupational_categories,
        // 'id_regime_categories': id_regime_categories,
        // 'id_regime_types': id_regime_types,
        'start_date': start_date,
        'end_date': end_date,
        'id_health_entity': id_health_entity,
        // 'active': active,
        'firm_url': firm_url
    };
    const url = 'users/update/' + idUsers;
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}


function updateActiveUsers({ idUsers, active }) {
    const data = {
        'active': active
    };
    const url = 'users/update/active/' + idUsers;
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}



function usersRolesUsers({ idUsers }) {
    const url = 'users/roles/get/' + idUsers;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function usersRoles({ idUsers }) {
    const url = 'users/roles/users/get/' + idUsers;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function storeUsersRoles({ idUsers, idUsersRoles }) {
    const url = 'users/roles/store/' + idUsers;
    const data = {
        'id_users_roles': idUsersRoles
    }
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'POST', url: url });
}

function deleteUsersRoles({ idUsersRelsRoles }) {
    const url = 'users/roles/delete/' + idUsersRelsRoles;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'DELETE', url: url });
}


function usersDirection() {
    const url = 'user/direction';
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function usersTrust() {
    const url = 'user/trust';
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function usersNormal() {
    const url = 'user/normal';
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

const MyUsers = {
    genders, documentsTypes, positions, jobTypes, usersRolesUsers, users, storeUsers, editUsers, updateUsers, updateActiveUsers, usersRoles, storeUsersRoles, deleteUsersRoles, usersDirection, usersTrust, usersNormal
}

export default MyUsers;