import MyConfigurationApi from "./MyConfigurationApi";
import MyLocalStorage from "./MyLocalStorage";

function login({ email, password }) {
    const data = {
        'email': email,
        'password': password
    };
    return MyConfigurationApi.connectLoginApi({ dataJson: data, method: 'POST', url: 'login' });

}

function sessionRefresh() {
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'POST', url: 'refresh' });
}

function sessionValidate() {
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'POST', url: 'me' });
}

async function sessionLogout() {
    await MyConfigurationApi.connectApi({ dataJson: null, method: 'POST', url: 'logout' });
    MyLocalStorage.clearLocal();
}

const MyLogin = {
    login, sessionValidate, sessionRefresh, sessionLogout
}

export default MyLogin;
