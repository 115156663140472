import { React, useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import { Button, Card, CardContent, CardMedia, Dialog, DialogActions, DialogContent, Grid, Modal, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { styled } from '@mui/system';
import { Add, BorderColorOutlined, DeleteForever, Edit, HistoryEduOutlined, PictureAsPdf, Print, Search } from '@mui/icons-material';
import SideNav from '../../../../components/sidenav/sideNav';
import SubtitleText from '../../../../components/text/subtitleText';
import TitleText from '../../../../components/text/titleText';
import InputControlaVertical from '../../../../components/textfields/inputControlaVertical';
import MyPetar from '../../../../api/MyPetar';
import MyUsers from '../../../../api/MyUsers';
import DialogTitleControla from '../../../../components/dialog/dialogTitleControla';
import SearchUsersControla from '../../../../components/search/searchUsersControla';
import ButtonControla from '../../../../components/buttons/buttonController';
import ButtonIconControla from '../../../../components/buttons/buttonIconControla';
import MyConfigurationApi from '../../../../api/MyConfigurationApi';
import LoadingControla from '../../../../components/load/loadingControla';
import DialogConfirmControla from '../../../../components/dialog/dialogConfirmControla';
import SearchEppsControla from '../../../../components/search/searchEppsControla';
import CompanyLogoControla from '../../../../components/images/companyLogoControla';

const grey = "#9191B0";
const blue = "#034AFF";
const lilac = "#D1D1E9";
const skyblue = "#2D9CDB";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    maxWidth: 800,
    overflow: 'visible',
    border: '1px solid #000',
    backgroundColor: 'background.paper',
    borderRadius: '16px',
    boxShadow: 24,
    p: 4,
};

const StyledPrintButton = styled(Button)({
    textTransform: 'none',
    borderRadius: 14,
    backgroundColor: "#F2F2F2",
    color: blue,
    width: "100%"
});

const StyledCancelButton = styled(Button)({
    textTransform: 'none',
    borderRadius: 14,
    backgroundColor: lilac,
    color: 'black',
    width: "100%"
});

const StyledAddButton = styled(Button)({
    textTransform: 'none',
    backgroundColor: skyblue,
    borderRadius: 16,

});


const StyledTypography = styled(Typography)({
    color: "#305AD9",
    fontSize: 16,
    fontWeight: 600,
    gap: "2%",
    padding: "0.5em"
})

function EditPetar() {



    const [idUserLogged, setIdUserLogged] = useState(MyConfigurationApi.userData().id_users);

    const params = new URLSearchParams(window.location.search);
    const id_petar = params.get('id_petar');
    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [area, setArea] = useState('');
    const [place, setPlace] = useState('');
    const [hourStart, setHourStart] = useState('');
    const [hourFinish, setHourFinish] = useState('');
    const [description, setDescription] = useState('');
    const [tools, setTools] = useState('');
    const [procedure, setProcedure] = useState('');
    const [pdfGenerateUrl, setPdfGenerateUrl] = useState('');

    const [userNameFirstSearch, setUserNameFirstSearch] = useState('');
    const [userLastNameSearch, setUserLastNameSearch] = useState('');
    const [userDocumentSearch, setUserDocumentSearch] = useState('');

    const [rowsUserNormal, setRowsUserNormal] = useState([]);

    const [openModalCreateResponsible, setOpenModalCreateResponsible] = useState(false);
    const [openModalCreateSupervisor, setOpenModalCreateSupervisor] = useState(false);
    const [openModalCreateTasks, setOpenModalCreateTasks] = useState(false);
    const [openModalCreateAuthorization, setOpenModalCreateAuthorization] = useState(false);
    const [rowsResponsible, setRowsResponsible] = useState([]);

    const [stepsTasks, setStepsTasks] = useState('');
    const [dangersTasks, setDangersTasks] = useState('');
    const [risksTasks, setRisksTasks] = useState('');
    const [measuresTasks, setMeasuresTasks] = useState('');
    const [observation, setObservation] = useState('');

    //////readonly control
    const [readOnlyStatus, setReadOnlyStatus] = useState(false);
    const [idStatuses, setIdStatuses] = useState(false);
    //////////////////////

    //const [rowsEpps, setRowsEpps] = useState([]);
    const [rowsAuthorizations, setRowsAuthorizations] = useState([]);
    /////////////////////////

    const [rowsUsersNoResponsible, setRowsUsersNoResponsible] = useState([]);
    ////////////////////////
    const [rowsUsersNoAuthorization, setRowsUsersNoAuthorization] = useState([]);

    const handleOpenModalCreateTasks = () => setOpenModalCreateTasks(true);
    const handleCloseModalCreateTasks = () => setOpenModalCreateTasks(false);

    const handleOpenModalCreateResponsible = () => {
        handleUsersNoResponsible();
        setOpenModalCreateResponsible(true);
    };
    const handleCloseModalCreateResponsible = () => setOpenModalCreateResponsible(false);

    const handleOpenModalCreateSupervisor = () => setOpenModalCreateSupervisor(true);
    const handleCloseModalCreateSupervisor = () => setOpenModalCreateSupervisor(false);

    const handleOpenModalCreateAuthorization = () => {
        handleUsersNoAuthorizations();
        setOpenModalCreateAuthorization(true);
    }
    const handleCloseModalCreateAuthorization = () => setOpenModalCreateAuthorization(false);


    const handleEditPetar = async () => {
        console.log(id_petar)
        const response = await MyPetar.editPetar({ id_petar });
        if (response.success === false) {
            alert('Error');
        } else {

            setName(response.name);
            setCode(response.code);
            setArea(response.area);
            setPlace(response.place);
            setHourStart(response.start_time === null ? '' : response.start_time);
            setHourFinish(response.end_time === null ? '' : response.end_time);
            setDescription(response.description);
            setTools(response.tools);
            setProcedure(response.procedure);
            setObservation(response.observation);
            setIdStatuses(response.id_ats_statuses);
            setPdfGenerateUrl(response.pdf_generate_url);
            if (response.id_petar_statuses !== 1 || response.archived == 1) {
                setReadOnlyStatus(true)
            }
            console.log(response);
        }
        return response;
    };

    const handleGeneratePDF = async () => {
        const response = await MyPetar.generatePDF({ id_petar });
        return response;
    }

    const handleUsersNoResponsible = async () => {
        const response = await MyPetar.petarNoResponsibleGet({ id_petar: id_petar });
        if (response.success !== false) {
            setRowsUsersNoResponsible(response);
        } else {
            alert('Error');
        }
        return response;
    }

    const handleUsersNoAuthorizations = async () => {
        const response = await MyPetar.getNoAuthorizationUsers({ idPetar: id_petar });
        if (response.success !== false) {
            setRowsUsersNoAuthorization(response);
        } else {
            alert('Error');
        }
        return response;
    }

    const handleDeletePetarResponsible = async (id_petar_responsible) => {
        const response = await MyPetar.deletePetarResponsible({ id_petar_responsible });
        if (response.success === false) {
            alert('Error');
        } else {
            handlePetarResponsible();
        }
        return response;
    }

    const handleDeletePetarAuthorization = async (id_petar_authorizations) => {
        const response = await MyPetar.deleteAuthorizations({ idPetarAuthorizations: id_petar_authorizations });
        if (response.success === false) {
            alert('Error');
        } else {
            handlePetarAuthorizations();
        }
    }

    const handlePetarResponsible = async () => {
        const response = await MyPetar.petarResponsibleGet({ id_petar });
        if (response.success === false) {
            alert('Error');
        } else {
            setRowsResponsible(response);
        }
        return response;
    };

    const handlePetarAuthorizations = async () => {
        const response = await MyPetar.getAuthorizations({ idPetar: id_petar });

        if (response.success === false) {
            alert('Error');
        } else {
            setRowsAuthorizations(response);
        }
        return response;
    };

    const handlePostCreatePetarResponsible = async (id_users) => {
        const response = await MyPetar.createPetarResponsible({ id_petar, id_users });

        if (response.success === false) {
            alert('Error');
        } else {
            handlePetarResponsible();
            handleCloseModalCreateResponsible();
        }
        return response;
    };

    const handlePostCreatePetarAuthorization = async (id_users) => {
        const response = await MyPetar.storeAuthorizations({ idPetar: id_petar, idUsers: id_users });

        if (response.success === false) {
            alert('Error');
        } else {
            handlePetarAuthorizations();
            handleCloseModalCreateAuthorization();
        }
    }

    const handleUsersNormal = async () => {
        const response = await MyUsers.usersNormal();

        if (response.success === false) {
            alert('Error');
        } else {
            setRowsUserNormal(response);
        }
        return response;
    };

    ////////////////EPP

    const [openModalEpps, setOpenModalEpps] = useState(false);
    const [rowsEpps, setRowsEpps] = useState([]);

    const [rowsEppsPetar, setRowsEppsPetar] = useState([]);

    // Pantallas de carga y alertas INICIO
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState('');
    const [typeAlert, setTypeAlert] = useState('success');

    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [titleText, setTitleText] = useState('');
    const [contentText, setContentText] = useState('');
    const [buttonTextConfirm, setButtonTextConfirm] = useState('');
    const [handleActionConfirm, setHandleActionConfirm] = useState();

    const handleOpenModalEpps = () => {
        handlePetarNoEpps();
        setOpenModalEpps(true);
    }

    const handleCloseModalEpps = () => {
        setOpenModalEpps(false);
    }

    const handleSelectEpps = async (idEpps) => {
        handleOpenLoading();
        const response = await MyPetar.eppsStore({ id_petar, id_epps: idEpps });

        if (response.success !== false) {
            setRowsEpps([]);
            setOpenModalEpps(false);
            handlePetarNoEpps();
            handlePetarEpps();
            handleOpenAlert(response.msg, 'success');
        } else {
            handleOpenAlert('Hubo un error', 'error');
        }
        return response;
    }

    const handleSelectDeleteEpps = async (idPetarEpps) => {
        handleOpenLoading();
        const response = await MyPetar.eppsDelete({ id_petar_epps: idPetarEpps });

        if (response.success !== false) {
            setRowsEpps([]);
            setOpenModalEpps(false);
            handlePetarNoEpps();
            handlePetarEpps();
            handleOpenAlert(response.msg, 'success');
        } else {
            handleOpenAlert('Hubo un error', 'error');
        }
        return response;
    }

    const handlePetarNoEpps = async () => {
        const response = await MyPetar.eppsNoPetarGet({ id_petar });

        if (response.success === false) {
            alert('Error');
        } else {
            setRowsEpps(response);
        }
        return response;
    };

    const handlePetarEpps = async () => {
        const response = await MyPetar.eppPetarGet({ id_petar });

        if (response.success === false) {
            alert('Error listando los epps');
        } else {
            setRowsEppsPetar(response);
        }
        return response;
    };

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert('');
        setTypeAlert('success');
    }

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    }

    const handleCloseAlert = () => {
        setOpenAlert(false);
    }

    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    }

    ///////////////EPP

    //////////////FIRMA

    const signAuthorization = async (idPetarAuthorizations) => {
        const response = await MyPetar.signAuthorization({ idPetarAuthorizations: idPetarAuthorizations });
        return handlePetarAuthorizations();
    }

    const signResponsible = async (idPetarResponsible) => {
        const response = await MyPetar.signResponsibleEnd({ idPetarResponsible: idPetarResponsible });
        return handlePetarResponsible();
    }

    const signResponsibleStart = async (idPetarResponsible) => {
        const response = await MyPetar.signResponsibleStart({ idPetarResponsible: idPetarResponsible });
        return handlePetarResponsible();
    }
    //////////////FIRMA
    useEffect(() => {
        handleEditPetar();
        handlePetarResponsible();
        //handlePetarTasks();
        //handlePetarEpps();
        handlePetarAuthorizations();
        handleUsersNormal();
        handlePetarEpps();
    }, []);

    const handlePutUpdatePetar = async () => {
        const response = await MyPetar.updatePetar({ id_petar, name, code, area, place, start_time: hourStart, end_time: hourFinish, description, observation, tools, procedure, });
        if (response.success !== false) {
            handleOpenAlert(response.msg, 'success');
        } else {
            handleOpenAlert('Hubo un error al actualizar', 'success');
        }
        return window.location.reload();
    }

    return (
        <>
            <Box sx={{ display: "flex", height: "100%" }}>

                <SideNav returnBack='/documentation/documents/procedures/petar' />

                <LoadingControla openLoading={openLoading} openAlert={openAlert} handleCloseAlert={handleCloseAlert} messageAlert={messageAlert} typeAlert={typeAlert} />

                <DialogConfirmControla openModalConfirm={openModalConfirm} handleCloseModalConfirm={handleCloseModalConfirm} titleText={titleText} contentText={contentText} buttonTextConfirm={buttonTextConfirm} handleActionConfirm={handleActionConfirm} />

                <SearchEppsControla openModalEpps={openModalEpps} handleCloseModalEpps={handleCloseModalEpps} handleClickSelectEpps={handleSelectEpps} rowsEpps={rowsEpps} />

                <Container sx={{ paddingBottom: 4 }} >
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container rowSpacing={2} columnSpacing={2} alignItems="center">
                            <Grid item md={12}>
                                <SubtitleText text={"Procedimientos"} color={grey}></SubtitleText>
                            </Grid>
                            <Grid item md={10}>
                                <TitleText text={"Permiso Escrito de trabajo de alto riesgo (PETAR) "} />
                            </Grid>
                            <Grid item xs={1} sm={1} md={2} lg={2} xl={2}>
                                <CompanyLogoControla />
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={9} xl={9}>
                                <InputControlaVertical text={'Nombre del PETAR'} inputType={'text'} inputValue={setName} modalType={false} value={name} readOnly={readOnlyStatus} />
                                {/* <StyledTypography>Nombre del ATS</StyledTypography> */}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                <InputControlaVertical text={'Código'} inputType={'text'} inputValue={setCode} modalType={false} value={code} readOnly={readOnlyStatus} />
                                {/* <StyledTextField /> */}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                <InputControlaVertical text={'Área'} inputType={'text'} inputValue={setArea} modalType={false} value={area} readOnly={readOnlyStatus} />

                                {/* <StyledTypography>Área</StyledTypography> */}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                <InputControlaVertical text={'Lugar'} inputType={'text'} inputValue={setPlace} modalType={false} value={place} readOnly={readOnlyStatus} />

                                {/* <StyledTypography>Lugar</StyledTypography> */}
                            </Grid>
                            {/* SOLO ENVIADOS*/}
                            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                <InputControlaVertical text={'Hora de Inicio'} inputType={'time'} inputValue={setHourStart} modalType={false} value={hourStart} readOnly={(idStatuses == 2) ? false : readOnlyStatus} />

                                {/* <StyledTypography>Hora de Inicio</StyledTypography> */}
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3} xl={3}>
                                <InputControlaVertical text={'Hora de Fin'} inputType={'time'} inputValue={setHourFinish} modalType={false} value={hourFinish} readOnly={(idStatuses == 2) ? false : readOnlyStatus} />
                                {/* <StyledTypography>Hora de Fin</StyledTypography> */}
                            </Grid>
                            {/* SOLO ENVIADOS*/}
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <InputControlaVertical text={'Descripción del Trabajo'} inputType={'textArea'} inputValue={setDescription} modalType={false} value={description} readOnly={readOnlyStatus} />
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                <StyledTypography sx={{
                                    padding: '8px',
                                    fontSize: '20px',
                                    color: '#305AD9',
                                    fontWeight: 'bold'
                                }}>Responsables del trabajo</StyledTypography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                                {(readOnlyStatus == false ?
                                    <ButtonControla
                                        roles={[1, 7, 8, 15]}
                                        textButton={"Agregar Responsable"}
                                        iconButton={<Add />}
                                        backgroundColor={skyblue}
                                        backgroundColorHover={"#58C0FB"}
                                        functionOnClick={() => handleOpenModalCreateResponsible()}
                                    />
                                    : '')}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                        <TableHead>
                                        </TableHead>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ color: blue }} align="center">Cargo</TableCell>
                                                <TableCell sx={{ color: blue }} align="center">Nombres</TableCell>
                                                <TableCell sx={{ color: blue }} align="center">Firma Inicio</TableCell>
                                                <TableCell sx={{ color: blue }} align="center">Firma Final</TableCell>
                                                <TableCell sx={{ color: blue }} align="center">Acciones</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rowsResponsible.map((rowsResponsible) => (
                                                <TableRow hover key={rowsResponsible.id_petar_responsible}>
                                                    <TableCell align="center">{rowsResponsible.position_name}</TableCell>
                                                    <TableCell align="center">
                                                        {rowsResponsible.first_name + ' ' + rowsResponsible.last_name}
                                                    </TableCell>
                                                    {/* <TableCell>
                                                        {rowsResponsible.document}
                                                    </TableCell> */}

                                                    <TableCell align='center'>
                                                        {rowsResponsible.firm_start === 0 ? 'No firmado' && rowsResponsible.id_users === idUserLogged ?
                                                            (<ButtonIconControla
                                                                icon={
                                                                    <HistoryEduOutlined sx={{ color: "white" }} />
                                                                }
                                                                backgroundColor={"#F1C40F"}
                                                                backgroundColorHover={"#F4D03F"}
                                                                textTooltip={"Firmar"}
                                                                functionOnClick={() => signResponsibleStart(rowsResponsible.id_petar_responsible)}
                                                            />)
                                                            : 'No firmado' : 'Firmado'}
                                                    </TableCell>
                                                    <TableCell align='center' >
                                                        {rowsResponsible.firm_end === 0 ? 'No firmado' && rowsResponsible.id_users === idUserLogged ?
                                                            (<ButtonIconControla
                                                                icon={
                                                                    <HistoryEduOutlined sx={{ color: "white" }} />
                                                                }
                                                                backgroundColor={"#F1C40F"}
                                                                backgroundColorHover={"#F4D03F"}
                                                                textTooltip={"Firmar"}
                                                                functionOnClick={() => signResponsible(rowsResponsible.id_petar_responsible)}
                                                            />)
                                                            : 'No firmado' : 'Firmado'}

                                                    </TableCell>
                                                    <TableCell align="center" >
                                                        {(readOnlyStatus == false ?
                                                            <ButtonControla
                                                                textButton={"Eliminar"}
                                                                backgroundColor={"#EB5757"}
                                                                backgroundColorHover={"#FF4040"}
                                                                iconButton={<DeleteForever />}
                                                                functionOnClick={() => handleDeletePetarResponsible(rowsResponsible.id_petar_responsible)}
                                                            >
                                                            </ButtonControla>
                                                            : '')}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>

                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8} >
                                <StyledTypography sx={{
                                    padding: '8px',
                                    fontSize: '20px',
                                    color: '#305AD9',
                                    fontWeight: 'bold'
                                }}>EPPS</StyledTypography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                                {(readOnlyStatus == false ?
                                    <ButtonControla
                                        roles={[1, 7, 8, 15]}
                                        iconButton={<Add sx={{ color: "white" }} />}
                                        backgroundColor={"#169073"}
                                        backgroundColorHover={"#1BAA88"}
                                        textButton={"Agregar EPP"}
                                        functionOnClick={handleOpenModalEpps}
                                    />
                                    : '')}
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Paper sx={{ overflow: 'hidden' }}>
                                    <Grid container spacing={2} sx={{ width: '100%', margin: 'auto' }} >
                                        {rowsEppsPetar
                                            .map((rowsEpps) => (
                                                <Grid item key={rowsEpps.id_epps} xs={12} sm={6} md={4} lg={3} xl={3} sx={{ p: 2 }}>
                                                    <Card sx={{
                                                        display: 'flex', justifyContent: 'space-between', height: 80,
                                                        "&:hover": {
                                                            cursor: 'pointer',
                                                            border: "1px solid #0F1255",
                                                            boxShadow: '0px 4px #0F1255',
                                                        },
                                                    }}
                                                    // onClick={() => { handleSelectDeleteEpps(rowsEpps.id_petar_epps) }}
                                                    >
                                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                                            <CardContent sx={{ flex: '1 0 auto' }}>
                                                                <Typography sx={{ fontSize: '14px' }}>
                                                                    {rowsEpps.epp}
                                                                </Typography>
                                                            </CardContent>
                                                        </Box>
                                                        <CardMedia
                                                            component="img"
                                                            sx={{ maxWidth: 80, marginLeft: 0, maxHeight: 80, objectFit: 'contain' }}
                                                            image={rowsEpps.photo_url}
                                                            alt="Live from space album cover"
                                                        />
                                                    </Card>
                                                </Grid>
                                            ))
                                        }
                                    </Grid>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputControlaVertical text={'Herramientas, equipo y material:'} inputType={'textArea'} inputValue={setTools} modalType={false} value={tools} readOnly={readOnlyStatus} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputControlaVertical text={'Procedimiento:'} inputType={'textArea'} inputValue={setProcedure} modalType={false} value={procedure} readOnly={readOnlyStatus} />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <InputControlaVertical text={'Observaciones:'} inputType={'textArea'} inputValue={setObservation} modalType={false} value={observation} readOnly={(idStatuses == 2) ? false : readOnlyStatus} />
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                <StyledTypography
                                    sx={{
                                        padding: '8px',
                                        fontSize: '20px',
                                        color: '#305AD9',
                                        fontWeight: 'bold'
                                    }}
                                >Autorización y supervisión</StyledTypography>
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} xl={4} textAlign="right">
                                {(readOnlyStatus == false ?
                                    <ButtonControla
                                        roles={[1, 7, 8]}
                                        iconButton={<Add />}
                                        backgroundColor={skyblue}
                                        textButton='Agregar Supervisor'
                                        backgroundColorHover={"#58C0FB"}
                                        functionOnClick={() => handleOpenModalCreateAuthorization()}
                                    />
                                    : '')}

                            </Grid>
                            <Grid item md={12} >
                                <Paper sx={{ overflow: 'hidden' }}>
                                    <TableContainer sx={{ minWidth: 650 }}>
                                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell sx={{ color: blue }} align="center">Cargo</TableCell>
                                                    <TableCell sx={{ color: blue }} align="center">Nombre</TableCell>
                                                    <TableCell sx={{ color: blue }} align="center">Firma</TableCell>
                                                    <TableCell sx={{ color: blue }} align="center">Fecha de firma</TableCell>
                                                    <TableCell sx={{ color: blue }} align="center">Acciones</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rowsAuthorizations
                                                    .map((rowsAuthorizations) => (
                                                        <TableRow hover key={rowsAuthorizations.id_petar_authorizations}>
                                                            <TableCell align="center">
                                                                {rowsAuthorizations.position_name}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {rowsAuthorizations.first_name + ' ' + rowsAuthorizations.last_name}
                                                            </TableCell>
                                                            <TableCell align='center'>
                                                                {rowsAuthorizations.firm === 0 ? 'No firmado' && rowsAuthorizations.id_users === idUserLogged ?
                                                                    (<ButtonIconControla

                                                                        icon={
                                                                            <HistoryEduOutlined sx={{ color: "white" }} />
                                                                        }
                                                                        backgroundColor={"#F1C40F"}
                                                                        backgroundColorHover={"#F4D03F"}
                                                                        textTooltip={"Firmar"}
                                                                        functionOnClick={() => signAuthorization(rowsAuthorizations.id_petar_authorizations)}
                                                                    />)
                                                                    : 'No firmado' : 'Firmado'}
                                                                {/* {((rowsAuthorizations.firm === 1) ? 'Si' : 'No')} */}
                                                            </TableCell>
                                                            <TableCell align="center" >
                                                                {rowsAuthorizations.firm_date !== null ? rowsAuthorizations.firm_date.substring(0, 10) : 'Sin fecha'}
                                                            </TableCell>
                                                            <TableCell align="center" >
                                                                {(readOnlyStatus == false ?
                                                                    <ButtonControla
                                                                        roles={[7, 8]}
                                                                        textButton={"Eliminar"}
                                                                        backgroundColor={"#EB5757"}
                                                                        backgroundColorHover={"#FF4040"}
                                                                        iconButton={<DeleteForever />}
                                                                        functionOnClick={() => handleDeletePetarAuthorization(rowsAuthorizations.id_petar_authorizations)}
                                                                    />
                                                                    : '')}
                                                            </TableCell>

                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Paper>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                <Stack direction={'row'} spacing={2} justifyContent={'center'}>
                                    <Box>
                                        <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Regresar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} url={"/documentation/documents/procedures/petar?idTabs=1"} />
                                    </Box>
                                    <Box>

                                        {/* {(idStatuses == 3) ? ((pdfGenerateUrl != null && pdfGenerateUrl != undefined && pdfGenerateUrl != '') ? */}
                                        {(readOnlyStatus == true ?
                                            <ButtonControla
                                                iconButton={<PictureAsPdf sx={{ color: "white" }} />}
                                                backgroundColor={"#F2994A"}
                                                backgroundColorHover={"#FF881E"}
                                                textButton={"Ver documento generado"}
                                                typeButton={'button'}
                                                url={pdfGenerateUrl}
                                                target='_blank'
                                            />
                                            : '')}
                                        {/* <ButtonControla
                                                iconButton={<PictureAsPdf sx={{ color: "white" }} />}
                                                backgroundColor={"#F2994A"}
                                                backgroundColorHover={"#FF881E"}
                                                textButton={"Generar PDF"}
                                                typeButton={'button'}
                                                functionOnClick={() => handleGeneratePDF}
                                            />) : '' */}
                                        {/* } */}
                                    </Box>
                                    {(readOnlyStatus == false ? <Box>
                                        <ButtonControla
                                            roles={[1, 7, 8, 9, 15]}
                                            iconButton={<Edit sx={{ color: "white" }} />}
                                            backgroundColor={"#169073"}
                                            backgroundColorHover={"#1BAA88"}
                                            textButton={"Actualizar PETAR"}
                                            typeButton={'button'}
                                            functionOnClick={handlePutUpdatePetar}
                                        />
                                    </Box> : '')}

                                </Stack>
                            </Grid>
                        </Grid>
                        <Dialog
                            open={openModalCreateResponsible}
                            onClose={handleCloseModalCreateResponsible}
                            maxWidth="sm"
                            fullWidth={true}
                            sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                        >
                            <DialogTitleControla titleText='Agregar Responsable' functionOnClose={handleCloseModalCreateResponsible} />
                            <DialogContent >
                                <SearchUsersControla rowsUser={rowsUsersNoResponsible} handleUsersActionButton={handlePostCreatePetarResponsible} />
                            </DialogContent>
                            <DialogActions>
                                <Stack direction={'row'} spacing={2}>
                                    <Box>
                                        <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cancelar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={handleCloseModalCreateResponsible} />
                                    </Box>
                                </Stack>
                            </DialogActions>
                        </Dialog>

                        <Dialog
                            open={openModalCreateAuthorization}
                            onClose={handleCloseModalCreateAuthorization}
                            maxWidth="sm"
                            fullWidth={true}
                            sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
                        >
                            <DialogTitleControla titleText='Agregar Supervisor' functionOnClose={handleCloseModalCreateAuthorization} />
                            <DialogContent >
                                <SearchUsersControla rowsUser={rowsUsersNoAuthorization} handleUsersActionButton={handlePostCreatePetarAuthorization} />
                            </DialogContent>
                            <DialogActions>
                                <Stack direction={'row'} spacing={2}>
                                    <Box>
                                        <ButtonControla backgroundColor={'#CBCBFF'} textButton={'Cancelar'} backgroundColorHover={'#CBCBFF'} typeButton={'button'} functionOnClick={handleCloseModalCreateAuthorization} />
                                    </Box>
                                </Stack>
                            </DialogActions>
                        </Dialog>

                    </Box>
                </Container >
            </Box >
        </>
    )
}

export default EditPetar