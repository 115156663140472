import * as React from "react";
import Container from "@mui/material/Container";
import SideNav from "../../components/sidenav/sideNav";
import Box from "@mui/material/Box";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
} from "@mui/material";
import "../../index.css";
import CardControla from "../../components/cards/cardControla";
import BannerControla from "../../components/cards/bannerControla";
import styled from "styled-components";
import { InfoOutlined } from "@mui/icons-material";
import SubtitleText from "../../components/text/subtitleText";
import ButtonControla from "../../components/buttons/buttonController";
import DialogTitleControla from "../../components/dialog/dialogTitleControla";

const colorTitle = "#9191B0";
const skyblue = "#2D9CDB";
const purple = "#8B8BD8";

function Index() {
  const StyledAddButton = styled(Button)({
    textTransform: "none",
    backgroundColor: skyblue,
    borderRadius: 16,
  });

  //   informacion del documento
  const StyledModalTitle = styled("h2")({
    color: purple,
  });
  const StyledModalSubtitle = styled("h3")({
    fontWeight: "bold",
  });

  const [openModalInfo, setOpenModalInfo] = React.useState(false);

  const handleOpenModalInfo = () => {
    setOpenModalInfo(true);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <SideNav returnBack="/documentation" />

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={6} md={6}>
              <SubtitleText
                text={"Documentación"}
                color={colorTitle}
              ></SubtitleText>
            </Grid>
            <Grid item xd={6} md={6} style={{ textAlign: "right" }}>
              <StyledAddButton
                variant="text"
                startIcon={<InfoOutlined />}
                disableElevation
                size="large"
                style={{
                  backgroundColor: "#305AD9",
                  borderRadius: "15px",
                  color: "white",
                  padding: "8px 25px",
                }}
                onClick={() => handleOpenModalInfo()}
              >
               ¿Qué debo saber para elaborar mis MSDS?
              </StyledAddButton>
            </Grid>
            <Grid item xs={12} md={12}>
              <BannerControla
                image="/assets/images/bannersecuritysheet.png"
                color2="#2F67BC"
                color1="#8FBCFF"
                text="Hojas de seguridad(MSDS)"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/securitysheet/explosives"}
                text={"Explosivos"}
                img={"/assets/images/banners/documents/explosives.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/securitysheet/gases"}
                text={"Gases"}
                img={"/assets/images/banners/documents/gases.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/securitysheet/flammableliquid"}
                text={"Líquidos inflamables"}
                img={"/assets/images/banners/documents/liquidosinflamables.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/securitysheet/flammablesolids"}
                text={"Sólidos inflamables"}
                img={"/assets/images/banners/documents/solidosinflamables.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/securitysheet/oxidizingsubstances"}
                text={"Sustancias oxidantes, peróxidos orgánicos"}
                img={"/assets/images/banners/documents/suatanciasoxidantes.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/securitysheet/poisonoussubstances"}
                text={"Sustancias venenosas"}
                img={"/assets/images/banners/documents/sustanciasvenenosas.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/securitysheet/radioactivematerials"}
                text={"Materiales radioactivos"}
                img={"/assets/images/banners/documents/radioactivos.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/securitysheet/corrosivesubstances"}
                text={"Sustancias corrosivas"}
                img={"/assets/images/banners/documents/corrosivos.png"}
              />
            </Grid>
            <Grid item xs={12} md={3} sx={{ mt: 3 }}>
              <CardControla
                url={"/documentation/securitysheet/miscelaneous"}
                text={"Misceláneos"}
                img={
                  "/assets/images/banners/documents/securitysheetmiselaneos.png"
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Container>

      {/* dialogo de información */}
      <Dialog
        open={openModalInfo}
        onClose={() => setOpenModalInfo(false)}
        maxWidth="lg"
        fullWidth={true}
        sx={{
          padding: "20px",
        }}
      >
        <DialogTitleControla titleText="HOJA INFORMATIVA SOBRE SUSTANCIAS PELIGROSAS - MSDS" />
        <DialogContent>
          <Grid container>
            <Grid item md={12}>
              <StyledModalTitle>1. Introducción:</StyledModalTitle>
            </Grid>
            <Grid item>
              La Hoja Informativa sobre Sustancias Peligrosas (Hojas de
              Seguridad o MSDS) es un documento que contiene información
              detallada sobre la naturaleza de una sustancia química, así como
              sus propiedades físicas y químicas, información sobre salud,
              seguridadd, fuego y riesgos de medio ambiente que la sustancia
              puede causar. A continuación, te presentamos sus principales
              características.
            </Grid>
            <Grid item md={12}>
              <StyledModalTitle>2. Consideraciones generales:</StyledModalTitle>
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle>
                ¿Quién es responsable de preparar la Hoja Informativa sobre
                Sustancias Peligrosas (MSDS)?
              </StyledModalSubtitle>
            </Grid>
            <Grid item>
              Si bien esta no es una exigencia legal, el Estándar de
              Comunicación de Riesgos de la Administración Federal de Seguridad
              y Salud Ocupacional (Federal Occupational Safety anh Health
              Administrattion - OHSA) 29 CFR 1910.1200) requiere que los
              fabricantes y distribuidores de productos químicos preparen y
              remitan la Hoja Informativa sobre Sustancias Peligrosas (MSDS) con
              el primer envío de cualquier producto químico peligroso, y el
              empleador es responsable de poner estas Hojas Informativas al
              alcance de Uds. los trabajadores.
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle>
                ¿Para quién es la Hoja Informativa sobre Sustancias Peligrosas
                (MSDS)?
              </StyledModalSubtitle>
            </Grid>
            <Grid item>
              Esta Hoja Informativa es para:
              <ol type="a">
                <li>
                  Los trabajadores que puedan estar expuestos a materiales
                  peligrosas y que deben informarse sobre los riesgos que
                  conllevan estas sustancias.
                </li>
                <li>
                  El personal de emergencia (brigada de emergencia, bomberos, y
                  otros) que son los designados para atender cualquier tipo de
                  emergencia.
                </li>
                <li>
                  Los trabajadores de las empresas contratistas que realicen su
                  trabajo con este tipo de sustancias.
                </li>
              </ol>
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle>
                ¿Qué información debe contener la Hoja Informativa sobre
                Sustancias Peligrosas (MSDS)?
              </StyledModalSubtitle>
            </Grid>
            <Grid item>
              La MSDS de contener:
              <ol type="a">
                <li>Nombre del producto.</li>
                <li>
                  Información sobre el productor o fabricante (nombre, dirección
                  y teléfono de contacto).
                </li>
                <li>Teléfono de emergencia.</li>
                <li>
                  Lista de componentes químicos peligrosos y también no
                  peligrosos.
                </li>
                <li>Características físicas o químicas.</li>
                <li>
                  Información sobre riesgos de fuego y explosión: punto de
                  combustión, límites de combustión, métodos de extinción,
                  procedimientos especiales contra el fuego, entre otros.
                </li>
                <li>
                  Información sobre reactividad: (cómo reaccionan ciertos
                  materiales cuando se mezclan o almacenan junto con otros).
                </li>
                <li>
                  Información sobre riesgos para la salud: efectos que las
                  sustancias químicas pueden causar, vías por los que las
                  sustancias químicas pueden ingresar al cuerpo, síntomas,
                  procedimientos de emergencia y primeros auxilios.{" "}
                </li>
                <li>
                  Precauciones para un manejo seguro y uso seguro: qué hacer en
                  caso de derrames, fugas; cómo deshacerse del desperdicio del
                  material químico y cómo manipular y almacenar el material de
                  forma segura.
                </li>
                <li>
                  Medidas de control: ventilación del local, equipos de
                  protección personal.
                </li>
              </ol>
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle>
                ¿Cómo se categoriza una sustancia peligrosa?
              </StyledModalSubtitle>
            </Grid>
            <Grid item>
              Las sustancias peligrosas, de acuerdo al Libro Naranja de las
              Naciones Unidas, se categorizan por el riesgo primario.
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle>
                ¿Qué simbología se usa para identificar el riesgo en las hojas
                de seguridad?
              </StyledModalSubtitle>
            </Grid>
            <Grid item>
              Se utiliza el Código de la NFPA, Asociación Nacional de Protección
              contra el Fuego (Estados Unidos) que establece un sistema de
              identificación de Riesgos, los cuales son consignados en relación
              al fuego y sus consecuencias. El pictograma consiste en un rombo
              dividido en cuatro secciones de color azul, rojo, amarillo y
              blanco, que indican el riesgo para la salud, la inflamabilidad, la
              reactividad y el riesgo principal de la sustancia identificada
              respectivamente (riesgo específico). Se numeran del 0 al 4, de
              menor a mayor, de acuerdo a la intensidad del riesgo.{" "}
            </Grid>
            <Grid>
              <a href="/assets/images/info/NFPA_SENALES.jpg" target="_blank">
                <ButtonControla
                  textButton={"Ver simbología"}
                  backgroundColor={"#169073"}
                  backgroundColorHover={"#1BAA88"}
                />
              </a>
            </Grid>
            <Grid item md={12}>
              <StyledModalTitle>3. Fiscalización</StyledModalTitle>
            </Grid>
            <Grid item md={12}>
              <StyledModalSubtitle>
                ¿Qué aspectos principales considerará la autoridad ante una
                actuación inspectiva?
              </StyledModalSubtitle>
            </Grid>
            <Grid item>
              Esta Hoja Informativa es para:
              <ol type="a">
                <li>
                  Que toda sustancia química cuente con su respectiva hoja MSDS
                  en el lugar o zona de trabajo.
                </li>
                <li>
                  Que los recipientes de las sustancias químicas estén
                  debidamente identificados (rombo de seguridad).
                </li>
                <li>
                  Que los trabajadores hayan sido debidamente capacitados
                  respecto del uso de la información de las hojas de seguridad
                  (MSDS)
                </li>
                <li>
                  Que se sigan las especificaciones respecto de la compatiblidad
                  y modo de almacenamiento de las sustancias químicas
                  establecidas en las hojas de seguridad (MSDS).
                </li>
                <li>
                  Que se cuente con un archivo central de hojas de seguridad
                  (MSDS).
                </li>
              </ol>
            </Grid>  
            <Grid item>
              Fuente: Libro Naranja de las Naciones Unidas; ICS (International Classification System).
              </Grid> 
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModalInfo(false)}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Index;
