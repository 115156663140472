import {
    Backdrop,
    Box,
    Button,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import { React, useState, useEffect } from "react";
import SideNav from "../../components/sidenav/sideNav";
import BannerControla from "../../components/cards/bannerControla";
import { Add, BorderColorOutlined, Delete } from "@mui/icons-material";
import ButtonControla from "../../components/buttons/buttonController";
import ButtonIconControla from "../../components/buttons/buttonIconControla";
import DialogTitleControla from "../../components/dialog/dialogTitleControla";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import MyAreas from "../../api/MyAreas";
import DialogConfirmControla from "../../components/dialog/dialogConfirmControla";
import MyTrainings from "../../api/MyTrainings";
import LoadingControla from "../../components/load/loadingControla";

//colors
const purple = "#8B8BD8";
const skyblue = "#2D9CDB";
const blue = "#034AFF";

function Index() {
    const params = new URLSearchParams(window.location.search);
    const idobjectivegeneral = params.get("id_og");
    const nameobjectivegeneral = params.get("name_og");
    const idprograms = params.get("id_programs");
    const origin = params.get("origin");
    //BackDrop
    const [openBackdrop, setOpenBackdrop] = useState(false);

    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
    };
    //Variables
    const [rowSubjects, setRowSubjects] = useState([]);

    const [idSubject, setIdSubject] = useState("");
    const [subject, setSubject] = useState("");

    //Modals
    const [openModalCreate, setOpenModalCreate] = useState(false);

    const handleOpenModalCreate = () => {
        setOpenModalCreate(true);
    };
    const handleCloseModalCreate = () => {
        setOpenModalCreate(false);
    };

    const [openModalEdit, setOpenModalEdit] = useState(false);

    const handleOpenModalEdit = (idSubject, subject) => {
        setIdSubject(idSubject);
        console.log(subject);
        setSubject(subject);
        setOpenModalEdit(true);
    };
    const handleCloseModalEdit = () => {
        setOpenModalEdit(false);
    };

    // alerts
    const [openAlert, setOpenAlert] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);
    const [messageAlert, setMessageAlert] = useState("");
    const [typeAlert, setTypeAlert] = useState("success");

    const handleOpenLoading = () => {
        setOpenAlert(false);
        setOpenLoading(true);
        setMessageAlert("");
        setTypeAlert("success");
    };

    const handleOpenAlert = (alertMessage, alertType) => {
        setOpenAlert(true);
        setOpenLoading(false);
        setMessageAlert(alertMessage);
        setTypeAlert(alertType);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };
    // Dialogos de confirmacion INICIO

    const [openModalConfirm, setOpenModalConfirm] = useState(false);
    const [titleText, setTitleText] = useState("");
    const [contentText, setContentText] = useState("");
    const [buttonTextConfirm, setButtonTextConfirm] = useState("");
    const [buttonActionType, setButtonActionType] = useState("");

    const handleOpenModalConfirm = (
        id,
        titleText,
        contentText,
        buttonTextConfirm,
        buttonActionType
    ) => {
        setIdSubject(id);
        setTitleText(titleText);
        setContentText(contentText);
        setButtonTextConfirm(buttonTextConfirm);
        setButtonActionType(buttonActionType);
        setOpenModalConfirm(true);
    };

    const handleCloseModalConfirm = () => {
        setOpenModalConfirm(false);
    };

    const handleActionConfirm = () => {
        switch (buttonActionType) {
            case "delete":
                handleDeleteSubjects(idSubject);
                break;
        }
        setOpenModalConfirm(false);
    };

    //CRUD functions
    const handleGetSubjects = async () => {
        const response = await MyTrainings.getSubjects();
        setRowSubjects(response);
        return response;
    };

    const handleCreateSubjects = async () => {
        setOpenBackdrop(!openBackdrop);
        const response = await MyTrainings.createSubjects({ subject: subject });
        handleCloseBackdrop();
        handleCloseModalCreate();
        handleGetSubjects();
        return response;
    };

    const handleUpdateSubjects = async () => {
        setOpenBackdrop(!openBackdrop);
        const response = await MyTrainings.updateSubjects({
            id_trainings_subjects: idSubject,
            subject: subject,
        });
        handleCloseBackdrop();
        handleCloseModalEdit();
        handleGetSubjects();
        return response;
    };

    const handleDeleteSubjects = async (idSubject) => {
        handleOpenLoading();
        const response = await MyTrainings.deleteSubjects({ id_trainings_subjects: idSubject });
        // handleCloseBackdrop();
        if (response.success == 'true') {
            handleOpenAlert(response.msg, 'success');
        } else {
            handleOpenAlert(response.msg, 'error');
        }

        handleGetSubjects();
    };

    //UseEffect
    useEffect(() => {
        handleGetSubjects();
    }, []);

    return (
        <Box sx={{ display: "flex", height: "100%" }}>
            <SideNav returnBack={(origin === 'programs' ? '/documentation/programs/training/objectivegeneral?id_og=' + idobjectivegeneral + '&name_og=' + nameobjectivegeneral + '&id_programs=' + idprograms : '/trainings')} />
            <LoadingControla
                openLoading={openLoading}
                openAlert={openAlert}
                handleCloseAlert={handleCloseAlert}
                messageAlert={messageAlert}
                typeAlert={typeAlert}
            />
            <DialogConfirmControla
                openModalConfirm={openModalConfirm}
                handleCloseModalConfirm={handleCloseModalConfirm}
                titleText={titleText}
                contentText={contentText}
                buttonTextConfirm={buttonTextConfirm}
                handleActionConfirm={handleActionConfirm}
            />
            <Container>
                <Box sx={{ flexGrow: 1 }}>
                    <Grid container>
                        <Grid item xs={6} md={6}>
                            {/* <SubtitleText
                              text={"Areas"}
                              color={colorTitle}
                              ></SubtitleText> */}
                        </Grid>
                        <Grid item xd={6} md={6} style={{ textAlign: "right" }}></Grid>
                        <Grid item xs={12} md={12} sx={{ mt: 2 }}>
                            <BannerControla
                                image=""
                                color2="#2F67BC"
                                color1="#8FBCFF"
                                text="Temas de capacitacion"
                            />
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={2}
                        sx={{ mt: 2 }}
                        justifyContent="space-between"
                        direction="row"
                        alignItems="center"
                    >
                        <Grid item>
                            <ButtonControla
                                roles={[1, 7, 8]}
                                iconButton={<Add sx={{ color: "white" }} />}
                                backgroundColor={"#169073"}
                                backgroundColorHover={"#1BAA88"}
                                textButton={"Agregar temas"}
                                functionOnClick={() => handleOpenModalCreate()}
                            />
                        </Grid>
                    </Grid>
                    <Grid container sx={{ mt: 2 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <Paper sx={{ overflow: "hidden" }}>
                                <TableContainer>
                                    <Table
                                        stickyHeader
                                        sx={{ width: "100%", minWidth: "700px" }}
                                        aria-label="simple table"
                                    >
                                        <TableHead>
                                            <TableRow>
                                                {/* <TableCell align="center"><IconButton><TaskAlt /></IconButton></TableCell> */}
                                                <TableCell align="center" sx={{ color: blue }}>
                                                    Nombre
                                                </TableCell>
                                                <TableCell align="center" sx={{ color: blue }}>
                                                    Acciones
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {rowSubjects.map((areas, key) => (
                                                <TableRow
                                                    hover
                                                    sx={{
                                                        "&:last-child td, &:last-child th": { border: 0 },
                                                    }}
                                                    key={key}
                                                >
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        sx={{ color: purple }}
                                                        align="center"
                                                    >
                                                        {areas.subject}
                                                    </TableCell>

                                                    <TableCell align="right">
                                                        <Stack
                                                            direction="row"
                                                            justifyContent="end"
                                                            alignItems="center"
                                                        >
                                                            <ButtonIconControla
                                                                roles={[1, 7, 8]}
                                                                icon={
                                                                    <BorderColorOutlined
                                                                        sx={{ color: "white" }}
                                                                    />
                                                                }
                                                                backgroundColor={"#2D9CDB"}
                                                                backgroundColorHover={"#33AEF4"}
                                                                textTooltip={"Editar"}
                                                                functionOnClick={() =>
                                                                    handleOpenModalEdit(
                                                                        areas.id_trainings_subjects,
                                                                        areas.subject
                                                                    )
                                                                }
                                                            />
                                                            <ButtonIconControla
                                                                roles={[1, 7, 8]}
                                                                icon={<Delete sx={{ color: "white" }} />}
                                                                backgroundColor={"#EB5757"}
                                                                backgroundColorHover={"#FF4040"}
                                                                textTooltip={"Eliminar"}
                                                                functionOnClick={() => {
                                                                    handleOpenModalConfirm(
                                                                        areas.id_trainings_subjects,
                                                                        "¿Estás seguro de eliminar el tema?",
                                                                        'Para poder eliminar el tema no debe tener ninguna capacitacion asignada de lo contrario no se podra eliminar. Se eliminará:  "' +
                                                                        areas.subject +
                                                                        '"',
                                                                        "Si, eliminar",
                                                                        "delete"
                                                                    );
                                                                }}
                                                            />
                                                        </Stack>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Paper>
                        </Grid>
                    </Grid>
                </Box>
            </Container>

            <Dialog
                open={openModalCreate}
                onClose={() => handleCloseModalCreate()}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText="Agregar temas"
                    functionOnClose={() => handleCloseModalCreate()}
                />
                {/* <form onSubmit={() => handleCreateSubjects()}> */}
                <DialogContent sx={{ paddingTop: "0" }}>
                    <InputControlaVertical
                        text={"Nombre"}
                        inputType={"text"}
                        inputValue={setSubject}
                        modalType={true}
                        required
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        // color="success"
                        sx={{
                            textTransform: "none",
                            color: "#6969D6",
                            backgroundColor: "#CBCBFF",
                            borderRadius: "16px",
                            marginRight: "10px",
                        }}
                        onClick={() => handleCloseModalCreate()}
                    >
                        Cancelar
                    </Button>
                    <Button
                        //   type="submit"
                        variant="contained"
                        color="success"
                        sx={{
                            textTransform: "none",
                            // backgroundColor: '#169073',
                            borderRadius: "16px",
                            marginRight: "10px",
                        }}
                        onClick={() => handleCreateSubjects()}
                    >
                        Crear
                    </Button>
                </DialogActions>
                {/* </form> */}
            </Dialog>

            <Dialog
                open={openModalEdit}
                onClose={() => handleCloseModalEdit()}
                maxWidth="sm"
                fullWidth={true}
                sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
                <DialogTitleControla
                    titleText="Editar temas"
                    functionOnClose={() => handleCloseModalEdit()}
                />
                {/* <form onSubmit={() => handleCreateSubjects()}> */}
                <DialogContent sx={{ paddingTop: "0" }}>
                    <InputControlaVertical
                        text={"Nombre"}
                        inputType={"text"}
                        inputValue={setSubject}
                        value={subject}
                        modalType={true}
                        required
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        // color="success"
                        sx={{
                            textTransform: "none",
                            color: "#6969D6",
                            backgroundColor: "#CBCBFF",
                            borderRadius: "16px",
                            marginRight: "10px",
                        }}
                        onClick={() => handleCloseModalEdit()}
                    >
                        Cancelar
                    </Button>
                    <Button
                        //   type="submit"
                        variant="contained"
                        color="success"
                        sx={{
                            textTransform: "none",
                            // backgroundColor: '#169073',
                            borderRadius: "16px",
                            marginRight: "10px",
                        }}
                        onClick={() => handleUpdateSubjects()}
                    >
                        Editar
                    </Button>
                </DialogActions>
                {/* </form> */}
            </Dialog>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openBackdrop}
            >
                {/* <CircularProgress color="inherit" /> */}
                <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
            </Backdrop>
        </Box>
    );
}

export default Index;
