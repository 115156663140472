// colocar lo de Workaccidents.jsx
import * as React from "react";

import SubtitleText from "../../../../components/text/subtitleText";
import Container from "@mui/material/Container";
import SideNav from "../../../../components/sidenav/sideNav";
import Box from "@mui/material/Box";
import {
  Alert,
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import {
  Add,
  BorderColorOutlined,
  Delete,
  VisibilityOutlined,
} from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";

import BannerControla from "../../../../components/cards/bannerControla";
import ButtonControla from "../../../../components/buttons/buttonController";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import MyRegistersAccidents from "../../../../api/MyRegistersAccidents";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import MyConfigurationApi from "../../../../api/MyConfigurationApi";
import MyRegistersMonitoring from "../../../../api/MyRegistersMonitoring";
import MyRegistersCompetencyCertificate from "../../../../api/MyRegistersCompetencyCertificate";
import DialogTitleControla from "../../../../components/dialog/dialogTitleControla";

const colorTitle = "#9191B0";

function Index() {
  const [code, setCode] = React.useState("");

  const [rowsActives, setRowsActives] = React.useState([]);
  const [openModalUpload, setOpenModalUpload] = React.useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  /**** Message Alert */
  const [stateAlert, setStateAlert] = React.useState({
    openAlert: false,
    vertical: "top",
    horizontal: "center",
    severityAlert: "success",
    messageAlert: "",
  });
  const { severityAlert, messageAlert, vertical, horizontal, openAlert } =
    stateAlert;

  const handleClickAlert = (severityAlert, messageAlert) => {
    setStateAlert({
      openAlert: true,
      vertical: "top",
      horizontal: "right",
      severityAlert: severityAlert,
      messageAlert: messageAlert,
    });
  };

  const handleCloseAlert = () => {
    setStateAlert({ ...stateAlert, openAlert: false });
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };
  const handleOpenModalUpload = () => {
    setCode(null);
    setOpenModalUpload(true);
  };

  const handleCloseModalUpload = () => {
    setOpenModalUpload(false);
  };

  // const handleGetRegistersAccidents = async () => {
  //   const response = await MyRegistersAccidents.registersAccidents();

  //   if (response.success === false) {
  //     handleClickAlert("error", "Error al guardar el documento!");
  //   } else {
  //     setRowsActives(response);
  //   }
  //   return response;
  // };

  //Certificados
  //variables
  //funciones

  const handleGetCertificate = async () => {
    setOpenBackdrop(!openBackdrop);
    const response =
      await MyRegistersCompetencyCertificate.getRegisterCertificate();
    setRowsActives(response);
    handleCloseBackdrop();
    console.log(response);
  };

  const handleCreateCertificate = async (event) => {
    event.preventDefault();
    setOpenBackdrop(!openBackdrop);
    const response =
      await MyRegistersCompetencyCertificate.createRegisterCertificate({
        code: code,
      });

    if (response != "") {
      handleCloseBackdrop();
      handleCloseModalUpload();
      window.location.href =
        "/documentation/registers/generals/competencycertificate/edit?idRegistersCertificate=" +
        response.id;
    } else {
      handleClickAlert("error", "Error al registrar documento");
      handleCloseBackdrop();
      handleCloseModalUpload();
    }
  };
  const handleDeleteCertificate = (id) => {
    // setOpenBackdrop(!openBackdrop);
    const response = MyRegistersCompetencyCertificate.deleteRegisterCertificate(
      {
        idRegister: id,
      }
    );
    // handleCloseModalDelete();
    window.location.reload();
  };
  /////Modal Delete

  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [rowId, setRowId] = React.useState();
  const handleOpenModalDelete = (id) => {
    setRowId(id);
    setOpenModalDelete(true);
  };

  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
  };

  React.useEffect(() => {
    // handleGetRegistersAccidents();
    // handleGetRegistersMonitoring();
    handleGetCertificate();
  }, []);

  console.log(rowsActives);
  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="/documentation/registers" />

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={openAlert}
        onClose={handleCloseAlert}
        key={vertical + horizontal}
        autoHideDuration={3000}
      >
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                handleCloseAlert();
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity={severityAlert}
          size="small"
        >
          {messageAlert}
        </Alert>
      </Snackbar>

      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <SubtitleText
                text={"Registros"}
                color={colorTitle}
              ></SubtitleText>
              <BannerControla
                image="/assets/images/banners/documents/bannercompetencycertificate.png"
                color2="#2F67BC"
                color1="#8FBCFF"
                text="Certificados de aptitud"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} md={3}>
              <ButtonControla
                iconButton={<Add sx={{ color: "white" }} />}
                backgroundColor={"#169073"}
                backgroundColorHover={"#1BAA88"}
                textButton={"Agregar nuevo certificado"}
                functionOnClick={handleOpenModalUpload}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Grid item md={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#1638F2",
                            fontSize: "18px",
                            width: "20%",
                          }}
                        >
                          Código
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#1638F2",
                            fontSize: "18px",
                            width: "20%",
                          }}
                        >
                          Nombres y Apellidos
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#1638F2",
                            fontSize: "18px",
                            width: "20%",
                          }}
                        >
                          Documento
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#1638F2",
                            fontSize: "18px",
                            width: "20%",
                          }}
                        >
                          Sexo
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#1638F2",
                            fontSize: "18px",
                            width: "20%",
                          }}
                        >
                          Tipo de examen
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#1638F2",
                            fontSize: "18px",
                            width: "20%",
                          }}
                        >
                          Resultado
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#1638F2",
                            fontSize: "18px",
                            width: "20%",
                          }}
                        >
                          Fechas
                        </TableCell>
                        <TableCell
                          align="center"
                          sx={{
                            color: "#1638F2",
                            fontSize: "18px",
                            width: "20%",
                          }}
                        >
                          Observaciones
                        </TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {rowsActives.map((row) => (
                        <TableRow
                          key={row.id_registers_accidents}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            padding: "4px",
                          }}
                        >
                          <TableCell align="center" component="th" scope="row">
                            {row.code}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {row.first_name === "" ||
                            row.first_name === undefined ||
                            row.first_name === null
                              ? "Sin completar"
                              : row.first_name + " " + row.last_name}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {row.document === null || row.document === ""
                              ? "Sin completar"
                              : row.document}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {row.users_gender === "" || row.document === ""
                              ? "Sin completar"
                              : row.users_gender}
                          </TableCell>
                          {/* <TableCell align="center" component="th" scope="row">
                            {row.register_status == 1 ? "Creado" : "Cerrado"}
                          </TableCell> */}
                          <TableCell align="center" component="th" scope="row">
                            {row.exam_type === ""
                              ? "Sin completar"
                              : row.exam_type}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {row.result === "" ? "Sin completar" : row.result}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {row.exam_date === null
                              ? "Sin fecha"
                              : row.exam_date}{" "}
                            /
                            {row.next_exam_date === null
                              ? "Sin fecha"
                              : row.next_exam_date}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {row.observations === ""
                              ? "Sin completar"
                              : row.observations}
                          </TableCell>
                          <TableCell align="right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                            >
                              {row.register_status === 1 ? (
                                <ButtonIconControla
                                  icon={
                                    <BorderColorOutlined
                                      sx={{ color: "white" }}
                                    />
                                  }
                                  backgroundColor={"#2D9CDB"}
                                  backgroundColorHover={"#33AEF4"}
                                  textTooltip={"Editar"}
                                  functionOnClick={() => {
                                    // window.location.href = '/documentation/registers/generals/accidents/edit?idRegistersAccidents='+ row.id_registers_accidents;
                                    window.location.href =
                                      "/documentation/registers/generals/competencycertificate/edit?idRegistersCertificate=" +
                                      row.id;
                                  }}
                                />
                              ) : (
                                ""
                              )}
                              {row.document_url !== "" ? (
                                <ButtonIconControla
                                  icon={
                                    <VisibilityOutlined
                                      sx={{ color: "white" }}
                                    />
                                  }
                                  backgroundColor={"#305AD9"}
                                  backgroundColorHover={"#0E4AFF"}
                                  textTooltip={"Ver"}
                                  functionOnClick={() => {
                                    window.open(row.document_url);
                                  }}
                                />
                              ) : (
                                ""
                              )}
                              <ButtonIconControla
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#EB5757"}
                                backgroundColorHover={"#FF4040"}
                                textTooltip={"Eliminar"}
                                functionOnClick={() => {
                                  // handleDeleteCertificate(row.id);
                                  handleOpenModalDelete(row.id);
                                }}
                              />
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>

      <Dialog
        open={openModalUpload}
        onClose={handleCloseModalUpload}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            sx={{
              color: "#1638F2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          >
            Agregar código del certificado
          </Typography>
        </DialogTitle>
        <form onSubmit={handleCreateCertificate}>
          <DialogContent sx={{ paddingTop: "0" }}>
            <InputControlaVertical
              text={"Código"}
              inputType={"text"}
              inputValue={setCode}
              modalType={true}
              required
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalUpload}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                borderRadius: "16px",
                marginRight: "10px",
              }}
            >
              Crear
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={openModalDelete}
        onClose={handleCloseModalDelete}
        maxWidth="sm"
        fullWidth={true}
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitleControla
        titleText="¿Eliminar Certificado?"
        functionOnClose={()=> handleCloseModalDelete()}/>
          <DialogContent>
            <Typography sx={{
              paddingTop: "1em"
            }}>
              No se podrá recuperar el certificado una vez eliminado.
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                color: "#6969D6",
                backgroundColor: "#CBCBFF",
                borderRadius: "16px",
                marginRight: "10px",
              }}
              onClick={handleCloseModalDelete}
            >
              Cancelar
            </Button>
            <Button
              // type="submit"
              variant="contained"
              color="success"
              sx={{
                textTransform: "none",
                borderRadius: "16px",
                marginRight: "10px",
              }}

              onClick={()=>  handleDeleteCertificate(rowId)}
            >
              Eliminar
            </Button>
          </DialogActions>

      </Dialog>

      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        {/* <CircularProgress color="inherit" /> */}
        <img src="/assets/icons/GifLogoControlaUnScreen.gif" alt="gif" />
      </Backdrop>
    </Box>
  );
}

export default Index;
