import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function campus() {
    const url = 'company/campus/get';
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'GET', url: url });
}

function storeCampus({ name, address }) {
    const url = 'company/campus/store';
    const data = {
        'name': name,
        'address': address
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'POST', url: url });
}

function updateCampus({ idCampus, name, address }) {
    const url = 'company/campus/update/' + idCampus;
    const data = {
        'name': name,
        'address': address
    };
    return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

function deleteCampus({ idCampus }) {
    const url = 'company/campus/delete/' + idCampus;
    return MyConfigurationApi.connectApi({ dataJson: null, method: 'DELETE', url: url });
}

const MyCampus = {
    campus, storeCampus, updateCampus, deleteCampus
}

export default MyCampus;
