
function getLocal(key) {
    const value = window.localStorage.getItem(key);
    if (!value) {
        return null;
    }
    return JSON.parse(value);
}

function setLocal(key, value) {
    window.localStorage.setItem(key, JSON.stringify(value))
}

function removeLocal(key) {
    localStorage.removeItem(key);
}

function clearLocal() {
    window.localStorage.clear();
}

const MyLocalStorage = {
    getLocal, setLocal, removeLocal, clearLocal
};

export default MyLocalStorage;