import { React, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import SubtitleText from "../../components/text/subtitleText";
import Container from "@mui/material/Container";
import SideNav from "../../components/sidenav/sideNav";
import Box from "@mui/material/Box";
import {
  Grid,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  MenuItem,
  DialogActions,
  Divider,
  Tooltip,
} from "@mui/material";
import {
  Close,
  DoNotDisturb,
  FileDownloadOutlined,
  FileOpenOutlined,
  Search,
  Send,
} from "@mui/icons-material";
import TitleText from "../../components/text/titleText";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import MyMailbox from "../../api/MyMailbox";
import CompanyLogoControla from "../../components/images/companyLogoControla";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import SelectControla from "../../components/selects/selectControla";
import MyConfigurationApi from "../../api/MyConfigurationApi";
import MyUsers from "../../api/MyUsers";
import SearchUsersControla from "../../components/search/searchUsersControla";
import SearchButtonControla from "../../components/search/searchButtonControla";
import LoadingControla from "../../components/load/loadingControla";
import ButtonControla from "../../components/buttons/buttonController";
import { InputBase } from "@mui/material";
import DialogConfirmControla from "../../components/dialog/dialogConfirmControla";
import { CSVLink } from "react-csv";
import ButtonIconControla from "../../components/buttons/buttonIconControla";
import MyReports from "../../api/MyReports";

const colorTitle = "#9191B0";
const skyblue = "#2D9CDB";

const StyledAddButton = styled(Button)({
  textTransform: "none",
  backgroundColor: skyblue,
  borderRadius: 16,
});
const SearchTextField = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: "white",
  },
  // marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    // marginRight: '1rem',
    width: "auto",
    // display: 'block'
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
}));

function Index() {
  //   const [sentMail, setSentMail] = useState([]);
  const [users, setUsers] = useState([]);
  const [addressee, setaddresse] = useState();
  const [subject, setSubject] = useState(0);
  const [subjects, setSubjects] = useState([]);
  const [message, setMessage] = useState("");
  const [documentUrl, setDocumentUrl] = useState("");
  const [idUserSender, setIdUserSender] = useState("");
  const [userSender, setUserSender] = useState("");

  const [idMail, setIdMail] = useState("");
  const [idMailStatus, setIdMailStatus] = useState();
  const [mailSubject, setMailSubject] = useState("Tema");
  const [mailAuthor, setMailAuthor] = useState("Autor");
  const [mailAuthorAcronym, setMailAuthorAcronym] = useState("");
  const [mailDate, setMailDate] = useState("fecha");
  const [mailUserPriority, setMailUserPriority] = useState("Prioridad");
  const [mailSenderPriority, setMailSenderPriority] = useState("Sin Prioridad");
  const [mailConsult, setMailConsult] = useState("Consulta");

  // responder mensaje
  const [mailAnswer, setMailAnswer] = useState("");
  const [mailIdReceivePriority, setMailIdReceivePriority] = useState(0);
  const [documentUrlAnswer, setDocumentUrlAnswer] = useState("");

  const [flag, setFlag] = useState(false);

  const [openCreateMailModal, setOpenCreateMailModal] = useState(false);

  const [openSearchUser, setOpenSearchUser] = useState(false);

  const mailboxPriorities = [3, 2, 1];

  // Pantallas de carga y alertas INICIO
  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");
  const [typeAlert, setTypeAlert] = useState("success");

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert("");
    setTypeAlert("success");
  };

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  // Pantallas de carga y alertas FIN

  // Dialogos de confirmacion INICIO

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [contentText, setContentText] = useState("");
  const [buttonTextConfirm, setButtonTextConfirm] = useState("");
  const [buttonActionType, setButtonActionType] = useState("");

  const handleOpenModalConfirm = (
    id,
    titleText,
    contentText,
    buttonTextConfirm,
    buttonActionType
  ) => {
    // setIdPETS(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  };

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  };

  const handleActionConfirm = () => {
    switch (buttonActionType) {
      case "close":
        handleUpdateStatus(3);
        break;
      case "cancel":
        handleUpdateStatus(4);
        break;
    }
    setOpenModalConfirm(false);
  };

  // Dialogos de confirmacion FIN

  const handleOpenSearchUser = () => {
    setOpenSearchUser(true);
  };

  const handleCloseSearchUser = () => {
    setOpenSearchUser(false);
  };

  const handleOpenCreateModal = () => {
    setMessage(null);
    setaddresse(null);
    setMailIdReceivePriority(0);
    setSubject(null);
    setDocumentUrl(null);
    setOpenCreateMailModal(true);
    setUserSendMessage(null);
  };

  const handleCloseCreateModal = () => {
    setOpenCreateMailModal(false);
  };

  const [userData, setUserData] = useState({ first_name: " ", last_name: " " });

  const handleGetSent = async () => {
    const response = await MyMailbox.consultationSent();
    // setSentMail(response);
    // setRowsMailSendSearch(response);
  };

  const handleGetUser = () => {
    const response = MyConfigurationApi.userData();
    setUserData(response);
  };

  const handleGetAllUsers = async () => {
    const response = await MyUsers.users();
    setUsers(response.users_actives);
  };

  const [userSendMessage, setUserSendMessage] = useState(null);
  const handleCatchAdressee = async (idUser) => {
    setaddresse(idUser);
    handleOpenLoading();
    const response = await MyUsers.editUsers({ idUsers: idUser });
    if (response.success !== false) {
      setUserSendMessage(response.first_name + " " + response.last_name);

      handleOpenAlert(response.msg, "success");
      handleCloseSearchUser();
    } else {
      handleOpenAlert(response.msg, "error");
    }
  };

  const handleGetSubjects = async () => {
    const response = await MyMailbox.getSubjects();
    setSubjects(response);
  };

  const sendMessageToUser = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyReports.postReports({
      idUsersReceiving: addressee,
      report: message,
      idPrioritiesSender: mailIdReceivePriority,
      idReportsTheme: subject,
      document_url: documentUrl,
    });   
    
    if (response !== false) {      

      handleGetConsultations();
      handleCloseCreateModal();
      handleOpenAlert(response.msg, "success");
    } else {
    }
  };

  const [reportsDetails, setReportsDetails] = useState();
  const handleShowSent = (mail) => {
    setReportsDetails(mail.mail.detail);
    setIdMail(mail.mail.id_reports);
    setFlag(true);

    handleGetUser();
    setMailSubject(mail.mail.consultation_theme);
    // setMailAuthor(mail.mail.first_name + " " + mail.mail.last_name);
    // setMailAuthorAcronym(mail.mail.first_name[0] + mail.mail.last_name[0]);
    setMailDate(mail.mail.timestamp_created.substring(0, 16));
    setMailUserPriority(mail.mail.priority_sended);
    setMailConsult(mail.mail.consultation);
    setMailAnswer(mail.mail.answer);

    if (mail.mail.priority_receiving === null) {
      setMailSenderPriority("Sin prioridad");
    } else {
      setMailSenderPriority(mail.mail.priority_receiving);
    }
    if (mail.mail.id_priorities_receiving === null) {
      setMailIdReceivePriority(0);
    } else {
      setMailIdReceivePriority(mail.mail.id_priorities_receiving);
    }
  };

  // jalando todos los sms
  const [reports, setReports] = useState();
  const handleGetConsultations = async () => {
    // cambiar el api de reports
    const response = await MyReports.getReports();
    if (response !== false) {
      setReports(response);
      setRowsMailSendSearch(response);
      if (idMail !== "") {
        response.map((report) => {
          if (report.id_reports === idMail) {
            setReportsDetails(report.detail);
          }
        });
      }
    } else {
      alert("error");
    }
  };

  // buscar mensaje
  const [rowsMailSendSearch, setRowsMailSendSearch] = useState([]);

  const handleSearchMessage = (searchMessage) => {
    setRowsMailSendSearch(reports);
    if (searchMessage !== "") {
      let expresion = new RegExp(`${searchMessage}.*`, "i");
      let sendMailSearch = reports.filter(
        (x) =>
          expresion.test(x.report_theme) ||
          expresion.test(x.first_name_sender) ||
          expresion.test(x.last_name_sender) ||
          expresion.test(x.last_name_receiving) ||
          expresion.test(x.first_name_receiving) ||
          expresion.test(x.report)
      );
      setRowsMailSendSearch(sendMailSearch);
    } else {
      setRowsMailSendSearch(reports);
    }
  };

  //   cambiando de estado de sms cerrado y anulado
  const handleUpdateStatus = async (status) => {
    handleOpenLoading();
    const response = await MyReports.updateReportStatuses({
      idReports: idMail,
      idReportsStatuses: status,
    });
  
    if (response !== false) {
      handleGetConsultations();
      handleOpenAlert(response.msg, "success");
    } else {
      handleOpenAlert(response.msg, "error");
    }
  };

  //   Modal para responder mensaje consultations
  const [openAnswerModal, setOpenAnswerModal] = useState(false);
  const handleOpenAnswerModal = () => {
    setMailAnswer(null);
    setDocumentUrlAnswer(null);
    // setMailIdReceivePriority(0);
    setOpenAnswerModal(true);
  };

  const handleCloseAnswerModal = () => {
    setOpenAnswerModal(false);
  };
  //  responder reporte
  const handlePostAnswer = async (event) => {
    event.preventDefault();
    handleOpenLoading();
 
    const response = await MyReports.updateReports({
      idPrioritiesReceiving: mailIdReceivePriority,
      answer: mailAnswer,
      idReports: idMail,
      document_url: documentUrlAnswer,

    });
    if (response !== false) {
      handleGetConsultations();
      handleCloseAnswerModal();
      handleOpenAlert("Mensaje enviado", "success");
    } else {
      handleGetConsultations();
      handleCloseAnswerModal();
      handleOpenAlert("Hubo un error, no se envió el mensaje", "error");
    }
  };

  // expoertar datos CSV
  const [dowloandConsultation, setDowloandConsultation] = useState([]);
  const handleGetDowloandConsultations = async () => {
    const response = await MyMailbox.getDowloandConsultations();
    console.log(response);
    if (response !== false) {
      setDowloandConsultation(response);
    } else {
    }
  };



  useEffect(() => {
    handleGetSent();
    handleGetAllUsers();
    handleGetSubjects();
    handleGetConsultations();

    handleGetDowloandConsultations();
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      <SideNav returnBack="/mailbox/" />
      <LoadingControla
        openLoading={openLoading}
        openAlert={openAlert}
        handleCloseAlert={handleCloseAlert}
        messageAlert={messageAlert}
        typeAlert={typeAlert}
      />

      <DialogConfirmControla
        openModalConfirm={openModalConfirm}
        handleCloseModalConfirm={handleCloseModalConfirm}
        titleText={titleText}
        contentText={contentText}
        buttonTextConfirm={buttonTextConfirm}
        handleActionConfirm={handleActionConfirm}
      />
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container justifyContent="flex-end">
            <Grid item xs={12} md={5}>
              <SubtitleText
                text={"Buzón de participación"}
                color={colorTitle}
              ></SubtitleText>
              <TitleText text={"Bandeja de reportes "}></TitleText>
            </Grid>
            <Grid item xs={12} md={5}></Grid>
            <Grid
              item
              xs={12}
              md={2}
              justifyContent="flex-end"
              style={{ textAlign: "center" }}
            >
              <CompanyLogoControla />
            </Grid>
            <Grid item xs={12} md={2}></Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1, mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Stack direction="row" spacing={2}>
                <StyledAddButton
                  variant="text"
                //   disableElevation
                  size="large"
                  style={{
                    backgroundColor: "rgb(89, 156, 255)",
                    borderRadius: "15px",
                    color: "white",
                    padding: "6px 18px",
                  }}
                  onClick={handleOpenCreateModal}
                >
                  Redactar
                </StyledAddButton>

                <SearchTextField
                  onChange={({ target }) => handleSearchMessage(target.value)}
                >
                  <SearchIconWrapper>
                    <Search />
                  </SearchIconWrapper>
                  <StyledInputBase
                    placeholder="Buscar ..."
                    inputProps={{ "aria-label": "search" }}
                  />
                </SearchTextField>
                <StyledAddButton
                  variant="text"
                  startIcon={<FileDownloadOutlined />}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "15px",
                    color: "#305AD9",
                    padding: "5px  0 5px 25px ",
                  }}
                >
                  <CSVLink
                    variant="text"
                    // disableElevation
                    size="large"
                    style={{
                      backgroundColor: "white",
                      borderRadius: "15px",
                      color: "#305AD9",
                      padding: "5px  25px 5px 0 ",
                      textDecoration: "none",
                    }}
                    data={dowloandConsultation}
                    filename={"datos.csv"}
                    separator={";"}
                  >
                    Descargar CSV
                  </CSVLink>
                </StyledAddButton>
              </Stack>
            </Grid>
            <Grid item md={6}>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="center"
                alignItems="left"
              >
                <Button
                  size="large"
                  variant="text"
                  style={{
                    backgroundColor: "#599CFF",
                    borderRadius: "15px",
                    color: "white",
                    padding: "8px 25px",
                    textTransform: "none",
                  }}
                  // href={"/mailbox"}
                >
                  Consultas y comentarios
                </Button>
                <Button
                  size="large"
                  variant="text"
                  style={{ textTransform: "none", borderRadius: "15px" }}
                  // href={"/mailbox/reports/sentreport"}
                >
                  Reportes
                </Button>
                <Button
                  size="large"
                  variant="text"
                  style={{ textTransform: "none", borderRadius: "15px" }}
                  // href={"/mailbox/congratulations/sentcongratulations"}
                >
                  Felicitaciones
                </Button>
              </Stack>
            </Grid>
            <Grid item md={3} style={{ textAlign: "right" }}></Grid>
          </Grid>
        </Box>

        <Box sx={{ flexGrow: 1, mt: 2, mb: 4 }}>
          <Grid container spacing={2} sx={{ paddingTop: 0 }}>
            {reports !== null ? (
              <Grid
                item
                md={6}
                sx={{
                  textAlign: "left",
                  overflowY: "scroll",
                  maxHeight: "520px",
                  paddingTop: "0 !important",

                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                  },
                  "&::-webkit-scrollbar-button": {
                    height: "80px",
                  },
                  "&::-webkit-scrollbar-track": {
                    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#222ADA",
                  },
                }}
              >
                {rowsMailSendSearch.map((mail, key) => (
                  <Card
                    onClick={() => {
                      handleShowSent({
                        mail,
                      });
                    }}
                    sx={{ mb: 2 }}
                    style={{
                      backgroundColor: "white",
                      borderRadius: "16px",
                      padding: "10px",
                      cursor: "pointer",
                      border:
                        idMail === mail.id_reports
                          ? "2px solid #1538f5"
                          : "",
                    }}
                    key={key}
                  >
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item md={8} style={{ textAlign: "left" }}>                          
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#034AFF",
                                fontWeight: 600,
                              }}
                            >
                              De:
                              {" " +
                                mail.first_name_sender +
                                " " +
                                mail.last_name_sender}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "#034AFF",
                                fontWeight: 600,
                              }}
                            >
                              Para:
                              {" " +
                                mail.first_name_receiving +
                                " " +
                                mail.last_name_receiving}
                            </Typography>                         
                        </Grid>
                        <Grid item md={4} style={{ textAlign: "right" }}>
                          <Typography
                            sx={{ fontSize: "12px", color: "#9191B0" }}
                          >
                            {mail.timestamp_created.substring(0, 16) + " hrs"}
                          </Typography>
                        </Grid>
                        <Grid item md={12} style={{ textAlign: "left" }}>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              color: "#305AD9",
                              fontWeight: "600",
                            }}
                          >
                            Asunto:{" " +mail.report_theme}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "15px",
                              color: "#091641",
                              fontWeight: "400",
                            }}
                          >
                            {mail.report}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />

                    <CardActions>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          md={6}
                          style={{ textAlign: "left", width: "50%", mt: 0 }}
                        >
                          <Grid item md={12}>
                            <Tooltip title="Estado">
                              <IconButton>
                                <StyledAddButton
                                  disabled
                                  variant="text"
                                //   disableElevation
                                  size="small"
                                  style={{
                                    backgroundColor:
                                      mail.statuses === "Abierto"
                                        ? "#21A94C"
                                        : mail.statuses === "Proceso"
                                        ? "#26B2C5"
                                        : mail.statuses === "Cerrado"
                                        ? "#C53226"
                                        : mail.statuses === "Anulado"
                                        ? "#0E0D0D"
                                        : "",
                                    borderRadius: "10px",
                                    color: "white",
                                    padding: "2px 5px",
                                  }}
                                >
                                  {mail.statuses}
                                </StyledAddButton>
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          md={6}
                          style={{
                            width: "50%",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Stack>
                            <Tooltip title="Tu prioridad">
                              <IconButton>
                                <StyledAddButton
                                  disabled
                                  variant="text"
                                  startIcon={<ManageAccountsOutlinedIcon />}
                                //   disableElevation
                                  size="small"
                                  style={{
                                    backgroundColor:
                                      mail.priority_sended === "Bajo"
                                        ? "#42a5f5"
                                        : mail.priority_sended === "Medio"
                                        ? "#ff9800"
                                        : "#d32f2f",
                                    borderRadius: "10px",
                                    color: "white",
                                    padding: "2px 6px",
                                  }}
                                >
                                  {mail.priority_sended}
                                </StyledAddButton>
                              </IconButton>
                            </Tooltip>
                          </Stack>                        
                          <Stack>
                            <Tooltip title="Su prioridad">
                              <IconButton>
                                <StyledAddButton
                                  disabled
                                  variant="text"
                                  startIcon={<ManageAccountsOutlinedIcon />}
                                //   disableElevation
                                  size="small"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    backgroundColor:
                                      mail.priority_receiving === "Bajo"
                                        ? "#42a5f5"
                                        : mail.priority_receiving === "Medio"
                                        ? "#ff9800"
                                        : mail.priority_receiving === "Alto"
                                        ? "#d32f2f  "
                                        : "grey",
                                    borderRadius: "10px",
                                    color: "white",
                                    padding: "2px 6px",
                                  }}
                                >
                                  {mail.priority_receiving === null
                                    ? "Sin Prioridad"
                                    : mail.priority_receiving}
                                </StyledAddButton>
                              </IconButton>
                            </Tooltip>
                          </Stack>
                        </Grid>
                      </Grid>
                    </CardActions>
                  </Card>
                ))}
              </Grid>
            ) : (
              <Grid
                item
                md={6}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "15px",
                    color: "#2F80ED",
                    fontWeight: "600",
                  }}
                >
                  Bandeja vacía
                </Typography>             
              </Grid>
            )}
            {/* box para responder el mailBox */}
            {flag === true ? (
              <Grid
                item
                md={6}
                sx={{
                  textAlign: "left",
                  overflowY: "scroll",
                  maxHeight: "523px",
                  paddingTop: "0 !important",

                  "&::-webkit-scrollbar": {
                    width: "0.4em",
                  },
                  "&::-webkit-scrollbar-button": {
                    height: "80px",
                  },
                  "&::-webkit-scrollbar-track": {
                    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#222ADA",
                  },
                }}
              >
                <Card
                  sx={{ mb: 2 }}
                  style={{
                    backgroundColor: "white",
                    borderRadius: "16px",
                    padding: "10px",
                    cursor: "pointer",
                  }}
                  // key={key}
                >
                  {reportsDetails.map((mail, key) => (
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item md={8} style={{ textAlign: "left" }}>
                          <Stack
                            direction="row"
                            spacing={2}
                            justifyContent="start"
                            alignItems="center"
                          >
                            <Avatar
                              sx={{
                                bgcolor: "#4d7ab6",
                                fontSize: "15px",
                                fontWeight: "700",
                              }}
                            >
                              {mail.first_name[0] + mail.last_name[0]}
                            </Avatar>
                            <Typography
                              sx={{
                                fontSize: "16px",
                                color: "#034AFF",
                                fontWeight: 600,
                              }}
                            >
                              {" " + mail.first_name + " " + mail.last_name}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item md={4} style={{ textAlign: "right" }}>
                          <Typography
                            sx={{ fontSize: "12px", color: "#9191B0" }}
                          >
                            {mail.timestamp_created.substring(0, 16)}
                          </Typography>
                        </Grid>

                        <Grid item md={11} style={{ textAlign: "left" }}>
                          <Typography
                            sx={{
                              fontSize: "17px",
                              color: "#323232",
                            }}
                          >
                            {mail.message}
                          </Typography>
                        </Grid>
                        {mail.document_url !== null ? (
                          <Grid item md={1} style={{ textAlign: "right" }}>
                            <ButtonIconControla
                              icon={
                                <FileOpenOutlined sx={{ color: "white" }} />
                              }
                              backgroundColor={"#305AD9"}
                              backgroundColorHover={"#0E4AFF"}
                              textTooltip={"Ver archivo"}
                              directionUrl={mail.document_url}
                              directionTarget={"_blank"}
                            />
                          </Grid>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </CardContent>
                  ))}
                  <CardActions>
                    <Stack
                      direction={"row"}
                      spacing={2}
                      justifyContent={"center"}
                    >
                      <Box>
                        <ButtonControla
                          iconButton={<Send sx={{ color: "white" }} />}
                          backgroundColor={"#305AD9"}
                          backgroundColorHover={"#4B76F6"}
                          textButton={"Responder"}
                          functionOnClick={handleOpenAnswerModal}
                        />
                      </Box>
                      <Box>
                        <ButtonControla
                          iconButton={<Close sx={{ color: "white" }} />}
                          backgroundColor={"#C61C39"}
                          backgroundColorHover={"#D61F3E"}
                          textButton={"Cerrar"}
                          typeButton={"button"}
                          functionOnClick={() => {
                            handleOpenModalConfirm(
                              "",
                              "¿Esta seguro de cerrar el reporte?",
                              "Una vez cerrado  no se podrá enviar mensajes respecto al tema.",
                              "Si, Cerrar",
                              "close"
                            );
                          }}
                        />
                      </Box>{" "}
                      <Box>
                        <ButtonControla
                          iconButton={<DoNotDisturb sx={{ color: "white" }} />}
                          backgroundColor={"#090909"}
                          backgroundColorHover={"#39393B"}
                          textButton={"Anular"}
                          typeButton={"button"}                       
                          functionOnClick={() => {
                            handleOpenModalConfirm(
                              "",
                              "¿Esta seguro de anular el reporte?",
                              "Una vez anulado no se podrá enviar mensajes  respecto al tema. ",
                              "Si, anular",
                              "cancel"
                            );
                          }}
                        />
                      </Box>
                    </Stack>
                  </CardActions>
                </Card>              
              </Grid>
            ) : (
              <Grid
                item
                md={6}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid item md={5}>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      color: "#2F80ED",
                      fontWeight: "600",
                    }}
                  >
                    Bandeja vacía
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      color: "#2F80ED",
                      fontWeight: "600",
                    }}
                  >
                    Seleccione un mensaje
                  </Typography>
                  <img
                    src="/assets/images/rowLeft.png"
                    alt="row left"
                    width={"100px"}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>

      {/* Dialogo para crear mensaje */}
      <Dialog
        open={openCreateMailModal}
        onClose={handleCloseCreateModal}
        maxWidth="xs"
        fullWidth
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            sx={{
              color: "#305AD9",
              fontSize: 24,
              fontWeight: "bold",
            }}
          >
            Crear nuevo reporte
          </Typography>
        </DialogTitle>
        <form onSubmit={sendMessageToUser}>
          <DialogContent>
            <DialogContentText></DialogContentText>
            <InputControlaVertical
              inputValue={setMessage}
              value={message}
              inputType={"textArea"}
              modalType={true}
              text="Mensaje"
              required
            ></InputControlaVertical>
            <SelectControla
              inputValue={setSubject}
              value={subject}
              modalType={true}
              text="Tema"
              required
              childrenRows={subjects.map((subject, key) => (
                <MenuItem key={key} value={subject.id_reports_theme}>
                  {subject.report_theme}
                </MenuItem>
              ))}
            />
            <SelectControla
              inputValue={setMailIdReceivePriority}
              value={mailIdReceivePriority}
              modalType={true}
              text="Prioridad"
              required
              childrenRows={mailboxPriorities.map((priority, key) => (
                <MenuItem key={key} value={priority}>
                  {priority === 3
                    ? "Alto"
                    : priority === 2
                    ? "Medio"
                    : priority === 1
                    ? "Bajo"
                    : ""}
                </MenuItem>
              ))}
            />

            <SearchButtonControla
              titleLabel={"Buscar Usuario"}
              modalType={true}
              functionClick={handleOpenSearchUser}
              inputValue={userSendMessage}
              required
            ></SearchButtonControla>
            <InputControlaVertical
              inputValue={setDocumentUrl}
              value={documentUrl}
              inputType={"file"}
              modalType={true}
              // text="Mensaje"
              // required
            ></InputControlaVertical>
          </DialogContent>
          <DialogActions>
            <Stack direction={"row"} spacing={2} justifyContent={"center"}>
              <Box>
                <ButtonControla
                  iconButton={<Close sx={{ color: "white" }} />}
                  backgroundColor={"#C61C39"}
                  backgroundColorHover={"#D61F3E"}
                  textButton={"Cancelar"}
                  typeButton={"button"}
                  functionOnClick={handleCloseCreateModal}
                />
              </Box>{" "}
              <Box>
                <ButtonControla
                  iconButton={<Send sx={{ color: "white" }} />}
                  backgroundColor={"#169073"}
                  backgroundColorHover={"#1BAA88"}
                  textButton={"Enviar mensaje"}
                  typeButton={"submit"}
                />
              </Box>
            </Stack>
          </DialogActions>
        </form>
      </Dialog>

      {/* Dialog para buscar usuario */}
      <Dialog
        open={openSearchUser}
        onClose={handleCloseSearchUser}
        maxWidth="xs"
        fullWidth
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <SearchUsersControla
          rowsUser={users}
          handleUsersActionButton={handleCatchAdressee}
        ></SearchUsersControla>
      </Dialog>

      {/* Dialog de responder sms */}
      <Dialog
        open={openAnswerModal}
        onClose={handleCloseAnswerModal}
        maxWidth="xs"
        fullWidth
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
      >
        <DialogTitle>
          <Typography
            sx={{
              color: "#305AD9",
              fontSize: 24,
              fontWeight: "bold",
            }}
          >
            Responder
          </Typography>
        </DialogTitle>
        <form onSubmit={handlePostAnswer}>
          <DialogContent>
            <InputControlaVertical
              required
              inputType={"textArea"}
              text="Respuesta"
              modalType={true}
              inputValue={setMailAnswer}
              value={mailAnswer}
            />
            <SelectControla
              inputValue={setMailIdReceivePriority}
              value={mailIdReceivePriority}
              modalType={true}
              text="Prioridad"
              required
              childrenRows={mailboxPriorities.map((priority, key) => (
                <MenuItem key={key} value={priority}>
                  {priority === 3
                    ? "Alto"
                    : priority === 2
                    ? "Medio"
                    : priority === 1
                    ? "Bajo"
                    : ""}
                </MenuItem>
              ))}
            />
            <InputControlaVertical
            //   required
              inputType={"file"}
              // text="Respuesta"
              modalType={true}
              inputValue={setDocumentUrlAnswer}
              value={documentUrlAnswer}
            />
          </DialogContent>
          <DialogActions>
            <Stack direction={"row"} spacing={2} justifyContent={"center"}>
              <Box>
                <ButtonControla
                  iconButton={<Close sx={{ color: "white" }} />}
                  backgroundColor={"#C61C39"}
                  backgroundColorHover={"#D61F3E"}
                  textButton={"Cancelar"}
                  typeButton={"button"}
                  functionOnClick={handleCloseAnswerModal}
                />
              </Box>{" "}
              <Box>
                <ButtonControla
                  iconButton={<Send sx={{ color: "white" }} />}
                  backgroundColor={"#169073"}
                  backgroundColorHover={"#1BAA88"}
                  textButton={"Responder "}
                  typeButton={"submit"}
                />
              </Box>
            </Stack>
          </DialogActions>
        </form>
      </Dialog>
    </Box>
  );
}

export default Index;
