import { React } from "react";
import SubtitleText from "../components/text/subtitleText";
import TitleText from "../components/text/titleText";
import Container from "@mui/material/Container";
import DocCard from "../components/cards/docCard";
import Link from "@mui/material/Link";
import SideNav from "../components/sidenav/sideNav";
import Box from "@mui/material/Box";
import { Grid, Tooltip } from "@mui/material";
import MyBaseline from "../api/MyBaseline";

const colorTitle = "#9191B0";
const colorOneCardOne = "#e7e7e7";

const handleGetBaselineDiagnosis = async () => {
  const response = await MyBaseline.baselineDiagnosis();

  if (response.success === false) {
    //console.log("error no se puedo traer lois diagnósticos")
  } else {
    const archivedBaselines = response.baseLine_archived;
    const activeBaselines = response.baseLine_current;
    const inProgressBaselines = response.baseLine_in_progress;

    if (
      JSON.stringify(archivedBaselines) === "[]" &&
      JSON.stringify(activeBaselines) === "[]" &&
      JSON.stringify(inProgressBaselines) === "[]"
    ) {
      window.location.href = "/baseline/startprocess";
    } else {
      console.log("hay algo lleno");
      window.location.href = "/baseline/diagnosis";
    }
  }
  return response;
};

function Baseline() {
  return (
    <Box sx={{ display: "flex", backgroundColor: "#F5F4F6" }}>
      <SideNav returnBack="/"/>
      <Container>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <SubtitleText
                text={"Línea Base"}
                color={colorTitle}
              ></SubtitleText>
            </Grid>
            <Grid item md={12}>
              <TitleText text={"Línea Base"} color={colorTitle}></TitleText>
            </Grid>
            <Grid item xs={12} md={12}>
              <img
                src="/assets/images/baselinebanner.png"
                style={{ width: "100%" }}
                alt={"banner"}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {/* <Grid item xs={12} md={3.5} sx={{ mt: 3 }}>
                            <Link href={"/"} underline="none">
                                <DocCard size={"medium"} color1={colorOneCardOne} color2={colorOneCardOne} text={"Respuestas Líneas Base"} img={"/assets/images/baselineanswers.png"} alt="banner"></DocCard>
                            </Link>
                        </Grid>
                        <Grid item xs={12} md={3.5} sx={{ mt: 3 }}>
                            <Link href={"/"} underline="none">
                                <DocCard size={"medium"} color1={colorOneCardOne} color2={colorOneCardOne} text={"Resultados Línea Base"} img={"/assets/images/baselineresults.png"} alt="banner"></DocCard>
                            </Link>
                        </Grid> */}
            <Grid
              item
              xs={12}
              md={3.5}
              sx={{ mt: 3 }}
              style={{ cursor: "pointer" }}
            >
              <Link
                onClick={() => handleGetBaselineDiagnosis()}
                underline="none"
              >
                <DocCard
                  size={"medium"}
                  color1={colorOneCardOne}
                  color2={colorOneCardOne}
                  text={"Mis diagnósticos"}
                  img={"/assets/images/mydiagnosticsbanner.png"}
                  alt="banner"
                ></DocCard>
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default Baseline;
