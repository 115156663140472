import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function getRegisterCertificate() {
  const url = "competency_certificate";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

function createRegisterCertificate({ code }) {
  const url = "competency_certificate";
  const data = {
    code: code,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function editRegisterCertificate({ idRegister }) {
  const url = "competency_certificate/" + idRegister;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function updateRegisterCertificate({
  idRegister,
  code,
  workerName,
  examType,
  examDate,
  result,
  certificate,
  observations,
  nextExamDate,
  workerGender,
  workerDocument,
  idUser,
  documentUrl,
  documentName,
}) {
  const current = new Date();
  const year = current.getFullYear();
  const month = current.getMonth() + 1;
  const day = current.getDate();

  if (documentUrl.length !== 0) {
    documentUrl = await uploadFileCompanies(
      documentUrl,
      "documentation/registers/generals/competencycertificate/" +
        year +
        "/" +
        month +
        "/" +
        day +
        "/"
    );
  }

  const url = "competency_certificate/" + idRegister;

  const data = {
    worker: workerName,
    exam_type: examType,
    exam_date: examDate,
    result: result,
    certificate: certificate,
    observations: observations,
    next_exam_date: nextExamDate,
    code: code,
    gender: workerGender,
    document: workerDocument,
    id_users: idUser,
    document_url: documentUrl,
    document_name: documentName,
  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

function closeRegisterCertificate({ idRegister }) {
  const url = idRegister + "/status/end";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "PUT",
    url: url,
  });
}

function deleteRegisterCertificate({ idRegister }) {
  const url = "competency_certificate/" + idRegister;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "DELETE",
    url: url,
  });
}

////No longer used
// async function UploadCertificateFile({ idRegister, document, documentName }) {
//   const current = new Date();
//   const year = current.getFullYear();
//   const month = current.getMonth() + 1;
//   const day = current.getDate();

//   if (document.length !== 0) {
//     document = await uploadFileCompanies(
//       document,
//       "documentation/registers/generals/competencycertificate/" +
//       year +
//       "/" +
//       month +
//       "/" +
//       day +
//       "/"
//     );
//   }
//   const url = "competency_certificate/" + idRegister;

//   const data = {
//     document: document,
//     document_name: documentName,
//   };
//   return MyConfigurationApi.connectFormApi({
//     dataJson: data,
//     method: "PUT",
//     url: url,
//   });
// }
const MyRegistersCompetencyCertificate = {
  getRegisterCertificate,
  createRegisterCertificate,
  updateRegisterCertificate,
  editRegisterCertificate,
  closeRegisterCertificate,
  // UploadCertificateFile,
  deleteRegisterCertificate,
};

export default MyRegistersCompetencyCertificate;
