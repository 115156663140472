import * as React from "react";
import Box from "@mui/material/Box";
import SubtitleText from "../text/subtitleText";
import TitleText from "../text/titleText";
import BlueButton from "../buttons/blueButton";
import { Circle } from "@mui/icons-material";
import { Grid, Stack, Tooltip } from "@mui/material";
import SideNav from "../sidenav/sideNav";
import Container from "@mui/material/Container";
import BannerButtonControla from "../cards/bannerButtonControla";
import BannerVerticalControla from "../cards/bannerVerticalControla";

const grey = "#9191B0";
const blue = "#034AFF";
function HomeView() {
  return (
    <Box sx={{ display: "flex", backgroundColor: "#F5F4F6" }}>
      <SideNav />
      <Container>  
        <Box sx={{ flexGrow: 1 }}>
          <Grid container rowSpacing={3} columnSpacing={6}>
            <Grid item xs={6} md={12}>
              <SubtitleText text={"Dashboard"} color={grey}></SubtitleText>
            </Grid>
            <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
              <BannerButtonControla
                color1={"#3863FB"}
                color2={"#161A78"}
                title={"¡Bienvenido a controla!"}
                subtitle={
                  "Espacio perfecto para organizar tu trabajo en el sistema de gestion SSO "
                }
                textButton={"Comenzar ahora"}
                image={"/assets/images/dashboard/bienvenido.png"}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
              <BannerButtonControla
                color1={"#1638F2"}
                color2={"#5683F6"}
                // title={"¡Bienvenido a controla!"}
                subtitle={"Recuerda revisar regularmente tu buzón "}
                textButton={"Comenzar ahora"}
                image={"/assets/images/dashboard/buzon.png"}
                url={"/mailbox"}
              />
            </Grid>

            <Grid item xs={12} sm={3.5} md={3.5} lg={3.5} xl={3.5}>
              <BannerVerticalControla
                color1={"#7575CC"}
                color2={"#4242B1"}
                title={"Organiza tu documentación fácilmente"}
                image={"/assets/images/dashboard/tareas.png"}
                url={"/documentation"}
                // sx={{ marginLeft: "20px" }}
              />
            </Grid>
            <Grid item xs={12} sm={3.5} md={3.5} lg={3.5} xl={3.5}>
                <BannerVerticalControla
                  color1={"#9B51E0"}
                  color2={"#7432B1"}
                  title={"Recuerda agendar la reunión mensual de tu comité"}
                  image={"/assets/images/doccreationimage.png"}
                  functionClick={() => { alert('No disponible en esta versión')}}
                />
            </Grid>
            <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
              <BannerVerticalControla
                color1={"#0D579C"}
                color2={"#95CCFF"}
                title={"Evalúa tu sistema y toma acción"}
                image={"/assets/images/dashboard/recursos.png"}
                url={"/baseline"}
              />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
export default HomeView;
