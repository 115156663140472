import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function getRit() {
  const url = "rit";
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "GET",
    url: url,
  });
}

async function createRit({ name, file }) {
  const current = new Date();
  const year = current.getFullYear();
  const month = current.getMonth() + 1;
  const day = current.getDate();

  if (file !== null && file !== undefined) {
    file = await uploadFileCompanies(
      file,
      "documentation/rit" + year + "/" + month + "/" + day + "/"
    );
  }

  const url = "rit";
  const data = {
    name: name,
    file: file,
  };

  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

async function deleteRit({ idRit }) {
  const url = "rit/" + idRit;
  return MyConfigurationApi.connectFormApi({
    dataJson: null,
    method: "DELETE",
    url: url,
  });
}

function changeRitStatus({ idRit, registerStatus }) {
  const url = "rit/" + idRit;
  const data = {
    register_status: registerStatus,
  };
  return MyConfigurationApi.connectFormApi({
    dataJson: data,
    method: "PUT",
    url: url,
  });
}

const MyRit = {
  getRit,
  createRit,
  deleteRit,
  changeRitStatus
};

export default MyRit;
