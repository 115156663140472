import { React, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  Modal,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import Container from "@mui/material/Container";
import { Stack, styled } from "@mui/system";
import {
  Add,
  Archive,
  ArrowBackIosNew,
  ArrowForwardIos,
  BorderColorOutlined,
  Delete,
  DriveFolderUpload,
  FileUploadOutlined,
  Note,
  Send,
  TableChart,
  VisibilityOutlined,
} from "@mui/icons-material";
import SideNav from "../../../../components/sidenav/sideNav";
import SubtitleText from "../../../../components/text/subtitleText";
import MyPetar from "../../../../api/MyPetar";
import InputControlaVertical from "../../../../components/textfields/inputControlaVertical";
import TitleText from "../../../../components/text/titleText";
import CloseIcon from "@mui/icons-material/Close";
import ButtonIconControla from "../../../../components/buttons/buttonIconControla";
import BannerControla from "../../../../components/cards/bannerControla";
import LoadingControla from "../../../../components/load/loadingControla";
import DialogConfirmControla from "../../../../components/dialog/dialogConfirmControla";
import ButtonControla from "../../../../components/buttons/buttonController";
import DialogTitleControla from "../../../../components/dialog/dialogTitleControla";

const blue = "#034AFF";
const purple = "#8B8BD8";

function Index() {
  const params = new URLSearchParams(window.location.search);
  const idTabs = parseInt(
    params.get("idTabs") !== null ? params.get("idTabs") : 0
  );

  const handleGetPetar = async (inputYear = undefined) => {
    if (inputYear != undefined) {
      handleOpenLoading();
    }
    const response = await MyPetar.petar({ year: (inputYear == undefined ? year : inputYear) });
    console.log(response);

    if (response.success === false) {
      handleOpenAlert('Ocurrio un error al listar los Petar', 'error');
    } else {
      const petar_model = response.petar_model;
      const petar_no_archived = response.petar_no_archived;
      const petar_archived = response.petar_archived;
      setRowsModels(petar_model);
      setRowsActives(petar_no_archived);
      setRowsArchives(petar_archived);
      if (inputYear != undefined) {
        setDocumentType(1);
        handleOpenAlert('Listando Petar activos del año ' + inputYear, 'success');
      }
    }
    return response;
  };


  const [openAlert, setOpenAlert] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState('');
  const [typeAlert, setTypeAlert] = useState('success');

  const handleOpenLoading = () => {
    setOpenAlert(false);
    setOpenLoading(true);
    setMessageAlert('');
    setTypeAlert('success');
  }

  const handleOpenAlert = (alertMessage, alertType) => {
    setOpenAlert(true);
    setOpenLoading(false);
    setMessageAlert(alertMessage);
    setTypeAlert(alertType);
  }

  const handleCloseAlert = () => {
    setOpenAlert(false);
  }

  const handlePostCreatePetar = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    if (documentUrl !== null) {
      const response = await MyPetar.createPetar({
        name,
        code,
        area,
        place,
        start_time: hourStart,
        end_time: hourFinish,
        model: 0,
        document_url: documentUrl,
      });
      if (response.success === true) {
        window.location.reload();
      }
    } else {
      const response = await MyPetar.createPetar({
        name,
        code,
        area,
        place,
        start_time: hourStart,
        end_time: hourFinish,
        model: 0,
        document_url: documentUrl,
      });
      if (response.success === true) {
        window.location.href = "/editPetar?id_petar=" + response.id_petar;
      }
    }
  };

  const handlePutUpdatePetar = async (idPetar) => {
    const response = await MyPetar.updatePetar(
      {
        id_petar: idPetar,
        name,
        code,
        document_url: documentUrl,
      });
    console.log('Respuesta del update: ' + response);

  }

  const handleDeletePetar = async (idPetar) => {
    const response = await MyPetar.deletePetar({ idPetar });
    if (response.success) {
      handleGetPetar();
    } else {
      // console.log(response)
    }
  }

  const handlePostCreateModel = async (event) => {
    event.preventDefault();
    handleOpenLoading();
    const response = await MyPetar.createPetar({
      name, code, model: 1
    });
    if (response.success) {
      window.location.href = "/documentation/documents/procedures/petar/editModel?id_petar=" + response.id_petar;
    } else {
      handleOpenAlert('Ocurrio un error al crear el modelo PETAR', 'error');
    }
  }

  const sendToWorkers = async (idPetar) => {
    const response = await MyPetar.sendToWorkers({ id_petar: idPetar });
    handleGetPetar();

  }

  const publishPetar = async (idPetar) => {
    const response = await MyPetar.publishPetar({ id_petar: idPetar });
    handleGetPetar();
  }

  const toArchive = async (idPetar) => {
    const response = await MyPetar.archivePetar({ id_petar: idPetar });
    handleGetPetar();
  }

  const toUnarchive = async (idPetar) => {
    const response = await MyPetar.unarchivePetar({ id_petar: idPetar });
    console.log(response);
    handleGetPetar();
  }

  useEffect(() => {
    handleGetPetar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [openModalCreate, setOpenModalCreate] = useState(false);
  const handleOpenModalCreate = () => setOpenModalCreate(true);
  const handleCloseModalCreate = () => setOpenModalCreate(false);

  const [openAlertModal, setOpenAlertModal] = useState(false);
  const handleOpenAlertModal = () => setOpenAlertModal(true);
  const handleCloseAlertModal = () => setOpenAlertModal(false);

  const [toBeDeleted, setToBeDeleted] = useState();

  const [rowsModels, setRowsModels] = useState([]);
  const [rowsActives, setRowsActives] = useState([]);
  const [rowsArchives, setRowsArchives] = useState([]);

  const [idPetar, setIdPetar] = useState(null);
  const [name, setName] = useState(null);
  const [code, setCode] = useState(null);
  const [area, setArea] = useState(null);
  const [place, setPlace] = useState(null);
  const [hourStart, setHourStart] = useState(null);
  const [hourFinish, setHourFinish] = useState(null);
  const [documentUrl, setDocumentUrl] = useState(null);

  const currentTime = new Date();
  const [year, setYear] = useState(currentTime.getFullYear());

  const [documentType, setDocumentType] = useState(idTabs !== undefined && idTabs !== null && idTabs !== "" ? idTabs : 0);

  const handleChangeDocumentType = (event, newValue) => {
    setDocumentType(newValue);
  };

  const handleIncreaseYear = (event, newValue) => {
    setYear(year + 1);
    handleGetPetar(year + 1);
  };

  const handleDecreaseYear = (event, newValue) => {
    setYear(year - 1);
    handleGetPetar(year - 1);
  };

  const [openModalUpload, setOpenModalUpload] = useState(false);

  const handleOpenModalUpload = () => {
    setName(null);
    setCode(null);
    setArea(null);
    setPlace(null);
    setHourStart(null);
    setHourFinish(null);
    setDocumentUrl(null);
    setOpenModalUpload(true);
  };

  const handleCloseModalUpload = () => setOpenModalUpload(false);

  const [openModalUploadUpdate, setOpenModalUploadUpdate] = useState(false);

  const handleOpenModalUploadUpdate = (idPetar, name, code) => {
    setIdPetar(idPetar);
    setName(name);
    setCode(code);
    setOpenModalUploadUpdate(true);
  }

  const handleCloseModalUploadUpdate = () => {
    setOpenModalUploadUpdate(false);
  }

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const handleOpenConfirmModal = () => { setOpenConfirmModal(true) };
  const handleCloseConfirmModal = () => { setOpenConfirmModal(false) };


  /////////////////////// ALERT

  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [titleText, setTitleText] = useState('');
  const [contentText, setContentText] = useState('');
  const [buttonTextConfirm, setButtonTextConfirm] = useState('');
  const [buttonActionType, setButtonActionType] = useState('');


  const handleOpenModalConfirm = (id, titleText, contentText, buttonTextConfirm, buttonActionType) => {
    setIdPetar(id);
    setTitleText(titleText);
    setContentText(contentText);
    setButtonTextConfirm(buttonTextConfirm);
    setButtonActionType(buttonActionType);
    setOpenModalConfirm(true);
  }

  const handleCloseModalConfirm = () => {
    setOpenModalConfirm(false);
  }

  const handleActionConfirm = () => {
    switch (buttonActionType) {
      case 'delete':
        handleDeletePetar();
        break;
      case 'sendWorkers':
        sendToWorkers(idPetar);
        break;
      case 'public':
        publishPetar(idPetar);
        break;
      case 'archived':
        toArchive(idPetar);
        break;
      case 'unarchived':
        toUnarchive(idPetar);
        break;
    }
    setOpenModalConfirm(false);
  }
  /////////////////////// ALERT


  return (
    <>
      <Box sx={{ display: "flex", height: "100%" }}>
        <SideNav returnBack='/documentation/documents/procedures' />

        <LoadingControla openLoading={openLoading} openAlert={openAlert} handleCloseAlert={handleCloseAlert} messageAlert={messageAlert} typeAlert={typeAlert} />

        <DialogConfirmControla openModalConfirm={openModalConfirm} handleCloseModalConfirm={handleCloseModalConfirm} titleText={titleText} contentText={contentText} buttonTextConfirm={buttonTextConfirm} handleActionConfirm={handleActionConfirm} />

        <Container>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container rowSpacing={3} columnSpacing={6}>
              <Grid item md={12}>
                {/* NO ELIMINAR - TEMPORALMENTE COMENTADO */}

                {/* <SubtitleText
                                    text={"Procedimientos"}
                                    color={grey}>
                                </SubtitleText> */}
                <BannerControla
                  image="/assets/images/banners/documents/petarBanner.png"
                  color2="#2F67BC"
                  color1="#8FBCFF"
                  text="Permiso Escrito para Trabajos de Alto Riesgo"
                />
              </Grid>
              <Grid container item direction={"row"} md={12} gap={2}>
                <Grid item md={5} >
                  <Stack spacing={2} direction="row">
                    <ButtonControla
                      roles={[1, 7, 8, 15]}
                      textButton={"Agregar modelo Petar"}
                      iconButton={<Add />}
                      backgroundColor={"#2D9CDB"}
                      backgroundColorHover={"#4db2eb"}
                      functionOnClick={() => handleOpenModalCreate()}
                    />
                    <ButtonControla
                      roles={[1, 7, 8, 15]}
                      textButton={"Subir Petar"}
                      iconButton={<DriveFolderUpload />}
                      backgroundColor={"#169073"}
                      backgroundColorHover={"#2bcda6"}
                      functionOnClick={() => handleOpenModalUpload()}
                    />
                  </Stack>
                </Grid>
                {/* COMENTADO POR EL MOMENTO - NO ELIMINAR */}
                {/* <Grid
                  item
                  md={1.8}
                  alignItems="center"
                  justifyContent={"center"}
                >
                  <Button
                    //onClick={handleClickOpen}
                    variant="contained"
                    startIcon={<TableChart />}
                    sx={{
                      textTransform: "none",
                      backgroundColor: "#007bff",
                      borderRadius: 6,
                      width: "100%",
                    }}
                    disableElevation
                    onClick={handleOpenModalModel}
                  >
                    Usar Plantilla
                  </Button>
                </Grid> */}
                {/* <Grid item md={4.8}></Grid> */}
                <Grid item md={3.5}></Grid>
                <Grid container spacing={2} item xs={12} sm={12} md={4} lg={3} xl={3} alignItems="center" justifyContent={"right"}>
                  <Grid item xs={3} sm={2} md={3} lg={3} xl={3}>
                    <InputLabel
                      sx={{
                        padding: "8px 0px 8px 0px",
                        fontSize: "14px",
                        color: "#305AD9",
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      Periodo:
                    </InputLabel>
                  </Grid>
                  <Grid item xs={3} sm={3} md={5} lg={4} xl={4}>
                    <InputBase
                      type="number"
                      value={year}
                      onChange={handleGetPetar}
                      readOnly
                      sx={{
                        width: "100%",
                        padding: "4px 10px 4px 10px",
                        backgroundColor: "white",
                        color: "#305AD9",
                        fontSize: "14px",
                        borderRadius: "8px",
                      }}
                    ></InputBase>
                  </Grid>
                  <Grid item xs={2} sm={1} md={2} lg={2} xl={2}>
                    <IconButton
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "15px",
                        color: "#305AD9",
                      }}
                      onClick={handleDecreaseYear}
                    >
                      <ArrowBackIosNew />
                    </IconButton>
                  </Grid>
                  <Grid item xs={2} sm={1} md={2} lg={2} xl={2}>
                    <IconButton
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "15px",
                        color: "#305AD9",
                      }}
                      onClick={handleIncreaseYear}
                    >
                      <ArrowForwardIos />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container item md={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead></TableHead>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ color: blue }} align="center">
                          Nombre del documento
                        </TableCell>
                        <TableCell align="center" sx={{ color: blue, display: (documentType === 0 ? 'none' : '') }}>Tipo</TableCell>
                        <TableCell align="center" sx={{ color: blue, display: (documentType === 0 ? 'none' : '') }}>Estado</TableCell>
                        <TableCell
                          colSpan={4}
                          sx={{ color: blue }}
                          align="center"
                        >
                          <Stack
                            direction="row"
                            justifyContent="end"
                            alignItems="center"
                          >
                            <Tabs
                              value={documentType}
                              onChange={handleChangeDocumentType}
                              aria-label="Tipos de documento"
                            >
                              <Tab label="Modelos" />
                              <Tab label="Activos" />
                              <Tab label="Archivados" />
                            </Tabs>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{ display: documentType !== 0 ? "none" : "" }}
                    >
                      {rowsModels.map((rowsModels) => (
                        <TableRow hover key={rowsModels.id_petar}>
                          <TableCell sx={{ color: purple }} align="center">
                            {rowsModels.name}
                          </TableCell>
                          <TableCell sx={{ color: purple }} align="center">
                            {/*espacio libre*/}
                          </TableCell>
                          <TableCell sx={{ color: purple }} align="right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                            >
                              <ButtonIconControla
                                roles={[1, 7, 8, 15]}
                                icon={
                                  <BorderColorOutlined
                                    sx={{ color: "white" }}
                                  />
                                }
                                backgroundColor={"#2D9CDB"}
                                textTooltip={"Editar"}
                                functionOnClick={() =>
                                (window.location.href =
                                  "petar/editModel?id_petar=" +
                                  rowsModels.id_petar)
                                }
                              />
                              <ButtonIconControla
                                roles={[1, 7, 8, 15]}
                                icon={<Delete sx={{ color: "white" }} />}
                                backgroundColor={"#ED2727"}
                                textTooltip={"Eliminar"}
                                // functionOnClick={() => handleDeletePetar(rowsModels.id_petar)}\
                                functionOnClick={() => handleOpenModalConfirm(rowsModels.id_petar, '¿Esta seguro de eliminar este documento?', 'Una vez eliminado no podrá recuperar el PETAR:  \"' + rowsModels.name + '\"', 'Si, eliminar', 'delete')}
                              />
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableBody
                      sx={{ display: documentType !== 1 ? "none" : "" }}
                    >
                      {rowsActives.map((rowsActives) => (
                        <TableRow
                          hover
                          key={rowsActives.id_petar}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell sx={{ color: purple }} align="center">
                            {rowsActives.name}
                          </TableCell>
                          <TableCell sx={{ color: purple }} align="center">
                            {rowsActives.document_url === null ? 'SISTEMA' : 'ARCHIVO'}
                          </TableCell>
                          <TableCell sx={{ color: purple }} align="center">
                            {rowsActives.status}
                          </TableCell>
                          <TableCell sx={{ color: purple }} align="right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                            >
                              {rowsActives.document_url === null && rowsActives.id_petar_statuses === 1 ?
                                <>
                                  <ButtonIconControla
                                    roles={[1, 7, 8, 15]}
                                    icon={
                                      <BorderColorOutlined
                                        sx={{ color: "white" }}
                                      />
                                    }
                                    backgroundColor={"#2D9CDB"}
                                    textTooltip={"Editar"}
                                    functionOnClick={() =>
                                    (window.location.href =
                                      "/editPetar?id_petar=" +
                                      rowsActives.id_petar)
                                    }
                                  />
                                  <ButtonIconControla
                                    roles={[1, 7, 8, 15]}
                                    icon={<Send sx={{ color: "white" }} />}
                                    backgroundColor={"#169073"}
                                    backgroundColorHover={"#1AA483"}
                                    textTooltip={"Enviar a trabajadores"}
                                    functionOnClick={() =>
                                      sendToWorkers(
                                        rowsActives.id_petar
                                      )
                                    }
                                  />
                                </>
                                : rowsActives.document_url !== null && rowsActives.id_petar_statuses === 1 ?
                                  <>
                                    <ButtonIconControla
                                      icon={
                                        <BorderColorOutlined
                                          sx={{ color: "white" }}
                                        />
                                      }
                                      roles={[1, 5, 6, 11]}
                                      backgroundColor={"#2D9CDB"}
                                      textTooltip={"Editar"}
                                      functionOnClick={() =>
                                        handleOpenModalUploadUpdate(rowsActives.id_petar, rowsActives.name, rowsActives.code)
                                      }
                                    />
                                    <ButtonIconControla
                                      roles={[1, 5, 11]}
                                      icon={<Send sx={{ color: "white" }} />}
                                      backgroundColor={"#169073"}
                                      backgroundColorHover={"#1AA483"}
                                      textTooltip={"Enviar a trabajadores"}
                                      functionOnClick={() =>
                                        sendToWorkers(
                                          rowsActives.id_petar
                                        )
                                      }
                                    />
                                  </>
                                  : <></>
                              }
                              {rowsActives.id_petar_statuses === 2 ?
                                <ButtonIconControla
                                  roles={[1, 5, 11]}
                                  icon={
                                    <FileUploadOutlined
                                      sx={{ color: "white" }}
                                    />
                                  }
                                  backgroundColor={"#7b19bb"}
                                  textTooltip={"Cerrar"}
                                  functionOnClick={() => handleOpenModalConfirm(rowsActives.id_petar, '¿Esta seguro de cerrar este documento?', 'Una vez cerrado no podrá editar nuevamente el PETAR:  \"' + rowsActives.name + '\"', 'Si, cerrar', 'public')}
                                /> : <></>}
                              {rowsActives.document_url !== null ? (
                                <ButtonIconControla
                                  roles={[1, 5, 11]}
                                  icon={
                                    <VisibilityOutlined sx={{ color: "white" }} />
                                  }
                                  backgroundColor={"#2D9CDB"}
                                  textTooltip={"Ver"}
                                  directionUrl={rowsActives.document_url} directionTarget={'_blank'}
                                />
                              ) :
                                <ButtonIconControla
                                  roles={[1, 5, 6, 11]}
                                  icon={
                                    <VisibilityOutlined sx={{ color: "white" }} />
                                  }
                                  backgroundColor={"#2D9CDB"}
                                  textTooltip={"Ver"}
                                  functionOnClick={() =>
                                  (window.location.href =
                                    "/editPetar?id_petar=" +
                                    rowsActives.id_petar)
                                  }
                                />}
                              {rowsActives.id_petar_statuses === 3 ?
                                <ButtonIconControla
                                  roles={[1, 7, 8]}
                                  icon={<Archive sx={{ color: "white" }} />}
                                  backgroundColor={"#f4711a"}
                                  textTooltip={"Archivar"}
                                  functionOnClick={() => handleOpenModalConfirm(rowsActives.id_petar, '¿Esta seguro de archivar?', 'Una vez archivado no podrá editar nuevamente el PETAR:  \"' + rowsActives.name + '\"', 'Si, archivar', 'archived')}
                                /> : <></>
                              }
                              {rowsActives.id_petar_statuses === 1 ?
                                <ButtonIconControla
                                  roles={[1, 5, 11]}
                                  icon={<Delete sx={{ color: "white" }} />}
                                  backgroundColor={"#ED2727"}
                                  textTooltip={"Eliminar"}
                                  functionOnClick={() => handleOpenModalConfirm(rowsActives.id_petar, '¿Esta seguro de eliminar?', 'Una vez eliminado no podrá editar nuevamente el PETAR:  \"' + rowsActives.name + '\"', 'Si, eliminar', 'delete')}
                                /> : <></>
                              }
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <TableBody
                      sx={{ display: documentType !== 2 ? "none" : "" }}
                    >
                      {rowsArchives.map((rowsArchives) => (
                        <TableRow
                          hover
                          key={rowsArchives.id_petar}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell sx={{ color: purple }} align="center">
                            {rowsArchives.name}
                          </TableCell>
                          <TableCell sx={{ color: purple }} align="center">
                            {rowsArchives.document_url === null ? 'SISTEMA' : 'ARCHIVO'}
                          </TableCell>
                          <TableCell sx={{ color: purple }} align="center">
                            {rowsArchives.status}
                          </TableCell>
                          <TableCell sx={{ color: purple }} align="center">

                          </TableCell>
                          <TableCell align="right">
                            <Stack
                              direction="row"
                              justifyContent="end"
                              alignItems="center"
                            > {rowsArchives.document_url !== null ? (
                              <ButtonIconControla
                                roles={[1, 5, 6, 11]}
                                icon={
                                  <VisibilityOutlined sx={{ color: "white" }} />
                                }
                                backgroundColor={"#2D9CDB"}
                                textTooltip={"Ver"}
                                directionUrl={rowsArchives.document_url} directionTarget={'_blank'}
                              />
                            ) : <ButtonIconControla
                              icon={
                                <VisibilityOutlined sx={{ color: "white" }} />
                              }
                              backgroundColor={"#2D9CDB"}
                              textTooltip={"Ver"}
                              functionOnClick={() =>
                              (window.location.href =
                                "/editPetar?id_petar=" +
                                rowsArchives.id_petar)
                              }
                            />}
                              <ButtonIconControla
                                roles={[1,7,8]}
                                icon={<Archive sx={{ color: "white" }} />}
                                backgroundColor={"#f4711a"}
                                textTooltip={"Desarchivar"}
                                functionOnClick={() => handleOpenModalConfirm(rowsArchives.id_petar, '¿Esta seguro de desarchivar?', 'Una vez desarchivado se podrá realizar acciones de acuerdo al último estado del doumento.:  \"' + rowsArchives.name + '\"', 'Si, desarchivar', 'unarchived')}
                              />

                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item md={12}></Grid>
            </Grid>

            <Dialog open={openModalCreate} onClose={handleCloseModalCreate} maxWidth="sm"
              PaperProps={{
                style: {
                  backgroundColor: "#F5F5F5",
                  width: "55%",
                  padding: "10px",
                  borderRadius: "15px",
                },
              }}
              fullWidth={true}
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
              <DialogTitleControla titleText='Nuevo Modelo de PETAR' functionOnClose={handleCloseModalCreate} />
              <form onSubmit={handlePostCreateModel}>
                <DialogContent>
                  <InputControlaVertical
                    text="Código"
                    inputType={"text"}
                    inputValue={setCode}
                    modalType={false}
                    required
                  />
                  <InputControlaVertical
                    text="Nombre del modelo de PETAR"
                    inputType={"text"}
                    inputValue={setName}
                    modalType={false}
                    required
                  />
                </DialogContent>
                <DialogActions>
                  <Box>
                    <ButtonControla
                      backgroundColor={'#CBCBFF'}
                      textButton={'Cancelar'}
                      backgroundColorHover={'#CBCBFF'}
                      typeButton={'button'}
                      functionOnClick={handleCloseModalCreate}
                    >
                      Cancelar
                    </ButtonControla>
                  </Box>
                  <Box>
                    <ButtonControla
                      iconButton={<Add />}
                      backgroundColor={'#169073'}
                      backgroundColorHover={'#1BAA88'}
                      textButton={'Crear modelo de PETAR'}
                      typeButton={'submit'}
                    >
                      Agregar nuevo modelo
                    </ButtonControla>
                  </Box>
                </DialogActions>
              </form>
            </Dialog>
            <Dialog
              open={openModalUpload}
              onClose={handleCloseModalUpload}
              PaperProps={{
                style: {
                  backgroundColor: "#F5F5F5",
                  width: "65%",
                  padding: "10px",
                  borderRadius: "15px",
                },
              }}
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
              <DialogTitleControla titleText='Subir documento PETAR' functionOnClose={handleCloseModalUpload} />
              <form onSubmit={handlePostCreatePetar}>
                <DialogContent>
                  <InputControlaVertical
                    text={"Título del documento"}
                    inputType={"text"}
                    inputValue={setName}
                    modalType={false}
                    required
                  />
                  <InputControlaVertical
                    text={"Código"}
                    inputType={"text"}
                    inputValue={setCode}
                    modalType={false}
                    required
                  />
                  <InputControlaVertical
                    text={"Subir Archivo"}
                    inputType={"file"}
                    inputValue={setDocumentUrl}
                    modalType={false}
                    required
                  />
                </DialogContent>
                <DialogActions>
                  <Box>
                    <ButtonControla
                      backgroundColor={'#CBCBFF'}
                      textButton={'Cancelar'}
                      backgroundColorHover={'#CBCBFF'}
                      typeButton={'button'}
                      functionOnClick={handleCloseModalUpload}
                    >
                    </ButtonControla>
                  </Box>
                  <Box>
                    <ButtonControla
                      iconButton={<Add />}
                      backgroundColor={'#169073'}
                      backgroundColorHover={'#1BAA88'}
                      textButton={'Subir archivo PETAR'}
                      typeButton={'submit'}
                    >
                    </ButtonControla>
                  </Box>

                </DialogActions>
              </form>
            </Dialog>
            <Dialog
              open={openAlertModal}
              onClose={handleCloseAlertModal}>
              <DialogTitle>
                ¿Seguro que desea eliminar este documento?
              </DialogTitle>
              <DialogActions>
                <Button>
                  Eliminar
                </Button>
                <Button onClick={handleCloseAlertModal}>
                  Cancelar
                </Button>
              </DialogActions>
            </Dialog>
            {/* modal actualizar subir doc */}
            <Dialog
              open={openModalUploadUpdate}
              onClose={handleCloseModalUploadUpdate}
              PaperProps={{
                style: {
                  backgroundColor: "#F5F5F5",
                  width: "65%",
                  padding: "10px",
                  borderRadius: "15px",
                },
              }}
              sx={{ zIndex: (theme) => theme.zIndex.drawer + 0 }}
            >
              <DialogTitle>
                <Typography
                  id="modal-modal-title"
                  variant="h4"
                  component="h2"
                  sx={{
                    color: "#1638F2",
                    fontWeight: "bold",
                  }}
                >
                  Actualizar documento PETAR
                </Typography>
              </DialogTitle>
              <DialogContent>
                <InputControlaVertical
                  text={"Título del documento"}
                  inputType={"text"}
                  value={name}
                  inputValue={setName}
                  modalType={false}
                  required
                />
                <InputControlaVertical
                  text={"Código"}
                  inputType={"text"}
                  value={code}
                  inputValue={setCode}
                  modalType={false}
                  required
                />
                <InputControlaVertical
                  text={"Subir Archivo"}
                  inputType={"file"}
                  value={documentUrl}
                  inputValue={setDocumentUrl}
                  modalType={false}
                  required
                />
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="success"
                  sx={{
                    textTransform: "none",
                    // backgroundColor: '#169073',
                    borderRadius: "16px",
                    marginRight: "10px",
                  }}
                  onClick={() => handlePutUpdatePetar(idPetar)}
                >
                  Subir nuevo archivo PETAR
                </Button>
                <Button
                  variant="contained"
                  // color="success"
                  sx={{
                    textTransform: "none",
                    color: "#6969D6",
                    backgroundColor: "#CBCBFF",
                    borderRadius: "16px",
                    marginRight: "10px",
                  }}
                  onClick={handleCloseModalUploadUpdate}
                >
                  Cancelar
                </Button>
              </DialogActions>
            </Dialog >

            <Dialog
              open={openConfirmModal}
              onClose={handleCloseConfirmModal}
              PaperProps={{
                style: {
                  backgroundColor: "#F5F5F5",
                  width: "65%",
                  padding: "10px",
                  borderRadius: "15px",
                },
              }}>
              <DialogTitle>

              </DialogTitle>
              <DialogContent>

              </DialogContent>
              <DialogActions>

              </DialogActions>
            </Dialog>
          </Box>
        </Container >
      </Box >
    </>
  );
}

export default Index;
