import React from 'react'

function TitleText({text}) {
  return (
    <div
    style={{
        color:'#1638F2',
        fontSize: 31,
        fontWeight: 'bold'
    }}
    >{text}</div>
  )
}

export default TitleText