import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function getSentCongratulations() {
    const url = "mailbox/congratulations/sent/get";
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url,
    });
}

function getCongratulations() {
    const url = "mailbox/congratulations/get";
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url,
    });
}

function getReceiveCongratulations() {
    const url = "mailbox/congratulations/receive/get";
    return MyConfigurationApi.connectApi({
        dataJson: null,
        method: "GET",
        url: url,
    });
}

async function storeCongratulations({ idUsersReceiving, congratulation, document_url }) {
    if (document_url !== null && document_url !== undefined) {
        const current = new Date();
        const year = current.getFullYear();
        const month = current.getMonth() + 1;
        const day = current.getDate();
        document_url = await uploadFileCompanies(document_url, 'mailbox/congratulations/' + year + '/' + month + '/' + day + '/');
    
    }
    const url = "mailbox/congratulations/store";
    const data = {
        'id_users_receiving': idUsersReceiving, 
        'congratulation': congratulation,
        'document_url':document_url

    }
    return MyConfigurationApi.connectApi({
        dataJson: data,
        method: "POST",
        url: url,
    });
}

const MyCongratulations = {
    getSentCongratulations, getReceiveCongratulations, storeCongratulations, getCongratulations
}

export default MyCongratulations;