import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Link,
  Typography,
} from "@mui/material";
import React from "react";
import MyConfigurationApi from "../../api/MyConfigurationApi";

function CardControla({ text, img, url, roles = [] }) {

  const userData = MyConfigurationApi.userData();
  let usersRoles = userData.roles;
  let showButton = false;
  roles = (roles.length === 0 ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13] : roles);
  roles.map((rol) => {
    if (usersRoles.includes(rol)) {
      showButton = true;
    }
  });
  if (showButton === true) {
    return (
      <Link href={url} underline="none">
        <Card sx={{ maxWidth: 345, height: "235px" }}>
          <CardActionArea sx={{ height: "235px" }}>
            <CardMedia
              component="img"
              sx={{ width: "100%", height: "140px", display: "block" }}
              image={img}
              alt="card"
            />
            <CardContent sx={{ height: "95px", display: "block" }}>
              <Typography
                sx={{
                  backgroundColor: "#FCFCFC",
                  color: "#395AC8",
                  fontSize: 20,
                  textAlign: "center",
                }}
                gutterBottom
                variant="h5"
                component="div"
              >
                {text}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
    );
  } else {
    return "";
  }
}

export default CardControla;
