// import MyLocalStorage from "./MyLocalStorage";

// async function encryptValue(value) {
//     const publickKey = MyLocalStorage.getLocal('keys').pbk;
//     let valueEncryptReturn;
//     await window.crypto.subtle.importKey('jwk',
//         publickKey,
//         {
//             name: "RSA-OAEP",
//             hash: { name: "SHA-256" },
//         },
//         true,
//         ['encrypt']
//     ).then(async (key) => {
//         const enc = new TextEncoder();
//         console.log(value);
//         value = enc.encode(value);
//         console.log('textencoder');
//         console.log(value);
//         const valueEncrypt = await window.crypto.subtle.encrypt(
//             {
//                 name: "RSA-OAEP"
//             },
//             key,
//             value
//         );

//         valueEncryptReturn = valueEncrypt;
//     });
//     return valueEncryptReturn;


// }

// async function decryptValue(value) {
//     const privateKey = MyLocalStorage.getLocal('keys').pvk;
//     await window.crypto.subtle.importKey('jwk',
//         privateKey,
//         {
//             name: "RSA-OAEP",
//             hash: { name: "SHA-256" },
//         },
//         true,
//         ['decrypt']
//     ).then(async (key) => {
//         let valueDecrypted = await window.crypto.subtle.decrypt(
//             {
//                 name: "RSA-OAEP"
//             },
//             key,
//             value
//         );
//         console.log(valueDecrypted);
//         let dec = new TextDecoder();
//         console.log(JSON.parse(dec.decode(valueDecrypted)));
//     });
// }

// async function generateKey() {
//     await window.crypto.subtle.generateKey(
//         {
//             name: "RSA-OAEP",
//             // Consider using a 4096-bit key for systems that require long-term security
//             modulusLength: 2048,
//             publicExponent: new Uint8Array([1, 0, 1]),
//             hash: "SHA-256",
//         },
//         true,
//         ["encrypt", "decrypt"]
//     ).then(async (keyPair) => {
//         let keys = {
//             'pbk': undefined,
//             'pvk': undefined
//         };
//         await window.crypto.subtle.exportKey("jwk", keyPair.publicKey)
//             .then((e) => {
//                 keys.pbk = e;
//             });
//         await window.crypto.subtle.exportKey("jwk", keyPair.privateKey)
//             .then((e) => {
//                 keys.pvk = e;
//             });
//         MyLocalStorage.setLocal('keys', keys);

//     });
// }

const crypt = (salt, text) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);

    return text
        .split("")
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join("");
};

const decrypt = (salt, encoded) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) => textToChars(salt).reduce((a, b) => a ^ b, code);
    return encoded
        .match(/.{1,2}/g)
        .map((hex) => parseInt(hex, 16))
        .map(applySaltToChar)
        .map((charCode) => String.fromCharCode(charCode))
        .join("");
};

const MyCrypto = {
    // generateKey, encryptValue, decryptValue, 
    crypt, decrypt
};

export default MyCrypto;