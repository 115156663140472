import { React, useState, useEffect } from 'react';

import SubtitleText from '../../components/text/subtitleText';
import Container from '@mui/material/Container';
import SideNav from "../../components/sidenav/sideNav";
import Box from '@mui/material/Box';
import { Grid, Button, FormControl, Typography, Divider, InputLabel, CardMedia, CardActionArea } from '@mui/material';
import { Add, ArrowBackIosNew, ArrowForwardIos, ArrowRight, Delete, DriveFileRenameOutlineOutlined, } from '@mui/icons-material';
import TitleText from '../../components/text/titleText';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/system';
import InputControla from '../../components/textfields/inputControla';
import LabelControla from '../../components/textfields/labelControla';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import InputControlaVertical from '../../components/textfields/inputControlaVertical';
import CompanyLogoControla from '../../components/images/companyLogoControla';
import ButtonIconControla from '../../components/buttons/buttonIconControla';
import ButtonControla from '../../components/buttons/buttonController';
import SelectYesNotControla from '../../components/selects/selectYesNotControla';
import MyCompanies from '../../api/MyCompanies';
import CardControla from '../../components/cards/cardControla';
import MyConfigurationApi from '../../api/MyConfigurationApi';

const colorTitle = "#9191B0";
const blue = "#034AFF";
const green = "#2C8C75";
const lilac = "#D1D1E9";
const skyblue = "#2D9CDB";

function Index() {

    const name = MyConfigurationApi.companyData().name;
    const ruc = MyConfigurationApi.companyData().ruc;
    const logo = MyConfigurationApi.companyData().logo_url;

    return (

        <Box sx={{ display: "flex", height: '100%' }}>
            <SideNav returnBack='/settings' />

            <Container>

                <Box sx={{ flexGrow: 1, marginBottom: 4 }}>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={11} sm={11} md={10} lg={10} xl={10}>
                            <SubtitleText text={"Configuración"} color={colorTitle} />
                            <TitleText text={"Perfil de mi empresa"} />
                        </Grid>
                    </Grid>
                    <Card
                        sx={{
                            width: "100%",
                            height: "154px",
                            borderRadius: "16px",
                            background: `linear-gradient(90deg, #1638f2, white)`,
                            backgroundSize: "100%",
                            opacity: "0.75",
                            color: "white",
                            padding: "0 20px",
                            fontSize: "90%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: 'space-between'
                        }}
                    >
                        <CardContent
                            sx={{ width: "70%" }}
                        >
                            <Typography
                                variant="h4"
                                sx={{ fontWeight: "bold" }}
                            >
                                {name}
                            </Typography>
                            <Typography
                                variant="h6"
                                sx={{ fontWeight: "bold" }}
                            >
                                {'RUC: ' + ruc}
                            </Typography>
                            {/* <ButtonControla
                                backgroundColor={"#169073"}
                                backgroundColorHover={"#1AA483"}
                                textButton={'Ir paso a paso'}
                            /> */}
                        </CardContent>
                        <CardMedia
                            component="img"
                            sx={{ width: '100%', maxWidth: "200px", maxHeight: "110px", display: "flex", justifyContent: "flex-end", alignContent: 'center' }}
                            image={logo}
                            alt="card"
                        />
                    </Card>

                    <Grid container spacing={2} sx={{ mt: 3 }}>
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <CardControla
                                url={"/settings/company/generalinformation"}
                                text={"Informacion general"}
                                img={"/assets/images/errors/imgNotImage.jpg"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <CardControla
                                url={"/settings/company/organization"}
                                text={"Organizacion"}
                                img={"/assets/images/errors/imgNotImage.jpg"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <CardControla
                                url={"/settings/registers"}
                                text={"Caracteristicas"}
                                img={"/assets/images/errors/imgNotImage.jpg"}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3} lg={3} xl={3}>
                            <CardControla
                                url={"/settings/registers"}
                                text={"Certificaciones"}
                                img={"/assets/images/errors/imgNotImage.jpg"}
                            />
                        </Grid>
                    </Grid>

                </Box>
            </Container>

        </Box>

    );

}

export default Index;

