import MyConfigurationApi from "./MyConfigurationApi";
import { uploadFileCompanies } from "./MyFirebase";

function occupationalDiseases() {
  const url = "registers/general/occupational/diseases/get";
  return MyConfigurationApi.connectApi({ dataJson: null,  method: "GET",  url: url, });
}

//registros de enfermedades ocupacionales
async function createOccupationalsDiseases({ code }) {
  const url = "registers/general/occupational/diseases/store";
  const data = { code: code };
  return MyConfigurationApi.connectApi({
    dataJson: data,
    method: "POST",
    url: url,
  });
}

function editOccupationalsDiseases({idRegistersOcupationalDiseases }) {
  const url = "registers/general/occupational/diseases/edit/" + idRegistersOcupationalDiseases;
  return MyConfigurationApi.connectApi({  dataJson: null,  method: "GET",  url: url, });
}


async function updateOccupationalsDiseases({ idRegistersOcupationalDiseases, code, document_url_1 , year}) {
  if (document_url_1 !== null && document_url_1 !== undefined) {
    const current = new Date();
    const year = current.getFullYear();
    const month = current.getMonth() + 1;
    const day = current.getDate();
    document_url_1 = await uploadFileCompanies(document_url_1,"documentation/registers/generals/occupationaldiseases/" + year + '/' + month + '/' + day + '/');
  } 
  const url = "registers/general/occupational/diseases/update/" + idRegistersOcupationalDiseases;
  const data = {
    code: code,
    document_url_1: document_url_1,
    year: year
   
  };
  return MyConfigurationApi.connectApi({  dataJson: data,   method: "PUT",  url: url, });
}

//cerrar registro de enfermedades ocupacionales
function ocupationalDiseasesEnd({idRegistersOcupationalDiseases}) {
  const url = "registers/general/occupational/diseases/update/statuses/end/"+idRegistersOcupationalDiseases;
  return MyConfigurationApi.connectApi({ dataJson: null,  method: "PUT", url: url, });
}


// MEDIDAS CORECTIVAS

//get de estatus de medidas correctivas
function getMeasuresStatuses() {
  const url = "registers/general/occupational/diseases/measures/statuses/get";
  return MyConfigurationApi.connectApi({ dataJson: null,  method: "GET", url: url, });
}

// Buscar trabajadores en medidas correctivas
function getSearchsMeasuresUsers({ idRegistersOcupationalDiseases }) {
  const url = "registers/general/occupational/diseases/measures/users/get/" + idRegistersOcupationalDiseases;
  return MyConfigurationApi.connectApi({ dataJson: null, method: "GET",  url: url, });
}

// listar medidas correctivas en la tabla principal
function getMeasures({ idRegistersOcupationalDiseases }) {
  const url = "registers/general/occupational/diseases/measures/get/" + idRegistersOcupationalDiseases;
  return MyConfigurationApi.connectApi({dataJson: null, method: "GET", url: url, });
}

// crear medidas correctivas
async function createCorrectivesActions({ 
  idRegistersOcupationalDiseases,  
  id_registers_occupational_diseases_measures_statuses, 
  id_users, 
  timestamp_start, 
  timestamp_end,
  description}) 
  {
  const url = "registers/general/occupational/diseases/measures/store/" + idRegistersOcupationalDiseases;
  const data = {
    id_registers_occupational_diseases_measures_statuses: id_registers_occupational_diseases_measures_statuses, 
    id_users: id_users, 
    timestamp_start: timestamp_start,  
    timestamp_end: timestamp_end,  
    description: description, 
  };
  return MyConfigurationApi.connectApi({dataJson: data,    method: "POST",    url: url,  });
}

//eliminar medidas correctivas
async function deleteCorrectivesActions({ id_measures }) {
  const url ="registers/general/occupational/diseases/measures/delete/" + id_measures ;
  return MyConfigurationApi.connectApi({dataJson: null,  method: "DELETE",  url: url, });
}

// RESPOSABLES
// Buscar trabajadores en responsables:
function getSearchsResponsiblesWorkers({ idRegistersOcupationalDiseases }) {
  const url = "registers/general/occupational/diseases/responsible/users/get/" + idRegistersOcupationalDiseases;
  return MyConfigurationApi.connectApi({ dataJson: null, method: "GET",  url: url, });
}

//get de trabajadores responsables
function getResponsibleWorkers({ idRegistersOcupationalDiseases }) {
  const url = "registers/general/occupational/diseases/responsible/get/"+idRegistersOcupationalDiseases;
  return MyConfigurationApi.connectApi({ dataJson: null,  method: "GET", url: url, });
}

// crear trabajadores responsables
async function createResponsibleWorkers({ idRegistersOcupationalDiseases,  id_users, firm}) {
  const url =    "registers/general/occupational/diseases/responsible/store/" + idRegistersOcupationalDiseases;
  const data = {
    id_users: id_users, 
    firm: firm, 
  };
  return MyConfigurationApi.connectApi({    dataJson: data,    method: "POST",    url: url,  });
}
// eliminar trabajadores responsable
async function deleteResponsibleWorkers({ idRegistersOcupationalDiseases }) {
  const url ="registers/general/occupational/diseases/responsible/delete/" + idRegistersOcupationalDiseases;
  return MyConfigurationApi.connectApi({dataJson: null,  method: "DELETE",  url: url, });
}

// SUSTANCIAS
//get de sustancias cancerígenas
function getDiseasesCarcinogens({ idRegistersOcupationalDiseases }) {
  const url = "registers/general/occupational/diseases/carcinogens/get/"+idRegistersOcupationalDiseases;
  return MyConfigurationApi.connectApi({ dataJson: null,  method: "GET", url: url, });
}
// crear sustancias cancerígenas
async function createDiseasesCarcinogens({ idRegistersOcupationalDiseases,  carcinogen, monitoring}) {
  const url =    "registers/general/occupational/diseases/carcinogens/store/" + idRegistersOcupationalDiseases;
  const data = {
    carcinogen: carcinogen, 
    monitoring: monitoring, 
  };
  return MyConfigurationApi.connectApi({    dataJson: data,    method: "POST",    url: url,  });
}

// eliminar sustancias cancerígenas
async function deleteDiseasesCarcinogens({ idRegistersOcupationalDiseases }) {
  const url ="registers/general/occupational/diseases/carcinogens/delete/" + idRegistersOcupationalDiseases;
  return MyConfigurationApi.connectApi({dataJson: null,  method: "DELETE",  url: url, });
}

//ENFERMEDADES OCUPACIONALES

// Listar tipos de enfermedades ocupacionales
function getAgentsTypes() {
  const url = "registers/general/occupational/diseases/agents/types/get";
  return MyConfigurationApi.connectApi({ dataJson: null,  method: "GET",  url: url, });
}

//get de enfermedades ocupacionales
function getRelsDAgentsTypes({ idRegistersOcupationalDiseases }) {
  const url = "registers/general/occupational/diseases/rels/agents/types/get/"+idRegistersOcupationalDiseases;
  return MyConfigurationApi.connectApi({ dataJson: null,  method: "GET", url: url, });
}
// crear enfermedades ocupacionales
async function createAgentsTypes({ 
  idRegistersOcupationalDiseases,  
  id_registers_occupational_diseases_agents_types, 
  disease_name,
  area,
  affected_body,
  affected_workers_count,
  job_changes_count,
  january_count,
  february_count,
  march_count,
  april_count,
  may_count,
  june_count,
  july_count,
  august_count,
  september_count,
  october_count,
  november_count,
  december_count
}) {
  const url =    "registers/general/occupational/diseases/rels/agents/types/store/" + idRegistersOcupationalDiseases;
  const data = {
    id_registers_occupational_diseases_agents_types, 
  disease_name,
  area,
  affected_body,
  affected_workers_count,
  job_changes_count,
  january_count,
  february_count,
  march_count,
  april_count,
  may_count,
  june_count,
  july_count,
  august_count,
  september_count,
  october_count,
  november_count,
  december_count 
  };
  console.log(job_changes_count);
  return MyConfigurationApi.connectApi({    dataJson: data,    method: "POST",    url: url,  });
}

// eliminar enfermedades ocupacionales
async function deleteAgentsTypes({ id_agent_type }) {
  const url ="registers/general/occupational/diseases/rels/agents/types/delete/" + id_agent_type;
  return MyConfigurationApi.connectApi({dataJson: null,  method: "DELETE",  url: url, });
}

// firmar responsable por
function signResponsible({idResponsibleSign}) {
  const url = 'registers/general/occupational/diseases/responsible/update/firm/' + idResponsibleSign;
  const data = {  
  };
  return MyConfigurationApi.connectApi({ dataJson: data, method: 'PUT', url: url });
}

const MyOccupationalDiseases = { 
  occupationalDiseases, 
  createOccupationalsDiseases, 
  editOccupationalsDiseases,
  updateOccupationalsDiseases,
  ocupationalDiseasesEnd,

// medidas correctivas
  getMeasuresStatuses,
  getSearchsMeasuresUsers,
  getMeasures,
  createCorrectivesActions,
  deleteCorrectivesActions,

  // responsables
  getSearchsResponsiblesWorkers,
  getResponsibleWorkers,
  createResponsibleWorkers,
  deleteResponsibleWorkers,

  //sustancias 
  getDiseasesCarcinogens,
  createDiseasesCarcinogens,
  deleteDiseasesCarcinogens,

  // agentes
  getAgentsTypes,
  getRelsDAgentsTypes,
  createAgentsTypes,
  deleteAgentsTypes,

  // firmar responsable
  signResponsible
 };

  export default MyOccupationalDiseases;
  