import { Grid, InputLabel } from "@mui/material";
import React from "react";

function InputControla({ text }) {
  return (
    <Grid container>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <InputLabel
          sx={{
            padding: '8px',
            fontSize: '20px',
            color: '#305AD9',
            fontWeight: 'bold'
          }}> {text} </InputLabel>
      </Grid>
    </Grid >
  );
}

export default InputControla;
