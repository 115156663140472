
import { Typography } from '@mui/material'
import React from 'react'

function SubtitleText({ text, color }) {

  return (
    <Typography
      style={{
        display: 'flex',
        fontSize: '22px',
        color: color,
        height: '48px',
      }}
    >{text}</Typography>
  )
}

export default SubtitleText