import { InfoOutlined } from "@mui/icons-material";
import { Grid, IconButton, InputLabel, MenuItem, Select, Stack, Tooltip } from "@mui/material";
import { React, useState } from "react";
import ButtonIconControla from "../buttons/buttonIconControla";

function SelectControla(props) {

    const { text, inputValue, modalType = false, value = undefined, childrenRows, optionNull = 0, textTooltip = '', ...other } = props;

    return (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>

                <InputLabel
                    sx={{
                        padding: '8px',
                        fontSize: '18px',
                        color: '#305AD9',
                        fontWeight: 'bold'
                    }}>
                    <Stack direction={'row'} >
                        {text}
                        <Tooltip title={textTooltip} placement="top">
                            <InfoOutlined sx={{
                                display: (textTooltip == '' ? 'none' : 'block'),
                                color: "white",
                                backgroundColor: '#FFC300',
                                marginLeft: '10px',
                                width: '20px',
                                height: '20px',
                                borderRadius: '50%',
                                "&:hover": {
                                    backgroundColor: '#FACB32',
                                    boxShadow:
                                        "rgba(50, 50, 93, 0.25) 0px 6px 12px 1px, rgba(0, 0, 0, 0.3) 0px 3px 7px 1px",
                                },
                            }} />
                        </Tooltip>
                    </Stack>

                </InputLabel>
                <Select
                    size="small"
                    value={(value === undefined || value === null ? undefined : value)}
                    // defaultValue={value}
                    displayEmpty
                    onChange={(event) => { inputValue(event.target.value) }}
                    sx={{
                        fontFamily: 'Roboto',
                        width: "100%",
                        backgroundColor: (modalType === true ? '#F5F5F5' : '#FFFFFF'),
                        height: "42px",
                        color: '#305AD9',
                    }}
                    {...other}
                >
                    <MenuItem sx={{ display: (optionNull == 1 ? 'block' : 'none') }} value={''}>{'Sin seleccionar'}</MenuItem>
                    {childrenRows}
                </Select>

            </Grid>
        </Grid >
    );
}

export default SelectControla;
