import React, { Component, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export const EditorSsoPoliticsControla = ({
  dataEdit,
  handleDataEditor = alert,
}) => {
  const template = `
  <h2>Política de Seguridad y Salud Ocupacional</h2>

  <p>NOMBRE_EMPRESA, empresa dedicada a INGRESAR OBJETO SOCIAL, convencida que su capital más importante es su personal, ha elaborado la siguiente política de seguridad y salud en trabajo, mediante la que se compromete a:
  </p>
  <ul>
    <li>
    Desarrollar sus actividades protegiendo la integridad de sus colaboradores, proveedores y la de terceros en las instalaciones pertenecientes a la empresa, mediante la prevención de las lesiones, dolencias, enfermedades e incidentes relacionados con el trabajo, identificando los peligros, evaluando los riesgos relacionados a las actividades de la empresa y estableciendo medidas de control.
    </li>
    <li>
    Cumplir con la normativa legal vigente, regulaciones internas, compromisos voluntarios suscritos por la organización en materia de seguridad y salud en el trabajo, así como estándares internacionales.
    </li>
    <li>
    Asegurar la consulta y participación activa de nuestro personal y de sus representantes en todos los aspectos del Sistema de Gestión de Seguridad y Salud en el Trabajo de nuestra empresa.
    </li>
    <li>
    Informar, comunicar y poner a disposición de manera responsable y oportuna a nuestro personal, sus representantes y grupos de interés los aspectos del Sistema de Gestión de Seguridad y Salud en el Trabajo de nuestra empresa para eliminar los peligros y reducir los riesgos.
    </li>
    <li>
    Capacitar, entrenar y comprometer a nuestro personal con la cultura de prevención de la seguridad y salud en todos los aspectos del Sistema de Gestión de Seguridad y Salud en el Trabajo de nuestra empresa.
    </li>
    <li>
    Mejorar continuamente el desempeño del Sistema de Gestión de Seguridad y Salud en el trabajo e integrarlo a las demás actividades y otros sistemas de la empresa.
    </li>
  </ul>
  <p>
  Firma Fecha:
  </p>
  <p>
  ______________________
  </p>
  <p>
  Gerente General o equivalente (director / presidente / CEO, etc.)
  </p>`;
  return (
    <div className="App">
      <CKEditor
        editor={ClassicEditor}
        data={dataEdit === null ? template : dataEdit}
        onReady={(editor) => {
          const data = editor.getData();
          handleDataEditor(data);
          // You can store the "editor" and use when it is needed.
          // console.log( 'Editor is ready to use!', editor );
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          handleDataEditor(data);
          // console.log({ data });
        }}
        // onChange={(event, editor) => dataEditor(editor.getData())}

        onBlur={(event, editor) => {
          const data = editor.getData();
          handleDataEditor(data);
        }}
        onFocus={(event, editor) => {
          const data = editor.getData();
          handleDataEditor(data);
        }}
      />
    </div>
  );
};
