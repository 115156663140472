import { React, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Paper,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
} from "@mui/material";
import Container from "@mui/material/Container";
import { styled } from "@mui/system";
import {
    Add,
    BorderColorOutlined,
    Close,
    Delete,
    Download,
    Print,
    UploadFile,
    Visibility,
} from "@mui/icons-material";
import SideNav from "../../components/sidenav/sideNav";
import SubtitleText from "../../components/text/subtitleText";
import DocBanner from "../../components/cards/docBanner";
import MyBaseline from "../../api/MyBaseline";
import ButtonIconControla from "../../components/buttons/buttonIconControla";
import TitleText from "../../components/text/titleText";
import InputControlaVertical from "../../components/textfields/inputControlaVertical";
import ButtonControla from "../../components/buttons/buttonController";
import MyUsers from "../../api/MyUsers";

const grey = "#9191B0";
const blue = "#034AFF";

function Index() {
    const [usersActivesRows, setUsersActivesRows] = useState([]);
    const [usersInactivesRows, setUsersInactivesRows] = useState([]);
    const [rowsType, setrowsType] = useState(0);

    const handleChangerowsType = (event, newValue) => {
        setrowsType(newValue);
    };

    const handleGetUsers = async () => {
        const response = await MyUsers.users();

        if (response.success === false) {
            //console.log("error no se puedo traer lois diagnósticos")
        } else {
            // console.log(window.localStorage.getItem('userToken'));
            console.log(response);
            const usersActives = response.users_actives;
            const usersInactives = response.users_inactives;

            setUsersActivesRows(usersActives);
            setUsersInactivesRows(usersInactives);
        }
        return response;
    };

    useEffect(() => {
        handleGetUsers();
    }, []);

    return (
        <>
            <Box sx={{ display: "flex", height: "100%" }}>
                <SideNav returnBack="/settings" />
                <Container>
                    <Box>
                        <Grid container>
                            <Grid item md={12}>
                                <SubtitleText text={"Usuarios"} color={grey}></SubtitleText>
                                <DocBanner
                                    color1={"#8FBCFF"}
                                    color2={"#2F67BC"}
                                    text={"Mis Usuarios"}
                                ></DocBanner>
                            </Grid>
                        </Grid>
                        <Stack direction='row' spacing={2} justifyContent="start" sx={{ marginTop: 3, marginBottom: 3 }}>
                            <Box>
                                <ButtonControla
                                    iconButton={<Add />}
                                    backgroundColor={"#169073"}
                                    backgroundColorHover={"#1BAA88"}
                                    textButton={'Crear usuario'}
                                    url={'/settings/users/create'}
                                // functionOnClick={handleOpenModalUpload} 
                                /></Box>
                            <Box>
                                <ButtonControla
                                    iconButton={<UploadFile />}
                                    backgroundColor={"#2D9CDB"}
                                    backgroundColorHover={"#33AEF4"}
                                    textButton={'Importar usuarios'}
                                // functionOnClick={handleOpenModalUpload} 
                                />
                            </Box>
                        </Stack>

                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead></TableHead>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left" sx={{ color: blue }}>Nombre completo</TableCell>
                                        <TableCell align="left" sx={{ color: blue }}>Documento de identidad</TableCell>
                                        <TableCell align="left" sx={{ color: blue }}>Email / Usuario</TableCell>
                                        <TableCell colSpan={4}>
                                            <Stack
                                                direction="row"
                                                justifyContent="end"
                                                alignItems="center"
                                            >
                                                <Tabs
                                                    value={rowsType}
                                                    onChange={handleChangerowsType}
                                                    aria-label="Tipos de documento"
                                                >
                                                    <Tab label="Habilitados" />
                                                    <Tab label="Deshabilitados" />
                                                    {/* <Tab label="" /> */}
                                                </Tabs>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody
                                    sx={{ display: rowsType !== 0 ? "none" : "" }}
                                >
                                    {usersActivesRows.map((rowUser) => (
                                        <TableRow
                                            hover
                                            key={rowUser.id_users}
                                            sx={{
                                                "&:last-child td, &:last-child th": { border: 0 },
                                            }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {rowUser.first_name + ' ' + rowUser.last_name}
                                            </TableCell>
                                            <TableCell>
                                                {rowUser.document}
                                            </TableCell>
                                            <TableCell>
                                                {rowUser.email}
                                            </TableCell>
                                            <TableCell align="right">
                                                <Stack
                                                    direction="row"
                                                    justifyContent="end"
                                                    alignItems="center"
                                                >

                                                    <ButtonIconControla
                                                        icon={<Visibility sx={{ color: "white" }} />}
                                                        backgroundColor={"#305AD9"}
                                                        textTooltip={"Ver"}
                                                        functionOnClick={() =>
                                                        (window.location.href =
                                                            "/settings/users/edit?idUsers=" +
                                                            rowUser.id_users)
                                                        }
                                                    />

                                                    {/* <ButtonIconControla
                                                        icon={<Delete sx={{ color: "white" }} />}
                                                        backgroundColor={"#EB5757"}
                                                        textTooltip={"Eliminar"}
                                                        functionOnClick={() =>
                                                        (window.location.href =
                                                            "" + rowUser.id_baseline)
                                                        }
                                                    /> */}
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                                <TableBody
                                    sx={{ display: rowsType !== 1 ? "none" : "" }}
                                >
                                    {usersInactivesRows.map((rowUser) => (
                                        <TableRow
                                            hover
                                            key={rowUser.id_users}
                                            sx={{
                                                "&:last-child td, &:last-child th": { border: 0 },
                                            }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {rowUser.first_name + ' ' + rowUser.last_name}
                                            </TableCell>
                                            <TableCell>
                                                {rowUser.document}
                                            </TableCell>
                                            <TableCell>
                                                {rowUser.email}
                                            </TableCell>
                                            <TableCell align="right">
                                                <Stack
                                                    direction="row"
                                                    justifyContent="end"
                                                    alignItems="center"
                                                >

                                                    <ButtonIconControla
                                                        icon={<Visibility sx={{ color: "white" }} />}
                                                        backgroundColor={"#305AD9"}
                                                        textTooltip={"Ver"}
                                                        functionOnClick={() =>
                                                        (window.location.href =
                                                            "/settings/users/edit?idUsers=" +
                                                            rowUser.id_users)
                                                        }
                                                    />
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>

                    </Box>
                </Container>
            </Box >
        </>
    );
}

export default Index;
